import React, { Component } from "react";
import { View, Text, TouchableOpacity, Modal, ScrollView } from "react-native";
import {
	APP_COLOURS,
	TaskStatus,
	LISTIGSTATUS,
	ListingStatusColour,
} from "../../APP_VARS";
import { crudCreate, crudSendEmail } from "../../CRUDHELPER";
import moment from "moment";

export default class StatusItemListings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			status: this.props.status,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.status !== this.props.status) {
			this.setState({
				status: this.props.status,
			});
		}
	}

	handleStatusChange = (status) => {
		this.setState({ status: status, showModalArea: false });

		setTimeout(() => {
			this.processParent(status);
		}, 200);
		// }
	};

	processParent = (status) => {
		this.props.handleStatusChange(this.props.id, status);
	};

	render() {
		let d = this.props;
		let fs = this.props.fs;
		return (
			<View
				style={{
					// flex: 1,
					width: 80,
					// maxWidth: 200,
					backgroundColor: TaskStatus(this.state.status),
					borderRadius: 4,
					overflow: "hidden",
				}}>
				<TouchableOpacity
					style={{
						flex: 1,
					}}
					onPress={(event) => {
						const layout = event.nativeEvent;
						let x = layout.pageX;
						let y = layout.pageY;
						this.setState({
							showModalArea: !this.state.showModalArea,
							// xPos: x + 20,
							// yPos: y - 40,
							selectedTask: d,
							selectedID: d.id,
						});
					}}>
					<View
						style={{
							backgroundColor: ListingStatusColour(
								this.state.status,
							),
							padding: 5,
							flex: 1,
							alignItems: "center",
							justifyContent: "center",
						}}>
						<Text
							numberOfLines={1}
							adjustsFontSizeToFit
							style={{
								textAlign: "center",
								color:
									this.state.status === "copy" ||
									this.state.status === "Design"
										? "#FFF"
										: "#000",
								fontSize: fs * 0.8,
							}}>
							{this.state.status}
						</Text>
					</View>
				</TouchableOpacity>

				{this.state.showModalArea ? (
					<Modal
						animationType='fade'
						transparent={true}
						visible={true}>
						<View
							style={{
								width: window.innerWidth,
								height: window.innerHeight,
								backgroundColor: "rgba(0, 0, 0, 0.75)",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<TouchableOpacity
								activeOpacity={1}
								style={{
									width: window.innerWidth,
									height: window.innerHeight,
									backgroundColor: "rgba(0, 0, 0, 0.75)",
									zIndex: 10,
									position: "absolute",
									top: 0,
								}}
								onPress={() => {
									this.setState({
										showModalArea: "",
									});
								}}
							/>

							<View
								style={{
									position: "absolute",
									// top: this.state.yPos + 10,
									// left: this.state.xPos - 270,

									width: 300,
									height: "auto",
									maxHeight: 350,
									backgroundColor: "#20242B",
									borderRadius: 15,
									padding: 10,
									zIndex: 999,
									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 4,
									},
									shadowOpacity: 0.23,
									shadowRadius: 4.65,
									elevation: 8,
								}}>
								<ScrollView>
									{LISTIGSTATUS.sort(
										(a, b) => a.type > b.type,
									).map((status, ii) => (
										<TouchableOpacity
											key={ii}
											onPress={() => {
												this.handleStatusChange(
													status.status,
												);
											}}
											style={{
												flexDirection: "row",
												justifyContent: "center",
												alignItems: "center",
												marginBottom: 2,
												backgroundColor: status.color,
												width: "100%",
												borderRadius: 10,
												// borderWidth: 0.5,
												marginRight: 4,
												// borderColor: '#797979',
											}}>
											<View
												style={{
													padding: 10,
												}}>
												<Text
													numberOfLines={1}
													adjustsFontSizeToFit
													style={{
														fontFamily: "Gotham",
														fontSize: 15,
														fontWeight: "600",
														color:
															status.status ===
																"copy" ||
															status.status ===
																"Design"
																? "#FFF"
																: "#000",
													}}>
													{status.status}
												</Text>
											</View>
										</TouchableOpacity>
									))}
								</ScrollView>
							</View>
						</View>
					</Modal>
				) : null}
			</View>
		);
	}
}
