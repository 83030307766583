import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	TextInput,
	Modal,
	Image,
	ActivityIndicator,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import { crudDelete, crudRead, crudDeleteArray } from "../../CRUDHELPER";
import moment from "moment";

function findUrl(text) {
	// Regular expression pattern to match URLs
	const urlPattern = /https?:\/\/[\w\-\.]+[\w\/]*(\?\S+)?/g;
	// Use the match method to find all URLs in the text
	const urls = text.match(urlPattern);
	// Return the first found URL or null if no URL is found
	return urls ? urls[0] : null;
}

export default class NotificationList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterByName: "",
			notifications: [],
			showNotifications: false,
			loading: true,
		};
	}

	componentWillUnmount() {
		this.setState = (state, callback) => {
			return;
		};
	}

	componentDidMount() {
		this.getNotifications();
	}

	getNotifications = () => {
		crudRead("user_notifications", "", (s, d) => {
			console.log(s, d);
			this.setState({
				notifications: d,
				loading: false,
			});
		});
	};

	batchDelete = (d) => {
		// let array = [];
		// crudDeleteArray("user_notifications", d, () => {
		// 	this.getNotifications();
		// });
	};

	render() {
		let notifs = this.state.notifications
			.filter((d_) => {
				let filter = d_;
				if (
					typeof this.state.filterByName !== "undefined" &&
					this.state.filterByName !== ""
				) {
					let text_ = this.state.filterByName;
					var outString = text_.replace(
						/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
						"",
					);

					let join_names = `${JSON.stringify(d_)}`;

					return join_names
						.toLowerCase()
						.includes(outString.toLowerCase());
				}

				return filter;
			})
			.sort(
				(a, b) =>
					moment(b.createdAt).valueOf() -
					moment(a.createdAt).valueOf(),
			);

		let widthOfModal = 350;
		return (
			<View
				style={{
					width: "100%",
					// flex: 1,
					marginBottom: 10,
				}}>
				<View
					onLayout={(e) => {
						// console.log(e.nativeEvent);
						this.setState({
							left: e.nativeEvent.layout.left,
							top: e.nativeEvent.layout.top,
						});
					}}
					style={{
						width: "100%",
						padding: 7,
						fontSize: 16,
						borderRadius: 9,
						borderWidth: 0.3,
						borderColor: APP_COLOURS.BG3,
						backgroundColor: APP_COLOURS.BG3,

						flexDirection: "row",
						// justifyContent: "space-between",
						alignItems: "center",
						justifyContent: "center",
						// justifyContent: "center",
					}}>
					<TouchableOpacity
						style={{
							// alignItems: "center",
							// justifyContent: "center",
							marginLeft: 9,
							flex: 1,
							display: this.props.collapse ? "flex" : "none",
						}}
						onPress={(e) => {
							this.setState({
								showNotifications:
									!this.state.showNotifications,
							});
							this.getNotifications();
						}}>
						<Text
							style={{
								fontFamily: "Avenir",
								fontSize: 14,
								color: APP_COLOURS.TEXTCOLOR,
							}}>
							Notifications{" "}
							{this.state.notifications.length > 0
								? `(${this.state.notifications.length})`
								: ""}
						</Text>
					</TouchableOpacity>
					<TouchableOpacity>
						{this.props.loading ? (
							<ActivityIndicator color={APP_COLOURS.BLUE} />
						) : (
							<Image
								source={require("../../../assets/images/notification.png")}
								style={{
									width: 25,
									height: 25,
									resizeMode: "contain",
								}}
							/>
						)}
					</TouchableOpacity>
				</View>

				<Modal
					transparent={true}
					visible={this.state.showNotifications}
					animationType='fade'
					style={{
						backgroundColor: APP_COLOURS.BACK_TRANSPARENT2,
						width: window.innerWidth,
						height: window.innerHeight,
					}}>
					<TouchableOpacity
						activeOpacity={1}
						style={{
							backgroundColor: APP_COLOURS.BACK_TRANSPARENT2,
							width: window.innerWidth,
							height: window.innerHeight,
						}}
						onPress={() =>
							this.setState({
								showNotifications: false,
							})
						}>
						<View
							style={{
								flex: 1,
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: APP_COLOURS.BACK_TRANSPARENT2,
								width: window.innerWidth,
								height: window.innerHeight,
							}}></View>
					</TouchableOpacity>

					<View
						style={{
							width: widthOfModal,
							maxHeight: 500,
							backgroundColor: APP_COLOURS.BG2,
							position: "absolute",
							top: this.state.top,
							left: this.state.left,
							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 2,
							},
							shadowOpacity: 0.25,
							shadowRadius: 7.84,
							elevation: 12,
							borderRadius: 10,
							// padding: 10,
							zIndex: 999,
						}}>
						<View
							ref={(ref) => {
								this.searchBox = ref;
							}}
							onLayout={(e) => {
								// console.log(e.nativeEvent);
								this.setState({
									searchBox: e.nativeEvent.layout.width,
								});
							}}
							style={{
								backgroundColor: APP_COLOURS.BG2,
								borderRadius: 12,
							}}>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "space-between",
									padding: 8,
									paddingLeft: 15,
									borderWidth: 0.3,
									borderColor: APP_COLOURS.BG3,
									borderRadius: 9,
									backgroundColor: APP_COLOURS.BG3,
									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 2,
									},
									shadowOpacity: 0.25,
									shadowRadius: 7.84,
									elevation: 12,
								}}>
								<TextInput
									ref={(ref) => {
										this.textInput = ref;
									}}
									style={{
										// padding: 8,
										fontSize: 16,
										borderRadius: 9,
										color: APP_COLOURS.WHITE,
										fontWeight: "200",
										fontFamily: "Avenir",
									}}
									autoFocus
									placeholderTextColor={"#797979"}
									autoCapitalize='words'
									clearButtonMode='while-editing'
									editable={true}
									placeholder={"Notifications"}
									value={this.state.filterByName}
									onChangeText={(text) => {
										this.setState({
											filterByName: text,
										});
									}}
								/>
								<TouchableOpacity
									onPress={() => {
										this.setState({
											filterByName: "",
										});
										this.textInput.focus();
									}}>
									<Image
										source={require("../../../assets/images/close.png")}
										style={{
											width: 20,
											height: 20,
											resizeMode: "contain",
										}}
									/>
								</TouchableOpacity>
								<TouchableOpacity
									onPress={() => {
										this.setState((prevState) => ({
											showNotifications: false,
										}));
									}}>
									{this.props.loading ? (
										<ActivityIndicator
											color={APP_COLOURS.BLUE}
										/>
									) : (
										<Image
											source={require("../../../assets/images/skrum_menu.png")}
											style={{
												width: 20,
												height: 20,
												resizeMode: "contain",
											}}
										/>
									)}
								</TouchableOpacity>
							</View>
							<View
								style={{
									maxHeight: 450,
								}}>
								<ScrollView
									contentContainerStyle={{
										padding: 5,
									}}>
									{notifs.map((d, i) => (
										<View
											key={d.id}
											style={{
												flexDirection: "row",
												width: "100%",
											}}>
											<TouchableOpacity
												onPress={() => {
													// this.setState(() => ({
													// 	selectedNotification: d,
													// 	showNotifications: false,
													// 	filterByName: d.id,
													// }));
													// this.props.selectedNotification(
													// 	d,
													// );

													const foundUrl = findUrl(
														d.message,
													);
													console.log(
														"Found URL:",
														foundUrl,
													);
													// window.open(foundUrl);
													window.location.href =
														foundUrl;
												}}
												style={{
													flexDirection: "row",
													flex: 1,
												}}>
												<Image
													source={{
														uri: d.avatar_url,
													}}
													style={{
														width: 30,
														height: 30,
														borderRadius: 25,
														resizeMode: "contain",
														backgroundColor: "#FFF",
														borderWidth: 0.3,
														borderColor: "#C5C5C5",
														shadowColor: "#ECF4FF",
														shadowOffset: {
															width: 0,
															height: 8,
														},
														shadowOpacity: 0.44,
														shadowRadius: 10.32,

														elevation: 16,
													}}
												/>
												<View
													style={{
														flexWrap: "wrap",
														flex: 1,
														padding: 8,
														borderBottomColor:
															"#797979",
														marginBottom: 2,
														// flexDirection: "row",
													}}>
													<Text
														style={{
															color: "#FFF",
															fontSize: 15,
														}}>
														{d.createdBy.name}
													</Text>
													<Text
														style={{
															color: "#FFF",
															fontSize: 15,
														}}>
														{d.message}
													</Text>
													<Text
														style={{
															color: "#FFF",
															fontSize: 8,
															opacity: 0.5,
														}}>
														{moment(
															d.createdAt,
														).fromNow()}
													</Text>
												</View>
											</TouchableOpacity>
											{this.state.loading ? (
												<ActivityIndicator
													color={APP_COLOURS.WHITE}
												/>
											) : (
												<TouchableOpacity
													onPress={() => {
														this.setState({
															loading: true,
														});
														crudDelete(
															"user_notifications",
															d,
															() => {
																this.getNotifications();
															},
														);
													}}
													style={{
														flexDirection: "row",
														justifyContent:
															"center",
														width: 40,
														alignItems: "center",
														padding: 5,
														// borderLeftWidth: 0.5,
														// borderLeftColor: "#FFF",
														marginLeft: 5,
													}}>
													<Image
														source={require("../../../assets/images/bin.png")}
														style={{
															width: 22,
															height: 22,
															// borderRadius: 25,
															resizeMode:
																"contain",
															marginRight: 5,
														}}
													/>
												</TouchableOpacity>
											)}
										</View>
									))}
								</ScrollView>
							</View>
						</View>
					</View>
				</Modal>
			</View>
		);
	}
}
