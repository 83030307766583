import React, { Component } from "react";
import { View, Text, ScrollView, TouchableOpacity } from "react-native";
import { APP_COLOURS } from "../../APP_VARS";

const DynamicSpreadsheetTable = ({ data }) => {
	// Function to get all unique keys (columns) from the data array
	const getAllKeys = (data) => {
		const allKeys = new Set();
		data.forEach((item) => {
			Object.keys(item).forEach((key) => allKeys.add(key));
		});
		return Array.from(allKeys);
	};

	const columns = getAllKeys(data);

	let w = 100;

	return (
		<View
			style={{
				flex: 1,
				padding: 10,
				backgroundColor: APP_COLOURS.WHITE,
			}}>
			<ScrollView horizontal>
				<View
					style={{
						flex: 1,
						width: "100%",
					}}>
					{/* <View
						style={{
							flexDirection: "row",
							width: "100%",
						}}>
						{columns.map((column) => (
							<View
								style={{
									flex: 1,
									width: w,
									padding: 2,
								}}
								key={column}>
								{column}
							</View>
						))}
					</View> */}
					<View
						style={{
							flex: 1,
							// flexDirection: "row",
						}}>
						{data.map((row, index) => (
							<View
								key={index}
								style={{
									flex: 1,
									flexDirection: "row",
								}}>
								{columns.map((column) => (
									<TouchableOpacity
										onPress={() => {
											// alert(row[column]);
										}}>
										<Text
											key={`${index}-${column}`}
											numberOfLines={1}
											style={{
												width: w,
												padding: 2,
												borderWidth: 0.5,
												borderColor:
													APP_COLOURS.WHITE_TRANSPARENT6,
											}}>
											{row[column] !== undefined
												? row[column]
												: ""}
										</Text>
									</TouchableOpacity>
								))}
							</View>
						))}
					</View>
				</View>
			</ScrollView>
		</View>
	);
};

export default DynamicSpreadsheetTable;
