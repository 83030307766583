import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";

import { APP_COLOURS } from "../../APP_VARS";
import LoadingStream from "./LoadingStream";
import CalendarStream from "./CalendarStream";
import NoticeBoardStream from "./NoticeBoardStream";
import PreviewUsers from "../../Authed/UserManagement/PreviewUsers";
import StreamTasks from "./StreamTasks";
import GoogleSheetStream from "./GoogleSheetStream";
// import AutoHeightImage from "../../Authed/AutoHeightImageComp";

const LiveStream = () => {
	const [list, setList] = useState([]);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const [sent, setSent] = useState(false);
	const [sending, setSending] = useState(false);

	const { token } = useParams();

	document.title = `LIVE STREAM - ${token}`;

	if (token !== "id996997991") {
		window.location.href = "/";
	}

	useEffect(() => {
		const interval = setInterval(() => {
			window.location.reload();
		}, 3600000);
		return () => clearInterval(interval);
	}, []);

	if (error) return <div>Error: {error}</div>;

	// if (sending) return <SendingFeedback />;
	// if (sent) return <ApprovalSent />;

	let calWidth = window.innerHeight / 3.5;

	return <GoogleSheetStream />;

	return (
		<View
			style={{
				backgroundColor: APP_COLOURS.BG,
				alignItems: "center",
				flex: 1,
				padding: 20,
				height: "100%",
				overflow: "hidden",
			}}>
			{/* <LoadingStream />
			 */}
			<View
				style={{
					width: "100%",
					justifyContent: "flex-start",
					paddingTop: 5,
					paddingLeft: 10,
					flexDirection: "row",
					padding: 20,
					justifyContent: "space-between",
					alignItems: "center",
				}}>
				<View
					style={{
						flexDirection: "row",
						alignItems: "center",
					}}>
					<View
						style={{
							flex: 1,
							flexDirection: "row",
							alignItems: "center",
						}}>
						<Image
							source={require("../../../assets/images/icons/skrum_menu.png")}
							style={{
								width: 45,
								height: 45,
								resizeMode: "contain",
								marginRight: 3,
							}}
						/>
						<Image
							source={require("../../../assets/images/skrum_logo.png")}
							style={{
								width: 100,
								height: 45,
								resizeMode: "contain",
								marginRight: 7,
							}}
						/>
					</View>
				</View>
				<Text
					style={{
						fontFamily: "Gotham",
						fontSize: 20,
						color: APP_COLOURS.TEXTCOLOR,
						fontWeight: "300",
					}}>
					{moment().format("dddd DD MMM")}
				</Text>
			</View>
			<View style={{ width: "100%", flexDirection: "row" }}>
				<View style={{ marginTop: 10 }}>
					<View
						style={{
							width: calWidth,
							backgroundColor: APP_COLOURS.BG3,
							borderRadius: 10,
						}}>
						<CalendarStream
							compWidth={calWidth}
							calendarList={list}
							selectedDate={moment()}
							startDate={(date) => {}}
							endDate={(date) => {}}
						/>
					</View>
					<View
						style={{
							width: calWidth,
							backgroundColor: APP_COLOURS.BG3,
							borderRadius: 10,
							marginTop: 10,
							opacity: 0.6,
						}}>
						<CalendarStream
							compWidth={calWidth}
							calendarList={list}
							selectedDate={moment().add(1, "month")}
							startDate={(date) => {}}
							endDate={(date) => {}}
						/>
					</View>
					<View
						style={{
							width: calWidth,
							backgroundColor: APP_COLOURS.BG3,
							borderRadius: 10,
							marginTop: 10,
							opacity: 0.6,
						}}>
						<CalendarStream
							compWidth={calWidth}
							calendarList={list}
							selectedDate={moment().add(2, "month")}
							startDate={(date) => {}}
							endDate={(date) => {}}
						/>
					</View>
				</View>
				<View
					style={{
						flex: 2,
						backgroundColor: APP_COLOURS.BG3,
						borderRadius: 10,
						marginTop: 10,
						marginHorizontal: 10,
					}}>
					{/* <StreamTasks /> */}
					<GoogleSheetStream />
				</View>
				<View
					style={{
						flex: 1,
						backgroundColor: APP_COLOURS.BG3,
						borderRadius: 10,
						marginTop: 10,
					}}>
					<NoticeBoardStream />
					{/* <PreviewUsers /> */}
				</View>
			</View>
		</View>
	);
};

export default LiveStream;
