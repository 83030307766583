import React, { Component } from "react";
import {
	View,
	Image,
	ScrollView,
	Linking,
	Text,
	TouchableOpacity,
} from "react-native";
import AuditLandingPage from "./LandingPage";
import logo_tetrice from "../../../assets/images/tetrice-revlon-main.png";
import { APP_COLOURS, screenHeight } from "../../APP_VARS";
import Font from "react-font";
import "./open.css";
if (typeof window !== "undefined") {
	require("smoothscroll-polyfill").polyfill();
}

export default class OpenProposal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
			showSidebar: false,
			loading: true,

			pageNumber: 0,

			proposal_id: this.props.proposal_id,
			proposalData: "",
			proposalPages: [],
			proposalPageData: {
				id: "",
			},
			lockContent: true,
		};
	}

	componentDidMount() {
		let accessToken = this.props.token;

		console.log("Approved User", accessToken);
		if (accessToken !== null) {
			// this.props.history.push("/dashboard/Home");
		} else {
			localStorage.clear();
			this.setState({
				loading: false,
			});
		}

		this.fetchProposalData();

		window.addEventListener("resize", this.resize.bind(this));
		this.resize();

		// setTimeout(() => {
		// 	this.setState({
		// 		loading: false,
		// 	});
		// }, 2000);
	}

	fetchProposalData = async () => {
		this.setState({
			loading: true,
			proposalData: "",
			proposalPages: [],
			proposalPageData: "",
		});
		let url =
			"https://workflow.tetrice.co.za/webhook/b860d5cc-6e8e-4542-8566-af350fe44694";
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${this.props.token}`,
			},
			body: JSON.stringify({
				_id: this.props.proposal_id,
				id: this.props.proposal_id,
				title: "",
			}),
		};
		fetch(url, requestOptions)
			.then((response) => {
				if (!response.ok) {
					throw new Error("Failed to fetch data");
				}
				return response.json();
			})
			.then((data) => {
				console.log(data);
				this.setState({
					proposalData: data,
				});
				this.fetchProposalPages(data._id);
			})
			.catch((error) => {
				this.setState({
					error: error,
					loading: false,
				});
			});
	};
	fetchProposalPages = async (_id) => {
		// POST this data from the parent list or create a new proposal
		let url =
			"https://workflow.tetrice.co.za/webhook/a61608aa-7bdb-4ca0-88ce-58ce856ee0f4";
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${this.props.token}`,
			},
			body: JSON.stringify({
				proposal_id: _id,
			}),
		};
		fetch(url, requestOptions)
			.then((response) => {
				if (!response.ok) {
					throw new Error("Failed to fetch data");
				}
				return response.json();
			})
			.then((data) => {
				console.log(data);
				this.setState({
					proposalPages: data,
					loading: false,
				});
			})
			.catch((error) => {
				this.setState({
					error: error,
					loading: false,
				});
			});
	};
	getLargeHtmlData = (geturl, next) => {
		// POST this data from the parent list or create a new proposal
		let url =
			"https://workflow.tetrice.co.za/webhook/59af9ff2-5530-45b9-b4d8-fb9631c555fb";
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${this.props.token}`,
			},
			body: JSON.stringify({
				url: geturl,
			}),
		};
		fetch(url, requestOptions)
			.then((response) => {
				if (!response.ok) {
					throw new Error("Failed to fetch data");
				}
				return response.json();
			})
			.then((html) => {
				return next(html);
			})
			.catch((error) => {
				this.setState({
					error: error,
					loading: false,
				});
			});
	};

	componentDidUpdate() {}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	render() {
		const { screenWidth } = this.state;
		const isSmallScreen = screenWidth < 1000;
		// const scaleFactor = isSmallScreen ? 0.8 : 1.0;
		// const pageStyle = {
		// 	transform: `scale(${scaleFactor})`,
		// };

		let fs = window.innerWidth * 0.07;
		// let maxFont = 40;
		// let h1 = fs < maxFont ? fs : maxFont;
		// let h2 = h1 / 1.618;
		// let pp = h2 / 1.618;

		let pp = isSmallScreen ? 16 : 20;
		let h2 = pp * 1.2618;
		let h1 = h2 * 1.2618;
		let h0 = h1 * 1.2618;
		let maxFont = 40;

		let mw = 1000;

		if (this.state.loading) {
			return (
				<View
					style={{
						// width: window.innerWidth,
						height: window.innerHeight,
						backgroundColor: "#171A20",
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						position: "absolute",
						top: 0,
						zIndex: 999,
						width: "100%",
					}}>
					<Image
						source={logo_tetrice}
						style={{
							width: 350,
							height: 100,
							resizeMode: "contain",
						}}
					/>
				</View>
			);
		} else
			return (
				<View
					style={{
						width: this.state.screenWidth,
						height: this.state.screenHeight,
						overflow: "hidden",
						backgroundColor: "#171A20",
					}}>
					<ScrollView
						ref={(ref) => {
							this.scrollRef = ref;
						}}
						overScrollMode='never'
						showsVerticalScrollIndicator={false}
						// pagingEnabled={true}
						// decelerationRate='fast'
						// scrollEventThrottle={160}
						// snapToInterval={this.state.screenHeight}
						contentContainerStyle={{
							alignItems: "center",

							// backgroundColor: APP_COLOURS.BACK_TRANSPARENT2,
						}}>
						<AuditLandingPage
							{...this.state}
							h0={h0}
							h1={h1}
							h2={h2}
							pp={pp}
							mw={mw}
							// title={this.state.proposalData?.title}
							// title2={this.state.proposalData?.title2}
							// subTitle={this.state.proposalData?.subTitle}
							// description={this.state.proposalData?.description}
							// createdBy={this.state.proposalData?.createdBy}
						/>

						<View
							style={{
								flexDirection: "row",
								marginBottom: 20,
								width: "100%",
								maxWidth: 1000,
								alignItems: "center",
							}}>
							<ScrollView horizontal>
								{this.state.proposalPages
									.sort((a, b) => a.title > b.title)
									.map((d) => (
										<TouchableOpacity
											onPress={async () => {
												await this.setState({
													proposalPageData: "",
													selectedPage: d._id,
												});
												if (
													typeof d.htmlData !==
													"undefined"
												) {
													this.getLargeHtmlData(
														d.htmlData?.htmlFileUrl,
														(html) => {
															console.log(html);
															this.setState({
																proposalPageData:
																	{
																		...d,
																		html: html.data,
																	},
															});
														},
													);
												} else {
													await this.setState({
														proposalPageData: {
															...d,
															html: "",
														},
													});
												}
											}}>
											<View
												style={{
													flex: 1,
													alignItems: "center",
													justifyContent: "center",
													borderRadius: 10,
													borderWidth: 1,
													borderColor:
														this.state
															.selectedPage ===
														d._id
															? "transparent"
															: "#FFF",
													height: 60,
													marginRight: 5,
													padding: 20,
													backgroundColor:
														this.state
															.selectedPage ===
														d._id
															? APP_COLOURS.BLUE
															: "transparent",
												}}>
												<Text
													style={{
														fontSize: 16,
														color: APP_COLOURS.DESELECTEDCOLOR,
													}}>
													<Font
														family='Montserrat'
														weight={300}>
														{d.title}
													</Font>
												</Text>
											</View>
										</TouchableOpacity>
									))}

								<View style={{ width: 100 }} />

								<TouchableOpacity
									onPress={async () => {
										await this.setState({
											proposalPageData: "",
											showTimeline:
												!this.state.showTimeline,
										});
									}}>
									<View
										style={{
											flex: 1,
											alignItems: "center",
											justifyContent: "center",
											borderRadius: 10,
											borderWidth: 1,
											borderColor: "#FFF",
											height: 60,
											marginRight: 5,
											padding: 20,
										}}>
										<Text
											style={{
												fontSize: 16,
												color: APP_COLOURS.DESELECTEDCOLOR,
											}}>
											<Font
												family='Montserrat'
												weight={300}>
												Timeline
											</Font>
										</Text>
									</View>
								</TouchableOpacity>

								<TouchableOpacity
									onPress={async () => {
										await this.setState({
											proposalPageData: "",
											showCostEstimate:
												!this.state.showCostEstimate,
										});
									}}>
									<View
										style={{
											flex: 1,
											alignItems: "center",
											justifyContent: "center",
											borderRadius: 10,
											borderWidth: 1,
											borderColor: "#FFF",
											height: 60,
											marginRight: 5,
											padding: 20,
										}}>
										<Text
											style={{
												fontSize: 16,
												color: APP_COLOURS.DESELECTEDCOLOR,
											}}>
											<Font
												family='Montserrat'
												weight={300}>
												Cost Estimate
											</Font>
										</Text>
									</View>
								</TouchableOpacity>
							</ScrollView>
						</View>

						{this.state.proposalPageData !== "" ? (
							<View
								style={{
									width: "100%",
									// alignItems: "center",
									paddingTop: 40,
									justifyContent: "center",
									width: "100%",
									maxWidth: 1000,
								}}>
								<div
									className='ql-editor'
									dangerouslySetInnerHTML={{
										__html: this.state.proposalPageData
											.html,
									}}
								/>
							</View>
						) : null}

						<View
							style={{
								height: 300,
							}}
						/>

						<View style={{ width: "100%" }}>
							{/* <ProposalPage
									{...this.state}
									h0={h0}
									h1={h1}
									h2={h2}
									pp={pp}
									{...{
										title: "Digital Audit",
										title2: "",
										animationData: infographics,
										items: [],
										sheetName: "OverviewPage",
									}}
								/> */}

							{/* <VTOPage
								{...this.state}
								h0={h0}
								h1={h1}
								h2={h2}
								pp={pp}
								{...{
									title: "VTO - revlon.co.za",
									title2: "",
									animationData: content,
									items: [],
									sheetName: "OverviewPage",
								}}
							/>

							<EtailerAuditPage
								{...this.state}
								h0={h0}
								h1={h1}
								h2={h2}
								pp={pp}
								{...{
									title: "E-Commerce Audit",
									title2: "",
									animationData: brandidentity,
									items: [],
									sheetName: "OverviewPage",
								}}
							/>

							<ProductListingPage
								{...this.state}
								h0={h0}
								h1={h1}
								h2={h2}
								pp={pp}
								{...{
									title: "Website Listings",
									title2: "",
									animationData: infographics,
									items: [],
									sheetName: "OverviewPage",
								}}
							/>

							<ProposalPage
								{...this.state}
								h0={h0}
								h1={h1}
								h2={h2}
								pp={pp}
								{...{
									title: "Page 2 month",
									title2: "",
									animationData: findTarget,
									items: [],
									sheetName: "Page2",
								}}
							/> */}
						</View>
					</ScrollView>
				</View>
			);
	}
}
