import React, { Component } from "react";
import Lottie from "react-lottie";

class LottieControl extends Component {
	constructor(props) {
		super(props);
		this.state = { isPaused: false };
		this.animationRef = React.createRef(); // Create a ref for the Lottie component
	}

	componentDidMount() {
		// Set a timeout to stop the animation after 1000ms
		this.timeout = setTimeout(() => {
			if (this.animationRef.current) {
				// Check if the ref is available
				this.setState({ isPaused: true }); // Update state to pause the animation
			}
		}, 2500); // Stop after 1000ms
	}

	componentWillUnmount() {
		// Clear the timeout when the component is unmounted to prevent memory leaks
		clearTimeout(this.timeout);
	}

	render() {
		const { animationData } = this.props;
		const { isPaused } = this.state;

		const defaultOptions = {
			loop: false,
			autoplay: true, // Start playing automatically
			animationData: animationData,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		return (
			<Lottie
				options={defaultOptions}
				isPaused={isPaused} // Control the animation play state with isPaused prop
				height={90}
				width={90}
				ref={this.animationRef}
			/>
		);
	}
}

export default LottieControl;
