// import React, { useEffect, useState } from "react";
// import "./Pie.css"; // Assuming you have a CSS file for .pie and other classes

// const Pie = ({ percentage, color = "black", onFinish }) => {
// 	const [timeLeft, setTimeLeft] = useState(percentage);

// 	useEffect(() => {
// 		if (timeLeft > 0) {
// 			const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
// 			return () => clearTimeout(timerId);
// 		} else {
// 			onFinish();
// 		}
// 	}, [timeLeft, onFinish]);

// 	const pieStyle = {
// 		"--p": `${percentage}`,
// 		"--c": color,
// 		"--b": "10px",
// 		fontSize: "1em", // Example additional inline style
// 	};

// 	const classNames = `pie animate`;

// 	return (
// 		<div className={classNames} style={pieStyle}>
// 			{percentage}%
// 		</div>
// 	);
// };

// const CountDownTimer = ({ duration, color, onCountdownFinish }) => {
// 	return (
// 		<div>
// 			<Pie
// 				percentage={duration}
// 				color={color}
// 				onFinish={onCountdownFinish}
// 			/>
// 		</div>
// 	);
// };

// export default CountDownTimer;

import React, { useEffect, useState } from "react";
import "./Pie.css"; // Assuming you have CSS for animation
import { crudReadUnass } from "../../CRUDHELPER";

const Pie = ({ duration, color = "black", onCountdownFinish }) => {
	const [timeLeft, setTimeLeft] = useState(duration);
	const percentage = (timeLeft / duration) * 100;

	useEffect(() => {
		if (timeLeft === 0) {
			onCountdownFinish();
			resetTimer();
		}
		// Timer logic
		const timerId =
			timeLeft > 0 && setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
		return () => clearTimeout(timerId);
	}, [timeLeft]);

	const resetTimer = () => {
		setTimeLeft(duration);
	};

	const pieStyle = {
		"--p": `${percentage}`,
		"--c": color,
		"--b": "8px",
		fontSize: "1em",
		width: "40px",
		height: "40px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: "50%",
	};

	return (
		<div className='pie animate' style={pieStyle}>
			<div
				style={{
					fontSize: 12,
				}}>
				{timeLeft}s
			</div>
		</div>
	);
};

const CountDownTimer = ({ duration, color, onCountdownFinish }) => {
	return (
		<div
			style={{
				alignItems: "center",
				justifyContent: "center",
			}}>
			<Pie
				duration={duration}
				color={color}
				onCountdownFinish={onCountdownFinish}
			/>
		</div>
	);
};

export default CountDownTimer;
