import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import { Avatars, StatusColour, TaskStatus } from "../../APP_VARS";
import StatusItem from "../DailyPlanner/StatusItem";
import Lottie from "react-lottie";
import {
	crudCreate,
	crudDelete,
	crudGetcalendars,
	crudRead,
	crudReadAll,
	crudUpdate,
} from "../../CRUDHELPER";
import Fade from "react-reveal/Fade";
import AutoHeightImage from "../AutoHeightImageComp";
import * as animationData from "../../../assets/lottie/team.json";

export default class SentimentTracker extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<View>
				<Text> index </Text>
			</View>
		);
	}

	selectPlatform = () => {
		return (
			<View
				style={{
					flex: 1,
					padding: 10,
				}}>
				<View
					style={{
						flex: 1,
						width: "100%",
						// maxWidth: 1000,
						flexDirection: "row",
						marginBottom: 5,
						// width: "60%",
					}}>
					<View
						style={{
							width: 500,
							paddingBottom: 60,
							backgroundColor: "#FFF",
							padding: 5,
							borderRadius: 10,
							// alignItems: "center",
						}}>
						<View
							style={{
								flexDirection: "row",
								justifyContent: "space-between",
								alignItems: "center",
							}}>
							<View
								style={{
									padding: 10,
								}}>
								<TouchableOpacity
									style={{
										flexDirection: "row",
										alignItems: "center",
										// position: "absolute",
									}}
									onPress={() => {
										// this.props.close();
									}}>
									<AutoHeightImage
										width={20}
										source={require("../../../assets/images/menu.png")}
									/>
									<Text
										adjustsFontSizeToFit
										style={{
											fontWeight: "800",
											fontFamily: "Gotham",
											color: APP_COLOURS.BG2,
											fontSize: 13,
											marginLeft: 4,
										}}>
										tetrice
									</Text>
								</TouchableOpacity>
								<Text
									style={{
										color: APP_COLOURS.BG2,
										fontSize: 28,
										fontWeight: "800",
										fontFamily: "Gotham",
										// marginTop: 20,
										// marginTop: 6,
									}}>
									Content Calendar
								</Text>

								<Text
									style={{
										fontSize: 16,
										color: APP_COLOURS.BG2,
										fontWeight: "300",
										padding: 2,
										marginBottom: 20,
									}}>
									Calendar per month
								</Text>
							</View>
							<View
								style={
									{
										// position: "absolute",
										// right: 10,
										// top: 10,
									}
								}>
								<Lottie
									options={{
										loop: true,
										autoplay: true,
										animationData: animationData,
										rendererSettings: {
											preserveAspectRatio:
												"xMidYMid slice",
										},
									}}
									height={150}
									width={200}
								/>
							</View>
						</View>

						<View
							style={{
								// flex: 1,
								width: "100%",
								flexDirection: "row",
								alignItems: "center",
								height: 45,
								paddingLeft: 5,
							}}>
							<View
								style={{
									// height: "auto",
									// width: "100%",
									flex: 1,
									minWidth: 200,
									// maxWidth: 400,
									backgroundColor: APP_COLOURS.BLACK_TRANS,
									borderRadius: 13,
								}}>
								{this.state.showInput ? (
									<TextInput
										style={{
											fontWeight: "400",
											fontFamily: "Gotham",

											width: "100%",
											paddingLeft: 15,
											padding: 10,
											fontSize: 15,
											borderRadius: 13,
											marginRight: 15,
											color: APP_COLOURS.BG2,
											// backgroundColor:
											// 	APP_COLOURS.WHITE_TRANSPARENT4,
											backgroundColor: "#E8E8E8",
											fontWeight: "500",
										}}
										placeholderTextColor={"#797979"}
										autoCapitalize='sentences'
										clearButtonMode='while-editing'
										autoCompleteType='off'
										editable={true}
										autoCorrect={true}
										autoFocus
										keyboardType='default'
										textContentType='none'
										placeholder={` Search `}
										// defaultValue={this.state.user_data.email}
										defaultValue={this.state.filterSearch}
										onChangeText={(text) => {
											console.log(text);
											this.setState({
												filterSearch: text,
											});
										}}
									/>
								) : null}
							</View>
							<TouchableOpacity
								style={{
									marginRight: 10,
									marginLeft: 10,
								}}
								onPress={() =>
									this.setState({
										showInput: !this.state.showInput,
									})
								}>
								<AutoHeightImage
									width={19}
									source={require("../../../assets/images/searchIcon.png")}
								/>
							</TouchableOpacity>
							{this.state.loading ? (
								<ActivityIndicator
									color={APP_COLOURS.PRIMARY}
								/>
							) : null}
							{this.state.user_data.username ===
							"wesley@tetrice.co.za" ? (
								<TouchableOpacity
									style={{
										// marginRight: 15,
										marginLeft: 15,
										// backgroundColor: APP_COLOURS.BG2,
										borderRadius: 15,
										flexDirection: "row",
										paddingVertical: 10,
										alignItems: "center",
										paddingHorizontal: 15,
										borderWidth: 0.5,
										borderColor: APP_COLOURS.BG2,
									}}
									onPress={async () => {
										this.setState({
											addNewService:
												!this.state.addNewService,
										});
									}}>
									<Text
										style={{
											color: APP_COLOURS.BG2, // "#FFF",
											fontSize: 14,
											marginRight: 10,
										}}>
										+
									</Text>
									<Text
										style={{
											color: APP_COLOURS.BG2, // "#FFF",
											fontSize: 12,
										}}>
										New
									</Text>
								</TouchableOpacity>
							) : null}
						</View>
					</View>
				</View>
			</View>
		);
	};
}
