import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TextInput,
	ScrollView,
	Keyboard,
	Modal,
	ActivityIndicator,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";

export default class TimeSelector extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [
				"07:00",
				"08:00",
				"09:00",
				"10:00",
				"11:00",
				"12:00",
				"13:00",
				"14:00",
				"15:00",
				"16:00",
				"17:00",
				"18:00",
				"19:00",
				"20:00",
				"21:00",
			],
		};
	}

	render() {
		let { data } = this.state;
		return (
			<View style={{ padding: 10, width: "100%" }}>
				<ScrollView showsHorizontalScrollIndicator={false} horizontal>
					<View
						style={{
							flexDirection: "row",
						}}>
						{/* <TouchableOpacity
							style={{}}
							onPress={() =>
								this.setState({
									filterTab: "",
								})
							}>
							<View
								style={{
									width: "auto",
									//  height: 50,
									justifyContent: "center",
									alignItems: "center",

									padding: 10,
									borderRadius: 12,
									marginRight: 5,
									backgroundColor:
										this.state.filterTab === ""
											? APP_COLOURS.BLUE
											: APP_COLOURS.WHITE_TRANSPARENT9,
								}}>
								<Text
									style={{
										fontSize: 12,
										color:
											this.state.filterTab === ""
												? "#FFF"
												: APP_COLOURS.BLUE,
										fontWeight: "400",
										fontFamily: "Gotham",
									}}>
									All
								</Text>
							</View>
						</TouchableOpacity> */}
						{data
							.filter((d_) => {
								let filter = d_;

								if (
									typeof this.state.filterSearch !==
										"undefined" &&
									this.state.filterSearch !== ""
								) {
									let text_ = this.state.filterSearch;
									var outString = text_.replace(
										/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
										"",
									);

									let join_names = `${d_}`;

									join_names = join_names.replace(
										/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
										"",
									);

									return join_names
										.toLowerCase()
										.includes(outString.toLowerCase());
								}

								return filter;
							})
							.map((d, i) => (
								<TouchableOpacity
									key={i}
									style={{}}
									onPress={() => {
										this.setState({
											filterTab: d,
										});

										this.props.selected(d);
									}}>
									<View
										style={{
											width: "auto",
											//   height: 50,
											justifyContent: "center",
											alignItems: "center",
											backgroundColor:
												this.state.filterTab === d
													? APP_COLOURS.BLUE
													: APP_COLOURS.BLACK_TRANS,
											padding: 10,
											borderRadius: 10,
											marginRight: 5,
										}}>
										<Text
											style={{
												fontSize: 12,
												color:
													this.state.filterTab === d
														? "#FFF"
														: APP_COLOURS.BG2,
												fontWeight: "500",
											}}>
											{d}
										</Text>
									</View>
								</TouchableOpacity>
							))}
					</View>
				</ScrollView>
			</View>
		);
	}
}
