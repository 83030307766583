import React, { Component } from "react";
import { View, Text, TouchableOpacity, TextInput } from "react-native";
import { emailPresentation } from "../../../CRUDHELPER";
import { APP_COLOURS } from "../../../APP_VARS";
import moment from "moment";

function uuid() {
	return "xxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16).toUpperCase();
	});
}

export default class EmailPresentation extends Component {
	constructor(props) {
		super(props);
		this.state = { recipients: [] };
	}

	emailProposal_ = async (_id) => {
		let stri_ = "";
		let recipients = this.state.recipients;
		recipients.map((d) => (stri_ = `${stri_}<${d}>;`));

		let readId = await uuid();

		let host = "http://presentations.tetrice.co.za/presentation";
		// let host = 'http://localhost:3001/presentation';

		let obj = {
			recipients: stri_,
			subject: `${this.props.proposalData.title}`,
			subject2: `${this.props.proposalData.title2}`,
			body: `${this.props.proposalData.description}`,
			proposal_id: this.props.proposalData._id,
			representative: this.props.user_data.username,
			date: moment().toISOString(),
			readId: readId,
			presentation_link: `${host}/${this.props.proposalData._id}`,
			expiry: moment(this.props.proposalData.end_date).diff(
				moment(),
				"seconds",
			),
			expiresIn: moment(this.props.proposalData.end_date).toISOString(),
			status: {
				read: false,
				readDate: "",
			},
		};

		this.inputRef.clear();

		// console.log(obj);
		// return;

		emailPresentation(obj, () => {
			this.props.sentEmail();
		});
	};

	render() {
		return (
			<View style={{ flex: 1 }}>
				<View
					style={{
						width: "100%",
					}}>
					{[""].map((d, i) => (
						<TextInput
							ref={(ref) => (this.inputRef = ref)}
							style={{
								width: "100%",
								padding: 10,
								borderRadius: 10,
								marginBottom: 5,

								color: "#FFF",
								fontSize: 16,

								textAlign: "center",
								backgroundColor: APP_COLOURS.BG4,
							}}
							numberOfLines={1}
							placeholderTextColor={"#000"}
							clearButtonMode='while-editing'
							autoCorrect={true}
							placeholder={`Email`}
							onChangeText={(text) => {
								let rec = this.state.recipients;
								rec[i] = text;
								this.setState({
									recipients: rec,
								});
							}}
						/>
					))}
				</View>

				<TouchableOpacity
					style={{
						// width: 120,
						// height: 40,
						padding: 10,
						alignItems: "center",
						justifyContent: "center",
						borderRadius: 13,
						backgroundColor: APP_COLOURS.BLUE,
						paddingHorizontal: 20,
					}}
					onPress={() => {
						this.emailProposal_();
						// console.log(this.state.recipients);
					}}>
					<Text
						style={{
							fontSize: 16,
							marginTop: 2,
							fontWeight: "600",
							color: "#FFF",
						}}>
						Send
					</Text>
				</TouchableOpacity>
			</View>
		);
	}
}
