import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	TextInput,
	LayoutAnimation,
	Keyboard,
	Alert,
	Modal,
	Animated,
	ActivityIndicator,
	ScrollViewBase,
} from "react-native";
import moment from "moment";

import {
	APP_COLOURS,
	CustomLayoutSpring,
	Avatars,
	AppIcons,
	TaskStatus,
	StatusColour,
	PROJECTSTATUS,
	// SocialIcon,
} from "../../APP_VARS";
// import ProgressBarStages from "./ProgressBar";
// import AutoHeightImage from "../AutoHeightImageComp";

function isOdd(num) {
	return num % 2;
}

export default class ContentCalendarItem extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		let d = this.props.data;

		let size = 16;

		let postSize = 180;

		return (
			<View
				style={{
					flex: 1,
					width: "100%",
					// maxWidth: 500,
					marginRight: 10,
					// minWidth: 350,

					flexDirection: "row",

					borderRadius: 8,

					marginBottom: 50,
					backgroundColor: "#000",
					borderRadius: 8,
				}}>
				<View
					style={{
						flex: 1,
						flexDirection: "row",
						alignItems: "center",

						backgroundColor: APP_COLOURS.BG,
						padding: 20,
						borderRadius: 8,
					}}>
					<View
						style={{
							flex: 1,
							// flexDirection: "row",
							width: "100%",
							backgroundColor: "#000",
						}}>
						<View
							style={{
								// width: this.props.screenWidth * 0.4,
								flex: 1,
								// flexDirection: "row",
							}}>
							<Text
								allowFontScaling={false}
								style={{
									fontWeight:
										this.state.selectedProject === d.id
											? "700"
											: "500",
									fontSize: 18,
									color: APP_COLOURS.BG2,
								}}>
								{d.title}
							</Text>

							<View
								style={{
									// width: "100%",
									// flexDirection: "row",
									backgroundColor: "#FFF",
									// maxWidth: postSize * 1.1,
								}}>
								<ScrollView horizontal>
									{d.assets.map((ass) => {
										if (ass.type === "img") {
											return (
												<Image
													source={{
														uri: ass.link,
													}}
													style={{
														height: postSize,
														width: postSize,
														resizeMode: "cover",
													}}
												/>
											);
										}
									})}
								</ScrollView>
							</View>

							<View style={{ padding: 10, flex: 1 }}>
								<View
									style={{
										flexDirection: "row",
									}}>
									<View
										style={{
											width: 60,
											backgroundColor: "#FFF",
											height: 60,
											borderRadius: 15,
											overflow: "hidden",
											alignItems: "center",
											justifyContent: "center",
											marginRight: 10,
										}}>
										<View
											style={{
												backgroundColor:
													APP_COLOURS.RED2,
												width: "100%",
												padding: 3,
												alignItems: "center",
												justifyContent: "center",
											}}>
											<Text
												style={{
													color: "#FFF",
													fontWeight: "500",
													fontSize: 14,
												}}>
												{moment(
													d.date,
													"DD/MM/YYYY",
												).format("MMM")}
											</Text>
										</View>
										<View
											style={{
												flex: 1,
												width: "100%",
												justifyContent: "center",
												alignItems: "center",
											}}>
											<Text
												style={{
													color: APP_COLOURS.BG2,
													fontWeight: "800",
													fontSize: 22,
												}}>
												{moment(
													d.date,
													"DD/MM/YYYY",
												).format("DD")}
											</Text>
										</View>
									</View>
									<View
										style={{
											justifyContent: "center",
											// alignItems: "center",
											backgroundColor: "#FFF",
											padding: 5,
											borderRadius: 10,
											flex: 1,
										}}>
										<View
											style={{
												// width: 150,
												// justifyContent: "center",
												// alignItems: "center",
												paddingRight: 2,
											}}>
											<Text
												allowFontScaling={false}
												style={{
													fontWeight: "500",
													fontSize: 19,
													color: APP_COLOURS.BG2,
												}}>
												{d.channel}
											</Text>
											<Text
												allowFontScaling={false}
												style={{
													fontWeight: "300",
													fontSize: 12,
													color: APP_COLOURS.BG2,
												}}>
												{d.handle}
											</Text>
											{/* <Text
												allowFontScaling={false}
												style={{
													fontWeight: "700",
													fontSize: 14,
													// color: TaskStatus(d.status),
													// opacity: 0.8,
												}}>
												{moment(
													d.publishDate,
												).fromNow()}
											</Text> */}
											<Text
												allowFontScaling={false}
												style={{
													fontWeight:
														this.state
															.selectedProject ===
														d.id
															? "700"
															: "300",
													fontSize: 15,
													color: APP_COLOURS.BG2,
													// opacity: 0.8,
												}}>
												{typeof d.publishDate !==
													"undefined" &&
												d.publishDate !== ""
													? `${moment(
															d.publishDate,
													  ).format(
															"DD/MM/YY HH:mm:ss",
													  )}`
													: ""}
											</Text>
										</View>
									</View>
								</View>

								<Text
									style={{
										color: APP_COLOURS.BG2,
										fontWeight: "400",
										marginBottom: 5,
										marginTop: 20,
										opacity: 0.6,
										fontSize: 12,
									}}>
									Copy:
								</Text>
								<Text
									style={{
										fontWeight: "400",
										fontSize: 16,
										color: APP_COLOURS.BG2,
										padding: 15,
										backgroundColor: "#FFF",
										borderRadius: 10,
										maxWidth: 500,
									}}>
									<Text
										style={{
											fontWeight: "600",
										}}>
										{d.handle}{" "}
									</Text>
									{d.copy}
								</Text>
								<Text
									style={{
										color: APP_COLOURS.BG2,
										fontWeight: "700",
										marginBottom: 5,
										marginTop: 20,
										opacity: 0.6,
										fontSize: 12,
									}}>
									Hash tags:
								</Text>
								{d.hashtags.map((dd) => (
									<Text
										style={{
											fontWeight: "400",
											fontSize: 14,
											color: APP_COLOURS.BLUE,
											padding: 15,
											backgroundColor: "#FFF",
											borderRadius: 10,
											maxWidth: 500,
										}}>
										{dd}
									</Text>
								))}
								<Text
									style={{
										color: APP_COLOURS.BG2,
										fontWeight: "700",
										marginBottom: 5,
										marginTop: 20,
										opacity: 0.6,
										fontSize: 12,
									}}>
									Tags:
								</Text>
								{d.tags.map((dd) => (
									<Text
										style={{
											fontWeight: "400",
											fontSize: 14,
											color: APP_COLOURS.BG2,
										}}>
										{dd}
									</Text>
								))}
							</View>
						</View>

						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "row",
							}}>
							<View
								style={{
									width: 35,
								}}>
								{/* <Image
									style={{
										width: 30,
										height: 30,
										borderRadius: 15,
										resizeMode: "contain",
									}}
									source={SocialIcon(d.channel)}
								/> */}
							</View>

							<View
								style={{
									width: 140,
								}}></View>
						</View>

						<View
							style={{
								flexDirection: "row",
								// marginRight: 20,
								alignItems: "center",
								// display: 'none',
								// width:
								//   d.assignment.length > 3
								//     ? 4 * (size / 1.5)
								//     : d.assignment.length * (size / 1.5),
								width: 60,
							}}>
							{d.assignment
								.filter((d) => d.id !== this.props.user_data.id)
								.slice(0, 3)
								.map((ass, i) => {
									return (
										<View
											key={i}
											style={{
												flexDirection: "row",
												justifyContent: "flex-start",
												alignItems: "center",

												width: size / 2,
												shadowColor: "#000",
												shadowOffset: {
													width: 0,
													height: 8,
												},
												shadowOpacity: 0.83,
												shadowRadius: 10.65,
												elevation: 8,
											}}>
											<Image
												// source={avatars[parseInt(ass.avatar)]}
												source={
													Avatars[
														parseInt(ass.avatar)
													].pp
												}
												style={{
													width: size * 1.2,
													height: size * 1.2,
													borderRadius: size,
													resizeMode: "contain",
													backgroundColor: "#FFF",
												}}
											/>
										</View>
									);
								})}
							{d.assignment.length > 3 ? (
								<View
									style={{
										backgroundColor: "#252525",
										flexDirection: "row",
										justifyContent: "center",
										alignItems: "center",
										position: "absolute",
										left: size * 1.5,
										width: size,
										height: size,

										borderRadius: 15,
									}}>
									<Text
										style={{
											color: "#FFF",
											fontSize: 9,
										}}>
										+{d.assignment.length - 3}
									</Text>
								</View>
							) : null}
						</View>
					</View>
				</View>

				<View
					style={{
						flex: 1,

						alignItems: "center",
						padding: 20,
					}}>
					<View
						style={{
							flexDirection: "row",
						}}>
						<View
							style={{
								padding: 20,
								borderRadius: 2,
								backgroundColor: d.approval
									? APP_COLOURS.WHITE_TRANSPARENT6
									: APP_COLOURS.GREENCOMPLETE,
								marginRight: 15,
								justifyContent: "center",
								alignItems: "center",
							}}>
							<Text
								allowFontScaling={false}
								style={{
									color: APP_COLOURS.BG2,
									fontWeight: "400",

									fontSize: 13,
								}}>
								{d.approval ? "" : "Approved"}
							</Text>
						</View>

						<View
							style={{
								width: 80,
								justifyContent: "center",
								alignItems: "center",
								borderLeftWidth: 1,
								paddingLeft: 5,
								borderLeftColor: "#E8E8E8",
							}}>
							<View
								style={{
									flexDirection: "row",
									width: "100%",
								}}>
								{[
									"Planning",
									"Design",
									"Copy",
									"Awaiting Approval",
									"Approved",
									"Scheduled",
									"Published",
								].map((p, i) => {
									let current = d.stage === i + 1;
									let pending = d.stage < i + 1;
									let complete = d.stage > i + 1;
									return (
										<View
											style={{
												width: 80,
												display:
													complete || pending
														? "none"
														: "flex",
												justifyContent: "center",
												alignItems: "center",
												backgroundColor: TaskStatus(
													d.stage,
												),
												padding: 20,
											}}>
											<Text
												style={{
													fontSize: 13,
													fontWeight: current
														? "700"
														: "300",
												}}>
												{p}
											</Text>
										</View>
									);
								})}
							</View>
						</View>
					</View>
				</View>

				<View
					style={{
						flex: 1,
						marginVertical: 15,
						backgroundColor: "#000",
					}}>
					{/* <ProgressBarStages
									progress={`${(d.stage / 7) * 100}%`}
									inProgress={true}
									color={TaskStatus(d.status)}
								/> */}

					<View
						style={{
							padding: 10,
						}}>
						<View style={{}}>
							<Text
								style={{
									fontWeight: "400",
									fontFamily: "Gotham",
									fontSize: 16,
									color: "#FFF",
									marginTop: 20,
									backgroundColor: "#000",
									borderRadius: 8,
									width: "100%",
									textAlign: "center",
								}}>
								Post image/video
							</Text>

							<View
								style={{
									marginTop: 5,
									marginVertical: 5,
									flexDirection: "row",
								}}>
								<TouchableOpacity
									style={{
										flex: 1,
										// backgroundColor:
										// 	approvePost === "false"
										// 		? "#FF4E24"
										// 		: "transparent",
										borderWidth: 1,
										borderColor: "#FF4E24",
										padding: 15,
										borderRadius: 10,
										justifyContent: "center",
										alignItems: "center",
										marginVertical: 5,
										flexDirection: "row",
										marginRight: 5,
									}}
									onPress={() => {
										// this.toggleApproval(
										// 	"approvePost",
										// 	"false",
										// );
									}}>
									<Text
										style={{
											fontSize: 16,
											marginTop: 2,
											fontWeight: "500",
											color: "#FFF",
										}}>
										Reject
									</Text>
								</TouchableOpacity>

								<TouchableOpacity
									style={{
										flex: 1,
										// backgroundColor:
										// 	approvePost === "true"
										// 		? "#0098FD"
										// 		: "transparent",
										borderWidth: 1,
										borderColor: "#0098FD",
										padding: 15,
										borderRadius: 10,
										justifyContent: "center",
										alignItems: "center",
										marginVertical: 5,
										flexDirection: "row",
									}}
									onPress={() =>
										this.toggleApproval(
											"approvePost",
											"true",
										)
									}>
									<Text
										style={{
											fontSize: 16,
											marginTop: 2,
											fontWeight: "500",
											color: "#FFF",
										}}>
										Approve
									</Text>
								</TouchableOpacity>
							</View>
						</View>

						<View style={{}}>
							<Text
								style={{
									fontWeight: "400",
									fontFamily: "Gotham",
									fontSize: 16,
									color: "#FFF",
									marginTop: 20,
									backgroundColor: "#000",
									borderRadius: 8,
									width: "100%",
									textAlign: "center",
								}}>
								Copy & Hash tags
							</Text>

							<View
								style={{
									marginTop: 5,
									marginVertical: 5,
									flexDirection: "row",
								}}>
								<TouchableOpacity
									style={{
										flex: 1,
										// backgroundColor:
										// 	approvedCopy === "false"
										// 		? "#FF4E24"
										// 		: "transparent",
										borderWidth: 1,
										borderColor: "#FF4E24",
										padding: 15,
										borderRadius: 10,
										justifyContent: "center",
										alignItems: "center",
										marginVertical: 5,
										flexDirection: "row",
										marginRight: 5,
									}}
									onPress={() => {
										// this.toggleApproval(
										// 	"approvedCopy",
										// 	"false",
										// );
									}}>
									<Text
										style={{
											fontSize: 16,
											marginTop: 2,
											fontWeight: "500",
											color: "#FFF",
										}}>
										Reject
									</Text>
								</TouchableOpacity>

								<TouchableOpacity
									style={{
										flex: 1,
										// backgroundColor:
										// 	approvedCopy === "true"
										// 		? "#0098FD"
										// 		: "transparent",
										borderWidth: 1,
										borderColor: "#0098FD",
										padding: 15,
										borderRadius: 10,
										justifyContent: "center",
										alignItems: "center",
										marginVertical: 5,
										flexDirection: "row",
									}}
									onPress={() => {
										// this.toggleApproval(
										// 	"approvedCopy",
										// 	"true",
										// );
									}}>
									<Text
										style={{
											fontSize: 16,
											marginTop: 2,
											fontWeight: "500",
											color: "#FFF",
										}}>
										Approve
									</Text>
								</TouchableOpacity>
							</View>
						</View>

						<View style={{}}>
							<Text
								style={{
									fontWeight: "400",
									fontFamily: "Gotham",
									fontSize: 16,
									color: "#FFF",
									marginTop: 20,
									backgroundColor: "#000",
									borderRadius: 8,
									width: "100%",
									textAlign: "center",
								}}>
								Mentions
							</Text>

							<View
								style={{
									marginTop: 5,
									marginVertical: 5,
									flexDirection: "row",
								}}>
								<TouchableOpacity
									style={{
										flex: 1,
										// backgroundColor:
										// 	approvedTags === "false"
										// 		? "#FF4E24"
										// 		: "transparent",
										borderWidth: 1,
										borderColor: "#FF4E24",
										padding: 15,
										borderRadius: 10,
										justifyContent: "center",
										alignItems: "center",
										marginVertical: 5,
										flexDirection: "row",
										marginRight: 5,
									}}
									onPress={() => {
										// this.toggleApproval(
										// 	"approvedTags",
										// 	"false",
										// );
									}}>
									<Text
										style={{
											fontSize: 16,
											marginTop: 2,
											fontWeight: "500",
											color: "#FFF",
										}}>
										Reject
									</Text>
								</TouchableOpacity>

								<TouchableOpacity
									style={{
										flex: 1,
										// backgroundColor:
										// 	approvedTags === "true"
										// 		? "#0098FD"
										// 		: "transparent",
										borderWidth: 1,
										borderColor: "#0098FD",
										padding: 15,
										borderRadius: 10,
										justifyContent: "center",
										alignItems: "center",
										marginVertical: 5,
										flexDirection: "row",
									}}
									onPress={() => {
										// this.toggleApproval(
										// 	"approvedTags",
										// 	"true",
										// );
									}}>
									<Text
										style={{
											fontSize: 16,
											marginTop: 2,
											fontWeight: "500",
											color: "#FFF",
										}}>
										Approve
									</Text>
								</TouchableOpacity>
							</View>
						</View>

						<View style={{}}>
							<Text
								style={{
									fontWeight: "400",
									fontFamily: "Gotham",
									fontSize: 16,
									color: "#FFF",
									marginTop: 20,
									backgroundColor: "#000",
									borderRadius: 8,
									width: "100%",
									textAlign: "center",
								}}>
								Date of post
							</Text>

							<View
								style={{
									marginTop: 5,
									marginVertical: 5,
									flexDirection: "row",
								}}>
								<TouchableOpacity
									style={{
										flex: 1,
										// backgroundColor:
										// 	approvedDate === "false"
										// 		? "#FF4E24"
										// 		: "transparent",
										borderWidth: 1,
										borderColor: "#FF4E24",
										padding: 15,
										borderRadius: 10,
										justifyContent: "center",
										alignItems: "center",
										marginVertical: 5,
										flexDirection: "row",
										marginRight: 5,
									}}
									onPress={() => {
										this.toggleApproval(
											"approvedDate",
											"false",
										);
									}}>
									<Text
										style={{
											fontSize: 16,
											marginTop: 2,
											fontWeight: "500",
											color: "#FFF",
										}}>
										Reject
									</Text>
								</TouchableOpacity>

								<TouchableOpacity
									style={{
										flex: 1,
										// backgroundColor:
										// 	approvedDate === "true"
										// 		? "#0098FD"
										// 		: "transparent",
										borderWidth: 1,
										borderColor: "#0098FD",
										padding: 15,
										borderRadius: 10,
										justifyContent: "center",
										alignItems: "center",
										marginVertical: 5,
										flexDirection: "row",
									}}
									onPress={() => {
										// this.toggleApproval(
										// 	"approvedDate",
										// 	"true",
										// );
									}}>
									<Text
										style={{
											fontSize: 16,
											marginTop: 2,
											fontWeight: "500",
											color: "#FFF",
										}}>
										Approve
									</Text>
								</TouchableOpacity>
							</View>
						</View>

						<View style={{}}>
							<View
								style={{
									marginTop: 20,
									marginVertical: 5,
								}}>
								<Text
									style={{
										fontWeight: "400",
										fontFamily: "Gotham",
										fontSize: 12,
										color: "#0098FD",
										marginBottom: 4,
										position: "absolute",
										top: -12,
										left: 9,
										zIndex: 99,
										backgroundColor: "#000",
										padding: 4,
										borderRadius: 8,
									}}>
									Add comment
								</Text>

								<TextInput
									style={{
										fontSize: 16,
										color: "#FFF",
										fontWeight: "400",
										fontFamily: "Gotham",
										width: "100%",
										paddingLeft: 10,
										padding: 13,
										borderWidth: 1,
										borderColor: "#0098FD",
										borderRadius: 7,
										borderWidth: 1,
										minHeight: 30,
									}}
									placeholderTextColor={"#797979"}
									autoCapitalize='sentences'
									multiline
									// numberOfLines={commentLines}
									clearButtonMode='while-editing'
									// autoCompleteType="off"
									placeholder={`Add comment`}
									onChangeText={(text) => {
										// let commentLines =
										// 	text.length / 50;
										// console.log();
										// commentLines =
										// 	commentLines + 1;
										// this.setState({
										// 	comment: text,
										// 	commentLines:
										// 		commentLines.toFixed(
										// 			0,
										// 		),
										// });
									}}
								/>
							</View>
						</View>

						<TouchableOpacity
							style={{
								borderRadius: 10,
								justifyContent: "center",
								alignItems: "center",
								marginVertical: 25,
								flexDirection: "row",
								overflow: "hidden",
							}}
							onPress={() => {
								this.props.handleApproval(this.state);
							}}>
							<Image
								source={require("./button.svg").default}
								style={{
									width: "101%",
									height: 45,
									resizeMode: "cover",
								}}
							/>

							<Text
								style={{
									fontSize: 16,
									marginTop: 2,
									fontWeight: "600",
									color: "#FFF",
									position: "absolute",
								}}>
								Save & Send
							</Text>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		);
	}
}
