import React, { Component } from "react";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
// import moment from "moment";
// import Fade from "react-reveal/Fade";
import Font from "react-font";
import memberBadge from "../../../assets/images/memberBadge.svg";
import logo from "../../../assets/images/whiteLogo.png";
import tick from "./../../../assets/images/tick.png";
import bin from "./../../../assets/images/bin.png";
import close from "../../../assets/images/close.png";
import moment from "moment";
import Add from "./Add";
import {
	crudCreate,
	crudCreateWithBase64,
	crudDelete,
	crudRead,
	crudReadAll,
	crudUpdate,
} from "../../CRUDHELPER";
import UsersSelectList from "../UsersSelectList";
import { Avatars } from "../../APP_VARS";

export default class BrandCrud extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fontSize: -3,
			showDelete: false,
			userGroups: [],

			addNewBrand: false,
			selectedUserGroup: 1,
			selecteedUserGroupName: "Staff",
			brands: [],
			filterByName: "",
			showModalArea: "",
			user_data: "",
		};
	}

	addNewBrand = () => {
		return (
			<Add
				addBrand={(d) => this.addBrand(d)}
				user_data={this.state.user_data}
				close={() =>
					this.setState({
						addNewBrand: false,
					})
				}
			/>
		);
	};

	addBrand = async (d) => {
		this.setState({
			addingBrand: true,
			errorLogin: false,
		});

		crudCreateWithBase64("brands", d, () => {
			this.setState({
				addingBrand: false,
				errorLogin: false,
				loading: false,
			});

			this.getBrands();
		});
	};

	getBrands = async () => {
		await this.setState({
			loading: true,
			brands: [],
			showDelete: false,
			addNewBrand: false,
			viewBrandProfile: false,
		});

		crudReadAll("brands", (status, response) => {
			if (status === 200) {
				console.log(response);
				this.setState({
					brands: response,
					loading: false,
				});
			} else {
				this.setState({ loading: false });
			}
		});
	};

	deleteBrand = async (d) => {
		crudDelete("brands", d, () => {
			this.getBrands();
		});
	};

	BrandProfileModal = (d) => {
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						// justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<ScrollView>
						<View
							style={{
								height: 90,
							}}
						/>
						<View
							style={{
								width: "100%",
								maxWidth: 500,
								// height: "80%",
								backgroundColor: "#FFF",
								borderRadius: 20,
								// justifyContent: "center",
								// alignItems: "center",
							}}>
							<View
								style={{
									position: "absolute",
									zIndex: 99,
									top: 10,
									left: 10,
								}}>
								<TouchableOpacity
									onPress={() =>
										this.setState({
											viewBrandProfile: false,
										})
									}>
									<Image
										source={close}
										style={{
											width: 20,
											height: 20,
											resizeMode: "contain",
										}}
									/>
								</TouchableOpacity>
							</View>
							<View
								style={{
									width: "100%",
									padding: 20,
									height: "auto",
								}}>
								<View
									style={{
										justifyContent: "center",
										alignItems: "center",
										padding: 20,
									}}>
									<View
										style={{
											width: 110,
											height: 110,
											alignItems: "center",
											justifyContent: "center",
											borderRadius: 55,
											borderWidth: 1,
											borderColor: "#797979",
											backgroundColor: "#797979",
											shadowColor: "#000",
											shadowOffset: {
												width: 0,
												height: 2,
											},
											shadowOpacity: 0.25,
											shadowRadius: 7.84,
											elevation: 12,
										}}>
										<Image
											source={logo}
											style={{
												width: 70,
												height: 70,
												resizeMode: "contain",
											}}
										/>
									</View>
								</View>

								<Text
									allowFontScaling={false}
									style={{
										fontSize: 20,
										fontFamily: "Gotham",
										textAlign: "center",
										fontWeight: "600",
										marginBottom: 20,
									}}>
									{d.first_name} {d.last_name}
								</Text>

								<View
									style={{
										marginTop: 15,
									}}>
									<View
										style={{
											flex: 1,
											paddingLeft: 15,
										}}>
										<Text
											adjustsFontSizeToFit
											style={{
												color: "#000",
												fontSize: 10,
											}}>
											<Font
												weight={600}
												family='Montserrat'>
												Contacts
											</Font>
										</Text>
									</View>
									<View
										style={{
											padding: 15,
											paddingRight: 50,
										}}>
										<View
											style={{
												width: "auto",
												marginBottom: 25,
											}}>
											<View
												style={{
													// width: col1,
													flexDirection: "row",
													alignItems: "center",
													marginBottom: 5,
												}}>
												<Text
													style={{
														fontSize: 10,
														marginRight: 3,
														opacity: 0.6,
													}}>
													email:
												</Text>
												{d.userActive ? (
													<Image
														source={tick}
														style={{
															width: 12,
															height: 12,
															// borderRadius: 25,
															resizeMode:
																"contain",
															marginRight: 5,
														}}
													/>
												) : (
													<View />
												)}
											</View>

											<Text
												style={{
													width: 200,
													fontSize: 15,
													marginBottom: 10,
												}}>
												{d.username}
											</Text>

											<Text
												style={{
													fontSize: 10,
													marginBottom: 5,
													opacity: 0.6,
												}}>
												created at:
											</Text>
											<Text
												style={{
													fontSize: 13,
												}}>
												{moment(d.date_created).format(
													"MMM DD, YYYY",
												)}
											</Text>
										</View>
									</View>
								</View>

								<TouchableOpacity
									onPress={() => this.deleteBrand(d)}
									style={{
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Image
										source={bin}
										style={{
											width: 22,
											height: 22,
											resizeMode: "contain",
											marginRight: 5,
										}}
									/>
								</TouchableOpacity>
							</View>
						</View>
					</ScrollView>
				</View>
			</Modal>
		);
	};

	render() {
		let col1 = 200,
			col2 = 100,
			col3 = 50;

		let fs = 12;

		// .filter((d_) => d_.user_type == this.state.selectedUserGroup)

		let brands = this.state.brands.filter((d_) => {
			let filter = d_;

			if (
				typeof this.state.filterByName !== "undefined" &&
				this.state.filterByName !== ""
			) {
				let text_ = this.state.filterByName;
				var outString = text_.replace(
					/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
					"",
				);

				let join_names = `${d_.title} ${d_.description}`;

				return join_names
					.toLowerCase()
					.includes(outString.toLowerCase());
			}

			return filter;
		});

		// .sort((a, b) => b.last_name < a.last_name);

		return (
			<View
				style={{
					flex: 1,
					padding: 8,
					// width: "100%",
					// height: this.props.screenHeight,
				}}>
				<View
					style={{
						flex: 1,
						// flexDirection: 'row',
						// marginTop: 25,
						width: "100%",
					}}>
					<View
						style={{
							// width: 220,
							// paddingLeft: 0,
							// flex: 1,
							paddingRight: 8,
							flexDirection: "row",
							// alignItems: "center",
						}}>
						<View
							style={{
								flexDirection: "row",
								flex: 1,
								alignItems: "center",
								// justifyContent: "flex-end",
								// paddingTop: 5,
								marginBottom: 5,
							}}>
							<TouchableOpacity
								style={{
									// width: 130,
									height: "auto",
									alignItems: "center",
									borderRadius: 10,
									marginRight: 10,
									height: "auto",
									backgroundColor: APP_COLOURS.BLUE,
									flexDirection: "row",
								}}
								onPress={async () => {
									await this.setState({
										addNewBrand: true,
									});
									await this.setState({
										addNewBrand: true,
									});
								}}>
								<View
									style={{
										flex: 1,
										flexWrap: "wrap",
										padding: 12,
										borderRadius: 10,
									}}>
									<Text
										numberOfLines={2}
										style={{
											fontFamily: "Gotham",
											fontSize: 13,
											// opacity: 0.8,
											flex: 1,
											flexWrap: "wrap",
											fontWeight: "600",
											color: APP_COLOURS.WHITE,
										}}>
										+ Brand
									</Text>
								</View>
							</TouchableOpacity>
							<TextInput
								ref={(ref) => {
									this.filterByName = ref;
								}}
								style={{
									padding: 8,
									fontSize: 16,
									borderRadius: 9,
									color: APP_COLOURS.WHITE,
									fontWeight: "200",
									fontFamily: "Gotham",
									borderWidth: 0.3,
									borderColor: APP_COLOURS.BG3,
									backgroundColor: APP_COLOURS.BG3,
									marginRight: 5,
									paddingLeft: 15,
								}}
								placeholderTextColor={"#797979"}
								autoCapitalize='words'
								clearButtonMode='while-editing'
								editable={true}
								placeholder={`Search brands `}
								onChangeText={(text) => {
									this.setState({
										filterByName: text,
									});
								}}
							/>
						</View>
					</View>
					<View
						style={{
							// flex: 1,
							paddingRight: 8,
							borderTopColor: "#797979",
							borderTopWidth: 0.3,
							width: "100%",
						}}>
						<View
							style={{
								flex: 1,
								// height: this.props.screenHeight - 60,
								// padding: 8,
								paddingTop: 8,
								width: "100%",
							}}>
							<ScrollView>
								{brands.map((d, i) => (
									<View
										key={i}
										onMouseEnter={() =>
											this.setState({
												hover: i,
											})
										}
										onMouseLeave={() =>
											this.setState({
												hover: -1,
											})
										}
										style={{
											// flex: 1,
											borderRadius: 5,
											flexDirection: "row",
											borderColor: "#C5C5C5",
											borderWidth:
												this.state.showDelete === d.id
													? 1
													: 0,
											backgroundColor:
												"rgba(255, 255, 255, 0.05)",
											marginBottom: 5,
											padding: 10,
											width: "100%",
										}}>
										<View
											style={{
												borderWidth: 2,
												borderColor: "#FFF",
												borderRadius: 10,
												padding: 10,
												backgroundColor: "#FFF",
												marginRight: 15,
											}}>
											<Image
												source={{
													uri: `${d.imageData.url}`,
												}}
												style={{
													height: 60,
													width: 100,
													resizeMode: "contain",
												}}
											/>
										</View>

										<View
											style={{
												flex: 1,
												opacity:
													this.state.hover === i
														? 1
														: 0.9,

												height: "auto",
												padding: 10,
												// alignItems: "center",

												marginRight: 10,
												height: "auto",
												marginBottom: 2,
												// flexDirection: "row",
											}}>
											<Text
												style={{
													// width: col1,
													fontWeight: "600",
													fontSize: 15,
													color: APP_COLOURS.BG2,
												}}>
												{d.title}
											</Text>

											<View style={{}}>
												<View
													style={
														{
															// width: col1,
															// flexDirection: "row",
															// alignItems: "center",
														}
													}>
													<Text
														style={{
															fontSize: 9,
															color: APP_COLOURS.WHITE,
															opacity: 0.7,
														}}>
														description
													</Text>

													<Text
														style={{
															fontSize: 12,
															color: APP_COLOURS.WHITE,
														}}>
														{d.description}
													</Text>
												</View>
											</View>
											<View style={{}}>
												<Text
													style={{
														fontSize: 9,
														color: APP_COLOURS.WHITE,
														opacity: 0.7,
													}}>
													created at:
												</Text>
												<Text
													style={{
														fontSize: 12,
														color: APP_COLOURS.WHITE,
													}}>
													{moment(
														d.date_created,
													).format("MMM DD, YYYY")}
												</Text>
											</View>

											<TouchableOpacity
												onPress={(event) => {
													const layout =
														event.nativeEvent;
													let x = layout.pageX;
													let y = layout.pageY;
													console.log("assignment");
													this.setState({
														showModalArea:
															"assignment",

														xPos: x,
														yPos: y - 30,
														selectedBrand: d,
														selectedID: d.id,
													});
												}}>
												<View
													style={{
														width: 100,
														// backgroundColor: '#252525',
														// padding: fs / 2,
													}}>
													{typeof d.assignment !==
													"undefined" ? (
														d.assignment?.length <
														1 ? (
															<View
																style={{
																	//  flex: 1,
																	//  backgroundColor: '#252525',
																	justifyContent:
																		"center",
																	alignItems:
																		"center",
																	opacity: 0.4,
																}}>
																<Text
																	style={{
																		color: APP_COLOURS.BG2,
																		fontSize: 16,
																	}}>
																	+ Assignment
																</Text>
															</View>
														) : (
															<View
																style={{
																	flexDirection:
																		"row",
																	width:
																		d
																			.assignment
																			?.length >
																		5,
																}}>
																{d.assignment
																	.filter(
																		(d) =>
																			d.id !==
																			this
																				.state
																				.user_data
																				.id,
																	)
																	.slice(0, 5)
																	.map(
																		(
																			ass,
																			i,
																		) => {
																			return (
																				<View
																					key={
																						i
																					}
																					style={{
																						flexDirection:
																							"row",
																						justifyContent:
																							"flex-start",
																						alignItems:
																							"center",

																						width:
																							25 /
																							2,
																						shadowColor:
																							"#6494B7",
																						shadowOffset:
																							{
																								width: 0,
																								height: 4,
																							},
																						shadowOpacity: 0.23,
																						shadowRadius: 4.65,
																						elevation: 8,
																					}}>
																					<Image
																						// source={avatars[parseInt(ass.avatar)]}
																						source={
																							Avatars[
																								parseInt(
																									ass.avatar,
																								)
																							]
																								.pp
																						}
																						style={{
																							width: 25,
																							height: 25,
																							borderRadius:
																								25 /
																								2,
																							resizeMode:
																								"contain",
																							backgroundcolor:
																								APP_COLOURS.BG2,
																						}}
																					/>
																				</View>
																			);
																		},
																	)}
																{d.assignment
																	.length >
																5 ? (
																	<View
																		style={{
																			backgroundColor:
																				"#252525",
																			flexDirection:
																				"row",
																			justifyContent:
																				"center",
																			alignItems:
																				"center",
																			//   position: 'absolute',
																			right:
																				25 /
																				-2,
																			width: 25,
																			height: 25,

																			borderRadius: 15,
																		}}>
																		<Text
																			style={{
																				color: APP_COLOURS.BG2,
																				fontSize:
																					25 *
																					0.7,
																			}}>
																			+
																			{d
																				.assignment
																				.length -
																				5}
																		</Text>
																	</View>
																) : null}
															</View>
														)
													) : (
														<View
															style={{
																//  flex: 1,
																//  backgroundColor: '#252525',
																justifyContent:
																	"center",
																alignItems:
																	"center",
																opacity: 0.4,
															}}>
															<Text
																style={{
																	color: APP_COLOURS.BG2,
																	fontSize: 16,
																}}>
																+ Assignment
															</Text>
														</View>
													)}
												</View>
											</TouchableOpacity>
										</View>

										<TouchableOpacity
											disable={this.state.hover !== i}
											onPress={() => this.deleteBrand(d)}
											style={{
												justifyContent: "center",
												alignItems: "center",
											}}>
											<Image
												source={bin}
												style={{
													width: 22,
													height: 22,
													resizeMode: "contain",
													marginRight: 5,
													opacity:
														this.state.hover === i
															? 1
															: 0,
												}}
											/>
										</TouchableOpacity>
									</View>
								))}
								{this.state.loading ? (
									<View
										style={{
											flex: 1,
											justifyContent: "center",
											alignItems: "center",
											minHeight: 50,
										}}>
										<ActivityIndicator
											color={APP_COLOURS.BLUE}
										/>
									</View>
								) : null}
							</ScrollView>
						</View>
					</View>
				</View>
				{this.state.addNewBrand ? this.addNewBrand() : <View />}
				{this.state.viewBrandProfile ? (
					this.brandProfileModal(this.state.profileData)
				) : (
					<View />
				)}
				{this.state.showModalArea !== "" ? (
					<Modal
						animationType='fade'
						transparent={true}
						visible={true}>
						<View
							style={{
								width: this.props.screenWidth,
								height: this.props.screenHeight,
								backgroundColor: "rgba(52, 52, 52, 0.6)",
							}}>
							<TouchableOpacity
								activeOpacity={1}
								style={{
									width: this.props.screenWidth,
									height: this.props.screenHeight,
									backgroundColor: "rgba(52, 52, 52, 0.6)",
									zIndex: 10,
									position: "absolute",
									top: 0,
								}}
								onPress={() => {
									this.setState({
										showModalArea: "",
									});
								}}
							/>
							{this.state.showModalArea === "assignment" ? (
								<View
									style={{
										top: this.state.yPos + 10,
										left: this.state.xPos - 270,
										width: 250,
										height: 220,
										backgroundColor: APP_COLOURS.BG,
										borderRadius: 15,
										padding: 10,
										zIndex: 999,
										shadowColor: "#000",
										shadowOffset: {
											width: 0,
											height: 4,
										},
										shadowOpacity: 0.23,
										shadowRadius: 4.65,
										elevation: 8,
									}}>
									<UsersSelectList
										assignment={
											this.state.selectedBrand.assignment
										}
										assignment_ids={
											this.state.selectedBrand
												.assignment_ids
										}
										addAssignment={(ass, ids) => {
											this.setState({
												showModalArea: "",
											});
											this.addAssignment(ass, ids);
											//  console.log(ass, ids);
										}}
									/>
								</View>
							) : null}
						</View>
					</Modal>
				) : null}
			</View>
		);
	}

	addAssignment = async (assignment, assignment_ids) => {
		let brands = [...this.state.brands];
		let brandsIndex = brands.findIndex(
			(task) => task.id === this.state.selectedID,
		);
		brands[brandsIndex].assignment = assignment;
		brands[brandsIndex].assignment_ids = assignment_ids;
		console.log(JSON.stringify(brands[brandsIndex].assignment_ids));
		await this.updateItem(brands[brandsIndex]);
		await this.setState({ brands });
	};

	updateItem = (d) => {
		clearTimeout(this._timeout);
		this._timeout = setTimeout(async () => {
			this.setState({
				loading: true,
			});

			await crudUpdate("brands", d, (status, d) => {
				console.log(status, d);
				// console.log("updateItem");
				// if (status === 200) {
				// 	this.getBrands();
				// 	// return;
				// }
			});
		}, 1000);
		// return;
	};

	async componentDidMount() {
		this.getDeviceData();
	}

	getDeviceData = async () => {
		try {
			const value = await localStorage.getItem("user_data");
			if (value !== null) {
				const d = JSON.parse(value);
				await this.setState({
					user_data: d,
					accessToken: d.accessToken,
				});
				this.getBrands();
			}
		} catch (error) {
			// Error retrieving data
		}
	};
}
