import React, { Component } from "react";
import {
	View,
	Image,
	ScrollView,
	Linking,
	Text,
	TouchableOpacity,
	Modal,
	ActivityIndicator,
	TextInput,
} from "react-native";
import { APP_COLOURS, screenHeight } from "../../APP_VARS";
import Font from "react-font";

export default class TitleComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			newTitle: "",
			saveNewTitle: false,
		};
	}

	render() {
		let d = this.props.title;
		return (
			<View
				style={{
					flex: 1,
					padding: 4,
					// alignItems: "center",
					// justifyContent: "center",
					borderRadius: 10,
					paddingHorizontal: 10,

					borderColor: APP_COLOURS.DESELECTEDCOLOR,
					borderWidth: 1,
					borderColor:
						this.state.newTitle !== "" ? "#FFF" : "transparent",
					marginLeft: 10,
				}}>
				<View
					style={{
						width: "auto",

						flexDirection: "row",
						alignItems: "center",
						margin: 4,
					}}>
					<Text
						style={{
							fontFamily: "Gotham",
							fontSize: 12,
							color: APP_COLOURS.PLACEHOLDER,
							fontWeight: "500",
							marginBottom: 4,
							position: "absolute",
							top: -12,
							left: 9,
							padding: 4,
							borderRadius: 8,
						}}>
						{this.props.componentTitle}
					</Text>
					<TextInput
						style={{
							width: "100%",
							padding: 10,
							// paddingLeft: 30,
							fontSize: 20,
							borderRadius: 8,

							color: "#FFF",
							fontWeight: "600",
							fontFamily: "Gotham",
						}}
						placeholderTextColor={APP_COLOURS.BLUE2}
						// autoCapitalize="sentences"
						clearButtonMode='while-editing'
						autoCorrect={false}
						placeholder={"Page title"}
						defaultValue={typeof d !== "undefined" ? d : "Title"}
						onChangeText={async (text) => {
							// if (typeof d !== "undefined") {
							// 	this.setState({
							// 		saveNewTitle: true,
							// 	});
							// } else {
							await this.setState({
								newTitle: text,
								saveNewTitle: true,
							});
							// }
						}}
					/>

					<TouchableOpacity
						disabled={!this.state.saveNewTitle}
						onPress={async () => {
							this.props.saveTitle(this.state.newTitle);
						}}>
						<View
							style={{
								flex: 1,
								alignItems: "center",
								justifyContent: "center",
								borderRadius: 10,
								// borderWidth: 1,
								// borderColor: "#FFF",
								// height: 60,
								marginRight: 5,
								padding: 5,
								paddingHorizontal: 15,
								opacity: this.state.saveNewTitle ? 1 : 0,
							}}>
							<Text
								style={{
									fontSize: 16,
									color: APP_COLOURS.DESELECTEDCOLOR,
								}}>
								<Font family='Montserrat' weight={300}>
									Save
								</Font>
							</Text>
						</View>
					</TouchableOpacity>

					{/* <Image
															source={require("../../../assets/images/tick.png")}
															style={{
																width: 16,
																height: 16,
																resizeMode:
																	"contain",
																position:
																	"absolute",
																left: 9,
																top: 16,
															}}
														/> */}
				</View>
			</View>
		);
	}
}
