import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	Alert,
	LayoutAnimation,
	Animated,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import debbie from "../../../assets/images/cloud.png";
import bin from "../../../assets/images/bin.png";
import { APP_COLOURS } from "../../APP_VARS";
import { Avatars, TaskStatus } from "../../APP_VARS";
import Fade from "react-reveal/Fade";
import { crudCreate, sendDiscordMessage } from "../../CRUDHELPER";
import UsersSelectList from "../UsersSelectList";
import FullCalendar from "../DailyPlanner/FullCalendar";

export default class AddTask extends Component {
	constructor(props) {
		super(props);
		this.state = {
			assignment: [],
			assignment_ids: [this.props.user_data.id],
		};
	}

	addTask = async (main) => {
		let obj = {
			project_id: this.props.project_id,

			title: this.state.taskName,
			color: "#AF78FF",

			description: "",
			priority: 0,
			progress: 0,
			end_date: this.state.end_date,
			start_date: this.state.start_date,
			status: "pending",
			assignment: this.state.assignment,
			assignment_ids: this.state.assignment_ids,
		};
		// console.log(obj);
		// return;

		let user_data = JSON.parse(localStorage.getItem("user_data"));
		// 		sendDiscordMessage(
		// 			`${user_data.first_name} ${user_data.last_name}`,
		// 			`[New Task]
		// - ${obj.title}
		// - ${moment(obj.start_date).format("DD/MM")} to ${moment(obj.end_date).format(
		// 				"DD/MM",
		// 			)}
		// `,
		// 			`${window.location.origin}${Avatars[parseInt(user_data.avatar)].pp}`,
		// 			"0QPZYrY6zpAnJXqX9gQl",
		// 		);

		const mapThrough = await obj.assignment_ids
			.filter((a) => a !== this.props.user_data.id)
			.forEach(async (id) => {
				await sendDiscordMessage(
					`${user_data.first_name} ${user_data.last_name}`,
					`### *${this.props.selectedProject.brand.title} - ${
						this.props.selectedProject.title
					}*
> ${this.props.user_data.first_name} assigned you a task:
> ${obj.title}
> ${moment(obj.start_date).format("DD/MM")} to ${moment(obj.end_date).format(
						"DD/MM",
					)}
`,
					`${window.location.origin}${
						Avatars[parseInt(user_data.avatar)].pp
					}`,
					id,
				);
			});

		crudCreate("projects_tasks", obj, () => {
			this.props.reloadData();
			this.props.close();
		});
	};

	componentDidMount() {}

	render() {
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "flex-start",
						alignItems: "center",
						backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<View
						style={{
							flex: 1,
							width: "100%",
							maxWidth: 400,
							height: "auto",
							backgroundColor: "white",
							// alignItems: 'center',
							borderRadius: 12,
							paddingTop: 20,
							paddingBottom: 10,
							padding: 10,
							marginTop: 10,
						}}>
						<View
							style={{
								flexDirection: "row",
								alignItems: "center",
								marginBottom: 20,
							}}>
							<TouchableOpacity
								onPress={() => this.props.close()}>
								<Image
									source={require("../../../assets/images/close.png")}
									style={{
										width: 20,
										height: 20,
										resizeMode: "contain",
									}}
								/>
							</TouchableOpacity>
							<Text
								style={{
									color: APP_COLOURS.BG2,
									fontSize: 20,
									marginLeft: 10,
									fontWeight: "600",
									fontFamily: "Gotham",
								}}>
								Add task
							</Text>
						</View>
						<View style={{ flex: 1 }}>
							<ScrollView>
								<View
									style={{
										marginTop: 5,
										flex: 1,
										marginBottom: 10,
									}}>
									<Text
										style={{
											fontWeight: "400",
											fontFamily: "Gotham",

											fontSize: 12,
											color: APP_COLOURS.BG2,
											marginBottom: 4,
											position: "absolute",
											top: -12,
											left: 9,
											backgroundColor: "#FFF",
											padding: 4,
											borderRadius: 8,
											zIndex: 99,
										}}>
										Task title
									</Text>
									<View
										style={{
											borderRadius: 7,
											borderColor: APP_COLOURS.BG2,
											borderWidth: 1,
										}}>
										<TextInput
											returnKeyType='done'
											autoCorrect={false}
											style={{
												fontSize: 19,
												fontWeight: "500",
												color: APP_COLOURS.BG2,
												width: "100%",
												height: "auto",
												padding: 10,
											}}
											editable={true}
											onChangeText={(text) =>
												this.setState({
													taskName: text,
												})
											}
											multiline
											numberOfLines={5}
											autoCapitalize='sentences'
											placeholder='Title'
											ref='taskName'
											clearButtonMode='while-editing'
											autoFocus
										/>
									</View>
								</View>

								<View
									style={{
										width: "100%",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<View
										style={{
											width: 350,
											// display: this.state.showCalendar
											// 	? "flex"
											// 	: "none",
											justifyContent: "center",
											alignItems: "center",
										}}>
										<FullCalendar
											compWidth={350}
											calendarList={[]}
											selectedDate={
												this.state[
													this.state.dateAllocation
												]
											}
											startDate={(date) => {
												this.setState({
													start_date: date,
												});
											}}
											endDate={(date) => {
												this.setState({
													end_date: date,
												});
											}}
										/>
										<View
											style={{
												width: 350,
												flexDirection: "row",
												display: "none",
											}}>
											<View
												style={{
													marginTop: 20,
													flex: 1,
													marginRight: 10,
												}}>
												<Text
													style={{
														fontWeight: "400",
														fontFamily:
															"Avenir Next",

														fontSize: 12,
														color: APP_COLOURS.BG2,
														marginBottom: 4,
														position: "absolute",
														top: -12,
														left: 9,
														backgroundColor: "#FFF",
														padding: 4,
														borderRadius: 8,
														zIndex: 99,
													}}>
													Start date
												</Text>
												<View
													style={{
														borderRadius: 7,
														borderColor:
															APP_COLOURS.BG2,
														borderWidth: 1,
													}}>
													<TouchableOpacity
														onPress={() => {
															this.setState({
																dateAllocation:
																	"start_date",
																showCalendar:
																	!this.state
																		.showCalendar,
															});
														}}>
														<View
															style={{
																width: "100%",
																padding: 13,
																justifyContent:
																	"center",
																alignItems:
																	"center",
																paddingLeft: 10,
															}}>
															<Text
																style={{
																	color: APP_COLOURS.BG2,
																	fontWeight:
																		"600",
																	fontSize: 16,
																}}>
																{moment(
																	this.state
																		.start_date,
																).format(
																	"DD/MM/YYYY",
																)}
															</Text>
														</View>
													</TouchableOpacity>
												</View>
											</View>
											<View
												style={{
													marginTop: 20,
													flex: 1,
												}}>
												<Text
													style={{
														fontWeight: "400",
														fontFamily:
															"Avenir Next",

														fontSize: 12,
														color: APP_COLOURS.BG2,
														marginBottom: 4,
														position: "absolute",
														top: -12,
														left: 9,
														backgroundColor: "#FFF",
														padding: 4,
														borderRadius: 8,
														zIndex: 99,
													}}>
													Due date
												</Text>
												<View
													style={{
														borderRadius: 7,
														borderColor:
															APP_COLOURS.BG2,
														borderWidth: 1,
													}}>
													<TouchableOpacity
														onPress={() => {
															this.setState({
																dateAllocation:
																	"end_date",
															});
														}}>
														<View
															style={{
																width: "100%",
																padding: 13,
																justifyContent:
																	"center",
																alignItems:
																	"center",
																paddingLeft: 10,
															}}>
															<Text
																style={{
																	color: APP_COLOURS.BG2,
																	fontWeight:
																		"600",
																	fontSize: 16,
																}}>
																{moment(
																	this.state
																		.end_date,
																).format(
																	"DD/MM/YYYY",
																)}
															</Text>
														</View>
													</TouchableOpacity>
												</View>
											</View>
										</View>
									</View>
								</View>

								<View
									style={{
										width: "100%",
										marginTop: 20,
										maxHeight: 450,
									}}>
									<UsersSelectList
										assignment={this.state.assignment}
										assignment_ids={
											this.state.assignment_ids
										}
										addAssignment={(ass, ids) => {
											this.setState({
												assignment: ass,
												assignment_ids: ids,
											});
										}}
									/>
								</View>
								<View
									style={{
										// backgroundColor: 'red',
										width: "100%",
										height: 40,
										flexDirection: "row",
										justifyContent: "space-between",
										marginTop: 15,
									}}>
									<TouchableOpacity
										onPress={() => this.addTask()}
										style={{
											flex: 1,
											backgroundColor: APP_COLOURS.BG2,
											justifyContent: "center",
											borderRadius: 20,
										}}>
										<Text
											style={{
												width: "100%",
												textAlign: "center",
												color: "white",
												fontSize: 12,
												fontWeight: "600",
												padding: 5,
											}}>
											Add task
										</Text>
									</TouchableOpacity>
								</View>
							</ScrollView>
						</View>
					</View>
				</View>
			</Modal>
		);
	}
}
