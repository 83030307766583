import React, { Component } from "react";

import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import Lottie from "react-lottie";
import * as animationData from "./sending.json";
import AutoHeightImage from "../AutoHeightImageComp";
import LottieControl from "./LottieControl";

export default class FeedBackSent extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<View
				style={{
					backgroundColor: "#000",
					width: window.innerWidth,
					overflow: "hidden",
					height: window.innerHeight,
					alignItems: "center",
				}}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						padding: 5,
						paddingBottom: 100,
					}}>
					<Text
						style={{
							color: "#FFF",
							fontSize: 19,
							fontWeight: "800",
							fontFamily: "Gotham",
							opacity: 0.8,
						}}>
						Sent
					</Text>

					{/* <View style={{}}>
						<AutoHeightImage
							width={60}
							source={require("./verified.png")}
						/>
					</View> */}
					<Text
						style={{
							fontSize: 16,
							color: "#FFF",
							fontWeight: "300",
							padding: 2,
							opacity: 0.7,
							marginBottom: 20,
						}}>
						Thank you for your feedback
					</Text>

					<View style={{}}>
						{/* <Lottie
							options={{
								loop: false,
								autoplay: true,
								animationData: animationData,
								rendererSettings: {
									preserveAspectRatio: "xMidYMid slice",
								},
							}}
							height={90}
							width={90}
						/> */}
						<LottieControl animationData={animationData} />
					</View>
				</View>
			</View>
		);
	}
}
