import React, { Component } from "react";
import {
	View,
	Text,
	LayoutAnimation,
	TouchableOpacity,
	Image,
	Linking,
	TextInput,
} from "react-native";
import { APP_COLOURS, CustomLayoutSpring, Avatars } from "../APP_VARS";
import {
	crudCreate,
	crudSendEmail,
	crudUpdate,
	sendDiscordMessage,
} from "../CRUDHELPER";
import moment from "moment";
import LocationAndGeofence from "./LocationAndGeofence";
import { playAudio } from "./Notifications";
import FlashImage from "./FlashImage";

export default class StatusModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showNDANotice: false,
			done: false,
			fadeBackground: false,

			location: "",
			geoRequired: true,
			tryAgain: false,

			location: "",
			statusDescription: "",

			isAvaliable: false,
			location: "",
		};
	}

	componentDidMount() {
		this.findCoordinates();
		setTimeout(() => {
			this.setState({
				showNDANotice: true,
			});
			LayoutAnimation.configureNext(CustomLayoutSpring);
		}, 300);
	}

	findCoordinates = () => {
		console.log("Getting Location Permission");
	};

	openSettings = () => {
		this.setState({
			tryAgain: true,
		});
	};

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			this.setState({
				...this.props,
			});
		}
	}

	updateStatus = async (status, color) => {
		console.log(status, this.state.location);
		let now = moment().toISOString();
		// this.state.location?.locationUrl
		let location =
			this.state.location !== ""
				? this.state.location.withinGeofence
					? "In office"
					: "Out of office"
				: "No location";

		this.setState({
			showNDANotice: false,
		});

		crudUpdate(
			"users",
			{
				id: this.props.user_data.id,
				status: status,
				lastSeen: now,
				location: this.state.location,
				statusDescription: this.state.statusDescription,
			},
			(status, d) => {},
		);
		//
		//
		//
		// console.log(
		// 	`${window.location.origin}${
		// 		Avatars[this.props.user_data.avatar].pp
		// 	}`,
		// );
		// return;
		// if (this.props.user_data.username !== "wesley@tetrice.co.za") {
		// 		sendDiscordMessage(
		// 			`${this.props.user_data.first_name} ${this.props.user_data.last_name}`,
		// 			`## ${status}
		// ${this.state.statusDescription !== "" ? `${this.state.statusDescription}` : ""}
		// - ${location}
		// `,
		// 			`${window.location.origin}${
		// 				Avatars[this.props.user_data.avatar].pp
		// 			}`,
		// 			this.props.user_data.id,
		// 		);

		sendDiscordMessage(
			`${this.props.user_data.first_name} ${this.props.user_data.last_name}`,
			`## ${status} - ${location}
${this.state.statusDescription !== "" ? `${this.state.statusDescription}` : ""}

`,
			`${window.location.origin}${
				Avatars[this.props.user_data.avatar].pp
			}`,
			"0QPZYrY6zpAnJXqX9gQl",
		);

		// }

		// return;
		let user_data = JSON.stringify({
			...this.props.user_data,
			status: status,
		});
		const lastUpdated = await localStorage.setItem("lastUpdated", now);
		const setSession = await localStorage.getItem("user_data", user_data);

		crudCreate(
			"attendance_record",
			{
				user: {
					id: this.props.user_data.id,
					first_name: this.props.user_data.first_name,
					last_name: this.props.user_data.last_name,
				},
				status: status,
				location: this.state.location,
				statusDescription: this.state.statusDescription,
			},
			(status, d) => {},
		);

		setTimeout(() => {
			this.setState({
				done: true,
			});
			this.props.accepted(true, status);
		}, 300);
	};

	render() {
		return (
			<View
				onLayout={() => {
					setTimeout(() => {
						playAudio(7);
					}, 200);
				}}
				style={{
					position: "absolute",
					justifyContent: "center",
					alignItems: "center",
					// top: 50,
					zIndex: 999,
					top: 0,
					display: this.state.done ? "none" : "flex",
					width: this.props.screenWidth,
					height: this.props.screenHeight,
					backgroundColor: this.state.showNDANotice
						? APP_COLOURS.BACK_TRANSPARENT
						: "transparent",
				}}>
				<View
					style={{
						bottom: this.state.showNDANotice
							? 0
							: this.props.screenHeight * -1,
						position: "absolute",
						width: this.state.showNDANotice
							? this.props.screenWidth * 1
							: this.props.screenWidth * 0.8,
						marginTop: 0,
						maxWidth: 490,
						// marginLeft: 10,
						backgroundColor: "#FFF",
						borderRadius: 20,
						alignItems: "center",
						padding: 15,
						paddingBottom: 50,
					}}>
					<View
						style={{
							width: "100%",
						}}>
						<View
							style={{
								display: !this.state.isAvaliable
									? "flex"
									: "none",
							}}>
							<View
								style={{
									width: "100%",
									marginTop: 5,

									padding: 5,
								}}>
								{this.props.avatarComp}
								<View
									style={{
										alignItems: "center",
										justifyContent: "center",
									}}>
									{/* <Text
									allowFontScaling={false}
									style={{
										color: "#000",
										fontSize: 17,
										fontStyle: "normal",
										fontWeight: "900",
									}}>
									Status:
								</Text> */}
									<Text
										allowFontScaling={false}
										style={{
											color: "#000",
											fontSize: 17,
											fontStyle: "normal",
											fontWeight: "900",
										}}>
										Update your current work status
									</Text>

									{this.state.location === "" ? (
										<Text
											allowFontScaling={false}
											style={{
												color: "#000",
												fontSize: 12,
												fontStyle: "normal",
												fontWeight: "900",
											}}>
											<LocationAndGeofence
												onResult={(d) => {
													this.setState({
														location: d,
													});
													console.log(d);
												}}
											/>
										</Text>
									) : null}
								</View>
							</View>

							<View
								style={{
									borderBottomWidth: 0.5,
									borderColor: "#797979",
									width: "100%",
									margin: 10,
								}}
							/>
							<TouchableOpacity
								onPress={() => {
									this.updateStatus(
										"Check in",
										APP_COLOURS.BLUE2,
									);
								}}
								style={{
									// display: this.state.acceptPolicy ? 'flex' : 'none',

									padding: 15,
									width: "100%",

									borderRadius: 10,

									alignItems: "center",
									marginBottom: 10,
									backgroundColor: APP_COLOURS.BLUE2,
								}}>
								<Text style={{ fontSize: 15, color: "#FFF" }}>
									Starting my day
								</Text>
							</TouchableOpacity>
							<TouchableOpacity
								onPress={() => {
									this.updateStatus(
										"busy",
										APP_COLOURS.ORANGE,
									);
								}}
								style={{
									// display: this.state.acceptPolicy ? 'flex' : 'none',

									padding: 15,
									width: "100%",

									borderRadius: 10,

									alignItems: "center",
									marginBottom: 10,
									backgroundColor: APP_COLOURS.ORANGE,
								}}>
								<Text
									style={{ fontSize: 15, color: "#252525" }}>
									In meeting / Do not disturb
								</Text>
							</TouchableOpacity>

							<TouchableOpacity
								onPress={() => {
									this.updateStatus(
										"On lunch",
										APP_COLOURS.PURPLE,
									);
								}}
								style={{
									// display: this.state.acceptPolicy ? 'flex' : 'none',

									padding: 15,
									width: "100%",

									borderRadius: 10,

									alignItems: "center",
									marginBottom: 10,
									backgroundColor: APP_COLOURS.PURPLE,
								}}>
								<Text style={{ fontSize: 15, color: "#FFF" }}>
									Lunch break
								</Text>
							</TouchableOpacity>

							<TouchableOpacity
								onPress={() => {
									this.updateStatus(
										"offline",
										APP_COLOURS.RED2,
									);
								}}
								style={{
									// display: this.state.acceptPolicy ? 'flex' : 'none',

									padding: 15,
									width: "100%",

									borderRadius: 10,

									alignItems: "center",
									marginBottom: 10,
									backgroundColor: APP_COLOURS.RED2,
								}}>
								<Text style={{ fontSize: 15, color: "#FFF" }}>
									Done for the day
								</Text>
							</TouchableOpacity>

							<TouchableOpacity
								onPress={() => {
									// this.setState({
									// 	isAvaliable: true,
									// });

									this.updateStatus(
										"online",
										APP_COLOURS.GREEN,
									);
								}}
								style={{
									padding: 15,
									width: "100%",

									borderRadius: 10,

									alignItems: "center",
									marginBottom: 10,
									backgroundColor: APP_COLOURS.GREEN,
									marginTop: 50,
								}}>
								<Text
									style={{
										fontSize: 14,
										color: "#FFF",
										fontWeight: "600",
									}}>
									Avaliable
								</Text>
							</TouchableOpacity>
						</View>

						{this.state.isAvaliable ? (
							<View
								style={{
									justifyContent: "center",
									alignItems: "center",
									marginTop: 10,
									borderWidth: 0.5,
									borderColor: "#797979",
									padding: 5,
									borderRadius: 10,
								}}>
								<TextInput
									ref={(ref) => (this.inputRef = ref)}
									style={{
										width: "100%",
										paddingLeft: 10,
										paddingTop: 10,
										marginBottom: 5,
										marginTop: 0,
										maxHeight: 300,
										color: "#000",
										fontSize: 16,
										minHeight: 50,
										marginRight: 10,
										textAlign: "center",
									}}
									multiline
									autoFocus
									numberOfLines={1}
									placeholderTextColor={"#000"}
									clearButtonMode='while-editing'
									autoCorrect={true}
									placeholder={`Describe what you're currently working on`}
									onChangeText={(text) => {
										this.setState({
											statusDescription: text,
										});
									}}
								/>
								<TouchableOpacity
									disabled={
										this.state.statusDescription === ""
									}
									onPress={() => {
										this.updateStatus(
											"online",
											APP_COLOURS.GREEN,
										);
									}}
									style={{
										// display: this.state.acceptPolicy ? 'flex' : 'none',
										opacity:
											this.state.statusDescription !== ""
												? 1
												: 0.5,
										padding: 15,
										width: "100%",

										borderRadius: 10,

										alignItems: "center",
										marginBottom: 10,
										backgroundColor: APP_COLOURS.GREEN,
										marginTop: 50,
									}}>
									<Text
										style={{
											fontSize: 14,
											color: "#FFF",
											fontWeight: "600",
										}}>
										Submit
									</Text>
								</TouchableOpacity>
							</View>
						) : null}
					</View>
				</View>
			</View>
		);
	}
}
