import React, { Component } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import PageFooter from "./PageFooter";
import { APP_COLOURS } from "../APP_VARS";
import TeamTetrice from "./TeamTetrice";
import { ScrollView } from "react-native-web";

export default class TeamOverview extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<View style={{ flex: 1, padding: 1 }}>
				<ScrollView showsVerticalScrollIndicator={false}>
					<PageFooter />
					<View
						style={{
							flex: 1,
						}}>
						<TeamTetrice />
					</View>
				</ScrollView>
			</View>
		);
	}
}
