import React, { Component } from "react";
import {
	View,
	Image,
	ScrollView,
	Linking,
	Text,
	TouchableOpacity,
	Modal,
	ActivityIndicator,
	TextInput,
} from "react-native";
import LandingPage from "./LandingPage";
import logo_tetrice from "../../../assets/images/skrum_menu.png";
import { APP_COLOURS, screenHeight } from "../../APP_VARS";
import Font from "react-font";
import bin from "./../../../assets/images/bin.png";
import QuillEditor from "./Editor/QuillEditor";
import EmailPresentation from "./EmailPresentation/EmailPresentation";
import {
	crudCreateWithHtml,
	crudDeleteDocument,
	deleteProposalPage,
	fetchPresentationSharedList,
} from "../../CRUDHELPER";
import moment from "moment";

import starFill from "../../OpenUrls/Presentations/starFill.png";
import star from "../../OpenUrls/Presentations/star.png";
import AutoHeightImage from "../../AutoHeightImageComp";
import CostEstimate from "./CostEstimate";
import TitleComponent from "./TitleComponent";
// import DisclaimerPage from "./DisclaimerPage";

if (typeof window !== "undefined") {
	require("smoothscroll-polyfill").polyfill();
}

function uuid() {
	return "xxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

export default class PresentationApp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
			showSidebar: false,
			loading: true,

			pageNumber: 0,

			presentation_id: this.props.presentationData._id,
			presentationData: "",
			presentationPages: [],
			presentationPageData: "",
			lockContent: true,

			sharedList: [],

			presentationRows: [],

			showSignaturePad: false,
			base64Signature: "",

			showRow: "",

			showEditTitles: false,
		};
	}

	async componentDidMount() {
		console.log("componentDidMount", this.props.presentationData);
		// return;
		let accessToken = localStorage.getItem("accessToken");

		console.log("Approved User", accessToken);
		if (accessToken !== null) {
			// this.props.history.push("/dashboard/Home");
		} else {
			await localStorage.clear();
			await this.setState({
				loading: false,
			});
		}

		await this.fetchPresentationData();

		await window.addEventListener("resize", this.resize.bind(this));
		await this.resize();

		this.setState({
			loading: false,
			presentationData: this.props.presentationData,
			presentationRows:
				typeof this.props.presentationData?.presentationRows !==
				"undefined"
					? this.props.presentationData?.presentationRows
					: [],
		});

		// setTimeout(() => {
		this.setState({
			loading: false,
		});
		// }, 2000);
	}

	fetchPresentationData = async () => {
		const accessToken = localStorage.getItem("accessToken");

		this.setState({
			loading: true,
			presentationData: "",
			presentationPages: [],
			presentationPageData: "",
		});
		let url =
			"https://workflow.tetrice.co.za/webhook/ab860d5cc-6e8e-4542-8566-af350fe44694";
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify(this.props.presentationData),
		};
		fetch(url, requestOptions)
			.then((response) => {
				if (!response.ok) {
					throw new Error("Failed to fetch data");
				}
				return response.json();
			})
			.then((data) => {
				console.log(data);
				this.setState({
					loading: false,
					presentationData: data,
					presentationRows:
						typeof data?.presentationRows !== "undefined"
							? data?.presentationRows
							: [],
				});
			})
			.catch((error) => {
				this.setState({
					error: error,
					loading: false,
				});
			});
	};
	// fetchPresentationPages = async (_id) => {
	// 	// POST this data from the parent list or create a new presentation
	// 	let url =
	// 		"https://workflow.tetrice.co.za/webhook-test/b61608aa-7bdb-4ca0-88ce-58ce856ee0f4";
	// 	const requestOptions = {
	// 		method: "POST",
	// 		headers: { "Content-Type": "application/json" },
	// 		body: JSON.stringify({
	// 			presentation_id: _id,
	// 		}),
	// 	};
	// 	fetch(url, requestOptions)
	// 		.then((response) => {
	// 			if (!response.ok) {
	// 				throw new Error("Failed to fetch data");
	// 			}
	// 			return response.json();
	// 		})
	// 		.then((data) => {
	// 			console.log(data);
	// 			// this.setState({
	// 			// 	presentationPages: data,
	// 			// 	loading: false,
	// 			// });
	// 		})
	// 		.catch((error) => {
	// 			this.setState({
	// 				error: error,
	// 				loading: false,
	// 			});
	// 		});
	// };

	fetchSharedList = async (presentation_id) => {
		this.setState({
			sharedList: [],
		});
		let obj = {
			proposal_id: presentation_id,
		};

		fetchPresentationSharedList(obj, (s, d) => {
			if (s === 200) {
				console.log(d);
				this.setState({
					sharedList: d,
				});
			}
		});
	};

	addNewPage = async (presentation_id) => {
		try {
			const response = await fetch(
				"https://workflow.tetrice.co.za/webhook/a643d6b3-e05c-47da-a13c-77e4951c6508",
				{
					// Replace with your backend endpoint
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						Origin: "",
						dataType: "json",
					},
					body: JSON.stringify({
						html: "",
						presentation_id: presentation_id,
						title: "New Page",
					}),
				},
			)
				.then((response) => {
					if (!response.ok) {
						throw new Error("Failed to fetch data");
					}
					return response.json();
				})
				.then((data) => {
					console.log(data);
					this.fetchPresentationData();
				})
				.catch((error) => {});
		} catch (error) {
			console.error("Error saving HTML content:", error);
		}
	};

	// getLargeHtmlData = (geturl, next) => {
	// 	// POST this data from the parent list or create a new presentation
	// 	let url =
	// 		"https://workflow.tetrice.co.za/webhook/59af9ff2-5530-45b9-b4d8-fb9631c555fb";
	// 	const requestOptions = {
	// 		method: "POST",
	// 		headers: { "Content-Type": "application/json" },
	// 		body: JSON.stringify({
	// 			url: geturl,
	// 		}),
	// 	};
	// 	fetch(url, requestOptions)
	// 		.then((response) => {
	// 			if (!response.ok) {
	// 				throw new Error("Failed to fetch data");
	// 			}
	// 			return response.json();
	// 		})
	// 		.then((html) => {
	// 			return next(html);
	// 		})
	// 		.catch((error) => {
	// 			this.setState({
	// 				error: error,
	// 				loading: false,
	// 			});
	// 		});
	// };

	// deleteWithHtml = (htmlFileName, _id, d, rd, rdIn) => {
	// 	console.log(d);
	// 	this.deleteColumn(d, rd, rdIn);
	// 	// return;
	// 	deleteProposalPage(
	// 		"presentation_pages",
	// 		{
	// 			htmlFileName: htmlFileName,
	// 			_id: _id,
	// 		},
	// 		() => {
	// 			const newRows = [...this.state.presentationRows];

	// 			// Find the index of the row to delete
	// 			const rowIndexToDelete = newRows.findIndex(
	// 				(row) => row.id === d.id,
	// 			);

	// 			// Find the index of the column to delete within the row
	// 			const columnIndexToDelete = newRows[
	// 				rowIndexToDelete
	// 			]?.rowData?.findIndex((col) => col._id === rd._id);

	// 			if (rowIndexToDelete !== -1 && columnIndexToDelete !== -1) {
	// 				// Remove the column from the row
	// 				newRows[rowIndexToDelete].rowData.splice(
	// 					columnIndexToDelete,
	// 					1,
	// 				);

	// 				// Update the order of remaining columns within the row
	// 				newRows[rowIndexToDelete].rowData.forEach((col, i) => {
	// 					col.order = i;
	// 				});

	// 				// If the row has no columns left after deletion, remove the entire row
	// 				if (newRows[rowIndexToDelete].rowData.length === 0) {
	// 					newRows.splice(rowIndexToDelete, 1);
	// 				}

	// 				// Update the order of remaining rows if necessary
	// 				newRows.forEach((row, i) => {
	// 					row.order = i;
	// 				});

	// 				// Call deleteColumn to update the row data
	// 				// this.deleteColumn(d, rd, rdIn);
	// 				// console.log("this.deleteColumn(d, rd, rdIn)", d, rd, rdIn);
	// 				// return;
	// 				// Update the state with the modified presentationRows
	// 				this.setState(
	// 					{
	// 						presentationRows: newRows,
	// 					},
	// 					() => {
	// 						this.fetchPresentationData();
	// 					},
	// 				);
	// 			}
	// 		},
	// 	);
	// };

	componentDidUpdate() {}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	render() {
		const { screenWidth } = this.state;
		const isSmallScreen = screenWidth < 1200;
		// const scaleFactor = isSmallScreen ? 0.8 : 1.0;
		// const pageStyle = {
		// 	transform: `scale(${scaleFactor})`,
		// };

		let fs = window.innerWidth * 0.07;
		// let maxFont = 40;
		// let h1 = fs < maxFont ? fs : maxFont;
		// let h2 = h1 / 1.618;
		// let pp = h2 / 1.618;

		let pp = isSmallScreen ? 14 : 20;
		let h2 = pp * 1.2618;
		let h1 = h2 * 1.2618;
		let h0 = h1 * 1.2618;
		let maxFont = 40;

		let mw = 1000;

		if (this.state.loading) {
			return (
				<View
					style={{
						// width: window.innerWidth,
						height: window.innerHeight,
						backgroundColor: "#171A20",
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						position: "absolute",
						top: 0,
						zIndex: 999,
						width: "100%",
					}}>
					<Image
						source={logo_tetrice}
						style={{
							width: 350,
							height: 100,
							resizeMode: "contain",
						}}
					/>
				</View>
			);
		} else
			return (
				<View
					style={{
						width: "100%",
						height: this.state.screenHeight,
						// overflow: "hidden",
						backgroundColor: "#171A20",
						flexDirection: "row",
					}}>
					<View style={{ flex: 1 }}>
						<ScrollView
							ref={(ref) => {
								this.scrollRef = ref;
							}}
							overScrollMode='never'
							showsVerticalScrollIndicator={false}
							// pagingEnabled={true}
							// decelerationRate='fast'
							// scrollEventThrottle={160}
							// snapToInterval={this.state.screenHeight}
							contentContainerStyle={{
								alignItems: "center",

								// backgroundColor: APP_COLOURS.BACK_TRANSPARENT2,
							}}>
							<View
								style={{
									width: "100%",
									display: this.state.showEditTitles
										? "flex"
										: "none",
									paddingTop: 50,
								}}>
								<TitleComponent
									title={this.state.presentationData.title}
									componentTitle={"Main title"}
									saveTitle={(t) => {
										let obj = {
											...this.state.presentationData,
											title: t,
										};

										this.updateDocument(obj);
									}}
								/>
								<TitleComponent
									title={this.state.presentationData.title2}
									componentTitle={"Title 2"}
									saveTitle={(t) => {
										let obj = {
											...this.state.presentationData,

											title2: t,
										};

										this.updateDocument(obj);
									}}
								/>
								<TitleComponent
									title={
										this.state.presentationData.description
									}
									componentTitle={"Description "}
									saveTitle={(t) => {
										let obj = {
											...this.state.presentationData,
											description: t,
										};

										this.updateDocument(obj);
									}}
								/>
							</View>
							<LandingPage
								{...this.state}
								{...this.props}
								user_data={this.props.user_data}
								h0={h0}
								h1={h1}
								h2={h2}
								pp={pp}
								mw={mw}
								// title={this.state.presentationData?.title}
								// title2={this.state.presentationData?.title2}
								// subTitle={this.state.presentationData?.subTitle}
								// description={this.state.presentationData?.description}
								// createdBy={this.state.presentationData?.createdBy}
								sharePresentation={() => {
									this.fetchSharedList(
										this.state.presentation_id,
									);
									this.setState({
										sharePage: !this.state.sharePage,
										presentationPageData: "",
									});
								}}
								setStatus={(_id, _status) => {
									this.updateStatus(_id, _status);
								}}
								updateMainImage={(_id, image) => {
									let obj = {
										_id,
										mainImage: image,
									};

									this.updateDocument(obj);
								}}
								editTitle={() => {
									this.setState({
										showEditTitles:
											!this.state.showEditTitles,
									});
								}}
							/>

							{/* {this.state.screenWidth <= 600
								? this.tabComponent("horizontal")
								: null} */}
							{/* {this.tabComponent("horizontal")} */}
							<View style={{ padding: 20, width: "100%" }}>
								{this.state.presentationRows
									.sort((a, b) => {
										if (
											parseInt(a.order) <
											parseInt(b.order)
										)
											return -1;
										if (
											parseInt(a.order) >
											parseInt(b.order)
										)
											return 1;
										return 0;
									})
									.map((d, i) => (
										<View
											key={`${d.id}`}
											style={{
												borderBottomColor:
													APP_COLOURS.BG4,
												borderBottomWidth: 1,
												// height: this.state.screenHeight,
												width: "100%",
												// alignItems: "center",
												borderRadius: 20,
												overflow: "hidden",
												marginBottom: 20,
											}}>
											<View
												style={{
													flexDirection: "row",
													alignItems: "center",
													padding: 10,
													backgroundColor:
														APP_COLOURS.BG3,
													width: "100%",
												}}>
												<View
													style={{
														flexDirection: "row",
														alignItems: "center",
													}}>
													<TouchableOpacity
														style={{}}
														onPress={() =>
															this.setState({
																showRow:
																	this.state
																		.showRow !==
																	d.id
																		? d.id
																		: this
																				.state
																				.showRow !==
																		  ""
																		? ""
																		: d.id,
															})
														}>
														<Image
															style={{
																width: 35,
																height: 35,
																marginRight: 10,
																marginLeft: 5,
																resizeMode:
																	"contain",
															}}
															source={require("../../../assets/images/expand.png")}
														/>
													</TouchableOpacity>
												</View>
												<TitleComponent
													componentTitle={
														"Page title"
													}
													title={
														typeof d.title !==
														"undefined"
															? d.title
															: ""
													}
													saveTitle={(t) => {
														d.title = t;
														let obj = {
															...this.state
																.presentationData,
															d,
														};
														// console.log(obj);
														// return;
														this.updateDocument(
															obj,
														);
													}}
												/>

												<View
													style={{
														flexDirection: "row",
														alignItems: "center",
														width: 150,
													}}>
													<TouchableOpacity
														onPress={async () => {
															this.pageOrder(
																d,
																i,
																d.order,
																"up",
															);
														}}>
														<AutoHeightImage
															width={19}
															source={require("../../../assets/images/add_btn.png")}
														/>
													</TouchableOpacity>
													<View
														style={{
															padding: 4,
															alignItems:
																"center",
															justifyContent:
																"center",
															borderRadius: 10,
															paddingHorizontal: 10,

															borderColor:
																APP_COLOURS.DESELECTEDCOLOR,
														}}>
														<Text
															style={{
																fontSize: 15,
																fontWeight:
																	"600",
																color: APP_COLOURS.DESELECTEDCOLOR,
															}}>
															<Font
																family='Montserrat'
																weight={300}>
																Page:
															</Font>
														</Text>
														<Text
															style={{
																fontSize: 15,
																fontWeight:
																	"600",
																color: APP_COLOURS.DESELECTEDCOLOR,
															}}>
															<Font
																family='Montserrat'
																weight={500}>
																{parseInt(
																	d.order,
																) + 1}
															</Font>
														</Text>
													</View>

													<TouchableOpacity
														onPress={async () => {
															this.pageOrder(
																d,
																i,
																d.order,
																"down",
															);
														}}>
														<AutoHeightImage
															width={19}
															source={require("../../../assets/images/min_btn.png")}
														/>
													</TouchableOpacity>
												</View>

												<TouchableOpacity
													onPress={() => {
														this.addRow(d, i);
													}}>
													<View
														style={{
															width: 120,
														}}>
														<View
															style={{
																padding: 4,
																alignItems:
																	"center",
																justifyContent:
																	"center",
																borderRadius: 10,
																paddingHorizontal: 10,
																borderWidth: 1,
																borderColor:
																	APP_COLOURS.DESELECTEDCOLOR,
															}}>
															<Text
																style={{
																	fontSize: 15,
																	fontWeight:
																		"600",
																	color: APP_COLOURS.DESELECTEDCOLOR,
																}}>
																<Font
																	family='Montserrat'
																	weight={
																		600
																	}>
																	+ Column
																</Font>
															</Text>
														</View>
													</View>
												</TouchableOpacity>
											</View>
											<View
												style={{
													width: "100%",
													flexDirection: isSmallScreen
														? "column"
														: "row",
													// maxHeight: window.innerHeight,

													// paddingBottom: 5,
													// marginVertical: 10,

													// padding: "2%",
												}}>
												{this.state.showRow === d.id
													? d.rowData.map(
															(rd, rdIn) => (
																<View
																	key={`${rd._id}`}
																	onMouseEnter={() =>
																		this.setState(
																			{
																				hover: d.id,
																			},
																		)
																	}
																	onMouseLeave={() =>
																		this.setState(
																			{
																				hover: "",
																			},
																		)
																	}
																	style={{
																		flex: 1,
																		// justifyContent:
																		// 	"center",
																		width: "100%",
																		maxWidth: 1300,
																		// paddingRight: 10,
																		// borderRightWidth: 1,
																		// borderRightColor:
																		// 	APP_COLOURS.DESELECTEDCOLOR,
																		// borderStyle:
																		// 	"dotted",
																	}}>
																	<View
																		style={{
																			flexDirection:
																				"row",
																			marginBottom: 20,
																			alignItems:
																				"center",
																			backgroundColor:
																				APP_COLOURS.BG3,
																			borderBottomLeftRadius: 15,
																			borderBottomRightRadius: 15,
																			padding: 2,
																		}}>
																		<TouchableOpacity
																			onPress={() => {
																				this.setState(
																					{
																						lockContent:
																							this
																								.state
																								.lockContent ===
																							rd._id
																								? ""
																								: rd._id,
																					},
																				);
																			}}>
																			<View
																				style={{
																					width: 80,
																					alignItems:
																						this
																							.state
																							.lockContent ===
																						rd._id
																							? "flex-start"
																							: "flex-end",
																					// backgroundColor:
																					// 	APP_COLOURS.BG4,
																					borderRadius: 15,
																					height: 40,
																					// display:
																					// 	this.state
																					// 		.hover ===
																					// 	d.id
																					// 		? "flex"
																					// 		: "none",
																				}}>
																				<View
																					style={{
																						width: 80,
																						height: 40,
																						alignItems:
																							"center",
																						justifyContent:
																							"center",
																						borderRadius: 13,
																						backgroundColor:
																							this
																								.state
																								.lockContent ===
																							rd._id
																								? "white"
																								: APP_COLOURS.BLUE,
																						paddingHorizontal: 10,
																					}}>
																					<Text
																						style={{
																							fontSize: 13,
																							color:
																								this
																									.state
																									.lockContent ===
																								rd._id
																									? "blue"
																									: APP_COLOURS.DESELECTEDCOLOR,
																						}}>
																						<Font
																							family='Montserrat'
																							weight={
																								300
																							}>
																							{this
																								.state
																								.lockContent ===
																							rd._id
																								? "Unlocked"
																								: "Locked"}
																						</Font>
																					</Text>
																				</View>
																			</View>
																		</TouchableOpacity>

																		<View
																			style={{
																				width: 200,
																				justifyContent:
																					"center",
																				alignItems:
																					"center",
																			}}>
																			<Text
																				style={{
																					fontSize: 13,
																					color: APP_COLOURS.DESELECTEDCOLOR,
																				}}>
																				<Font
																					family='Montserrat'
																					weight={
																						300
																					}>
																					{
																						d.id
																					}
																				</Font>
																			</Text>
																			<Text
																				style={{
																					fontSize: 13,
																					color: APP_COLOURS.DESELECTEDCOLOR,
																				}}>
																				<Font
																					family='Montserrat'
																					weight={
																						300
																					}>
																					{
																						rd._id
																					}
																				</Font>
																			</Text>
																		</View>

																		{this
																			.state
																			.lockContent ===
																		rd._id ? (
																			<TouchableOpacity
																				onPress={() => {
																					this.deleteWithHtml(
																						rd
																							?.htmlData
																							?.htmlFileName,
																						rd
																							?.htmlData
																							._id,
																						d,
																						rd,
																						rdIn,
																					);

																					// console.log(
																					// 	rd,
																					// 	rdIn,
																					// );
																				}}
																				style={{
																					justifyContent:
																						"center",
																					alignItems:
																						"center",
																				}}>
																				<Image
																					source={
																						bin
																					}
																					style={{
																						width: 30,
																						height: 30,
																						resizeMode:
																							"contain",
																						marginRight: 5,
																						marginLeft: 10,
																					}}
																				/>
																			</TouchableOpacity>
																		) : null}
																	</View>

																	<QuillEditor
																		presentation_id={
																			this
																				.state
																				.presentation_id
																		}
																		pageData={
																			rd
																		}
																		reloadData={(
																			html,
																		) => {
																			crudCreateWithHtml(
																				"presentation_pages",
																				{
																					presentation_id:
																						this
																							.state
																							.presentation_id,
																					html: html,
																					_id:
																						typeof rd
																							?.htmlData
																							?._id !==
																						"undefined"
																							? rd
																									.htmlData
																									._id
																							: "",
																				},
																				(
																					status,
																					data,
																				) => {
																					console.log(
																						data,
																						status,
																					);
																					this.updateRow(
																						d,
																						rdIn,
																						i,
																						data?.htmlData,
																					);
																				},
																			);
																		}}
																		lockContent={
																			this
																				.state
																				.lockContent !==
																			rd._id
																		}
																		fontSizes={{
																			"--h1": `${h0}px`,
																			"--h2": `${h1}px`,
																			"--pp": `${pp}px`,
																		}}
																	/>
																</View>
															),
													  )
													: null}
											</View>
										</View>
									))}

								<TouchableOpacity
									onPress={() => {
										this.addPage();
									}}>
									<View
										style={{
											width: 120,
											borderRadius: 15,
											// height: 40,
										}}>
										<View
											style={{
												width: 120,
												height: 40,
												alignItems: "center",
												justifyContent: "center",
												borderRadius: 13,
												backgroundColor:
													APP_COLOURS.BG3,
												paddingHorizontal: 10,
											}}>
											<Text
												style={{
													fontSize: 16,
													color: "#FFF",
												}}>
												<Font
													family='Montserrat'
													weight={400}>
													Add Page
												</Font>
											</Text>
										</View>
									</View>
								</TouchableOpacity>

								<View style={{}}>
									{this.costEstimateComponent(
										this.state.presentationData,
										this.state.presentationData.title,
										this.state.presentationData.title2,
										this.state.presentationData.end_date,
									)}
								</View>
							</View>

							{this.state.sharePage &&
							this.state.presentationPageData === "" ? (
								<Modal
									animationType='fade'
									transparent={true}
									visible={true}>
									<View
										style={{
											flex: 1,
											justifyContent: "flex-start",
											alignItems: "center",
											backgroundColor:
												"rgba(52, 52, 52, 0.6)",
										}}>
										<View
											style={{
												marginTop: 100,
												justifyContent: "center",
												// width: 600,
												// height: 300,
												backgroundColor:
													APP_COLOURS.BG3,
												borderRadius: 15,
												alignItems: "center",
												padding: 10,
											}}>
											<TouchableOpacity
												onPress={() => {
													this.setState({
														sharePage:
															!this.state
																.sharePage,
														presentationPageData:
															"",
													});
												}}>
												<View
													style={{
														// backgroundColor: APP_COLOURS.BG4,
														borderRadius: 15,
														height: 40,
														marginRight: 10,
													}}>
													<View
														style={{
															width: 80,
															height: 40,
															alignItems:
																"center",
															justifyContent:
																"center",
															borderRadius: 13,
															// backgroundColor: APP_COLOURS.,
															paddingHorizontal: 10,
														}}>
														<Text
															style={{
																fontSize: 13,
																color: APP_COLOURS.BLUE,
																fontWeight:
																	"700",
															}}>
															<Font
																family='Montserrat'
																weight={300}>
																Close
															</Font>
														</Text>
													</View>
												</View>
											</TouchableOpacity>
											<View
												style={{
													flex: 1,
												}}>
												<View
													style={{
														width: 500,
														marginBottom: 20,
													}}>
													<Text
														style={{
															fontSize: 16,
															marginTop: 2,
															fontWeight: "600",
															color: "#FFF",
															marginBottom: 10,
														}}>
														Email list
													</Text>
													<View
														style={{
															height: 450,
															backgroundColor:
																APP_COLOURS.BG3,
															borderRadius: 10,
														}}>
														<ScrollView>
															{this.state.sharedList?.map(
																(d) => (
																	<View
																		style={{
																			flexDirection:
																				"row",
																			justifyContent:
																				"space-between",
																			borderBottomWidth: 0.5,
																			borderColor:
																				APP_COLOURS.BG5,
																			paddingBottom: 3,
																			marginBottom: 3,
																		}}>
																		<View
																			style={{
																				flex: 1,
																			}}>
																			<Text
																				style={{
																					fontSize: 14,
																					marginTop: 2,
																					fontWeight:
																						"600",
																					color: "#FFF",
																				}}>
																				{
																					d.recipients
																				}
																			</Text>
																			<Text
																				style={{
																					fontSize: 10,
																					marginTop: 2,
																					fontWeight:
																						"300",
																					color: "#FFF",
																				}}>
																				Sent:{" "}
																				{moment(
																					d.date,
																				).fromNow()}
																			</Text>
																			<Text
																				style={{
																					fontSize: 10,
																					marginTop: 2,
																					fontWeight:
																						"300",
																					color: "#FFF",
																				}}>
																				Expires:{" "}
																				{moment(
																					d.expiresIn,
																				).fromNow()}
																			</Text>

																			{typeof d.feedback !==
																			"undefined" ? (
																				<View
																					style={{
																						padding: 5,
																					}}>
																					<View
																						style={{
																							// width: 250,
																							width: "100%",
																							flexDirection:
																								"row",
																							marginBottom: 20,
																							justifyContent:
																								"center",
																						}}>
																						{[
																							1,
																							2,
																							3,
																							4,
																							5,
																							6,
																							7,
																						].map(
																							(
																								priority,
																							) => (
																								<View
																									key={
																										priority
																									}
																									style={{
																										marginRight: 5,
																									}}
																									onPress={async () => {
																										this.setState(
																											{
																												starRating:
																													priority,
																											},
																										);
																									}}>
																									{this
																										.state
																										.starRating >=
																									priority ? (
																										<AutoHeightImage
																											width={
																												12
																											}
																											style={{
																												marginRight: 5,
																											}}
																											source={
																												starFill
																											}
																										/>
																									) : (
																										<AutoHeightImage
																											width={
																												12
																											}
																											style={{
																												marginRight: 5,
																												opacity: 0.3,
																											}}
																											source={
																												star
																											}
																										/>
																									)}
																								</View>
																							),
																						)}
																					</View>
																					<Text
																						style={{
																							fontSize: 10,
																							marginTop: 2,
																							fontWeight:
																								"300",
																							color: "#FFF",
																						}}>
																						{
																							d
																								.feedback
																								.description
																						}
																					</Text>

																					<Text
																						style={{
																							fontSize: 10,
																							marginTop: 2,
																							fontWeight:
																								"300",
																							color: "#FFF",
																						}}>
																						{moment(
																							d
																								.feedback
																								.date,
																						).fromNow()}
																					</Text>
																				</View>
																			) : null}
																		</View>

																		<View
																			style={{
																				// opacity:
																				// 	d
																				// 		.status
																				// 		?.read
																				// 		? 1
																				// 		: 0.2,
																				backgroundColor:
																					d
																						.status
																						?.read
																						? APP_COLOURS.GREEN
																						: APP_COLOURS.BG4,
																				borderRadius: 5,
																				width: 200,
																				justifyContent:
																					"center",
																				alignItems:
																					"center",
																			}}>
																			<Text
																				style={{
																					fontSize: 10,
																					marginTop: 2,
																					fontWeight:
																						"600",
																					color: "#FFF",
																				}}>
																				{d
																					.status
																					?.read
																					? "Read"
																					: "Pending"}
																			</Text>
																			<Text
																				style={{
																					fontSize: 10,
																					marginTop: 2,
																					fontWeight:
																						"600",
																					color: "#FFF",
																				}}>
																				{
																					d
																						.status
																						?.readDate
																				}
																			</Text>
																		</View>
																	</View>
																),
															)}
														</ScrollView>
													</View>
												</View>

												<EmailPresentation
													user_data={
														this.props.user_data
													}
													proposalData={
														this.props
															.presentationData
													}
													sentEmail={() => {
														this.fetchSharedList(
															this.state
																.presentation_id,
														);
													}}
												/>
											</View>
										</View>
									</View>
								</Modal>
							) : null}

							<View
								style={{
									height: 300,
								}}
							/>
						</ScrollView>
					</View>
					{/* {this.state.screenWidth > 600
						? this.tabComponent("vertical")
						: null} */}
				</View>
			);
	}

	tabComponent = (orientation) => {
		return (
			<View
				style={{
					flexDirection:
						orientation === "horizontal" ? "row" : "column",
					// marginBottom: 20,
					// width: "100%",
					// maxWidth: 1000,
					// alignItems: "center",
					width: 250,
					// backgroundColor: "red",
					backgroundColor: APP_COLOURS.BG2,
					// position: "absolute",
					// zIndex: 999,
					// right: 10,
					// flex: 1,
					// top: 100,
				}}>
				<TouchableOpacity
					onPress={() => {
						this.setState({
							sharePage: !this.state.sharePage,
							presentationPageData: "",
						});
					}}>
					<View
						style={{
							// backgroundColor: APP_COLOURS.BG4,
							borderRadius: 15,
							height: 40,
							marginRight: 10,
						}}>
						<View
							style={{
								width: 80,
								height: 40,
								alignItems: "center",
								justifyContent: "center",
								borderRadius: 13,
								// backgroundColor: APP_COLOURS.,
								paddingHorizontal: 10,
							}}>
							<Text
								style={{
									fontSize: 13,
									color: APP_COLOURS.BLUE,
									fontWeight: "700",
								}}>
								<Font family='Montserrat' weight={300}>
									Share
								</Font>
							</Text>
						</View>
					</View>
				</TouchableOpacity>
				<TouchableOpacity
					onPress={() => {
						this.addNewPage(this.state.presentation_id);
					}}>
					<View
						style={{
							// backgroundColor: APP_COLOURS.BG4,
							// borderRadius: 15,
							// height: 40,
							// marginRight: 10,
							width: "100%",
							marginVertical: 10,
						}}>
						<View
							style={{
								width: "100%",
								height: 40,
								alignItems: "center",
								justifyContent: "center",
								borderRadius: 13,
								backgroundColor: APP_COLOURS.BLUE,
								paddingHorizontal: 10,
							}}>
							<Text
								style={{
									fontSize: 13,
									color: APP_COLOURS.DESELECTEDCOLOR,
									fontWeight: "500",
								}}>
								<Font family='Montserrat' weight={300}>
									+ Page
								</Font>
							</Text>
						</View>
					</View>
				</TouchableOpacity>

				<ScrollView
					showsHorizontalScrollIndicator={false}
					horizontal={orientation === "horizontal"}>
					{this.state.presentationPages
						.sort((a, b) => a.title > b.title)
						.map((d) => (
							<TouchableOpacity
								onPress={async () => {
									await this.setState({
										presentationPageData: "",
										selectedPage: d._id,
									});
									if (typeof d?.htmlData !== "undefined") {
										this.getLargeHtmlData(
											d.htmlData?.htmlData,
											(html) => {
												console.log(html);
												this.setState({
													presentationPageData: {
														...d,
														html: html.data,
													},
												});
											},
										);
									} else {
										await this.setState({
											presentationPageData: {
												...d,
												html: "",
											},
										});
									}
								}}>
								<View
									style={{
										// flex: 1,
										alignItems: "center",
										justifyContent: "center",
										borderRadius: 10,
										borderWidth: 1,

										borderColor:
											this.state.selectedPage === d._id
												? "transparent"
												: "#FFF",
										// height: 60,
										marginRight: 5,
										padding: 20,
										backgroundColor:
											this.state.selectedPage === d._id
												? APP_COLOURS.BLUE
												: "transparent",
										marginVertical: 5,
									}}>
									<Text
										style={{
											fontSize: 16,
											color: APP_COLOURS.DESELECTEDCOLOR,
										}}>
										<Font family='Montserrat' weight={300}>
											{d.title}
										</Font>
									</Text>
								</View>
							</TouchableOpacity>
						))}

					<View style={{ width: 100 }} />

					<TouchableOpacity
						onPress={async () => {
							await this.setState({
								presentationPageData: "",
								showTimeline: !this.state.showTimeline,
							});
						}}>
						<View
							style={{
								flex: 1,
								alignItems: "center",
								justifyContent: "center",
								borderRadius: 10,
								borderWidth: 1,
								borderColor: "#FFF",
								height: 60,
								marginRight: 5,
								padding: 20,
							}}>
							<Text
								style={{
									fontSize: 16,
									color: APP_COLOURS.DESELECTEDCOLOR,
								}}>
								<Font family='Montserrat' weight={300}>
									Timeline
								</Font>
							</Text>
						</View>
					</TouchableOpacity>

					<TouchableOpacity
						onPress={async () => {
							await this.setState({
								presentationPageData: "",
								showCostEstimate: !this.state.showCostEstimate,
							});
						}}>
						<View
							style={{
								flex: 1,
								alignItems: "center",
								justifyContent: "center",
								borderRadius: 10,
								borderWidth: 1,
								borderColor: "#FFF",
								height: 60,
								marginRight: 5,
								padding: 20,
							}}>
							<Text
								style={{
									fontSize: 16,
									color: APP_COLOURS.DESELECTEDCOLOR,
								}}>
								<Font family='Montserrat' weight={300}>
									Cost Estimate
								</Font>
							</Text>
						</View>
					</TouchableOpacity>
				</ScrollView>
			</View>
		);
	};

	addPage = () => {
		let { presentationRows } = this.state;
		presentationRows.push({
			order: presentationRows.length,
			id: `page_${uuid()}`,
			rowData: [
				{
					_id: `row_${uuid()}`,
					htmlData: "",
				},
			],
		});

		let obj = {
			...this.state.presentationData,
			presentationRows,
		};
		this.updateDocument(obj);
	};

	updateCostEstimate = (cost) => {
		let obj = {
			_id: this.state.presentationData._id,
			...cost,
		};

		// console.log(obj);
		// return;
		this.updateDocument(obj);
	};

	updateStatus = (_id, status) => {
		let obj = {
			_id,
			status: status,
		};

		// console.log(obj);
		// return;
		this.updateDocument(obj);
	};

	addRow = (d, i) => {
		d.rowData.push({
			_id: `row_${uuid()}`,
			htmlData: "",
		});

		let { presentationRows } = this.state;
		presentationRows[i] = d;

		let obj = {
			...this.state.presentationData,
			presentationRows,
		};

		// console.log(obj);
		// return;
		this.updateDocument(obj);
	};
	updateRow = (d, ror_i, page_i, html) => {
		// let indexOf = d.rowData.findIndex((a) => a._id === d._id);
		d.rowData[ror_i] = {
			_id: `row_${uuid()}`,
			htmlData: html,
		};

		let { presentationRows } = this.state;
		presentationRows[page_i] = d;

		let obj = {
			...this.state.presentationData,
			presentationRows,
		};

		// console.log(obj);
		// return;
		this.updateDocument(obj);
	};

	pageOrder = (d, ror_i, order, direction) => {
		function updateOrder(presentationRows, id, newOrder) {
			// Find the index of the item with the given id
			const currentIndex = presentationRows.findIndex(
				(row) => row.id === id,
			);
			if (currentIndex === -1) {
				console.error("Item with the specified ID not found.");
				return presentationRows;
			}

			// Get the item that needs to be moved
			const itemToMove = presentationRows[currentIndex];

			// Remove the item from its current position
			presentationRows.splice(currentIndex, 1);

			// Insert the item at the new position
			presentationRows.splice(newOrder, 0, itemToMove);

			// Recalculate the order for all items
			presentationRows.forEach((row, index) => {
				row.order = index;
			});

			return presentationRows;
		}

		let newOrder = parseInt(order) - 1;
		if (direction === "up") {
		} else {
			newOrder = parseInt(order) + 1;
		}

		console.log(newOrder);
		d.order = newOrder;

		const updatedPresentationRows = updateOrder(
			this.state.presentationRows,
			d.id,
			newOrder,
		);
		console.log(updatedPresentationRows);

		this.setState({
			presentationRows: updatedPresentationRows,
			presentationData: {
				...this.state.presentationData,
				presentationRows: updatedPresentationRows,
			},
		});
		const obj = {
			_id: this.state.presentationData._id,
			presentationRows: updatedPresentationRows,
		};
		this.updateDocument(obj);
	};

	deleteWithHtml = async (htmlFileName, _id, d, rd, rdIn) => {
		// console.log(d);
		// return;
		await this.deleteColumn(d, rd, rdIn);
		// return;
		await deleteProposalPage(
			"presentation_pages",
			{
				htmlFileName: htmlFileName,
				_id: _id,
			},
			() => {},
		);
	};

	// deleteColumn = (d, rd, page_i) => {
	// 	// Copy the current presentationRows from state
	// 	let presentationRows = [...this.state.presentationRows];

	// 	// Filter out the row data that should be removed
	// 	let newrowData = presentationRows[page_i].rowData.filter(
	// 		(m) => m._id !== rd._id,
	// 	);

	// 	// Update the specific row's rowData with the new filtered array
	// 	presentationRows[page_i] = {
	// 		...presentationRows[page_i],
	// 		rowData: newrowData,
	// 	};

	// 	console.log(page_i, presentationRows[page_i]);
	// 	return;

	// 	// Prepare the object to be updated
	// 	const obj = {
	// 		_id: this.state.presentationData._id,
	// 		presentationRows: presentationRows,
	// 	};

	// 	// Call your update function to update the document or state
	// 	// this.updateDocument(obj);

	// 	console.log(obj);

	// 	this.setState({
	// 		presentationRows,
	// 		presentationData: {
	// 			...this.state.presentationData,
	// 			presentationRows,
	// 		},
	// 	});
	// };

	deleteColumn = async (d, rd, rdIn) => {
		try {
			console.log("Deleting column:", rd._id, "from row:", d.id);

			// Create a copy of the current presentationRows
			const newRows = [...this.state.presentationRows];

			// Find the index of the row containing the column to delete
			const rowIndexToDelete = newRows.findIndex(
				(row) => row.id === d.id,
			);

			if (rowIndexToDelete !== -1) {
				// Remove the column from the row
				newRows[rowIndexToDelete].rowData.splice(rdIn, 1);

				// Update the order of remaining columns within the row
				newRows[rowIndexToDelete].rowData.forEach((col, i) => {
					col.order = i;
				});

				// If the row becomes empty after deletion, remove the entire row
				if (newRows[rowIndexToDelete].rowData.length === 0) {
					newRows.splice(rowIndexToDelete, 1);
				}

				// Update the order of remaining rows
				newRows.forEach((row, i) => {
					row.order = i;
				});

				console.log("newRows", newRows);
				this.setState({
					presentationRows: newRows,
					presentationData: {
						...this.state.presentationData,
						presentationRows: newRows,
					},
				});
				// return;
				// Prepare the object to be updated
				const obj = {
					_id: this.state.presentationData._id,
					presentationRows: newRows,
				};

				this.updateDocument(obj);
			}
		} catch (error) {
			console.error("Error deleting column:", error);
		}
	};

	updateDocument = (obj) => {
		console.log("updateDocument");
		let url =
			"https://workflow.tetrice.co.za/webhook/fa683270-ffec-4542-beff-a2acafd63bfd";
		const requestOptions = {
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(obj),
		};
		fetch(url, requestOptions)
			.then((response) => {
				if (!response.ok) {
					throw new Error("Failed to fetch data");
				}
				return response.json();
			})
			.then((data) => {
				console.log(data);
				this.props.reloadData();
				this.fetchPresentationData();
			})
			.catch((error) => {
				this.setState({
					error: error,
					loading: false,
				});
			});
	};

	costEstimateComponent = (presentationData, title, title2, end_date) => {
		return (
			<CostEstimate
				{...presentationData}
				title={title}
				title2={title2}
				end_date={end_date}
				updateCostEstimate={(costs) => this.updateCostEstimate(costs)}
			/>
		);
	};
}
