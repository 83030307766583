import React, { useState, useEffect } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Modal,
	TextInput,
	Button,
} from "react-native";

const ItemModal = ({ isOpen, onRequestClose, category, onSave }) => {
	const [items, setItems] = useState(category);

	// Update items when the category prop changes
	useEffect(() => {
		setItems(category);
	}, [category]);

	const addItem = () => {
		setItems([...items, { title: "", desc: "", totalExcl: "" }]);
	};

	const deleteItem = (index) => {
		const updatedItems = items.filter((_, i) => i !== index);
		setItems(updatedItems);
	};

	const handleChange = (index, field, value) => {
		const updatedItems = items.map((item, i) =>
			i === index ? { ...item, [field]: value } : item,
		);
		setItems(updatedItems);
	};

	const handleSave = () => {
		onSave(items);
		onRequestClose();
	};

	return (
		<View visible={isOpen}>
			<View style={{ padding: 20, maxWidth: 450 }}>
				<Text style={{ fontSize: 20, marginBottom: 10 }}>
					Edit Items
				</Text>
				{items.map((item, index) => (
					<View key={index} style={{ marginBottom: 10 }}>
						<TextInput
							placeholder='Title'
							value={item.title}
							onChangeText={(text) =>
								handleChange(index, "title", text)
							}
							style={{ borderBottomWidth: 1, marginBottom: 10 }}
						/>
						<TextInput
							placeholder='Description'
							value={item.desc}
							onChangeText={(text) =>
								handleChange(index, "desc", text)
							}
							style={{ borderBottomWidth: 1, marginBottom: 10 }}
						/>
						<TextInput
							placeholder='Value'
							value={item.totalExcl}
							onChangeText={(text) =>
								handleChange(index, "totalExcl", text)
							}
							keyboardType='numeric'
							style={{ borderBottomWidth: 1, marginBottom: 10 }}
						/>
						<Button
							title='Delete'
							onPress={() => deleteItem(index)}
							color='red'
						/>
					</View>
				))}
				<Button title='Add Item' onPress={addItem} />
				<Button
					title='Save'
					onPress={handleSave}
					style={{ marginTop: 10 }}
				/>
			</View>
		</View>
	);
};

const AddCostModal = ({ saveParent, defaultCosts }) => {
	const [additionalItems, setAdditionalItems] = useState(
		defaultCosts.additionalItems,
	);
	const [annuallyRecurring, setAnnuallyRecurring] = useState(
		defaultCosts.annuallyRecurring,
	);
	const [monthlyRecurring, setMonthlyRecurring] = useState(
		defaultCosts.monthlyRecurring,
	);
	const [onceOffCosts, setOnceOffCosts] = useState(defaultCosts.onceOffCosts);
	const [usageBased, setUsageBased] = useState(defaultCosts.usageBased);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentCategory, setCurrentCategory] = useState([]);
	const [currentSetter, setCurrentSetter] = useState(() => {});

	const openModal = (category, setter) => {
		setCurrentCategory(category);
		setCurrentSetter(() => setter);
		setIsModalOpen(true);
	};

	const closeModal = () => setIsModalOpen(false);

	const handleSave = (updatedItems) => {
		currentSetter(updatedItems);
		closeModal();
	};

	return (
		<View style={{ padding: 20 }}>
			<h1>Manage Costs</h1>
			{isModalOpen ? (
				<>
					<ItemModal
						isOpen={isModalOpen}
						onRequestClose={closeModal}
						category={currentCategory}
						onSave={handleSave}
					/>
				</>
			) : (
				<View>
					<View>
						<View
							style={{
								flexDirection: "row",
								justifyContent: "space-between",
							}}>
							<h2>Additional Items:</h2>
							<Button
								title='Edit'
								onPress={() =>
									openModal(
										additionalItems,
										setAdditionalItems,
									)
								}
							/>
						</View>
						<ul>
							{additionalItems.map((item, index) => (
								<li key={index}>
									{item.title}: {item.desc} - {item.totalExcl}
								</li>
							))}
						</ul>

						<View
							style={{
								flexDirection: "row",
								justifyContent: "space-between",
							}}>
							<h2>Annually Recurring:</h2>
							<Button
								title='Edit'
								onPress={() =>
									openModal(
										annuallyRecurring,
										setAnnuallyRecurring,
									)
								}
							/>
						</View>

						<ul>
							{annuallyRecurring.map((item, index) => (
								<li key={index}>
									{item.title}: {item.desc} - {item.totalExcl}
								</li>
							))}
						</ul>

						<View
							style={{
								flexDirection: "row",
								justifyContent: "space-between",
							}}>
							<h2>Once-Off Costs:</h2>
							<Button
								title='Edit '
								onPress={() =>
									openModal(onceOffCosts, setOnceOffCosts)
								}
							/>
						</View>
						<ul>
							{onceOffCosts.map((item, index) => (
								<li key={index}>
									{item.title}: {item.desc} - {item.totalExcl}
								</li>
							))}
						</ul>

						<View
							style={{
								flexDirection: "row",
								justifyContent: "space-between",
							}}>
							<h2>Monthly Recurring:</h2>
							<Button
								title='Edit'
								onPress={() =>
									openModal(
										monthlyRecurring,
										setMonthlyRecurring,
									)
								}
							/>
						</View>
						<ul>
							{monthlyRecurring.map((item, index) => (
								<li key={index}>
									{item.title}: {item.desc} - {item.totalExcl}
								</li>
							))}
						</ul>
						<View
							style={{
								flexDirection: "row",
								justifyContent: "space-between",
							}}>
							<h2>Usage-Based:</h2>
							<Button
								title='Edit'
								onPress={() =>
									openModal(usageBased, setUsageBased)
								}
							/>
						</View>

						<ul>
							{usageBased.map((item, index) => (
								<li key={index}>
									{item.title}: {item.desc} - {item.totalExcl}
								</li>
							))}
						</ul>
					</View>

					<Button
						title='Save'
						onPress={() =>
							saveParent({
								additionalItems,
								annuallyRecurring,
								monthlyRecurring,
								onceOffCosts,
								usageBased,
							})
						}
						style={{ marginTop: 10, borderRadius: 15 }}
					/>
				</View>
			)}
		</View>
	);
};

export default AddCostModal;
