import React from "react";
import {
	StyleSheet,
	View,
	Button,
	TextInput,
	TouchableOpacity,
	Dimensions,
	ScrollView,
	Keyboard,
	Image,
	Alert,
	Text,
	LayoutAnimation,
} from "react-native";

// import {PieChart} from 'react-native-chart-kit';
import moment from "moment";
// import TodoModel from "./todo/TodoModel";

import { APP_COLOURS, CustomLayoutSpring, screenWidth } from "../../APP_VARS";
import { crudCreate } from "../../CRUDHELPER";

export default class DailyPlanner extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			longPress: false,
			locationX: 0,
			locationY: 0,
			text: "",
			viewChart: true,
			expandedRow: 0,
			data: [
				{
					title: "sdfvsdfv",
					category: "wes",
					createdAt: "2023-11-03 1:08:43",
					updatedAt: "2023-11-03 1:08:43",
					status: "incomplete",
				},
				{
					title: "sdfvsdfv",
					category: "wes",
					createdAt: "2023-11-03 1:08:43",
					updatedAt: "2023-11-03 1:08:43",
					status: "inprogress",
				},
				{
					title: "sdfvsdfv",
					category: "wes",
					createdAt: "2023-11-03 1:08:43",
					updatedAt: "2023-11-03 1:08:43",
					status: "complete",
				},
			],
			chartData: [],
			dataStore: [],
			completed: false,
			visible: false,
			category: "",
			keyboardOffset: 336 - 110,

			keyboardShow: "",

			// sprint: [
			//   {
			//     category: 'Mon',
			//     createdAt: '2022-12-20 9:57:03',
			//     description: '',
			//     id: '2f34aaa0-1cbf-4fa8-8702-aff1df7fb4bc',
			//     status: 'incomplete',
			//     title: 'Sdfvsdfvvdfvsdfv',
			//     updatedAt: '2022-12-20 9:57:03',
			//   },
			// ],
			selectedItem: "",
		};
		this.add = this.add.bind(this);
		this.textInput = React.createRef();
		this.getLocalData(this.props.category);
		this._timeout = 500;
	}

	async getLocalData(item) {
		// AsyncStorage.getItem(item).then((data) => {
		// 	if (data !== null) {
		// 		this.setState({
		// 			data: JSON.parse(data),
		// 			loading: false,
		// 		});
		// 		// console.log('getLocalData', JSON.parse(data));
		// 		this.updateChartData();
		// 	} else {
		// 		this.setState({
		// 			data: [],
		// 			loading: false,
		// 		});
		// 	}
		// });
	}

	async onValueChange(item, selectedValue) {
		try {
			// await AsyncStorage.setItem(item, selectedValue).then(
			// 	(x) => this.updateStates(selectedValue),
			// 	this.updateChartData(),
			// );
		} catch (error) {
			console.error(error);
		}
	}

	updateStates = (selectedValue) => {
		let parsed = JSON.parse(selectedValue);
		this.setState({
			data: parsed,
			text: "",
			loading: false,
		});
	};

	add = async () => {
		await Keyboard.dismiss();
		let text = await this.state.text;
		let category = await this.props.category;
		let date = await moment().format("YYYY-MM-DD h:mm:ss");
		let notEmpty = text.trim().length > 0; // if its not blank
		this.textInput.value = "";
		this.textInput.title = "";
		await this.textInput.clear();
		await this.setState({ text: "", loading: true, keyboardShow: "" });
		if (notEmpty) {
			let newDataItem = {
				text,
				category,
				createdAt: date,
				updatedAt: date,
				status: "incomplete",
			}; // create the data model object
			let newDataArray = [...this.state.data, newDataItem];
			await this.onValueChange(category, JSON.stringify(newDataArray));
			await this.updateChartData();

			// console.log(newDataItem.title);
			// crudCreate(
			// 	"activity_record_user",
			// 	{
			// 		user: {
			// 			id: this.props.user_data.id,
			// 			first_name: this.props.user_data.first_name,
			// 			last_name: this.props.user_data.last_name,
			// 		},
			// 		description: `task added: ${newDataItem.title}`,
			// 	},
			// 	(status, d) => {},
			// );

			console.log(newDataItem);
		} else {
			this.setState({ text: "", loading: false });
		}
	};

	deleteTask(item) {
		let data = this.state.data;
		const category = this.props.category;
		// data.filter(item => !thisitem.includes(item));

		// const filteredItems = items.slice(0, i).concat(items.slice(i + 1, items.length))

		// const valuesToRemove = thisitem;
		// const filteredItems = data.filter(item => !valuesToRemove.includes(item));

		console.log("item.id", data, item.id);

		let newArray = data.filter((d) => d.id !== item.id);
		console.log(newArray);

		this.setState({
			data: newArray,
			loading: false,
			expandedRow: 0,
			longPress: false,
			xPos: 0,
			yPos: 0,
			index: -1,
		});
		// this.onValueChange(category, JSON.stringify(newArray)),
		// 	() => this.updateChartData();
		this.updateChartData();
		// crudCreate(
		// 	"activity_record_user",
		// 	{
		// 		user: {
		// 			id: this.props.user_data.id,
		// 			first_name: this.props.user_data.first_name,
		// 			last_name: this.props.user_data.last_name,
		// 		},
		// 		description: `task deleted: status ${item.status} for ${item.title}`,
		// 	},
		// 	(status, d) => {},
		// );
	}

	updateArray = (id, key, updatedValue) => {
		var array = this.state.data;

		//find the index of object from array that you want to update
		const objIndex = array.findIndex((obj) => obj.id === id);

		// make new object of updated object.
		const updatedObj = { ...array[objIndex], [key]: updatedValue };

		console.log(updatedObj);

		// make final new array of objects by combining updated object.
		const updatedarray = [
			...array.slice(0, objIndex),
			updatedObj,
			...array.slice(objIndex + 1),
		];
		console.log("original data=", array);
		console.log("updated data=", updatedarray);
		let category = this.props.category;
		let newDataArray = updatedarray;
		this.onValueChange(category, JSON.stringify(newDataArray));

		// crudCreate(
		// 	"activity_record_user",
		// 	{
		// 		user: {
		// 			id: this.props.user_data.id,
		// 			first_name: this.props.user_data.first_name,
		// 			last_name: this.props.user_data.last_name,
		// 		},
		// 		description: `task updated: ${updatedObj.status} for ${updatedObj.title}`,
		// 	},
		// 	(status, d) => {},
		// );
	};

	updateChartData = () => {
		let complete = this.state.data.filter((item) => {
			return item.status == "complete";
		}).length;
		let inprogress = this.state.data.filter((item) => {
			return item.status == "inprogress";
		}).length;
		let incomplete = this.state.data.filter((item) => {
			return item.status == "incomplete";
		}).length;
		let chartData = [
			{
				name: "Complete",
				number: complete,
				color: "#00CB66",
				legendFontColor: "#7F7F7F",
				legendFontSize: 15,
			},
			{
				name: "Inprogress",
				number: inprogress,
				color: "#FFA400",
				legendFontColor: "#7F7F7F",
				legendFontSize: 15,
			},
			{
				name: "Incomplete",
				number: incomplete,
				color: "#FF113B",
				legendFontColor: "#7F7F7F",
				legendFontSize: 15,
			},
		];
		this.setState({ chartData: chartData, loading: false });
	};

	handleRowClick(rowId) {
		const currentExpandedRows = this.state.expandedRows;
		const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

		const newExpandedRows = isRowCurrentlyExpanded
			? currentExpandedRows.filter((id) => id !== rowId)
			: currentExpandedRows.concat(rowId);

		this.setState({ expandedRows: newExpandedRows });
	}

	_scrolltoend = () => {
		this.refs.flatList1.scrollToEnd();
	};

	deleteAll(key) {
		Alert.alert(
			"Delete All Items",
			"Are you sure you want to delete all the items in this list?",
			[
				{ text: "Yes", onPress: () => this.deleteAsync(key) },
				{
					text: "Cancel",
					onPress: () => console.log("Cancel Pressed"),
					style: "cancel",
				},
			],
			{ cancelable: true },
		);
	}

	async deleteAsync(key) {
		// try {
		// 	await AsyncStorage.removeItem(key);
		// 	// alert('All items have been deleted');
		// 	this.setState({ data: [] });
		// 	this.updateChartData();
		// } catch (exception) {
		// 	alert(exception);
		// }
	}

	_keyboardDidShow = (event) => {
		console.log(event.endCoordinates.height);
		let offset = event.endCoordinates.height - 110;
		this.setState({
			keyboardOffset: offset,
		});
		// LayoutAnimation.spring();
	};

	_keyboardDidHide = () => {
		// this.setState({
		//   keyboardOffset: 0,
		// });
		// LayoutAnimation.spring();
	};

	renderLongPress = (index, event) => {
		const layout = event.nativeEvent;
		let x = layout.pageX;
		let y = layout.pageY;
		console.log(y);
		this.setState({
			longPress: true,
			xPos: x,
			yPos: y - 30,
			selectedItem: index,
		});
	};

	componentDidMount() {
		this.updateChartData();
		this.keyboardDidShowListener = Keyboard.addListener(
			"keyboardDidShow",
			this._keyboardDidShow,
		);
		this.keyboardDidHideListener = Keyboard.addListener(
			"keyboardDidHide",
			this._keyboardDidHide,
		);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.category !== this.props.category) {
			console.log("Props Changed");
			this.getLocalData(this.props.category);
		}
		if (prevState.data !== this.state.data) {
			console.log("State Changed");
			this.updateChartData();
		}
	}

	componentWillUnmount() {
		this.keyboardDidShowListener.remove();
		this.keyboardDidHideListener.remove();
	}

	render() {
		LayoutAnimation.configureNext(CustomLayoutSpring);
		let vg = [
			{
				color: "#00CB66",
				legendFontColor: "#7F7F7F",
				legendFontSize: 15,
				name: "Complete",
				number: 0,
			},
			{
				color: "#FFA400",
				legendFontColor: "#7F7F7F",
				legendFontSize: 15,
				name: "Inprogress",
				number: 0,
			},
			{
				color: "#FF113B",
				legendFontColor: "#7F7F7F",
				legendFontSize: 15,
				name: "Incomplete",
				number: 0,
			},
		];
		if (this.state.loading) {
			return <Text>.</Text>;
		}
		return (
			<View
				style={{
					flex: 1,
					// padding: 1,
					backgroundColor: "transparent",
				}}>
				<View
					style={{
						// backgroundColor: "#292A4A",
						width: "100%",
						height: "auto",
						// padding: 10,
						// borderRadius: 10,
					}}>
					{/* <TouchableOpacity
            style={{
              width: 30,
              marginBottom: 20,
              height: 30,
              borderRadius: 15,
              backgroundColor: '#FF113B',
              opacity: 0.3,
              paddingTop: 4,
            }}
            onPress={() => this.deleteAll(this.props.category)}>
            <Text
              style={{
                fontSize: 16,
                width: '100%',
                textAlign: 'center',
                height: '100%',
                color: 'black',
                fontWeight: '300',
              }}>
              x
            </Text>
          </TouchableOpacity> */}

					{this.state.loading ? null : (
						<View
							style={{
								flexDirection: "row",
								height: 15,
								borderRadius: 10,
								width: "100%",
								// backgroundColor: "#292A4A",
								overflow: "hidden",
							}}>
							{this.state.chartData.map((d, i) => (
								<View
									style={{
										flex: d.number,
										backgroundColor: d.color,
										alignItems: "center",
										justifyContent: "center",
									}}>
									{d.number > 0 ? (
										<Text
											adjustsFontSizeToFit
											style={{
												color: "#292A4A",
												fontWeight: "900",
												fontSize: 12,
											}}>
											{d.number}
										</Text>
									) : null}
								</View>
							))}
						</View>
					)}
				</View>

				<TouchableOpacity
					onPress={() =>
						this.setState({ viewChart: !this.state.viewChart })
					}>
					<View
						style={{
							backgroundColor: "#000",
							// borderRadius: 10,
							marginBottom: 4,
							marginTop: 2,
							width: "100%",
							// height: 44,
							paddingLeft: 8,
							paddingTop: 5,
							flexDirection: "row",
							borderBottomWidth: 0.5,
							borderBottomColor: "#7D7FB2",
						}}>
						<View
							style={{
								flex: 1,
								alignItems: "flex-start",
								// justifyContent: 'space-between',
								padding: 5,
							}}>
							<Text
								numberOfLines={1}
								adjustsFontSizeToFit
								style={{
									fontSize: 16,
									color: "#F6F8FE",
									fontWeight: "900",
								}}>
								{this.props.title}
							</Text>
							<Text
								style={{
									opacity: 0.6,
									color: "#F6F8FE",
									fontWeight: "400",
									fontSize: 12,
								}}>
								{/* {moment(this.props.date).format("MMM DD, YYYY")} */}
							</Text>
						</View>
					</View>
				</TouchableOpacity>

				<ScrollView
					showsHorizontalScrollIndicator={false}
					showsVerticalScrollIndicator={false}>
					{this.state.data
						.filter((d) => {
							if (this.state.expandedRow !== 0) {
								return d.id == this.state.expandedRow;
							} else {
								return this.state.data;
							}
						})
						.filter((d_) => d_.status === "inprogress")
						.map((item, index) => this.itemCom(item, index))}

					{this.state.data
						.filter((d) => {
							if (this.state.expandedRow !== 0) {
								return d.id == this.state.expandedRow;
							} else {
								return this.state.data;
							}
						})
						.filter((d_) => d_.status === "incomplete")
						.map((item, index) => this.itemCom(item, index))}

					{this.state.data
						.filter((d) => {
							if (this.state.expandedRow !== 0) {
								return d.id == this.state.expandedRow;
							} else {
								return this.state.data;
							}
						})
						.filter((d_) => d_.status === "complete")
						.map((item, index) => this.itemCom(item, index))}
				</ScrollView>

				<View
					style={{
						height: 50,
						width: "100%",
					}}
				/>

				<View
					style={{
						flex: 1,
						width: "100%",
						padding: 10,
						justifyContent: "center",
						alignItems: "center",
					}}>
					{/* this.state.keyboardOffset > 0 ? this.state.keyboardOffset : 0, */}

					<TextInput
						style={{
							position: "absolute",
							bottom:
								this.state.keyboardShow === this.props.category
									? this.state.keyboardOffset
									: 0,
							left: 0,
							zIndex: 99,
							width:
								this.state.keyboardShow === this.props.category
									? screenWidth
									: "100%",
							maxWidth: 500,
							flex: 1,
							// height: 50,
							padding: 10,
							paddingLeft: 20,
							fontSize: 16,
							borderWidth:
								this.state.keyboardShow == this.props.category
									? 2
									: 1,
							borderColor:
								this.state.keyboardShow == this.props.category
									? "#FF113B"
									: APP_COLOURS.WHITE_TRANSPARENT2,
							borderRadius: 8,
							// backgroundColor: '#434350',
							backgroundColor: APP_COLOURS.BG2,
							color: "#D8D8FF",
						}}
						placeholderTextColor={"#828282"}
						clearButtonMode='while-editing'
						onFocus={() =>
							this.setState({
								viewChart: false,
								keyboardShow: this.props.category,
							})
						}
						onBlur={() => this.setState({ keyboardShow: "" })}
						autoComplete='off'
						autoCorrect={true}
						// value={this.state.text}
						placeholder={"+ Add new task"}
						onChangeText={async (text) => {
							await clearTimeout(this._timeout);
							this._timeout = await setTimeout(() => {
								this.setState({ text: text });
							}, 100);
						}}
						onSubmitEditing={() => this.add()}
						ref={(ref) => {
							this.textInput = ref;
						}}
					/>
				</View>

				{this.state.longPress && this.state.expandedRow == 0 ? (
					<View
						style={{
							position: "absolute",
							top: this.state.yPos - 100,
							left:
								this.state.xPos > screenWidth / 2
									? this.state.xPos - 150
									: this.state.xPos,
							width: 150,
							height: 100,
							zIndex: 99,
							paddingRight: 10,
							paddingLeft: 10,
							flexDirection: "column",
							backgroundColor: "white",
							justifyContent: "center",
							alignItems: "center",
							borderRadius: 10,
							shadowColor: "#000",

							shadowOffset: {
								width: 0,
								height: 5,
							},
							shadowOpacity: 0.34,
							shadowRadius: 6.27,

							elevation: 10,
						}}>
						<View
							style={{
								flex: 1,
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<TouchableOpacity
								style={{
									height: 30,
									borderRadius: 15,
									padding: 5,
									flexDirection: "row",
								}}
								onPress={() =>
									this.deleteTask(this.state.selectedItem)
								}>
								<Text
									style={{
										fontSize: 16,
										textAlign: "center",
										color: "black",
										fontWeight: "500",
									}}>
									Delete
								</Text>
								<Image
									source={require("../../../assets/images/bin.png")}
									style={{
										resizeMode: "contain",
										marginLeft: 10,
										width: 20,
										height: 20,
									}}
								/>
							</TouchableOpacity>
						</View>

						<View
							style={{
								width: "100%",
								height: 2,
								borderBottomWidth: 1,
								borderBottomColor: "#DFDFE8",
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
							}}
						/>

						<View
							style={{
								flex: 1,
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<TouchableOpacity
								style={{
									flex: 1,
									borderRadius: 15,
									marginTop: 16,
								}}
								onPress={() =>
									this.setState({ longPress: false })
								}>
								<Text
									style={{
										fontSize: 16,
										width: "100%",
										textAlign: "center",
										height: "100%",
										color: "black",
										fontWeight: "300",
									}}>
									Cancel
								</Text>
							</TouchableOpacity>
						</View>
					</View>
				) : null}

				{/* </Content> */}
			</View>
		);
	}

	itemCom = (item, index) => {
		return (
			<View
				style={{
					// borderBottomColor: "#28282F",
					// borderBottomWidth: 0.5,
					// backgroundColor: "#171B22",
					marginBottom: 2,
					marginTop: 2,
					backgroundColor: APP_COLOURS.WHITE_TRANSPARENT6,
				}}
				key={index}>
				<TouchableOpacity
					onLongPress={(event) => this.renderLongPress(item, event)}
					// onPress={() =>
					//   this.state.expandedRow == item.id
					//     ? this.setState({expandedRow: 0, longPress: false})
					//     : this.setState({expandedRow: item.id})
					// }
					onPress={() => {
						if (item.status === "incomplete") {
							this.updateArray(item.id, "status", "inprogress");
						} else if (item.status === "inprogress") {
							this.updateArray(item.id, "status", "complete");
						} else if (item.status === "complete") {
							this.updateArray(item.id, "status", "incomplete");
						}
					}}>
					<View
						style={{
							width: "100%",
							height: "auto",
							minHeight: 40,
							padding: 1,
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							opacity: item.status === "complete" ? 0.5 : 1,
						}}>
						<View
							style={{
								marginLeft: 3,
								width: 8,
								height: 30,
								borderRadius: 2.5,
								justifyContent: "center",
								alignItems: "center",
								backgroundColor:
									item.status === "complete"
										? "#00CB66"
										: item.status === "inprogress"
										? "#FFA400"
										: item.status === "incomplete"
										? "#FF113B"
										: "transparent",
							}}
						/>
						<View
							style={{
								flex: 1,
							}}>
							<Text
								style={{
									fontWeight:
										item.status === "inprogress"
											? "600"
											: "300",
									fontSize: 12,
									marginLeft: 10,
									flexWrap: "wrap",
									color: APP_COLOURS.BG2,
									textDecorationLine:
										item.status === "complete"
											? "line-through"
											: "none",
								}}>
								{item.title}
							</Text>
						</View>
					</View>
				</TouchableOpacity>
				{this.state.expandedRow === item.id ? (
					<View
						style={{
							marginTop: 5,
							width: "100%",
							height: "auto",
							marginBottom: 100,
							flexDirection: "column",
							// backgroundColor: '#F5F5F5',
						}}>
						<View
							style={{
								borderRadius: 5,
								width: "100%",
								height: "100%",
								flexDirection: "column",
							}}>
							<View
								style={{
									flex: 1,
									padding: 5,
								}}>
								<TextInput
									style={{
										borderRadius: 12,
										borderWidth: 1,
										padding: 8,
										height: 150,
										justifyContent: "flex-start",
										backgroundColor: "#434350",
										color: "#D8D8FF",
									}}
									onChange={(event) =>
										this.updateArray(
											item.id,
											"description",
											event.nativeEvent.text,
										)
									}
									onFocus={() =>
										this.setState({ viewChart: false })
									}
									defaultValue={item.description}
									underlineColorAndroid='transparent'
									placeholder='Description'
									placeholderTextColor={"#828282"}
									numberOfLines={10}
									multiline={true}
									editable
								/>
							</View>
							<View
								style={{
									width: 110,
									height: 150,
									flexDirection: "column",
									padding: 5,
								}}>
								<TouchableOpacity
									style={{
										flex: 1,
										padding: 5,
									}}
									onPress={() =>
										this.updateArray(
											item.id,
											"status",
											"complete",
										)
									}>
									<View
										style={{
											flex: 1,
											height: 30,
											backgroundColor: "#00CB66",
											borderRadius: 12,
											alignItems: "center",
											justifyContent: "center",
										}}>
										<Text
											style={{
												fontWeight: "800",
												fontSize: 12,
												color: "white",
											}}>
											Complete
										</Text>
									</View>
								</TouchableOpacity>

								<TouchableOpacity
									style={{
										flex: 1,
										padding: 5,
									}}
									onPress={() =>
										this.updateArray(
											item.id,
											"status",
											"inprogress",
										)
									}>
									<View
										style={{
											flex: 1,
											backgroundColor: "#FFA400",
											borderRadius: 12,
											alignItems: "center",
											justifyContent: "center",
										}}>
										<Text
											style={{
												fontWeight: "800",
												fontSize: 12,
												color: "white",
											}}>
											Inprogress
										</Text>
									</View>
								</TouchableOpacity>

								<TouchableOpacity
									style={{
										flex: 1,
										padding: 5,
									}}
									onPress={() =>
										this.updateArray(
											item.id,
											"status",
											"incomplete",
										)
									}>
									<View
										style={{
											flex: 1,
											backgroundColor: "#FF113B",
											borderRadius: 12,
											alignItems: "center",
											justifyContent: "center",
										}}>
										<Text
											style={{
												fontWeight: "800",
												fontSize: 12,
												color: "white",
											}}>
											Incomplete
										</Text>
									</View>
								</TouchableOpacity>
							</View>
						</View>

						{/* <View
            style={{
              borderRadius: 5,
              width: '100%',
              height: '100%',
              flexDirection: 'row',
            }}>
            <Text> skjvjfnvj</Text>
          </View> */}
					</View>
				) : null}
			</View>
		);
	};
}
