import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TextInput,
	ScrollView,
	Keyboard,
	Modal,
} from "react-native";
import moment from "moment";
// import DatePicker from 'react-native-date-picker';
import { APP_COLOURS } from "../../APP_VARS";

const BG = "#171B22";
const BG2 = "#20242B";
const FONT = "#F3F3F5";
const BORDER = "#383D43";

export default class ChangeDate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			docDate: "",
			dueDate: this.props.date,
			saveChanges: false,
		};
	}

	componentDidMount() {
		const dueDate =
			typeof this.props.date !== "undefined" || this.props.date === ""
				? new Date()
				: new Date(moment(this.props.date));

		this.setState({
			dueDate: dueDate,
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.date !== this.props.date) {
			this.setState({
				docDate: new Date(moment(this.props.date)),
				dueDate: this.props.date,
			});
		}
	}

	render() {
		return (
			<View
				style={{
					marginTop: 20,
				}}>
				<Text
					style={{
						fontWeight: "400",
						fontFamily: "Gotham",

						fontSize: 12,
						color: BORDER,
						marginBottom: 4,
						position: "absolute",
						top: -12,
						left: 9,
						backgroundColor: "#20242B",
						padding: 4,
						borderRadius: 8,
						zIndex: 99,
					}}>
					Due date
				</Text>
				<View
					style={{
						borderRadius: 7,
						borderColor: BORDER,
						borderWidth: 1,
					}}>
					<TouchableOpacity
						onPress={() =>
							this.setState({
								dueDate: moment(),
							})
						}>
						<View
							style={{
								width: "100%",
								padding: 13,
								// justifyContent: "center",
								// alignItems: "center",
								paddingLeft: 10,
							}}>
							<Text
								style={{
									color: FONT,
									fontWeight: "600",
									fontSize: 16,
								}}>
								{moment(this.state.dueDate).format(
									"DD/MM/YYYY",
								)}
							</Text>
						</View>
					</TouchableOpacity>

					<View
						style={{
							display:
								this.state.dueDate !== "" ? "flex" : "none",
							width: "100%",
							justifyContent: "center",
							alignItems: "center",
						}}>
						{/* <DatePicker
              date={this.state.docDate}
              textColor="#FFF"
              onDateChange={date =>
                this.setState({
                  docDate: date,
                  dueDate: moment(date).toISOString(),
                  saveChanges: true,
                })
              }
            /> */}
					</View>
				</View>

				{this.state.saveChanges ? (
					<TouchableOpacity
						onPress={() => {
							this.setState({
								showAssignment: false,
							});
							this.props.changeDate(this.state.dueDate);
						}}>
						<View
							style={{
								padding: 8,
								backgroundColor: APP_COLOURS.BLUE,
								borderRadius: 10,
								justifyContent: "center",
								alignItems: "center",
							}}>
							<Text
								style={{
									color: "#FFF",
									fontSize: 14,
								}}>
								Save
							</Text>
						</View>
					</TouchableOpacity>
				) : (
					<TouchableOpacity
						onPress={() => {
							this.setState({
								showAssignment: false,
							});
							this.props.close();
						}}>
						<View
							style={{
								padding: 8,
								borderRadius: 10,
								justifyContent: "center",
								alignItems: "center",
							}}>
							<Text
								style={{
									color: "#FFF",
									fontSize: 14,
								}}>
								close
							</Text>
						</View>
					</TouchableOpacity>
				)}
			</View>
		);
	}
}
