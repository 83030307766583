import React, { Component } from "react";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
// import moment from "moment";
// import Fade from "react-reveal/Fade";
import Font from "react-font";
import memberBadge from "../../../assets/images/memberBadge.svg";
import logo from "../../../assets/images/whiteLogo.png";
import tick from "./../../../assets/images/tick.png";
import bin from "./../../../assets/images/bin.png";
import close from "../../../assets/images/close.png";

// import {
// 	api_getStaffMembers,
// 	api_deleteStaffMember,
// 	api_addStaffMember,
// } from "../../Api";
import moment from "moment";
import TopTabs from "../TopTabs";
import AutoHeightImage from "../AutoHeightImageComp";
import SmallerTabs from "../TopTabs/SmallerTabs";
import { faceBookIcon, instagraM, tikTok } from "./tikTok";

function uuidv4() {
	return "xxxxxxx".replace(/[xy]/g, function (c) {
		let r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

function capitalizeName(name) {
	return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
}

function formatNumberWithCommas(number) {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default class ProstaffSignups extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fontSize: -3,
			showDelete: false,
			userGroups: [
				// {
				// 	title: "Staff",
				// 	group_id: 1,
				// },
				// {
				// 	title: "Staff",
				// 	group_id: 2,
				// },
				{
					title: "All Staff",
					group_id: 3,
				},
				// {
				// 	title: "Club",
				// 	group_id: 3,
				// },
			],
			formFields: [
				{
					id: 1,
					editable: true,
					state: "username",
					title: "Username",
					autoCapitalize: false,
					validation: "email",
				},
				{
					id: 2,
					editable: true,
					state: "first_name",
					title: "First name",
					autoCapitalize: true,
					validation: "name",
				},
				{
					id: 3,
					editable: true,
					state: "last_name",
					title: "Last name",
					autoCapitalize: true,
					validation: "name",
				},
				{
					id: 4,
					editable: true,
					state: "passcode",
					title: "Passcode",
					autoCapitalize: true,
					validation: "none",
				},
			],

			addNewMember: false,
			selectedUserGroup: 1,
			selecteedUserGroupName: "Staff",
			users: [
				// {
				// 	id: "0eab6853-275b-4e3c-ab55-d080a4e0c1e7",
				// 	username: "monique@TRAQair.com",
				// 	first_name: "Monique",
				// 	last_name: "Bethwaite",
				// 	gender: "female",
				// 	dob: "16/04/1989",
				// 	email: {
				// 		address: "wesleycheia@gmail.com",
				// 		verified: true,
				// 	},
				// 	cell: {
				// 		number: "081 250 2054",
				// 		verified: true,
				// 	},
				// Staffhip: {
				// 	type: "Club Staffhip",
				// 	duration: "24 month",
				// 	endDate: "23/12/2024",
				// },
				// },
			],
			filterByName: "",
			filterSearch: "",
		};
	}

	validateEmail = (text) => {
		// console.log(text);
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (reg.test(text) === false) {
			// console.log("Email is Not Correct");
			this.setState({ emailValid: false });
			return false;
		} else {
			this.setState({ emailValid: true });
			// console.log("Email is Correct");
			return true;
		}
	};

	async componentDidMount() {
		await this.validateSession();
	}

	validateSession = async () => {
		let user_data = await localStorage.getItem("user_data");
		let email = await localStorage.getItem("email");
		let accessToken = await localStorage.getItem("accessToken");
		let refresh_token = await localStorage.getItem("refresh_token");

		await this.setState({
			user_data: JSON.parse(user_data),
			email,
			accessToken,
			refresh_token,
		});

		if (accessToken !== null && accessToken !== "") {
			this.getAllUsers();
		} else {
			this.props.history.push("/");
			localStorage.clear();
			window.location.href = "/";
		}
	};

	componentWillUnmount() {}

	getAllUsers = async () => {
		let url =
			"https://workflow.tetrice.co.za/webhook/3e1bae5e-41bc-4790-ad35-b4d341b16409";

		await this.setState({
			loading: true,
			users: [],
			showDelete: false,
			addNewMember: false,
			viewmemberProfile: false,
		});

		let requestOptions = {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.state.accessToken}`,
			},
			redirect: "follow",
		};

		await fetch(url, requestOptions)
			.then((response) => {
				// console.log('response', response);
				if (response.status === 200) {
					response
						.json()
						.then(async (responseData) => {
							console.log("response", responseData);
							this.setState({
								users: responseData.data,
								loading: false,
							});
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
							});
						});
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
			});
	};

	// deleteMember = async (id) => {
	// 	let url = api_deleteStaffMember;

	// 	await this.setState({
	// 		loading: true,
	// 		users: [],
	// 	});

	// 	let requestOptions = {
	// 		method: "DELETE",
	// 		headers: {
	// 			Accept: "application/json",
	// 			"Content-Type": "application/json",
	// 			Origin: "",
	// 			dataType: "json",
	// 			Authorization: `Bearer ${this.state.accessToken}`,
	// 		},
	// 		redirect: "follow",
	// 		body: JSON.stringify({
	// 			id: id,
	// 		}),
	// 	};

	// 	await fetch(url, requestOptions)
	// 		.then((response) => {
	// 			if (response.status === 200) {
	// 				this.getAllUsers();
	// 			} else if (response.status == 401) {
	// 				this.props.logOut();
	// 			} else {
	// 				alert("Error deleting");
	// 				this.setState({ loading: false });
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			console.log(error);
	// 			this.setState({
	// 				loading: false,
	// 			});
	// 		});
	// };

	// addNewMember = () => {
	// 	return (
	// 		<Modal animationType='fade' transparent={true} visible={true}>
	// 			<View
	// 				style={{
	// 					flex: 1,
	// 					justifyContent: "center",
	// 					alignItems: "center",
	// 					backgroundColor: "rgba(52, 52, 52, 0.6)",
	// 				}}>
	// 				<View
	// 					style={{
	// 						width: "100%",
	// 						maxWidth: 500,
	// 						height: "auto",
	// 						backgroundColor: "white",
	// 						borderRadius: 20,
	// 						justifyContent: "center",
	// 						alignItems: "center",
	// 					}}>
	// 					<View
	// 						style={{
	// 							position: "absolute",
	// 							zIndex: 99,
	// 							top: 10,
	// 							left: 10,
	// 						}}>
	// 						<TouchableOpacity
	// 							onPress={() =>
	// 								this.setState({
	// 									addNewMember: false,
	// 								})
	// 							}>
	// 							<Image
	// 								source={close}
	// 								style={{
	// 									width: 20,
	// 									height: 20,
	// 									resizeMode: "contain",
	// 								}}
	// 							/>
	// 						</TouchableOpacity>
	// 					</View>
	// 					<View
	// 						style={{
	// 							width: "100%",
	// 							padding: 20,
	// 							height: "auto",
	// 						}}>
	// 						<Text
	// 							allowFontScaling={false}
	// 							style={{
	// 								fontSize: 17 + this.state.fontSize,
	// 								fontFamily: "Gotham",
	// 								textAlign: "center",
	// 								fontWeight: "600",
	// 								marginBottom: 20,
	// 							}}>
	// 							Add staff member
	// 						</Text>
	// 						{/* <Text
	// 							allowFontScaling={false}
	// 							style={{
	// 								fontSize: 16 + this.state.fontSize,
	// 								fontFamily: "Gotham",
	// 								textAlign: "center",
	// 								fontWeight: "400",
	// 								marginBottom: 20,
	// 								width: "100%",
	// 							}}>
	// 							Please note, correctly formatted CSV/Excel spreadsheets
	// 							are neccessary for the assessment to be uploaded and
	// 							displayed correctly.
	// 						</Text> */}
	// 						{this.state.formFields.map((d, i) => {
	// 							let validated = `${d.state}_valid`;
	// 							return (
	// 								<View
	// 									key={i}
	// 									style={{
	// 										width: "auto",
	// 										marginBottom: 16,
	// 										flexDirection: "row",
	// 										alignItems: "center",
	// 									}}>
	// 									<Text
	// 										style={{
	// 											opacity: this.state.unEditable
	// 												? 0.4
	// 												: 1,
	// 											fontFamily: "Gotham",
	// 											fontSize: 12,
	// 											color: APP_COLOURS.PLACEHOLDER,
	// 											fontWeight: "500",
	// 											marginBottom: 4,
	// 											position: "absolute",
	// 											top: -12,
	// 											left: 9,
	// 											backgroundColor: "#FFF",
	// 											padding: 4,
	// 											borderRadius: 8,
	// 										}}>
	// 										{d.title}
	// 									</Text>
	// 									<TextInput
	// 										style={{
	// 											width: "100%",
	// 											opacity: this.state.unEditable
	// 												? 0.4
	// 												: 1,
	// 											borderWidth: 1,
	// 											borderColor: this.state[
	// 												validated
	// 											]
	// 												? APP_COLOURS.GREEN
	// 												: typeof this.state[
	// 														d.state
	// 												  ] !== "undefined"
	// 												? "#000"
	// 												: APP_COLOURS.RED,
	// 											padding: 12,
	// 											paddingLeft: 30,
	// 											fontSize: 16,
	// 											borderRadius: 8,
	// 											backgroundColor:
	// 												APP_COLOURS.WHITE,
	// 											color: "#000",
	// 											fontWeight: "200",
	// 											fontFamily: "Gotham",
	// 										}}
	// 										placeholderTextColor={"#000"}
	// 										autoCapitalize='words'
	// 										clearButtonMode='while-editing'
	// 										autoCompleteType='email'
	// 										editable={d.editable}
	// 										onFocus={() => {
	// 											this.setState({
	// 												unEditable: !d.editable,
	// 											});
	// 										}}
	// 										onBlur={() => {
	// 											this.setState({
	// 												unEditable: false,
	// 											});
	// 										}}
	// 										autoCorrect={false}
	// 										keyboardType='email-address'
	// 										textContentType='emailAddress'
	// 										placeholder={d.title}
	// 										// defaultValue={this.state.user_data.email}
	// 										value={this.state[d.state]}
	// 										onChangeText={async (text) => {
	// 											let valid = false;

	// 											if (d.autoCapitalize) {
	// 												text = await capitalizeName(
	// 													text,
	// 												);
	// 											}

	// 											if (d.validation === "email") {
	// 												valid =
	// 													await this.validateEmail(
	// 														text,
	// 													);
	// 											}

	// 											await this.setState({
	// 												[d.state]: text,
	// 												[validated]: valid,
	// 											});
	// 										}}
	// 									/>

	// 									{this.state.unEditable &&
	// 									!d.editable ? (
	// 										<View
	// 											style={{
	// 												position: "absolute",
	// 												width: "100%",
	// 												height: "100%",
	// 												backgroundColor: `rgba(52, 52, 52, 0.3)`,
	// 												flex: 1,
	// 												justifyContent: "center",
	// 												alignItems: "center",
	// 												borderRadius: 8,
	// 											}}>
	// 											<Text
	// 												style={{
	// 													fontFamily: "Gotham",
	// 													fontSize: 12,
	// 													color: APP_COLOURS.WHITE,
	// 													fontWeight: "700",
	// 												}}>
	// 												Uneditable field
	// 											</Text>
	// 										</View>
	// 									) : null}

	// 									{this.state[validated] ? (
	// 										<Image
	// 											source={tick}
	// 											style={{
	// 												width: 16,
	// 												height: 16,
	// 												resizeMode: "contain",
	// 												position: "absolute",
	// 												left: 9,
	// 												top: 16,
	// 											}}
	// 										/>
	// 									) : null}
	// 								</View>
	// 							);
	// 						})}
	// 					</View>

	// 					<TouchableOpacity
	// 						style={{
	// 							width: this.state.addingStaff ? "auto" : "100%",
	// 							padding: 10,
	// 						}}
	// 						onPress={async () => {
	// 							await this.addStaffMember();
	// 						}}>
	// 						<View
	// 							style={{
	// 								backgroundColor: APP_COLOURS.RED,
	// 								width: this.state.addingStaff
	// 									? "auto"
	// 									: "100%",
	// 								padding: 13,
	// 								height: 45,
	// 								borderRadius: 25,
	// 								justifyContent: "center",
	// 								alignItems: "center",
	// 							}}>
	// 							{!this.state.addingStaff ? (
	// 								<Text
	// 									style={{
	// 										alignItems: "center",
	// 										justifyContent: "center",
	// 										color: "#FFF",
	// 										fontSize: 15,
	// 									}}>
	// 									<Font weight={700} family='Montserrat'>
	// 										Add staff member
	// 									</Font>
	// 								</Text>
	// 							) : (
	// 								<ActivityIndicator color={"#FFF"} />
	// 							)}
	// 						</View>
	// 					</TouchableOpacity>
	// 				</View>
	// 			</View>
	// 		</Modal>
	// 	);
	// };

	// addStaffMember = async () => {
	// 	this.setState({
	// 		addingStaff: true,
	// 		errorLogin: false,
	// 	});

	// 	let { first_name, last_name, username, passcode } = this.state;

	// 	let date_created = moment().toISOString();

	// 	let permission = "1";
	// 	let obj = {
	// 		first_name,
	// 		last_name,
	// 		username,
	// 		passcode,
	// 		permission,
	// 		date_created,
	// 	};

	// 	var requestOptions = {
	// 		method: "POST",
	// 		headers: {
	// 			Accept: "application/json",
	// 			"Content-Type": "application/json",
	// 			Origin: "",
	// 			dataType: "json",
	// 			Authorization: `Bearer ${this.state.accessToken}`,
	// 		},
	// 		redirect: "follow",
	// 		body: JSON.stringify(obj),
	// 	};
	// 	await fetch(api_addStaffMember, requestOptions)
	// 		.then(async (response) => {
	// 			console.log("response =====", response);
	// 			if (response.status === 200) {
	// 				alert("Member added");
	// 				this.getAllUsers();
	// 			} else {
	// 				alert("error adding staff member");
	// 				this.setState({
	// 					addingStaff: false,
	// 				});
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			this.setState({
	// 				addingStaff: false,
	// 			});
	// 		});
	// };

	memberProfileModal = (d) => {
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						// justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<ScrollView>
						<View
							style={{
								height: 90,
								width: "100%",
							}}
						/>
						<View
							style={{
								width: "100%",
								maxWidth: 500,
								// height: "80%",
								backgroundColor: "#FFF",
								borderRadius: 20,
								// justifyContent: "center",
								// alignItems: "center",
							}}>
							<View
								style={{
									position: "absolute",
									zIndex: 99,
									top: 10,
									left: 10,
								}}>
								<TouchableOpacity
									onPress={() =>
										this.setState({
											viewmemberProfile: false,
										})
									}>
									<Image
										source={close}
										style={{
											width: 20,
											height: 20,
											resizeMode: "contain",
										}}
									/>
								</TouchableOpacity>
							</View>
							<View
								style={{
									width: "100%",
									padding: 20,
									height: "auto",
								}}>
								<View
									style={{
										justifyContent: "center",
										alignItems: "center",
										padding: 20,
									}}>
									<View
										style={{
											width: "100%",
											maxWidth: 500,
											alignItems: "center",
											justifyContent: "center",
											borderRadius: 55,
											// borderWidth: 1,
											borderColor: "#797979",
											backgroundColor: "#797979",
											shadowColor: "#000",
											shadowOffset: {
												width: 0,
												height: 2,
											},
											shadowOpacity: 0.25,
											shadowRadius: 7.84,
											elevation: 12,
											overflow: "hidden",
										}}>
										<Image
											source={{ uri: d.imageData.url }}
											style={{
												width: 500,
												height: 500,
												resizeMode: "cover",
											}}
										/>
									</View>
								</View>

								<Text
									allowFontScaling={false}
									style={{
										fontSize: 20,
										fontFamily: "Gotham",
										textAlign: "center",
										fontWeight: "600",
										// marginBottom: 20,
									}}>
									{d.first_name} {d.last_name}
								</Text>

								<View
									style={{
										marginTop: 10,

										opacity: 0.7,
									}}>
									<Text
										allowFontScaling={false}
										style={{
											fontSize: 15,
											fontFamily: "Gotham",
											textAlign: "center",
											fontWeight: "500",
										}}>
										{d?.demographic?.race}{" "}
										{d?.demographic?.gender}{" "}
										{d?.demographic?.age}
									</Text>
								</View>
								<View
									style={{
										marginTop: 3,
										marginBottom: 20,
										opacity: 0.7,
									}}>
									<Text
										allowFontScaling={false}
										style={{
											fontSize: 15,
											fontFamily: "Gotham",
											textAlign: "center",
											fontWeight: "500",
										}}>
										{d?.city} {d?.country}
									</Text>
								</View>
								<TouchableOpacity
									onPress={() => {
										// window.open(d.instagram_link, "_blank");

										// return;
										if (
											typeof d.instagram_link !==
											"undefined"
										) {
											window.open(
												d.instagram_link,
												"_blank",
											);
										} else {
											window.open(
												`https://www.instagram.com/${d.instragram}`,
												"_blank",
											);
										}
									}}>
									<View
										style={{
											// width: col2,
											flexDirection: "row",
											alignItems: "center",
										}}>
										<View
											style={{
												flex: 1,
												flexDirection: "row",
												alignItems: "center",
											}}>
											<Image
												source={{
													uri: instagraM,
												}}
												style={{
													width: 25,
													height: 25,
													resizeMode: "cover",
													marginRight: 5,
												}}
											/>
											<Text
												style={{
													fontSize: 17,
												}}>
												<Text
													style={{
														fontWeight: "600",
													}}>
													{formatNumberWithCommas(
														parseInt(
															d.instagram_followers,
														),
													)}
												</Text>
											</Text>
										</View>
										<Text
											style={{
												fontSize: 17,
											}}>
											<Text
												style={{
													fontWeight: "600",
												}}>
												{d.instragram}
											</Text>
										</Text>
									</View>
								</TouchableOpacity>

								<TouchableOpacity
									onPress={() => {
										window.open(d.tiktok_link, "_blank");
									}}>
									<View
										style={{
											// width: col2,
											flexDirection: "row",
											alignItems: "center",
											marginTop: 5,
										}}>
										<View
											style={{
												flex: 1,
												flexDirection: "row",
												alignItems: "center",
											}}>
											<Image
												source={{ uri: tikTok }}
												style={{
													width: 25,
													height: 25,
													resizeMode: "cover",
													marginRight: 5,
												}}
											/>
											<Text
												style={{
													fontSize: 17,
												}}>
												<Text
													style={{
														fontWeight: "600",
													}}>
													{formatNumberWithCommas(
														parseInt(
															d.TikTokfollowers,
														),
													)}
												</Text>
											</Text>
										</View>
										<Text
											style={{
												fontSize: 17,
											}}>
											<Text
												style={{
													fontWeight: "600",
												}}>
												{d.TikTok}
											</Text>
										</Text>
									</View>
								</TouchableOpacity>

								<TouchableOpacity
									onPress={() => {
										if (
											typeof d.facebook_link !==
											"undefined"
										) {
											window.open(
												d.facebook_link,
												"_blank",
											);
										} else {
										}
									}}>
									<View
										style={{
											// width: col2,
											flexDirection: "row",
											alignItems: "center",
											marginTop: 5,
										}}>
										<View
											style={{
												flex: 1,
												flexDirection: "row",
												alignItems: "center",
											}}>
											<Image
												source={{
													uri: faceBookIcon,
												}}
												style={{
													width: 25,
													height: 25,
													resizeMode: "cover",
													marginRight: 5,
												}}
											/>
											<Text
												style={{
													fontSize: 17,
												}}>
												<Text
													style={{
														fontWeight: "600",
													}}>
													{formatNumberWithCommas(
														parseInt(
															d.Facebookfollowers,
														),
													)}
												</Text>
											</Text>
										</View>
										<Text
											style={{
												fontSize: 17,
											}}>
											<Text
												style={{
													fontWeight: "600",
												}}>
												{d.Facebook}
											</Text>
										</Text>
									</View>
								</TouchableOpacity>

								<View
									style={{
										marginTop: 15,
									}}>
									<View
										style={{
											flex: 1,
											paddingLeft: 15,
										}}>
										<Text
											adjustsFontSizeToFit
											style={{
												color: "#000",
												fontSize: 10,
											}}>
											<Font
												weight={600}
												family='Montserrat'>
												Contacts
											</Font>
										</Text>
									</View>
									<View
										style={{
											padding: 15,
											paddingRight: 50,
										}}>
										<View
											style={{
												marginTop: 15,
												marginBottom: 10,
											}}>
											<Text
												allowFontScaling={false}
												style={{
													fontSize: 18,
													fontFamily: "Gotham",
													textAlign: "center",
													fontWeight: "600",
													marginBottom: 20,
												}}>
												{d.email}
											</Text>
										</View>

										{/* <View
											style={{
												marginTop: 15,
												marginBottom: 10,
											}}>
											<Text
												allowFontScaling={false}
												style={{
													fontSize: 18,
													fontFamily: "Gotham",
													textAlign: "center",
													fontWeight: "600",
													marginBottom: 20,
												}}>
												{d.email}
											</Text>
										</View> */}
									</View>
								</View>

								<View
									style={{
										width: "100%",
									}}>
									<Text
										allowFontScaling={false}
										style={{
											fontSize: 13,
											fontFamily: "Gotham",
											// textAlign: "center",
											fontWeight: "600",
										}}>
										Interests:
									</Text>
									{typeof d.interests !== "undefined"
										? d.interests?.map((dd) => (
												<View
													style={{
														marginTop: 1,
													}}>
													<Text
														allowFontScaling={false}
														style={{
															fontSize: 16,
															fontFamily:
																"Gotham",
															// textAlign: "center",
															fontWeight: "400",
														}}>
														{dd}
													</Text>
												</View>
										  ))
										: null}
								</View>

								{/* <TouchableOpacity
									onPress={() => this.deleteMember(d.id)}
									style={{
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Image
										source={bin}
										style={{
											width: 22,
											height: 22,
											resizeMode: "contain",
											marginRight: 5,
										}}
									/>
								</TouchableOpacity> */}
							</View>
						</View>
					</ScrollView>
				</View>
			</Modal>
		);
	};

	render() {
		let col1 = 200,
			col2 = 100,
			col3 = 50;

		let fs = 12;

		// .filter((d_) => d_.user_type == this.state.selectedUserGroup)

		let users = this.state.users

			.filter((d_) => {
				let filter = d_;

				if (
					typeof this.state.filterByName !== "undefined" &&
					this.state.filterByName !== ""
				) {
					let text_ = this.state.filterByName;
					var outString = text_.replace(
						/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
						"",
					);

					let join_names = `${d_.last_name} ${d_.first_name}`;

					return join_names
						.toLowerCase()
						.includes(outString.toLowerCase());
				}

				return filter;
			})

			.sort((a, b) => b.last_name < a.last_name);

		return (
			<div
				style={{
					overflow: "-moz-hidden-unscrollable",
					flex: 1,
					padding: 8,
				}}>
				<View style={{ marginRight: 1 }}>
					<View
						style={{
							flex: 1,
							// flexDirection: "row",
							marginBottom: 5,
							// maxWidth: 600,
							// paddingBottom: 60,
							backgroundColor: "#FFF",
							padding: 5,
							borderRadius: 10,
						}}>
						<View
							style={{
								width: "100%",
								flexDirection: "row",
								justifyContent: "space-between",
								alignItems: "center",
							}}>
							<View
								style={{
									padding: 10,
								}}>
								<TouchableOpacity
									style={{
										flexDirection: "row",
										alignItems: "center",
										// position: "absolute",
									}}
									onPress={() => {
										// this.props.close();
									}}>
									<AutoHeightImage
										width={20}
										source={require("../../../assets/images/menu.png")}
									/>
									<Text
										adjustsFontSizeToFit
										style={{
											fontWeight: "800",
											fontFamily: "Gotham",
											color: APP_COLOURS.BG2,
											fontSize: 13,
											marginLeft: 4,
										}}>
										ProStaff
									</Text>
								</TouchableOpacity>
								<Text
									style={{
										color: APP_COLOURS.BG2,
										fontSize: 28,
										fontWeight: "900",
										fontFamily: "Gotham",
										// marginTop: 20,
										// marginTop: 6,
									}}>
									Persona Management
								</Text>

								<Text
									style={{
										fontSize: 16,
										color: APP_COLOURS.BG2,
										fontWeight: "400",
										padding: 2,
										// marginBottom: 20,
									}}></Text>
							</View>
							<View
								style={{
									position: "absolute",
									right: 10,
									top: 0,
								}}>
								<AutoHeightImage
									width={150}
									source={require("../../../assets/images/shoutImage.png")}
								/>
							</View>
						</View>

						<View
							style={{
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "space-between",
							}}>
							<SmallerTabs
								selectedSubPage={(d) => {
									this.setState({
										selectedTab: d,
									});
									console.log(d);
								}}
								tabs={[
									"Signups",
									"Shortlisted",
									"Macro",
									"Micro",
								]}
							/>
						</View>

						<Text
							style={{
								width: col1,
								fontSize: fs,
							}}>
							total:
							<Text
								style={{
									fontWeight: "600",
									fontSize: 25,
								}}>
								{this.state.users.length}
							</Text>
						</Text>
					</View>
				</View>

				<View
					style={{
						flex: 1,
						// flexDirection: 'row',
						marginTop: 16,
					}}>
					<View
						style={{
							// flex: 1,
							paddingRight: 8,
							borderTopColor: "#797979",
							borderTopWidth: 0.3,
						}}>
						<View
							style={{
								// flex: 1,
								height: this.props.screenHeight - 200,
								// padding: 8,
								paddingTop: 8,
							}}>
							<View
								style={{
									width: "100%",
									flexDirection: "row",
									padding: 5,
									backgroundColor: "#FFF",
									marginBottom: 1,
								}}>
								<View
									style={{
										flex: 1,
									}}>
									<TextInput
										style={{
											fontWeight: "400",
											fontFamily: "Gotham",

											width: "100%",
											paddingLeft: 15,
											padding: 10,
											fontSize: 16,
											borderRadius: 13,
											marginRight: 15,
											color: APP_COLOURS.BG2,
											backgroundColor:
												APP_COLOURS.WHITE_TRANSPARENT4,
											fontWeight: "500",
										}}
										placeholderTextColor={"#797979"}
										autoCapitalize='sentences'
										clearButtonMode='while-editing'
										autoCompleteType='off'
										editable={true}
										autoCorrect={true}
										autoFocus
										keyboardType='default'
										textContentType='none'
										placeholder={` Search `}
										// defaultValue={this.state.user_data.email}
										defaultValue={this.state.filterSearch}
										onChangeText={(text) => {
											console.log(text);
											this.setState({
												filterSearch: text,
											});
										}}
									/>
								</View>
							</View>
							<ScrollView>
								{/* {
                           .sort(
										(a, b) =>
											parseInt(a.instagram_followers) <
											parseInt(b.instagram_followers),
									)
                        } */}
								{users
									.sort(
										(a, b) =>
											moment(a.createdAt).unix() <
											moment(b.createdAt).unix(),
									)
									.filter((d_) => {
										let filter = d_;

										if (
											typeof this.state.filterSearch !==
												"undefined" &&
											this.state.filterSearch !== ""
										) {
											let text_ = this.state.filterSearch;
											var outString = text_.replace(
												/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
												"",
											);

											let join_names = `${d_.first_name} ${d_.last_name} ${d_.country} ${d_.city} ${d_.TikTok} ${d_.instragram}  ${d_.facebook} ${d_.demographic?.race} ${d_.demographic?.age}`;

											join_names = join_names.replace(
												/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
												"",
											);

											return join_names
												.toLowerCase()
												.includes(
													outString.toLowerCase(),
												);
										}

										return filter;
									})
									.map((d, i) => (
										<View
											key={i}
											style={{
												// flex: 1,
												borderRadius: 5,
												flexDirection: "row",
												borderColor: "#C5C5C5",
												borderWidth:
													this.state.showDelete ===
													d.id
														? 1
														: 0,
												backgroundColor:
													this.state.hover == i
														? APP_COLOURS.WHITE
														: APP_COLOURS.OFFWHITE,
												marginBottom: 1,
											}}>
											<TouchableOpacity
												onPress={() => {
													this.setState({
														// showDelete: d.id,

														profileData: d,
														viewmemberProfile: true,
													});
												}}>
												<View
													key={i}
													onMouseEnter={() =>
														this.setState({
															hover: i,
														})
													}
													onMouseLeave={() =>
														this.setState({
															hover: -1,
														})
													}
													style={{
														// flex: 1,
														opacity:
															this.state.hover ===
															i
																? 1
																: 0.9,

														height: "auto",
														padding: 4,
														alignItems: "center",

														marginRight: 10,
														height: "auto",
														marginBottom: 2,

														flexDirection: "row",
													}}>
													<View
														style={{
															width: 5,
															borderRadius: 4,
															height: 30,
															backgroundColor:
																this.state
																	.selectedUserID ===
																d.id
																	? APP_COLOURS.BLUE
																	: "transparent",
															marginRight: 12,
														}}
													/>
													<Text
														style={{
															width: col1,
															fontSize: fs,
														}}>
														{d.first_name}
														<Text
															style={{
																fontWeight:
																	"600",
															}}>
															{" "}
															{d.last_name}
														</Text>
													</Text>

													<View
														style={{
															width: col2,
															flexDirection:
																"row",
															alignItems:
																"center",
															opacity:
																parseInt(
																	d.TikTokfollowers,
																) > 0
																	? 1
																	: 0.2,
														}}>
														<Image
															source={{
																uri: tikTok,
															}}
															style={{
																width: 20,
																height: 20,
																resizeMode:
																	"cover",
																marginRight: 5,
															}}
														/>
														<Text
															style={{
																fontSize:
																	fs - 1,
															}}>
															<Text
																style={{
																	fontWeight:
																		"600",
																}}>
																{formatNumberWithCommas(
																	parseInt(
																		d.TikTokfollowers,
																	),
																)}
															</Text>
														</Text>
													</View>

													<View
														style={{
															width: col2,
															flexDirection:
																"row",
															alignItems:
																"center",
															opacity:
																parseInt(
																	d.instagram_followers,
																) > 0
																	? 1
																	: 0.2,
														}}>
														<Image
															source={{
																uri: instagraM,
															}}
															style={{
																width: 20,
																height: 20,
																resizeMode:
																	"cover",
																marginRight: 5,
															}}
														/>
														<Text
															style={{
																fontSize:
																	fs - 1,
															}}>
															<Text
																style={{
																	fontWeight:
																		"600",
																}}>
																{formatNumberWithCommas(
																	parseInt(
																		d.instagram_followers,
																	),
																)}
															</Text>
														</Text>
													</View>

													<View
														style={{
															width: col2,
															flexDirection:
																"row",
															alignItems:
																"center",
															opacity:
																parseInt(
																	d.Facebookfollowers,
																) > 0
																	? 1
																	: 0.2,
														}}>
														<Image
															source={{
																uri: faceBookIcon,
															}}
															style={{
																width: 20,
																height: 20,
																resizeMode:
																	"cover",
																marginRight: 5,
															}}
														/>
														<Text
															style={{
																fontSize:
																	fs - 1,
															}}>
															<Text
																style={{
																	fontWeight:
																		"600",
																}}>
																{d.Facebookfollowers >
																0
																	? formatNumberWithCommas(
																			parseInt(
																				d.Facebookfollowers,
																			),
																	  )
																	: ""}
															</Text>
														</Text>
													</View>

													{[
														{
															title: "email",
															state: "email",
														},
														{
															title: "city",
															state: "city",
														},
														{
															title: "city",
															state: "country",
														},
													].map((p) => {
														return (
															<View
																style={{
																	width: 150,
																	marginRight: 10,
																}}>
																<View
																	style={{}}>
																	<Text
																		style={{
																			fontSize:
																				fs -
																				5,
																			opacity: 0.7,
																		}}>
																		{
																			p.title
																		}
																	</Text>

																	<Text
																		style={{
																			fontSize:
																				fs -
																				1,
																		}}>
																		{
																			d[
																				p
																					.state
																			]
																		}
																	</Text>
																</View>
															</View>
														);
													})}
													<View
														style={{
															width: col2,
														}}>
														<Text
															style={{
																fontSize:
																	fs - 1,
															}}>
															{moment(
																d.createdAt,
															).format(
																"MMM DD, YYYY",
															)}
														</Text>
													</View>
												</View>
											</TouchableOpacity>

											{this.state.showDelete === d.id ? (
												<TouchableOpacity
													onPress={() =>
														this.deleteMember(d.id)
													}
													style={{
														justifyContent:
															"center",
														alignItems: "center",
													}}>
													<Image
														source={bin}
														style={{
															width: 22,
															height: 22,
															resizeMode:
																"contain",
															marginRight: 5,
														}}
													/>
												</TouchableOpacity>
											) : null}
										</View>
									))}
								{this.state.loading ? (
									<View
										style={{
											flex: 1,
											justifyContent: "center",
											alignItems: "center",
											minHeight: 50,
										}}>
										<ActivityIndicator
											color={APP_COLOURS.BLUE}
										/>
									</View>
								) : null}
							</ScrollView>
						</View>
					</View>
				</View>
				{this.state.addNewMember ? this.addNewMember() : <View />}
				{this.state.viewmemberProfile ? (
					this.memberProfileModal(this.state.profileData)
				) : (
					<View />
				)}
			</div>
		);
	}
}
