import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	TextInput,
	LayoutAnimation,
	Keyboard,
	Alert,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
// import AutoHeightImage from "react-native-auto-height-image";

import {
	APP_COLOURS,
	screenHeight,
	screenWidth,
	CustomLayoutSpring,
	Avatars,
	AppIcons,
} from "../APP_VARS";
import { crudGetUsers, crudReadAll } from "../CRUDHELPER";
// import AsyncStorage from '@react-native-async-storage/async-storage';

export default class UsersSelectCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user_data: { id: "" },
			loading: true,
			users: [],

			saveChanges: false,
			filterSearch: "",

			selectedid: "",
		};
	}

	componentDidMount() {
		this.getusers();
	}

	componentDidUpdate(prevProps) {}

	getusers = async () => {
		crudGetUsers((users) => {
			this.setState({
				users: users,
				loading: false,
			});
		});
	};

	render() {
		return (
			<View
				style={{
					width: "100%",
					borderLeftWidth: 0.5,
				}}>
				<View style={{ flex: 1 }}>
					<ScrollView
						horizontal
						showsHorizontalScrollIndicator={false}
						contentContainerStyle={
							{
								// width: "100%",
								// // paddingRight: 15,
								// paddingBottom: 2,
							}
						}>
						{this.state.loading ? (
							<View
								style={{
									flex: 1,
									width: "100%",
									alignItems: "center",
									opacity: 0.5,
									flexDirection: "column",
									//  backgroundColor: APP_COLOURS.WHITE_TRANSPARENT5,
									borderRadius: 13,
									marginLeft: 10,
								}}>
								<View
									style={{
										padding: 5,
										borderRadius: 50,
										backgroundColor: APP_COLOURS.PRIMARY,
										opacity: 0.8,
									}}>
									<ActivityIndicator
										color={"#FFF"}
										size='small'
									/>
								</View>
							</View>
						) : null}

						{this.state.users
							.sort((a, b) => a.first_name > b.first_name)
							.filter((d_) => {
								let filter = d_;

								if (
									typeof this.state.filterSearch !==
										"undefined" &&
									this.state.filterSearch !== ""
								) {
									let text_ = this.state.filterSearch;
									var outString = text_.replace(
										/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
										"",
									);

									let join_names = `${d_.first_name} ${d_.last_name} ${d_.organisation} ${d_.id}`;

									join_names = join_names.replace(
										/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
										"",
									);

									return join_names
										.toLowerCase()
										.includes(outString.toLowerCase());
								}

								return filter;
							})
							.filter((d) => d.id !== this.props.user_data.id)
							.map((user, i) => {
								let selectedid = this.state.selectedid;
								let selected = selectedid === user.id;
								return (
									<TouchableOpacity
										key={i}
										onPress={async () => {
											if (
												this.state.selectedid !==
												user.id
											) {
												this.props.selectedUser(user);
												this.setState({
													selected: user,
													selectedid: user.id,
												});
											} else {
												this.props.selectedUser("");
												this.setState({
													selected: "",
													selectedid: "",
												});
											}
										}}
										style={{
											padding: 5,
											// flexDirection: "row",
											justifyContent: "flex-start",
											alignItems: "center",
											marginBottom: 2,
											// borderWidth: 0.5,
											width: 55,
											// height: 80,
											backgroundColor: selected
												? APP_COLOURS.BG2
												: "transparent",
											borderRadius: 12,
										}}>
										{typeof user.avatar !== "undefined" ? (
											<Image
												source={
													Avatars[
														parseInt(user.avatar)
													].pp
												}
												style={[
													{
														width: 30,
														height: 30,
														borderRadius: 25,
														resizeMode: "contain",
														backgroundColor: "#FFF",
													},
												]}
											/>
										) : (
											<Image
												source={Avatars[1].pp}
												style={{
													width: 30,
													height: 30,
													borderRadius: 25,
													resizeMode: "contain",
													backgroundColor: "#FFF",
													// marginRight: 5,
												}}
											/>
										)}

										<View style={{}}>
											<Text
												numberOfLines={1}
												adjustsFontSizeToFit
												style={{
													fontFamily: "Gotham",
													fontSize: 8,
													fontWeight: "400",
													color: selected
														? "#FFF"
														: APP_COLOURS.BG2,
												}}>
												<Text
													numberOfLines={1}
													adjustsFontSizeToFit
													style={{
														fontWeight: "800",
													}}>
													{user.first_name}
												</Text>
											</Text>
										</View>
									</TouchableOpacity>
								);
							})}
					</ScrollView>
				</View>
			</View>
		);
	}
}
