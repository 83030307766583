import React, { useRef, useState, useEffect } from "react";

const SignaturePad = ({ onEnd }) => {
	const canvasRef = useRef(null);
	const [isDrawing, setIsDrawing] = useState(false);

	useEffect(() => {
		const canvas = canvasRef.current;
		const ctx = canvas.getContext("2d");

		// Adjust for high DPI displays
		const dpi = window.devicePixelRatio;
		// Set the display size
		const displayWidth = window.innerWidth > 600 ? 600 : window.innerWidth;
		const displayHeight =
			window.innerWidth > 600 ? 600 * 0.8 : window.innerWidth * 0.8;
		canvas.style.width = `${displayWidth}px`;
		canvas.style.height = `${displayHeight}px`;
		// Set the actual size
		canvas.width = displayWidth * dpi;
		canvas.height = displayHeight * dpi;

		// Scale the context to ensure correct drawing coordinates
		ctx.scale(dpi, dpi);
	}, []);

	const getCoordinates = (event) => {
		if (!canvasRef.current) {
			return { x: 0, y: 0 };
		}

		const canvas = canvasRef.current;
		const rect = canvas.getBoundingClientRect();
		const x = (event.clientX || event.touches[0].clientX) - rect.left;
		const y = (event.clientY || event.touches[0].clientY) - rect.top;
		return { x, y };
	};

	const startDrawing = (event) => {
		const { x, y } = getCoordinates(event);
		const ctx = canvasRef.current.getContext("2d");
		ctx.beginPath();
		ctx.moveTo(x, y);
		setIsDrawing(true);
	};

	const draw = (event) => {
		if (!isDrawing) return;
		const { x, y } = getCoordinates(event);
		const ctx = canvasRef.current.getContext("2d");
		ctx.lineTo(x, y);
		ctx.stroke();
	};

	const stopDrawing = () => {
		const ctx = canvasRef.current.getContext("2d");
		ctx.closePath();
		setIsDrawing(false);
		onEnd(canvasRef.current.toDataURL("image/png"));
	};

	const clearCanvas = () => {
		const canvas = canvasRef.current;
		const ctx = canvas.getContext("2d");
		ctx.clearRect(0, 0, canvas.width, canvas.height);
	};

	return (
		<div>
			<canvas
				ref={canvasRef}
				width={window.innerWidth > 600 ? 600 : window.innerWidth}
				height={
					window.innerWidth > 600
						? 600 * 0.8
						: window.innerWidth * 0.8
				}
				onMouseDown={startDrawing}
				onMouseUp={stopDrawing}
				// onMouseOut={stopDrawing}
				onMouseMove={draw}
				onTouchStart={startDrawing}
				// onTouchEnd={stopDrawing}
				onTouchMove={draw}
				style={{}}
			/>
			<button onClick={clearCanvas}>Clear</button>
		</div>
	);
};

export default SignaturePad;
