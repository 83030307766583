import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	TextInput,
	LayoutAnimation,
	Keyboard,
	Alert,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
// import AutoHeightImage from "react-native-auto-height-image";

import {
	APP_COLOURS,
	screenHeight,
	screenWidth,
	CustomLayoutSpring,
	Avatars,
	AppIcons,
} from "../APP_VARS";
import { crudGetUsers, crudReadAll } from "../CRUDHELPER";
// import AsyncStorage from '@react-native-async-storage/async-storage';

export default class UsersSelectList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user_data: { id: "" },
			loading: true,
			users: [],
			assignment_ids: this.props.assignment_ids,
			assignment: this.props.assignment,
			saveChanges: false,
			filterSearch: "",
		};
	}

	componentDidMount() {
		console.log(this.props.assignment_ids, this.props.assignment);
		this.validateSession();
		this.getusers();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.assignment_ids !== this.props.assignment_ids ||
			prevProps.assignment !== this.props.assignment
		) {
			this.setState({
				assignment_ids: this.props.assignment_ids,
				assignment: this.props.assignment,
			});

			// console.log(this.props.assignment_ids, this.props.assignment);
		}
	}

	getusers = async () => {
		crudGetUsers((users) => {
			this.setState({
				users: users,
				loading: false,
			});
		});
	};

	validateSession = async () => {
		let user_data = await localStorage.getItem("user_data");
		let email = await localStorage.getItem("email");
		let accessToken = await localStorage.getItem("accessToken");
		let refresh_token = await localStorage.getItem("refresh_token");

		if (accessToken !== null && accessToken !== "") {
			await this.setState({
				user_data: JSON.parse(user_data),
				email,
				accessToken,
				refresh_token,
			});
			setTimeout(() => {
				this.setState({
					loading: false,
					authenticating: false,
				});
			}, 1000);
		} else {
			window.location.href = "/";
			localStorage.clear();
		}
	};

	render() {
		return (
			<View
				style={{
					flex: 1,
					width: "100%",
					borderRadius: 12,
					borderWidth: 0.5,
					// paddingHorizontal: 5,
					padding: 5,
				}}>
				<TextInput
					style={{
						fontWeight: "400",
						fontFamily: "Gotham",

						width: "100%",
						paddingLeft: 15,
						padding: 10,
						fontSize: 16,
						borderRadius: 13,
						marginRight: 15,
						color: APP_COLOURS.BG2,
						backgroundColor: APP_COLOURS.WHITE_TRANSPARENT4,
						fontWeight: "500",
					}}
					placeholderTextColor={"#797979"}
					autoCapitalize='sentences'
					clearButtonMode='while-editing'
					autoCompleteType='off'
					editable={true}
					autoCorrect={true}
					autoFocus
					keyboardType='default'
					textContentType='none'
					placeholder={` Search `}
					// defaultValue={this.state.user_data.email}
					defaultValue={this.state.filterSearch}
					onChangeText={(text) => {
						console.log(text);
						this.setState({
							filterSearch: text,
						});
					}}
				/>

				<View style={{ flex: 1 }}>
					<ScrollView
						contentContainerStyle={{
							width: "100%",
							// paddingRight: 15,
							paddingBottom: 2,
						}}>
						{this.state.loading ? (
							<View
								style={{
									flex: 1,
									width: "100%",
									alignItems: "center",
									opacity: 0.5,
									flexDirection: "column",
									//  backgroundColor: APP_COLOURS.WHITE_TRANSPARENT5,
									borderRadius: 13,
									padding: 20,
									marginTop: 20,
								}}>
								<View
									style={{
										padding: 5,
										borderRadius: 50,
										backgroundColor: APP_COLOURS.PRIMARY,
										opacity: 0.8,
									}}>
									<ActivityIndicator
										color={"#FFF"}
										size='small'
									/>
								</View>
							</View>
						) : null}
						{/* .filter((d) => d.id !== this.state.user_data.id) */}

						{this.state.users
							.filter((d_) => {
								let filter = d_;

								if (
									typeof this.state.filterSearch !==
										"undefined" &&
									this.state.filterSearch !== ""
								) {
									let text_ = this.state.filterSearch;
									var outString = text_.replace(
										/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
										"",
									);

									let join_names = `${d_.first_name} ${d_.last_name} ${d_.organisation}`;

									join_names = join_names.replace(
										/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
										"",
									);

									return join_names
										.toLowerCase()
										.includes(outString.toLowerCase());
								}

								return filter;
							})

							.map((user, i) => {
								let assignment_ids = this.state.assignment_ids;
								let assigned =
									assignment_ids.filter((a) => a === user.id)
										.length > 0;

								{
									/* console.log(assignment_ids); */
								}

								return (
									<TouchableOpacity
										key={i}
										onPress={async () => {
											let assignment =
												this.state.assignment;
											if (assigned) {
												assignment_ids =
													assignment_ids.filter(
														(ds) => ds !== user.id,
													);
												assignment = assignment.filter(
													(ds) => ds.id !== user.id,
												);
											} else {
												assignment_ids.push(user.id);
												assignment.push(user);
											}
											// console.log(
											// 	"assignment_ids",
											// 	assignment_ids,
											// );
											this.setState({
												assignment_ids: assignment_ids,
												assignment: assignment,
												saveChanges: true,
											});

											if (!this.props.isStandalone) {
												this.props.addAssignment(
													assignment,
													assignment_ids,
												);
											}
										}}
										style={{
											padding: 5,
											flexDirection: "row",
											justifyContent: "flex-start",
											alignItems: "center",
											marginBottom: 2,
											width: "100%",
											backgroundColor: assigned
												? APP_COLOURS.BG2
												: "transparent",
											borderRadius: 12,
										}}>
										{typeof user.avatar !== "undefined" ? (
											<Image
												source={
													Avatars[
														parseInt(user.avatar)
													].pp
												}
												style={{
													width: 30,
													height: 30,
													borderRadius: 25,
													resizeMode: "contain",
													backgroundColor: "#FFF",
													marginRight: 5,
												}}
											/>
										) : (
											<Image
												source={Avatars[1].pp}
												style={{
													width: 30,
													height: 30,
													borderRadius: 25,
													resizeMode: "contain",
													backgroundColor: "#FFF",
													marginRight: 5,
												}}
											/>
										)}

										<View style={{}}>
											<Text
												style={{
													fontFamily: "Gotham",
													fontSize: 14,
													fontWeight: "600",
													color: assigned
														? "#FFF"
														: APP_COLOURS.BG2,
												}}>
												<Text
													style={{
														fontWeight: "800",
													}}>
													{user.first_name}
												</Text>{" "}
												{user.last_name}
											</Text>
											<Text
												style={{
													fontFamily: "Gotham",
													fontSize: 9,
													fontWeight: "200",
													color: assigned
														? "#FFF"
														: APP_COLOURS.BG2,
												}}>
												{user.organisation}
											</Text>
										</View>
									</TouchableOpacity>
								);
							})}
					</ScrollView>
					{this.state.saveChanges && this.props.isStandalone ? (
						<TouchableOpacity
							onPress={() => {
								this.setState({
									showAssignment: false,
								});
								this.props.addAssignment(
									this.state.assignment,
									this.state.assignment_ids,
								);
							}}>
							<View
								style={{
									padding: 8,
									backgroundColor: APP_COLOURS.BLUE,
									borderRadius: 10,
									justifyContent: "center",
									alignItems: "center",
								}}>
								<Text
									style={{
										color: "#FFF",
										fontSize: 14,
									}}>
									Save
								</Text>
							</View>
						</TouchableOpacity>
					) : null}
				</View>
			</View>
		);
	}
}
