import React, { useState, useEffect } from "react";
import axios from "axios";
import { jsPDF } from "jspdf";

const PdfPage = () => {
	const [pdfData, setPdfData] = useState(null);
	const pdfUrl =
		"https://storage.googleapis.com/tetricepm-5f972.appspot.com/axysshare/tetrice_axys.pdf?GoogleAccessId=firebase-adminsdk-w4xhp%40tetricepm-5f972.iam.gserviceaccount.com&Expires=1705011147&Signature=ePDf5MKd4F40zz4pzhuImiN2Yy45vyHr8kvJ9SMLOVtJtf5sKo%2BQ7gMorDptIxj%2FxM5tGZbeYlWUeMw0ylN2kUui61a%2FxAiCO2fvjsebRDn99ibMSEyGOLYltKQfW5YdgE%2B%2BPHMgWqQkkgexCxuZ39MqDyURqgok8bhU%2FhopbVV1SHieKl2zz2cY0k47Ia6RKHp1XQCt5c%2FKo52vMVQtgL3r3HYksUubZXmjQhUE6SqQMlRTqcW5pV%2FeHV17HNDh151ZJjv%2BescAv%2B3o8qomJ%2B9IzHQDT1uUIB8wOeStRjB29iHHRrMauu8L%2F%2FZT0WAj6oi3EmeJMae9enIJTBurWg%3D%3D";
	const base64Image = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==
   `;

	useEffect(() => {
		axios
			.get(pdfUrl, { responseType: "blob" })
			.then((response) => {
				const reader = new FileReader();
				reader.readAsDataURL(response.data);
				reader.onloadend = () => {
					const base64data = reader.result;
					setPdfData(base64data);
				};
			})
			.catch((error) => console.error(error));
	}, [pdfUrl]);

	const appendImageAndDownload = () => {
		if (pdfData) {
			const pdf = new jsPDF();
			pdf.addImage(pdfData, "PDF", 0, 0, 210, 297);
			pdf.addImage(base64Image, "PNG", 10, 10, 50, 50); // Adjust dimensions as needed
			pdf.save("modified-document.pdf");
		}
	};

	return (
		<div>
			{pdfData && (
				<iframe
					src={pdfData}
					style={{ width: "100%", height: "90vh" }}
				/>
			)}
			<button onClick={appendImageAndDownload}>
				Append Image & Download
			</button>
		</div>
	);
};

export default PdfPage;
