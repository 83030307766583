import React, { Component } from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import moment from "moment";
import {
	APP_COLOURS,
	CustomLayoutSpring,
	screenHeight,
	StatusColour,
	screenWidth,
} from "../APP_VARS";
import { ScrollView } from "react-native-web";

class AutoHeightImage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<Image
				style={{
					width: this.props.width,
					height:
						typeof this.props.height !== "undefined"
							? this.props.height
							: this.props.width,
					resizeMode: "contain",
				}}
				source={this.props.source}
			/>
		);
	}
}
export default class EventCalendar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showDates: false,

			// selectedDate: moment(this.props.selectedDate).format("DD/MM/YYYY"),
			selectedDate: "",
			currentMonthInView: moment(this.props.selectedDate),
			currentMomnth: moment(this.props.selectedDate),
			dates: Array.from(
				Array(moment(this.props.selectedDate).daysInMonth()).keys(),
			),
			weekStart:
				moment(moment(this.props.selectedDate).startOf("month")).format(
					"w",
				) - 1,
			weekEnd:
				moment(moment(this.props.selectedDate).endOf("month")).format(
					"w",
				) + 1,
			totalWeeks:
				moment(moment(this.props.selectedDate).endOf("month")).format(
					"w",
				) -
				moment(moment(this.props.selectedDate).startOf("month")).format(
					"w",
				) +
				1,

			datesArray: [],
			// layoutWidth: 300,
			dayOfWeekArray: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],

			schedule: [],

			loading: true,
			scheduleType: "",

			compWidth: this.props.compWidth,

			companyEventsForSelectedDay: [],

			eventsForSelectedDay: this.props.calendarList.filter(
				(c) =>
					moment(c.end_date).format("DDMMYYYY") ===
						moment().format("DDMMYYYY") ||
					moment(c.start_date).format("DDMMYYYY") ===
						moment().format("DDMMYYYY"),
			),
		};
	}

	async componentDidMount() {
		this.sideCalc();
	}

	componentDidUpdate(prevProps) {
		// if (prevProps.calendarList !== this.props.calendarList) {
		// 	this.setState({
		// 		eventsForSelectedDay: this.props.calendarList.filter(
		// 			(c) =>
		// 				moment(c.end_date).format("DDMMYYYY") ===
		// 					moment().format("DDMMYYYY") ||
		// 				moment(c.start_date).format("DDMMYYYY") ===
		// 					moment().format("DDMMYYYY"),
		// 		),
		// 	});
		// }
	}

	changeMonth = async (change) => {
		let newMonth = "";
		if (change === "next") {
			newMonth = moment(this.state.currentMonthInView).add(1, "month");
		} else {
			newMonth = moment(this.state.currentMonthInView).subtract(
				1,
				"month",
			);
		}

		await this.setState({
			currentMonthInView: newMonth,
		});

		this.sideCalc();
	};

	render() {
		return (
			<View
				style={{
					borderRadius: 10,
					width: "100%",
					// maxWidth: this.state.compWidth,
					height: "auto",
					opacity: this.state.loading ? 0 : 1,
				}}>
				<View
					onLayout={(e) => {
						this.setState({
							layoutWidth: e.nativeEvent.layout.width,
							loading: false,
						});
					}}>
					<View
						style={{
							width: "100%",
							flexDirection: "row",
							justifyContent: "space-between",
							padding: 10,
							// marginTop: 15,
						}}>
						<TouchableOpacity
							onPress={() => this.changeMonth("prev")}>
							<AutoHeightImage
								width={50}
								height={35}
								source={require("../../assets/images/left_btn.png")}
							/>
						</TouchableOpacity>
						<View>
							<Text
								style={{
									color: APP_COLOURS.BG2,
									fontWeight: "700",
									fontSize: 20,
								}}>
								{moment(this.state.currentMonthInView).format(
									"MMMM YYYY",
								)}
							</Text>
						</View>
						<TouchableOpacity
							onPress={() => this.changeMonth("next")}>
							<AutoHeightImage
								width={50}
								height={35}
								source={require("../../assets/images/right_btn.png")}
							/>
						</TouchableOpacity>
					</View>
					<View
						style={{
							justifyContent: "center",
							alignItems: "center",
						}}>
						{this.heading()}

						<View
							style={{
								flexDirection: "row",
								flexWrap: "wrap",
								paddingLeft: 0.5 * 6,
							}}>
							{this.state.datesArray.map((d, i) => {
								let today = moment().format("DD/MM/YYYY");
								let layoutWidth = this.state.compWidth / 7;
								let eventDue = this.props.calendarList.filter(
									(c) =>
										moment(c.end_date).format(
											"DDMMYYYY",
										) ===
										moment(d, "DD/MM/YYYY").format(
											"DDMMYYYY",
										),
								);

								let eventStart = this.props.calendarList.filter(
									(c) =>
										moment(c.start_date).format(
											"DDMMYYYY",
										) ===
										moment(d, "DD/MM/YYYY").format(
											"DDMMYYYY",
										),
								);

								{
									/* let companyEvent =
									this.props.companyEvents.filter(
										(c) =>
											moment(c.start_date).format(
												"DDMMYYYY",
											) ===
											moment(d, "DD/MM/YYYY").format(
												"DDMMYYYY",
											),
									); */
								}

								return (
									<View
										key={i}
										style={{
											width: layoutWidth - 0.5,
											height: layoutWidth - 0.5,
											padding: 2,
										}}>
										<TouchableOpacity
											onPress={() => {
												this.setState({
													selectedDate: d,
													eventsForSelectedDay: [
														...eventDue,
														...eventStart,
													],

													// companyEventsForSelectedDay:
													// 	[...companyEvent],
												});
												this.props.dateSelected(
													moment(d, "DD/MM/YYYY"),
												);
											}}>
											<View
												style={{
													width: layoutWidth - 5,
													height: layoutWidth - 5,
													justifyContent: "center",
													alignItems: "center",
													borderRadius: 8,
													borderWidth:
														d === today ? 1 : 0,

													textAlign: "center",
													backgroundColor:
														this.state
															.selectedDate === d
															? APP_COLOURS.BLUE2
															: "#FFF",
													borderColor:
														today === d
															? "red"
															: APP_COLOURS.BG2,
													opacity:
														moment(
															d,
															"DD/MM/YYYY",
														).format("MM") ===
														moment(
															this.state
																.currentMonthInView,
														).format("MM")
															? moment(
																	d,
																	"DD/MM/YYYY",
															  ).format(
																	"ddd",
															  ) === "Sun"
																? 0.4
																: moment(
																		d,
																		"DD/MM/YYYY",
																  ).format(
																		"ddd",
																  ) === "Sat"
																? 0.4
																: 1
															: 0.3,
												}}>
												<Text
													style={{
														// color: APP_COLOURS.BG2,
														color:
															this.state
																.selectedDate ===
															d
																? "#FFF"
																: APP_COLOURS.BG2,
														fontWeight:
															this.state
																.selectedDate ===
															d
																? "600"
																: "300",
														fontSize: 16,
													}}>
													{moment(
														d,
														"DD/MM/YYYY",
													).format("DD")}
												</Text>
												<View
													style={{
														flexDirection: "row",
														justifyContent:
															"space-between",
														alignItems: "center",
														marginTop: 2,
													}}>
													{eventDue
														.slice(0, 4)
														.map((ev, iii) => (
															<View
																key={iii}
																style={{
																	width: 5,
																	height: 5,
																	borderRadius: 3,
																	backgroundColor:
																		APP_COLOURS.RED,
																	marginRight: 1,
																}}
															/>
														))}
													{eventStart
														.slice(0, 4)
														.map((ev, ii) => (
															<View
																key={ii}
																style={{
																	width: 5,
																	height: 5,
																	borderRadius: 3,
																	backgroundColor:
																		APP_COLOURS.BLUE,
																	marginRight: 1,
																}}
															/>
														))}
												</View>
											</View>
										</TouchableOpacity>
									</View>
								);
							})}
						</View>
					</View>
				</View>

				<View style={{ flex: 1 }}>
					<View style={{ padding: 5 }}>
						<Text
							style={{
								color: APP_COLOURS.BG2,
								fontWeight: "700",
								fontSize: 12,
							}}>
							{moment(
								this.state.selectedDate,
								"DD/MM/YYYY",
							).format("DD MMM YYYY")}
						</Text>
					</View>

					<View
						style={{
							flex: 1,
							// backgroundColor: "#E8E8E8",
							padding: 5,
							maxHeight: 500,
						}}>
						<ScrollView>
							{this.state.companyEventsForSelectedDay.map(
								(d, i) => (
									<View
										key={i}
										style={{
											padding: 4,
											flexDirection: "row",
											// backgroundColor: moment(
											// 	this.state.selectedDate,
											// 	"DD/MM/YYYY",
											// ).isSame(moment(d.end_date))
											// 	? APP_COLOURS.RED2
											// 	: APP_COLOURS.GREEN,
											backgroundColor: "#FFF",
											borderRadius: 5,
											// opacity: 0.6,
											marginBottom: 3,
										}}>
										<View
											style={{
												height: 20,
												width: 3,
												backgroundColor:
													APP_COLOURS.BG2,
												borderRadius: 3,
												marginRight: 10,
											}}
										/>
										<Text
											style={{
												color: APP_COLOURS.BG2,
												// color: "#FFF",
												fontWeight: "400",
											}}>
											{moment(
												this.state.selectedDate,
												"DD/MM/YYYY",
											).isSame(moment(d.end_date))
												? "Due: "
												: "Start: "}{" "}
											{d.title}
										</Text>
									</View>
								),
							)}

							{this.state.eventsForSelectedDay.map((d, i) => (
								<View
									key={i}
									style={{
										padding: 4,
										flexDirection: "row",
										// backgroundColor: moment(
										// 	this.state.selectedDate,
										// 	"DD/MM/YYYY",
										// ).isSame(moment(d.end_date))
										// 	? APP_COLOURS.RED2
										// 	: APP_COLOURS.GREEN,
										backgroundColor: "#FFF",
										borderRadius: 5,
										// opacity: 0.6,
										marginBottom: 3,
									}}>
									<View
										style={{
											height: 20,
											width: 3,
											backgroundColor: APP_COLOURS.BG2,
											borderRadius: 3,
											marginRight: 10,
										}}
									/>
									<Text
										style={{
											color: APP_COLOURS.BG2,
											// color: "#FFF",
											fontWeight: "400",
										}}>
										{moment(
											this.state.selectedDate,
											"DD/MM/YYYY",
										).isSame(moment(d.end_date))
											? "Due: "
											: "Start: "}{" "}
										{d.title}
									</Text>
								</View>
							))}
						</ScrollView>
					</View>
				</View>
			</View>
		);
	}

	heading = () => {
		let layoutWidth = this.state.compWidth / 7;
		// console.log(layoutWidth);
		return (
			<View
				style={{
					// flex: 1,
					// paddingLeft: 10,
					// paddingRight: 10,
					marginTop: 6,
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
				}}>
				{this.state.dayOfWeekArray.map((d, i) => {
					return (
						<View
							key={i}
							style={{
								width: layoutWidth,
								//  height: 37,
								//  margin: 2,
								justifyContent: "center",
								alignItems: "center",
								borderRadius: 8,
								// backgroundcolor: APP_COLOURS.BG2,
								// borderWidth: 0.3,
								// borderColor: "#E8E8E8",\
							}}>
							<Text
								style={{
									color: APP_COLOURS.BG2,
									fontWeight: "300",
									fontSize: 10,
									opacity: 0.9,
								}}>
								{d}
							</Text>
						</View>
					);
				})}
			</View>
		);
	};

	sideCalc = () => {
		// currentMonthInView: moment(this.state.selectedDate, "DD/MM/YYYY"),

		let datesArray = [];
		let curr = this.state.currentMonthInView;
		// let curr = moment("02/01/2023", "DD/MM/YYYY");
		// console.log('curr', curr);
		// console.log(
		// 	this.state.totalWeeks,
		// 	Array.from(Array(this.state.totalWeeks).keys()).length,
		// 	"weeks",
		// );
		let startDateInMonth = moment(curr).startOf("month");
		let startDayInMonth = moment(curr).startOf("month").format("ddd");
		// console.log('startDayInMonth', startDayInMonth);
		//
		//
		// Calculate the day of the week that is the start day of the mont
		// the number of days offset before the start
		let dayOfWeekArray = this.state.dayOfWeekArray;
		// let daysBeforeStartOfMonth = dayOfWeekArray.slice(
		// 	0,
		// 	dayOfWeekArray.indexOf(startDayInMonth),
		// );
		let arr = dayOfWeekArray.indexOf(startDayInMonth);
		// console.log(arr);
		if (arr > 0) {
			let dLoop = Array.from(Array(arr).keys());
			for (const d of dLoop) {
				let doff = d + 1;
				let date = moment(startDateInMonth)
					.subtract(doff, "days")
					.format("DD/MM/YYYY");
				datesArray.push(date);
			}
		}

		//
		//
		//

		let dayesInMonth = Array.from(Array(moment(curr).daysInMonth()).keys());

		for (const d of dayesInMonth) {
			let date = moment(startDateInMonth)
				.add(d, "day")
				.format("DD/MM/YYYY");
			datesArray.push(date);
		}

		let datesSorted = datesArray.sort(
			(a, b) =>
				moment(a, "DD/MM/YYYY").unix() - moment(b, "DD/MM/YYYY").unix(),
		);
		// console.log('datesArray', datesSorted);

		this.setState({
			datesArray: datesSorted,
		});
	};
}
