import React, { useState, useEffect } from "react";
import {
	View,
	Image,
	ScrollView,
	Linking,
	Text,
	TouchableOpacity,
	TextInput,
} from "react-native";
import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css"; // import Quill styles
import "./descriptionStyles.css";
import { ActivityIndicator } from "react-native-web";

function getLargeHtmlData(geturl, next) {
	// POST this data from the parent list or create a new presentation
	let url =
		"https://workflow.tetrice.co.za/webhook/59af9ff2-5530-45b9-b4d8-fb9631c555fb";
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			url: geturl,
		}),
	};
	fetch(url, requestOptions)
		.then((response) => {
			if (!response.ok) {
				throw new Error("Failed to fetch data");
			}
			return response.json();
		})
		.then((html) => {
			// console.log(html.data);
			return next(typeof html?.data !== "undefined" ? html?.data : "");
		})
		.catch((error) => {
			console.log(error);
		});
}

const DescriptionQuillEditor = ({
	presentation_id,
	pageData,
	reloadData,
	lockContent,
}) => {
	const [loading, setLoading] = useState(true);
	const [pageTitle, setpageTitle] = useState("");
	const [editorHtml, setEditorHtml] = useState("");
	const [saveHtml, setSaveHtml] = useState(false);

	useEffect(() => {
		setSaveHtml(lockContent);
		if (pageData?.htmlData) {
			// setEditorHtml(pageData.html);
			// setpageTitle(pageData.title);

			getLargeHtmlData(pageData?.htmlData.htmlFileUrl, (d) => {
				setEditorHtml(d);
				setLoading(false);
			});
		} else {
			setLoading(false);
			if (pageData !== "") {
				setEditorHtml(pageData);
			}
		}
	}, [pageData, lockContent]);

	const modules = {
		toolbar: {
			container: [
				[{ header: "1" }, { header: "2" }, { font: [] }],
				[{ size: [] }],

				["bold", "italic", "underline", "strike", "blockquote"],
				[
					{ list: "ordered" },
					{ list: "bullet" },
					{ indent: "-1" },
					{ indent: "+1" },
				],
				["link", "image", "video"],
				["clean"],
			],
		},
	};

	const formats = [
		"header",
		"font",
		"size",
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"bullet",
		"indent",
		"link",
		"image",
		"video",
	];

	const handleChange = (html) => {
		setEditorHtml(html);
	};

	const handleSave = async () => {
		setSaveHtml(true);

		reloadData(editorHtml);
	};

	return (
		<div
			className='qleditor'
			style={{
				color: "#2b2c31",
			}}>
			{loading ? <ActivityIndicator /> : null}

			{!saveHtml ? (
				<>
					<ReactQuill
						// theme='snow'
						value={editorHtml}
						onChange={handleChange}
						modules={modules}
						formats={formats}
						className='ql-editor'
						style={{
							color: "#2b2c31",
						}}
					/>
					<div className='cursor'>
						<TouchableOpacity
							style={{
								borderRadius: 10,
								justifyContent: "center",
								alignItems: "center",
								marginVertical: 25,
								flexDirection: "row",
								overflow: "hidden",
							}}
							onPress={() => {
								handleSave();
							}}>
							<Image
								source={require("./button.svg").default}
								style={{
									width: "101%",
									height: 45,
									resizeMode: "cover",
								}}
							/>

							<Text
								style={{
									fontSize: 16,
									marginTop: 2,
									fontWeight: "600",
									color: "#FFF",
									position: "absolute",
								}}>
								Save & Update
							</Text>
						</TouchableOpacity>
					</div>
				</>
			) : (
				<div className='qleditor'>
					<div dangerouslySetInnerHTML={{ __html: editorHtml }} />
				</div>
			)}
		</div>
	);
};

export default DescriptionQuillEditor;
