import React, { Component } from "react";
import { View, Text, ScrollView, TouchableOpacity } from "react-native";
import { APP_COLOURS } from "../../APP_VARS";

export default class SmallerTabs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: this.props.tabs[0],
		};
	}

	render() {
		return (
			<View style={{}}>
				<ScrollView showsHorizontalScrollIndicator={false} horizontal>
					<View
						style={{
							flexDirection: "row",
						}}>
						{/* <TouchableOpacity
							style={{}}
							onPress={() =>
								this.setState({
									filterTab: "",
								})
							}>
							<View
								style={{
									width: "auto",
									//  height: 50,
									justifyContent: "center",
									alignItems: "center",

									padding: 10,
									borderRadius: 12,
									marginRight: 5,
									backgroundColor:
										this.state.filterTab === ""
											? APP_COLOURS.BLUE
											: APP_COLOURS.WHITE_TRANSPARENT9,
								}}>
								<Text
									style={{
										fontSize: 12,
										color:
											this.state.filterTab === ""
												? "#FFF"
												: APP_COLOURS.BLUE,
										fontWeight: "400",
										fontFamily: "Gotham",
									}}>
									All
								</Text>
							</View>
						</TouchableOpacity> */}
						{this.props.tabs.map((d, i) => (
							<TouchableOpacity
								key={i}
								style={{}}
								onPress={() => {
									this.setState({
										selected: d,
									});
									this.props.selectedSubPage(d);
								}}>
								<View
									style={{
										width: "auto",
										//   height: 50,
										justifyContent: "center",
										alignItems: "center",
										// borderColor:
										// 	this.state.selected === d
										// 		? APP_COLOURS.BLUE
										// 		: APP_COLOURS.BG2,
										// borderWidth: 0.5,
										backgroundColor:
											this.state.selected === d
												? APP_COLOURS.BLUE
												: "#FFF",
										padding: 10,
										// borderRadius: 10,
										marginRight: 5,
										borderTopLeftRadius: 10,
										borderTopRightRadius: 10,
										paddingHorizontal: 15,
										paddingTop: 15,
									}}>
									<Text
										style={{
											fontSize: 15,
											color:
												this.state.selected === d
													? "#FFF"
													: APP_COLOURS.BG2,
											fontWeight: "400",
										}}>
										{d}
									</Text>
								</View>
							</TouchableOpacity>
						))}
					</View>
				</ScrollView>
			</View>
		);
	}
}
