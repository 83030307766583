import moment from "moment";
import React, { Component } from "react";
import {
	StyleSheet,
	View,
	Text,
	Image,
	TouchableOpacity,
	TextInput,
} from "react-native";
import Carousel from "./CarouselItem";

class MultiContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLiked: false,
			likeCount: 0,
			commentCount: 0,
			approvedCopy: "",
			approvedTags: "",

			approvePost: "",
			commentAdded: "",
			approvedDate: "",
			commentLines: 1,

			id: this.props.id,
			reviewDate: moment().toISOString(),
		};
	}

	toggleLike = () => {
		this.setState((prevState) => ({
			isLiked: !prevState.isLiked,
			likeCount: prevState.isLiked
				? prevState.likeCount - 1
				: prevState.likeCount + 1,
		}));
	};

	toggleApproval = (item, text) => {
		this.setState((prevState) => ({
			[item]: text,
		}));
	};

	render() {
		const {
			isLiked,
			likeCount,
			commentCount,

			commentLines,
			approvedDate,
			commentAdded,
			approvePost,
			approvedCopy,
			approvedTags,
		} = this.state;
		const {
			title,
			username,
			avatarUri,
			version,
			method,
			postImageUri,
			verified,
			platform,
			copy,
			tags,
			postDate,
			screenWidth,
		} = this.props; // pass these props to display specific post data

		return (
			<View
				style={{
					// borderRadius: 15,
					overflow: "hidden",
					backgroundColor: "#000",
					marginBottom: 20,
				}}>
				<View
					style={{
						padding: 5,
						borderColor: "#797979",
						borderWidth: 1,
						alignItems: "center",
						justifyContent: "center",
					}}>
					<Text
						style={{
							fontWeight: "800",
							color: "#FFF",

							fontSize: 19,
						}}>
						{title}
					</Text>
					<Text
						style={{
							fontWeight: "800",
							color: "#FFF",

							fontSize: 19,
						}}>
						version {version}
					</Text>
				</View>

				{typeof postImageUri !== "undefined" ? (
					<Carousel
						screenWidth={screenWidth}
						postImageUri={postImageUri}
					/>
				) : null}

				<View
					style={{
						padding: 10,
					}}>
					<Text
						style={{
							fontWeight: "800",
							color: "#FFF",
							marginRight: 5,
							marginTop: 10,
						}}>
						Copy
					</Text>
					<Text
						style={{
							fontSize: 14,
							marginTop: 2,
							fontWeight: "300",
							color: "#FFF",
						}}>
						{copy}
					</Text>
					<Text
						style={{
							fontSize: 14,
							marginTop: 2,
							fontWeight: "300",
							color: "#FFF",
							opacity: 0.6,
						}}>
						{tags}
					</Text>
				</View>

				<View
					style={{
						padding: 10,
					}}>
					{/* <Text
						style={{
							fontSize: 14,
							marginTop: 2,
							fontWeight: "300",
							color: "#FFF",
						}}>
						{commentCount} comments
					</Text> */}

					<View style={{}}>
						<Text
							style={{
								fontWeight: "400",
								fontFamily: "Gotham",
								fontSize: 16,
								color: "#FFF",
								marginTop: 20,
								backgroundColor: "#000",
								borderRadius: 8,
								width: "100%",
								textAlign: "center",
							}}>
							Post image/video
						</Text>

						<View
							style={{
								marginTop: 5,
								marginVertical: 5,
								flexDirection: "row",
							}}>
							<TouchableOpacity
								style={{
									flex: 1,
									backgroundColor:
										approvePost === "false"
											? "#FF4E24"
											: "transparent",
									borderWidth: 1,
									borderColor: "#FF4E24",
									padding: 15,
									borderRadius: 10,
									justifyContent: "center",
									alignItems: "center",
									marginVertical: 5,
									flexDirection: "row",
									marginRight: 5,
								}}
								onPress={() =>
									this.toggleApproval("approvePost", "false")
								}>
								<Text
									style={{
										fontSize: 16,
										marginTop: 2,
										fontWeight: "500",
										color: "#FFF",
									}}>
									Reject
								</Text>
							</TouchableOpacity>

							<TouchableOpacity
								style={{
									flex: 1,
									backgroundColor:
										approvePost === "true"
											? "#0098FD"
											: "transparent",
									borderWidth: 1,
									borderColor: "#0098FD",
									padding: 15,
									borderRadius: 10,
									justifyContent: "center",
									alignItems: "center",
									marginVertical: 5,
									flexDirection: "row",
								}}
								onPress={() =>
									this.toggleApproval("approvePost", "true")
								}>
								<Text
									style={{
										fontSize: 16,
										marginTop: 2,
										fontWeight: "500",
										color: "#FFF",
									}}>
									Approve
								</Text>
							</TouchableOpacity>
						</View>
					</View>

					<View style={{}}>
						<Text
							style={{
								fontWeight: "400",
								fontFamily: "Gotham",
								fontSize: 16,
								color: "#FFF",
								marginTop: 20,
								backgroundColor: "#000",
								borderRadius: 8,
								width: "100%",
								textAlign: "center",
							}}>
							Caption & Hashtags
						</Text>

						<View
							style={{
								marginTop: 5,
								marginVertical: 5,
								flexDirection: "row",
							}}>
							<TouchableOpacity
								style={{
									flex: 1,
									backgroundColor:
										approvedCopy === "false"
											? "#FF4E24"
											: "transparent",
									borderWidth: 1,
									borderColor: "#FF4E24",
									padding: 15,
									borderRadius: 10,
									justifyContent: "center",
									alignItems: "center",
									marginVertical: 5,
									flexDirection: "row",
									marginRight: 5,
								}}
								onPress={() =>
									this.toggleApproval("approvedCopy", "false")
								}>
								<Text
									style={{
										fontSize: 16,
										marginTop: 2,
										fontWeight: "500",
										color: "#FFF",
									}}>
									Reject
								</Text>
							</TouchableOpacity>

							<TouchableOpacity
								style={{
									flex: 1,
									backgroundColor:
										approvedCopy === "true"
											? "#0098FD"
											: "transparent",
									borderWidth: 1,
									borderColor: "#0098FD",
									padding: 15,
									borderRadius: 10,
									justifyContent: "center",
									alignItems: "center",
									marginVertical: 5,
									flexDirection: "row",
								}}
								onPress={() =>
									this.toggleApproval("approvedCopy", "true")
								}>
								<Text
									style={{
										fontSize: 16,
										marginTop: 2,
										fontWeight: "500",
										color: "#FFF",
									}}>
									Approve
								</Text>
							</TouchableOpacity>
						</View>
					</View>

					<View style={{}}>
						<Text
							style={{
								fontWeight: "400",
								fontFamily: "Gotham",
								fontSize: 16,
								color: "#FFF",
								marginTop: 20,
								backgroundColor: "#000",
								borderRadius: 8,
								width: "100%",
								textAlign: "center",
							}}>
							Tags
						</Text>

						<View
							style={{
								marginTop: 5,
								marginVertical: 5,
								flexDirection: "row",
							}}>
							<TouchableOpacity
								style={{
									flex: 1,
									backgroundColor:
										approvedTags === "false"
											? "#FF4E24"
											: "transparent",
									borderWidth: 1,
									borderColor: "#FF4E24",
									padding: 15,
									borderRadius: 10,
									justifyContent: "center",
									alignItems: "center",
									marginVertical: 5,
									flexDirection: "row",
									marginRight: 5,
								}}
								onPress={() =>
									this.toggleApproval("approvedTags", "false")
								}>
								<Text
									style={{
										fontSize: 16,
										marginTop: 2,
										fontWeight: "500",
										color: "#FFF",
									}}>
									Reject
								</Text>
							</TouchableOpacity>

							<TouchableOpacity
								style={{
									flex: 1,
									backgroundColor:
										approvedTags === "true"
											? "#0098FD"
											: "transparent",
									borderWidth: 1,
									borderColor: "#0098FD",
									padding: 15,
									borderRadius: 10,
									justifyContent: "center",
									alignItems: "center",
									marginVertical: 5,
									flexDirection: "row",
								}}
								onPress={() =>
									this.toggleApproval("approvedTags", "true")
								}>
								<Text
									style={{
										fontSize: 16,
										marginTop: 2,
										fontWeight: "500",
										color: "#FFF",
									}}>
									Approve
								</Text>
							</TouchableOpacity>
						</View>
					</View>

					<View style={{}}>
						<Text
							style={{
								fontWeight: "400",
								fontFamily: "Gotham",
								fontSize: 16,
								color: "#FFF",
								marginTop: 20,
								backgroundColor: "#000",
								borderRadius: 8,
								width: "100%",
								textAlign: "center",
							}}>
							Date of post
						</Text>

						<View
							style={{
								marginTop: 5,
								marginVertical: 5,
								flexDirection: "row",
							}}>
							<TouchableOpacity
								style={{
									flex: 1,
									backgroundColor:
										approvedDate === "false"
											? "#FF4E24"
											: "transparent",
									borderWidth: 1,
									borderColor: "#FF4E24",
									padding: 15,
									borderRadius: 10,
									justifyContent: "center",
									alignItems: "center",
									marginVertical: 5,
									flexDirection: "row",
									marginRight: 5,
								}}
								onPress={() =>
									this.toggleApproval("approvedDate", "false")
								}>
								<Text
									style={{
										fontSize: 16,
										marginTop: 2,
										fontWeight: "500",
										color: "#FFF",
									}}>
									Reject
								</Text>
							</TouchableOpacity>

							<TouchableOpacity
								style={{
									flex: 1,
									backgroundColor:
										approvedDate === "true"
											? "#0098FD"
											: "transparent",
									borderWidth: 1,
									borderColor: "#0098FD",
									padding: 15,
									borderRadius: 10,
									justifyContent: "center",
									alignItems: "center",
									marginVertical: 5,
									flexDirection: "row",
								}}
								onPress={() =>
									this.toggleApproval("approvedDate", "true")
								}>
								<Text
									style={{
										fontSize: 16,
										marginTop: 2,
										fontWeight: "500",
										color: "#FFF",
									}}>
									Approve
								</Text>
							</TouchableOpacity>
						</View>
					</View>

					<View style={{}}>
						<View
							style={{
								marginTop: 20,
								marginVertical: 5,
							}}>
							<Text
								style={{
									fontWeight: "400",
									fontFamily: "Gotham",
									fontSize: 12,
									color: "#0098FD",
									marginBottom: 4,
									position: "absolute",
									top: -12,
									left: 9,
									zIndex: 99,
									backgroundColor: "#000",
									padding: 4,
									borderRadius: 8,
								}}>
								Add comment
							</Text>

							<TextInput
								style={{
									fontSize: 16,
									color: "#FFF",
									fontWeight: "400",
									fontFamily: "Gotham",
									width: "100%",
									paddingLeft: 10,
									padding: 13,
									borderWidth: 1,
									borderColor: "#0098FD",
									borderRadius: 7,
									borderWidth: 1,
									minHeight: 30,
								}}
								placeholderTextColor={"#797979"}
								autoCapitalize='sentences'
								multiline
								numberOfLines={commentLines}
								clearButtonMode='while-editing'
								// autoCompleteType="off"
								placeholder={`Add comment`}
								onChangeText={(text) => {
									let commentLines = text.length / 50;
									console.log();
									commentLines = commentLines + 1;
									this.setState({
										comment: text,
										commentLines: commentLines.toFixed(0),
									});
								}}
							/>
						</View>
					</View>

					<TouchableOpacity
						style={{
							borderRadius: 10,
							justifyContent: "center",
							alignItems: "center",
							marginVertical: 25,
							flexDirection: "row",
							overflow: "hidden",
						}}
						onPress={() => {
							this.props.handleApproval(this.state);
						}}>
						<Image
							source={require("./button.svg").default}
							style={{
								width: "101%",
								height: 45,
								resizeMode: "cover",
							}}
						/>

						<Text
							style={{
								fontSize: 16,
								marginTop: 2,
								fontWeight: "600",
								color: "#FFF",
								position: "absolute",
							}}>
							Save & Send
						</Text>
					</TouchableOpacity>
				</View>
			</View>
		);
	}
}

export default MultiContent;
