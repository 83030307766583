import React, { Component } from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import moment from "moment";
import {
	APP_COLOURS,
	CustomLayoutSpring,
	screenHeight,
	StatusColour,
	screenWidth,
} from "../../APP_VARS";

class AutoHeightImage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<Image
				style={{
					width: this.props.width,
					height:
						typeof this.props.height !== "undefined"
							? this.props.height
							: this.props.width,
					resizeMode: "contain",
				}}
				source={this.props.source}
			/>
		);
	}
}

export default class PostCalendar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showDates: false,
			startDate: "",
			endDate: "",
			selectedDate: moment(this.props.selectedDate).format("DD/MM/YYYY"),
			currentMonthInView: moment(this.props.selectedDate),
			currentMomnth: moment(this.props.selectedDate),
			dates: Array.from(
				Array(moment(this.props.selectedDate).daysInMonth()).keys(),
			),
			weekStart:
				moment(moment(this.props.selectedDate).startOf("month")).format(
					"w",
				) - 1,
			weekEnd:
				moment(moment(this.props.selectedDate).endOf("month")).format(
					"w",
				) + 1,
			totalWeeks:
				moment(moment(this.props.selectedDate).endOf("month")).format(
					"w",
				) -
				moment(moment(this.props.selectedDate).startOf("month")).format(
					"w",
				) +
				1,

			datesArray: [],
			// layoutWidth: 300,
			dayOfWeekArray: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],

			schedule: [],

			loading: true,
			scheduleType: "",

			compWidth: this.props.compWidth,
		};
	}

	async componentDidMount() {
		this.sideCalc();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.selectedDate !== this.props.selectedDate ||
			prevProps.contentList !== this.props.contentList
		) {
			this.setState({
				selectedDate: moment(this.props.selectedDate).format(
					"DD/MM/YYYY",
				),
			});
		}
	}

	changeMonth = async (change) => {
		let newMonth = "";
		if (change === "next") {
			newMonth = moment(this.state.currentMonthInView).add(1, "month");
		} else {
			newMonth = moment(this.state.currentMonthInView).subtract(
				1,
				"month",
			);
		}

		await this.setState({
			currentMonthInView: newMonth,
		});

		this.sideCalc();
	};

	render() {
		let itemSize = 12;
		return (
			<View
				style={{
					borderRadius: 10,
					width: "100%",
					// maxWidth: this.state.compWidth,
					height: "auto",
					opacity: this.state.loading ? 0 : 1,
				}}>
				<View
					onLayout={(e) => {
						this.setState({
							layoutWidth: e.nativeEvent.layout.width,
							loading: false,
						});
					}}>
					<View
						style={{
							justifyContent: "center",
							alignItems: "center",
						}}>
						<Text style={{ fontSize: 25 }}>
							{moment(
								this.props.calendarDate,
								"DD/MM/YYYY",
							).format("MMMM")}
						</Text>
						{this.heading()}

						<View
							style={{
								flexDirection: "row",
								flexWrap: "wrap",
								paddingLeft: 0.5 * 6,
							}}>
							{this.state.datesArray.map((d, i) => {
								let today = moment().format("DD/MM/YYYY");
								let layoutWidth = this.state.compWidth / 7;
								let event = this.props.contentList.filter(
									(c) =>
										moment(c.date, "DD/MM/YYYY").format(
											"DDMMYYYY",
										) ===
										moment(d, "DD/MM/YYYY").format(
											"DDMMYYYY",
										),
								);

								let isAfter = moment(
									d,
									"DD/MM/YYYY",
								).isSameOrAfter(
									moment(this.state.startDate, "DD/MM/YYYY"),
								);

								let isBefore = moment(
									this.state.endDate,
									"DD/MM/YYYY",
								).isSameOrAfter(moment(d, "DD/MM/YYYY"));

								return (
									<View
										key={i}
										style={{
											width: layoutWidth - 0.5,
											height: layoutWidth - 0.5,
											// padding: 2,
											borderRadius: 8,
											backgroundColor:
												isAfter && isBefore
													? "rgba(63, 101, 244, 0.2)"
													: "transparent",
											justifyContent: "center",
											alignItems: "center",
											// padding: 10,
											// paddingRight: 4,
										}}>
										<TouchableOpacity
											onPress={async () => {
												await this.setState({
													selectedDate: d,
												});
												this.props.startDate(
													moment(d, "DD/MM/YYYY")
														.startOf("day")
														.toISOString(),
												);
											}}>
											<View
												style={{
													width: layoutWidth - 4,
													height: layoutWidth - 4,
													// justifyContent: "center",
													// alignItems: "center",
													overflow: "hidden",
													padding: 10,
													borderRadius: 15,
													borderWidth:
														today === d ? 1 : 0,

													backgroundColor:
														this.state.startDate ===
														d
															? APP_COLOURS.GREEN
															: this.state
																	.selectedDate ===
															  d
															? "#FFF"
															: "transparent",
													borderColor:
														today === d
															? "red"
															: APP_COLOURS.BG2,
													opacity:
														moment(
															d,
															"DD/MM/YYYY",
														).format("MM") ===
														moment(
															this.state
																.currentMonthInView,
														).format("MM")
															? 1
															: 0.3,

													shadowColor: "#000",
													shadowOffset: {
														width: 0,
														height: 3,
													},
													shadowOpacity: 0.14,
													shadowRadius: 9.32,
													elevation: 4,
												}}>
												{event.length > 0 ? (
													<View
														style={{
															width: layoutWidth,
															height: layoutWidth,
															backgroundColor:
																"red",
															position:
																"absolute",
															left: 0,
															top: 0,
														}}>
														<Image
															source={{
																uri: event[0]
																	.assets[0]
																	.link,
															}}
															style={{
																width: layoutWidth,
																height: layoutWidth,
																resizeMode:
																	"cover",
															}}
														/>
														<Image
															source={
																require("../../../assets/images/fade.svg")
																	.default
															}
															style={{
																width: layoutWidth,
																height: layoutWidth,
																resizeMode:
																	"cover",
																opacity: 0.6,
																position:
																	"absolute",
																left: 0,
																top: 0,
															}}
														/>
													</View>
												) : null}

												<Text
													style={{
														color:
															event.length > 0
																? "#FFF"
																: APP_COLOURS.BG2,
														fontWeight:
															this.state
																.selectedDate ===
															d
																? "900"
																: "600",
														fontSize: 16,
														zIndex: 99,
													}}>
													{moment(
														d,
														"DD/MM/YYYY",
													).format("DD")}
												</Text>

												<View
													style={{
														flexDirection: "row",
														alignItems: "center",
														marginTop: 2,
													}}>
													{event
														.filter(
															(evt) =>
																evt.postType ===
																"Post",
														)
														.map((ev, iii) => (
															<View
																key={iii}
																style={{
																	width: itemSize,
																	height: itemSize,
																	borderRadius:
																		itemSize /
																		2,
																	backgroundColor:
																		APP_COLOURS.RED,
																	marginRight: 1,
																}}
															/>
														))}
													{event
														.filter(
															(evt) =>
																evt.postType ===
																"Story",
														)
														.map((ev, ii) => (
															<View
																key={ii}
																style={{
																	width: itemSize,
																	height: itemSize,
																	borderRadius:
																		itemSize /
																		2,
																	backgroundColor:
																		APP_COLOURS.BLUE,
																	marginRight: 1,
																}}
															/>
														))}
													{event
														.filter(
															(evt) =>
																evt.postType ===
																"Reel",
														)
														.map((ev, ii) => (
															<View
																key={ii}
																style={{
																	width: itemSize,
																	height: itemSize,
																	borderRadius:
																		itemSize /
																		2,
																	backgroundColor:
																		APP_COLOURS.GREENCOMPLETE,
																	marginRight: 1,
																}}
															/>
														))}
												</View>
											</View>
										</TouchableOpacity>
									</View>
								);
							})}
						</View>
					</View>
				</View>
			</View>
		);
	}

	heading = () => {
		let layoutWidth = this.state.compWidth / 7;
		// console.log(layoutWidth);
		return (
			<View
				style={{
					marginTop: 6,
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
				}}>
				{this.state.dayOfWeekArray.map((d, i) => {
					return (
						<View
							key={i}
							style={{
								width: layoutWidth,
								justifyContent: "center",
								alignItems: "center",
								borderRadius: 8,
							}}>
							<Text
								style={{
									color: APP_COLOURS.BG2,
									fontWeight: "600",
									fontSize: 13,
									// opacity: 0.9,
								}}>
								{d}
							</Text>
						</View>
					);
				})}
			</View>
		);
	};

	sideCalc = () => {
		// currentMonthInView: moment(this.state.selectedDate, "DD/MM/YYYY"),

		let datesArray = [];
		let curr = this.state.currentMonthInView;
		// let curr = moment("02/01/2023", "DD/MM/YYYY");
		// console.log('curr', curr);
		// console.log(
		// 	this.state.totalWeeks,
		// 	Array.from(Array(this.state.totalWeeks).keys()).length,
		// 	"weeks",
		// );
		let startDateInMonth = moment(curr).startOf("month");
		let startDayInMonth = moment(curr).startOf("month").format("ddd");
		// console.log('startDayInMonth', startDayInMonth);
		//
		//
		// Calculate the day of the week that is the start day of the mont
		// the number of days offset before the start
		let dayOfWeekArray = this.state.dayOfWeekArray;
		// let daysBeforeStartOfMonth = dayOfWeekArray.slice(
		// 	0,
		// 	dayOfWeekArray.indexOf(startDayInMonth),
		// );
		let arr = dayOfWeekArray.indexOf(startDayInMonth);
		// console.log(arr);
		if (arr > 0) {
			let dLoop = Array.from(Array(arr).keys());
			for (const d of dLoop) {
				let doff = d + 1;
				let date = moment(startDateInMonth)
					.subtract(doff, "days")
					.format("DD/MM/YYYY");
				datesArray.push(date);
			}
		}

		//
		//
		//

		let dayesInMonth = Array.from(Array(moment(curr).daysInMonth()).keys());

		for (const d of dayesInMonth) {
			let date = moment(startDateInMonth)
				.add(d, "day")
				.format("DD/MM/YYYY");
			datesArray.push(date);
		}

		let datesSorted = datesArray.sort(
			(a, b) =>
				moment(a, "DD/MM/YYYY").unix() - moment(b, "DD/MM/YYYY").unix(),
		);
		// console.log('datesArray', datesSorted);

		this.setState({
			datesArray: datesSorted,
		});
	};
}
