import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import { crudRead, crudReadAll, crudReadUnass } from "../CRUDHELPER";
import { APP_COLOURS } from "../APP_VARS";
import AutoHeightImage from "./AutoHeightImageComp";

export default class ActivityLog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			logs: [],
		};
	}

	componentDidMount() {
		this.getActivityLog();
	}

	getActivityLog = async () => {
		await this.setState({
			loading: true,
		});
		if (this.props.projectID !== "") {
			crudReadUnass(
				`projects_log`,
				{
					field: "project_id",
					action: "==",
					value: this.props.projectID,
				},
				(status, response) => {
					console.log(status, response);
					if (status === 200) {
						this.setState({
							logs: response,
							loading: false,
						});
					} else {
						this.setState({ loading: false });
					}
				},
			);
		} else {
			crudReadUnass(`projects_log`, "", (status, response) => {
				console.log(status, response);
				if (status === 200) {
					this.setState({
						logs: response,
						loading: false,
					});
				} else {
					this.setState({ loading: false });
				}
			});
		}
	};

	render() {
		return (
			<View
				style={{
					flex: 1,
					padding: 5,
				}}>
				<View
					style={{
						flex: 1,

						paddingBottom: 60,
						backgroundColor: "#FFF",
						// padding: 15,
						borderRadius: 10,
					}}>
					<Text
						style={{
							color: APP_COLOURS.BG2,
							fontSize: 12,
							fontWeight: "800",
							fontFamily: "Gotham",
						}}>
						Activity log
					</Text>
					<ScrollView>
						{this.state.logs
							.sort(
								(a, b) =>
									moment(b.createdAt).unix() >
									moment(a.createdAt).unix(),
							)
							.map((d) => (
								<View
									key={`act-${d.id}`}
									style={{
										width: "100%",
										flexDirection: "row",
										// justifyContent: "space-between",
										padding: 3,
										marginBottom: 3,
										borderBottomWidth: 0.3,
										borderBottomColor: "#E8E8E8",
									}}>
									<Text
										style={{
											color: APP_COLOURS.BG2,
											width: 100,
										}}>
										{moment(d.createdAt).fromNow()}
									</Text>
									<Text
										style={{
											color: APP_COLOURS.BLUE,
											width: 100,
											fontWeight: "500",
										}}>
										{d.created_by}
									</Text>

									<Text
										style={{
											color: APP_COLOURS.BG2,
											// width: 200,
											fontWeight: "500",
										}}>
										{d.action}{" "}
										<Text
											style={{
												fontWeight: "200",
											}}>
											{d.data}
										</Text>
									</Text>
									{/* <Text
										style={{
											display:
												this.props.projectID !== ""
													? "none"
													: "flex",
											color: APP_COLOURS.BG2,
											width: 200,
										}}>
										{d.project_id}
									</Text> */}
								</View>
							))}
					</ScrollView>
				</View>
			</View>
		);
	}
}
