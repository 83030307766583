import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import { Avatars, StatusColour, TaskStatus } from "../../APP_VARS";
import {
	crudCreate,
	crudDelete,
	crudRead,
	crudReadAll,
	crudUpdate,
} from "../../CRUDHELPER";
import Fade from "react-reveal/Fade";
import StatusItem from "../DailyPlanner/StatusItem";
import AutoHeightImage from "../AutoHeightImageComp";
import BrandTabs from "../BrandTabs";

export default class CommunityManager extends Component {
	constructor(props) {
		super(props);
		this.state = {
			slas: [],
			uniqueServiceTitles: [],
			services: [],

			responses: [
				{
					brand: "Elizabeth Arden",
					category: "General response",
					response: `Hello [Name] and thank you for reaching out to Elizabeth Arden.
We love hearing from you, and your feedback is very important to us!
For consumer-related issues, stock availability, and PR requests, kindly forward your query and contact details to https://www.elizabetharden.co.za/contact-us/
Thank you,
Team ELIZABETH ARDEN
						`,
				},
				{
					brand: "Elizabeth Arden",
					response: `Hello [Name] and thank you for reaching out to Elizabeth Arden.
We're thrilled to hear from you and eager to assist further 😊
					Could you please send your details tohttps://www.elizabetharden.co.za/contact-us/Our team member will be in the perfect position to help you out and looks forward to hearing from you!
Thank you,
Team ELIZABETH ARDEN
					
						`,
				},

				{
					id: "1234",
					category:
						"Consumer-Related Issues / Adverse Reactions / Stock Availability",
					response:
						"Hello [Name] and thank you for contacting Elizabeth Arden. We love hearing from you, and your feedback is very important to us! For consumer-related issues, adverse reactions, and stock availability, kindly forward your query and contact details to https://www.elizabetharden.co.za/contact-us/ Thank you, Team ELIZABETH ARDEN",
				},
				{
					id: "1235",
					category: "Price Queries & Stockists",
					response:
						"Hello [Name] and thank you for reaching out to Elizabeth Arden. We love hearing from you, and your requests are very important to us! Our products vary in price and are available at any of our Elizabeth Arden stockists. For more information on our products, please visit www.elizabetharden.co.za. If you’re visiting a store, please ask one of our Elizabeth Arden representatives to assist you. For any additional questions or comments, please let us know, and we will gladly help. Thank you, Team ELIZABETH ARDEN",
				},
				{
					id: "1236",
					category: "Complaints",
					response:
						"Hello [Name] and thank you for reaching out to Elizabeth Arden. We're sorry to hear about your disappointing experience. Please could you collect and provide us with as many of these details and images as possible: Name & Surname, Phone number, Email address, Full name of the product concerned, A photo of the product, A photo of the barcode, A photo of the receipt, Store name where the product was purchased, Batch code number (this can be found embossed or engraved on the side or the bottom of the product and consists of 4-6 digits). Please send the above information to https://www.elizabetharden.co.za/contact-us/, and our team will tend to your email as soon as possible. Kind regards, Team ELIZABETH ARDEN",
				},
				{
					id: "1237",
					category: "Careers / I Want to Work at Mitchum",
					response:
						"Hello [Name] and thank you for expressing interest in working with Elizabeth Arden. We love hearing from you, and your enthusiasm is very important to us! Please visit our careers portal via the link http://www.elizabetharden.co.za/careers. Thank you, Team ELIZABETH ARDEN",
				},
			],
		};
	}

	render() {
		return (
			<View
				style={{
					flex: 1,
					padding: 10,
				}}>
				<View
					style={{
						// width: "100%",
						flex: 1,
						flexDirection: "row",
					}}>
					<View style={{ flex: 1, marginRight: 1 }}>
						<View
							style={{
								flex: 1,
								// flexDirection: "row",
								marginBottom: 5,
								maxWidth: 600,
								paddingBottom: 60,
								backgroundColor: "#FFF",
								padding: 5,
								borderRadius: 10,
							}}>
							<View
								style={{
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
								}}>
								<View
									style={{
										padding: 10,
									}}>
									<TouchableOpacity
										style={{
											flexDirection: "row",
											alignItems: "center",
											// position: "absolute",
										}}
										onPress={() => {
											// this.props.close();
										}}>
										<AutoHeightImage
											width={20}
											source={require("../../../assets/images/menu.png")}
										/>
										<Text
											adjustsFontSizeToFit
											style={{
												fontWeight: "800",
												fontFamily: "Gotham",
												color: APP_COLOURS.BG2,
												fontSize: 13,
												marginLeft: 4,
											}}>
											tetrice
										</Text>
									</TouchableOpacity>
									<Text
										style={{
											color: APP_COLOURS.BG2,
											fontSize: 28,
											fontWeight: "800",
											fontFamily: "Gotham",
											// marginTop: 20,
											// marginTop: 6,
										}}>
										Community Management
									</Text>

									<Text
										style={{
											fontSize: 16,
											color: APP_COLOURS.BG2,
											fontWeight: "800",
											padding: 2,
											marginBottom: 20,
										}}>
										Brands, Standard responses, Tone of
										voice
									</Text>
								</View>
								<View
									style={
										{
											// position: "absolute",
											// right: 10,
											// top: 10,
										}
									}>
									<AutoHeightImage
										width={150}
										source={require("../../../assets/images/workfromhome.png")}
									/>
								</View>
							</View>

							<View
								style={{
									padding: 10,
									borderRadius: 10,
									backgroundColor: APP_COLOURS.BG2,
									// backgroundColor: "#FFF",
									// maxWidth: 350,
									marginVertical: 10,
								}}>
								<Text
									style={{
										fontSize: 10,
										color: "#FFF",
										fontWeight: "400",
										fontFamily: "Gotham",
										// opacity: 0.5,
										padding: 0,
										marginBottom: 3,
									}}>
									Select brand
								</Text>
								<ScrollView
									horizontal
									showsHorizontalScrollIndicator={false}>
									<BrandTabs
										{...this.state}
										selectedSubPage={(d) =>
											this.setState({
												brand: d,
											})
										}
									/>
								</ScrollView>
							</View>
						</View>
					</View>

					<View style={{ flex: 1 }}>
						<View
							style={{
								flexDirection: "row",
								alignItems: "center",
							}}>
							<View>
								<Text
									style={{
										fontSize: 16,
										color: APP_COLOURS.BG2,
										fontWeight: "800",
										padding: 5,
										marginRight: 15,
									}}>
									SLA Service Codes & Documentation
								</Text>
								<Text
									style={{
										fontSize: 14,
										color: APP_COLOURS.BG2,
										fontWeight: "300",
										padding: 5,
										marginRight: 15,
									}}>
									Break down of all service offerings under
									each SLA.
								</Text>
							</View>
							<View
								style={{
									flex: 1,
									justifyContent: "flex-end",
									alignItems: "flex-end",
								}}>
								<Image
									style={{
										width: 250,
										height: 150,
										resizeMode: "contain",
									}}
									source={require("../../../assets/images/contentCalHead.png")}
								/>
							</View>
						</View>
						{/* .filter(
								(d) => d.brand === "Elizabeth Arden",
							), */}
						{this.standardResponses(this.state.responses)}
					</View>

					{/* <View style={{ flex: 2 }}>
						<View
							style={{
								flex: 1,
								paddingBottom: 60,
								backgroundColor: "#FFF",
								padding: 5,
								borderRadius: 10,
							}}>
							<View
								style={{
									width: "100%",

									paddingBottom: 2,
								}}>
								<View
									style={{
										flexDirection: "row",
										alignItems: "center",
									}}>
									<View>
										<Text
											style={{
												fontSize: 16,
												color: APP_COLOURS.BG2,
												fontWeight: "800",
												padding: 5,
												marginRight: 15,
											}}>
											SLA Service Codes & Documentation
										</Text>
										<Text
											style={{
												fontSize: 14,
												color: APP_COLOURS.BG2,
												fontWeight: "300",
												padding: 5,
												marginRight: 15,
											}}>
											Break down of all service offerings
											under each SLA.
										</Text>
									</View>
									<View
										style={{
											flex: 1,
											justifyContent: "flex-end",
											alignItems: "flex-end",
										}}>
										<Image
											style={{
												width: 250,
												height: 150,
												resizeMode: "contain",
											}}
											source={require("../../../assets/images/contentCalHead.png")}
										/>
									</View>
								</View>
								<View
									style={{
										// flex: 1,
										width: "100%",
										flexDirection: "row",
										alignItems: "center",
										height: 45,
									}}>
									<TouchableOpacity
										style={{
											marginRight: 10,
											marginLeft: 10,
										}}
										onPress={() =>
											this.setState({
												showInput:
													!this.state.showInput,
											})
										}>
										<AutoHeightImage
											width={19}
											source={require("../../../assets/images/searchIcon.png")}
										/>
									</TouchableOpacity>
									{this.state.loading ? (
										<ActivityIndicator
											color={APP_COLOURS.PRIMARY}
										/>
									) : null}
									<View
										style={{
											// height: "auto",
											// width: "100%",
											flex: 1,
											minWidth: 200,
											// maxWidth: 400,
											backgroundColor:
												APP_COLOURS.BLACK_TRANS,
											borderRadius: 13,
										}}>
										{this.state.showInput ? (
											<TextInput
												style={{
													fontWeight: "400",
													fontFamily: "Gotham",

													width: "100%",
													paddingLeft: 15,
													padding: 10,
													fontSize: 12,
													borderRadius: 13,
													marginRight: 15,
													color: APP_COLOURS.BG2,
													// backgroundColor:
													// 	APP_COLOURS.WHITE_TRANSPARENT4,
													backgroundColor: "#E8E8E8",
													fontWeight: "500",
												}}
												placeholderTextColor={"#797979"}
												autoCapitalize='sentences'
												clearButtonMode='while-editing'
												autoCompleteType='off'
												editable={true}
												autoCorrect={true}
												autoFocus
												keyboardType='default'
												textContentType='none'
												placeholder={` Search `}
												// defaultValue={this.state.user_data.email}
												defaultValue={
													this.state.filterSearch
												}
												onChangeText={(text) => {
													console.log(text);
													this.setState({
														filterSearch: text,
													});
												}}
											/>
										) : null}
									</View>

									<TouchableOpacity
										style={{
											// marginRight: 15,
											marginLeft: 15,
											backgroundColor: APP_COLOURS.BG2,
											borderRadius: 15,
											flexDirection: "row",
											paddingVertical: 10,
											alignItems: "center",
											paddingHorizontal: 15,
										}}
										onPress={async () => {
											this.setState({
												addNewService:
													!this.state.addNewService,
											});
										}}>
										<Text
											style={{
												color: "#FFF",
												fontSize: 14,
												marginRight: 10,
											}}>
											+
										</Text>
										<Text
											style={{
												color: "#FFF",
												fontSize: 12,
											}}>
											New
										</Text>
									</TouchableOpacity>
								</View>
								<View style={{ flex: 1 }}>
									<ScrollView
										showsHorizontalScrollIndicator={false}
										horizontal>
										<View
											style={{
												flexDirection: "row",
											}}>
											<TouchableOpacity
												style={{}}
												onPress={() =>
													this.setState({
														filterTab: "",
													})
												}>
												<View
													style={{
														width: "auto",
														//  height: 50,
														justifyContent:
															"center",
														alignItems: "center",

														padding: 10,
														borderRadius: 12,
														marginRight: 5,
														backgroundColor:
															this.state
																.filterTab ===
															""
																? APP_COLOURS.BLUE
																: APP_COLOURS.WHITE_TRANSPARENT9,
													}}>
													<Text
														style={{
															fontSize: 12,
															color:
																this.state
																	.filterTab ===
																""
																	? "#FFF"
																	: APP_COLOURS.BLUE,
															fontWeight: "400",
															fontFamily:
																"Avenir Next",
														}}>
														All
													</Text>
												</View>
											</TouchableOpacity>
											{this.state.uniqueServiceTitles
												.filter((d_) => {
													let filter = d_;

													if (
														typeof this.state
															.filterSearch !==
															"undefined" &&
														this.state
															.filterSearch !== ""
													) {
														let text_ =
															this.state
																.filterSearch;
														var outString =
															text_.replace(
																/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
																"",
															);

														let join_names = `${d_}`;

														join_names =
															join_names.replace(
																/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
																"",
															);

														return join_names
															.toLowerCase()
															.includes(
																outString.toLowerCase(),
															);
													}

													return filter;
												})
												.map((d, i) => (
													<TouchableOpacity
														key={i}
														style={{}}
														onPress={() =>
															this.setState({
																filterTab: d,
															})
														}>
														<View
															style={{
																width: "auto",
																//   height: 50,
																justifyContent:
																	"center",
																alignItems:
																	"center",
																backgroundColor:
																	this.state
																		.filterTab ===
																	d
																		? APP_COLOURS.BLUE
																		: APP_COLOURS.WHITE_TRANSPARENT9,
																padding: 10,
																borderRadius: 10,
																marginRight: 5,
															}}>
															<Text
																style={{
																	fontSize: 12,
																	color:
																		this
																			.state
																			.filterTab ===
																		d
																			? "#FFF"
																			: APP_COLOURS.BG2,
																	fontWeight:
																		"500",
																}}>
																{d}
															</Text>
														</View>
													</TouchableOpacity>
												))}
										</View>
									</ScrollView>
								</View>
							</View>

							<ScrollView>
								{this.state.services
									.sort((a, b) => a.title > b.title)

									.filter((d_) => {
										let filter = d_;

										if (
											typeof this.state.filterTab !==
												"undefined" &&
											this.state.filterTab !== ""
										) {
											let text_ = this.state.filterTab;
											var outString = text_.replace(
												/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
												"",
											);

											let join_names = `${d_.brand?.title} ${d_.sla_id}`;

											join_names = join_names.replace(
												/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
												"",
											);

											return join_names
												.toLowerCase()
												.includes(
													outString.toLowerCase(),
												);
										}

										return filter;
									})

									.map((d, i) => this.serviceItem(d))}
							</ScrollView>
						</View>
					</View> */}
				</View>
			</View>
		);
	}

	standardResponses = (d) => {
		return (
			<View
				style={{
					// flex: 1,
					minWidth: 500,
					minHeight: 500,
					maxHeight: 600,
				}}>
				<View
					style={{
						// flex: 1,
						width: "100%",
						flexDirection: "row",
						alignItems: "center",
						height: 45,
					}}>
					<TouchableOpacity
						style={{
							marginRight: 10,
							marginLeft: 10,
						}}
						onPress={() =>
							this.setState({
								showInput: !this.state.showInput,
							})
						}>
						<AutoHeightImage
							width={19}
							source={require("../../../assets/images/searchIcon.png")}
						/>
					</TouchableOpacity>
					{this.state.loading ? (
						<ActivityIndicator color={APP_COLOURS.PRIMARY} />
					) : null}
					<View
						style={{
							// height: "auto",
							// width: "100%",
							flex: 1,
							minWidth: 200,
							// maxWidth: 400,
							backgroundColor: APP_COLOURS.BLACK_TRANS,
							borderRadius: 13,
						}}>
						<TextInput
							style={{
								fontWeight: "400",
								fontFamily: "Gotham",

								width: "100%",
								paddingLeft: 15,
								padding: 10,
								fontSize: 12,
								borderRadius: 13,
								marginRight: 15,
								color: APP_COLOURS.BG2,
								// backgroundColor:
								// 	APP_COLOURS.WHITE_TRANSPARENT4,
								backgroundColor: "#E8E8E8",
								fontWeight: "500",
							}}
							placeholderTextColor={"#797979"}
							autoCapitalize='sentences'
							clearButtonMode='while-editing'
							autoCompleteType='off'
							editable={true}
							autoCorrect={true}
							autoFocus
							keyboardType='default'
							textContentType='none'
							placeholder={` Search `}
							// defaultValue={this.state.user_data.email}
							defaultValue={this.state.filterSearch}
							onChangeText={(text) => {
								console.log(text);
								this.setState({
									filterSearch: text,
								});
							}}
						/>
					</View>

					<TouchableOpacity
						style={{
							// marginRight: 15,
							marginLeft: 15,
							backgroundColor: APP_COLOURS.BG2,
							borderRadius: 15,
							flexDirection: "row",
							paddingVertical: 10,
							alignItems: "center",
							paddingHorizontal: 15,
						}}
						onPress={async () => {
							this.setState({
								addNewService: !this.state.addNewService,
							});
						}}>
						<Text
							style={{
								color: "#FFF",
								fontSize: 14,
								marginRight: 10,
							}}>
							+
						</Text>
						<Text
							style={{
								color: "#FFF",
								fontSize: 12,
							}}>
							New
						</Text>
					</TouchableOpacity>
				</View>
				<View
					style={{
						flex: 1,
						paddingBottom: 60,
						// backgroundColor: "#FFF",
						padding: 5,
						borderRadius: 10,
					}}>
					<View
						style={{
							width: "100%",

							paddingBottom: 2,
						}}>
						<View style={{ flex: 1 }}>
							<ScrollView>
								<View
									style={{
										marginVertical: 10,
									}}>
									{d
										.filter((d_) => {
											let filter = d_;

											if (
												typeof this.state
													.filterSearch !==
													"undefined" &&
												this.state.filterSearch !== ""
											) {
												let text_ =
													this.state.filterSearch;
												var outString = text_.replace(
													/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
													"",
												);

												let join_names = `${JSON.stringify(
													d_,
												)}`;

												join_names = join_names.replace(
													/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
													"",
												);

												return join_names
													.toLowerCase()
													.includes(
														outString.toLowerCase(),
													);
											}

											return filter;
										})
										.map((d, i) => (
											<TouchableOpacity
												key={i}
												style={{}}
												onPress={() => {
													this.setState({
														filterTab: d.id,
													});
												}}>
												<View
													style={{
														width: "100%",
														//   height: 50,
														// justifyContent:
														// 	"center",
														// alignItems: "center",
														backgroundColor: "#FFF",
														padding: 10,
														borderRadius: 10,
														marginRight: 5,
													}}>
													<Text
														style={{
															fontSize: 15,
															color: APP_COLOURS.BG2,
															fontWeight: "500",
														}}>
														{d.category}
													</Text>
													<Text
														style={{
															fontSize: 15,
															color: APP_COLOURS.BG2,
															fontWeight: "300",
															marginVertical: 10,
														}}>
														{d.response}
													</Text>
												</View>
											</TouchableOpacity>
										))}
								</View>
							</ScrollView>
						</View>
					</View>

					{/* <ScrollView>
						{this.state.services
							.sort((a, b) => a.title > b.title)

							.filter((d_) => {
								let filter = d_;

								if (
									typeof this.state.filterTab !==
										"undefined" &&
									this.state.filterTab !== ""
								) {
									let text_ = this.state.filterTab;
									var outString = text_.replace(
										/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
										"",
									);

									let join_names = `${d_.brand?.title} ${d_.sla_id}`;

									join_names = join_names.replace(
										/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
										"",
									);

									return join_names
										.toLowerCase()
										.includes(outString.toLowerCase());
								}

								return filter;
							})

							.map((d, i) => this.serviceItem(d))}
					</ScrollView> */}
				</View>
			</View>
		);
	};

	async componentDidMount() {
		// this.setState({
		// 	...this.props,
		// });
		// console.log(this.props.user_data.id);
		// this.getAllServices();
	}
}
