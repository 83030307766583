import React, { useState } from "react";
import { View, Image } from "react-native";
import ImageUploaderComp from "./ImageUploaderComp"; // Adjust the path as necessary
import { ScrollView } from "react-native-web";

const ImageUploader = ({ assets }) => {
	const [images, setImages] = useState([]);

	const handleImagesUploaded = (uploadedImages) => {
		setImages(uploadedImages);
		assets(uploadedImages);
	};

	return (
		<View
			style={{
				width: "100%",
			}}>
			<ImageUploaderComp onImagesUploaded={handleImagesUploaded} />
			<View
				style={{
					width: "100%",
				}}>
				<ScrollView horizontal>
					{images.map((img, index) => (
						<Image
							key={index}
							source={{ uri: img.base64 }}
							style={{ width: 200, height: 200 }}
						/>
					))}
				</ScrollView>
			</View>
		</View>
	);
};

export default ImageUploader;
