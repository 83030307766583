import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import logo_tetrice from "../../../assets/images/tetrice-revlon-main.png";
import "./resizeScale.scss";
import "./hoverEffect.scss";
import { APP_COLOURS } from "../../APP_VARS";

export default class LandingPage extends Component {
	constructor(props) {
		super(props);
		this.state = { inView: false };
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({
				inView: true,
				showObjects: true,
				showPhone: true,
			});
		}, 500);
	}

	render() {
		let contain =
			window.innerWidth < window.innerHeight
				? window.innerWidth * 0.8
				: window.innerHeight * 0.45;

		let maxContain = contain > 500 ? 500 : contain;

		let ar = 636 / 545;

		let h = window.innerHeight;
		let w = window.innerWidth;

		let { h1, h2, pp } = this.props;

		return (
			<View
				style={{
					// flex: 1,
					// width: window.innerWidth,
					width: "100%",
					// height: window.innerHeight,
					// height: window.innerHeight * 0.75,
					// paddingLeft: "15%",
					// backgroundColor: "#171A20",
					// padding: "5%",
					// minHeight: 750,
				}}>
				<View
					style={{
						// width: window.innerWidth,
						flexDirection: "row",
						flexWrap: "wrap",
						marginBottom: "10%",
						overflow: "hidden",
						justifyContent: "space-between",
					}}>
					<View
						style={{
							flex: 1,
							maxWidth: 700,
							opacity: 0.9,
						}}>
						<Fade cascade top>
							{/* <Image
							source={logo_tetrice}
							style={{
								width: "50%",
								maxWidth: 500,
								height: 100,
								resizeMode: "contain",
							}}
						/> */}
							<View
								style={{
									width: "90%",
									marginBottom: 20,
									maxWidth: 500,
								}}>
								<img
									src={logo_tetrice}
									alt='img'
									className='responsive'
								/>
							</View>

							<View
								style={{
									width: "90%",
									marginBottom: 20,
									maxWidth: 350,
								}}>
								<img
									src={require("../../../assets/images/Social Media.png")}
									alt='img'
									className='responsive'
								/>
							</View>

							<View
								style={{
									width: "90%",
									marginBottom: 20,
									maxWidth: 450,
								}}>
								<img
									src={require("../../../assets/images/EoM REPORT.png")}
									alt='img'
									className='responsive'
								/>
							</View>

							<Text
								style={{
									fontSize: h1,
									opacity: 0.7,
									color: "#FFF",
								}}>
								<Font family='Poppins' weight={600}>
									Elizabeth Arden
								</Font>
							</Text>
							<Text
								style={{
									fontSize: pp,
									color: "#FFF",
								}}>
								<Font family='Poppins' weight={600}>
									November 2023
								</Font>
							</Text>
						</Fade>
					</View>
					<View
						style={{
							width: "90%",
							maxWidth: 500,
						}}>
						<img
							src={require("../../../assets/images/shoutImage.png")}
							alt='img'
							className='responsive'
						/>
					</View>
				</View>

				<View
					style={{
						flex: 1,
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
						// marginBottom: "10%",
					}}>
					<View
						style={{
							width: "50%",
							marginBottom: 20,
							maxWidth: 250,
						}}>
						<img
							src={require("../../../assets/images/services.png")}
							alt='img'
							className='responsive'
						/>
					</View>
					<View
						style={{
							width: "50%",
							marginBottom: 20,
							maxWidth: 250,
						}}>
						<img
							src={require("../../../assets/images/infod.png")}
							alt='img'
							className='responsive'
						/>
					</View>
				</View>
			</View>
		);
	}
}
