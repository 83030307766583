import React, { Component } from "react";
import {
	View,
	Text,
	ActivityIndicator,
	TouchableOpacity,
	ScrollView,
} from "react-native";
import moment from "moment";

import { APP_COLOURS, screenWidth } from "../../APP_VARS";
import {
	crudCreate,
	crudUpdate,
	crudRead,
	crudReadAll,
	crudSendEmail,
} from "../../CRUDHELPER";
import AutoHeightImage from "../AutoHeightImageComp";

export default class Schedule extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showDates: false,

			selectedDate: moment().format("DD/MM/YYYY"),
			currentMonthInView: moment(),
			currentMomnth: moment(),
			dates: Array.from(Array(moment().daysInMonth()).keys()),
			weekStart: moment(moment().startOf("month")).format("w") - 1,
			weekEnd: moment(moment().endOf("month")).format("w") + 1,
			totalWeeks:
				moment(moment().endOf("month")).format("w") -
				moment(moment().startOf("month")).format("w") +
				1,

			datesArray: [],
			layoutWidth: 300,
			dayOfWeekArray: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],

			schedule: [],

			loading: true,
			scheduleType: "",
		};
	}

	async componentDidMount() {
		this.sideCalc();

		this.getSchedule();
	}

	getSchedule = () => {
		crudRead(
			"staff_schedule",
			{
				field: "createdBy",
				action: "==",
				value: this.props.user_data.id,
			},
			(status, d) => {
				console.log(this.props.user_data.id, status, d);
				if (status === 200) {
					this.setState({
						schedule: d,
						loading: false,
					});
				}
			},
		);
	};

	changeMonth = async (change) => {
		let newMonth = "";
		if (change === "next") {
			newMonth = moment(this.state.currentMonthInView).add(1, "month");
		} else {
			newMonth = moment(this.state.currentMonthInView).subtract(
				1,
				"month",
			);
		}

		await this.setState({
			currentMonthInView: newMonth,
		});

		this.sideCalc();
	};

	createSchedule = (status) => {
		this.setState({
			loading: true,
		});
		crudCreate(
			"staff_schedule",
			{
				user: {
					id: this.props.user_data.id,
					first_name: this.props.user_data.first_name,
					last_name: this.props.user_data.last_name,
				},
				assignment_ids: [this.props.user_data.id],
				scheduleDate: this.state.selectedDate,
				status: status,
				approval: "Pending",
			},
			(status, d) => {
				console.log(status, d);
				this.getSchedule();
			},
		);
	};

	updateSchedule = (data) => {
		this.setState({
			loading: true,
		});
		crudUpdate("staff_schedule", data, (status, d) => {
			this.getSchedule();
		});
	};

	render() {
		return (
			<View
				style={{
					flex: 1,

					width: "100%",
				}}>
				<ScrollView showsVerticalScrollIndicator={false}>
					<View
						style={{
							// flex: 1,
							// flexDirection: "row",
							width: "100%",
						}}>
						<View
							style={{
								padding: 5,
								// backgroundColor: APP_COLOURS.BG3,
								// borderRadius: 10,
								// marginBottom: 10,
							}}>
							{/* <Text
								allowFontScaling={false}
								style={{
									fontSize: 20,
									fontFamily: "Gotham",
									textAlign: "center",
									fontWeight: "700",
									color: "#FFF",
								}}>
								Work calendar
							</Text> */}

							<View
								style={{
									flex: 1,
									maxWidth: 600,
								}}>
								<View
									style={{
										flex: 1,
										// margin: 10,
										padding: 12,
										borderRadius: 10,
									}}>
									<View
										onLayout={(e) => {
											this.setState({
												layoutWidth:
													e.nativeEvent.layout.width,
											});
										}}
										style={{}}>
										<View
											style={{
												flexDirection: "row",
												justifyContent: "space-between",
											}}>
											<TouchableOpacity
												onPress={() =>
													this.changeMonth("prev")
												}>
												<View>
													<AutoHeightImage
														width={35}
														source={require("../../../assets/images/left_btn.png")}
													/>
												</View>
											</TouchableOpacity>
											<View>
												<Text
													style={{
														color: "#FFF",
														fontWeight: "700",
													}}>
													{moment(
														this.state
															.currentMonthInView,
													).format("MMMM YYYY")}
												</Text>
											</View>
											<TouchableOpacity
												onPress={() =>
													this.changeMonth("next")
												}>
												<View>
													<AutoHeightImage
														width={35}
														source={require("../../../assets/images/right_btn.png")}
													/>
												</View>
											</TouchableOpacity>
										</View>
										<View
											style={{
												justifyContent: "center",
												alignItems: "center",
												width: "100%",
											}}>
											{this.heading()}
											<View
												style={{
													width: "100%",
													justifyContent: "center",
													alignItems: "center",
												}}>
												<View
													style={{
														flexDirection: "row",
														flexWrap: "wrap",
													}}>
													{this.state.datesArray.map(
														(d, i) => {
															let today =
																moment().format(
																	"DD/MM/YYYY",
																);
															let layoutWidth =
																this.state
																	.layoutWidth /
																7;

															let scheduleItem =
																this.state.schedule.filter(
																	(s) =>
																		s.scheduleDate ===
																		d,
																);

															let scheduled =
																scheduleItem.map(
																	(m) =>
																		m.status,
																)[0];
															return (
																<TouchableOpacity
																	style={{
																		width: layoutWidth,
																		height: layoutWidth,
																		padding: 2,
																		// overflow:
																		// 	"hidden",
																	}}
																	onPress={() => {
																		this.setState(
																			{
																				selectedDate:
																					d,
																				// showOptions:
																				// 	!this.state
																				// 		.showOptions,

																				scheduleType:
																					typeof scheduled !==
																					"undefined"
																						? "update"
																						: "create",
																				scheduleItem:
																					scheduleItem[0],
																			},
																		);

																		// console.log(d, scheduled);
																		// this.setDate(d);
																	}}>
																	<View
																		key={i}
																		style={{
																			flex: 1,

																			justifyContent:
																				"center",
																			alignItems:
																				"center",
																			borderRadius: 8,
																			borderWidth: 2,

																			textAlign:
																				"center",
																			backgroundColor:
																				scheduled !==
																				""
																					? scheduled ===
																					  "working from office"
																						? APP_COLOURS.BLUE
																						: scheduled ===
																						  "working from home"
																						? APP_COLOURS.GREEN
																						: scheduled ===
																						  "request leave"
																						? APP_COLOURS.RED
																						: "transparent"
																					: this
																							.state
																							.selectedDate ===
																					  d
																					? APP_COLOURS.BLUE2
																					: APP_COLOURS.BG2,
																			borderColor:
																				this
																					.state
																					.selectedDate ===
																				d
																					? "#FFF"
																					: today ===
																					  d
																					? "red"
																					: APP_COLOURS.BG2,
																			opacity:
																				moment(
																					d,
																					"DD/MM/YYYY",
																				).format(
																					"MM",
																				) ===
																				moment(
																					this
																						.state
																						.currentMonthInView,
																				).format(
																					"MM",
																				)
																					? moment(
																							d,
																							"DD/MM/YYYY",
																					  ).format(
																							"ddd",
																					  ) ===
																					  "Sun"
																						? 0.4
																						: moment(
																								d,
																								"DD/MM/YYYY",
																						  ).format(
																								"ddd",
																						  ) ===
																						  "Sat"
																						? 0.4
																						: 1
																					: 0.3,
																		}}>
																		<Text
																			style={{
																				color: "#FFF",
																				// this.state.selectedDate === d
																				//   ? '#FFF'
																				//   : '#141D31',
																				fontWeight:
																					this
																						.state
																						.selectedDate ===
																					d
																						? "600"
																						: "300",
																				fontSize: 15,
																			}}>
																			{moment(
																				d,
																				"DD/MM/YYYY",
																			).format(
																				"DD",
																			)}
																		</Text>
																	</View>
																</TouchableOpacity>
															);
														},
													)}
												</View>
											</View>
										</View>
									</View>
								</View>
							</View>

							{this.state.loading ? (
								<View
									style={{
										width: "100%",
										borderRadius: 10,
										alignItems: "center",
										padding: 15,
									}}>
									<ActivityIndicator
										color={APP_COLOURS.BLUE}
									/>
									<Text
										style={{
											color: "#FFF",
											fontSize: 13,
										}}>
										Loading schedule
									</Text>
								</View>
							) : (
								<View
									style={{
										width: "100%",
										justifyContent: "center",
										alignItems: "center",
										display:
											this.state.scheduleType !== ""
												? "flex"
												: "none",
									}}>
									<View
										style={{
											flexDirection: "row",

											borderRadius: 12,
											justifyContent: "center",
											alignItems: "center",
										}}>
										<TouchableOpacity
											onPress={() =>
												this.setState({
													addDate:
														!this.state.addDate,
												})
											}>
											<Text
												style={{
													fontWeight: "600",
													color: "#FFF",
													// opacity: 0.7,
													fontSize: 16,
												}}>
												{moment(
													this.state.selectedDate,
													"DD/MM/YYYY",
												).format("ddd DD MMM, YYYY")}
											</Text>
										</TouchableOpacity>
									</View>

									<View
										style={{
											width: "100%",
											padding: 15,
										}}>
										<TouchableOpacity
											onPress={() => {
												let type =
													"working from office";
												if (
													this.state.scheduleType ===
													"create"
												) {
													this.createSchedule(type);
												} else {
													this.updateSchedule({
														...this.state
															.scheduleItem,
														status: type,
													});
													console.log(
														this.state.scheduleItem,
													);
												}
											}}
											style={{
												padding: 15,
												width: "100%",
												borderRadius: 10,
												alignItems: "center",
												marginBottom: 10,

												backgroundColor:
													APP_COLOURS.BLUE,
											}}>
											<Text
												style={{
													fontSize: 14,
													color: "#FFF",
													fontWeight: "600",
												}}>
												Working from office
											</Text>
										</TouchableOpacity>
										<TouchableOpacity
											onPress={() => {
												let type = "working from home";
												if (
													this.state.scheduleType ===
													"create"
												) {
													this.createSchedule(type);
												} else {
													this.updateSchedule({
														...this.state
															.scheduleItem,
														status: type,
													});
													console.log(
														this.state.scheduleItem,
													);
												}
											}}
											style={{
												padding: 15,
												width: "100%",
												borderRadius: 10,
												alignItems: "center",
												marginBottom: 10,
												backgroundColor:
													APP_COLOURS.GREEN,
											}}>
											<Text
												style={{
													fontSize: 14,
													color: "#FFF",
													fontWeight: "600",
												}}>
												Working from home
											</Text>
										</TouchableOpacity>

										<TouchableOpacity
											onPress={() => {
												let type = "request leave";
												if (
													this.state.scheduleType ===
													"create"
												) {
													this.createSchedule(type);
												} else {
													this.updateSchedule({
														...this.state
															.scheduleItem,
														status: type,
													});
													console.log(
														this.state.scheduleItem,
													);
												}

												crudSendEmail(
													"SKrum  <skrum@tetrice.co.za>",
													"accounts@tetrice.co.za",
													`Leave Request - ${this.props.user_data.first_name} ${this.props.user_data.last_name}`,
													`${this.props.user_data.first_name} ${this.props.user_data.last_name}  has requested leave on ${this.state.selectedDate}`,
												);
											}}
											style={{
												padding: 15,
												width: "100%",
												borderRadius: 10,
												alignItems: "center",
												marginBottom: 10,
												backgroundColor:
													APP_COLOURS.RED,
											}}>
											<Text
												style={{
													fontSize: 14,
													color: "#FFF",
													fontWeight: "600",
												}}>
												Request leave
											</Text>
										</TouchableOpacity>

										{/* <TouchableOpacity
											onPress={() => {
												let type = "sick leave";
												if (
													this.state.scheduleType ===
													"create"
												) {
													this.createSchedule(type);
												} else {
													this.updateSchedule({
														...this.state
															.scheduleItem,
														status: type,
													});
													console.log(
														this.state.scheduleItem,
													);
												}
											}}
											style={{
												padding: 15,
												width: "100%",
												borderRadius: 10,
												alignItems: "center",
												marginBottom: 10,

												backgroundColor:
													APP_COLOURS.ORANGE,
											}}>
											<Text
												style={{
													fontSize: 14,
													color: "#FFF",
													fontWeight: "600",
												}}>
												Sick leave
											</Text>
										</TouchableOpacity> */}
									</View>
								</View>
							)}
						</View>

						<View
							style={{
								// flex: 1,
								minHeight: 500,
								padding: 5,
								backgroundColor: APP_COLOURS.BG3,
								borderRadius: 10,
								paddingTop: 10,
							}}>
							<Text
								allowFontScaling={false}
								style={{
									fontSize: 20,
									fontFamily: "Gotham",
									textAlign: "center",
									fontWeight: "700",
									color: "#FFF",
								}}>
								Leave Schedule
							</Text>

							<ScrollView>
								<View
									style={{
										flex: 1,
									}}>
									{this.state.schedule
										.filter(
											(s) => s.status === "request leave",
										)
										.map((d, i) => {
											return (
												<View
													style={{
														flexDirection: "row",
														alignItems: "center",
														padding: 10,
														marginBottom: 10,
														borderBottomWidth: 0.5,
														borderBottomColor:
															APP_COLOURS.BG4,
													}}>
													<Text
														style={{
															fontSize: 14,
															color: "#FFF",
															fontWeight: "600",
															width: 100,
														}}>
														{d.scheduleDate}
													</Text>

													<Text
														style={{
															fontSize: 14,
															color: "#FFF",
															fontWeight: "600",
															width: 100,
														}}>
														{d.status}
													</Text>
													<View style={{ flex: 1 }} />
													<View
														style={{
															padding: 5,
															borderRadius: 5,
															borderWidth: 0.5,
															borderColor:
																APP_COLOURS.BG4,
															flexDirection:
																"row",
														}}>
														<View
															style={{
																display:
																	d.approval.toLowerCase() ===
																	"approved"
																		? "flex"
																		: "none",
																marginRight: 10,
															}}>
															<AutoHeightImage
																width={15}
																source={require("../../../assets/images/tick.png")}
															/>
														</View>
														<Text
															style={{
																fontSize: 14,
																color: "#FFF",

																width: 100,
															}}>
															{d.approval}
														</Text>
													</View>
												</View>
											);
										})}
								</View>
							</ScrollView>
						</View>
					</View>
				</ScrollView>
			</View>
		);
	}

	heading = () => {
		let layoutWidth = this.state.layoutWidth / 7;
		// console.log(layoutWidth);
		return (
			<View
				style={{
					// flex: 1,
					// paddingLeft: 10,
					// paddingRight: 10,
					marginTop: 6,
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
				}}>
				{this.state.dayOfWeekArray.map((d, i) => {
					return (
						<View
							key={i}
							style={{
								width: layoutWidth,
								height: 37,
								//  margin: 2,
								justifyContent: "center",
								alignItems: "center",
								borderRadius: 8,
							}}>
							<Text
								style={{
									color: "#FFF",
									fontSize: 14,
									opacity: 0.7,
								}}>
								{d}
							</Text>
						</View>
					);
				})}
			</View>
		);
	};

	sideCalc = () => {
		// currentMonthInView: moment(this.state.selectedDate, "DD/MM/YYYY"),

		let datesArray = [];
		let curr = this.state.currentMonthInView;
		// let curr = moment("02/01/2023", "DD/MM/YYYY");
		// console.log('curr', curr);
		// console.log(
		// 	this.state.totalWeeks,
		// 	Array.from(Array(this.state.totalWeeks).keys()).length,
		// 	"weeks",
		// );
		let startDateInMonth = moment(curr).startOf("month");
		let startDayInMonth = moment(curr).startOf("month").format("ddd");
		// console.log('startDayInMonth', startDayInMonth);
		//
		//
		// Calculate the day of the week that is the start day of the mont
		// the number of days offset before the start
		let dayOfWeekArray = this.state.dayOfWeekArray;
		// let daysBeforeStartOfMonth = dayOfWeekArray.slice(
		// 	0,
		// 	dayOfWeekArray.indexOf(startDayInMonth),
		// );
		let arr = dayOfWeekArray.indexOf(startDayInMonth);
		// console.log(arr);
		if (arr > 0) {
			let dLoop = Array.from(Array(arr).keys());
			for (const d of dLoop) {
				let doff = d + 1;
				let date = moment(startDateInMonth)
					.subtract(doff, "days")
					.format("DD/MM/YYYY");
				datesArray.push(date);
			}
		}

		//
		//
		//

		let dayesInMonth = Array.from(Array(moment(curr).daysInMonth()).keys());

		for (const d of dayesInMonth) {
			let date = moment(startDateInMonth)
				.add(d, "day")
				.format("DD/MM/YYYY");
			datesArray.push(date);
		}

		let datesSorted = datesArray.sort(
			(a, b) =>
				moment(a, "DD/MM/YYYY").unix() - moment(b, "DD/MM/YYYY").unix(),
		);
		// console.log('datesArray', datesSorted);

		this.setState({
			datesArray: datesSorted,
		});
	};
}
