import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TextInput,
	ScrollView,
	Modal,
	Alert,
	Keyboard,
	Animated,
	LayoutAnimation,
	ActivityIndicator,
} from "react-native";
import {
	APP_COLOURS,
	TaskStatus,
	CustomLayoutSpring,
	Avatars,
} from "../../APP_VARS";
import moment from "moment";
import Lottie from "react-lottie";
import {
	crudRead,
	crudReadAll,
	crudCreate,
	crudDelete,
	crudUpdate,
	crudGetUsers,
} from "../../CRUDHELPER";
import TodayTimeline from "./TodayTimeline";
import EventCalendar from "../EventCalendar";
import AutoHeightImage from "../AutoHeightImageComp";

export default class TetriceCalendarMobile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			projects: [],
			tasks: [],
			crudDocumentName: "projects",
			companyEvents: [],

			recurringEvents: [
				// {
				// 	title: "CM & CC end of month report",
				// 	date: "20/12/2023",
				// },
			],
		};
	}
	componentDidMount() {
		this.getDeviceData();
	}

	getDeviceData = async () => {
		try {
			const value = await localStorage.getItem("user_data");
			if (value !== null) {
				const d = JSON.parse(value);
				await this.setState({
					user_data: d,
					accessToken: d.accessToken,
				});
				this.getAllProjectTasks();
			}
		} catch (error) {
			// Error retrieving data
		}
	};

	getAllProjectTasks = () => {
		if (this.state.user_data.id === "") {
			return;
		}
		this.setState({
			loading: true,
		});
		crudRead(
			`${this.state.crudDocumentName}_tasks`,
			{
				field: "assignment_ids",
				action: "array-contains",
				value: this.state.user_data.id,
			},
			async (status, d) => {
				console.log(status, d);
				if (status === 200) {
					await this.setState({
						tasks: d,

						loading: false,
					});

					localStorage.setItem("tasks", JSON.stringify(d));
				}
			},
		);
	};

	render() {
		return (
			<View
				style={{
					flex: 1,
					// padding: 10,
				}}>
				<View
					style={{
						flex: 1,
						width: "100%",
						// maxWidth: 400,
						// backgroundColor: "#E0E3EB",
						zIndex: 999,
						// display: this.state.addProjectTask ? 'flex' : 'none',
						borderTopLeftRadius: 15,
						borderTopRightRadius: 15,
					}}>
					{this.state.recurringEvents.map((d, i) => (
						<View
							key={i}
							style={{
								padding: 10,
								flexDirection: "row",
								backgroundColor: "#FFF",
								borderRadius: 5,
								// opacity: 0.6,
								marginBottom: 3,
							}}>
							<View
								style={{
									height: 20,
									width: 3,
									backgroundColor: APP_COLOURS.RED2,
									borderRadius: 3,
									marginRight: 10,
								}}
							/>
							<Text
								style={{
									color: APP_COLOURS.BG2,
									// color: "#FFF",
									fontWeight: "400",
									fontSize: 15,
								}}>
								{/* {moment().isSame(
												moment(d.date_monthly),
											)
												? "Due: "
												: "Start: "}{" "} */}
								{d.title}
							</Text>
						</View>
					))}
					<TodayTimeline
						tasks={this.state.tasks.filter((project) => {
							return moment(project.start_date).isSame(
								moment(),
								"day",
							);
						})}
						close={() =>
							this.setState({
								showTodayView: false,
							})
						}
					/>
				</View>
			</View>
		);
	}
}
