import React, { useEffect } from "react";

const AutoRefresh = ({ children }) => {
	let timer;

	// Resets the timer
	const resetTimer = () => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			// Refresh the page
			window.location.reload();
		}, 600000); // 10 minutes in milliseconds
	};

	useEffect(() => {
		// Events that should reset the timer
		const events = ["click", "load", "keypress", "scroll", "mousemove"];

		// Reset the timer on any of the above events
		for (let event of events) {
			window.addEventListener(event, resetTimer);
		}

		// Set the initial timer
		resetTimer();

		// Cleanup on component unmount
		return () => {
			clearTimeout(timer);
			for (let event of events) {
				window.removeEventListener(event, resetTimer);
			}
		};
	}, []);

	return <>{children}</>;
};

export default AutoRefresh;
