import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import { Avatars, StatusColour, TaskStatus } from "../../APP_VARS";
import StatusItem from "../DailyPlanner/StatusItem";
import Lottie from "react-lottie";
import {
	createPostApproval,
	crudCreate,
	crudDelete,
	crudGetUsers,
	crudRead,
	crudReadAll,
	crudUpdate,
	sendDiscordMessage,
} from "../../CRUDHELPER";
import Fade from "react-reveal/Fade";
import * as animationData from "../../../assets/lottie/team.json";

import AutoHeightImage from "../AutoHeightImageComp";
import InstagramPost from "./Instagram";
import ApprovalSent from "./ApprovalSent";
import SendingFeedback from "./SendingFeedback";

let posts = [
	{
		id: "retinolhpr1",
		title: "",
		method: "Post",
		platform: "instagram",
		username: "elizabetharden_sa",
		postDate: "09/11/2023",
		verified: true,
		avatarUri: require("./ea_logo.png"),
		commentCount: 5,
		postImageUri: [
			{
				type: "image",
				uri: require("./POSTS/Retinolhpr1@2x.png"),
			},
			{
				type: "image",
				uri: require("./POSTS/EA_RET_HPR_SOCIAL_ASSET_8_4X5_FRAME_2.jpg"),
			},
		],
		copy: `Introducing NEW Retinol + HPR Ceramide Rapid Skin Renewing Water Cream is powered with retinol and HPR, a next generation retinoid, to speed up the skin regeneration process for younger-looking skin without the typical side effects of retinol.

Who's ready to give retinol a shot? 🙋‍♀️ 
Let us know in the comments

#RetinolRedefined 
#Retinol 
#RetinolHPR 
#ElizabethArden
	`,
		tags: `@edgarsbeauty @foschinibeauty @woolworths_sa 
@superbalist @truworthsfashion @clicks_sa 
@dischem.beauty @dischem_pharmacies @friendsofbash @wooliesbeauty`,
	},
	{
		id: "colorstay",
		title: "",
		method: "Post trillogy",
		platform: "instagram",
		username: "revlonsa",
		postDate: "10/01/2024",
		verified: true,
		avatarUri: require("./revlonavtar.png"),
		commentCount: 5,
		postImageUri: [
			{
				type: "image",
				uri: "https://storage.googleapis.com/tetricepm-5f972.appspot.com/pm_projects_timeline/undefined_1704723104465?GoogleAccessId=firebase-adminsdk-w4xhp%40tetricepm-5f972.iam.gserviceaccount.com&Expires=1799366400&Signature=aGt8GLyFk51YkpOmX1D2cgyHWwcor4ij1uWN%2Bp97tBCzaYi%2F19f0Xsb8ttbjlzikYXdxmxrTSa7yuJh%2BmXJggDtltV98h6uxvKU9nmyaIljybdzI2b9m1LHqR3tvqnKD%2B9i4MyZUudmHMPuYt7KyRItTkCaKIT%2BDX4JyFm472T9Y%2FeRe3NErSfqOU%2FXdjJXCAAQYK9%2BhfdwHt3%2FYhaTE%2FMq6L5lqZ53zKMU93snE2C6LNjsTEsGRDLL%2BgQlT3jG%2BDCUFity3pMt68%2FiKQ9idivmic6%2BEOouHC5rHgn%2BeNH7lPC1Bk7EeUCge5pw%2BkdvqYVbBDznElxpt9zMFC%2FQUgg%3D%3D",
			},
			{
				type: "image",
				uri: "https://storage.googleapis.com/tetricepm-5f972.appspot.com/pm_projects_timeline/undefined_1704723134297?GoogleAccessId=firebase-adminsdk-w4xhp%40tetricepm-5f972.iam.gserviceaccount.com&Expires=1799366400&Signature=x%2FZrx4FeHodhOgXnFObz%2BLhUuOycyHOooO2koq2xujIw1mf%2FxzapIHoAXFvU8caUL%2F58bCqM3HECk5ZVcOV4U%2BTMTo9LQZt8D25igJYdsAtkRUHSCSmYHSJ8Te1Dv49IIZGi59OHcDyjWmVKSqw2kN80lP8EFj%2FNZm%2FgTU3KnJc3BsWPNN59g90OBRqe3BZUNUiu3IvauRUtlZlych%2B9g7R0jogW%2B8cLG15toIRYgLIxsv5Jn3yiezJcAQKmJ4Cpzgyleby5cJ9JdBACithbHO%2B%2FYHJXTp%2BmK%2BKmt%2Bbf%2FeumXww6s97x4SkRlFfkSV23DluP%2F5tYaJYb2WjeK1j3tA%3D%3D",
			},
			{
				type: "image",
				uri: "https://storage.googleapis.com/tetricepm-5f972.appspot.com/pm_projects_timeline/undefined_1704723134297?GoogleAccessId=firebase-adminsdk-w4xhp%40tetricepm-5f972.iam.gserviceaccount.com&Expires=1799366400&Signature=x%2FZrx4FeHodhOgXnFObz%2BLhUuOycyHOooO2koq2xujIw1mf%2FxzapIHoAXFvU8caUL%2F58bCqM3HECk5ZVcOV4U%2BTMTo9LQZt8D25igJYdsAtkRUHSCSmYHSJ8Te1Dv49IIZGi59OHcDyjWmVKSqw2kN80lP8EFj%2FNZm%2FgTU3KnJc3BsWPNN59g90OBRqe3BZUNUiu3IvauRUtlZlych%2B9g7R0jogW%2B8cLG15toIRYgLIxsv5Jn3yiezJcAQKmJ4Cpzgyleby5cJ9JdBACithbHO%2B%2FYHJXTp%2BmK%2BKmt%2Bbf%2FeumXww6s97x4SkRlFfkSV23DluP%2F5tYaJYb2WjeK1j3tA%3D%3D",
			},
		],
		copy: `Introducing NEW Retinol + HPR Ceramide Rapid Skin Renewing Water Cream is powered with retinol and HPR, a next generation retinoid, to speed up the skin regeneration process for younger-looking skin without the typical side effects of retinol.

		Who's ready to give retinol a shot? 🙋‍♀️ 
		Let us know in the comments
		
		#RetinolRedefined 
		#Retinol 
		#RetinolHPR 
		#ElizabethArden
			`,
		tags: `@edgarsbeauty @foschinibeauty @woolworths_sa 
		@superbalist @truworthsfashion @clicks_sa 
		@dischem.beauty @dischem_pharmacies @friendsofbash @wooliesbeauty`,
	},

	{
		id: "retinolhpr2",
		title: "",
		method: "Story",
		platform: "instagram",
		username: "elizabetharden_sa",
		postDate: "09/11/2023",
		verified: true,
		avatarUri: require("./ea_logo.png"),
		commentCount: 5,
		postImageUri: [
			{
				type: "video",
				uri: require("./POSTS/EA_RET_HPR_SOCIAL_ASSET_3_9X16.mp4"),
			},
		],
		copy: `Is skin that is smoother, refined and radiant your skincare goal? Look no further than Retinol and HPR Ceramide Rapid Skin Renewing Water Cream.

This potent yet very gentle retinol wonder packs all the ingredients you need to achieve healthy, beautiful skin. It's even gentle enough to use with your daytime routine, just remember to use SPF!
	
	`,
		tags: `@edgarsbeauty @foschinibeauty @woolworths_sa 
@superbalist @truworthsfashion @clicks_sa 
@dischem.beauty @dischem_pharmacies @friendsofbash @wooliesbeauty`,
	},

	{
		id: "glamour",
		title: "",
		method: "Post",
		platform: "instagram",
		username: "glamour_sa",
		postDate: "14/02/2024",
		verified: true,
		avatarUri: require("./ea_logo.png"),
		commentCount: 5,
		postImageUri: [
			{
				type: "image",
				uri: require("./POSTS/1.png"),
			},
			{
				type: "image",
				uri: require("./POSTS/2.png"),
			},
			{
				type: "image",
				uri: require("./POSTS/3.png"),
			},
			{
				type: "image",
				uri: require("./POSTS/4.png"),
			},
			{
				type: "image",
				uri: require("./POSTS/5.png"),
			},
			{
				type: "image",
				uri: require("./POSTS/6.png"),
			},
			{
				type: "image",
				uri: require("./POSTS/7.png"),
			},
		],
		copy: `
	`,
		tags: ``,
	},
	{
		id: "glamour2",
		title: "",
		method: "Post",
		platform: "instagram",
		username: "glamour_sa",
		postDate: "14/02/2024",
		verified: true,
		avatarUri: require("./ea_logo.png"),
		commentCount: 5,
		postImageUri: [
			{
				type: "image",
				uri: require("./POSTS/1 copy 5.png"),
			},
			{
				type: "image",
				uri: require("./POSTS/2 copy 12.png"),
			},
			{
				type: "image",
				uri: require("./POSTS/3 copy 2.png"),
			},
			{
				type: "image",
				uri: require("./POSTS/4 copy 10.png"),
			},
			{
				type: "image",
				uri: require("./POSTS/4 copy 11.png"),
			},
			{
				type: "image",
				uri: require("./POSTS/4 copy 12.png"),
			},
			{
				type: "image",
				uri: require("./POSTS/5 copy 6.png"),
			},
			{
				type: "image",
				uri: require("./POSTS/7 copy.png"),
			},
		],
		copy: `
	`,
		tags: ``,
	},
	{
		id: "glamour3",
		title: "",
		method: "Post",
		platform: "instagram",
		username: "glamour_sa",
		postDate: "14/02/2024",
		verified: true,
		avatarUri: require("./ea_logo.png"),
		commentCount: 5,
		postImageUri: [
			{
				type: "image",
				uri: require("./POSTS/1.3.1.png"),
			},
			{
				type: "image",
				uri: require("./POSTS/1.3.2.png"),
			},
			{
				type: "image",
				uri: require("./POSTS/1.3.3.png"),
			},
			{
				type: "image",
				uri: require("./POSTS/1.3.4.png"),
			},
			{
				type: "image",
				uri: require("./POSTS/1.3.5.png"),
			},
			{
				type: "image",
				uri: require("./POSTS/1.3.6.png"),
			},
			{
				type: "image",
				uri: require("./POSTS/1.3.7.png"),
			},
			{
				type: "image",
				uri: require("./POSTS/1.3.8.png"),
			},
		],
		copy: `
	`,
		tags: ``,
	},

	{
		id: "gwpvideo9s",
		title: "GWP 9second ",
		method: "Post",
		platform: "all",
		username: "elizabeth arden",
		postDate: "14/02/2024",
		verified: true,
		avatarUri: require("./ea_logo.png"),
		commentCount: 5,
		postImageUri: [
			{
				type: "video",
				uri: "https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/VIDEO-2024-02-15-12-17-24.mp4?alt=media&token=7f105f9b-5776-4696-bca9-1662ab098f7f",
			},
		],
		copy: `
	`,
		tags: ``,
	},
];

const PostApproval = () => {
	const [post, setPost] = useState(null);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const [sent, setSent] = useState(false);
	const [sending, setSending] = useState(false);

	const { postid, token } = useParams();

	document.title = `Post approval - ${postid}`;

	useEffect(() => {
		const fetchPost = async () => {
			let postData = posts.filter((d) => d.id === postid);

			setPost(postData[0]);
		};

		fetchPost();
	}, [postid, token]);

	// Approval handler
	const handleApproval = async (data) => {
		console.log("handleApproval", data);
		// return;
		setSending(true);

		data.token = token;

		sendDiscordMessage(
			`POST ${postid}`,
			`[POST REVIEW]: ${data.id} - ${data.token}
> ${data.reviewDate}

> Liked ? ${data.isLiked}
> ${data.approvedCopy ? `Copy APPROVED` : `Copy REJECTED`}
> ${data.approvedTags ? `Tags APPROVED` : `Tags REJECTED`}
> ${data.approvedPost ? `Post APPROVED` : `Post REJECTED`}
> ${data.approvedDate ? `Date APPROVED` : `Date REJECTED`}
${data.comment}
${window.location.href}

`,
			``,
			"0QPZYrY6zpAnJXqX9gQl",
		);

		setSending(false);
		setSent(true);
		// createPostApproval({ ...data }, (status_) => {
		// 	if (status_ === 200) {
		// 		setSending(false);
		// 		setSent(true);
		// 	} else {
		// 		setSending(false);
		// 		setSent(false);
		// 		setError(true);
		// 	}
		// });
	};

	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error}</div>;

	if (sending) return <SendingFeedback />;
	if (sent) return <ApprovalSent />;

	let screenWidth = window.innerWidth;

	screenWidth = screenWidth > 450 ? 450 : screenWidth;

	return (
		<View
			style={{
				backgroundColor: "#000",
				width: "100%",
				overflow: "hidden",
				height: window.innerHeight,
				alignItems: "center",
			}}>
			<View
				style={{
					flex: 1,
					width: screenWidth,
				}}>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						padding: 5,
					}}>
					<View
						style={{
							padding: 5,
							flexDirection: "row",
						}}>
						<TouchableOpacity
							style={{
								flexDirection: "row",
								alignItems: "center",
								// position: "absolute",
							}}
							onPress={() => {
								// this.props.close();
							}}>
							<AutoHeightImage
								width={27}
								source={require("../../../assets/images/menu.png")}
							/>
							<Text
								adjustsFontSizeToFit
								style={{
									fontWeight: "800",
									fontFamily: "Gotham",
									color: "#FFF",
									fontSize: 15,
									marginLeft: 4,
								}}>
								tetrice
							</Text>
							<Text
								adjustsFontSizeToFit
								style={{
									fontWeight: "300",
									fontFamily: "Gotham",
									color: "#FFF",
									fontSize: 15,
									marginLeft: 4,
									opacity: 0.7,
								}}>
								verification
							</Text>
						</TouchableOpacity>
						{/* <Text
						style={{
							color: "#FFF",
							fontSize: 19,
							fontWeight: "800",
							fontFamily: "Gotham",
							opacity: 0.8,
						}}>
						Content calendar
					</Text>

					<Text
						style={{
							fontSize: 16,
							color: "#FFF",
							fontWeight: "300",
							padding: 2,
							opacity: 0.7,
							marginBottom: 20,
						}}>
						Instagram post approval
					</Text> */}
						<Image
							source={require("./verified.png")}
							style={{
								width: 15,
								height: 15,
								resizeMode: "contain",
							}}
						/>
					</View>
				</View>
				<ScrollView showsVerticalScrollIndicator={false}>
					<View
						style={{
							justifyContent: "space-between",
							alignItems: "center",
							padding: 5,
						}}>
						{/* <View>
							<Text
								style={{
									color: "#FFF",
									fontSize: 19,
									fontWeight: "800",
									fontFamily: "Gotham",
									opacity: 0.8,
								}}>
								Content calendar
							</Text>

							<Text
								style={{
									fontSize: 14,
									color: "#FFF",
									fontWeight: "300",
									padding: 2,
									opacity: 0.7,
									marginBottom: 20,
								}}>
								Instagram post approval
							</Text>
						</View> */}
						{/* <View
							style={{
								// position: "absolute",
								// right: 10,
								// top: 10,
								maxWidth: 500,
							}}>
							<Lottie
								options={{
									loop: true,
									autoplay: true,
									animationData: animationData,
									rendererSettings: {
										preserveAspectRatio: "xMidYMid slice",
									},
								}}
								height={screenWidth * 0.9}
								width={screenWidth * 0.9}
							/>
						</View> */}
					</View>
					<InstagramPost
						screenWidth={screenWidth}
						id={postid}
						{...post}
						handleApproval={(data) => handleApproval(data)}
					/>
				</ScrollView>
				{/* {post && (
				<div>
					<h2>{post.title}</h2>

					<p>{post.content}</p>

					<button onClick={() => handleApproval(true)}>
						Approve
					</button>
					<button onClick={() => handleApproval(false)}>
						Reject
					</button>
				</div>
			)} */}
			</View>
		</View>
	);
};

export default PostApproval;
