import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { TouchableOpacity } from "react-native-web";

const styles = StyleSheet.create({
	container: { flexDirection: "row", flex: 1 },
	list: {
		flex: 1,
		padding: 20,
		backgroundColor: "#f5f5f5",
		maxWidth: 550,
	},
	step: {
		marginBottom: 10,
		padding: 10,
		backgroundColor: "#fff",

		borderRadius: 5,
		// shadowColor: "#000",
		// shadowOffset: { width: 0, height: 2 },
		// shadowOpacity: 0.1,
		// shadowRadius: 2,
		// elevation: 1,
	},
	title: {
		fontSize: 16,
		fontWeight: "bold",
		marginBottom: 5,
	},
	description: {
		fontSize: 14,
	},
	link: {
		fontSize: 14,
		color: "blue",
	},
});

const Step = ({ title, description, link }) => (
	<View style={styles.step}>
		<Text style={styles.title}>{title}</Text>
		<Text style={styles.description}>{description}</Text>
		{typeof link !== "undefined" && link !== "" ? (
			<TouchableOpacity
				onPress={() => {
					window.open(link, "_blank");
				}}>
				<Text style={styles.link}>{link}</Text>
			</TouchableOpacity>
		) : null}
	</View>
);

const ProcessFlow = () => {
	const processSteps = [
		{
			title: "Step 1: Access Google Drive",
			description:
				"Click on the link to Revlon & Elizabeth Arden - Google Drive.",
			link: "https://drive.google.com/drive/folders/1tjR3xonoU9knAtYrlt1xl9OQUgD-U6Vb",
		},
		{
			title: "Step 2: Navigate to Retailer Files",
			description:
				"Go to Retailer Files. Click on the retailer you want to review, then click on 2024.",
		},
		{
			title: "Step 3: Select Quarter",
			description:
				"Select Q1 and choose the relevant NPD (New Product Development).",
		},
		{
			title: "Step 4: Access Documents",
			description: "Access all supporting documents related to the NPD.",
		},
		{
			title: "Step 5: Use R-drive",
			description:
				"Navigate to R-drive > B-Busisiwe for access to paid media files, retailer listings, Global CR briefs, etc.",
			link: "https://docs.google.com/spreadsheets/d/13Q_T7NWFQmL2rpz4_TPoZ1DczHD1bMNhI63xjnxf2oM/edit#gid=31467532",
		},
		{
			title: "Step 6: 2024 Planning and Toolkits",
			description:
				"Access 2024 planning and toolkits in R-drive > a-EANPD. This file is shared with Luke and the Trade Marketing team and contains all factsheets for 2024 and toolkits.",
		},
		{
			title: "Step 7: Consult for Factsheet Listing",
			description:
				"Contact Luke or Irene for assistance with factsheets and listings. Ashley should initially share these as received from Global.",
		},
		{
			title: "Step 8: Use Revlon & Elizabeth Arden Digital Master",
			description:
				"Master Data contains all existing SKUs of all brands. It's the first reference point for consolidating listing information for KAMs.",
		},
		{
			title: "Step 9: Source Listing Information",
			description:
				"Get the factsheet from Ashley, extract listing information from Salsify Products, and consult with the team for selecting assets.",
		},
		{
			title: "Step 10: Approval and Submission",
			description:
				"Send CR to Global for approval and then add assets to the Google Drive.",
		},
		{
			title: "Step 11: Use Brief SharePoints",
			description:
				"Retrieve information from marketing decks available on SharePoint using templates from the agency.",
		},
		{
			title: "Step 12: Content Plans with iProspect",
			description:
				"iProspect will share updated content plan templates. Use these to share assets requested from Global as per their chase list.",
		},
		{
			title: "Step 13: Accessing b-Busisiwe Folder",
			description:
				"b-Busisiwe folder contains examples of old chase lists and content plans, which will help in understanding the layout and requirements.",
		},
		{
			title: "Step 14: Retailer Rate Cards",
			description:
				"All retailer rate cards are saved under the B-Busisiwe file. Use these for online bookings with retailers.",
		},
	];

	const masterDataSteps = [
		{
			title: "Master Data has all existing SKUs of all brands.",
			description:
				". This is your first point of reference when you want to consolidate listing information for the KAMs. ",
			link: "https://docs.google.com/spreadsheets/d/13Q_T7NWFQmL2rpz4_TPoZ1DczHD1bMNhI63xjnxf2oM/edit#gid=31467532",
		},
		{
			title: "listing information's comes from: ",
			description: `from Ashley< she gets it from Global)
`,
		},
		{
			title: "salsify.com",
			description:
				"2.	Take the barcode or article code from the factsheet then extract the listing information from Salsify Products (salsify.com) You will need to get log in from Ashley.",
		},
		{
			title: "2.	Take the barcode or article code from the factsheet then extract the listing information from Salsify Products (salsify.com) You will need to get log in from Ashley. ",
		},
	];

	return (
		<View style={styles.container}>
			<View style={styles.list}>
				<Text
					style={{
						fontSize: 20,
						fontWeight: "600",
					}}>
					Listings process
				</Text>
				{processSteps.map((step, index) => (
					<Step
						key={`step-${index}`}
						title={step.title}
						description={step.description}
						link={step.link}
					/>
				))}
			</View>

			<View style={styles.list}>
				<Text
					style={{
						fontSize: 20,
						fontWeight: "600",
					}}>
					Master Data
				</Text>

				{masterDataSteps.map((step, index) => (
					<Step
						key={`step-${index}`}
						title={step.title}
						description={step.description}
						link={step.link}
					/>
				))}
			</View>
		</View>
	);
};

export default ProcessFlow;
