import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ActivityIndicator,
	ScrollView,
	Modal,
} from "react-native";
// import Lottie from "react-lottie";
// import Fade from "react-reveal/Fade";
import moment from "moment";
import Font from "react-font";
// import logo from "../assets/images/tetrice_logo.png";
// import cris from "../assets/images/cris.png";
import tick from "../assets/images/tick.png";
// import bg from "../assets/images/bg.png";
import send from "../assets/images/send.png";
import { api_newQuery } from "./../Api";
import { APP_COLOURS } from "../APP_VARS";
import * as animationData from "../assets/lottie/lf30_editor_gqidrmge.json";

import "./input.css";

function uuid() {
	return "xxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16).toUpperCase();
	});
}

function randomString(len, charSet) {
	charSet = charSet || "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
	var randomString = "";
	for (var i = 0; i < len; i++) {
		var randomPoz = Math.floor(Math.random() * charSet.length);
		randomString += charSet.substring(randomPoz, randomPoz + 1);
	}
	return randomString;
}

class NewQueryModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			formElements: this.props.formElements,
			formData: {},
			formComplete: false,
			submitting: false,
		};

		this.formComplete = React.createRef();
		this.completeButton = React.createRef();
		this.scrollView = React.createRef();

		this._timeout = 500;
	}

	submitTicket = async () => {
		await this.setState({
			submitting: true,
		});

		let formElements = this.state.formElements;
		let object_ = {};
		for (const el of formElements) {
			object_[el.state] = this.state[el.state];
		}

		if (this.props.full_name !== "" && this.props.userid !== "") {
			this.sendQuery(object_);
		}
	};

	sendQuery = async (data) => {
		let ticketNumber = await randomString(6);
		let date_ = moment().toLocaleString();
		console.log("new ticket", ticketNumber);

		let obj = {
			query: data,
			queryCategory: this.props.quertyType.title,
			userid: this.props.userid,
			full_name: this.props.full_name,
			ticketNumber: ticketNumber,
			date_created: date_,
			newMessageForSupport: true,
			newMessageForClient: false,
		};

		console.log(obj);
		// return;

		await fetch(api_newQuery, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
			},
			body: JSON.stringify(obj),
			redirect: "follow",
		})
			.then((response) => {
				console.log("response.status", response.status);
				console.log("response", response);
				if (response.status == 200) {
					this.setState({
						submitSuccessful: true,
						ticketNumber: ticketNumber,
					});
					this.props.redirectBack();
					alert(
						`Thank you!, your ticket has been submitted successfully ${ticketNumber}`,
					);
				} else {
					this.setState({
						submitSuccessful: false,
					});
				}
			})

			.catch((error) => {
				console.log(error);
			});
	};

	validateEmail = async (text) => {
		// await console.log(text);
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (reg.test(text) === false) {
			// await console.log("Email is Not Correct");
			await this.setState({ emailValid: false });
			return false;
		} else {
			await this.setState({ emailValid: true });
			// await console.log("Email is Correct");
			return true;
		}
	};

	splitName = async (fullName) => {
		var name = fullName; // try "Paul", "Paul Steve"
		var firstName = name.split(" ")[0];
		var lastName = name.substring(firstName.length).trim();

		if (lastName === "") {
			firstName = fullName;
		}

		console.log("firstName", firstName);
		console.log("lastName", lastName);

		await this.setState({
			fullName: fullName,
			firstName: firstName,
			lastName: lastName,
		});
	};

	checkAllRequired = () => {
		const form = this.state.formElements.filter((d_) => d_.required);

		let formComplete = form.length;
		for (const el of form) {
			if (
				typeof this.state[el.state] != "undefined" &&
				this.state[el.state] !== ""
			) {
				formComplete = formComplete - 1;
			}
		}

		console.log(formComplete);

		this.setState({
			formComplete: formComplete,
		});
	};

	render() {
		return (
			<View
				style={{
					width: "100%",
					// maxWidth: 500,
					height: "auto",
					// maxHeight: 650,
					borderRadius: 5,
					borderColor: "#FFF",
				}}>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						width: "100%",
						padding: 5,
						alignItems: "center",
						backgroundColor: APP_COLOURS.SECONDARY,
						borderRadius: 15,
						marginBottom: 15,
					}}>
					<TouchableOpacity onPress={() => this.props.redirectBack()}>
						<View
							style={{
								width: 60,
								height: "auto",
								padding: 8,
								margin: 5,
								borderRadius: 10,
								backgroundColor: APP_COLOURS.BLUE,
								justifyContent: "center",
								alignItems: "center",
							}}>
							<Text
								style={{
									color: APP_COLOURS.SECONDARY,
									fontSize: 11,
								}}>
								<Font weight={300} family='Poppins'>
									Cancel
								</Font>
							</Text>
						</View>
					</TouchableOpacity>

					<View
						style={{
							flexDirection: "row",
							flex: 1,
							justifyContent: "space-between",
							alignItems: "center",
							padding: 5,
						}}>
						<Text
							style={{
								color: APP_COLOURS.PRIMARY,
								fontSize: 15,
							}}>
							<Font weight={600} family='Montserrat'>
								{/* {this.props.quertyType.title} */}
								New ticket
							</Font>
						</Text>
					</View>
				</View>

				<View
					style={{
						flex: 1,
					}}>
					<ScrollView
						ref={(ref) => {
							this.scrollView = ref;
						}}
						onLayout={() =>
							this.scrollView.scrollTo({
								x: 0,
								y: 0,
							})
						}
						style={{
							padding: 15,
							flex: 1,
							backgroundColor: APP_COLOURS.PRIMARY,
							borderRadius: 15,
						}}>
						<View
							style={{
								flex: 1,
							}}>
							<View
								style={{
									flex: 1,
									width: "100%",
									display: this.state.submitting ? "none" : "flex",
								}}>
								<Text
									style={{
										color: "#FFF",

										marginBottom: 20,
										fontSize: 12,
									}}>
									Please fill out all the required fields below
								</Text>

								{this.state.formElements.map((d, i) => (
									<View
										key={i}
										style={{
											width: "100%",
											marginTop: 10,
											flex: 1,
										}}>
										{/* <Text
												style={{
													color: '#797979',
													marginBottom: 5,
													paddingLeft: 10,
												}}>
												{d.title}
											</Text> */}
										{d.state !== "note" ? (
											<TextInput
												style={{
													flex: 1,
													paddingLeft: 10,
													marginBottom: 5,
													marginTop: 0,
													fontSize: 16,
													color: APP_COLOURS.PRIMARY,
													minHeight: 50,
													borderWidth: 0.5,
													borderRadius: 7,
													borderColor: "#797979",
													width: "100%",
													backgroundColor: APP_COLOURS.SECONDARY,
													padding: 5,
												}}
												placeholderTextColor={"#797979"}
												multiline={d.state == "description"}
												numberOfLines={
													d.state == "description" ? 10 : 1
												}
												clearButtonMode='while-editing'
												autoCorrect={true}
												autoCapitalize='sentences'
												required={d.required}
												placeholder={d.title}
												onChangeText={async (text) => {
													await clearTimeout(this._timeout);
													this._timeout = await setTimeout(
														async () => {
															await this.setState({
																[d.state]: text,
															});
															await this.checkAllRequired();
														},
														500,
													);
												}}
											/>
										) : (
											<Text
												style={{
													color: "#FFF",
													marginBottom: 5,
													paddingLeft: 10,
												}}>
												{d.title}
											</Text>
										)}
									</View>
								))}

								<View>
									{this.state.formElements
										.filter((d_) => d_.required)
										.map((d, i) => (
											<View
												key={i}
												style={{
													width: "100%",
													marginTop: 10,
													justifyContent: "center",
												}}>
												<Text
													style={{
														fontSize: 8,
														color: "#FFF",
													}}>
													{typeof this.state[d.state] !=
														"undefined" &&
													this.state[d.state] !== "" ? (
														<Image
															source={tick}
															style={{
																width: 8,
																height: 8,
																resizeMode: "contain",
															}}
														/>
													) : (
														" - "
													)}{" "}
													{d.title}
												</Text>
											</View>
										))}
								</View>
							</View>

							{this.state.formComplete === 0 ? (
								<TouchableOpacity
									disabled={this.state.submitting}
									onPress={async () => {
										this.submitTicket();
									}}>
									{this.state.submitting ? (
										<ActivityIndicator color={"#FFF"} />
									) : (
										<View
											style={{
												width: "100%",
												height: "auto",
												justifyContent: "center",
												alignItems: "center",
												backgroundColor: APP_COLOURS.BLUE,
												borderRadius: 10,
												flexDirection: "row",
												padding: 10,
												marginTop: 20,
											}}>
											{/* <Image
											source={send}
											style={{
												width: 30,
												height: 30,
												resizeMode: "contain",
											}}
										/> */}
											<Text
												style={{
													color: "#FFF",
													fontSize: 15,
													marginLeft: 15,
												}}>
												<Font weight={600} family='Poppins'>
													Send
												</Font>
											</Text>
										</View>
									)}
								</TouchableOpacity>
							) : (
								<View
									style={{
										width: "100%",
										height: 50,
									}}
								/>
							)}
						</View>
					</ScrollView>
				</View>
			</View>
		);
	}
}

export default NewQueryModal;
