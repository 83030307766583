import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
// import { InView } from "react-intersection-observer";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
// import logo_tetrice from "./assets/logo_tetrice.png";
// import logo_tetrice from "./assets/tetrice_logo_dark.png";
// import logo_tetrice from "./assets/traqbi.svg";
// import bg2 from "./assets/bg2.svg";
import { APP_COLOURS } from "../../APP_VARS";

import "./resizeScale.scss";
import "./hoverEffect.scss";
// import PageFooter from "./PageFooter";
// import ScaleView from "./ScaleView";

export default class IntroPage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		// const defaultOptions = {
		// 	loop: true,
		// 	autoplay: true,
		// 	animationData: animationData,
		// 	rendererSettings: {
		// 		preserveAspectRatio: "xMidYMid slice",
		// 	},
		// };

		let h = window.innerHeight;
		let w = window.innerWidth;

		let contain = w * 0.9 > h * 0.9 ? h * 0.89 : w * 0.9;

		let maxContain = contain > 700 ? 700 : contain;

		let ar = 265 / 345;

		let bias = h > w ? w : h;

		let mw = 800;

		let { h1, h2, pp } = this.props;

		return (
			<View
				style={{
					// flex: 1,
					// width: window.innerWidth,
					width: "100%",
					// height: this.props.screenHeight,
					height: "auto",
					// backgroundColor: "#171A20",
					// maxWidth: 700,
					// backgroundColor: "#1F2229",
					// backgroundColor: "#FFF",
					// backgroundColor: "#EDF1F4",
					// overflow: "hidden",
					// justifyContent: "center",
					// alignItems: "center",
					// paddingTop: "30%",
					// paddingBottom: "10%",

					// padding: "5%",

					justifyContent: "center",
					alignItems: "center",

					paddingTop: h1,
				}}>
				<View
					style={{
						flexDirection: "row",
						width: "100%",
						// maxWidth: mw,
					}}>
					<View
						style={{
							width: "100%",
							// maxWidth: mw,
							flex: 1,
							// justifyContent: "center",
							// alignItems: "center",
						}}>
						<View
							style={{
								flex: 1,
								// padding: 40,
								flexDirection: "row",
								// justifyContent: "flex-end",
								// alignItems: "flex-end",
								// maxHeight: h * 0.5,
							}}>
							<View
								style={{
									flex: 1,
									// maxWidth: w * 0.5,
									// paddingLeft: 30,
								}}>
								<Fade>
									<Text
										style={{
											fontSize: h1,
											color: APP_COLOURS.TEXTCOLOR,
										}}>
										<Font family='Poppins' weight={800}>
											Elevate & Engage:
										</Font>
									</Text>

									<Text
										style={{
											fontSize: h2,
											color: APP_COLOURS.TEXTCOLOR,
										}}>
										<Font family='Poppins' weight={600}>
											PR & Community Management
										</Font>
									</Text>
								</Fade>
							</View>
						</View>
					</View>
				</View>
				<View
					style={{
						width: "100%",
						// maxWidth: mw,
						// maxWidth: 700,
					}}>
					<Text
						style={{
							fontSize: pp,
							color: APP_COLOURS.TEXTCOLOR,
							marginBottom: h1,
							marginTop: h1,
						}}>
						<Font family='Montserrat' weight={500}>
							Executive Summary
						</Font>
					</Text>
					<Text
						style={{
							fontSize: pp,
							color: APP_COLOURS.TEXTCOLOR,
							marginBottom: h1,
							flexDirection: "row",
							textAlign: "justify",
						}}>
						<Font family='Montserrat' weight={300}>
							Our plan is to design, implement and increase Revlon
							& Elizabeth Arden's brand awareness, build
							credibility and trust among your target audience as
							well as drive engagement across social media
							platforms. We will achieve these goals through a
							comprehensive strategy that includes media outreach,
							social media management, content creation, events,
							influencer marketing, and crisis management.
						</Font>
					</Text>

					<Text
						style={{
							fontSize: pp,
							color: APP_COLOURS.TEXTCOLOR,
							marginVertical: h1,
							flexDirection: "row",
						}}>
						<Font family='Montserrat' weight={300}>
							Extending beyond the realms of traditional PR and
							social media management, our strategy also
							encompasses comprehensive digital community and
							e-commerce management. We will ensure that every
							online touchpoint, be it your website, social media
							platforms, or e-commerce listings, are all working
							in unison to deliver a seamless, engaging, and
							compelling brand experience.
						</Font>
					</Text>

					<Text
						style={{
							fontSize: pp,
							color: APP_COLOURS.TEXTCOLOR,
							marginVertical: h1,
							flexDirection: "row",
						}}>
						<Font family='Montserrat' weight={300}>
							In terms of web platform management, we aim to
							enhance the user experience on Revlon & Elizabeth
							Arden's websites. This involves improving
							navigation, optimizing content for search engines,
							integrating social media, and implementing analytics
							tracking to gain key insights about user behavior.
						</Font>
					</Text>

					<Text
						style={{
							fontSize: pp,
							color: APP_COLOURS.TEXTCOLOR,
							marginVertical: 10,
							flexDirection: "row",
						}}>
						<Font family='Montserrat' weight={300}>
							When it comes to e-commerce management, we will take
							a holistic approach to manage your product listings.
							From optimizing product descriptions and images to
							implementing effective SEO strategies, we'll make
							sure that your products are easy to find and
							appealing to potential customers.
						</Font>
					</Text>

					<Text
						style={{
							fontSize: pp,
							color: APP_COLOURS.TEXTCOLOR,
							marginVertical: h1,
							flexDirection: "row",
						}}>
						<Font family='Montserrat' weight={300}>
							Moreover, we'll also handle all aspects of digital
							community management, from engaging with customers
							on social media and responding to their comments and
							queries, to managing online reviews and feedback. We
							understand the power of a strong, active online
							community and will work tirelessly to nurture these
							relationships.
						</Font>
					</Text>

					<Text
						style={{
							fontSize: pp,
							color: APP_COLOURS.TEXTCOLOR,
							marginVertical: h1,
							flexDirection: "row",
						}}>
						<Font family='Montserrat' weight={300}>
							Additionally, we'll leverage data-driven strategies
							to constantly assess, tweak and improve our
							approach. With real-time analytics, we'll be able to
							track the performance of our campaigns, identify
							trends, and make informed decisions to ensure the
							success of our strategy.
						</Font>
					</Text>

					<Text
						style={{
							fontSize: pp,
							color: APP_COLOURS.TEXTCOLOR,
							marginVertical: h1,
							flexDirection: "row",
						}}>
						<Font family='Montserrat' weight={300}>
							By offering a full suite of services that go beyond
							traditional PR, we aim to provide an
							all-encompassing solution to meet Revlon & Elizabeth
							Arden's digital community and e-commerce management
							needs. Through this integrated approach, we are
							confident in our ability to elevate brand awareness,
							foster trust, and drive engagement across all
							digital platforms.
						</Font>
					</Text>
				</View>
			</View>
		);
	}
}
