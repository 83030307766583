import React, { useState } from "react";
import { View, Button, Text } from "react-native";

const ImageUploaderComp = ({ onImagesUploaded }) => {
	const [isImages, setisImages] = useState(false);
	const handleFileInput = async (e) => {
		const files = e.target.files;
		const images = await Promise.all(
			Array.from(files).map(async (file) => {
				const base64 = await toBase64(file);
				return {
					name: file.name,
					file_name: file.name,
					size: file.size,
					type: file.type,
					doc: file.type,
					base64: base64,
					uri: URL.createObjectURL(file),
				};
			}),
		);
		onImagesUploaded(images);
		setisImages(true);
	};

	const toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	return (
		<View>
			<Button
				title='Select Images'
				onPress={() => document.getElementById("fileInput").click()}
			/>
			<View
				style={{
					display: isImages ? "none" : "flex",
					justifyContent: "center",
					alignItems: "center",
				}}>
				<Text style={{ margin: 2 }}>
					Click the button to upload images
				</Text>
				<Text style={{ margin: 3, fontStyle: "italic" }}>
					Hold shift or option key to select multiple
				</Text>
			</View>
			<input
				type='file'
				id='fileInput'
				multiple
				style={{ display: "none" }}
				onChange={handleFileInput}
			/>
		</View>
	);
};

export default ImageUploaderComp;
