import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TextInput,
	ScrollView,
	Keyboard,
	Modal,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import Font from "react-font";
// import DatePicker from 'react-native-date-picker';
import { APP_COLOURS, RETAILERS, screenHeight } from "../../APP_VARS";

import tick from "../../../assets/images/tick.png";
import starFill from "../../../assets/images/Table/starFill.png";
import star from "../../../assets/images/Table/star.png";
import FullCalendar from "./FullCalendar";
import BrandTabs from "../BrandTabs";
import SmallerTabs from "../TopTabs/SmallerTabs";
import StatusItemListings from "../DailyPlanner/StatusItemListings";
import ListingImageBox from "./ListingImageBox";
import { crudDeleteImage } from "../../CRUDHELPER";

function currencyFormat(num) {
	return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function uuid() {
	return "xxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

function capitalizeName(name) {
	return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
}

const BG = "#171B22";
const BG2 = "#20242B";
const FONT = "#F3F3F5";
const BORDER = "#383D43";

const BLUE = "#3F6EFF";
const WHITE = FONT;
const FONT_ALMOSTWHITE = "#D4D4D4";

const RED = "#FF5F5F";
const GREEN = "#3F6EFF";
const OPACITYGREY = "#2F2F39";

const FONT_DARK = "#141D31";
const BORDERBOTTOM = "#28282F";

class AutoHeightImage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<Image
				style={{
					width: this.props.width,
					height:
						typeof this.props.height !== "undefined"
							? this.props.height
							: this.props.width,
					resizeMode: "contain",
				}}
				source={this.props.source}
			/>
		);
	}
}

export default class UpdateListing extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: uuid(),
			title: "",

			due_date: moment(),
			start_date: moment(),

			status: "Pending",
			description: "",

			assignment_ids: [this.props.user_data.id],
			assignment: [],
			googleDriveDocumentUrl: "",
			...this.props.selectedProduct,

			selectedTab: "Product",

			retailerList: RETAILERS,
		};
		this._timeout = 1000;
	}

	componentDidMount() {
		this.setKeyboardListener();
	}

	generateCalendar = () => {
		let startOfWeek = moment(this.state.due_date).startOf("week");

		console.log("startOfWeek", startOfWeek);

		let datesArray = [];

		let dLoop = Array.from(Array(7).keys());
		let doff = 0;
		for (const d of dLoop) {
			let date = moment(startOfWeek).add(doff, "days");
			doff = d + 1;
			datesArray.push({ date: date, event: true, meeting: true });
		}

		console.log(datesArray);
		// this.setState({
		//   eventCalendar: datesArray,
		// });
	};

	render() {
		let d = this.props.selectedProduct;
		let form_fields = [
			{
				id: 1,
				editable: true,
				state: "barcode",
				title: "Barcode / A Code",
				autoCapitalize: true,
				validation: "name",
			},
			{
				id: 1,
				editable: true,
				state: "SKU_NUMBER",
				title: "SKU NUMBER",
				autoCapitalize: true,
				validation: "name",
			},

			{
				id: 2,
				editable: true,
				state: "a_code",
				title: "A Code",
				autoCapitalize: true,
				validation: "name",
			},
			{
				id: 3,
				editable: true,
				state: "article_code",
				title: "Article Code (Dis-Chem)",
				autoCapitalize: true,
				validation: "name",
			},
			{
				id: 4,
				editable: true,
				state: "title",
				title: "Product title",
				autoCapitalize: true,
				validation: "name",
			},
			{
				id: 5,
				editable: true,
				state: "short_description",
				title: "Short description",
				autoCapitalize: true,
				validation: "name",
			},
			{
				id: 6,
				editable: true,
				state: "long_description",
				title: "Long description",
				autoCapitalize: true,
				validation: "name",
			},
			{
				id: 7,
				editable: true,
				state: "description_packaging",
				title: "Description (As per packaging)",
				autoCapitalize: true,
				validation: "name",
			},
			{
				id: 8,
				editable: true,
				state: "pack_size_ml_g",
				title: "Quantity in pack e.g ml /grams etc",
				autoCapitalize: true,
				validation: "name",
			},
			{
				id: 8,
				editable: true,
				state: "how_to",
				title: "How to Use ",
				autoCapitalize: true,
				validation: "name",
			},
			{
				id: 8,
				editable: true,
				state: "ingredients",
				title: "Ingredients ",
				autoCapitalize: true,
				validation: "name",
			},
			{
				id: 8,
				editable: true,
				state: "warnings",
				title: "Warnings ",
				autoCapitalize: true,
				validation: "name",
			},
			{
				id: 8,
				editable: true,
				state: "large",
				title: "Large ",
				autoCapitalize: true,
				validation: "name",
			},
			{
				id: 8,
				editable: true,
				state: "Width",
				title: "Width ",
				autoCapitalize: true,
				validation: "name",
			},
			{
				id: 8,
				editable: true,
				state: "Height",
				title: "Height ",
				autoCapitalize: true,
				validation: "name",
			},

			{
				id: 4,
				editable: true,
				state: "googleDriveDocumentUrl",
				title: "Google drive file URL",
				autoCapitalize: true,
				validation: "name",
			},
		];
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<ScrollView
						showsVerticalScrollIndicator={false}
						ref={(ref) => (this.scrollViewRef = ref)}>
						<View
							style={{
								width: "100%",
								height: "auto",
								backgroundColor: "#FFF",
								borderRadius: 10,
								padding: 10,
								marginTop: 50,
								minWidth: 500,
							}}>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
									// borderBottomWidth: 0.5,
									paddingBottom: 10,
									// borderBottomcolor: APP_COLOURS.BG2,
									marginBottom: 20,
								}}>
								<TouchableOpacity
									onPress={async () => {
										this.props.close();
									}}>
									<Image
										style={{
											width: 24,
											height: 24,
											resizeMode: "contain",
											marginRight: 10,
										}}
										source={require("../../../assets/images/close.png")}
									/>
								</TouchableOpacity>
								<View style={{ flex: 1 }}>
									<Text
										style={{
											color: APP_COLOURS.BG2,
											fontSize: 20,
											marginLeft: 10,
											fontWeight: "600",
											fontFamily: "Gotham",
										}}>
										{this.props.selectedProduct !== ""
											? "Update"
											: "Add"}{" "}
										Listing
									</Text>
								</View>

								{this.props.selectedProduct !== "" ? (
									<TouchableOpacity
										onPress={async () => {
											this.props.deleteListing();
										}}>
										<Image
											style={{
												width: 24,
												height: 24,
												resizeMode: "contain",
											}}
											source={require("../../../assets/images/bin.png")}
										/>
									</TouchableOpacity>
								) : null}
							</View>

							<SmallerTabs
								selectedSubPage={(d) => {
									this.setState({
										selectedTab: d,
									});
									console.log(d);
								}}
								tabs={["Product", "Images", "Dates"]}
							/>

							{this.state.selectedTab === "Product"
								? this.productItem(form_fields, d)
								: null}

							{this.state.selectedTab === "Dates"
								? this.dates(d)
								: null}

							{this.state.selectedTab === "Images"
								? this.imageRescaller(d, (images) => {
										let obj = { ...this.state };
										obj.images = images;

										delete obj.keyboardOffset;

										this.props.addEntry(obj);
										console.log(obj);
								  })
								: null}

							<TouchableOpacity
								style={{
									width: this.state.addingBrand
										? "auto"
										: "100%",
									padding: 10,
									display:
										this.state.passcode !== ""
											? "flex"
											: "none",
								}}
								onPress={async () => {
									let obj = { ...this.state };
									delete obj.keyboardOffset;

									this.props.addEntry(obj);
									console.log(obj);
								}}>
								<View
									style={{
										backgroundColor: APP_COLOURS.BG2,
										width: this.state.addingBrand
											? "auto"
											: "100%",
										padding: 13,
										height: 45,
										borderRadius: 25,
										justifyContent: "center",
										alignItems: "center",
									}}>
									{!this.state.addingBrand ? (
										<Text
											style={{
												alignItems: "center",
												justifyContent: "center",
												color: "#FFF",
												fontSize: 15,
											}}>
											<Font
												weight={700}
												family='Montserrat'>
												{this.props.selectedProduct !==
												""
													? "Update"
													: "Add"}{" "}
												listing
											</Font>
										</Text>
									) : (
										<ActivityIndicator color={"#FFF"} />
									)}
								</View>
							</TouchableOpacity>
						</View>
					</ScrollView>
				</View>
			</Modal>
		);
	}

	productItem = (form_fields, d) => {
		return (
			<View style={{ flex: 1 }}>
				{form_fields.map((d, i) => {
					let validated = `${d.state}_valid`;
					return (
						<View
							key={i}
							style={{
								width: "auto",

								flexDirection: "row",
								alignItems: "center",
								margin: 10,
								marginBottom: 16,
							}}>
							<Text
								style={{
									opacity: this.state.unEditable ? 0.4 : 1,
									fontFamily: "Gotham",
									fontSize: 12,
									color: APP_COLOURS.PLACEHOLDER,
									fontWeight: "500",
									marginBottom: 4,
									position: "absolute",
									top: -12,
									left: 9,
									backgroundColor: "#FFF",
									padding: 4,
									borderRadius: 8,
								}}>
								{d.title}
							</Text>
							<TextInput
								style={{
									width: "100%",
									opacity: this.state.unEditable ? 0.4 : 1,
									borderWidth: 1,
									borderColor: this.state[validated]
										? APP_COLOURS.GREEN
										: typeof this.state[d.state] !==
										  "undefined"
										? "#000"
										: APP_COLOURS.RED,
									padding: 12,
									paddingLeft: 30,
									fontSize: 16,
									borderRadius: 8,
									backgroundColor: APP_COLOURS.WHITE,
									color: "#000",
									fontWeight: "500",
									fontFamily: "Gotham",
								}}
								placeholderTextColor={"#000"}
								// autoCapitalize="sentences"
								clearButtonMode='while-editing'
								autoCorrect={false}
								// placeholder={d.title}
								defaultValue={this.state[d.state]}
								onChangeText={async (text) => {
									// if (d.autoCapitalize) {
									// 	text = await capitalizeName(
									// 		text,
									// 	);
									// }

									await this.setState({
										[d.state]: text,
									});
								}}
							/>

							{this.state.unEditable && !d.editable ? (
								<View
									style={{
										position: "absolute",
										width: "100%",
										height: "100%",
										backgroundColor: `rgba(52, 52, 52, 0.3)`,
										flex: 1,
										justifyContent: "center",
										alignItems: "center",
										borderRadius: 8,
									}}>
									<Text
										style={{
											fontFamily: "Gotham",
											fontSize: 12,
											color: APP_COLOURS.WHITE,
											fontWeight: "700",
										}}>
										Uneditable field
									</Text>
								</View>
							) : null}

							{this.state[validated] ? (
								<Image
									source={tick}
									style={{
										width: 16,
										height: 16,
										resizeMode: "contain",
										position: "absolute",
										left: 9,
										top: 16,
									}}
								/>
							) : null}
						</View>
					);
				})}

				<Text
					style={{
						fontFamily: "Gotham",
						fontSize: 12,
						color: APP_COLOURS.BG3,
						fontWeight: "700",
					}}>
					Select retailers
				</Text>

				<View
					style={{
						flex: 1,
						marginBottom: 60,
					}}>
					{d.retailerList?.map((r, i) => {
						console.log(r.status);

						return (
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",

									borderTopColor: "#E8E8E8",
									borderTopWidth: 0.5,
								}}>
								<StatusItemListings
									{...this.props}
									status={r.status}
									fs={15}
									handleStatusChange={async (
										_id,
										_status,
									) => {
										// let user_data = JSON.parse(
										// 	localStorage.getItem("user_data"),
										// );
										d.retailerList[i].status = _status;

										console.log(
											d.retailerList[i].status,
											i,
										);

										this.props.addEntry(d);
										// console.log(obj);
									}}
								/>
								{/* <View
									style={{
										width: 0.5,
										backgroundColor: APP_COLOURS.BG2,
										height: 30,
									}}
								/> */}
								<TouchableOpacity
									style={{
										flex: 1,
										// width: "100%",
										justifyContent: "center",
										// maxWidth: 1200,
										// minWidth: 250,
										marginHorizontal: 5,
									}}
									onPress={() => {
										// this.setState({
										// 	updateListing: !this.state.updateListing,
										// 	selectedR: d,
										// });
									}}>
									<View
										style={{
											width: "100%",
											padding: 10,
											// justifyContent: "center",
											minWidth: 200,
											minHeight: 20,
										}}>
										<Text
											ellipsizeMode='tail'
											style={{
												width: "100%",
												color: "#000",
												fontSize: 15,
												flexWrap: "wrap",
												// minHeight: 50,
												// paddingTop: 20,
											}}>
											{r.title} {r.status}
										</Text>
									</View>
								</TouchableOpacity>

								<TouchableOpacity
									style={{
										opacity:
											d.googleDriveDocumentUrl === ""
												? 0.7
												: 1,
									}}
									onPress={() => {
										if (d.googleDriveDocumentUrl === "") {
											alert("No file has been listed");
											return;
										}
										window.open(
											d.googleDriveDocumentUrl,
											"_blank",
										);
									}}>
									<View
										style={{
											borderRadius: 15,
											overflow: "hidden",
											alignItems: "center",
											marginRight: 2,
											borderWidth: 3,
											borderColor:
												d.googleDriveDocumentUrl === ""
													? APP_COLOURS.ORANGE
													: APP_COLOURS.GREEN,
										}}>
										<AutoHeightImage
											width={35}
											source={{
												uri: this.state.driveImg,
											}}
											style={{
												backgroundColor: "#FFF",
											}}
										/>
									</View>
								</TouchableOpacity>
							</View>
						);
					})}
				</View>
			</View>
		);
	};

	dates = () => {
		return (
			<View
				style={{
					width: 350,
					// display: this.state.showCalendar
					// 	? "flex"
					// 	: "none",
					justifyContent: "center",
					alignItems: "center",
				}}>
				<View
					style={{
						width: 330,
						flexDirection: "row",
					}}>
					<View
						style={{
							marginTop: 20,
							flex: 1,
						}}>
						<Text
							style={{
								fontWeight: "400",
								fontFamily: "Gotham",

								fontSize: 12,
								color: APP_COLOURS.BG2,
								marginBottom: 4,
								position: "absolute",
								top: -12,
								left: 9,
								backgroundColor: "#FFF",
								padding: 4,
								borderRadius: 8,
								zIndex: 99,
							}}>
							Due date
						</Text>
						<View
							style={{
								borderRadius: 7,
								borderColor: BORDER,
								borderWidth: 1,
							}}>
							<TouchableOpacity
								onPress={() => {
									this.setState({
										dateAllocation: "due_date",
									});
								}}>
								<View
									style={{
										width: "100%",
										padding: 13,
										// justifyContent: "center",
										// alignItems: "center",
										paddingLeft: 10,
									}}>
									<Text
										style={{
											color: APP_COLOURS.BG2,
											fontWeight: "600",
											fontSize: 16,
										}}>
										{moment(this.state.due_date).format(
											"DD/MM/YYYY",
										)}
									</Text>
								</View>
							</TouchableOpacity>
						</View>
					</View>
				</View>
				<FullCalendar
					compWidth={350}
					calendarList={[]}
					selectedDate={this.state[this.state.dateAllocation]}
					startDate={(date) => {
						this.setState({
							start_date: date,
						});
					}}
					endDate={(date) => {
						this.setState({
							due_date: date,
						});
					}}
				/>
			</View>
		);
	};

	imageRescaller = (d, saveImage) => {
		let images = typeof d.images !== "undefined" ? d.images : [];

		return (
			<ScrollView
				contentContainerStyle={{
					alignItems: "center",
					justifyContent: "center",
				}}>
				{images.map((img, index) => (
					<View
						key={index}
						style={{
							margin: 10,
							alignItems: "center",
							justifyContent: "center",
						}}>
						<Image
							source={{ uri: img.imageData.url }}
							style={{
								width: 200,
								height: 200,
								resizeMode: "contain",
							}}
						/>
						<Text>
							{moment(img.imageData.createdAt).toString()}
						</Text>
						<button
							onClick={() => {
								crudDeleteImage("dm_listingImages", img, () => {
									// alert('Deleted image');
									const remove = images.filter(
										(im) => im.id !== img.id,
									);
									// console.log(remove, img.id);
									saveImage(remove);
								});
							}}
							style={{ position: "absolute", top: 0, right: 0 }}>
							Delete
						</button>
					</View>
				))}
				<View
					style={{
						padding: 10,
						borderTopWidth: 1,
						width: "100%",
						backgroundColor: APP_COLOURS.BACK_TRANSPARENT2,
					}}>
					<ListingImageBox
						uploadImage={(img, data) => {
							images.push(img);
							saveImage(images);
						}}
					/>
				</View>
			</ScrollView>
		);
	};

	setKeyboardListener = async () => {
		this.keyboardDidShowListener = Keyboard.addListener(
			"keyboardDidShow",
			this._keyboardDidShow,
		);
		this.keyboardDidHideListener = Keyboard.addListener(
			"keyboardDidHide",
			this._keyboardDidHide,
		);
	};

	_keyboardDidShow = (event) => {
		console.log(event.endCoordinates.height);
		if (this.state.keyboardOffset === 0) {
			this.setState({
				keyboardOffset: event.endCoordinates.height,
				// keyboardActive: true,
			});
		}
	};

	_keyboardDidHide = () => {
		// this.setState({
		//   keyboardActive: false,
		// });
		// LayoutAnimation.spring();
	};

	componentWillUnmount() {
		this.setState = (state, callback) => {
			return;
		};
		this.keyboardDidShowListener.remove();
		this.keyboardDidShowListener.remove();
	}
}
