import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import { Avatars, StatusColour, TaskStatus } from "../../APP_VARS";
import StatusItem from "../DailyPlanner/StatusItem";
import Lottie from "react-lottie";
import {
	createPostApproval,
	crudCreate,
	crudDelete,
	crudGetUsers,
	crudRead,
	crudReadAll,
	crudUpdate,
	sendDiscordMessage,
} from "../../CRUDHELPER";
import Fade from "react-reveal/Fade";
import * as animationData from "../../../assets/lottie/team.json";

import AutoHeightImage from "../AutoHeightImageComp";
import InstagramPost from "./Instagram";
import ApprovalSent from "./ApprovalSent";
import SendingFeedback from "./SendingFeedback";
import MultiContent from "./MultiContent";
import { api_contentApprovalRequest } from "../../Api";
import LoadingContent from "./LoadingContent";

// let posts = [
// 	// {
// 	// 	id: "gwpvideo9s",
// 	// 	title: "GWP 9second ",
// 	// 	method: "Post",
// 	// 	platform: "all",

// 	// 	postDate: "14/02/2024",
// 	// 	verified: true,
// 	// 	avatarUri: require("./ea_logo.png"),
// 	// 	commentCount: 5,
// 	// 	postImageUri: [
// 	// 		{
// 	// 			type: "video",
// 	// 			uri: "https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/VIDEO-2024-02-15-12-17-24.mp4?alt=media&token=7f105f9b-5776-4696-bca9-1662ab098f7f",
// 	// 		},
// 	// 	],
// 	// 	copy: `GWP 9 Second video for approval,
// 	// `,
// 	// 	tags: ``,
// 	// },
// 	{
// 		id: "gwpvideo9s",
// 		version: 3,
// 		createdAt: "",
// 		title: "GWP 9second ",
// 		method: "Post",
// 		platform: "all",

// 		postDate: "14/02/2024",
// 		verified: true,

// 		commentCount: 5,
// 		postImageUri: [
// 			{
// 				type: "video",
// 				uri: "https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/WhatsApp%20Video%202024-02-15%20at%2015.42.21.mp4?alt=media&token=26912e1b-5435-451c-a107-c6f508f66021",
// 			},
// 			{
// 				type: "video",
// 				uri: "https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/WhatsApp%20Video%202024-02-15%20at%2015.42.24.mp4?alt=media&token=1cab7a26-0461-4db3-becb-9080170f2e3e",
// 			},
// 		],
// 		copy: `Both videos for approval
// 	`,
// 		tags: ``,
// 	},
// ];

const PostApproval = () => {
	const [post, setPost] = useState(null);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(true);
	const [sent, setSent] = useState(false);
	const [sending, setSending] = useState(false);

	const { contentid, token } = useParams();

	document.title = `Creative approval - ${contentid}`;

	// useEffect(() => {
	// 	const fetchPost = async () => {

	// 		let postData = posts.filter((d) => d.id === contentid);

	// 		setPost(postData[0]);
	// 	};

	// 	fetchPost();
	// }, [contentid, token]);

	useEffect(() => {
		setLoading(true);
		const fetchPost = async () => {
			try {
				const url = `${api_contentApprovalRequest}/${contentid}/${token}`;
				const response = await fetch(url, {
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json",
					},
				});

				if (!response.ok) {
					throw new Error(`Error: ${response.status}`);
				}
				console.log(response.status);
				const postData = await response.json();

				console.log(postData);
				setLoading(false);
				setPost(postData[0]);
			} catch (error) {
				console.error("Failed to fetch post:", error);
			}
		};

		fetchPost();
	}, [contentid, token]);

	// Approval handler
	const handleApproval = async (data) => {
		console.log("handleApproval", data);
		// return;
		setSending(true);

		data.token = token;

		sendDiscordMessage(
			`CREATIVE ${contentid}`,
			`[CREATIVE REVIEW]: ${data.id} - ${data.token}
> ${data.reviewDate}

> Liked ? ${data.isLiked}
> ${data.approvedCopy ? `Copy APPROVED` : `Copy REJECTED`}
> ${data.approvedTags ? `Tags APPROVED` : `Tags REJECTED`}
> ${data.approvedPost ? `Post APPROVED` : `Post REJECTED`}
> ${data.approvedDate ? `Date APPROVED` : `Date REJECTED`}
${data.comment}
${window.location.href}

`,
			``,
			"0QPZYrY6zpAnJXqX9gQl",
		);

		setSending(false);
		setSent(true);
		// createPostApproval({ ...data }, (status_) => {
		// 	if (status_ === 200) {
		// 		setSending(false);
		// 		setSent(true);
		// 	} else {
		// 		setSending(false);
		// 		setSent(false);
		// 		setError(true);
		// 	}
		// });
	};

	if (loading) return <LoadingContent />;
	if (error) return <div>Error: {error}</div>;

	if (sending) return <SendingFeedback />;
	if (sent) return <ApprovalSent />;

	let screenWidth = window.innerWidth;

	screenWidth = screenWidth > 450 ? 450 : screenWidth;

	return (
		<View
			style={{
				backgroundColor: "#000",
				width: "100%",
				overflow: "hidden",
				height: window.innerHeight,
				alignItems: "center",
			}}>
			<View
				style={{
					flex: 1,
					width: screenWidth,
				}}>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						padding: 5,
					}}>
					<View
						style={{
							padding: 5,
							flexDirection: "row",
						}}>
						<TouchableOpacity
							style={{
								flexDirection: "row",
								alignItems: "center",
								// position: "absolute",
							}}
							onPress={() => {
								// this.props.close();
							}}>
							<AutoHeightImage
								width={27}
								source={require("../../../assets/images/menu.png")}
							/>
							<Text
								adjustsFontSizeToFit
								style={{
									fontWeight: "800",
									fontFamily: "Gotham",
									color: "#FFF",
									fontSize: 15,
									marginLeft: 4,
								}}>
								tetrice
							</Text>
							<Text
								adjustsFontSizeToFit
								style={{
									fontWeight: "300",
									fontFamily: "Gotham",
									color: "#FFF",
									fontSize: 15,
									marginLeft: 4,
									opacity: 0.7,
								}}>
								verification
							</Text>
						</TouchableOpacity>

						<Image
							source={require("./verified.png")}
							style={{
								width: 15,
								height: 15,
								resizeMode: "contain",
							}}
						/>
					</View>
				</View>
				<ScrollView showsVerticalScrollIndicator={false}>
					<MultiContent
						screenWidth={screenWidth}
						id={contentid}
						{...post}
						handleApproval={(data) => handleApproval(data)}
					/>
				</ScrollView>
			</View>
		</View>
	);
};

export default PostApproval;
