// import React, { useEffect } from "react";

// const LocationSender = ({ onLocationFetch }) => {
// 	useEffect(() => {
// 		if (navigator.geolocation) {
// 			const options = {
// 				timeout: 10000, // Timeout after 10 seconds
// 				maximumAge: 60000, // Accept a cached position within 60 seconds
// 			};

// 			navigator.geolocation.getCurrentPosition(
// 				(position) => {
// 					const locationUrl = `https://www.google.com/maps/?q=${position.coords.latitude},${position.coords.longitude}`;
// 					onLocationFetch(locationUrl);
// 				},
// 				(error) => {
// 					switch (error.code) {
// 						case error.PERMISSION_DENIED:
// 							alert("User denied the request for Geolocation.");
// 							break;
// 						case error.POSITION_UNAVAILABLE:
// 							alert("Location information is unavailable.");
// 							break;
// 						case error.TIMEOUT:
// 							alert(
// 								"The request to get user location timed out.",
// 							);
// 							break;
// 						case error.UNKNOWN_ERROR:
// 							alert("An unknown error occurred.");
// 							break;
// 						default:
// 							alert("An error occurred while fetching location.");
// 					}
// 				},
// 				options,
// 			);
// 		} else {
// 			alert("Geolocation is not supported by your browser.");
// 		}
// 	}, [onLocationFetch]);

// 	return <div>Fetching location...</div>;
// };

// export default LocationSender;

//     Latitude (+/- 50 meters): 50111,000≈0.00045111,00050​≈0.00045 degrees.
// Longitude (+/- 50 meters): 50111,000≈0.00045111,00050​≈0.00045 degrees (since the location is not far from the equator, the approximation holds).
// Min Latitude: 26.003547657635632 - 0.00045
// Max Latitude: 26.003547657635632 + 0.00045
// Min Longitude: 28.011465133703815 - 0.00045
// Max Longitude: 28.011465133703815 + 0.00045

import React, { useEffect, useState } from "react";

const LocationAndGeofence = ({ onResult }) => {
	const geofence = {
		minLat: -26.00309765763563,
		maxLat: -26.003997657635633,
		minLong: 28.011015133703815,
		maxLong: 28.011915133703816,
	};

	const isWithinGeofence = (userLat, userLong, geofence) => {
		const { minLat, maxLat, minLong, maxLong } = geofence;

		console.log(`https://www.google.com/maps/?q=${minLat},${minLong}`);
		console.log(`https://www.google.com/maps/?q=${minLat},${maxLong}`);

		console.log(`https://www.google.com/maps/?q=${maxLat},${minLong}`);
		console.log(`https://www.google.com/maps/?q=${maxLat},${maxLong}`);

		console.log(`${userLat},${minLat} => ${userLat <= minLat}`);
		console.log(`${userLat},${maxLat} => ${userLat >= maxLat}`);

		console.log(`${userLong},${minLong} => ${userLong <= minLat}`);
		console.log(`${userLong},${maxLong} => ${userLong >= maxLong}`);
		return (
			userLat <= minLat &&
			userLat >= maxLat &&
			userLong >= minLong &&
			userLong <= maxLong
		);
	};

	useEffect(() => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const userLat = position.coords.latitude;
					const userLong = position.coords.longitude;

					const locationUrl = `https://www.google.com/maps/?q=${userLat},${userLong}`;
					const withinGeofence = isWithinGeofence(
						userLat,
						userLong,
						geofence,
					);

					const result = {
						locationUrl,
						withinGeofence,
					};

					onResult(result);
				},
				(error) => {
					console.error(
						"Error Code = " + error.code + " - " + error.message,
					);
				},
			);
		} else {
			console.error("Geolocation is not supported by your browser.");
		}
	}, [onResult, geofence]);

	return <div>Fetching location...</div>;
};

export default LocationAndGeofence;
