import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
	View,
	Text,
	Modal,
	TouchableOpacity,
	Image,
	Dimensions,
} from "react-native";
// import ReactToPrint from "react-to-print";
// import Lottie from "react-lottie";
import CoverPage from "./CoverPage";
// import GeneralDocument from "./GeneralDocument";
import { APP_COLOURS } from "../../../APP_VARS";
// import * as animationData from "./assets/lottie/65681-3d-downloading-animation.json";
import Page2 from "./Page2";

class Masterservices extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pageScale: 0.95,
			masterServicesAgreement: [
				{
					title: "",
				},
			],
			pageData: [
				{
					id: 1,
					page: 2,
					data: [
						{
							type: "h1",
							text: "Master Services Agreement",
						},
						{
							type: "p",
							text: `This Master Services Agreement (the “Agreement”) governs your acquisition and use of our Services. By executing an order form that references this Agreement, you are agreeing to be bound by its terms. The most recent update to this Agreement was on 14 March 2022. This Agreement is effective between ${this.props.clientName} and Tetrice (Pty) Ltd as of 01 November 2023 (the “Effective Date”)`,
						},
						{
							type: "h2",
							text: `Main terms`,
						},
						{
							type: "h3",
							text: `1. Definitions`,
						},
						{
							type: "li",
							data: [
								`"Article 28" means article 28 of the General Data Protection Regulation (Regulation (EU) 2016/679).`,
								`“Client” or “you” means the Client accepting this Agreement and identified on the cover page of this Agreement.`,
								`“Client Data” means all data (including Personal Data and End User data) that is provided to Tetrice (Pty) Ltd by, or on behalf of, Client through Client’s use of the Services, and any data that third parties submit to Client through the Services.`,
								`"Data Protection Impact Assessment" means a data protection impact assessment as referred to in article 35 of the General Data Protection Regulation (Regulation (EU) 2016/679).`,
								`“Deliverable” (applicable only to agreements regarding Tetrice (Pty) Ltd products) means a report, presentation or other document, or other electronic or tangible work product commissioned by, and developed specifically for, Client that Tetrice (Pty) Ltd is required to deliver to Client as part of Project Services.`,
								`“End Users” means Client’s employees, agents, independent contractors, and other individuals authorized by Client to access and use the Services, unless otherwise defined in a specific Order Form or SST.`,
								`“Intellectual Property Rights” means current and future worldwide rights under patent, copyright, design rights, trade secret, trademark, moral rights, and other similar rights, whether registered or unregistered.`,
								`“Order Form” means an order form, sales order, sales quote, or similar document referencing and made under this Agreement and executed by the parties.`,
								`“Personal Data” means information relating to a living individual who is, or can be, reasonably identified from information, either alone or in conjunction with other information, within Client’s control and which is stored, collected or processed within one of Client’s Tetrice (Pty) Ltd End User accounts.`,
								`“Privacy Policies” means the Tetrice (Pty) Ltd Privacy Policy at http://www.tetrice.co.za/privacy-policy/ and other online Service-specific privacy and data use policies, statements, and notices that detail how Tetrice (Pty) Ltd handles Client Data.`,
								`“Project Services” (applicable only to agreements regarding Tetrice (Pty) Ltd products) means professional services, implementation services, consulting services, or other project-based services. Project Services are not subscriptions.`,
								`“POPI Act or POPIA” means the Protection of Personal Information Act of 2013.`,
								`“Services” means the services ordered by Client on an Order Form, including but not limited to Subscriptions and Project Services.`,
								`“SSTs” means service-specific terms that apply to specific Services (including Subscriptions and Project Services) and that are incorporated into and form a part of this Agreement.`,
								`“Tetrice (Pty) Ltd” means the Tetrice (Pty) Ltd entity defined in Section 14 (Contracting Entity)`,
								`“Tetrice (Pty) Ltd Background IP” means Intellectual Property Rights: (a) owned by or licensed to Tetrice (Pty) Ltd as of the Effective Date; (b) developed or acquired by Tetrice (Pty) Ltd after the Effective Date but independent of, and unrelated to, Tetrice (Pty) Ltd’s performance of the Services for Client; or (c) relating to standard products or services offered or provided by Tetrice (Pty) Ltd (including any improvements to those products and services, but which do not constitute Deliverables).`,
							],
						},
					],
				},
				{
					id: 1,
					page: 3,
					data: [
						{
							type: "h3",
							text: "2. SERVICES",
						},
						{
							type: "p",
							text: `2.1 Provision of Services. Tetrice (Pty) Ltd will provide the Services to Client in accordance with this Agreement, including any Order Forms and any applicable SSTs.
2.2 Order Forms. The parties may enter into Order Forms under this Agreement. Tetrice (Pty) Ltd and Client may agree that Client’s affiliates may enter into Order Forms under this Agreement. Any such Order Form may be executed by Tetrice (Pty) Ltd or a Tetrice (Pty) Ltd affiliate pursuant to the requirements for Tetrice (Pty) Ltd entities under Section 14. Any reference in the Agreement to “Client” will refer to the Client entity signing the Order Form and any reference in the Agreement to “Tetrice (Pty) Ltd” will refer to the Tetrice (Pty) Ltd contracting entity signing the Order Form. Each Order Form will incorporate the terms and conditions of this Agreement and will be a separate contract between the entities entering into the Order Form.
2.3 Third Party Services. If Client integrates the Services with any non-Tetrice (Pty) Ltd-provided third party service (such as a third party’s service that uses an application programming interface (API)), Client acknowledges that such third party service might access or use Client Data and Client permits the third party service provider to access Client Data as required for the interoperation of that third party service with the Services. Client is solely responsible for the use of such third party services and any data loss or other losses it may suffer as a result of using any such services.
`,
						},
						{
							type: "h3",
							text: `3. TYPES OF SERVICES`,
						},
						{
							type: "p",
							text: `3.1 All services by Tetrice (pty) ltd and its affiliate are sold subject to the following terms, unless otherwise agreed on an Order Form:
(a) License. Subject to the terms and conditions of this Agreement, Tetrice (Pty) Ltd grants Client a non-exclusive, non-transferable worldwide right to access and use the Services during the subscription term.
(b) Subscription Term. The initial term of each subscription is specified on the Order Form. Subscriptions will automatically renew at the end of each subscription term for additional periods equal to one year, unless either party gives the other written notice of non-renewal at least 30 days before the end of the then-current subscription term.
(c) Subscription Units Added Mid-subscription Term. An Order Form may be used to add more subscription units (e.g. plugins, micro-services or packages) to a subscription during a subscription term. The per unit pricing for those additional subscription units will be as specified on the Order Form of the underlying subscription (or, absent such specification, at the same per unit pricing as the underlying subscription pricing), prorated for the portion of that subscription term remaining at the time the subscription units are added. Any such additional subscription units will renew or terminate on the same date as the underlying subscription. Subscription units relating to a Service cannot be decreased during a subscription term for that Service.
(d) Default Type. Each Service is purchased as a subscription unless otherwise specified in an Order Form or indicated given the nature of the Service.
3.2 Project Services (applicable only to agreements regarding Tetrice (Pty) Ltd products). Project Services are subject to the following terms, unless otherwise agreed on an Order Form:
(a) Project Term. The term of a Project Service ends upon completion of those Project Services, unless earlier terminated.
(b) Scope of Project Services. Tetrice (Pty) Ltd will provide Project Services to Client in accordance with an Order Form (which may alternatively be entitled a “Statement of Works”), including any specifications, timetables, and acceptance criteria and procedures described therein.
(c) Ownership of Deliverables. Tetrice (Pty) Ltd will own all Intellectual Property Rights in any Deliverables, and the Client hereby assigns all Intellectual Property Rights in any Deliverables to Tetrice (Pty) Ltd, unless otherwise agreed upon,. Tetrice (Pty) Ltd and its affiliates will remain the software-as-a-service provider regardless of the cost structure of service and development Deliverables. Notwithstanding the foregoing, nothing in this and or other Agreements will assign or vest ownership of any Tetrice (Pty) Ltd Background IP from Tetrice (Pty) Ltd to the Client. Client grants Tetrice (Pty) Ltd and its affiliates a worldwide, royalty-free, non-exclusive license during and after the Term to use, reproduce, distribute, modify, and adapt the Deliverables for the purpose of providing the Services to other Clients and in accordance with this Agreement.

               `,
						},
					],
				},
				{
					id: 1,
					page: 4,
					data: [
						{
							type: "h3",
							text: "4. SERVICE FEATURES",
						},
						{
							type: "p",
							text: `4.1 Future Functionality. Client agrees that its purchases are not contingent on the delivery of any future features or functionality, or dependent on any oral or written public comments made by Tetrice (Pty) Ltd regarding future features or functionality.
4.2 Changes to Services. Tetrice (Pty) Ltd continually changes and improves Services which are provided under the software-as-a-service model. Tetrice (Pty) Ltd may alter or remove functionality from such Services at any time without prior notice, provided that Tetrice (Pty) Ltd will provide Client with prior written notice if Tetrice (Pty) Ltd makes a change to the Services resulting in a material decrease in core functionality used by Tetrice (Pty) Ltd’s general Client base.
`,
						},
						{
							type: "h3",
							text: `5. FEES`,
						},
						{
							type: "p",
							text: `5.1 Fees. Client will pay to Tetrice (Pty) Ltd all applicable fees for the Services specified in each Order Form. Except as otherwise specified in this Agreement or prohibited by applicable law, payment obligations are non-cancelable and fees paid are not refundable; provided, however, that refunds may be provided to Client in the event this Agreement is terminated in accordance with Section 13.5(b).
5.2 Invoicing and Payment Terms. Invoicing and payment terms shall be specified in each Order Form.
5.3 Taxes. All amounts payable by Client under this Agreement are exclusive of any applicable taxes, levies, duties, or similar governmental assessments of any nature (including value-added, sales, and use taxes, but excluding withholding taxes and taxes based on Tetrice (Pty) Ltd’s income, property, or employees) (“Taxes”) that may arise in connection with Client’s purchases under this Agreement. If any such Taxes arise, Client will pay such Taxes in addition to all other amounts payable under this Agreement, unless Client provides Tetrice (Pty) Ltd with a valid tax exemption certificate or other documentary proof, issued by an appropriate taxing authority, that no tax should be charged. If Client is required by law to withhold any Taxes from its payments to Tetrice (Pty) Ltd, Client must provide Tetrice (Pty) Ltd with an official tax receipt or other appropriate documentation to support such payments.
5.4 Currency. All monetary amounts in this Agreement are denominated in the currency stated on the Order Form. Fee payments by Client must be received by Tetrice (Pty) Ltd in the same currency as such fees were billed.
5.5 Overdue Payments. Tetrice (Pty) Ltd may charge Client interest on overdue payments at the rate of 2% per month (or the highest rate permitted by law, if less) on the amount overdue. If any payment becomes overdue, Tetrice (Pty) Ltd may condition future subscription renewals and Order Forms on payment terms shorter than previously offered to Client. If any amount owed by Client is overdue by 30 days or more, Tetrice (Pty) Ltd may accelerate all of Client’s unpaid fee obligations under this Agreement and limit functionality or suspend provision of Services to Client until such amounts are paid in full.

               `,
						},
					],
				},
				{
					id: 1,
					page: 5,
					data: [
						{
							type: "h3",
							text: "6. Client OBLIGATIONS",
						},
						{
							type: "p",
							text: `6.1 Client Responsibilities.
(a) Account Security. Client is responsible for maintaining the confidentiality of its own passwords and any other credentials used by it and its End Users to access the Services. Client will use commercially reasonable efforts to prevent unauthorized use of the Services and will terminate any unauthorized use of which it becomes aware. Client will notify Tetrice (Pty) Ltd promptly if Client becomes aware of any unauthorized access to its accounts.
(b) End User Activities. Client is responsible for ensuring that its End Users comply with this Agreement. Client, and not Tetrice (Pty) Ltd, is responsible for the acts of its End Users and any activity occurring in its End User accounts (other than activity that Tetrice (Pty) Ltd is directly responsible for which is not performed in accordance with Client’s instructions).
(c) One Individual per Account. End User accounts and passwords may not be shared and may only be used by one individual per account.
6.2 Acceptable Uses by Client. Unless otherwise agreed by Tetrice (Pty) Ltd in writing, Client will not violate the Acceptable Uses Policy located at https://tetrice.co.za/acceptable-uses-policy/.
6.3 Third Party Requests. The parties may from time to time receive a request from a third party for records related to Client’s use of the Services, including information in a Client End User account or identifying information about a Client End User (“Third Party Request”). Third Party Requests include search warrants, subpoenas, and other forms of legal process.
Client is responsible for responding to Third Party Requests via its own access to information process e.g. though the Promotion of access to Information Act (PAIA), and will only contact Tetrice (Pty) Ltd if Client is unable to obtain such information after diligent efforts. If Tetrice (Pty) Ltd receives a valid Third Party Request then, to the extent permitted by law, Tetrice (Pty) Ltd:
(a) may inform the third party issuing such request that it should pursue the request directly with Client; and
(b) will: (i) promptly notify Client of the Third Party Request; (ii) cooperate, at Client’s expense, with Client’s reasonable requests regarding Client’s efforts to oppose a Third Party Request; and (iii) after providing Client with an opportunity to respond to or oppose the Third Party Request, Tetrice (Pty) Ltd may fulfill that request if Tetrice (Pty) Ltd determines that it is required or permitted by law to do so.
6.4 Embargoes. Client represents and warrants that it is not barred by any applicable laws from being supplied with the Services. The Services may not be used in any country that is subject to an embargo by the United States or European Union applicable to the Services. Client will ensure that: (a) its End Users do not use the Services in violation of any export restriction or embargo by the United States; and (b) it does not provide access to the Services to persons on the U.S. Department of Commerce’s Denied Persons List or Entity List, or the U.S. Treasury Department’s list of Specially Designated Nationals.
6.5 Suspension of Services. Tetrice (Pty) Ltd may limit or suspend the Services from time to time at its discretion (for example, to perform scheduled maintenance or to stop a violation of Section 6.2 (Acceptable Uses by Client). If the circumstances reasonably permit, Tetrice (Pty) Ltd will give Client reasonable advance notice of any limitation or suspension so that Client can plan around it, or address the issue that has prompted Tetrice (Pty) Ltd to take such action. There may be some situations, such as security emergencies, where it is not practicable for Tetrice (Pty) Ltd to give such advance notice. Tetrice (Pty) Ltd will use commercially reasonable efforts to narrow the scope and duration of the limitation or suspension as is needed to resolve the issue that prompted such action.
`,
						},
					],
				},
				{
					id: 1,
					page: 6,
					data: [
						{
							type: "p",
							text: `7.6 New Sub-processors. Client may request that Tetrice (Pty) Ltd provide notifications of new Sub-processors, and if Client has a reasonable basis to object to Tetrice (Pty) Ltd’s use of a new Sub-processor, Client will notify Tetrice (Pty) Ltd promptly in writing within 15 days after Tetrice (Pty) Ltd has provided notification of the new Sub-processor. Tetrice (Pty) Ltd will use reasonable efforts to make available to Client a change in the affected Services or recommend a commercially reasonable change to Client’s configuration or use of the affected Services to avoid processing of Personal Data by the objected-to new Sub-processor without unreasonably burdening Client. If Tetrice (Pty) Ltd is unable to make available such a change within a reasonable period of time, which will not exceed 30 days, Client may terminate the portion of any Agreement relating to the Services that cannot be reasonably provided without the objected-to new Sub-processor by providing written notice to Tetrice (Pty) Ltd. Such termination will be without a right of refund for any fees prepaid by Client for the period following termination.
7.7 Security Incident. If Tetrice (Pty) Ltd becomes aware of any unauthorized or unlawful access to, or acquisition, alteration, use, disclosure, or destruction of, Client Data (“Security Incident”), Tetrice (Pty) Ltd will take reasonable steps to notify Client without undue delay, but in any event within 72 hours of becoming aware of the Security Incident. Tetrice (Pty) Ltd will also reasonably cooperate with Client with respect to any investigations relating to a Security Incident with preparing any required notices, and provide any information reasonably requested by Client in relation to any Security Incident.
7.8 Audits. Client will provide Tetrice (Pty) Ltd with at least one month’s prior written notice of any audit, which may be conducted by Client or an independent auditor appointed by Client (provided that no person conducting the audit shall be, or shall act on behalf of, a competitor of Tetrice (Pty) Ltd) (“Auditor”). The scope of an audit will be as follows:
(a) Client will only be entitled to conduct an audit once per year (during the course of a 12 month subscription) unless otherwise legally compelled or required by a regulator with established authority over the Client to perform or facilitate the performance of more than 1 audit in that same year (in which circumstances Client and Tetrice (Pty) Ltd will, in advance of any such audits, agree upon a reasonable reimbursement rate for Tetrice (Pty) Ltd’s audit expenses).
(b) Tetrice (Pty) Ltd agrees, subject to any appropriate and reasonable confidentiality restrictions, to provide evidence of any certifications and compliance standards it maintains and will, on request, make available to Client an executive summary of Tetrice (Pty) Ltd’s most recent annual penetration tests, which summary shall include remedial actions taken by Tetrice (Pty) Ltd resulting from such penetration tests.
(c) The scope of an audit will be limited to Tetrice (Pty) Ltd systems, processes, and documentation relevant to the processing and protection of Personal Data, and Auditors will conduct audits subject to any appropriate and reasonable confidentiality restrictions requested by Tetrice (Pty) Ltd.
(d) Client will promptly notify and provide Tetrice (Pty) Ltd with full details regarding any perceived non-compliance or security concerns discovered during the course of an audit.
The parties agree that, except as otherwise required by order or other binding decree of a regulator with authority over the Client, this Section 7.8 sets out the entire scope of the Client’s audit rights as against Tetrice (Pty) Ltd.
7.9 Client Privacy Obligations. Client shall ensure and hereby warrants and represents that it is entitled to transfer the Client Data to Tetrice (Pty) Ltd so that Tetrice (Pty) Ltd may lawfully process and transfer the Personal Data in accordance with this Agreement. Client shall ensure that relevant data subjects have been informed of, and have given their consent to, such use, processing, and transfer as required by all applicable data protection legislation.
`,
						},
					],
				},
				{
					id: 1,
					page: 7,
					data: [
						{
							type: "h3",
							text: "8. INTELLECTUAL PROPERTY",
						},
						{
							type: "p",
							text: `8.1 Customer IP. As between the parties, the Customer retains ownership of all Intellectual Property Rights in the Customer Data. This Agreement does not grant Tetrice (Pty) Ltd any licenses or rights to the Customer Data except for the following:
(a) Customer grants Tetrice (Pty) Ltd and its affiliates a worldwide, royalty-free, non-exclusive, limited license to use, host, copy, transmit, modify, display, and distribute Customer Data only for the limited purposes of providing the Services to Customer and improving the Services.
(b) If Customer provides Tetrice (Pty) Ltd with feedback about the Services, Tetrice (Pty) Ltd may use that feedback and incorporate it into its products and services without any obligation to Customer.
8.2 Tetrice (Pty) Ltd IP. As between the parties, Tetrice (Pty) Ltd retains ownership of the Services and all related Intellectual Property Rights. No licenses or rights are granted to Customer by Tetrice (Pty) Ltd other than as expressly provided for in this Agreement. Except as permitted by Tetrice (Pty) Ltd’s brand and trademark use policies, this Agreement does not grant the Customer any right to use Tetrice (Pty) Ltd’s trademarks or other brand elements.
8.3 Customer Lists. Tetrice (Pty) Ltd may identify Customer by name and logo as a Tetrice (Pty) Ltd customer on Tetrice (Pty) Ltd’s website and on other promotional materials. Any goodwill arising from the use of Customer’s name and logo will inure to the benefit of Customer.
`,
						},
						{
							type: "h3",
							text: "9. CONFIDENTIALITY",
						},
						{
							type: "p",
							text: `9.1 Definition. “Confidential Information” means information disclosed by a party (“Discloser”) to the other party (“Recipient”) in connection with the use or provision of the Services that is either marked as confidential or would reasonably be considered as confidential under the circumstances. Customer’s Confidential Information includes Customer Data. Tetrice (Pty) Ltd’s Confidential Information includes the terms of this Agreement and any security information about the Services. Despite the foregoing, Confidential Information does not include information that: (a) is or becomes public through no fault of the Recipient; (b) the Recipient already lawfully knew; (c) was rightfully given to the Recipient by an unaffiliated third party without restriction on disclosure; or (d) was independently developed by the Recipient without reference to the Discloser’s Confidential Information.
9.2 Confidentiality. The Recipient will: (a) protect the Discloser’s Confidential Information using commercially reasonable efforts; (b) use the Discloser’s Confidential Information only as permitted by this Agreement, including to exercise the Recipient’s rights and fulfill the Recipient’s obligations under this Agreement; and (c) not disclose the Discloser’s Confidential Information without the Discloser’s prior consent, except to affiliates, contractors, agents, and professional advisors who need to know it and have agreed in writing (or, in the case of professional advisors, are otherwise bound) to keep it confidential on terms comparable to those under this Section. The Recipient may disclose the Discloser’s Confidential Information when and to the extent required by law or legal process, but only after the Recipient, if permitted by law, uses reasonable efforts to notify the other party.
9.3 Return or Destruction of Confidential Information. Upon the termination or expiration of the Agreement and all Order Forms under the Agreement, each party will promptly return to the other party or destroy all Confidential Information of the other party in its possession or control within a reasonable amount of time in accordance with the Recipient’s data destruction practices.
`,
						},
						{
							type: "h3",
							text: "10. WARRANTIES",
						},
						{
							type: "p",
							text: `10.1 Warranties. Each party represents and warrants that: (a) it has full power and authority to enter into this Agreement; and (b) it will comply with all laws and regulations applicable to its provision or use of the Services.
10.2 Disclaimers. TETRICE (PTY) LTD MAKES NO REPRESENTATION OR WARRANTY ABOUT THE SERVICES. TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, TETRICE (PTY) LTD DISCLAIMS ANY IMPLIED OR STATUTORY WARRANTY, INCLUDING ANY WARRANTY OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
`,
						},
					],
				},
				{
					id: 1,
					page: 8,
					data: [
						{
							type: "h3",
							text: "12. LIABILITY",
						},
						{
							type: "p",
							text: `12.1 Exclusion of Certain Liability. TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL TETRICE (PTY) LTD BE LIABLE UNDER OR IN CONNECTION WITH THIS AGREEMENT FOR: (A) ANY INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL, PUNITIVE OR EXEMPLARY DAMAGES, UNDER ANY THEORY OF LAW, INCLUDING TORT OR (B) LOSS OF USE, DATA, BUSINESS, REVENUES, OR PROFITS (IN EACH CASE WHETHER DIRECT OR INDIRECT), EVEN IF THE PARTY KNEW OR SHOULD HAVE KNOWN THAT SUCH DAMAGES WERE POSSIBLE, AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
12.2 Liability Cap. TO THE EXTENT PERMITTED BY APPLICABLE LAW, TETRICE (PTY) LTD’S AGGREGATE LIABILITY ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT FOR ALL CLAIMS OF ANY KIND WILL NOT EXCEED THE LESSER OF (A) THE AMOUNTS PAID BY CUSTOMER TO TETRICE (PTY) LTD UNDER THIS AGREEMENT DURING THE 12 MONTHS PRIOR TO THE EVENT GIVING RISE TO THE LIABILITY; AND (B) US$100,000; PROVIDED, HOWEVER, THAT THE LIABILITY CAP WILL NOT APPLY TO LIABILITY FOR (A) FRAUD OR WILFUL MISCONDUCT, (B) DEATH OR PERSONAL INJURY, OR (C) INFRINGEMENT OF A THIRD PARTY’S INTELLECTUAL PROPERTY RIGHTS.
`,
						},
						{
							type: "h3",
							text: "13. TERM AND TERMINATION",
						},
						{
							type: "p",
							text: `13.1 Term of Agreement. The term of this Agreement starts on the Effective Date and shall remain in effect until either party terminates upon 60 days’ written notice to the other party, subject to Section 13.4 (“Term”).
13.2 Term of Order Forms. The term of an Order Form starts on its effective date and terminates when all Services ordered under it are terminated or completed. For the term of Subscriptions ordered on an Order Form, see Section 3.1 (Subscriptions).
13.3 Termination for Cause. A party may terminate this Agreement and any Order Form for cause: (a) upon 30 days’ written notice to the other party of a material breach if such breach remains uncured at the expiration of such period; or (b) if the other party ceases its business operations or becomes the subject of a petition in bankruptcy or any other proceeding relating to insolvency, receivership, administration, liquidation, or assignment for the benefit of creditors.
13.4 Consequences of Termination of Agreement. If this Agreement terminates, any Order Forms in effect will remain in effect in accordance with their terms (including the terms of this Agreement that are incorporated by reference), but no new Order Forms may be entered into under this Agreement.
13.5 Consequences of Termination of Order Form.
(a) Upon termination of an Order Form, Customer will (i) remain liable to pay: (1) the full subscription fee, and (2) any fees for Project Services payable to Tetrice (Pty) Ltd for the period prior to, or any invoices outstanding on, the effective date of termination of that Order Form and (ii) no longer have access to the applicable Services.
(b) If an Order Form is terminated by Customer due to Tetrice (Pty) Ltd’s material breach, Tetrice (Pty) Ltd will provide Customer with a pro rata refund of any fees prepaid by Customer applicable to the period following the effective date of termination of that Order Form; and
(c) If an Order Form is terminated by Tetrice (Pty) Ltd due to Customer’s material breach, Tetrice (Pty) Ltd will invoice, and Customer will pay, any accrued but unbilled fees and any unpaid fees covering the remainder of the term of that Order Form had it not been terminated.
13.6 Survival. The following Sections will survive termination of this Agreement: 5.3, 5.4, 5.5, and 10 to 15.
`,
						},
						{
							type: "h3",
							text: "14. CONTRACTING ENTITY",
						},
						{
							type: "p",
							text: `14.1 Contracting Entity Table. In the table below, “Customer Location” refers to where Customer is located (as determined by Customer’s business address on the Order Form, if specified) and determines which table row applies to Customer:

`,
						},
						{
							type: "h3",
							text: " INSERT TABLE!!!",
						},
					],
				},
				{
					id: 1,
					page: 9,
					data: [
						{
							type: "p",
							text: `(a) Contracting Entity. References to “Tetrice (Pty) Ltd” are references to the applicable Contracting Entity specified in Contracting Entity Table. The Services are provided by that contracting entity.
(b) Governing Law. This Agreement is governed by the laws of the applicable jurisdiction specified in the Contracting Entity Table, without giving effect to any of its conflicts of laws principles.
14.2 Dispute Resolution. Tetrice (Pty) Ltd would like to address all disputes without resorting to formal legal proceedings. Before filing a claim, each party agrees to try to resolve a dispute by formally notifying the other party in writing that it wishes to negotiate a dispute. If a dispute is not resolved within 30 days of such notice and the Contracting Entity is (a) Tetrice (Pty) Ltd, then the Agreement is governed by the laws of South Africa, and any action or proceeding (including those arising from non-contractual disputes or claims) related to the Agreement will be brought in a court in Gauteng, South Africa; Each party irrevocably submits to the jurisdiction and venue of the applicable courts. The prevailing party in any litigation may seek to recover its legal fees and costs.
14.3 No Class Actions. Customer may only resolve disputes with Tetrice (Pty) Ltd on an individual basis and will not bring a claim in a class, consolidated, or representative action. Class arbitrations, class actions, private attorney general actions, and consolidation with other arbitrations are not allowed.
14.4 Attorneys’ Fees. In any legal proceeding instituted by a party to enforce this Agreement, the prevailing party shall have the right to collect from the other party the reasonable costs and expenses incurred by the prevailing party in conducting the legal proceeding, including reasonable attorneys' fees and disbursements, and court costs.

`,
						},
						{
							type: "h3",
							text: "12. GENERAL",
						},
						{
							type: "p",
							text: `15.1 Amendments. This Agreement may only be amended if authorized representatives of each party agree in a signed writing.
15.2 Assignment. Neither Customer nor Tetrice (Pty) Ltd may assign this Agreement without the other party’s prior written consent (such consent not to be unreasonably withheld). However, either party may assign this Agreement without notice to an affiliate or to a successor or acquirer, as the case may be, in connection with a merger, acquisition, corporate reorganization or consolidation, or the sale of all or substantially all of such party’s assets or of the Tetrice (Pty) Ltd business line to which the subject matter of this Agreement relates. Any other attempt to transfer or assign is void.
15.3 Counterparts. This Agreement may be executed in any number of counterparts, each of which will be deemed to be an original and all of which taken together will comprise a single instrument. This Agreement may be delivered by facsimile or electronic document format (e.g. PDF), and facsimile or electronic copies of executed signature pages will be binding as originals.
15.4 Entire Agreement. This Agreement (including any documents incorporated herein by reference to a URL or otherwise, and any Order Form prepared for the Customer by Tetrice (Pty) Ltd), constitutes the entire agreement between Customer and concerning its subject matter. Any terms and conditions appearing on a purchase order or similar document issued by Customer do not apply to the Services, do not override or form a part of this Agreement, and are void.
15.5 Force Majeure. Neither Tetrice (Pty) Ltd nor Customer will be liable for inadequate performance to the extent caused by a condition (for example, natural disaster, act of war or terrorism, riot, governmental action, or internet disturbance) that was beyond the party’s reasonable control.
15.6 Independent Contractors. The relationship between Tetrice (Pty) Ltd and Customer is that of independent contractors, and not legal partners, employees, joint venturers, or agents of each other.
15.7 Interpretation. The use of the terms “includes”, “including”, “such as” and similar terms, will be deemed not to limit what else might be included.
15.8 No Waiver. A party’s failure or delay to enforce a provision under this Agreement is not a waiver of its right to do so later.

`,
						},
					],
				},
				{
					id: 1,
					page: 10,
					data: [
						{
							type: "p",
							text: `15.9 Notices.
(a) Providing Notice. All notices must be in writing and will be deemed given when: (i) personally delivered, (ii) verified by written receipt, if sent by postal mail with verification of receipt service or courier, (iii) received, if sent by postal mail without verification of receipt, or (iv) verified by automated receipt or electronic logs if sent by email.
(b) Notices to Tetrice (Pty) Ltd. Notices to Tetrice (Pty) Ltd must be sent to Tetrice (Pty) Ltd, 159 Rivonia Road, Morningside, Sandton, South Africa, marked to the attention of the account representative. Email is insufficient for providing non-routine legal notices (including indemnification claims, breach notices, and termination notices) (“Non-Routine Legal Notices”) to Tetrice (Pty) Ltd. Customer may grant approvals, permission, extensions, and consents by email.
(c) Notices to Customer. Notices to Customer may be sent to the email address associated with Customer’s designated primary administrator for the relevant Service (“Primary Admin”). Billing-related notices (including notices of overdue payments) may be sent to the relevant billing contact designated by Customer. If Customer has provided contact details for legal notices on the cover page of this Agreement, any Non-Routine Legal Notices will be provided to such contact instead, with a copy to the email address associated with Customer’s Primary Admin. Notices to End Users of the Services may be sent to the email address associated with that End User’s account.
(d) Keep Contact Details Current. Customer and its End Users must keep the contact details associated with their user accounts and billing contacts current and accurate, and notify Tetrice (Pty) Ltd in writing of any changes to such details.
15.10 Precedence. If any conflict exists among the following documents, the order of precedence will be: (1) the applicable Order Form, (2) this Agreement, and (3) the applicable SSTs. Any terms set forth under a “Special Terms” heading in any of the foregoing documents will take precedence over any other terms to the contrary in that document.
15.11 Severability. If any provision of this Agreement is determined to be unenforceable by a court of competent jurisdiction, that provision will be severed and the remainder of terms will remain in full effect.
15.12 Subcontractors. Tetrice (Pty) Ltd may subcontract the provision of Services without Customer consent. Tetrice (Pty) Ltd will be responsible for the performance of its employees and contractors, and their compliance with Tetrice (Pty) Ltd’s obligations under this Agreement, except as may be otherwise specified herein.
15.13 Third Party Beneficiaries. There are no third party beneficiaries to this Agreement. Customer’s End Users are not third party beneficiaries to Customer’s rights under this Agreement.
`,
						},
					],
				},
			],
		};
	}

	render() {
		let w = 795; //595
		let h = 1119; //842
		let aspectRatio = w / h;

		let aph = this.props.screenHeight - 72;
		let apw = this.props.screenWidth - 190;

		let padding = 65;

		let zoom = this.state.pageScale + aph;
		let docWidth = zoom * aspectRatio - padding * aspectRatio;
		let docHeight = zoom - padding;

		return (
			<View
				style={{
					flex: 1,
					width: apw,
					height: aph,
				}}>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "center",
					}}>
					{/* <ReactToPrint
						onBeforeGetContent={() => {
							this.setState({
								loading: true,
							});
						}}
						onAfterPrint={() => {
							this.setState({
								loading: false,
							});
						}}
						trigger={() => {
							return (
								<a href='#'>
									<Image
										style={{
											width: 25,
											height: 25,
											resizeMode: "contain",
										}}
										source={require("../../../../assets/images/min_btn.png")}
									/>
								</a>
							);
						}}
						content={() => this.componentRef}
					/> */}
					<View
						style={{
							width: 150,
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
							marginRight: 50,
						}}>
						<TouchableOpacity
							onPress={async () => {
								this.setState({
									pageScale: this.state.pageScale - 0.1,
								});
							}}>
							<Image
								style={{
									width: 25,
									height: 25,
									resizeMode: "contain",
								}}
								source={require("../../../../assets/images/min_btn.png")}
							/>
						</TouchableOpacity>

						<Text
							style={{
								color: APP_COLOURS.BG2, // '#5A7BBA'
								fontSize: 17,
								fontWeight: "900",
								paddingBottom: 4,
							}}>
							zoom
						</Text>

						<TouchableOpacity
							onPress={async () => {
								this.setState({
									pageScale: this.state.pageScale + 0.1,
								});
							}}>
							<Image
								style={{
									width: 25,
									height: 25,
									resizeMode: "contain",
								}}
								source={require("../../../../assets/images/add_btn.png")}
							/>
						</TouchableOpacity>
					</View>
					{/* <View
						style={{
							width: 150,
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
						}}>
						<TouchableOpacity
							onPress={async () => {
								await this.setState({
									startweekNumber: this.state.startweekNumber - 1,
									endweekNumber: this.state.endweekNumber - 1,
								});
								await this.calculateCalendar();
								// await this.saveAppStates();
							}}>
							<Image
								style={{
									width: 45,
									height: 45,
									resizeMode: "contain",
								}}
								source={require("../../../../assets/images/left_btn.png")}
							/>
						</TouchableOpacity>

						<TouchableOpacity
							onPress={async () => {
								await this.setState({
									startweekNumber: this.state.startweekNumber + 1,
									endweekNumber: this.state.endweekNumber + 1,
								});
								await this.calculateCalendar();
								// await this.saveAppStates();
							}}>
							<Image
								style={{
									width: 45,
									height: 45,
									resizeMode: "contain",
								}}
								source={require("../../../../assets/images/right_btn.png")}
							/>
						</TouchableOpacity>
					</View> */}
				</View>

				<View
					// onLayout={(e) => {
					// 	console.log(e.nativeEvent.layout);
					// }}
					style={{
						flex: 1,
						overflow: "scroll",
						// justifyContent: "center",
						// alignItems: "center",
					}}>
					{/* <Document>
						<Page size='A4' style={styles.page}>
							<View style={styles.section}>
								<Text>Section #1</Text>
							</View>
							<View style={styles.section}>
								<Text>Section #2</Text>
							</View>
						</Page>
               </Document> */}
					{/* <PDFViewer>
						<MyDocument />
					</PDFViewer> */}
					<View
						style={{
							flex: 1,
							flexDirection: "row",
							transform: `scale(${this.state.pageScale})`,
							"transform-origin": `${0}px ${0}px`,
						}}>
						<View
							style={{
								width: 20,
							}}
						/>

						{/* <div
							// className='print-container'
							ref={(el) => (this.componentRef = el)}> */}
						<CoverPage />
						{this.state.pageData.map((d, i) => (
							<Page2
								data={d.data}
								pageNumber={d.page}
								totalPages={this.state.pageData.length}
							/>
						))}

						{/* </div> */}

						<View
							style={{
								width: docWidth,
							}}
						/>
					</View>
				</View>
				{this.state.loading ? this.loadingModal() : <View />}
			</View>
		);
	}

	loadingModal = (obj) => {
		// let width = Dimensions.get("window").width;
		// let height = Dimensions.get("window").height;
		// const defaultOptions = {
		// 	loop: true,
		// 	autoplay: true,
		// 	animationData: animationData,
		// 	rendererSettings: {
		// 		preserveAspectRatio: "xMidYMid slice",
		// 	},
		// };
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<View
						style={{
							// width: width,
							// height: height,
							backgroundColor: "#FFF",
							borderRadius: 20,
							justifyContent: "center",
							alignItems: "center",
							padding: 40,
						}}>
						<Text
							allowFontScaling={false}
							style={{
								fontSize: 18,
								fontFamily: "Gotham",
								margin: 5,
								fontWeight: "700",
								marginBottom: 16,
							}}>
							Processing Document
						</Text>
						<View
							style={{
								flexDirection: "row",
								alignItems: "center",
								// justifyContent: "center",
								width: "100%",
								padding: 5,
								justifyContent: "space-between",
							}}>
							{/* <Lottie
								options={defaultOptions}
								height={200}
								width={200}
							/> */}
						</View>
						<View
							style={{
								// width: width,
								// height: height - 90,
								marginTop: 20,
							}}>
							<TouchableOpacity
								onPress={() =>
									this.setState({
										loading: false,
									})
								}>
								<View
									style={{
										width: "100%",
										backgroundColor: APP_COLOURS.BLUE,
										padding: 9,
										justifyContent: "center",
										alignItems: "center",
										borderRadius: 10,
									}}>
									<Text
										style={{
											color: APP_COLOURS.WHITE,
											fontSize: 11,
										}}>
										Cancel download
									</Text>
								</View>
							</TouchableOpacity>
						</View>
					</View>
				</View>
			</Modal>
		);
	};
}

export default Masterservices;
