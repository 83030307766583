import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import AutoHeightImage from "../Authed/AutoHeightImageComp";
import { APP_COLOURS } from "../APP_VARS";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import DevicePolicy from "./DevicePolicy";
import EmployeeNotice from "./EmployeeNotice";
import EmployeeContract from "./EmployeeContract";
import PdfPage from "./PdfPage";

// import { APP_COLOURS } from "../../APP_VARS";
// import { Avatars, StatusColour, TaskStatus } from "../APP_VARS";
// import StatusItem from "../DailyPlanner/StatusItem";
// import Lottie from "react-lottie";
// import {
// 	createPostApproval,
// 	crudCreate,
// 	crudDelete,
// 	crudGetUsers,
// 	crudRead,
// 	crudReadAll,
// 	crudUpdate,
// } from "../../CRUDHELPER";
// import Fade from "react-reveal/Fade";
// import * as animationData from "../../../assets/lottie/team.json";

// import InstagramPost from "./Instagram";
// import ApprovalSent from "./ApprovalSent";
// import SendingFeedback from "./SendingFeedback";

let documents = [
	// {
	// 	id: "1234",
	// 	component: "DefaultDoc",
	// },
	// {
	// 	id: "12345",
	// 	component: "DevicePolicy",
	// },
	// {
	// 	id: "policy34183",
	// 	component: "EmployeeNotice",
	// },
	{
		id: "1234",
		component: "employeeContract",
	},
];

const CompanyDocuments = () => {
	const [post, setPost] = useState(documents[0]);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const [sent, setSent] = useState(false);
	const [sending, setSending] = useState(false);

	const { documentId, token } = useParams();

	document.title = `Document - ${documentId}`;

	useEffect(() => {
		const fetchPost = async () => {
			let postData = documents.filter((d) => d.id === documentId);
			if (postData.length > 0) setPost(postData[0]);
		};

		fetchPost();
	}, [documentId, token]);

	// Approval handler
	// const handleApproval = async (data) => {
	// 	console.log("handleApproval", data);
	// 	// return;
	// 	setSending(true);

	// 	data.token = token;

	// 	createPostApproval({ ...data }, (status_) => {
	// 		if (status_ === 200) {
	// 			setSending(false);
	// 			setSent(true);
	// 		} else {
	// 			setSending(false);
	// 			setSent(false);
	// 			setError(true);
	// 		}
	// 	});
	// };

	// if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error}</div>;

	// if (sending) return <SendingFeedback />;
	// if (sent) return <ApprovalSent />;

	let screenWidth = window.innerWidth;
	screenWidth = screenWidth > 450 ? 450 : screenWidth;

	return (
		<View
			style={{
				backgroundColor: "#000",
				// width: "100%",
				// overflow: "hidden",
				// height: window.innerHeight,
				alignItems: "center",
				flex: 1,
			}}>
			{/* <View
				style={{
					// flex: 1,
					width: screenWidth,
				}}>
				<View
					style={{
						padding: 5,
						width: "100%",
						alignItems: "center",
					}}>
					<TouchableOpacity
						style={{
							flexDirection: "row",
							alignItems: "center",
							// position: "absolute",
						}}
						onPress={() => {
							// this.props.close();
						}}>
						<AutoHeightImage
							width={27}
							source={require("./../../assets/images/menu.png")}
						/>
						<Text
							adjustsFontSizeToFit
							style={{
								fontWeight: "800",
								fontFamily: "Gotham",
								color: "#FFF",
								fontSize: 15,
								marginLeft: 4,
							}}>
							tetrice
						</Text>
						<Text
							adjustsFontSizeToFit
							style={{
								fontWeight: "200",
								fontFamily: "Gotham",
								color: "#FFF",
								fontSize: 15,
								marginLeft: 4,
								opacity: 0.7,
							}}>
							verified
						</Text>
						<Image
							source={require("./verified.png")}
							style={{
								width: 15,
								height: 15,
								resizeMode: "contain",
							}}
						/>
					</TouchableOpacity>
				</View>
			</View> */}

			<View style={{ flex: 1, width: window.innerWidth }}>
				{post.component === "EmployeeNotice" ? (
					<EmployeeNotice />
				) : null}

				{post.component === "DevicePolicy" ? (
					<PdfCreator
						style={{
							marginHorizontal: "auto",
							width: window.innerWidth,
						}}>
						<DevicePolicy />
					</PdfCreator>
				) : null}

				{post.component === "employeeContract" ? (
					<PdfCreator
						style={{
							marginHorizontal: "auto",
							width: window.innerWidth,
						}}>
						{/* <EmployeeContract /> */}
						<PdfPage />
					</PdfCreator>
				) : null}

				{/* {post.component === "DevicePolicy" ? (
					<PdfCreator
						style={{
							marginHorizontal: "auto",
							width: window.innerWidth,
						}}>
						<EmployeeNotice />
					</PdfCreator>
				) : null} */}
			</View>
		</View>
	);
};

const PdfCreator = ({ children, style }) => {
	// const createPDF = () => {
	// 	const input = document.getElementById("divToPrint");
	// 	const width = input.offsetWidth;
	// 	const height = input.offsetHeight;

	// 	html2canvas(input, { scrollY: -window.scrollY, scale: 1 }).then(
	// 		(canvas) => {
	// 			const imgData = canvas.toDataURL("image/png");
	// 			const pdf = new jsPDF({
	// 				orientation: "portrait",
	// 				unit: "px",
	// 				format: [width, height],
	// 			});

	// 			pdf.addImage(imgData, "PNG", 0, 0, width, height);
	// 			pdf.save("download.pdf");
	// 		},
	// 	);
	// };
	const createPDF = () => {
		const input = document.getElementById("divToPrint");
		const width = input.offsetWidth;

		// Assuming A4 size for PDF - adjust as needed
		const pageHeightInPx = 1122; // Roughly equivalent to an A4 page height in pixels
		const totalHeight = input.offsetHeight;
		const numberOfPages = Math.ceil(totalHeight / pageHeightInPx);

		html2canvas(input, { scrollY: -window.scrollY, scale: 1 }).then(
			(canvas) => {
				const imgData = canvas.toDataURL("image/png");
				const pdf = new jsPDF({
					orientation: "portrait",
					unit: "px",
					format: [width, pageHeightInPx],
				});

				for (let i = 0; i < numberOfPages; i++) {
					if (i > 0) {
						pdf.addPage([width, pageHeightInPx], "portrait");
					}
					// Adjust the position for each page
					pdf.addImage(
						imgData,
						"PNG",
						0,
						-i * pageHeightInPx,
						width,
						totalHeight,
					);
				}

				pdf.save("download.pdf");

				const pdfBlob = pdf.output("blob");

				// Create a URL for the Blob
				const pdfUrl = URL.createObjectURL(pdfBlob);

				// Open the PDF in a new window
				window.open(pdfUrl, "_blank");
			},
		);
	};

	return (
		<div style={style}>
			<div
				id='divToPrint'
				style={{
					backgroundColor: "white",
					width: "100vw",
					minHeight: "297mm",
					marginLeft: "auto",
					marginRight: "auto",
				}}>
				{children}
			</div>
			<button
				onClick={createPDF}
				style={
					{
						/* Your button styles here */
					}
				}>
				Save to PDF
			</button>
		</div>
	);
};

export default CompanyDocuments;
