import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TextInput,
	ScrollView,
	Modal,
	Alert,
	Keyboard,
	Animated,
	LayoutAnimation,
	ActivityIndicator,
} from "react-native";
import {
	APP_COLOURS,
	TaskStatus,
	CustomLayoutSpring,
	Avatars,
	PROJECTSTATUS,
} from "../../APP_VARS";
import moment from "moment";
import Lottie from "react-lottie";
import {
	crudRead,
	crudReadAll,
	crudCreate,
	crudDelete,
	crudUpdate,
	crudGetUsers,
	crudSendEmail,
	sendDiscordMessage,
	crudGetTasks,
	crudGetProjects,
} from "../../CRUDHELPER";
import ProgressBar from "./ProgressBar";
import CheckBox from "./checkBox";
import AddTask from "./AddTask";
import { TaskerInput } from "./TaskerInput";
import UsersSelectList from "../UsersSelectList";
import TodayTimeline from "./TodayTimeline";
import ChangeDate from "./ChangeDate";
import StatusItem from "./StatusItem";
import * as animationData from "../../../assets/lottie/animation_lnu500l9.json";

import debbie from "../../../assets/images/cloud.png";
import starFill from "../../../assets/images/Table/starFill.png";
import star from "../../../assets/images/Table/star.png";
import bin from "../../../assets/images/bin.png";
import AddProject from "./AddProject";
import EventCalendar from "../EventCalendar";
import GanttComponent from "../Gantt";
import DailyPlanner from "./DailyPlanner";
import TopTabs from "../TopTabs";
import TopTabsObj from "../TopTabs/TabPassObj";
import SmallerTabs from "../TopTabs/SmallerTabs";
import AutoHeightImage from "../AutoHeightImageComp";
import EditTaskMobile from "../Gantt/EditTaskMobile";
import ChatBox from "./ChatBox";
import TextEditorComp from "./TextEditorCom";
import ActivityLog from "../ActivityLog";

import Pie from "./PieChart";
import ProjectDocuments from "./ProjectDocuments";

import DescriptionQuillEditor from "./Editor/DescriptionQuillEditor";

function uuid() {
	return "xxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

function isOdd(num) {
	return num % 2;
}

const BG = "#171B22";
const BG2 = "#20242B";
const FONT = "#F3F3F5";
const BORDER = "#383D43";

const BLUE = "#3F6EFF";

// class AutoHeightImage extends Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {};
// 	}
// 	// <Image
// 	// 			style={{
// 	// 				width: this.props.width,
// 	// 				height: "auto",
// 	// 				resizeMode: "contain",
// 	// 			}}
// 	// 			source={this.props.source}
// 	// 		/>
// 	render() {
// 		return (
// 			<img
// 				style={{
// 					width: this.props.width,
// 					resizeMode: "contain",
// 				}}
// 				src={this.props.source}
// 				alt='Image'
// 				className='responsive'
// 			/>
// 		);
// 	}
// }

export default class ProjectManager extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			showModal: false,
			addProjectTask: false,
			addNewProject: false,
			showChat: false,
			showSubTasks: false,

			showAssignment: false,
			showDateChange: false,
			needToUpdateProject: false,
			selectedChat: "",
			filterTab: "",
			xPos: 0,
			yPos: 0,
			user_data: {
				id: "",
			},
			selectedProject: "",
			crudDocumentName: "projects",
			uniqueProjectTitles: [],
			showProjects: true,

			showModalArea: "",

			selectedPage: "Projects",

			projects: [],
			milestones: [],
			tasks: [],

			subPage: "",
			selectedTask: "",

			filterTasks: "",
		};

		this.keyboardOffset = new Animated.Value(0);
		this.projects = [];
		this._timeout = 1000;
		this.statusTypes = [
			{ title: "Pending", color: "transparent" },
			{ title: "Inprogress", color: "#E8E8E8" },
			{ title: "Delayed", color: APP_COLOURS.ORANGE },
			{ title: "Completed", color: APP_COLOURS.GREEN },
		];
	}

	handleStatusChange = async (id, status) => {
		let projects = [...this.state.projects];
		let taskIndex = projects.findIndex((task) => task.id === id);
		let task = projects[taskIndex];

		task.status = status;
		projects[taskIndex] = task;

		await this.setState({ projects });
		await this.updateProjectItem(projects[taskIndex]);
	};

	handleDateChange = async (date, id) => {
		let projects = [...this.state.projects];
		let projectIndex = projects.findIndex((task) => task.id === id);
		let project = projects[projectIndex];
		project.due_date = date;
		projects[projectIndex] = project;
		await this.updateProjectItem(projects[projectIndex]);
		await this.setState({ projects });
	};

	handleProjectChange = async (text, id) => {
		let projects = [...this.state.projects];
		let projectIndex = projects.findIndex((task) => task.id === id);
		let project = projects[projectIndex];
		project.project = text;
		projects[projectIndex] = project;
		await this.updateProjectItem(projects[projectIndex]);
		await this.setState({ projects });
	};

	handleTitleChange = async (text, id) => {
		let projects = [...this.state.projects];
		let projectIndex = projects.findIndex((task) => task.id === id);
		let project = projects[projectIndex];
		project.title = text;
		projects[projectIndex] = project;
		await this.updateProjectItem(projects[projectIndex]);
		await this.setState({ projects });
	};

	handlePriorityChange = async (priority, id) => {
		let projects = [...this.state.projects];
		let projectIndex = projects.findIndex((task) => task.id === id);
		let project = projects[projectIndex];
		project.priority = priority;
		projects[projectIndex] = project;
		await this.updateProjectItem(projects[projectIndex]);
		await this.setState({ projects });
	};

	handleNewMessageChange = async (text, id) => {
		let projects = [...this.state.projects];
		let projectIndex = projects.findIndex((task) => task.id === id);
		let project = projects[projectIndex];
		project.newMessage = text;
		projects[projectIndex] = project;
		await this.updateProjectItem(projects[projectIndex]);
		await this.setState({ projects });
	};

	handleMessageSubmit = async (id) => {
		let projects = [...this.state.projects];
		let projectIndex = projects.findIndex((task) => task.id === id);
		let project = projects[projectIndex];
		project.messages = [
			...project.messages,
			{
				text: project.newMessage,
				createdBy: this.state.user_data,
				id: uuid(),
			},
		];
		project.newMessage = "";
		projects[projectIndex] = project;
		await this.updateProjectItem(projects[projectIndex]);
		await this.setState({
			selectedSubtasks: projects[projectIndex],
		});
		await this.setState({ projects });
	};

	//
	//

	addAssignment = async (assignment, assignment_ids) => {
		let originalAssignments = this.state.selectedAssignment;
		// console.log(
		// 	"assignment",
		// 	assignment.map((d) => d.username),
		// 	"originalAssignments",
		// 	originalAssignments.map((d) => d.username),
		// );
		// return;
		//
		//
		let projects = [...this.state.projects];
		let projectIndex = projects.findIndex(
			(task) => task.id === this.state.selectedID,
		);
		projects[projectIndex].assignment = assignment;
		projects[projectIndex].assignment_ids = assignment_ids;

		const updatedAssignments = assignment; // Updated array with 5 items

		// Assuming each assignment has a unique 'id' or 'username'
		const newlyAddedAssignments = updatedAssignments.filter(
			(updatedAssignment) =>
				!originalAssignments.some(
					(originalAssignment) =>
						originalAssignment.id === updatedAssignment.id, // Replace 'id' with the property you use to uniquely identify assignments
				),
		);

		// console.log("newlyAddedAssignments", newlyAddedAssignments);
		// return;
		const mapThrough = await newlyAddedAssignments.forEach(async (e) => {
			await crudSendEmail(
				"SKrum  <skrum@tetrice.co.za>",
				e.username,
				"Project assignment",
				`You've been added to a project: <br> <h3> ${projects[projectIndex].brand?.title}</h3> <br> <h3> ${projects[projectIndex].title}</h3><br>
				
			<br>
			<br>Be sure to check for tasks that are allocated to you.
			<br>https://skrum.tetrice.co.za/
			`,
			);
		});

		console.log(JSON.stringify(projects[projectIndex].assignment_ids));
		await this.updateProjectItem(projects[projectIndex]);
		await this.setState({ projects });
	};

	//
	//
	addSubtask = async (subtask) => {
		let projects = [...this.state.projects];
		let projectIndex = projects.findIndex(
			(task) => task.id === this.state.selectedID,
		);
		projects[projectIndex].subtasks.push(subtask);
		await this.updateProjectItem(projects[projectIndex]);
		await this.setState({
			selectedSubtasks: projects[projectIndex],
		});
		await this.setState({ projects });
	};
	toggleSubtaskStatus = async (id, subtaskIndex, status) => {
		let projects = [...this.state.projects];
		let projectIndex = projects.findIndex(
			(task) => task.id === this.state.selectedID,
		);
		projects[projectIndex].subtasks[subtaskIndex].status =
			!projects[projectIndex].subtasks[subtaskIndex].status;
		let completedSubtasks = projects[projectIndex].subtasks.filter(
			(s) => s.status === true,
		);
		projects[projectIndex].progress =
			(completedSubtasks.length /
				projects[projectIndex].subtasks.length) *
			100;
		await this.updateProjectItem(projects[projectIndex]);
		await this.setState({
			selectedSubtasks: projects[projectIndex],
		});
		await this.setState({ projects });
	};

	removeSubtask = async (id, subtaskIndex) => {
		let projects = [...this.state.projects];
		let projectIndex = projects.findIndex((task) => task.id === id);
		projects[projectIndex].subtasks.splice(subtaskIndex, 1);
		let completedSubtasks = projects[projectIndex].subtasks.filter(
			(s) => s.status === true,
		);
		projects[projectIndex].progress =
			(completedSubtasks.length /
				projects[projectIndex].subtasks.length) *
			100;
		await this.updateProjectItem(projects[projectIndex]);
		await this.setState({
			selectedSubtasks: projects[projectIndex],
		});
		await this.setState({ projects });
	};

	deleteProject(id) {
		this.setState({
			projects: this.state.projects.filter(
				(project) => project.id !== id,
			),
		});
	}

	render() {
		LayoutAnimation.configureNext(CustomLayoutSpring);

		return (
			<View
				style={{
					flex: 1,
				}}>
				<View
					style={{
						flex: 1,
						flexDirection: "row",
						width: "100%",
					}}>
					<View
						style={{
							flex: 1,
							// marginTop: 10,
							// marginRight: 10,
						}}>
						{/* <Text
							style={{
								color: APP_COLOURS.WHITE_TRANSPARENT10,
								fontSize: 15,
								fontWeight: "800",
								fontFamily: "Gotham",
								marginLeft: 10,
							}}>
							{this.state.selectedPage}
						</Text> */}
						<View
							style={{
								flex: 1,
								overflow: "hidden",

								borderRadius: 15,
							}}>
							{this.state.selectedPage === "Projects"
								? this.projectsComponent()
								: null}

							{this.state.selectedPage === "Tasks"
								? this.allTasksComp(12, 20)
								: null}

							{this.state.selectedPage === "Calendar" ? (
								<View
									style={{
										width: "100%",
										flex: 1,
									}}>
									{/* <ScrollView> */}
									<View
										style={{
											flex: 1,
											padding: 2,
											backgroundColor: "#FFF",
											borderRadius: 14,
											alignItems: "center",
										}}>
										{/* <View
												style={{
													flexDirection: "row",
												}}>
												<Text
													style={{
														color: APP_COLOURS.BG2,
														fontSize: 25,
														fontWeight: "600",
														fontFamily: "Gotham",
														width: "100%",
														// textAlign: "center",e
													}}>
													Event Calendar
												</Text>
												<View
													style={{
														backgroundColor: "#FFF",
														padding: 2,
													}}>
													<Image
														style={{
															width: 50,
															height: 50,
															resizeMode:
																"contain",
														}}
														source={require("../../../assets/images/market-analysis.png")}
													/>
												</View>
											</View> */}

										<View
											style={{
												width: 350,
											}}>
											<EventCalendar
												compWidth={350}
												dateSelected={async (d) => {
													//   await this.setState({
													//     dateSelected: d,
													//     showFullCalendar: false,
													//   });
													//   await this.generateCalendar();
												}}
												calendarList={
													this.state.projects
												}
												selectedDate={moment()}
											/>
										</View>
									</View>
									{/* </ScrollView> */}
								</View>
							) : null}
						</View>

						{this.state.selectedProject ? (
							<Modal
								animationType='fade'
								transparent={true}
								visible={true}>
								{this.projectComponent(
									this.state.selectedProject,
								)}
							</Modal>
						) : null}

						{this.state.loading ? (
							<Modal
								animationType='fade'
								transparent={true}
								visible={true}>
								<View
									style={{
										flex: 1,
										justifyContent: "flex-start",
										alignItems: "center",
										backgroundColor:
											"rgba(52, 52, 52, 0.6)",
									}}>
									<View
										style={{
											flex: 1,
											justifyContent: "center",
											alignItems: "center",
										}}>
										<View
											style={{
												// flex: 1,
												width: 300,
												height: 250,
												backgroundColor:
													APP_COLOURS.BG2,
												justifyContent: "center",
												alignItems: "center",
												borderRadius: 15,
											}}>
											<View
												style={{
													flexDirection: "row",
													alignItems: "center",
													marginBottom: 40,
												}}>
												<Image
													source={require("../../../assets/images/icons/skrum_menu.png")}
													style={{
														width: 30,
														height: 30,
														resizeMode: "contain",
														// marginRight: 3,
													}}
												/>
												<Image
													source={require("../../../assets/images/skrum_logo.png")}
													style={{
														width: 80,
														height: 35,
														resizeMode: "contain",
														marginRight: 7,
													}}
												/>
											</View>
											{/* <Text
											style={{
												color: APP_COLOURS.TEXTCOLOR,
												fontSize: 12,
												marginLeft: 10,
												fontWeight: "300",
												fontFamily: "Gotham",
											}}>
											Loading...
										</Text> */}

											<ActivityIndicator
												color={APP_COLOURS.TEXTCOLOR}
											/>
										</View>
									</View>
								</View>
							</Modal>
						) : null}
					</View>
				</View>

				{this.bottomTabs()}

				{this.state.addNewProject ? (
					<AddProject
						{...this.state}
						uniqueProjectTitles={this.state.uniqueProjectTitles.filter(
							(d) =>
								this.state.filterTab !== ""
									? d === this.state.filterTab
									: d,
						)}
						selectedProject={this.state.filterTab}
						addEntry={async (newProject) => {
							let user_data = JSON.parse(
								localStorage.getItem("user_data"),
							);

							sendDiscordMessage(
								`${user_data.first_name} ${user_data.last_name}`,
								`[New Project]
- Brand: ${newProject.brand.title}
- Title: ${newProject.title}
- Start: ${moment(newProject.start_date).format("DD/MM/YY")}
							`,
								`${window.location.origin}${
									Avatars[parseInt(user_data.avatar)].pp
								}`,
								"0QPZYrY6zpAnJXqX9gQl",
							);
							if (user_data.id !== "0QPZYrY6zpAnJXqX9gQl") {
								sendDiscordMessage(
									`${user_data.first_name} ${user_data.last_name}`,
									`[New Project]
- Brand: ${newProject.brand.title}
- Title: ${newProject.title}
- Start: ${moment(newProject.start_date).format("DD MMM YYYY")}
							`,
									`${window.location.origin}${
										Avatars[parseInt(user_data.avatar)].pp
									}`,
									user_data.id,
								);
							}

							crudCreate(
								this.state.crudDocumentName,
								newProject,
								async () => {
									await this.getAllProjectsUser();
									await this.setState((prevState) => ({
										projects: [
											...prevState.projects,
											newProject,
										],
										addNewProject: false,
										filterSearch: newProject.title,
									}));
								},
							);
						}}
						close={() =>
							this.setState({
								addNewProject: false,
							})
						}
					/>
				) : null}
			</View>
		);
	}

	bottomTabs = () => {
		return (
			<View
				style={{
					width: "100%",
					padding: 2,
					// display: "none",
				}}>
				<View
					style={{
						width: "100%",
						padding: 5,
						flexDirection: "row",
						borderRadius: 15,
						padding: 10,
						// backgroundColor: "#FFF",
						justifyContent: "space-evenly",
					}}>
					<TouchableOpacity
						style={{
							opacity:
								this.state.selectedPage === "Projects"
									? 1
									: 0.4,
						}}
						onPress={() => {
							this.setState({
								selectedProject: "",
								subPage: "",
								selectedPage: "Projects",
							});
						}}>
						<View
							style={{
								marginRight: 15,
								marginLeft: 15,

								alignItems: "center",
							}}>
							<AutoHeightImage
								width={19}
								source={require("../../../assets/images/icons/skrum_menu.png")}
							/>
							<Text
								style={{
									color: APP_COLOURS.TEXTCOLOR,
									fontSize: 11,
									fontWeight:
										!this.state.selectedPage === "Projects"
											? "600"
											: "300",
									fontFamily: "Gotham",
								}}>
								Projects
							</Text>
						</View>
					</TouchableOpacity>
					<TouchableOpacity
						style={{
							opacity:
								this.state.selectedPage === "Tasks" ? 1 : 0.4,
						}}
						onPress={() => {
							this.setState({
								selectedProject: "",
								subPage: "",
								selectedPage: "Tasks",
							});
						}}>
						<View
							style={{
								marginRight: 15,
								marginLeft: 15,

								alignItems: "center",
							}}>
							<AutoHeightImage
								width={19}
								source={require("../../../assets/images/icons/tasks.png")}
							/>
							<Text
								style={{
									color: APP_COLOURS.TEXTCOLOR,
									fontSize: 11,
									fontWeight:
										this.state.selectedPage === "Tasks"
											? "600"
											: "300",
									fontFamily: "Gotham",
								}}>
								Tasks
							</Text>
						</View>
					</TouchableOpacity>
					<TouchableOpacity
						style={{
							opacity:
								this.state.selectedPage === "Calendar"
									? 1
									: 0.4,
						}}
						onPress={() => {
							this.setState({
								selectedProject: "",
								subPage: "",
								selectedPage: "Calendar",
							});
						}}>
						<View
							style={{
								marginRight: 15,
								marginLeft: 15,

								alignItems: "center",
							}}>
							<AutoHeightImage
								width={19}
								source={require("../../../assets/images/icons/tasks.png")}
							/>
							<Text
								style={{
									color: APP_COLOURS.TEXTCOLOR,
									fontSize: 11,
									fontWeight:
										this.state.selectedPage === "Calendar"
											? "600"
											: "300",
									fontFamily: "Gotham",
								}}>
								Calendar
							</Text>
						</View>
					</TouchableOpacity>

					{/* <TouchableOpacity
						style={{
							marginRight: 10,
							marginLeft: 10,
						}}
						onPress={() => {
							let page =
								this.state.currentPage === "projects"
									? "gantt"
									: "projects";
							this.setState({
								currentPage: page,
							});
						}}>
						<View
							style={{
								padding: 10,
								backgroundColor: "#FFF",
								borderRadius: 15,
								minWidth: 150,
							}}>
							{this.state.currentPage === "gantt" ? (
								<View
									style={{
										marginRight: 15,
										marginLeft: 15,
										flexDirection: "row",
										alignItems: "center",
									}}>
									<AutoHeightImage
										width={25}
										source={require("../../../assets/images/icons/DevTracker.png")}
									/>
									<Text
										style={{
											color: APP_COLOURS.BG2,
											fontSize: 16,
											fontWeight: "800",
											fontFamily: "Gotham",
											// marginTop: 15,
											// marginTop: 6,
											marginLeft: 10,
										}}>
										Projects
									</Text>
								</View>
							) : (
								<View
									style={{
										marginRight: 15,
										marginLeft: 15,
										flexDirection: "row",
										alignItems: "center",
									}}>
									<AutoHeightImage
										width={25}
										source={require("../../../assets/images/icons/gantt_icon.png")}
									/>
									<Text
										style={{
											color: APP_COLOURS.BG2,
											fontSize: 16,
											fontWeight: "800",
											fontFamily: "Gotham",
											// marginTop: 15,
											// marginTop: 6,
											marginLeft: 10,
										}}>
										Gantt
									</Text>
								</View>
							)}
						</View>
					</TouchableOpacity> */}

					{/* <TouchableOpacity
						style={{
							marginRight: 15,
							marginLeft: 15,
						}}
						onPress={() => {
							this.setState({
								showTodayView:
									!this.state.showTodayView,
							});
						}}>
						<View
							style={{
								padding: 10,
								backgroundColor: "#FFF",
								borderRadius: 15,
							}}>
							{this.state.currentPage === "gantt" ? (
								<View
									style={{
										marginRight: 15,
										marginLeft: 15,
									}}>
									<AutoHeightImage
										width={25}
										source={require("../../../assets/images/icons/settings.png")}
									/>
								</View>
							) : (
								<View
									style={{
										marginRight: 15,
										marginLeft: 15,
									}}>
									<AutoHeightImage
										width={25}
										source={require("../../../assets/images/icons/settings.png")}
									/>
								</View>
							)}
						</View>
					</TouchableOpacity> */}
				</View>
			</View>
		);
	};

	projectsComponent = () => {
		let fs = 10;
		let size = fs * 1.8;
		return (
			<View
				style={{
					width: "100%",
					flex: 1,
					// height: this.props.screenHeight,
					justifyContent: "flex-start",
					// alignItems: "center",
					// backgroundColor: 'rgba(0, 0, 0, 0.9)',
					backgroundColor: "#FFF",
					padding: 2,
					borderRadius: 15,
					// width: 400,
					marginRight: 10,
				}}>
				<View
					style={{
						// flex: 1,
						width: "100%",
						flexDirection: "row",
						alignItems: "center",
					}}>
					<View
						style={{
							flex: 1,
							minWidth: 200,
							backgroundColor: "#E8E8E8",
							borderRadius: 13,
							flexDirection: "row",
							alignItems: "center",
							paddingLeft: 12,
						}}>
						<AutoHeightImage
							width={19}
							source={require("../../../assets/images/searchIcon.png")}
						/>
						<TextInput
							style={{
								flex: 1,
								fontWeight: "400",
								fontFamily: "Gotham",

								// width: "100%",
								paddingLeft: 15,
								padding: 10,
								fontSize: 15,
								borderRadius: 13,
								marginRight: 15,
								color: APP_COLOURS.BG2,
								// backgroundColor:
								// 	APP_COLOURS.WHITE_TRANSPARENT4,
								backgroundColor: "#E8E8E8",
								fontWeight: "500",
							}}
							placeholderTextColor={"#797979"}
							autoCapitalize='sentences'
							clearButtonMode='while-editing'
							autoCompleteType='off'
							editable={true}
							autoCorrect={true}
							keyboardType='default'
							textContentType='none'
							placeholder={` Search `}
							// defaultValue={this.state.user_data.email}
							defaultValue={this.state.filterSearch}
							onChangeText={(text) => {
								console.log(text);
								this.setState({
									filterSearch: text,
								});
							}}
						/>
					</View>

					{this.state.loading ? (
						<ActivityIndicator color={APP_COLOURS.PRIMARY} />
					) : null}

					<TouchableOpacity
						style={{
							// marginRight: 15,
							marginLeft: 15,
							borderRadius: 15,
							flexDirection: "row",
							paddingVertical: 8,
							alignItems: "center",
							paddingHorizontal: 15,
							borderWidth: 1,
							borderColor: APP_COLOURS.BG4,
						}}
						onPress={async () => {
							this.setState({
								addNewProject: !this.state.addNewProject,
							});
						}}>
						<Text
							style={{
								color: APP_COLOURS.BG2, // "#FFF",
								fontSize: 16,
								marginRight: 10,
							}}>
							+
						</Text>
						<Text
							style={{
								color: APP_COLOURS.BG2,
								fontSize: 16,
								fontWeight: "800",
								fontFamily: "Gotham",
							}}>
							New
						</Text>
					</TouchableOpacity>
				</View>
				<View
					style={{
						flex: 1,
						borderRadius: 5,
					}}>
					<View
						style={{
							marginVertical: 5,
						}}>
						<View
							style={
								{
									// display: this.state.showProjects
									// 	? "flex"
									// 	: "none",
								}
							}>
							<ScrollView
								horizontal
								showsHorizontalScrollIndicator={false}>
								<View
									style={{
										flexDirection: "row",
										// marginBottom: 5,
									}}>
									<TouchableOpacity
										style={{
											display:
												this.state.filterTab !== ""
													? "flex"
													: "none",
										}}
										onPress={() =>
											this.setState({
												filterTab: "",
												projects: this.state.projects,
											})
										}>
										<View
											style={{
												width: "auto",
												//  height: 50,
												justifyContent: "center",
												alignItems: "center",

												padding: 10,
												borderRadius: 12,
												marginRight: 5,
											}}>
											<Text
												style={{
													fontSize: fs,
													color: APP_COLOURS.BG2,
													fontWeight: "400",
													fontFamily: "Gotham",
												}}>
												All
											</Text>
										</View>
									</TouchableOpacity>
									{this.state.uniqueProjectTitles
										.filter((d_) => {
											let filter = d_;

											if (
												typeof this.state
													.filterSearch !==
													"undefined" &&
												this.state.filterSearch !== ""
											) {
												let text_ =
													this.state.filterSearch;
												var outString = text_.replace(
													/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
													"",
												);

												let join_names = `${d_}`;

												join_names = join_names.replace(
													/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
													"",
												);

												return join_names
													.toLowerCase()
													.includes(
														outString.toLowerCase(),
													);
											}

											return filter;
										})
										.map((d, i) => (
											<TouchableOpacity
												key={i}
												style={{}}
												onPress={() =>
													this.setState({
														filterTab: d,
														projects:
															this.state.projects,
													})
												}>
												<View
													style={{
														width: "auto",
														//   height: 50,
														justifyContent:
															"center",
														alignItems: "center",
														backgroundColor:
															this.state
																.filterTab === d
																? APP_COLOURS.BG3
																: APP_COLOURS.WHITE_TRANSPARENT9,
														padding: 10,
														borderRadius: 10,
														marginRight: 5,
													}}>
													<Text
														style={{
															fontSize: fs,
															color:
																this.state
																	.filterTab ===
																d
																	? "#FFF"
																	: APP_COLOURS.BG2,
															fontWeight: "500",
														}}>
														{d}
													</Text>
												</View>
											</TouchableOpacity>
										))}
								</View>
							</ScrollView>
						</View>
					</View>

					<View
						style={{
							flex: 1,
							width: "100%",
						}}>
						<ScrollView
							ref={(ref) => (this.scrollView2 = ref)}
							scrollEventThrottle={16}
							showsVerticalScrollIndicator={false}
							contentContainerStyle={{}}>
							<View
								style={{
									// flex: 1,
									width: "100%",
									paddingBottom: this.state.keyboardActive
										? 300
										: 300,
								}}>
								{this.projectSearchandReturn(
									this.state.projects.filter(
										(f) => f.status === "Urgent",
									),
									"Urgent",
								)}

								{this.projectSearchandReturn(
									this.state.projects.filter(
										(f) => f.status === "In Progress",
									),
									"In Progress",
								)}

								{this.projectSearchandReturn(
									this.state.projects.filter(
										(f) =>
											f.status ===
											"Under Review by client",
									),
									"Under Review by client",
								)}

								{this.projectSearchandReturn(
									this.state.projects.filter(
										(f) =>
											f.status !== "Completed" &&
											f.status !== "Urgent" &&
											f.status !== "Cancelled" &&
											f.status !== "Paused" &&
											f.status !== "In Progress" &&
											f.status !==
												"Under Review by client",
									),
									"All",
								)}
								{this.projectSearchandReturn(
									this.state.projects.filter(
										(f) => f.status === "Paused",
									),
									"Paused",
								)}
								{/* {this.projectSearchandReturn(
									this.state.projects.filter(
										(f) => f.status === "Completed",
									),
									"Completed",
								)} */}
								{this.projectSearchandReturn(
									this.state.projects.filter(
										(f) => f.status === "Cancelled",
									),
									"Cancelled",
								)}
							</View>
						</ScrollView>
					</View>

					{this.state.showChat ? (
						<View
							style={{
								position: "absolute",
								top: this.state.yPos + 10,
								left: this.state.xPos - 270,
								width: 250,
								height: 290,
								backgroundColor: APP_COLOURS.BG,
								borderRadius: 15,
								padding: 10,
								zIndex: 999,
								shadowColor: "#000",
								shadowOffset: {
									width: 0,
									height: 4,
								},
								shadowOpacity: 0.23,
								shadowRadius: 4.65,
								elevation: 8,
							}}>
							<View
								style={{
									flex: 1,
								}}>
								<ScrollView>
									<View
										style={{
											flex: 1,
										}}>
										{this.state.selectedChat.messages.map(
											(message, messageIndex) => (
												<View
													key={messageIndex}
													style={{
														padding: 2,
														marginBottom: 1,
													}}>
													<Text
														style={{
															color: APP_COLOURS.BG2,
															fontSize: fs,
														}}>
														<Text
															style={{
																color: APP_COLOURS.BLUE,
															}}>
															{
																message
																	.createdBy
																	.first_name
															}
															:
														</Text>{" "}
														{message.text}
													</Text>
												</View>
											),
										)}
									</View>
								</ScrollView>
								<View
									style={{
										// height: 50,
										flexDirection: "row",
										alignItems: "center",
										// padding: 10,
									}}>
									<TextInput
										style={{
											flex: 1,
											fontSize: 16,
											color: "#F3F3F5",
											fontWeight: "400",
											fontFamily: "Gotham",
											width: "100%",
											paddingLeft: 10,
											padding: 10,
											borderRadius: 7,
											borderColor: "#383D43",
											borderWidth: 1,
										}}
										onChangeText={(text) =>
											this.handleNewMessageChange(
												text,
												this.state.selectedID,
											)
										}
										value={
											this.state.selectedChat.newMessage
										}
										onSubmitEditing={() =>
											this.handleMessageSubmit(
												this.state.selectedID,
											)
										}
									/>
								</View>
							</View>
						</View>
					) : null}

					{this.state.showSubTasks ? (
						<View
							style={{
								position: "absolute",
								top: this.state.yPos + 10,
								left: this.state.xPos - 270,

								zIndex: 999,
							}}>
							<View
								style={{
									width: 250,
									height: 220,
									marginBottom: 5,
									borderRadius: 15,
									padding: 10,
									marginTop: 5,
									backgroundColor: APP_COLOURS.BG,
									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 4,
									},
									shadowOpacity: 0.23,
									shadowRadius: 4.65,
									elevation: 8,
								}}>
								<View
									style={{
										width: "100%",
										flexDirection: "row",
									}}>
									<View
										style={{
											width: "100%",
											flexDirection: "row",
											alignItems: "center",
											padding: 0,
										}}>
										<ProgressBar
											progress={`${this.state.selectedSubtasks.progress}%`}
											color={APP_COLOURS.GREEN}
											inProgress={
												parseFloat(
													this.state.selectedSubtasks
														.progress,
												) > 0
											}
										/>
									</View>
								</View>
								<View
									style={{
										flex: 1,
									}}>
									<ScrollView>
										<View
											style={{
												width: "100%",
												borderRadius: 13,
												backgroundColor:
													"rgba(52, 52, 52, 0.12)",
												// backgroundColor: APP_COLOURS.BG2,
												padding: 10,
												marginBottom: 25,
												opacity: 0.9,
											}}>
											{this.state.selectedSubtasks.subtasks.map(
												(s, ii) => {
													return (
														<CheckBox
															key={ii}
															title={s.title}
															fontSize={16}
															checked={s.status}
															checkBoxStyle={3}
															checkedItem={async () => {
																this.toggleSubtaskStatus(
																	s.id,
																	ii,
																	!s.status,
																);
															}}
															delete={() => {
																this.removeSubtask(
																	this.state
																		.selectedID,
																	s.id,
																);
															}}
														/>
													);
												},
											)}
										</View>
									</ScrollView>
								</View>
								<TaskerInput
									onTextChange={(txt) => {
										let obj = {
											title: txt,
											status: false,
											id: uuid(),
										};
										this.addSubtask(obj);
									}}
								/>
							</View>
						</View>
					) : null}

					{this.state.showModalArea !== "" ? (
						<Modal
							animationType='fade'
							transparent={true}
							visible={true}>
							<View
								style={{
									width: this.props.screenWidth,
									height: this.props.screenHeight,
									backgroundColor: "rgba(0, 0, 0, 0.75)",
									// justifyContent: "center",
									alignItems: "center",
								}}>
								<TouchableOpacity
									activeOpacity={1}
									style={{
										width: this.props.screenWidth,
										height: this.props.screenHeight,
										backgroundColor: "rgba(0, 0, 0, 0.75)",
										zIndex: 10,
										position: "absolute",
										top: 0,
									}}
									onPress={() => {
										this.setState({
											showModalArea: "",
										});
									}}
								/>
								{this.state.showModalArea ===
								"projectassignment" ? (
									<View
										style={{
											// top: this.state.yPos + 10,
											// left: this.state.xPos - 270,
											width: "100%",
											height: 300,
											// backgroundColor: APP_COLOURS.BG,
											backgroundColor: "#FFF",
											borderRadius: 15,
											padding: 10,
											zIndex: 999,
											shadowColor: "#000",
											shadowOffset: {
												width: 0,
												height: 4,
											},
											shadowOpacity: 0.23,
											shadowRadius: 4.65,
											elevation: 8,
										}}>
										<UsersSelectList
											assignment={
												this.state.selectedProject
													.assignment
											}
											assignment_ids={
												this.state.selectedProject
													.assignment_ids
											}
											isStandalone={true}
											addAssignment={(ass, ids) => {
												this.setState({
													showModalArea: "",
												});
												this.addAssignment(ass, ids);
												console.log(ass, ids);
											}}
										/>
									</View>
								) : null}
								{/* {this.state.showModalArea ===
								"taskassignment" ? (
									<View
										style={{
											top: this.state.yPos + 10,
											left: this.state.xPos - 270,
											width: 350,
											height: 300,
											// backgroundColor: APP_COLOURS.BG,
											backgroundColor: "#FFF",
											borderRadius: 15,
											padding: 10,
											zIndex: 999,
											shadowColor: "#000",
											shadowOffset: {
												width: 0,
												height: 4,
											},
											shadowOpacity: 0.23,
											shadowRadius: 4.65,
											elevation: 8,
										}}>
										<UsersSelectList
											assignment={
												this.state.selectedTask
													.assignment
											}
											assignment_ids={
												this.state.selectedTask
													.assignment_ids
											}
											isStandalone={true}
											addAssignment={(ass, ids) => {
												this.setState({
													showModalArea: "",
												});
												this.addAssignment(ass, ids);
												//  console.log(ass, ids);
											}}
										/>
									</View>
								) : null} */}
								{this.state.showModalArea === "dateChange" ? (
									<View
										style={{
											position: "absolute",
											// top: this.state.yPos + 10,
											// left: this.state.xPos - 270,
											width: 330,
											height: "auto",
											backgroundColor: "#20242B",
											borderRadius: 15,
											padding: 10,
											zIndex: 999,
											shadowColor: "#000",
											shadowOffset: {
												width: 0,
												height: 4,
											},
											shadowOpacity: 0.23,
											shadowRadius: 4.65,
											elevation: 8,
										}}>
										<ChangeDate
											date={
												this.state.selectedTask.due_date
											}
											changeDate={(date) => {
												this.setState({
													showModalArea: "",
												});
												this.handleDateChange(
													date,
													this.state.selectedID,
												);
											}}
											close={() =>
												this.setState({
													showModalArea: "",
												})
											}
										/>
									</View>
								) : null}
							</View>
						</Modal>
					) : null}

					{/* {this.state.showDateChange ? ( */}
					{/* <View
						style={{
							position: "absolute",
							top: this.state.yPos + 10,
							left: this.state.xPos - 350,
							width: 330,
							height: "auto",
							backgroundColor: "#20242B",
							borderRadius: 15,
							padding: 10,
							zIndex: 999,
							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 4,
							},
							shadowOpacity: 0.23,
							shadowRadius: 4.65,
							elevation: 8,
						}}>
						<ChangeDate
							date={this.state.selectedTask.due_date}
							changeDate={(date) => {
								this.setState({
									showDateChange: false,
								});
								this.handleDateChange(
									date,
									this.state.selectedID,
								);
							}}
							close={() =>
								this.setState({
									showDateChange: false,
								})
							}
						/>
					</View> */}
					{/* ) : null} */}

					{/* <View
						style={{
							// flex: 1,
							height: "100%",
							position: "absolute",
							right: this.state.showTodayView ? 0 : -450,
							top: 0,
							width: "100%",
							maxWidth: 400,
							backgroundColor: APP_COLOURS.BG,
							zIndex: 999,
							// display: this.state.addProjectTask ? 'flex' : 'none',
							borderTopLeftRadius: 15,
							borderTopRightRadius: 15,
						}}>
						{this.state.showTodayView ? (
							<TodayTimeline
								//   {...this.state}
								tasks={this.state.projects.filter((project) => {
									return moment(project.due_date).isSame(
										moment(),
										"day",
									);
								})}
								close={() =>
									this.setState({
										showTodayView: false,
									})
								}
							/>
						) : (
							<View
								style={{
									width: 300,
								}}>
								<View
									style={{
										flex: 1,
										padding: 10,
										backgroundColor: "#FFF",
										borderRadius: 14,
										// justifyContent: "space-between",
									}}>
									<Text
										style={{
											color: APP_COLOURS.BG2,
											fontSize: 19,
											fontWeight: "600",
											fontFamily: "Gotham",
											width: "100%",
											// textAlign: "center",e
										}}>
										Event Calendar
									</Text>
									<View
										style={{
											backgroundColor: "#FFF",
											padding: 10,
											borderRadius: 14,
										}}>
										<Image
											style={{
												width: 230,
												height: 230,
												resizeMode: "contain",
											}}
											source={require("../../../assets/images/market-analysis.png")}
										/>
									</View>

									<View
										style={{
											width: 280,
										}}>
										<EventCalendar
											compWidth={280}
											dateSelected={async (d) => {
												//   await this.setState({
												//     dateSelected: d,
												//     showFullCalendar: false,
												//   });
												//   await this.generateCalendar();
											}}
											calendarList={this.state.projects}
											selectedDate={moment()}
										/>
									</View>
								</View>
							</View>
						)}
					</View> */}
				</View>
			</View>
		);
	};

	projectSearchandReturn = (projects, title) => {
		let fs = 12;
		let size = fs * 1.8;
		let filteredprojects = projects
			.sort((a, b) => `${a.title} ${a.id}` > `${b.title} ${b.id}`)
			.sort((a, b) => b.priority - a.priority)

			.filter((d_) => {
				if (
					typeof this.state.filterTab !== "undefined" &&
					this.state.filterTab !== ""
				) {
					if (d_.brand?.title === this.state.filterTab) return d_;
				} else {
					return d_;
				}
			})
			.filter((d_) => {
				let filter = d_;

				if (
					typeof this.state.filterSearch !== "undefined" &&
					this.state.filterSearch !== ""
				) {
					let text_ = this.state.filterSearch;
					var outString = text_.replace(
						/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
						"",
					);

					let join_names = `${d_.title} ${d_.brand?.title}`;

					join_names = join_names.replace(
						/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
						"",
					);

					return join_names
						.toLowerCase()
						.includes(outString.toLowerCase());
				}

				return filter;
			});
		return (
			<View
				style={{
					marginBottom: 30,
					width: "100%",
					display: filteredprojects.length === 0 ? "none" : "flex",
				}}>
				<View
					style={{
						width: "100%",
						padding: 10,
						borderRadius: 8,
						backgroundColor: TaskStatus(title),
						flexDirection: "row",
					}}>
					<Text
						style={{
							fontSize: 30,
							color: APP_COLOURS.BG2,
							fontWeight: "600",
							fontFamily: "Gotham",
						}}>
						{filteredprojects.length}
					</Text>
					<Text
						style={{
							fontSize: fs,
							color: APP_COLOURS.BG2,
							fontWeight: "600",
							fontFamily: "Gotham",
						}}>
						{title}
					</Text>
				</View>
				{filteredprojects.map((d, i) =>
					this.projectLeftCardItem(d, i, fs, size),
				)}
			</View>
		);
	};

	projectLeftCardItem = (d, i, fs, size) => {
		let tasksForProject = this.state.tasks.filter(
			(t) => t.project_id === d.id,
		);

		return (
			<View
				key={`item_${d.id}`}
				onMouseEnter={() => this.setState({ hover: d.id })}
				onMouseLeave={() => this.setState({ hover: "" })}
				style={{
					width: "100%",
					backgroundColor:
						this.state.hover === d.id
							? APP_COLOURS.WHITE_TRANSPARENT5
							: isOdd(i)
							? "transparent"
							: APP_COLOURS.WHITE_TRANSPARENT6,

					// backgroundColor: "#FFF",

					opacity: d.status === "Completed" ? 0.3 : 1,
					// 	isOdd(i)
					// ? 0.9
					// : 1,
					zIndex: 10,
					// borderRadius: 10,
					// minHeight: 40,
					borderTopColor: "#E8E8E8",
					borderTopWidth: 0.5,

					borderLeftWidth:
						this.state.selectedProject?.id === d.id ? 5 : 0,

					// marginBottom: 10,
				}}>
				<View
					style={{
						width: "100%",
						flexDirection: "row",
						paddingLeft: 3,
						maxWidth: 550,
					}}>
					{/* <StatusItem
						{...this.props}
						{...d}
						fs={fs}
						handleStatusChange={(_id, _status) =>
							this.handleStatusChange(_id, _status)
						}
					/> */}
					<View
						style={{
							borderLeftWidth: 1,
							width: 0.5,
							opacity: 0.1,
						}}
					/>

					<View
						style={{
							borderLeftWidth: 1,
							width: 0.5,
							opacity: 0.1,
						}}
					/>

					<View
						style={{
							flex: 1,
						}}>
						<TouchableOpacity
							style={{
								flex: 1,
								// width: "100%",
								justifyContent: "center",

								// maxWidth: 1200,
								// minWidth: 250,
							}}
							onPress={async () => {
								await this.setState({
									selectedProject: "",
									selectedAssignment: "",
									subPage: "",
								});
								await this.setState({
									selectedProject: d,
									selectedAssignment: [...d.assignment],
									subPage: "Overview",
								});

								console.log(d);

								if (
									this.state.user_data.username !==
									"wesley@tetrice.co.za"
								) {
									let obj = {
										project_id: d.id,
										action: "Selected project",
										data: d.title,
										date_created: moment().toISOString(),
										created_by: `${this.state.user_data.first_name} ${this.state.user_data.last_name}`,
									};
									crudCreate("projects_log", obj, () => {});
								}
							}}>
							<View
								style={{
									flex: 1,
									padding: fs / 2,
									justifyContent: "center",
								}}>
								<Text
									ellipsizeMode='tail'
									numberOfLines={1}
									style={{
										color: APP_COLOURS.BG2,
										fontSize: 9,
										fontWeight: "500",
										opacity: 0.5,
									}}>
									{d.brand?.title}
								</Text>
								<Text
									ellipsizeMode='tail'
									numberOfLines={1}
									style={{
										width: "100%",
										color:
											this.state.hover === d.id
												? APP_COLOURS.BLUE2
												: APP_COLOURS.BG2,
										fontSize: 13,
										flexWrap: "wrap",
										fontWeight: "700",
									}}>
									{d.title}
								</Text>
							</View>
						</TouchableOpacity>
					</View>

					<View
						style={{
							borderLeftWidth: 1,
							width: 0.5,
							opacity: 0.1,
						}}
					/>

					<View
						style={{
							// flex: 1,
							flexDirection: "row",
							height: 20,
							borderRadius: 9,
							overflow: "hidden",

							width: 80,
							alignSelf: "center",
							marginHorizontal: 7,
						}}>
						{PROJECTSTATUS.map((p) => (
							<View
								style={{
									// minWidth: 10,
									flex: tasksForProject.filter(
										(t) => t.status === p.status,
									).length,
									backgroundColor: TaskStatus(p.status),
								}}
							/>
						))}
					</View>
					<View
						style={{
							borderLeftWidth: 1,
							width: 0.5,
							opacity: 0.1,
						}}
					/>

					{/* <TouchableOpacity
					onPress={(event) => {
						const layout = event.nativeEvent;
						let x = layout.pageX;
						let y = layout.pageY;
						this.setState({
							showSubTasks: false,
							showChat: false,
							showAssignment: false,
							showDateChange: true,
							showModalArea: "dateChange",
							xPos: x,
							yPos: y - 30,
							selectedTask: d,
							selectedID: d.id,
						});
					}}>
					<View
						style={{
							width: 120,
							padding: fs / 2,
						}}>
						<Text
							style={{
								color: APP_COLOURS.BG2,
								fontSize: fs,
							}}>
							{d.start_date !== ""
								? moment(d.start_date).format("DD MMM YYYY")
								: ""}
						</Text>
						<Text
							style={{
								color: APP_COLOURS.BG2,
								fontSize: fs * 0.6,
								opacity: 0.7,
							}}>
							{d.start_date !== ""
								? moment(d.start_date).fromNow()
								: ""}
						</Text>
					</View>
				</TouchableOpacity> */}

					{/* <TouchableOpacity
					onPress={(event) => {
						const layout = event.nativeEvent;
						let x = layout.pageX;
						let y = layout.pageY;
						this.setState({
							showSubTasks: false,
							showChat: false,
							showAssignment: false,
							showDateChange: true,
							showModalArea: "dateChange",
							xPos: x,
							yPos: y - 30,
							selectedTask: d,
							selectedID: d.id,
						});
					}}>
					<View
						style={{
							width: 120,
							padding: fs / 2,
						}}>
						<Text
							style={{
								color: APP_COLOURS.BG2,
								fontSize: fs,
							}}>
							{d.due_date !== ""
								? moment(d.due_date).format("DD MMM YYYY")
								: ""}
						</Text>
						<Text
							style={{
								color: APP_COLOURS.BG2,
								fontSize: fs * 0.6,
								opacity: 0.7,
							}}>
							{d.due_date !== ""
								? moment(d.due_date).fromNow()
								: ""}
						</Text>
					</View>
				</TouchableOpacity> */}

					{/* {this.state.showEdit === d.id ? (
						<TouchableOpacity
							onPress={() => {
								// this.deleteProject(d.id);
								crudDelete(
									this.state.crudDocumentName,
									d,
									() => {
										this.getAllProjectsUser();
										this.setState({
											showEdit: "",
										});
									},
								);
							}}
							style={{
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
								width: fs * 1.5,
								height: fs * 1.5,

								marginLeft: 5,
								marginRight: 10,
							}}>
							<Image
								source={bin}
								style={{
									width: fs * 1.5,
									height: fs * 1.5,
									resizeMode: "contain",
									marginRight: 5,
								}}
							/>
						</TouchableOpacity>
					) : (
						<TouchableOpacity
							onPress={() => {
								this.setState({
									showEdit: d.id,
								});
							}}>
							<View
								style={{
									justifyContent: "center",
									alignItems: "center",
									marginLeft: 5,
									width: fs * 1.5,
									height: fs * 1.5,
									marginRight: 10,
									padding: fs / 2,
									opacity: 0.3,
								}}>
								<View
									style={{
										width: fs * 1.5,
										height: fs * 1.5,
										borderRadius: 4,
										borderWidth: 1,
										bordercolor: APP_COLOURS.BG2,
									}}
								/>
							</View>
						</TouchableOpacity>
					)} */}
				</View>
			</View>
		);
	};

	projectComponent = (d) => {
		let fs = 14;
		let size = fs * 1.8;
		return (
			<View
				style={{
					flex: 1,
					backgroundColor: "#FFF",
					padding: 5,
					borderRadius: 13,
					width: "100%",
				}}>
				<View
					style={{
						width: "100%",
						// flexDirection: "row",

						// marginLeft: 5,
						marginBottom: 10,
					}}>
					<View
						style={{
							width: "100%",
							// opacity: 0.8,
							// marginRight: 20,
							alignItems: "center",

							paddingLeft: 3,
							flexDirection: "row",
						}}>
						<TouchableOpacity
							onPress={async () => {
								this.setState({
									selectedProject: "",
								});
							}}>
							<Image
								style={{
									width: 30,
									height: 30,
									resizeMode: "contain",
									marginRight: 10,
								}}
								source={require("../../../assets/images/close.png")}
							/>
						</TouchableOpacity>
						{/* <View
							style={{
								flex: 1,
							}}
						/> */}
						<View
							style={{
								flex: 1,
								flexDirection: "row",
								// backgroundColor: "red",
							}}>
							<View
								style={{
									flex: 1,
								}}
							/>
							<TouchableOpacity
								onPress={(event) => {
									const layout = event.nativeEvent;
									let x = layout.pageX;
									let y = layout.pageY;
									console.log("assignment");
									this.setState({
										// showSubTasks: false,
										// showChat: false,
										// showAssignment: !this.state.showAssignment,
										showModalArea: "projectassignment",

										xPos: x,
										yPos: y - 30,
										// selectedTask: d,
										selectedID: d.id,

										selectedProject: d,
									});
								}}
								style={{
									justifyContent: "center",
									// backgroundColor: "#E8E8E8",
								}}>
								<View
									style={{
										// width: 150,
										alignItems: "flex-end",
										paddingRight: 3,
										// alignItems: "center",
										// backgroundColor: "#252525",
										// padding: fs / 2,
									}}>
									{d.assignment.filter(
										(d) => d.id !== this.state.user_data.id,
									).length < 1 ? (
										<View
											style={{
												//  flex: 1,
												//  backgroundColor: '#252525',
												justifyContent: "center",
												alignItems: "center",
												opacity: 0.4,
												minHeight: 35,
												minWidth: 80,
											}}>
											<Text
												style={{
													color: APP_COLOURS.BG2,
													fontSize: fs,
												}}>
												+ Assign to project
											</Text>
										</View>
									) : (
										<View
											style={{
												// width: 200,
												//  flexDirection: 'row-reverse',
												//  alignItems: 'flex-end',
												flexDirection: "row",
												alignItems: "center",
												// marginRight: 20,
												//  alignItems: 'center',
												// display: 'none',
												//  maxWidth: 120,
												// width:
												// 	d.assignment.length > 5
												// 		? 4 * (size / 2)
												// 		: d.assignment
												// 				.length *
												// 		  (size / 2),
											}}>
											{d.assignment
												.filter(
													(d) =>
														d.id !==
														this.state.user_data.id,
												)
												.slice(0, 3)
												.map((ass, i) => {
													return (
														<View
															style={{
																marginHorizontal: 2,
															}}>
															<View
																key={i}
																style={{
																	flexDirection:
																		"row",
																	justifyContent:
																		"center",
																	alignItems:
																		"center",
																	backgroundColor:
																		"#FFF",
																	borderWidth: 2,
																	borderColor:
																		"#FFF",
																	width:
																		size *
																		1.3,
																	height:
																		size *
																		1.3,
																	borderRadius:
																		size /
																		1.3,
																	shadowColor:
																		"#6494B7",
																	shadowOffset:
																		{
																			width: 0,
																			height: 4,
																		},
																	shadowOpacity: 0.23,
																	shadowRadius: 4.65,
																	elevation: 8,
																	// left:
																	// 	(size /
																	// 		2.2) *
																	// 	-i,
																	// position: "absolute",
																	// top: 0,
																	zIndex:
																		i + 1,
																}}>
																<Image
																	// source={avatars[parseInt(ass.avatar)]}
																	source={
																		Avatars[
																			parseInt(
																				ass.avatar,
																			)
																		].pp
																	}
																	style={{
																		width:
																			size *
																			1.35,
																		height:
																			size *
																			1.35,
																		borderRadius:
																			size /
																			1.3,
																		resizeMode:
																			"cover",
																		backgroundcolor:
																			APP_COLOURS.BG2,
																	}}
																/>
															</View>
															<Text
																style={{
																	color: APP_COLOURS.BG2,
																	fontSize: 8,
																}}>
																{ass.first_name}
															</Text>
														</View>
													);
												})}
											{/* {d.assignment.length > 5 ? (
													<View
														style={{
															backgroundColor:
																"#252525",
															flexDirection:
																"row",
															justifyContent:
																"center",
															alignItems:
																"center",
															//   position: 'absolute',
															right: size / -2,
															width: size,
															height: size,

															borderRadius: 15,
														}}>
														<Text
															style={{
																color: APP_COLOURS.BG2,
																fontSize: fs,
															}}>
															+
															{d.assignment
																.length - 5}
														</Text>
													</View>
												) : null} */}
										</View>
									)}
								</View>
							</TouchableOpacity>

							<StatusItem
								{...this.props}
								{...d}
								fs={fs}
								handleStatusChange={async (_id, _status) => {
									let user_data = JSON.parse(
										localStorage.getItem("user_data"),
									);
									// 									sendDiscordMessage(
									// 										`${user_data.first_name} ${user_data.last_name}`,
									// 										`[PROJECT Status Update]
									// - ${_status}
									// - ${d.title}
									// `,
									// 										`${window.location.origin}${
									// 											Avatars[parseInt(user_data.avatar)].pp
									// 										}`,
									// 										"0QPZYrY6zpAnJXqX9gQl",
									// 									);

									const mapThrough =
										await d.assignment_ids.forEach(
											async (id) => {
												await sendDiscordMessage(
													`${user_data.first_name} ${user_data.last_name}`,
													`[PROJECT Status Update]
### *${d.brand.title} - ${d.title}*
> *${_status}*
`,
													`${window.location.origin}${
														Avatars[
															user_data.avatar
														].pp
													}`,
													id,
												);
											},
										);

									this.handleStatusChange(_id, _status);
								}}
							/>
						</View>
					</View>
					<Text
						style={{
							color: APP_COLOURS.BG2,
							opacity: 0.8,
							fontSize: fs,
							fontWeight: "700",
							position: "absolute",
							top: 10,
							left: 40,
						}}>
						{d.brand?.title}
					</Text>
					<View
						style={{
							flex: 1,
							padding: 5,
							flexDirection: "row",
							borderBottomColor: "#E8E8E8",
							borderBottomWidth: 0.5,
							marginBottom: 4,
						}}>
						<TextInput
							returnKeyType='done'
							autoCorrect={false}
							style={{
								color: APP_COLOURS.BG2, // '#5A7BBA'
								fontSize: fs + 5,
								fontWeight: "700",
								marginBottom: 6,
								width: "100%",
							}}
							defaultValue={d.title}
							editable={true}
							onChangeText={(text) =>
								this.setState({
									changedTitle: text,
									needToUpdateProject: true,
								})
							}
							autoCapitalize='sentences'
							placeholder='TItle'
							clearButtonMode='while-editing'
						/>

						{this.state.needToUpdateProject ? (
							<TouchableOpacity
								style={{
									// marginRight: 15,
									marginLeft: 10,
									// backgroundColor: "#FFF",
									backgroundColor: APP_COLOURS.GREEN,
									borderRadius: 15,
									flexDirection: "row",
									paddingVertical: 12,
									alignItems: "center",
									paddingHorizontal: 15,
									// borderWidth: 0.5,
									// borderColor: APP_COLOURS.BG2,
								}}
								onPress={async () => {
									let obj = {
										...this.state.selectedProject,
										title: this.state.changedTitle,
										description:
											this.state.changedDescription,
									};

									if (this.state.changedDescription === "") {
										delete obj.description;
									}

									let user_data = JSON.parse(
										localStorage.getItem("user_data"),
									);
									sendDiscordMessage(
										`${user_data.first_name} ${user_data.last_name}`,
										`[Project Update] 
New title: ${obj.title}`,
										`${window.location.origin}${
											Avatars[parseInt(user_data.avatar)]
												.pp
										}`,
										"0QPZYrY6zpAnJXqX9gQl",
									);

									await this.updateProjectItem(obj);
									await this.createLog("update", obj.title);
									await this.setState({
										needToUpdateProject: false,
										changedTitle: "",
										changedDescription: "",
									});
								}}>
								<Text
									style={{
										color: "#FFF",
										fontSize: 13,
									}}>
									Save changes
								</Text>
							</TouchableOpacity>
						) : null}
					</View>
				</View>

				<View
					style={{
						flex: 1,
						flexDirection: "row",
					}}>
					<View
						style={{
							flex: 1.5,
						}}>
						<View
							style={{
								flex: 1,
								flexDirection: "row",
								backgroundColor: "#FFF",
								borderRadius: 13,
							}}>
							<View
								style={{
									flex: 1,
									backgroundColor: "#FFF",
									borderRadius: 13,
								}}>
								{/* <View
								style={{
									width: "100%",
									marginVertical: 15,
									backgroundColor: "#FFF",
								}}>
								<ProgressBar
									progress={`${
										(this.state.tasks.filter(
											(t) =>
												t.project_id === d.id &&
												t.status === "Completed",
										).length /
											this.state.tasks.filter(
												(t) => t.project_id === d.id,
											).length) *
										100
									}%`}
									inProgress={true}
									color={APP_COLOURS.GREEN}
								/>
							</View> */}

								<SmallerTabs
									selectedSubPage={(d) => {
										this.setState({
											subPage: d,
										});
										console.log(d);
									}}
									tabs={[
										"Overview",
										"Documents",
										"Tasks",
										"Chat",

										"Assignment",
									]}
								/>

								{this.state.subPage === "Overview" ? (
									<View
										style={{
											flex: 1,
										}}>
										<View
											style={{
												flex: 1,
												// padding: 5,
											}}>
											<ScrollView>
												<View
													style={{
														// padding: 15,
														// borderWidth: 0.5,
														borderRadius: 5,
														padding: 5,
													}}>
													{/* <TextEditorComp
														defaultvalue={
															d.description
														}
														exportHtml={(html) => {
															// console.log(html);

															this.setState({
																changedDescription:
																	html,
																needToUpdateDescription: true,
															});
														}}
													/> */}

													<DescriptionQuillEditor
														presentation_id={d.id}
														pageData={d.description}
														reloadData={(
															html,
														) => {}}
														lockContent={true}
													/>
												</View>
											</ScrollView>
											{/* {this.state
												.needToUpdateDescription ? (
												<TouchableOpacity
													style={{
														// marginRight: 15,
														marginLeft: 10,
														// backgroundColor: "#FFF",
														backgroundColor:
															APP_COLOURS.GREEN,
														borderRadius: 15,
														flexDirection: "row",
														paddingVertical: 12,
														alignItems: "center",
														paddingHorizontal: 15,
														// borderWidth: 0.5,
														// borderColor: APP_COLOURS.BG2,
														justifyContent:
															"center",
													}}
													onPress={async () => {
														let obj = {
															...this.state
																.selectedProject,

															description:
																this.state
																	.changedDescription,
														};
														await this.updateProjectItem(
															obj,
														);
														await this.createLog(
															"update",
															obj.description,
														);
														await this.setState({
															needToUpdateProject: false,
															changedTitle: "",
															changedDescription:
																"",
														});
													}}>
													<Text
														style={{
															color: "#FFF",
															fontSize: 13,
														}}>
														Save description
													</Text>
												</TouchableOpacity>
											) : null} */}
										</View>
									</View>
								) : null}

								{this.state.subPage === "Documents" ? (
									<ProjectDocuments
										{...this.state}
										project_id={
											this.state.selectedProject.id
										}
									/>
								) : null}

								{this.state.subPage === "Assignment" ? (
									<View
										style={{
											flex: 1,
										}}>
										<View
											style={{
												padding: 15,
											}}>
											<Text
												style={{
													color: APP_COLOURS.BG2, // '#5A7BBA'
													fontSize: 12,
													fontWeight: "300",
													marginBottom: 6,
													opacity: 0.5,
												}}>
												Assignment:
											</Text>

											<View
												style={{
													paddingRight: 3,
													// alignItems: "center",
													// backgroundColor: "#252525",
													// padding: fs / 2,
												}}>
												{d.assignment.length < 1 ? (
													<View
														style={{
															//  flex: 1,
															//  backgroundColor: '#252525',
															justifyContent:
																"center",

															opacity: 0.4,
														}}>
														<Text
															style={{
																color: APP_COLOURS.BG2,
																fontSize: fs,
															}}>
															+ Assignment
														</Text>
													</View>
												) : (
													<View style={{}}>
														{d.assignment.map(
															(ass, i) => {
																return (
																	<View
																		key={i}
																		style={{
																			flexDirection:
																				"row",

																			alignItems:
																				"center",
																		}}>
																		<Image
																			// source={avatars[parseInt(ass.avatar)]}
																			source={
																				Avatars[
																					parseInt(
																						ass.avatar,
																					)
																				]
																					.pp
																			}
																			style={{
																				width: size,
																				height: size,
																				borderRadius:
																					size /
																					2,
																				resizeMode:
																					"contain",
																				backgroundcolor:
																					APP_COLOURS.BG2,
																			}}
																		/>
																		<Text
																			style={{
																				color: APP_COLOURS.BG2,
																				fontSize:
																					fs -
																					2,
																			}}>
																			{
																				ass.first_name
																			}{" "}
																			{
																				ass.last_name
																			}
																		</Text>
																	</View>
																);
															},
														)}
													</View>
												)}
											</View>
										</View>
									</View>
								) : null}

								{/* {this.state.subPage === "Log" ? (
									<View
										style={{
											flex: 1,
											padding: 5,
										}}>
										<ActivityLog projectID={d.id} />
									</View>
								) : null} */}

								{/* {this.state.subPage === "Edit" ? (
									<View
										style={{
											flex: 1,
										}}>
										<View
											style={{
												padding: 15,
											}}>
											<Text
												style={{
													color: APP_COLOURS.BG2, // '#5A7BBA'
													fontSize: 12,
													fontWeight: "300",
													marginBottom: 6,
													opacity: 0.5,
												}}>
												Edit project:
											</Text>
											<View
												style={{
													flex: 1,
												}}>
												<TouchableOpacity
													onPress={() => {
														// this.deleteProject(d.id);
														console.log(d);
														return;
														crudDelete(
															this.state
																.crudDocumentName,
															d,
															() => {
																this.getAllProjectsUser();
																this.setState({
																	showEdit:
																		"",
																});
															},
														);

														crudDelete(
															this.state
																.crudDocumentName,
															d,
															() => {
																this.getAllProjectsUser();
																this.setState({
																	showEdit:
																		"",
																});
															},
														);
													}}
													style={{
														flexDirection: "row",
														justifyContent:
															"center",
														alignItems: "center",
														padding: 15,

														marginLeft: 5,
														marginRight: 10,
														backgroundColor:
															APP_COLOURS.RED2,
														borderRadius: 10,
													}}>
													<Text
														style={{
															color: "#FFF",
															marginRight: 10,
															fontWeight: "500",
														}}>
														Delete project and
														associated data
													</Text>
													<Image
														source={bin}
														style={{
															width: fs * 1.5,
															height: fs * 1.5,
															resizeMode:
																"contain",
															marginRight: 5,
														}}
													/>
												</TouchableOpacity>
											</View>
										</View>
									</View>
								) : null} */}

								{this.state.subPage === "Chat" ? (
									<View
										style={{
											flex: 1,
											// marginLeft: 5,
											backgroundColor: "#FFF",
											// padding: 5,
											borderRadius: 13,
											// marginLeft: 10,
										}}>
										<ChatBox
											chatTitle={d.title}
											project_id={d.id}
											selectedProject={
												this.state.selectedProject
											}
											user_data={this.state.user_data}
										/>
									</View>
								) : null}

								{this.state.subPage === "Tasks" ? (
									<View
										style={{
											flex: 1,
											// marginLeft: 5,
											backgroundColor: "#FFF",
											padding: 5,
											borderRadius: 13,
										}}>
										<Text
											style={{
												color: APP_COLOURS.BG2,
												fontSize: 16,
												fontWeight: "900",
												marginBottom: 6,
											}}>
											Tasks
										</Text>
										{/* <View
											style={{
												width: "100%",
												marginVertical: 15,
												backgroundColor: "#FFF",
											}}>
											<ProgressBar
												progress={`${
													(this.state.tasks.filter(
														(t) =>
															t.project_id ===
																d.id &&
															t.status ===
																"Completed",
													).length /
														this.state.tasks.filter(
															(t) =>
																t.project_id ===
																d.id,
														).length) *
													100
												}%`}
												inProgress={true}
												color={APP_COLOURS.GREEN}
											/>
										</View> */}
										{this.state.selectedTask !== "" ? (
											<View
												style={{
													flex: 1,
													// backgroundColor: APP_COLOURS.BG2,
												}}>
												<EditTaskMobile
													d={this.state.selectedTask}
													milestoneData={d}
													selectedProject={d}
													reloadData={() =>
														this.getAllProjectTasks()
													}
													close={() => {
														this.setState({
															selectedTask: "",
														});
													}}
												/>
												{/* <DailyPlanner
									user_data={this.state.user_data}
									title={d.title}
									category={"wes"}
								/> */}
											</View>
										) : (
											<View
												style={{
													flex: 1,

													maxWidth: 850,
													width: "100%",
													marginHorizontal: "auto",
													// padding: 5,
												}}>
												<ScrollView>
													{this.state.tasks
														.filter(
															(t) =>
																t.project_id ===
																d.id,
														)
														.sort(
															(a, b) =>
																moment(
																	a.start_date,
																).unix() >
																moment(
																	b.start_date,
																).unix(),
														)
														.map((task, ii) =>
															this.taskItem(
																task,
																ii,
																fs,
																size,
															),
														)}
													<View
														style={{
															height: 40,
														}}
													/>
												</ScrollView>

												<TouchableOpacity
													style={{
														width: "100%",

														// backgroundColor: "#FFF",
														backgroundColor:
															APP_COLOURS.BLUE2,
														borderRadius: 15,
														flexDirection: "row",
														paddingVertical: 12,
														alignItems: "center",
														paddingHorizontal: 15,
														borderWidth: 0.5,
														borderColor:
															APP_COLOURS.BG2,
														justifyContent:
															"center",
														alignContent: "center",
													}}
													onPress={async () => {
														this.setState({
															addProjectTask: true,
														});
													}}>
													<Text
														style={{
															color: "#FFF",
															fontSize: 14,
														}}>
														+ New task
													</Text>
												</TouchableOpacity>
											</View>
										)}
									</View>
								) : null}

								{/* {this.state.subPage === "Documents" ? (
									<View
										style={{
											flex: 1,
											padding: 5,
										}}></View>
								) : null} */}
							</View>
						</View>
					</View>

					{/* <View
						style={{
							flex: 1,
							// marginLeft: 5,
							backgroundColor: "#FFF",
							padding: 5,
							borderRadius: 13,
							marginLeft: 10,
						}}>
						<ChatBox
							project_id={d.id}
							user_data={this.state.user_data}
						/>
					</View> */}

					{this.state.addProjectTask ? (
						<AddTask
							{...this.state}
							project_id={d.id}
							selectedProject={d}
							close={() =>
								this.setState({
									addProjectTask: false,
								})
							}
							reloadData={() => this.getAllProjectTasks()}
						/>
					) : null}
				</View>
			</View>
		);
	};

	// saveupdateItem = (d) => {
	// 	// console.log(d);
	// 	// return;
	// 	clearTimeout(this._timeout);
	// 	this._timeout = setTimeout(async () => {
	// 		this.setState({
	// 			loading: true,
	// 		});

	// 		crudUpdate(`projects_tasks`, d, (status, d) => {
	// 			// console.log(status, d);
	// 			console.log("saveupdateItem");
	// 			if (status === 200) {
	// 				this.props.reloadData();
	// 			}
	// 		});
	// 	}, 1500);
	// 	// return;
	// };

	allTasksComp = (fs, size) => {
		let overdueTasks = this.state.tasks;
		let currentTasks;

		let allTasks = this.state.tasks
			.sort(
				(a, b) =>
					moment(a.start_date).unix() > moment(b.start_date).unix(),
			)
			.sort((a, b) => a.title > b.title);

		let mainTask = allTasks[0];
		return (
			<View
				style={{
					flex: 1,
					justifyContent: "flex-start",

					padding: 5,
					// borderRadius: 15,
					// marginRight: 10,
					width: "100%",
					// marginTop: 43,
					// backgroundColor: APP_COLOURS.BG2,
					backgroundColor: "#FFF",
					padding: 5,
				}}>
				{/* <Pie percentage={60} color={} /> */}
				<View style={{}}>{/* <Text>{mainTask.title}</Text> */}</View>
				<View
					style={{
						width: "100%",
						flexDirection: "row",
						height: 50,
						borderRadius: 12,
						overflow: "hidden",
						marginBottom: 15,
					}}>
					{PROJECTSTATUS.map((p) => {
						let total =
							(allTasks.filter((t) => t.status === p.status)
								.length /
								allTasks.length) *
							100;

						if (total > 0)
							return (
								<View
									style={{
										flex: allTasks.filter(
											(t) => t.status === p.status,
										).length,
										backgroundColor: TaskStatus(p.status),
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Text
										style={{
											fontSize: 16,
										}}>
										{total.toFixed(0)}%
									</Text>
								</View>
							);
					})}
				</View>
				<Text
					style={{
						color: APP_COLOURS.BG2, // '#5A7BBA'
						fontSize: 14,
						fontWeight: "700",
						// marginVertical: 10,
						width: "100%",
						textAlign: "right",
					}}>
					{moment().format("ddd DD MMM YYYY")}
				</Text>
				<SmallerTabs
					selectedSubPage={(d) => {
						this.setState({
							filterTasks: d === "All" ? "" : d,
						});
						console.log(d);
					}}
					tabs={[
						"All",
						"Urgent",
						"pending",
						"In Progress",
						"Copy",
						"Design",
						"Completed",
					]}
				/>
				<ScrollView
					contentContainerStyle={
						{
							// paddingLeft: 10,
						}
					}>
					{/* <Text
						style={{
							color: APP_COLOURS.WHITE_TRANSPARENT9, // '#5A7BBA'
							fontSize: 12,
							fontWeight: "300",
							marginBottom: 6,
							// opacity: 0.5,
							// marginVertical: 25,
						}}>
						Overdue
					</Text> */}

					{allTasks
						.filter((t) => t.status !== "Completed")
						.filter((t) => {
							if (this.state.filterTasks !== "") {
								return t.status === this.state.filterTasks;
							} else {
								return t;
							}
						})
						.filter((t) =>
							moment().isSameOrAfter(moment(t.end_date)),
						)
						.map((task, ii) => this.taskItem(task, ii, fs, size))}
					{/* <Text
						style={{
							color: APP_COLOURS.WHITE_TRANSPARENT9, // '#5A7BBA'
							fontSize: 12,
							fontWeight: "300",
							marginBottom: 6,
							// opacity: 0.5,
							marginVertical: 25,
						}}>
						Due
					</Text> */}

					{/* {allTasks
						.filter((t) => t.status !== "Completed")
						.filter(
							(t) =>
								moment(t.start_date).unix() >= moment().unix(),
						)
						.map((task, ii) => this.taskItem(task, ii, fs, size))} */}

					<View
						style={{
							display:
								this.state.filterTasks === "Completed"
									? "flex"
									: "none",
						}}>
						<Text
							style={{
								color: APP_COLOURS.BG2, // '#5A7BBA'
								fontSize: 12,
								fontWeight: "300",
								marginBottom: 6,
								// opacity: 0.5,
								marginVertical: 25,
							}}>
							Completed
						</Text>

						{allTasks
							.filter((t) => t.status === "Completed")
							.map((task, ii) =>
								this.taskItem(task, ii, fs, size),
							)}
					</View>

					<View
						style={{
							height: 40,
						}}
					/>
				</ScrollView>
			</View>
		);
	};

	taskItem = (d, i, fs, size) => {
		return (
			<View
				key={`task_${d.id}`}
				style={{
					width: "100%",
					// backgroundColor: isOdd(i)
					// 	? "transparent"
					// 	: APP_COLOURS.WHITE_TRANSPARENT6,
					backgroundColor: TaskStatus(d.status),
					// flexDirection: "row",
					// alignItems: "center",
					zIndex: 10,
					// borderRadius: 25,
					opacity: d.status === "Completed" ? 0.3 : 1,
					paddingLeft: 3,
					marginBottom: 1,
					overflow: "hidden",
					// borderTopColor: "#E8E8E8",
					// borderTopWidth: 0.5,

					// justifyContent: "center",
					alignItems: "center",
					padding: 5,
				}}>
				<View
					style={{
						flexDirection: "row",
						width: "100%",
					}}>
					<View
						style={{
							borderRightWidth: 0.5,
						}}>
						<View
							style={{
								flexDirection: "row",
								justifyContent: "space-evenly",
							}}>
							<Text
								ellipsizeMode='tail'
								style={{
									// width: "100%",

									color:
										this.state.hover === d.id
											? APP_COLOURS.BLUE2
											: APP_COLOURS.BG2,
									fontSize: 12,
									// flexWrap: "wrap",
								}}>
								{moment(d.start_date)
									.endOf("day")
									.format("DD/MM")}{" "}
								-
							</Text>
							<Text
								ellipsizeMode='tail'
								style={{
									// width: "100%",

									color:
										this.state.hover === d.id
											? APP_COLOURS.BLUE2
											: APP_COLOURS.BG2,
									fontSize: 12,
									// flexWrap: "wrap",
								}}>
								{moment(d.end_date).format("DD/MM")}
							</Text>
						</View>
						<StatusItem
							{...this.props}
							{...d}
							fs={fs + 2}
							handleStatusChange={async (_id, _status) => {
								let user_data = JSON.parse(
									localStorage.getItem("user_data"),
								);
								// 								sendDiscordMessage(
								// 									`${user_data.first_name} ${user_data.last_name}`,
								// 									`[Task Status Update]
								// - ${_status}
								// - ${d.title}
								// `,
								// 									`${window.location.origin}${
								// 										Avatars[parseInt(user_data.avatar)].pp
								// 									}`,
								// 									"0QPZYrY6zpAnJXqX9gQl",
								// 								);

								let selectedProject =
									this.state.projects.filter(
										(f) => f.id === d.project_id,
									);
								// const mapThrough = await d.assignment.forEach(
								// 	async (e) => {
								// 		await crudSendEmail(
								// 			"SKrum  <skrum@tetrice.co.za>",
								// 			e.username,
								// 			`[Task status update]: ${selectedProject[0].title}`,
								// 			`Status update <p>${selectedProject[0].title}</p> <br> <p> ${_status}</p><br>

								// `,
								// 		);
								// 	},
								// );

								const mapThrough =
									await d.assignment_ids.forEach(
										async (id) => {
											await sendDiscordMessage(
												`${user_data.first_name} ${user_data.last_name}`,
												`### *${selectedProject[0].brand.title} - ${selectedProject[0].title}*
[Task Status] - *${_status}*
> ${d.title}
`,
												`${window.location.origin}${
													Avatars[
														parseInt(
															user_data.avatar,
														)
													].pp
												}`,
												id,
											);
										},
									);

								crudUpdate(
									"projects_tasks",
									{
										id: _id,
										status: _status,
									},
									() => {
										this.getAllProjectTasks();
									},
								);
							}}
						/>
						<View
							style={{
								flexDirection: "row",
								justifyContent: "center",
								marginTop: 15,
								width: "100%",
							}}>
							<View style={{ flex: 1 }} />
							<TouchableOpacity
								onPress={(event) => {
									const layout = event.nativeEvent;
									let x = layout.pageX;
									let y = layout.pageY;
									console.log("taskassignment");
									this.setState({
										// showSubTasks: false,
										// showChat: false,
										// showAssignment: !this.state.showAssignment,
										showModalArea: "taskassignment",

										xPos: x,
										yPos: y - 30,
										selectedTask: d,
										selectedID: d.id,

										// selectedPage: "Projects",
									});
								}}>
								<View
									style={{
										width: 90,
										// backgroundColor: '#252525',
										// padding: fs / 2,
										justifyContent: "center",
									}}>
									{d.assignment.length < 1 ? (
										<View
											style={{
												//  flex: 1,
												//  backgroundColor: '#252525',
												justifyContent: "center",
												alignItems: "center",
												opacity: 0.4,
											}}>
											<Text
												style={{
													color: APP_COLOURS.BG2,
													fontSize: fs * 0.7,
												}}>
												+ Assignment
											</Text>
										</View>
									) : (
										<View
											style={{
												// width: 200,
												//  flexDirection: 'row-reverse',
												//  alignItems: 'flex-end',
												flexDirection: "row",
												// marginRight: 20,
												//  alignItems: 'center',
												// display: 'none',
												//  maxWidth: 120,
												width:
													d.assignment.length > 5
														? 4 * (size / 2)
														: d.assignment.length *
														  (size / 2),
											}}>
											{d.assignment
												.filter(
													(d) =>
														d.id !==
														this.state.user_data.id,
												)
												.slice(0, 5)
												.map((ass, i) => {
													return (
														<View
															key={i}
															style={{
																flexDirection:
																	"row",
																justifyContent:
																	"center",
																alignItems:
																	"center",
																backgroundColor:
																	"#FFF",
																borderWidth: 2,
																borderColor:
																	"#FFF",
																width: size,
																height: size,
																borderRadius:
																	size / 2,
																shadowColor:
																	"#6494B7",
																shadowOffset: {
																	width: 0,
																	height: 4,
																},
																shadowOpacity: 0.23,
																shadowRadius: 4.65,
																elevation: 8,
																left:
																	(size /
																		2.2) *
																	-i,
																// position: "absolute",
																// top: 0,
																zIndex: i + 1,
															}}>
															<Image
																// source={avatars[parseInt(ass.avatar)]}
																source={
																	Avatars[
																		parseInt(
																			ass.avatar,
																		)
																	].pp
																}
																style={{
																	width: size,
																	height: size,
																	borderRadius:
																		size /
																		2,
																	resizeMode:
																		"contain",
																	backgroundcolor:
																		APP_COLOURS.BG2,
																}}
															/>
														</View>
													);
												})}
											{d.assignment.length > 5 ? (
												<View
													style={{
														backgroundColor:
															"#252525",
														flexDirection: "row",
														justifyContent:
															"center",
														alignItems: "center",
														//   position: 'absolute',
														right: size / -2,
														width: size,
														height: size,

														borderRadius: 15,
													}}>
													<Text
														style={{
															color: APP_COLOURS.BG2,
															fontSize: fs * 0.7,
														}}>
														+
														{d.assignment.length -
															5}
													</Text>
												</View>
											) : null}
										</View>
									)}
								</View>
							</TouchableOpacity>
						</View>
					</View>

					<TouchableOpacity
						style={{
							flex: 1,
							// width: "100%",
							justifyContent: "center",
							maxWidth: 1200,
							minWidth: 250,
						}}
						onPress={() => {
							let selectedProject = this.state.projects.filter(
								(f) => f.id === d.project_id,
							);
							this.setState({
								selectedTask: d,
								selectedProject: selectedProject[0],

								selectedPage: "Projects",

								subPage: "Tasks",
							});
							console.log(d);
						}}>
						<View style={{ flex: 1 }}>
							<View
								style={{
									flex: 1,
									padding: fs / 2,
									justifyContent: "center",
								}}>
								<Text
									ellipsizeMode='tail'
									style={{
										// color:
										// 	this.state.hover === d.id
										// 		? APP_COLOURS.BLUE2
										// 		: APP_COLOURS.BG2,
										color: "#000",
										fontSize: 15,
										flexWrap: "wrap",
										fontWeight: "500",
									}}>
									{d.title}
								</Text>

								{/* <Text
								ellipsizeMode='tail'
								style={{
									width: "100%",
									color:
										this.state.hover === d.id
											? APP_COLOURS.BLUE2
											: APP_COLOURS.BG2,
									fontSize: 10,
									flexWrap: "wrap",
								}}>
								{moment(d.start_date)
									.endOf("day")
									.format("DD/MM/YYYY")}{" "}
								- {moment(d.end_date).format("DD/MM/YYYY")}
							</Text> */}
							</View>
						</View>
					</TouchableOpacity>
				</View>

				{/* <View
					style={{
						width: 90,
						marginLeft: 5,
						flexDirection: "row",
						justifyContent: "flex-start",
					}}>
					{[0, 1, 2, 3, 4, 5].map((priority) => (
						<TouchableOpacity
							key={priority}
							onPress={() =>
								this.handlePriorityChange(priority, d.id)
							}>
							{d.priority >= priority ? (
								<AutoHeightImage
									width={fs}
									style={{
										marginRight: 3,
									}}
									source={starFill}
								/>
							) : (
								<AutoHeightImage
									width={fs}
									style={{
										marginRight: 3,
										opacity: 0.5,
									}}
									source={star}
								/>
							)}
						</TouchableOpacity>
					))}
				</View> */}

				{/* {this.state.showEdit === d.id ? (
					<TouchableOpacity
						onPress={() => {
							// this.deleteProject(d.id);
							crudDelete(this.state.crudDocumentName, d, () => {
								this.getAllProjectsUser();
								this.setState({
									showEdit: "",
								});
							});
						}}
						style={{
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							width: fs * 1.5,
							height: fs * 1.5,

							marginLeft: 5,
							marginRight: 10,
						}}>
						<Image
							source={bin}
							style={{
								width: fs * 1.5,
								height: fs * 1.5,
								resizeMode: "contain",
								marginRight: 5,
							}}
						/>
					</TouchableOpacity>
				) : (
					<TouchableOpacity
						onPress={() => {
							this.setState({
								showEdit: d.id,
							});
						}}>
						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
								marginLeft: 5,
								width: fs * 1.5,
								height: fs * 1.5,
								marginRight: 10,
								padding: fs / 2,
								opacity: 0.3,
							}}>
							<View
								style={{
									width: fs * 1.5,
									height: fs * 1.5,
									borderRadius: 4,
									borderWidth: 1,
									bordercolor: APP_COLOURS.BG2,
								}}
							/>
						</View>
					</TouchableOpacity>
				)} */}
			</View>
		);
	};

	async componentDidMount() {
		if (typeof this.props.filteredProject !== "undefined") {
			this.setState({
				filterTab: this.props.filteredProject,
				showProjects: false,
			});
		}
		setTimeout(() => {
			this.setState({
				showModal: true,
			});
		}, 100);
		try {
		} catch (error) {
			// Error retrieving data
		}
		await this.setKeyboardListener();
		this.getDeviceData();
	}

	getDeviceData = async () => {
		try {
			const value = await localStorage.getItem("user_data");
			if (value !== null) {
				const d = JSON.parse(value);
				await this.setState({
					user_data: d,
					accessToken: d.accessToken,
				});
				this.checkToSeeIfDataIsLocal();
			}
		} catch (error) {
			// Error retrieving data
		}
	};

	checkToSeeIfDataIsLocal = async () => {
		["projects", "milestones", "tasks"].map(async (p) => {
			try {
				const value = await localStorage.getItem(p);
				if (value !== null) {
					const d = JSON.parse(value);
					await this.setState({
						[p]: d,
					});

					this.setUniqueProjectTitles(d);
				}
			} catch (error) {
				// Error retrieving data
			}
		});

		await this.getAllProjectsUser();
		await this.getAllProjectMilestones();
		await this.getAllProjectTasks();
	};

	getAllProjectsUser = () => {
		console.log("getAllProjectsUser", this.state.user_data.id);
		// return;
		if (this.state.user_data.id === "") {
			return;
		}
		this.setState({
			loading: true,
		});
		// crudRead(
		// 	this.state.crudDocumentName,
		// 	{
		// 		field: "assignment_ids",
		// 		action: "array-contains",
		// 		value: this.state.user_data.id,
		// 	},
		// 	async (status, d) => {
		// 		console.log(status, d);
		// 		if (status === 200) {
		// 			await this.setState({
		// 				projects: d,
		// 				selectedProject:
		// 					this.state.selectedProjectID !== ""
		// 						? d.find(
		// 								(obj) =>
		// 									obj.id ===
		// 									this.state.selectedProjectID,
		// 						  )
		// 						: this.state.selectedProject,
		// 				loading: false,
		// 			});

		// 			localStorage.setItem("projects", JSON.stringify(d));

		// 			this.setUniqueProjectTitles(d);
		// 		}
		// 	},
		// );

		crudGetProjects({ archive: false }, async (status, d) => {
			console.log(status, d);
			if (status === 200) {
				await this.setState({
					projects: d,
					selectedProject:
						this.state.selectedProjectID !== ""
							? d.find(
									(obj) =>
										obj.id === this.state.selectedProjectID,
							  )
							: this.state.selectedProject,
					loading: false,
				});

				localStorage.setItem("projects", JSON.stringify(d));

				this.setUniqueProjectTitles(d);
			}
		});
	};
	getAllProjectMilestones = () => {
		if (this.state.user_data.id !== "") {
			this.setState({
				loading: true,
			});
			crudRead(
				"projects_milestones",
				{
					field: "assignment_ids",
					action: "array-contains",
					value: this.state.user_data.id,
				},
				async (status, d) => {
					console.log("milestones", status, d);
					if (status === 200) {
						await this.setState({
							milestones: d,

							loading: false,
						});

						localStorage.setItem("milestones", JSON.stringify(d));
					}
				},
			);
		}
	};
	getAllProjectTasks = () => {
		if (this.state.user_data.id === "") {
			return;
		}
		this.setState({
			loading: true,
		});
		// crudRead(
		// 	`${this.state.crudDocumentName}_tasks`,
		// 	{
		// 		field: "assignment_ids",
		// 		action: "array-contains",
		// 		value: this.state.user_data.id,
		// 	},
		// 	async (status, d) => {
		// 		console.log(status, d);
		// 		if (status === 200) {
		// 			await this.setState({
		// 				tasks: d,

		// 				loading: false,
		// 			});

		// 			localStorage.setItem("tasks", JSON.stringify(d));
		// 		}
		// 	},
		// );

		crudGetTasks(async (status, d) => {
			console.log(status, d);
			if (status === 200) {
				await this.setState({
					tasks: d,

					loading: false,
				});

				localStorage.setItem("tasks", JSON.stringify(d));
			}
		});
	};

	setUniqueProjectTitles = (d) => {
		let uniqueProjectTitles = [];
		d.forEach((project) => {
			if (!uniqueProjectTitles.includes(project.brand?.title)) {
				uniqueProjectTitles.push(project.brand?.title);
			}
		});
		console.log(uniqueProjectTitles);
		this.setState({ uniqueProjectTitles });
	};

	getUsers = () => {
		crudGetUsers((d) => {
			if (d.length > 0) {
				this.setState({
					users: d,
				});
			} else {
				alert("Fetching users error");
			}
		});
	};

	handleSubTaskPress = (taskIndex, subtaskIndex) => {
		// this.setState(prevState => {
		//   const tasks = prevState.tasks;
		//   tasks[taskIndex].subtasks.splice(subtaskIndex, 1);
		//   return {tasks};
		// });
	};

	async onValueChange(item, selectedValue) {
		try {
			// await AsyncStorage.setItem(item, selectedValue).then((x) => {
			// 	// console.log('onValueChange', item, selectedValue);
			// });
		} catch (error) {
			console.error(error);
		}
	}

	setKeyboardListener = async () => {
		this.keyboardDidShowListener = Keyboard.addListener(
			"keyboardDidShow",
			this._keyboardDidShow,
		);
		this.keyboardDidHideListener = Keyboard.addListener(
			"keyboardDidHide",
			this._keyboardDidHide,
		);
	};

	_keyboardDidShow = (event) => {
		console.log(event.endCoordinates.height);

		let off = event.endCoordinates.height;
		this.setState({
			keyboardOffset: off,
			keyboardActive: true,
		});
		console.log("_keyboardDidShow");
		// setTimeout(() => {
		//   this.refs.scrollView2.scrollTo();
		//   // this.scrollView2.scrollToEnd({animated: true});
		// }, 600);
	};

	_keyboardDidHide = async () => {
		if (this.state.keyboardActive) {
			await console.log("_keyboardDidHide taskitem.js");
			await this.setState({
				keyboardActive: false,
			});
		}
	};

	updateProjectItem = (d) => {
		clearTimeout(this._timeout);
		this._timeout = setTimeout(async () => {
			this.setState({
				loading: true,
			});

			const mpmgh = await d.assignment_ids.forEach(async (id) => {
				await sendDiscordMessage(
					`${this.state.user_data.first_name} ${this.state.user_data.last_name}`,
					`### *${d.brand.title} - ${d.title}*
[PROJECT Overview Update]
`,
					`${window.location.origin}${
						Avatars[this.state.user_data.avatar].pp
					}`,
					id,
				);
			});

			await crudUpdate(this.state.crudDocumentName, d, (status, d) => {
				// console.log(status, d);
				console.log("updateProjectItem");
				if (status === 200) {
					this.getAllProjectsUser();
					// return;
				}
			});
		}, 1000);
		// return;
	};

	createLog = (action, data) => {
		let obj = {
			project_id: this.state.selectedProject.id,
			action: action,
			data: data,
			date_created: moment().toISOString(),
			created_by: `${this.state.user_data.first_name} ${this.state.user_data.last_name}`,
		};
		crudCreate("projects_log", obj, () => {});
	};

	// componentDidUpdate(prevProps, preState) {
	// 	if (prevProps.projects !== this.props.projects) {
	// 		this.getDeviceData();
	// 		this.setUniqueProjectTitles();
	// 	}
	// }

	async componentWillUnmount() {
		this.setState = (state, callback) => {
			return;
		};
		this.keyboardDidShowListener.remove();
		this.keyboardDidHideListener.remove();
	}
}
