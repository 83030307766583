import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
	View,
	Text,
	Image,
	TouchableOpacity,
	TextInput,
	ActivityIndicator,
} from "react-native";
import Lottie from "react-lottie";
import moment from "moment";
import Fade from "react-reveal/Fade";

import Font from "react-font";

import { APP_COLOURS } from "../../APP_VARS";

export default class OrganisationAcount extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
		};
	}

	async componentDidMount() {
		await this.validateSession();
	}

	render() {
		return (
			<View
				style={{
					flex: 1,
					// backgroundColor: APP_COLOURS.BACKGROUND,
					padding: 20,
				}}>
				<View style={{}}>
					<Text
						style={{
							color: APP_COLOURS.BG2,
							fontSize: 28,
							fontWeight: "800",
							fontFamily: "Gotham",
							// marginTop: 15,
							// marginTop: 6,
						}}>
						Organisation Profile
					</Text>
				</View>

				{this.state.laoding ? (
					<View
						style={{
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
						}}>
						<View
							style={{
								padding: 10,
								backgroundColor: APP_COLOURS.BG2,
								borderRadius: 12,
							}}>
							<ActivityIndicator color={"#FFF"} />
						</View>
					</View>
				) : (
					<View
						style={{
							// flex: 1,

							borderRadius: 10,
							// padding: 20,
						}}>
						<View
							style={{
								// flex: 1,
								backgroundColor: APP_COLOURS.BG2,
								borderRadius: 10,
								// padding: "5%",
								padding: 25,
								// justifyContent: "center",
								// alignItems: "center",
							}}>
							<View
								style={{
									width: "80%",
									maxWidth: 400,
								}}>
								<img
									src={require("../../../assets/images/tetrice-revlon-main.png")}
									alt='Description'
									className='responsive'
								/>
							</View>
						</View>
						<View
							style={{
								padding: 20,
							}}>
							<Text
								style={{
									padding: 10,
									color: APP_COLOURS.BG2,
								}}>
								No organisation profile set up.
							</Text>
							<Text
								style={{
									padding: 10,
									color: APP_COLOURS.BG2,
								}}>
								Please contact sales at sales@tetrice.co.za
							</Text>
						</View>
					</View>
				)}
			</View>
		);
	}

	validateSession = async () => {
		let user_data = await localStorage.getItem("user_data");
		let email = await localStorage.getItem("email");
		let accessToken = await localStorage.getItem("accessToken");
		let refresh_token = await localStorage.getItem("refresh_token");

		await this.setState({
			user_data: JSON.parse(user_data),
			email,
			accessToken,
			refresh_token,
		});

		if (accessToken !== null && accessToken !== "") {
			setTimeout(() => {
				this.setState({
					loading: false,
					authenticating: false,
				});
			}, 1000);
		} else {
			// this.props.history.push("/");
			localStorage.clear();
			window.location.href = "/";
		}
	};

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	logOut = async () => {
		await this.props.logOut();
	};
}
