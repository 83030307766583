import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import { Avatars, StatusColour, TaskStatus } from "../../APP_VARS";

import starFill from "./starFill.png";
import star from "./star.png";

import AutoHeightImage from "../AutoHeightImageComp";
import FeedBackSent from "./FeedBackSent";
import SendingFeedback from "./SendingFeedback";
import WhitePaperEditor from "./Editor/QuillEditor";
import { createNotice } from "../../CRUDHELPER";

const NewWhitepaper = ({ user_data, bgColor, mode, close }) => {
	const [survey, setSurvey] = useState(null);
	const [title, setTitle] = useState("");
	const [starRating, setstarRating] = useState(null);

	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const [beginSurvey, setbeginSurvey] = useState(false);

	const [sent, setSent] = useState(false);
	const [sending, setSending] = useState(false);

	// const { surveyId, token } = useParams();

	// useEffect(() => {}, []);

	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error}</div>;

	if (sending) return <SendingFeedback />;
	if (sent) return <FeedBackSent />;

	let screenWidth = window.innerWidth;

	screenWidth = screenWidth > 450 ? 450 : screenWidth;

	return (
		<View
			style={{
				flex: 1,
				backgroundColor: bgColor,
			}}>
			<View
				style={{
					position: "absolute",

					width: window.innerWidth * 1.2,
					height: window.innerWidth * 0.8,

					overflow: "hidden",
				}}>
				<Image
					source={require("./ARTICLEIMAGE.png")}
					style={{
						resizeMode: "cover",
						width: window.innerWidth * 1.2,
						height: window.innerWidth * 0.8,
					}}
				/>

				<View
					style={{
						position: "absolute",
						bottom: 0,
						width: window.innerWidth * 1.2,
						left: -20,
					}}>
					<Image
						source={require("./fade.png")}
						style={{
							width: window.innerWidth * 1.2,
							height: 200,
							resizeMode: "strech",
						}}
					/>
				</View>
			</View>
			<View
				style={{
					position: "absolute",
					right: 20,
					top: 20,
				}}>
				<Image
					source={require("./skrum_menu.png")}
					style={{
						width: 100,
						height: 100,
						resizeMode: "cover",
					}}
				/>
			</View>
			<ScrollView showsVerticalScrollIndicator={false}>
				<View
					style={{
						flex: 1,
						paddingTop: window.innerWidth * 0.8,
						opacity: 0.99,
						overflow: "hidden",
					}}>
					<View
						style={{
							position: "absolute",
							top: 0,
							width: window.innerWidth * 1.2,
							height: window.innerWidth * 0.8,
							overflow: "hidden",
						}}>
						<View
							style={{
								position: "absolute",
								bottom: 0,
								width: window.innerWidth * 1.2,
								left: -20,
							}}>
							<Image
								source={require("./fade.png")}
								style={{
									width: window.innerWidth * 1.2,
									height: 200,
									resizeMode: "strech",
								}}
							/>
						</View>
					</View>

					<View
						style={{
							padding: 20,
							backgroundColor: bgColor,
						}}>
						<View
							style={{
								top: -180,
							}}>
							<View
								style={{
									width: "100%",
									height: "auto",
									padding: 7,
									alignItems: "center",
									borderRadius: 10,
									minHeight: 70,

									flexDirection: "row",
									borderColor: "#C5C5C5",
								}}>
								<Image
									source={
										Avatars[parseInt(user_data.avatar)].pp
									}
									style={{
										width: 30,
										height: 30,
										borderRadius: 25,
										resizeMode: "contain",
										backgroundColor: "#FFF",
										borderWidth: 0.3,
										borderColor: "#C5C5C5",
										shadowColor: "#ECF4FF",
										shadowOffset: {
											width: 0,
											height: 8,
										},
										shadowOpacity: 0.44,
										shadowRadius: 10.32,

										elevation: 16,
									}}
								/>
								<View
									style={{
										padding: 5,
										borderRadius: 10,
										alignItems: "center",
										flexDirection: "column",
										flex: 1,
										alignItems: "flex-start",
									}}>
									<Text
										style={{
											fontFamily: "Gotham",
											fontSize: 12,
											fontWeight: "600",
											color: APP_COLOURS.WHITE,
										}}>
										{user_data.first_name}{" "}
										{user_data.last_name}
									</Text>
								</View>
							</View>
							<Text
								style={{
									fontFamily: "Gotham",
									fontSize: 9,
									marginBottom: 10,
									color:
										mode === "Dark"
											? APP_COLOURS.TEXTCOLOR
											: APP_COLOURS.BG3,
									fontWeight: "300",
								}}>
								{moment().format("DD/MM/YYYY")}
							</Text>
							<View
								style={{
									width: "100%",
								}}>
								<View style={{}}>
									<Text
										style={{
											fontWeight: "400",
											fontFamily: "Avenir Next",
											fontSize: 12,
											color: "#0098FD",
											marginBottom: 4,
											position: "absolute",
											top: -12,

											zIndex: 99,
											padding: 4,
											left: 15,
											borderRadius: 20,
											backgroundColor: bgColor,
										}}>
										Title
									</Text>

									<TextInput
										style={{
											fontSize: 19,
											color: "#FFF",
											fontWeight: "800",
											fontFamily: "Avenir Next",
											width: "100%",
											paddingLeft: 10,
											padding: 10,
											borderWidth: 1,
											borderColor: "#0098FD",
											borderRadius: 16,
											borderWidth: 1,
										}}
										placeholderTextColor={"#797979"}
										autoCapitalize='sentences'
										multiline
										numberOfLines={5}
										clearButtonMode='while-editing'
										// autoCompleteType="off"
										value={title}
										placeholder={`Type a title`}
										onChangeText={(text) => {
											setTitle(text);
										}}
									/>
								</View>
							</View>

							<WhitePaperEditor
								saveToParent={(html) => {
									console.log(html);

									// createWhitepaper(html);

									let obj = {
										id: "",
										title: title,
										dateCreated:
											moment().format("DD/MM/YYYY"),
										html: html,
										text: "",
										image: "",
										bgColor: "#090D13",
										mode: "Dark",
										writtenBy: {
											first_name: user_data.first_name,
											last_name: user_data.last_name,
											avatar: user_data.avatar,
										},
									};

									createNotice(obj, () => {
										close();
									});
								}}
							/>

							<Text
								style={{
									fontSize: 16,
									color:
										mode === "Dark"
											? APP_COLOURS.TEXTCOLOR
											: APP_COLOURS.BG3,
									fontFamily: "Gotham",
									fontWeight: "300",
									marginTop: 20,
								}}>
								Best regards,
							</Text>

							<Text
								style={{
									fontSize: 16,
									color:
										mode === "Dark"
											? APP_COLOURS.TEXTCOLOR
											: APP_COLOURS.BG3,
									fontFamily: "Gotham",
									fontWeight: "500",
									marginTop: 10,
								}}>
								{user_data.first_name}
								<Text
									style={{
										fontFamily: "Gotham",
										fontWeight: "800",
									}}>
									{" "}
									{user_data.last_name}
								</Text>
							</Text>

							{/* <TouchableOpacity
								style={{
									borderRadius: 10,
									justifyContent: "center",
									alignItems: "center",
									marginVertical: 25,
									flexDirection: "row",
									overflow: "hidden",
									marginTop: 30,
								}}
								onPress={() => {}}>
								<Image
									source={require("./button.svg").default}
									style={{
										width: "101%",
										height: 45,
										resizeMode: "cover",
									}}
								/>

								<Text
									style={{
										fontSize: 16,
										// marginTop: 12,
										fontWeight: "600",
										color: "#FFF",
										position: "absolute",
									}}>
									Send
								</Text>
							</TouchableOpacity> */}
						</View>
					</View>
				</View>
			</ScrollView>
			<View
				style={{
					position: "absolute",
					bottom: 0,
					width: window.innerWidth * 1.2,
					left: -20,
				}}>
				<Image
					source={require("./fade.png")}
					style={{
						width: window.innerWidth * 1.2,
						height: 100,
						resizeMode: "strech",
					}}
				/>
			</View>
		</View>
	);
};

export default NewWhitepaper;
