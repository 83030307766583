import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import { Avatars, StatusColour, TaskStatus } from "../../APP_VARS";
import { crudCreate, crudDelete, crudUpdate } from "../../CRUDHELPER";
import Fade from "react-reveal/Fade";
import StatusItem from "../DailyPlanner/StatusItem";
import AddTask from "./AddTask";
import EditTask from "./EditTask";
import EditMilestone from "./EditMilestone";

// import starFill from "../../../assets/images/Table/starFill.png";
// import star from "../../../assets/images/Table/star.png";

function uuid() {
	return "xxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

function firstAndLast(array) {
	var firstItem = array[0];
	var lastItem = array[array.length - 1];

	var objOutput = {
		start: firstItem,
		end: lastItem,
	};

	return objOutput;
}

export default class GanttComponent extends Component {
	constructor(props) {
		// if (!firebaseApp.apps.length) {
		//   firebaseApp.initializeApp(config);
		// }
		super(props);
		this.state = {
			saved: false,
			itemWidth: 100,
			startLeft: 100,
			enableScroll: true,
			totalWidth: 100,
			calendar: [],
			calendarDays: [],
			startweekNumber: moment().startOf("week").week(),
			endweekNumber: moment().add(1, "week").startOf("week").week(),
			hover: "",
			currentSubItemId: "",

			fontSize: 12,

			colours: ["#0468FC", "#E0A749", "#FF5F5F", "#45B88B", "#AF78FF"],

			// milestones: [
			// 	// {
			// 	// 	id: "1",
			// 	// 	title: "Boundry",
			// 	// 	color: "#0468FC",
			// 	// },
			// ],
			// tasks: [
			// 	// {
			// 	// 	id: "1",
			// 	// 	milestone_id: "1",
			// 	// 	title: "tiles",
			// 	// 	start_date: "2022-03-12T22:00:00.000Z",
			// 	// 	end_date: "2022-03-15T22:00:00.000Z",
			// 	// 	status: "pending",
			// 	// },
			// 	// {
			// 	// 	id: "2",
			// 	// 	milestone_id: "1",
			// 	// 	title: "dfkjvnjdfv",
			// 	// 	start_date: "2022-03-12T22:00:00.000Z",
			// 	// 	end_date: "2022-03-15T22:00:00.000Z",
			// 	// 	status: "pending",
			// 	// },
			// ],

			expandRow: [],

			todayPreviewList: [],

			new_start_date: new Date(),
			new_end_date: new Date(),
			taskName: "",

			selectedSubTask: "",
			currentStatus: "",

			addGroup: false,
			addSubItem: false,
			filterOnlyToday: false,
			filterOnlyThisWeek: false,

			selectedProject: "",
			selectedMilestoneComp: "",
			showEditModal: false,

			projects: [],
			milestones: [],
			tasks: [],

			loading: false,

			selectedMilestoneData: "",

			leftPanelMainWidth: 500,
			leftPanelWidth: 500,

			fontColor: "#FFF",
			bgColor1: APP_COLOURS.BG2,
			calendarBgColour: APP_COLOURS.BG3,
			projectsLeftBgColour: APP_COLOURS.BG3,
			projectsTaskBgColour: APP_COLOURS.BG4,
			borderLeftColour: APP_COLOURS.WHITE_TRANSPARENT9,
			dateBorders: APP_COLOURS.BACK_TRANSPARENT,
		};

		this._timeout = 500;
	}

	//
	//
	addTodayPreviewList = async () => {
		let data = this.state.tasks;
		// console.log(data);
		let milestones = this.state.milestones;

		// console.log("addTodayPreviewList");

		let listArray = [];

		for (const sub of data) {
			let isSubitem = (sub.main_project = milestones.filter(
				(d) => d.id == sub.milestone_id,
			));
			if (isSubitem.length > 0) {
				var sd = await moment(sub.start_date);
				var ed = await moment(sub.end_date);
				let today = moment();
				let isOnToday = sd <= today && ed >= today;

				if (isOnToday) {
					listArray.push(sub);
				}
			}
		}

		// console.log("TEMP TO DO LIST", listArray);
		let gantID = "tetrice_app";
		await this.sendToFiebase({
			data: JSON.parse(JSON.stringify(listArray)),
			gantt_id: gantID,
		});
		await this.setState({
			todayPreviewList: listArray,
		});
	};

	async sendToFiebase(data) {
		// let gantID = 'tetrice_app';
		// var db = firebaseApp.firestore();
		// const document = db.collection('gantt_today').doc(gantID);
		// await document
		//   .set(data)
		//   .then(() => {
		//     console.log('items sent');
		//   })
		//   .catch(error => console.log(error));
	}

	//
	//
	//
	expandRow = async (id) => {
		let exists = this.state.expandRow.some((d) => d == id);

		if (exists) {
			let filter = await this.state.expandRow.filter((d) => d !== id);
			await this.setState({
				expandRow: filter,
			});
		} else {
			let add = await this.state.expandRow.concat(id);
			await this.setState({
				expandRow: add,
			});
		}

		// await this.saveAppStates();
	};
	//
	//
	addSubItem = async () => {
		let { new_start_date, new_end_date, taskName, selectedMainItemID } =
			this.state;

		if (taskName == "") return;
		let id = uuid();
		let obj = {
			id: id,
			milestone_id: selectedMainItemID,
			title: taskName,
			status: "pending",
			order: 0,
			start_date: moment(new_start_date).startOf("day"),
			end_date: moment(new_end_date).startOf("day").add(1, "day"),
		};

		await this.addToArray(obj, "tasks");
		await this.setState({
			new_start_date: new Date(),
			new_end_date: new Date(),
			selectedSubTask: id,
			currentStatus: "pending",
			taskName: "",
			addSubItem: false,
		});
	};
	addMainItem = async () => {
		let { selectedColor, projectTitle } = this.state;

		if (projectTitle == "") return;

		let obj = {
			id: uuid(),
			title: projectTitle,
			color: selectedColor,
		};

		await this.addToArray(obj, "milestones");
		await this.setState({
			selectedColor: "",
			projectTitle: "",
			addGroup: false,
		});
	};

	addGroupsFirebase = async (projects) => {
		// let docID = 'tetrice_app_todo';
		// console.log('projects', projects);
		// var db = firebaseApp.firestore();
		// const document = db.collection('gantt_todo').doc(docID);
		// await document
		//   .update({
		//     projects: projects,
		//   })
		//   .then(() => {
		//     console.log('items sent');
		//   })
		//   .catch(error => console.log(error));
	};

	updateItem = async (id, side, oper) => {
		let array = await this.state.tasks;
		// console.log("ARRAY ===>", array);
		let index = await array.findIndex((d) => d.id == id);

		if (side == "left" && oper == "move") {
			array[index].end_date = moment(array[index].end_date).subtract(
				1,
				"day",
			);
			array[index].start_date = moment(array[index].start_date).subtract(
				1,
				"day",
			);
		}
		if (side == "right" && oper == "move") {
			array[index].end_date = moment(array[index].end_date).add(1, "day");
			array[index].start_date = moment(array[index].start_date).add(
				1,
				"day",
			);
		}

		if (side == "left" && oper == "minus") {
			array[index].start_date = moment(array[index].start_date).subtract(
				1,
				"day",
			);
		}
		if (side == "right" && oper == "plus") {
			array[index].end_date = moment(array[index].end_date).add(1, "day");
		}

		if (side == "left" && oper == "plus") {
			array[index].start_date = moment(array[index].start_date).add(
				1,
				"day",
			);
		}
		if (side == "right" && oper == "minus") {
			array[index].end_date = moment(array[index].end_date).subtract(
				1,
				"day",
			);
		}

		if (side == "status" && oper == "pending") {
			array[index].status = "inprogress";
			await this.setState({
				currentStatus: "inprogress",
			});
		}
		if (side == "status" && oper == "inprogress") {
			array[index].status = "complete";
			await this.setState({
				currentStatus: "complete",
			});
		}
		if (side == "status" && oper == "complete") {
			array[index].status = "pending";
			await this.setState({
				currentStatus: "pending",
			});
		}

		await this.setState({
			tasks: array,
		});

		// console.log(id, array[index]);

		// Add items to Temp List
		// await this.addTodayPreviewList();
		await this.saveupdateItem(array[index]);
	};

	saveupdateItem = (d) => {
		// console.log(d);
		// return;
		clearTimeout(this._timeout);
		this._timeout = setTimeout(async () => {
			this.setState({
				loading: true,
			});

			crudUpdate(`projects_tasks`, d, (status, d) => {
				// console.log(status, d);
				console.log("updateItem");
				if (status === 200) {
					this.props.reloadData();
				}
			});
		}, 1500);
		// return;
	};

	//
	// CRUD
	addToArray = async (data, array_name) => {
		let array = await this.state[array_name];
		await array.push(data);
		// console.log("addToArray", array_name, data);
		await this.setState({
			[array_name]: array,
		});
		await this.saveAppStates();
		await this.calculateCalendar();
		if (array_name == "milestones") {
			await this.addGroupsFirebase(array);
		}
	};
	removeFromArray = async (id, array_name) => {
		let array = this.state[array_name];
		const newarr = await array.filter((d) => d.id !== id);
		await this.setState({
			[array_name]: newarr,
		});

		if (array_name == "milestones") {
			// IF YOU DELETE THE MAIN ITEM AND IT HAS CHILDREN
			let array2 = this.state.tasks;
			const newarr2 = await array2.filter((d) => d.milestone_id !== id);
			await this.setState({
				tasks: newarr2,
			});
		}
		await this.saveAppStates();
		await this.calculateCalendar();
	};
	// CRUD END
	//

	calculateCalendar = async () => {
		const startWeek = this.state.startweekNumber;
		const endWeek = this.state.endweekNumber;

		// const startWeek = moment().startOf('month').week();
		// const endWeek = moment().endOf('month').week();

		let calendar = [];
		let calendarDays = [];
		for (var week = startWeek; week < endWeek; week++) {
			let days = await Array(7)
				.fill(0)
				.map((n, i) =>
					moment()
						.week(week)
						.startOf("week")
						.clone()
						.add(n + i, "day"),
				);
			// console.log(days);
			await calendar.push({
				week: week,
				days: days,
			});

			calendarDays = await [...calendarDays, ...days];
		}

		let sorted = await calendarDays.sort(function (a, b) {
			let first = new Date(a.date);
			let last = new Date(b.date);
			return first - last;
		});
		// console.log('sorted', sorted);
		let diff = await firstAndLast(sorted);

		await this.setState({
			calendar: calendar,
			calendarDays: calendarDays,
			calStartEnd: diff,
		});
		await this.addTodayPreviewList();
		// console.log('caslendar', JSON.stringify(diff));
	};

	saveAppStates = async () => {
		const {
			startweekNumber,
			endweekNumber,
			milestones,
			tasks,
			fontSize,
			expandRow,
		} = this.state;
		let obj = {
			startweekNumber,
			endweekNumber,
			milestones,
			tasks,
			fontSize,
			expandRow,
		};
		await this.onValueChange("ganttStates", JSON.stringify(obj));
	};

	async removeAsyncItem(key) {
		// try {
		//   await AsyncStorage.removeItem(key);
		//   return true;
		// } catch (exception) {
		//   alert('Error removing item');
		//   return false;
		// }
	}

	async onValueChange(item, selectedValue) {
		// await this.snoozeUpdateTimer();
		// try {
		//   await AsyncStorage.setItem(item, selectedValue).then(x => {});
		// } catch (error) {
		//   console.error(error);
		// }
	}

	// snoozeUpdateTimer = async () => {
	// 	await this.setState({
	// 		saved: false,
	// 	});
	// 	await clearTimeout(this._timeout);
	// 	this._timeout = await setTimeout(() => {
	// 		this.saveJsonDataToServer();
	// 	}, 2000);
	// };

	// getJsonDataFromServer = () => {
	// 	this.setState({
	// 		loading: true,
	// 	});
	// 	var raw = JSON.stringify({
	// 		jwt: "sdfiuvhsdihjjhbgjuhjwdcgGHGIjuhjkbJHGHJBkjb",
	// 		command: "get",
	// 		fileName: "project_gantt",
	// 	});
	// 	var myHeaders = new Headers();
	// 	myHeaders.append("Content-Type", "application/json");

	// 	var requestOptions = {
	// 		method: "POST",
	// 		headers: myHeaders,
	// 		body: raw,
	// 		redirect: "follow",
	// 	};

	// 	fetch(
	// 		"https://tetrice.co.za/REST/tetrice_masterfileapi/write_jsonlocal.php",
	// 		requestOptions,
	// 	)
	// 		.then((response) => {
	// 			console.log(response);
	// 			if (response.status == "200") {
	// 				response
	// 					.json()
	// 					.then((result) => {
	// 						console.log("result", result);

	// 						this.setState({
	// 							...result,
	// 							loading: false,
	// 							saved: true,
	// 						});
	// 					})
	// 					.catch((error) => this.setState({}));
	// 			} else {
	// 				this.setState({});
	// 			}
	// 		})
	// 		.catch((error) => console.log("error", error));
	// };

	// saveJsonDataToServer = () => {
	// 	this.setState({
	// 		loading: true,
	// 	});
	// 	var raw = JSON.stringify({
	// 		jwt: "sdfiuvhsdihjjhbgjuhjwdcgGHGIjuhjkbJHGHJBkjb",
	// 		json_data: {
	// 			milestones: this.state.milestones,
	// 			tasks: this.state.tasks,
	// 		},
	// 		command: "save",
	// 		fileName: "project_gantt",
	// 	});
	// 	var myHeaders = new Headers();
	// 	myHeaders.append("Content-Type", "application/json");

	// 	var requestOptions = {
	// 		method: "POST",
	// 		headers: myHeaders,
	// 		body: raw,
	// 		redirect: "follow",
	// 	};

	// 	fetch(
	// 		"https://tetrice.co.za/REST/tetrice_masterfileapi/write_jsonlocal.php",
	// 		requestOptions,
	// 	)
	// 		.then((response) => {
	// 			console.log(response);
	// 			if (response.status == "200") {
	// 				response
	// 					.json()
	// 					.then((result) => {
	// 						console.log("result", result);

	// 						this.setState({
	// 							...result,
	// 							loading: false,
	// 							saved: true,
	// 						});
	// 					})
	// 					.catch((error) => this.setState({}));
	// 			} else {
	// 				this.setState({});
	// 			}
	// 		})
	// 		.catch((error) => console.log("error", error));
	// };

	async componentDidMount() {
		await this.calculateCalendar();

		this.setState({
			...this.props,
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.projects !== this.props.projects) {
			this.setState({
				projects: this.props.projects,
			});
		}
		if (prevProps.milestones !== this.props.milestones) {
			this.setState({
				milestones: this.props.milestones,
			});
		}
		if (prevProps.tasks !== this.props.tasks) {
			this.setState({
				tasks: this.props.tasks,
			});
		}

		// ;

		// ;
	}

	render() {
		return (
			<View
				style={{
					flex: 1,
					height: this.props.screenHeight,
					padding: 5,
				}}>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
					}}>
					<View
						style={{
							flex: 1,
							flexDirection: "row",
							justifyContent: "space-between",
						}}>
						<View
							style={{
								width: 150,
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "space-between",
							}}>
							<TouchableOpacity
								onPress={async () => {
									let start = this.state.startweekNumber;
									let eow = this.state.endweekNumber - 1;

									if (eow > start) {
										await this.setState({
											endweekNumber: eow,
										});
										await this.calculateCalendar();
										// await this.saveAppStates();
									}
								}}>
								<Image
									style={{
										width: 25,
										height: 25,
										resizeMode: "contain",
									}}
									source={require("../../../assets/images/min_btn.png")}
								/>
							</TouchableOpacity>

							<Text
								style={{
									color: this.state.fontColor, // '#5A7BBA'
									fontSize: 17,
									fontWeight: "900",
									paddingBottom: 4,
								}}>
								{this.state.endweekNumber -
									this.state.startweekNumber}{" "}
								{this.state.endweekNumber -
									this.state.startweekNumber >
								1
									? "weeks"
									: "week"}
							</Text>

							<TouchableOpacity
								onPress={async () => {
									await this.setState({
										endweekNumber:
											this.state.endweekNumber + 1,
									});
									await this.calculateCalendar();
									// await this.saveAppStates();
								}}>
								<Image
									style={{
										width: 25,
										height: 25,
										resizeMode: "contain",
									}}
									source={require("../../../assets/images/add_btn.png")}
								/>
							</TouchableOpacity>
						</View>
						<View
							style={{
								width: 150,
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "space-between",
							}}>
							<TouchableOpacity
								onPress={async () => {
									await this.setState({
										startweekNumber:
											this.state.startweekNumber - 1,
										endweekNumber:
											this.state.endweekNumber - 1,
									});
									await this.calculateCalendar();
									// await this.saveAppStates();
								}}>
								<Image
									style={{
										width: 45,
										height: 45,
										resizeMode: "contain",
									}}
									source={require("../../../assets/images/left_btn.png")}
								/>
							</TouchableOpacity>

							<TouchableOpacity
								onPress={async () => {
									await this.setState({
										startweekNumber:
											this.state.startweekNumber + 1,
										endweekNumber:
											this.state.endweekNumber + 1,
									});
									await this.calculateCalendar();
									// await this.saveAppStates();
								}}>
								<Image
									style={{
										width: 45,
										height: 45,
										resizeMode: "contain",
									}}
									source={require("../../../assets/images/right_btn.png")}
								/>
							</TouchableOpacity>
						</View>
					</View>
				</View>

				<View
					style={{
						flex: 1,
						flexDirection: "row",
					}}>
					<View
						style={{
							width: this.state.leftPanelMainWidth,
							marginRight: 1,
							borderRightWidth: 1,
							borderRightColor: this.state.borderLeftColour,
						}}
					/>
					{this.leftgroupsComp()}
					{this.calendarBars()}
				</View>

				{this.state.selectedMilestoneComp !== ""
					? this.selectedMilestoneComp(
							this.state.selectedMilestoneComp,
					  )
					: null}

				{this.state.showEditModal
					? this.selectedTaskComp(this.state.selectedSubTask)
					: null}

				{this.state.addSubItem ? (
					<AddTask
						{...this.state}
						close={() =>
							this.setState({
								addSubItem: false,
							})
						}
						reloadData={() => this.props.reloadData()}
					/>
				) : null}

				<Modal
					animationType='fade'
					transparent={true}
					visible={this.state.addGroup}>
					<View
						style={{
							flex: 1,
							justifyContent: "flex-start",
							alignItems: "center",
							backgroundColor: "rgba(52, 52, 52, 0.6)",
						}}>
						<ScrollView>
							<View
								style={{
									width: 500 - 40,
									maxWidth: 400,
									height: "auto",
									backgroundColor: "white",
									// alignItems: 'center',
									borderRadius: 12,
									paddingTop: 20,
									paddingBottom: 10,
									padding: 10,
									marginTop: 40,
								}}>
								<Text>
									Add Group:{" "}
									<View
										style={{
											width: "100%",
											height: 30,
											backgroundColor:
												this.state.selectedColor == ""
													? APP_COLOURS.BG2
													: this.state.selectedColor,
											borderRadius: 5,
											alignItems: "center",
											justifyContent: "center",
										}}></View>
								</Text>

								<View
									style={{
										width: "100%",
										justifyContent: "space-between",
										flexDirection: "row",
										marginTop: 5,
										marginBottom: 5,
									}}>
									{this.state.colours.map((d, i) => (
										<TouchableOpacity
											style={{
												flex: 1,
												marginRight: 5,
											}}
											key={`color_${i}`}
											onPress={() =>
												this.setState({
													selectedColor:
														this.state
															.selectedColor == d
															? ""
															: d,
												})
											}>
											<View
												style={{
													width: "100%",
													height: 30,
													backgroundColor: d,
													borderRadius: 5,
													alignItems: "center",
													justifyContent: "center",
													opacity:
														this.state
															.selectedColor !== d
															? 1
															: 0.1,
												}}></View>
										</TouchableOpacity>
									))}
								</View>

								{this.state.selectedColor == "" ? null : (
									<TextInput
										returnKeyType='done'
										autoCorrect={false}
										style={{
											fontSize: 16,
											fontWeight: "600",
											color: this.state.fontColor,
											width: "100%",
											height: "auto",
											borderWidth: 0.5,
											borderRadius: 15,
											padding: 10,
											backgroundColor:
												this.state.selectedColor,
										}}
										editable={true}
										onChangeText={(text) =>
											this.setState({
												projectTitle: text,
											})
										}
										placeholder='Group Name'
										ref='project'
										clearButtonMode='while-editing'
										autoFocus
									/>
								)}

								<View
									style={{
										// backgroundColor: 'red',
										width: "100%",
										height: 40,
										flexDirection: "row",
										justifyContent: "space-between",
										marginTop: 15,
									}}>
									<TouchableOpacity
										style={{
											flex: 1,
											justifyContent: "center",
										}}
										onPress={() => {
											this.setState({
												addGroup: false,
											});
										}}>
										<Text
											style={{
												width: "100%",
												textAlign: "center",
												fontSize: 19,
												fontWeight: "300",
											}}>
											Cancel
										</Text>
									</TouchableOpacity>
									<TouchableOpacity
										onPress={() => this.addMainItem()}
										style={{
											flex: 1,
											backgroundColor: "#434586",
											justifyContent: "center",
											borderRadius: 20,
										}}>
										{this.state.newCatLoading ? (
											<View
												style={{
													flex: 1,
													justifyContent: "center",
													alignItems: "center",
												}}>
												<ActivityIndicator
													size='small'
													color='#000'
												/>
											</View>
										) : (
											<Text
												style={{
													width: "100%",
													textAlign: "center",
													color: "white",
													fontSize: 12,
													fontWeight: "600",
													padding: 5,
												}}>
												Add group
											</Text>
										)}
									</TouchableOpacity>
								</View>
							</View>
						</ScrollView>
					</View>
				</Modal>

				{/* <Modal
					animationType='fade'
					transparent={true}
					visible={this.state.selectedSubTask !== ""}>
					<View
						style={{
							flex: 1,
							justifyContent: "flex-start",
							alignItems: "center",
							backgroundColor: "rgba(52, 52, 52, 0.6)",
						}}>
						<ScrollView>
							<View
								style={{
									width: 500 - 40,
									maxWidth: 400,
									height: "auto",
									backgroundColor: "white",
									// alignItems: 'center',
									borderRadius: 12,
									paddingTop: 20,
									paddingBottom: 10,
									padding: 10,
									marginTop: 40,
								}}>
								<Text>
									MainID: {this.state.selectedMainItemID}
								</Text>

								<TextInput
									returnKeyType='done'
									autoCorrect={false}
									style={{
										fontSize: 16,
										fontWeight: "600",
										color: "#434586",
										width: "100%",
										height: "auto",
										borderWidth: 0.5,
										borderRadius: 15,
										padding: 10,
									}}
									editable={true}
									onChangeText={(text) =>
										this.setState({ taskName: text })
									}
									placeholder='Task Name'
									ref='taskName'
									clearButtonMode='while-editing'
									autoFocus
								/>

								<View
									style={{
										flexDirection: "row",
										alignItems: "center",
										backgroundColor: this.state.addStartDate
											? "#E8E8E8"
											: "transparent",
										borderRadius: 5,
										padding: 5,
									}}>
									<View
										style={{
											flex: 1,
										}}>
										<Text
											style={{
												color: "#292A4A",
												fontSize: 19,
												fontWeight: "800",
												marginBottom: 10,
											}}>
											Start Date
										</Text>
									</View>

									<View
										style={{
											flex: 1,
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "flex-end",
										}}>
										{this.state.addStartDate ? (
											<View
												style={{
													flex: 1,
												}}>
												<TouchableOpacity
													onPress={() =>
														this.setState({
															addStartDate: false,
														})
													}>
													<View
														style={{
															width: "auto",
															height: 35,
															justifyContent:
																"center",
															alignItems:
																"center",
															backgroundColor:
																"#FF5F5F",
															borderRadius: 12,
															padding: 5,
															marginTop: 10,
														}}>
														<Text
															style={{
																color: this.state.fontColor,
																fontWeight:
																	"600",
																fontSize: 13,
															}}>
															Done
														</Text>
													</View>
												</TouchableOpacity>
											</View>
										) : (
											<TouchableOpacity
												onPress={() =>
													this.setState({
														addStartDate: true,
													})
												}>
												<View
													style={{
														width: "auto",
														height: 35,
														justifyContent:
															"center",
														alignItems: "center",
														backgroundColor:
															"#FF5F5F",
														borderRadius: 12,
														padding: 5,
														paddingLeft: 30,
														paddingRight: 30,
													}}>
													<Text
														style={{
															color: this.state.fontColor,
															fontWeight: "600",
															fontSize: 13,
														}}>
														{moment(
															this.state
																.new_start_date,
														).format("DD/MM/YYYY")}
													</Text>
												</View>
											</TouchableOpacity>
										)}
									</View>
								</View>

								<View
									style={{
										// backgroundColor: 'red',
										width: "100%",
										height: 40,
										flexDirection: "row",
										justifyContent: "space-between",
										marginTop: 15,
									}}>
									<TouchableOpacity
										style={{
											flex: 1,
											justifyContent: "center",
										}}
										onPress={() => {
											this.setState({
												selectedSubTask: "",
											});
										}}>
										<Text
											style={{
												width: "100%",
												textAlign: "center",
												fontSize: 19,
												fontWeight: "300",
											}}>
											Cancel
										</Text>
									</TouchableOpacity>
									<TouchableOpacity
										onPress={() => this.addSubItem()}
										style={{
											flex: 1,
											backgroundColor: "#434586",
											justifyContent: "center",
											borderRadius: 20,
										}}>
										{this.state.newCatLoading ? (
											<View
												style={{
													flex: 1,
													justifyContent: "center",
													alignItems: "center",
												}}>
												<ActivityIndicator
													size='small'
													color='#000'
												/>
											</View>
										) : (
											<Text
												style={{
													width: "100%",
													textAlign: "center",
													color: "white",
													fontSize: 12,
													fontWeight: "600",
													padding: 5,
												}}>
												Add to Main Item
											</Text>
										)}
									</TouchableOpacity>
								</View>
							</View>
						</ScrollView>
					</View>
				</Modal> */}
			</View>
		);
	}

	leftgroupsComp = () => {
		return (
			<View
				style={{
					height: "100%",
					flex: 1,
					width: "100%",
					position: "absolute",
					left: 0,
					zIndex: 99,
				}}>
				<View
					style={{
						height: 40,
						width: this.state.leftPanelMainWidth,
						// backgroundColor: "#026BFA",
						backgroundcolor: this.state.bgColor1,
						padding: 5,
						alignItems: "center",
						// marginBottom: 50,
					}}>
					<Text
						style={{
							color: "#FFF", // '#5A7BBA'
							fontSize: 13,
							fontWeight: "900",
							marginBottom: 16,
						}}>
						Groups
					</Text>
				</View>

				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						width: this.state.leftPanelMainWidth,
						paddingRight: 5,
						marginTop: 5,
						marginBottom: 5,
					}}>
					{/* <View
						style={{
							marginLeft: 1,
						}}>
						{this.props.user_data.permission === "tetrice" ? (
							<TouchableOpacity
								onPress={async () => {
									await this.setState({
										addGroup: true,
									});
								}}>
								<View
									style={{
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "center",
									}}>
									<Image
										style={{
											width: 25,
											height: 25,
											resizeMode: "contain",
											marginRight: 5,
										}}
										source={require("../../../assets/images/add_btn.png")}
									/>
									<Text
										style={{
											color: this.state.fontColor, // '#5A7BBA'
											fontSize: 13,
											fontWeight: "900",
										}}>
										New Project
									</Text>
								</View>
							</TouchableOpacity>
						) : (
							<View />
						)}
					</View> */}
					<View
						style={{
							width: 100,
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
							marginBottom: 5,
						}}>
						<TouchableOpacity
							onPress={async () => {
								await this.setState({
									fontSize: this.state.fontSize - 1,
								});
								// await this.saveAppStates();
							}}>
							<Image
								style={{
									width: 25,
									height: 25,
									resizeMode: "contain",
								}}
								source={require("../../../assets/images/min_btn.png")}
							/>
						</TouchableOpacity>

						<Text
							style={{
								color: this.state.fontColor, // '#5A7BBA'
								fontSize: 17,
								fontWeight: "900",
								paddingBottom: 4,
							}}>
							{this.state.fontSize}
						</Text>

						<TouchableOpacity
							onPress={async () => {
								await this.setState({
									fontSize: this.state.fontSize + 1,
								});
								// await this.saveAppStates();
							}}>
							<Image
								style={{
									width: 25,
									height: 25,
									resizeMode: "contain",
								}}
								source={require("../../../assets/images/add_btn.png")}
							/>
						</TouchableOpacity>
					</View>
				</View>

				<View
					style={{
						width: this.state.leftPanelMainWidth,
						height: "auto",
						justifyContent: "flex-end",
						alignItems: "center",
						paddingRight: 5,
						flexDirection: "row",
					}}>
					{this.state.filterOnlyThisWeek ? (
						<TouchableOpacity
							style={{}}
							onPress={() =>
								this.setState({
									filterOnlyThisWeek: false,
								})
							}>
							<Text
								style={{
									color: "#E0A749",
									fontSize: 13,
									fontWeight: "300",
									marginRight: 10,
								}}>
								Only in View Tasks
							</Text>
						</TouchableOpacity>
					) : (
						<TouchableOpacity
							style={{}}
							onPress={() =>
								this.setState({
									filterOnlyThisWeek: true,
								})
							}>
							<Text
								style={{
									color: this.state.fontColor,
									fontSize: 13,
									marginRight: 10,
									fontWeight: "300",
									opacity: 0.5,
								}}>
								Only in View Tasks
							</Text>
						</TouchableOpacity>
					)}

					{this.state.filterOnlyToday ? (
						<TouchableOpacity
							style={{}}
							onPress={() =>
								this.setState({
									filterOnlyToday: false,
								})
							}>
							<Text
								style={{
									color: "#E0A749",
									fontSize: 13,
									fontWeight: "300",
								}}>
								Today Only
							</Text>
						</TouchableOpacity>
					) : (
						<TouchableOpacity
							style={{}}
							onPress={() =>
								this.setState({
									filterOnlyToday: true,
								})
							}>
							<Text
								style={{
									color: this.state.fontColor,
									fontSize: 13,
									fontWeight: "300",
									opacity: 0.5,
								}}>
								Today Only
							</Text>
						</TouchableOpacity>
					)}
					{/* {this.state.expandRow.length > 0 ? (
			<TouchableOpacity
				style={{}}
				onPress={() =>
					this.setState({
						expandRow: [],
					})
				}>
				<Image
					style={{
						width: 25,
						height: 25,
						marginRight: 0,
						marginLeft: 20,
						resizeMode: "contain",
					}}
					source={require("../../../assets/images/expand.png")}
				/>
			</TouchableOpacity>
		) : null} */}
				</View>

				<View style={{ flex: 1, width: "100%", marginTop: 20 }}>
					<ScrollView showsVerticalScrollIndicator={false}>
						<View
							style={{
								flex: 1,
								width: "100%",
								flexDirection: "column",
							}}>
							{this.proejctsItem()}
						</View>
						<View
							style={{
								height: 300,
							}}></View>
					</ScrollView>
				</View>

				{this.state.selectedSubTask !== "" && !this.state.showEditModal
					? this.selectedTaskItem()
					: null}

				{/* <TaskerModal projects={this.state.milestones} /> */}
				{/* <TodayList data={this.state.todayPreviewList} /> */}
			</View>
		);
	};

	proejctsItem = () => {
		let { projects } = this.state;
		let size = 25;
		let fs = 14;
		return (
			<View>
				{projects
					.sort((a, b) => a.title > b.title)
					.sort((a, b) => b.priority - a.priority)
					.filter((f) => f.status !== "Completed")
					.filter((d_) => {
						let filter = d_;

						if (
							typeof this.state.filterTab !== "undefined" &&
							this.state.filterTab !== ""
						) {
							let text_ = this.state.filterTab;
							var outString = text_.replace(
								/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
								"",
							);

							let join_names = `${d_.brand?.title}`;

							join_names = join_names.replace(
								/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
								"",
							);

							return join_names
								.toLowerCase()
								.includes(outString.toLowerCase());
						}

						return filter;
					})
					.filter((d_) => {
						let filter = d_;

						if (
							typeof this.state.filterSearch !== "undefined" &&
							this.state.filterSearch !== ""
						) {
							let text_ = this.state.filterSearch;
							var outString = text_.replace(
								/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
								"",
							);

							let join_names = `${d_.title} ${d_.brand?.title}`;

							join_names = join_names.replace(
								/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
								"",
							);

							return join_names
								.toLowerCase()
								.includes(outString.toLowerCase());
						}

						return filter;
					})
					.map((project) => (
						<View
							// onMouseEnter={() =>
							// 	this.setState({ mainHover: project.id })
							// }
							// onMouseLeave={() =>
							// 	this.setState({ mainHover: "" })
							// }
							key={`projects-${project.id}`}
							style={{
								width: "100%",
								marginBottom: 30,
								opacity:
									this.state.mainHover === project.id
										? 1
										: 0.8,

								// opacity:
								// 	project.status === "Completed" ? 0.2 : 1,
							}}>
							<View
								style={{
									width: this.state.leftPanelMainWidth,
								}}
							/>
							<View>
								<View
									// onMouseEnter={() =>
									// 	this.setState({ hover: project.id })
									// }
									// onMouseLeave={() =>
									// 	this.setState({ hover: "" })
									// }
									style={{
										width: "100%",
										flexDirection: "row",
										alignItems: "center",
										// backgroundColor: "#FFF",
										backgroundColor:
											this.state.calendarBgColour,
									}}>
									<View style={{ flex: 1 }}>
										<TouchableOpacity
											onPress={() =>
												this.expandRow(project.id)
											}>
											<View
												style={{
													// width: this.state
													// 	.leftPanelWidth,
													flexDirection: "row",
													alignItems: "center",
													padding: 5,
													// justifyContent:
													// 	"space-between",
													// backgroundColor: "#FFF",
													backgroundColor:
														this.state
															.projectsLeftBgColour,
													// borderRadius: 5,
													// marginBottom: 5,
													marginRight: 2,
													// borderTopLeftRadius: 5,
													borderTopRightRadius: 5,

													paddingLeft: 6,
													// borderLeftWidth: 9,
													// borderLeftColor: TaskStatus(
													// 	project.status,
													// ),
													minHeight: 50,
												}}>
												<View
													style={{
														flex: 1,
														paddingLeft: 10,
													}}>
													<Text
														style={{
															color: this.state
																.fontColor,
															fontSize:
																this.state
																	.fontSize -
																1,
															fontWeight: "500",
															opacity: 0.7,
														}}>
														{project.brand?.title}
													</Text>
													<Text
														style={{
															color: this.state
																.fontColor, // '#5A7BBA'
															fontSize:
																this.state
																	.fontSize +
																2,
															fontWeight: "700",
															marginBottom: 6,
														}}>
														{project.title}
													</Text>
												</View>
												{/* <StatusItem
													{...this.props}
													{...project}
													fs={this.state.fontSize}
													handleStatusChange={(
														_id,
														_status,
													) => {}}
												/> */}

												{/* <View
													style={{
														flexDirection: "row",
														alignItems: "center",
														width: 80,
													}}>
													{this.assignment(
														project,
														size,
													)}
												</View> */}
											</View>
										</TouchableOpacity>
									</View>
									{this.state.hover === project.id ? (
										<Fade>
											<TouchableOpacity
												onPress={async () => {
													// await this.setState({
													// 	addGroup: true,
													// });

													crudCreate(
														"projects_milestones",
														{
															project_id:
																project.id,
															title: `Milestone ${
																this.state.milestones.filter(
																	(d_) =>
																		d_.project_id ===
																		project.id,
																).length + 1
															}`,
															color: "#AF78FF",
															total_budget: "",
															description: "",
															priority: 0,
															createdAt:
																"2023-10-18T15:02:54.406Z",
															createdBy:
																this.props
																	.user_data
																	.id,

															progress: 0,

															due_date: moment(),
															start_date:
																moment().add(
																	1,
																	"day",
																),
															status: "Pending",
															assignment: [],
															assignment_ids: [
																this.props
																	.user_data
																	.id,
															],
														},
														async () => {
															await this.props.reloadData();

															// await this.setState(
															// 	{
															// 		selectedProject:
															// 			project,
															// 		selectedMilestoneComp:
															// 			main,
															// 	},
															// );
														},
													);
												}}>
												<View
													style={{
														flexDirection: "row",
														alignItems: "center",
														justifyContent:
															"center",
														marginRight: 15,
													}}>
													<Image
														style={{
															width: 16,
															height: 16,
															resizeMode:
																"contain",
															marginRight: 5,
														}}
														source={require("../../../assets/images/add_btn.png")}
													/>
													<Text
														style={{
															color: this.state
																.fontColor, // '#5A7BBA'
															fontSize: 13,
															fontWeight: "900",
														}}>
														Milestone
													</Text>
												</View>
											</TouchableOpacity>
										</Fade>
									) : null}

									{/* <View
										style={{
											width: 120,
											marginLeft: 5,
											flexDirection: "row",
											justifyContent: "flex-start",
										}}>
										{[0, 1, 2, 3, 4, 5].map((priority) => (
											<TouchableOpacity
												key={priority}
												onPress={() => {}}>
												{project.priority >=
												priority ? (
													<AutoHeightImage
														width={fs}
														style={{
															marginRight: 3,
														}}
														source={starFill}
													/>
												) : (
													<AutoHeightImage
														width={fs}
														style={{
															marginRight: 3,
															opacity: 0.5,
														}}
														source={star}
													/>
												)}
											</TouchableOpacity>
										))}
									</View> */}
									{/* <StatusItem
										{...this.props}
										{...project}
										fs={this.state.fontSize}
										handleStatusChange={(
											_id,
											_status,
										) => {}}
									/> */}
								</View>
							</View>

							{this.state.milestones
								.filter((d_) => d_.project_id === project.id)
								.map((d, i) => this.milstoneItems(d, project))}

							{/* .filter((f) => f.status !== "Completed") */}

							{this.state.tasks
								.filter((d) => d.project_id === project.id)
								.sort((a, b) => a.title > b.title)
								.sort(function (a, b) {
									let first = new Date(a.start_date);
									let last = new Date(b.start_date);
									return first - last;
								})

								.map((sub, i) => this.taskItems(sub, project))}
						</View>
					))}
			</View>
		);
	};

	milstoneItems = (main, project) => {
		let calendarDays = this.state.calendarDays;
		if (typeof calendarDays[0] !== "undefined") {
			let cs = moment(this.state.calStartEnd.start);
			let ce = moment(this.state.calStartEnd.end);
			let tasks = this.state.tasks.filter(
				(d) => d.milestone_id === main.id,
			);
			let sDates = tasks
				.sort(function (a, b) {
					let first = new Date(a.start_date);
					let last = new Date(b.start_date);
					return first - last;
				})
				.map((it) => it.start_date);
			let eDates = tasks
				.sort(function (a, b) {
					let first = new Date(a.end_date);
					let last = new Date(b.end_date);
					return first - last;
				})
				.map((it) => it.end_date);
			let lastDateCovered = firstAndLast(eDates);
			let LastDate = lastDateCovered.end;

			let leftDate = moment(sDates[0]);
			let rightDate = moment(LastDate);

			let leftStart = leftDate.diff(cs, "days");

			let mainDuration = rightDate.diff(leftDate, "days") + 1;

			let rightEnd = calendarDays.length - leftStart - mainDuration;

			// leftStart = leftStart < 0 ? 0 : leftStart;
			// rightEnd = rightEnd < 0 ? 0 : rightEnd;

			let inView =
				leftStart + mainDuration <= 0
					? false
					: rightEnd + mainDuration <= 0
					? false
					: true;

			return (
				<View
					onMouseEnter={() => this.setState({ hover: main.id })}
					onMouseLeave={() => this.setState({ hover: "" })}
					style={{
						width: "100%",
						// marginBottom: 5,
						opacity: inView ? 1 : 0.3,
					}}
					key={main.id}>
					<View
						style={{
							width: this.state.leftPanelMainWidth,
						}}
					/>
					<View
						style={{
							// backgroundColor: "rgba(0,0, 0, 0.1)"
							backgroundColor: this.state.projectsTaskBgColour,
						}}>
						<View
							style={{
								width: "100%",
								flexDirection: "row",
								alignItems: "center",
								// borderBottomWidth: 1,
								// borderBottomColor: "#E8E8E8",
							}}>
							<TouchableOpacity
								activeOpacity={0.8}
								onPress={() => this.expandRow(main.id)}>
								<View
									style={{
										width: this.state.leftPanelWidth,
										flexDirection: "row",
										alignItems: "center",
										padding: 8,
										justifyContent: "space-between",
										// backgroundColor:
										// 	"rgba(255,255, 255, 0.7)",
										// borderRadius: 5,
										// marginBottom: 5,
										marginRight: 2,
										// borderLeftWidth: 20,
										// borderLeftColor: TaskStatus(
										// 	main.status,
										// ),

										// backgroundColor:
										// 	this.state.projectsTaskBgColour,
									}}>
									<StatusItem
										{...this.props}
										{...main}
										fs={this.state.fontSize}
										handleStatusChange={(
											_id,
											_status,
										) => {}}
									/>
									<View
										style={{
											flex: 1,
										}}>
										<Text
											style={{
												color: this.state.fontColor, // '#5A7BBA'
												fontSize:
													this.state.fontSize + 2,
												fontWeight: "700",
											}}>
											{main.title}
										</Text>
										<Text
											style={{
												color: this.state.fontColor, // '#5A7BBA'
												fontSize:
													this.state.fontSize - 1,
												fontWeight: "400",
											}}>
											{main.description}
										</Text>
									</View>

									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
										}}>
										{this.state.hover === main.id ? (
											<Fade>
												<TouchableOpacity
													onPress={async () => {
														console.log(main);
														await this.setState({
															selectedProject:
																project,
															selectedMilestoneComp:
																main,
														});
													}}>
													<View
														style={{
															flexDirection:
																"row",
															alignItems:
																"center",
															justifyContent:
																"center",
															marginRight: 10,
														}}>
														<Text
															style={{
																color: this
																	.state
																	.fontColor, // '#5A7BBA'
																fontSize: 12,
																fontWeight:
																	"300",
															}}>
															Edit
														</Text>
													</View>
												</TouchableOpacity>
											</Fade>
										) : null}
										{/* add task */}
										{this.state.hover === main.id ? (
											<Fade>
												<TouchableOpacity
													onPress={async () => {
														await this.setState({
															addSubItem: true,
															selectedMilestoneData:
																main,
														});
													}}>
													<View
														style={{
															flexDirection:
																"row",
															alignItems:
																"center",
															justifyContent:
																"center",
															marginRight: 10,
														}}>
														<Image
															style={{
																width: 16,
																height: 16,
																resizeMode:
																	"contain",
																marginRight: 5,
															}}
															source={require("../../../assets/images/add_btn.png")}
														/>

														<Text
															style={{
																color: this
																	.state
																	.fontColor, // '#5A7BBA'
																fontSize: 12,
																fontWeight:
																	"500",
															}}>
															Task
														</Text>
													</View>
												</TouchableOpacity>
											</Fade>
										) : null}

										{this.state.tasks.filter(
											(d) => d.milestone_id === main.id,
										).length > 0 ? (
											<Text
												style={{
													color: this.state.fontColor, // '#5A7BBA'
													fontSize:
														this.state.fontSize + 2,
													fontWeight: "500",
													opacity: inView ? 0.7 : 0.2,
													marginRight: 2,
												}}>
												{
													this.state.tasks.filter(
														(d) =>
															d.milestone_id ==
															main.id,
													).length
												}
											</Text>
										) : null}

										{this.state.expandRow.filter(
											(d) => d == main.id,
										).length > 0 ? (
											<TouchableOpacity
												style={{}}
												onPress={() =>
													this.expandRow(main.id)
												}>
												<Image
													style={{
														width: 25,
														height: 25,
														// marginRight: 10,
														resizeMode: "contain",
													}}
													source={require("../../../assets/images/contract.png")}
												/>
											</TouchableOpacity>
										) : (
											<TouchableOpacity
												style={{}}
												onPress={() =>
													this.expandRow(main.id)
												}>
												<Image
													style={{
														width: 25,
														height: 25,
														// marginRight: 10,
														resizeMode: "contain",
													}}
													source={require("../../../assets/images/expand.png")}
												/>
											</TouchableOpacity>
										)}
										{/* {this.props
												.user_data
												.permission ===
											"tetrice" ? (
												<TouchableOpacity
													onPress={() =>
														this.removeFromArray(
															main.id,
															"milestones",
														)
													}
													style={{
														flexDirection:
															"row",
														justifyContent:
															"center",
														alignItems:
															"center",
														padding: 3,
													}}>
													<Image
														source={
															bin
														}
														style={{
															width: 20,
															height: 20,
															marginRight: 5,
															resizeMode:
																"contain",
														}}
													/>
												</TouchableOpacity>
											) : null}
											{this.props
												.user_data
												.permission ===
											"tetrice" ? (
												<TouchableOpacity
													style={{}}
													onPress={() =>
														this.setState(
															{
																selectedMainItemID:
																	main.id,
																addSubItem: true,
															},
														)
													}>
													<Image
														style={{
															width: 20,
															height: 20,
															resizeMode:
																"contain",
														}}
														source={require("../../../assets/images/add_btn.png")}
													/>
												</TouchableOpacity>
											) : (
												<View />
											)} */}
									</View>
								</View>
							</TouchableOpacity>
							<View
								style={{
									flex: 1,
									flexDirection: "row",
									marginBottom: 1,
									// backgroundColor: "rgba(255,255, 255, 0.3)",
									// backgroundColor:
									// 	this.state.projectsTaskBgColour,
									padding: 5,
								}}>
								<View
									style={{
										display:
											leftStart <= 0 ? "none" : "flex",
										flex: leftStart <= 0 ? 0 : leftStart,
										alignItems: "flex-start",
										justifyContent: "center",
									}}></View>
								<View
									style={{
										display:
											leftStart + mainDuration <= 0
												? "none"
												: rightEnd + mainDuration <= 0
												? "none"
												: "flex",
										flex:
											leftStart <= 0
												? leftStart + mainDuration
												: rightEnd <= 0
												? rightEnd + mainDuration
												: mainDuration,
									}}>
									<View
										style={{
											width: "100%",
											height: "auto",
											// backgroundColor: main.color,
											backgroundColor:
												"rgba(0,0, 0, 0.3)",
											position: "absolute",
											opacity: 0.4,
											borderRadius: 2,
											padding: 6,
											paddingLeft: 5,
										}}>
										<Text
											adjustsFontSizeToFit
											numberOfLines={1}
											style={{
												color: this.state.fontColor, // '#5A7BBA'
												fontSize: this.state.fontSize,
												fontWeight: "500",
												opacity: 0,
											}}>
											Opacity
										</Text>
									</View>
									<View
										style={{
											width: "100%",
											height: "auto",
											borderRadius: 2,
											padding: 5,
											paddingLeft: 5,
										}}>
										<Text
											adjustsFontSizeToFit
											numberOfLines={1}
											style={{
												color: this.state.fontColor, // '#5A7BBA'
												fontSize: this.state.fontSize,
												fontWeight: "500",
												// opacity: 0.8,
											}}>
											{main.title}
										</Text>
									</View>
								</View>

								<View
									style={{
										display:
											rightEnd <= 0 ? "none" : "flex",
										flex: rightEnd <= 0 ? 0 : rightEnd,
									}}></View>
							</View>
						</View>
					</View>

					<View
						style={
							{
								// borderLeftWidth: 3,
								// borderLeftcolor: this.state.fontColor,
							}
						}>
						{this.state.tasks
							.filter((d) => d.milestone_id === main.id)
							.sort(function (a, b) {
								let first = new Date(a.start_date);
								let last = new Date(b.start_date);
								return first - last;
							})
							.sort((a, b) => a.title > b.title)
							.map((sub, i) => this.taskItems(sub, main))}
					</View>

					{/* <View
						style={{
							width: 300,
							padding: 5,
							backgroundColor: "#E8E8E8",
							height: 25,
							borderBottomLeftRadius: 5,
							borderBottomRightRadius: 5,
						}}>
						
					</View> */}
				</View>
			);
		}
	};

	taskItems = (sub, main) => {
		let calendarDays = this.state.calendarDays;
		if (typeof calendarDays[0] !== "undefined") {
			var sd = moment(sub.start_date).startOf("day");
			var ed = moment(sub.end_date).endOf("day");
			let cs = moment(this.state.calStartEnd.start);

			let left = sd.diff(cs, "days");
			let duration = ed.diff(sd, "days");

			let right = calendarDays.length - left - duration - 1;

			let inView =
				left + duration <= 0
					? false
					: right + duration <= 0
					? false
					: true;

			let today = moment();
			let isOnToday = sd <= today && ed >= today;

			let isDueToday =
				moment().format("DD/MM/YYY") ===
				moment(sub.end_date).format("DD/MM/YYY");
			let isPastDue = moment().isSameOrAfter(moment(sub.end_date));
			isPastDue = isPastDue && sub.status !== "Completed";
			let showItem = true;

			if (this.state.filterOnlyThisWeek) {
				if (this.state.filterOnlyThisWeek && inView) {
					showItem = true;
				} else {
					showItem = false;
				}
			}

			if (this.state.filterOnlyToday) {
				if (this.state.filterOnlyToday && isOnToday) {
					showItem = true;
				} else {
					showItem = false;
				}
			}

			let selectedProject = this.state.projects.filter(
				(f) => f.id === sub.project_id,
			);

			return (
				<View
					key={sub.id}
					onMouseEnter={() => this.setState({ taskHover: sub.id })}
					onMouseLeave={() => this.setState({ taskHover: "" })}
					style={{
						width: "100%",
						// display: this.state.expandRow.some((d) => main.id === d)
						// 	? showItem
						// 		? "flex"
						// 		: "none"
						// 	: "none",
						flexDirection: "row",

						// backgroundColor:
						// 	this.state.selectedSubTask.id === sub.id
						// 		? "rgba(0,0, 0, 0.1)"
						// 		: "transparent",
						opacity: sub.status === "Completed" ? 0.05 : 1,
					}}>
					<TouchableOpacity
						onPress={() => {
							console.log(sub);
							this.setState({
								showEditModal: true,
								selectedSubTask: sub,
								selectedMilestoneData: main,
								currentStatus: sub.status,
								selectedProject: selectedProject[0],
								currentSubItemId: sub.id,
							});
						}}>
						<View
							style={{
								flex: 1,
								width: this.state.leftPanelWidth,
								paddingLeft: 5,
								// backgroundColor:
								// 	this.state.selectedSubTask === sub.id
								// 		? "rgba(255,255, 255, 0.5)"
								// 		: "rgba(255,255, 255, 1)",
								// borderLeftWidth: 30,
								// borderLeftColor: TaskStatus(sub.status),
								marginBottom: 1,
								justifyContent: "center",
								minHeight: 35,

								// backgroundColor:
								// 	this.state.projectsTaskBgColour,
							}}>
							<View
								style={{
									flex: 1,
									flexDirection: "row",
									paddingLeft: 5,
									justifyContent: "space-between",
									marginRight: 2,
								}}>
								<StatusItem
									{...sub}
									fs={this.state.fontSize}
									handleStatusChange={(_id, _status) => {
										console.log(_id, _status);
										let obj = {
											id: _id,
											status: _status,
										};
										crudUpdate(
											`projects_tasks`,
											obj,
											(status, d) => {
												// console.log(status, d);
												console.log("updateItem");
												if (status === 200) {
													this.props.reloadData();
												}
											},
										);
									}}
								/>
								<View
									style={{
										flex: 1,
										justifyContent: "center",
										opacity: isOnToday
											? 1
											: inView
											? 0.7
											: 0.5,
										paddingLeft: 10,
									}}>
									<Text
										ellipsizeMode='clip'
										numberOfLines={1}
										style={{
											// flex: 1,
											color: this.state.fontColor,
											fontSize: this.state.fontSize + 1,
											fontWeight: isOnToday
												? "600"
												: "400",

											height:
												sub.status === "Completed"
													? this.state.fontSize
													: "auto",
										}}>
										{sub.title}
									</Text>

									<Text
										style={{
											color: this.state.fontColor,
											// width: 120,
											fontSize: this.state.fontSize - 3,
											fontWeight: "500",
											opacity:
												this.state.taskHover === sub.id
													? 1
													: 0,
										}}>
										{moment(sd).format("DD/MM")} -
										{" Due date: "}
										{moment(ed).format("DD/MM")}
									</Text>
								</View>
								<View
									style={{
										overflow: "hidden",
										width: 80,
										// backgroundColor: "green",
									}}>
									{this.assignment(
										sub,
										this.state.fontSize + 5,
									)}
								</View>

								{/* {sub.status === "Completed" ? (
									<Image
										style={{
											width: this.state.fontSize,
											height: this.state.fontSize,
											resizeMode: "contain",
										}}
										source={require("../../../assets/images/Checkmark.png")}
									/>
								) : ( */}

								{/* )} */}
							</View>
						</View>
					</TouchableOpacity>

					<View
						style={{
							flex: 1,
							flexDirection: "row",
							marginBottom: 1,
						}}>
						{/* ITEMS  */}
						{left <= 0 ? null : (
							<View
								style={{
									// display: left <= 0 ? "none" : "flex",
									flex: left,
									alignItems: "center",
									justifyContent: "flex-end",
									flexDirection: "row",
								}}></View>
						)}

						<TouchableOpacity
							style={{
								// backgroundColor:
								// 	sub.status == "inprogress"
								// 		? "#E0A749"
								// 		: main.color,
								backgroundColor: isPastDue
									? APP_COLOURS.RED
									: TaskStatus(sub.status),

								// backgroundColor: isPastDue
								// 	? APP_COLOURS.RED
								// 	: APP_COLOURS.BLUE,
								borderRadius: 10,
								// padding: 3,
								display:
									left + duration <= 0
										? "none"
										: right + duration <= 0
										? "none"
										: "flex",
								flex:
									left <= 0
										? left + duration
										: right <= 0
										? right + duration
										: duration,
								minWidth: 20,
								borderTopRightRadius: 0,
								borderBottomRightRadius: 0,
								justifyContent: "center",
							}}
							onPress={() => {
								this.setState({
									selectedSubTask: sub,
									selectedMilestoneData: main,
									currentStatus: sub.status,

									currentSubItemId: sub.id,
								});
							}}>
							<View
								style={{
									flexDirection: "row",
									padding: 3,
									// height: this.state.fontSize + 12,
									borderRadius: 5,
									overflow: "hidden",
									alignItems: "center",
								}}>
								{/* {this.state.selectedSubTask.id === sub.id ? (
									<View
										style={{
											flex: 1,
											position: "absolute",
										}}
									/>
								) : null} */}

								<View
									style={{
										width: "100%",
										flexDirection: "row",
										alignItems: "center",
									}}>
									<View
										style={{
											width: 3,
											height: 20,
											borderRadius: 3,
											backgroundColor: "#FFF",
											marginRight: 5,
										}}
									/>
									<View style={{}}>
										{/* <Text
											// adjustsFontSizeToFit
											// numberOfLines={1}
											style={{
												color: this.state.fontColor,
												// width: 300,
												fontSize:
													this.state.fontSize - 1,
												fontWeight: "700",
											}}>
											{sub.title}
										</Text> */}
									</View>
								</View>
							</View>

							{/* <View style={{}}>
								<View
									style={{
										width: "100%",
										flexDirection: "row",
										paddingHorizontal: 10,
									}}>
									<View
										style={{
											flex: 1,
											flexDirection: "row",
											alignItems: "center",
										}}>
										<ProgressBar
											progress={`${0.3 * 100}%`}
											color={APP_COLOURS.GREEN}
											inProgress={parseFloat(0.3) > 0}
										/>
									</View>
								</View>

								<View
									style={{
										display:
											this.state.selectedSubTask ===
											sub.id
												? "flex"
												: "none",
										width: "100%",
										borderRadius: 13,
										// backgroundColor:
										// 	"rgba(52, 52, 52, 0.12)",
										// backgroundcolor: this.state.bgColor1,
										padding: 10,
										marginBottom: 25,
										opacity: 0.9,
									}}>
									{sub.checkList.map((s, ii) => {
										return (
											<CheckBox
												title={s.title}
												fontSize={16}
												checked={s.checked}
												checkBoxStyle={3}
												checkedItem={async () => {
													// this.changeCompletion(
													// 	s.id,
													// 	!s.status,
													// );
												}}
												delete={() => {
													// this.deleteSubTasks(s.id);
												}}
											/>
										);
									})}
								</View>
							</View> */}
						</TouchableOpacity>

						<View
							style={{
								flex: 1,
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "row",
								backgroundColor:
									isDueToday || isPastDue
										? APP_COLOURS.RED
										: TaskStatus(sub.status),

								borderTopRightRadius: 10,
								borderBottomRightRadius: 10,
								opacity: isDueToday ? 1 : 0.8,
								padding: 2,
							}}>
							{sub.status === "Completed" ? null : (
								<Text
									ellipsizeMode='clip'
									style={{
										color: this.state.fontColor,
										fontSize: this.state.fontSize - 3,
										fontWeight: "700",
									}}>
									Due{" "}
									{isDueToday
										? "today"
										: moment(sub.end_date)
												.subtract(1, "day")
												.fromNow()}
								</Text>
							)}
						</View>

						<View
							style={{
								display: right <= 0 ? "none" : "flex",
								flex: right <= 0 ? 0 : right,
								alignItems: "center",
								justifyContent: "flex-start",
								flexDirection: "row",
							}}>
							{/* {this.assignment(sub, 25)} */}
						</View>
					</View>
				</View>
			);
		}
	};

	selectedTaskItem = () => {
		return (
			<View
				style={{
					position: "absolute",
					right: 10,
					bottom: 10,
					// backgroundColor: "rgba(0,0, 0, 0.3)",
					width: 200,
					height: 200,
					borderRadius: 20,
					padding: 20,
				}}>
				<View
					style={{
						flex: 1,
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
					}}>
					<TouchableOpacity
						onPress={() =>
							this.updateItem(
								this.state.selectedSubTask.id,
								"right",
								"minus",
							)
						}>
						<View
							style={{
								width: 60,
								justifyContent: "center",
								alignItems: "center",
							}}>
							<Image
								style={{
									width: 40,
									height: 40,
									resizeMode: "contain",
								}}
								source={require("../../../assets/images/min_btn2.png")}
							/>
						</View>
					</TouchableOpacity>
					<TouchableOpacity
						onPress={() =>
							this.updateItem(
								this.state.selectedSubTask.id,
								"right",
								"plus",
							)
						}>
						<View
							style={{
								width: 60,
								justifyContent: "center",
								alignItems: "center",
							}}>
							<Image
								style={{
									width: 40,
									height: 40,
									resizeMode: "contain",
								}}
								source={require("../../../assets/images/add_btn2.png")}
							/>
						</View>
					</TouchableOpacity>
				</View>
				<View
					style={{
						flex: 1,
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
					}}>
					<TouchableOpacity
						onPress={() =>
							this.updateItem(
								this.state.selectedSubTask.id,
								"left",
								"move",
							)
						}>
						<View
							style={{
								marginRight: 4,
							}}>
							<Image
								style={{
									width: 60,
									height: 60,
									resizeMode: "contain",
								}}
								source={require("../../../assets/images/left_btn.png")}
							/>
						</View>
					</TouchableOpacity>

					<TouchableOpacity
						onPress={() =>
							this.updateItem(
								this.state.selectedSubTask.id,
								"right",
								"move",
							)
						}>
						<View
							style={{
								marginLeft: 2,
							}}>
							<Image
								style={{
									width: 60,
									height: 60,
									resizeMode: "contain",
								}}
								source={require("../../../assets/images/right_btn.png")}
							/>
						</View>
					</TouchableOpacity>
				</View>
				<View
					style={{
						flex: 1,
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "row",
					}}>
					{/* <TouchableOpacity
						onPress={() =>
							this.updateItem(
								this.state.selectedSubTask.id,
								"status",
								this.state.currentStatus,
							)
						}>
						<View
							style={{
								width: 100,
								height: "auto",
								padding: 10,
								borderRadius: 10,
								justifyContent: "center",
								alignItems: "center",
								backgroundColor:
									this.state.currentStatus == "pending"
										? "#FF5F5F"
										: this.state.currentStatus == "complete"
										? "rgba(0,0, 0, 0.3)"
										: "#E0A749",
							}}>
							{this.state.currentStatus == "complete" ? (
								<Image
									style={{
										width: 20,
										height: 20,
										resizeMode: "contain",
									}}
									source={require("../../../assets/images/Checkmark.png")}
								/>
							) : null}
							<Text
								style={{
									color: this.state.fontColor,
									fontWeight: "500",
								}}>
								{this.state.currentStatus}
							</Text>
						</View>
					</TouchableOpacity> */}

					{/* <TouchableOpacity
						onPress={() => {
							// console.log(this.state.selectedSubTask);
							// return;
							crudDelete(
								"projects_tasks",
								this.state.selectedSubTask,
								() => {
									// this.removeFromArray(
									// 	this.state.currentSubItemId,
									// 	"tasks",
									// );
									this.setState({
										selectedSubTask: "",
									});
									this.props.reloadData();
								},
							);
						}}
						style={{
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							padding: 3,
							marginLeft: 5,
						}}>
						<Image
							source={bin}
							style={{
								width: 38,
								height: 38,
								marginRight: 5,
								resizeMode: "contain",
							}}
						/>
					</TouchableOpacity> */}
				</View>
			</View>
		);
	};

	selectedMilestoneComp = (ddd) => {
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "flex-start",
						alignItems: "center",
						// backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<ScrollView>
						<View
							style={{
								width: 850 - 40,
								maxWidth: 850,
								height: "auto",
								backgroundColor: "white",
								// alignItems: 'center',
								borderRadius: 12,
								paddingTop: 20,
								paddingBottom: 10,
								padding: 10,
								marginTop: 40,
							}}>
							<EditMilestone
								d={ddd}
								projectData={this.state.selectedProject}
								updateMoveTask={this.updateItem.bind(this)}
								reloadData={() => this.props.reloadData()}
								close={() =>
									this.setState({
										selectedMilestoneComp: "",
									})
								}
							/>
						</View>
					</ScrollView>
				</View>
			</Modal>
		);
	};

	selectedTaskComp = (d) => {
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "flex-start",
						alignItems: "center",
						backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<ScrollView>
						<View
							style={{
								width: 850 - 40,
								maxWidth: 850,
								height: "auto",
								backgroundColor: "white",
								// alignItems: 'center',
								borderRadius: 12,
								paddingTop: 20,
								paddingBottom: 10,
								padding: 10,
								marginTop: 40,
							}}>
							<EditTask
								d={d}
								milestoneData={this.state.selectedMilestoneData}
								projectData={this.state.selectedProject}
								selectedProject={this.state.selectedProject}
								updateMoveTask={this.updateItem.bind(this)}
								reloadData={() => this.props.reloadData()}
								close={() =>
									this.setState({
										selectedSubTask: "",
										showEditModal: false,
										selectedProject: "",
										selectedMilestoneData: "",
									})
								}
							/>
						</View>
					</ScrollView>
				</View>
			</Modal>
		);
	};

	calendarBars = () => {
		return this.state.calendar.map((d, i) => (
			<View
				style={{
					flex: 1,
					marginleft: 1,
				}}>
				<View
					style={{
						height: 40,
						// backgroundColor: "#026BFA",
						backgroundcolor: this.state.bgColor1,
						padding: 5,
						marginRight: 0.5,
					}}>
					<Text
						style={{
							// color: this.state.fontColor, // '#5A7BBA'
							color: "#FFF",
							fontSize: 13,
							fontWeight: "900",
							marginBottom: 16,
						}}>
						Week {d.week}
					</Text>
				</View>

				<View
					style={{
						flex: 1,
						flexDirection: "row",
						borderRightWidth: 0.5,
						borderRightColor: this.state.borderLeftColour,
					}}>
					{d.days.map((d_, i_) => {
						let today = moment().format("DD MM");
						let day = moment(d_).format("DD MM");

						let dayName = moment(d_).format("ddd");

						let isWeekend = false;
						if (dayName == "Sat" || dayName == "Sun") {
							isWeekend = true;
						}

						let isToday = today == day;

						let startOfMonth = false;
						if (moment(d_).format("D") == 1) {
							startOfMonth = true;
						}

						return (
							<View
								key={i_}
								style={{
									flex: 1,
									borderRightWidth: isToday ? 2 : 0.5,
									borderRightColor: isToday
										? "rgba(255,0, 0, 0.1)"
										: isWeekend
										? this.state.dateBorders
										: this.state.dateBorders,

									borderLeftColor: startOfMonth
										? this.state.borderLeftColour
										: "red",
									borderLeftWidth: isToday
										? 1
										: startOfMonth
										? 1
										: 0,

									backgroundColor: isToday
										? "rgba(255,0, 0, 0.1)"
										: isWeekend
										? "rgba(12,0, 0, 0.05)"
										: "transparent",
									padding: 2,
								}}>
								<Text
									adjustsFontSizeToFit
									numberOfLines={1}
									style={{
										color: this.state.fontColor,
										fontSize: this.state.fontSize - 3,
										fontWeight: "400",
									}}>
									{dayName}
								</Text>
								<Text
									adjustsFontSizeToFit
									numberOfLines={1}
									style={{
										color: this.state.fontColor,
										fontSize: this.state.fontSize,
										fontWeight: "700",
									}}>
									{moment(d_).format("DD")}
								</Text>
								<View
									style={{
										height: 10,
									}}>
									<Text
										style={{
											// display: startOfMonth ? 'flex' : 'none',
											color: this.state.fontColor,
											fontSize: this.state.fontSize - 3,
											fontWeight: "400",
										}}>
										{moment(d_).format("MMM")}
									</Text>
								</View>
							</View>
						);
					})}
				</View>
			</View>
		));
	};

	assignment = (d, size) => {
		let amountAvatars = 5;
		return (
			<View
				style={{
					flex: 1,
					flexDirection: "row",
					width:
						d.assignment.length >= amountAvatars
							? amountAvatars * size
							: d.assignment.length * size,
					justifyContent: "center",
					// backgroundColor: "red",
					alignItems: "center",
				}}>
				{d.assignment
					.filter((d) => d.id !== this.props.user_data.id)
					.slice(0, amountAvatars)
					.map((ass, i) => {
						return (
							<View
								key={ass.id}
								style={{
									flexDirection: "row",
									// justifyContent: "flex-start",
									alignItems: "center",
									backgroundColor: "#FFF",
									width: size,
									height: size,
									borderRadius: size / 2,
									borderWidth: 2,
									borderColor: "#FFF",
									shadowColor: "#6494B7",
									shadowOffset: {
										width: 0,
										height: 4,
									},
									shadowOpacity: 0.23,
									shadowRadius: 4.65,
									elevation: 8,
									left: (size / 2.2) * -i,
									// position: "absolute",
									// top: 0,
									zIndex: i + 1,
								}}>
								<Image
									source={Avatars[parseInt(ass.avatar)].pp}
									style={{
										width: size,
										height: size,
										borderRadius: size / 2,
										resizeMode: "contain",
										backgroundcolor: this.state.bgColor1,
										overflow: "hidden",
									}}
								/>
							</View>
						);
					})}
				{d.assignment.length > amountAvatars ? (
					<View
						style={{
							backgroundColor: "#252525",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							//   position: 'absolute',
							right: size / -3,
							width: size,
							height: size,

							borderRadius: 15,
						}}>
						<Text
							style={{
								color: "#FFF",
								fontSize: 10,
							}}>
							+{d.assignment.length - amountAvatars}
						</Text>
					</View>
				) : null}
			</View>
		);
	};
}
