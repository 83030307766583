import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import { Avatars, StatusColour, TaskStatus } from "../../APP_VARS";
import {
	crudCreate,
	crudDelete,
	crudRead,
	crudReadAll,
	crudUpdate,
} from "../../CRUDHELPER";
import StatusItem from "../DailyPlanner/StatusItem";
import UsersSelectList from "../UsersSelectList";
import AutoHeightImage from "../AutoHeightImageComp";
import ProcessFlow from "./MerchandisingSteps";
import TopTabs from "../TopTabs";
import CSVToJsonConverter from "./CsvUploader";
import TSVToJsonConverter from "./TsvUploader";
import JsonTable from "./JSONTable";
import DynamicSpreadsheetTable from "./DynamicSpreadsheetTable";
import DynamicSpreadSheet from "./DynamicSpreadSheet";
import ListingRequests from "./ListingRequests";
import SmallerTabs from "../TopTabs/SmallerTabs";

function uuid() {
	return "xxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

function firstAndLast(array) {
	var firstItem = array[0];
	var lastItem = array[array.length - 1];

	var objOutput = {
		start: firstItem,
		end: lastItem,
	};

	return objOutput;
}

function calculateTotal(data) {
	let total = 0;
	data.forEach((item) => {
		total += parseFloat(item.total);
	});
	return total;
}

export default class MerchandisingIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			services: [
				{
					title: "Driving traffic, customer acquisition, customer journey & conversions through: ",
					description: `Social - linking
               identifying social media plans to align with retailers strategic periods
               work closely with PR to ensure always-on social content calendars are aligned and shared monthly with
retailers
               `,
					createdAt: "2023-10-31T00:27:41.929Z",
					createdBy: "6zRVgfKEgXcmbwoZnKzv",
					id: "tcGru4emohYe9NXujEB5",
					start_date: "2023-10-31T22:00:00.000Z",
					end_date: "",
				},
				{
					title: "Identifying social media plans to align with retailers strategic periods",
					description:
						"work closely with PR to ensure always-on social content calendars are aligned and shared monthly with retailers to drive awareness, provide value, prompt engagement and drive conversion",
					createdAt: "2023-10-31T00:27:41.929Z",
					createdBy: "6zRVgfKEgXcmbwoZnKzv",
					id: "tcGru4emohYe9NXujEB5",
					start_date: "2023-10-31T22:00:00.000Z",
					end_date: "",

					status: "In Progress",
				},
				{
					title: "Identifying social media plans to align with retailers strategic periods",
					description:
						"work closely with PR to ensure always-on social content calendars are aligned and shared monthly with retailers to drive awareness, provide value, prompt engagement and drive conversion",
					createdAt: "2023-10-31T00:27:41.929Z",
					createdBy: "6zRVgfKEgXcmbwoZnKzv",
					id: "tcGru4emohYe9NXujEB5",
					start_date: "2023-10-31T22:00:00.000Z",
					end_date: "",

					status: "In Progress",
				},
			],

			selectedTab: "Listings",

			jsonResult: [],
		};
	}

	render() {
		return (
			<View
				style={{
					borderRadius: 10,
					width: "100%",
					flex: 1,
					opacity: this.state.loading ? 0 : 1,
				}}>
				<View
					style={{
						// width: "100%",
						flex: 1,
						// flexDirection: "row",
					}}>
					<View style={{ marginRight: 1 }}>
						<View
							style={{
								flex: 1,
								// flexDirection: "row",
								marginBottom: 5,
								// maxWidth: 600,
								// paddingBottom: 60,
								backgroundColor: "#FFF",
								padding: 5,
								borderRadius: 10,
							}}>
							<View
								style={{
									width: "100%",
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
								}}>
								<View
									style={{
										padding: 10,
									}}>
									<TouchableOpacity
										style={{
											flexDirection: "row",
											alignItems: "center",
											// position: "absolute",
										}}
										onPress={() => {
											// this.props.close();
										}}>
										<AutoHeightImage
											width={20}
											source={require("../../../assets/images/menu.png")}
										/>
										<Text
											adjustsFontSizeToFit
											style={{
												fontWeight: "800",
												fontFamily: "Gotham",
												color: APP_COLOURS.BG2,
												fontSize: 13,
												marginLeft: 4,
											}}>
											tetrice
										</Text>
									</TouchableOpacity>
									<Text
										style={{
											color: APP_COLOURS.BG2,
											fontSize: 28,
											fontWeight: "900",
											fontFamily: "Gotham",
											// marginTop: 20,
											// marginTop: 6,
										}}>
										Digital Merchandising
									</Text>

									<Text
										style={{
											fontSize: 16,
											color: APP_COLOURS.BG2,
											fontWeight: "400",
											padding: 2,
											// marginBottom: 20,
										}}>
										Online E-comm and owned DTC platforms
									</Text>
								</View>
								<View
									style={
										{
											// position: "absolute",
											// right: 10,
											// top: 10,
										}
									}>
									<AutoHeightImage
										width={90}
										source={require("../../../assets/images/workfromhome.png")}
									/>
								</View>
							</View>

							{/* <TopTabs
								{...this.state}
								screenHeight={this.props.screenHeight}
								screenWidth={this.props.screenWidth}
								selectedSubPage={(d) =>
									this.setState({
										selectedTab: d,
									})
								}
								tabs={[
									"Listings",

									"Master Data",
									"Instructions",
								]}
							/> */}

							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "space-between",
								}}>
								<SmallerTabs
									selectedSubPage={(d) => {
										this.setState({
											selectedTab: d,
										});
										console.log(d);
									}}
									tabs={[
										"Listings",

										"Master Data",
										"Instructions",
									]}
								/>
							</View>
						</View>
					</View>

					{this.state.selectedTab === "Master Data Uploader" ? (
						<View
							style={{
								flex: 1,
								backgroundColor: "#FFF",
								padding: 5,
								borderRadius: 10,
							}}>
							<View
								style={{
									flex: 1,
								}}>
								<View
									style={{
										display:
											this.state.jsonResult.length > 0
												? "none"
												: "flex",
									}}>
									<TSVToJsonConverter
										jsonReturn={(jsonReturn) => {
											this.setState({
												jsonResult: jsonReturn,
											});
										}}
									/>
								</View>

								<ScrollView>
									{this.state.jsonResult.length > 0 && (
										<JsonTable
											jsonData={this.state.jsonResult}
										/>
									)}
								</ScrollView>
							</View>
						</View>
					) : null}
					{this.state.selectedTab === "Instructions" ? (
						<View
							style={{
								flex: 1,
								backgroundColor: "#FFF",
								padding: 5,
								borderRadius: 10,
							}}>
							<View
								style={{
									width: "100%",

									paddingBottom: 2,
								}}>
								<View
									style={{
										flexDirection: "row",
										alignItems: "center",
									}}>
									<View>
										<Text
											style={{
												fontSize: 16,
												color: APP_COLOURS.BG2,
												fontWeight: "800",
												padding: 5,
												marginRight: 15,
											}}>
											Support focuses
										</Text>
										<Text
											style={{
												fontSize: 14,
												color: APP_COLOURS.BG2,
												fontWeight: "300",
												padding: 5,
												marginRight: 15,
											}}>
											Break down of all service offerings
											under Digitl support SLA.
										</Text>
									</View>
									{/* <View
										style={{
											flex: 1,
											justifyContent: "flex-end",
											alignItems: "flex-end",
										}}>
										<Image
											style={{
												width: 250,
												height: 150,
												resizeMode: "contain",
											}}
											source={require("../../../assets/images/contentCalHead.png")}
										/>
									</View> */}
								</View>
								<View
									style={{
										// flex: 1,
										width: "100%",
										flexDirection: "row",
										alignItems: "center",
										height: 45,
									}}>
									<TouchableOpacity
										style={{
											marginRight: 10,
											marginLeft: 10,
										}}
										onPress={() =>
											this.setState({
												showInput:
													!this.state.showInput,
											})
										}>
										<AutoHeightImage
											width={19}
											source={require("../../../assets/images/searchIcon.png")}
										/>
									</TouchableOpacity>
									{this.state.loading ? (
										<ActivityIndicator
											color={APP_COLOURS.PRIMARY}
										/>
									) : null}
									<View
										style={{
											// height: "auto",
											// width: "100%",
											flex: 1,
											minWidth: 200,
											// maxWidth: 400,
											backgroundColor:
												APP_COLOURS.BLACK_TRANS,
											borderRadius: 13,
										}}>
										{this.state.showInput ? (
											<TextInput
												style={{
													fontWeight: "400",
													fontFamily: "Gotham",

													width: "100%",
													paddingLeft: 15,
													padding: 10,
													fontSize: 12,
													borderRadius: 13,
													marginRight: 15,
													color: APP_COLOURS.BG2,
													// backgroundColor:
													// 	APP_COLOURS.WHITE_TRANSPARENT4,
													backgroundColor: "#E8E8E8",
													fontWeight: "500",
												}}
												placeholderTextColor={"#797979"}
												autoCapitalize='sentences'
												clearButtonMode='while-editing'
												autoCompleteType='off'
												editable={true}
												autoCorrect={true}
												autoFocus
												keyboardType='default'
												textContentType='none'
												placeholder={` Search `}
												// defaultValue={this.state.user_data.email}
												defaultValue={
													this.state.filterSearch
												}
												onChangeText={(text) => {
													console.log(text);
													this.setState({
														filterSearch: text,
													});
												}}
											/>
										) : null}
									</View>

									<TouchableOpacity
										style={{
											// marginRight: 15,
											marginLeft: 15,
											backgroundColor: APP_COLOURS.BG2,
											borderRadius: 15,
											flexDirection: "row",
											paddingVertical: 10,
											alignItems: "center",
											paddingHorizontal: 15,
										}}
										onPress={async () => {
											this.setState({
												addNewService:
													!this.state.addNewService,
											});
										}}>
										<Text
											style={{
												color: "#FFF",
												fontSize: 14,
												marginRight: 10,
											}}>
											+
										</Text>
										<Text
											style={{
												color: "#FFF",
												fontSize: 12,
											}}>
											New
										</Text>
									</TouchableOpacity>
								</View>
							</View>

							<View
								style={{
									flex: 1,
								}}>
								<ScrollView>
									{/* {this.state.services
										.sort((a, b) => a.title > b.title)

										.filter((d_) => {
											let filter = d_;

											if (
												typeof this.state.filterTab !==
													"undefined" &&
												this.state.filterTab !== ""
											) {
												let text_ =
													this.state.filterTab;
												var outString = text_.replace(
													/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
													"",
												);

												let join_names = `${d_.brand?.title} ${d_.sla_id}`;

												join_names = join_names.replace(
													/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
													"",
												);

												return join_names
													.toLowerCase()
													.includes(
														outString.toLowerCase(),
													);
											}

											return filter;
										})

										.map((d, i) => this.serviceItem(d))} */}

									<ProcessFlow />
								</ScrollView>
							</View>
						</View>
					) : null}

					{this.state.selectedTab === "Listings" ? (
						<View
							style={{
								flex: 1,
								backgroundColor: "#FFF",
								padding: 5,
								borderRadius: 10,
							}}>
							<ListingRequests
								{...this.state}
								screenHeight={this.props.screenHeight}
								screenWidth={this.props.screenWidth}
							/>
						</View>
					) : null}

					{this.state.selectedTab === "Master Data" ? (
						<DynamicSpreadSheet />
					) : null}
				</View>
				{this.state.showModalArea ? (
					<Modal
						animationType='fade'
						transparent={true}
						visible={true}>
						<View
							style={{
								width: this.props.screenWidth,
								height: this.props.screenHeight,
								backgroundColor: "rgba(52, 52, 52, 0.6)",
							}}>
							<TouchableOpacity
								activeOpacity={1}
								style={{
									width: this.props.screenWidth,
									height: this.props.screenHeight,
									backgroundColor: "rgba(52, 52, 52, 0.6)",
									zIndex: 10,
									position: "absolute",
									top: 0,
								}}
								onPress={() => {
									this.setState({
										showModalArea: false,
									});
								}}
							/>
							<View
								style={{
									top: this.state.yPos + 10,
									left: this.state.xPos - 270,
									width: 350,
									height: 300,
									// backgroundColor: APP_COLOURS.BG,
									backgroundColor: "#FFF",
									borderRadius: 15,
									padding: 10,
									zIndex: 999,
									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 4,
									},
									shadowOpacity: 0.23,
									shadowRadius: 4.65,
									elevation: 8,
								}}>
								<UsersSelectList
									assignment={
										this.state.selectedService.assignment
									}
									assignment_ids={
										this.state.selectedService
											.assignment_ids
									}
									isStandalone={true}
									addAssignment={(ass, ids) => {
										this.setState({
											showModalArea: "",
										});
										// this.addAssignment(ass, ids);
										console.log(
											this.state.selectedService.id,
											ass,
											ids,
										);

										let obj = {
											id: this.state.selectedService.id,
											assignment: ass,
											assignment_ids: ids,
										};
										crudUpdate(
											this.state.crudDocumentName,
											obj,
											(status, d) => {
												// console.log(status, d);
												console.log("updateItem");
												if (status === 200) {
													this.getAllServices();
												}
											},
										);
									}}
								/>
							</View>
						</View>
					</Modal>
				) : null}

				{this.state.showSla ? (
					<Modal
						animationType='fade'
						transparent={true}
						visible={true}>
						<View
							style={{
								width: this.props.screenWidth,
								height: this.props.screenHeight,
								backgroundColor: "rgba(52, 52, 52, 0.6)",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<TouchableOpacity
								activeOpacity={1}
								style={{
									width: this.props.screenWidth,
									height: this.props.screenHeight,
									backgroundColor: "rgba(52, 52, 52, 0.6)",
									zIndex: 10,
									position: "absolute",
									top: 0,
								}}
								onPress={() => {
									this.setState({
										showSla: false,
									});
								}}
							/>
							<View
								style={{
									top: this.state.yPos + 10,
									left: this.state.xPos - 270,
									width: "90%",
									maxWidth: 1200,
									height: window.innerHeight * 0.95,
									// backgroundColor: APP_COLOURS.BG,
									backgroundColor: "#FFF",
									borderRadius: 5,
									padding: 10,
									zIndex: 999,
									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 4,
									},
									shadowOpacity: 0.23,
									shadowRadius: 4.65,
									elevation: 8,
								}}></View>
						</View>
					</Modal>
				) : null}
			</View>
		);
	}

	async componentDidMount() {
		// this.getAllServices();
	}

	getAllServices = () => {
		if (this.props.user_data.id === "") {
			return;
		}

		// {
		// 	field: "assignment_ids",
		// 	action: "array-contains",
		// 	value: this.props.user_data.id,
		// }
		console.log(this.props.user_data.id);
		crudRead(
			this.state.crudDocumentName,
			{
				field: "assignment_ids",
				action: "array-contains",
				value: this.props.user_data.id,
			},
			async (status, d) => {
				console.log(status, d);
				if (status === 200) {
					this.setState({
						services: d,
						loading: false,
					});
					this.setUniqueServiceTitles(d);
				}
			},
		);
	};

	serviceItem = (service) => {
		let size = 25;
		let fs = 14;
		return (
			<View
				onMouseEnter={() => this.setState({ mainHover: service.id })}
				onMouseLeave={() => this.setState({ mainHover: "" })}
				key={`services-${service.referenceId}`}
				style={{
					width: "100%",
					marginBottom: 0.5,
					opacity: service.status === "Completed" ? 0.2 : 1,
				}}>
				<View
					style={{
						width: this.state.leftPanelMainWidth,
					}}
				/>
				<View>
					<View
						onMouseEnter={() =>
							this.setState({ hover: service.id })
						}
						onMouseLeave={() => this.setState({ hover: "" })}
						style={{
							width: "100%",
							flexDirection: "row",
							alignItems: "center",
							// backgroundColor: "#FFF",
							borderTopColor: "#797979",
							borderTopWidth: 0.5,
						}}>
						<View style={{ flex: 1 }}>
							<View
								style={{
									// width: this.state.leftPanelWidth,
									flexDirection: "row",
									alignItems: "center",
									padding: 5,
									// justifyContent: "space-between",
									backgroundColor: "#FFF",
									marginRight: 2,
									// borderTopLeftRadius: 5,
									borderTopRightRadius: 5,
									paddingLeft: 6,
								}}>
								<View
									style={{
										flex: 1,
										flexWrap: "wrap",
									}}>
									<Text
										style={{
											color: APP_COLOURS.BG2, // '#5A7BBA'
											fontSize: 15,
											fontWeight: "500",
										}}>
										{service.title}
									</Text>
									<Text
										style={{
											flex: 1,
											flexWrap: "wrap",
											color: APP_COLOURS.BG2, // '#5A7BBA'
											fontSize: 14,
											fontWeight: "300",
										}}>
										{service.description}
									</Text>
								</View>

								<View
									style={{
										width: 85,
									}}>
									<Text
										ellipsizeMode='tail'
										style={{
											color: APP_COLOURS.BG2, // '#5A7BBA'
											fontSize: 12,
											fontWeight: "300",
										}}>
										{moment(service.start_date).format(
											"DD/MM/YYYY",
										)}
									</Text>
								</View>
								<View
									style={{
										width: 150,
									}}>
									<StatusItem
										{...this.props}
										{...service}
										fs={14}
										handleStatusChange={(_id, _status) => {
											let obj = {
												id: _id,
												status: _status,
											};
											crudUpdate(
												this.state.crudDocumentName,
												obj,
												(status, d) => {
													// console.log(status, d);
													console.log("updateItem");
													if (status === 200) {
														this.getAllServices();
													}
												},
											);
										}}
									/>
								</View>
							</View>
						</View>
					</View>
				</View>
			</View>
		);
	};
}
