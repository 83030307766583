import React from "react";

function GoogleSheetStream() {
	const googleSlidesUrl =
		"https://docs.google.com/presentation/d/e/2PACX-1vTup1qp00caXH53TdoBhXPAlj6JMz5TvGKZ5vsmpUt_tHPCPmDsdFyB8Nr4jawR-w/embed?start=true&loop=true&delayms=3000";
	return (
		<div>
			<iframe
				src={googleSlidesUrl}
				width='100%'
				height={window.innerHeight}
				allowFullScreen={true}></iframe>
		</div>
	);
}

export default GoogleSheetStream;
