import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import JsonTable from "./JSONTable";
import AutoHeightImage from "../AutoHeightImageComp";
import Paginater from "./Paginate";

export default class JSONTable extends Component {
	constructor(props) {
		super(props);
		this.state = { filterTable: "", data: this.props.jsonData };
	}

	render() {
		const styles = {
			headerCell: {
				border: "1px solid #ddd",
				padding: "8px",
				textAlign: "left",
				backgroundColor: "#f2f2f2",
			},
			cell: {
				border: "1px solid #ddd",
				padding: "8px",
				textAlign: "left",
			},
		};
		let data = this.state.data;
		const columnNames = data.length > 0 ? Object.keys(data[0]) : [];

		return (
			<View
				style={{
					flex: 1,
				}}>
				<View
					style={{
						width: "100%",

						paddingBottom: 2,
					}}>
					<View
						style={{
							flexDirection: "row",
							alignItems: "center",
						}}>
						<View>
							<Text
								style={{
									fontSize: 16,
									color: APP_COLOURS.BG2,
									fontWeight: "800",
									padding: 5,
									marginRight: 15,
								}}>
								Product Master database
							</Text>
						</View>
					</View>
					<View
						style={{
							// flex: 1,
							width: "100%",
							flexDirection: "row",
							alignItems: "center",
							height: 45,
						}}>
						<TouchableOpacity
							style={{
								marginRight: 10,
								marginLeft: 10,
							}}
							onPress={() =>
								this.setState({
									showInput: !this.state.showInput,
								})
							}>
							<AutoHeightImage
								width={19}
								source={require("../../../assets/images/searchIcon.png")}
							/>
						</TouchableOpacity>
						{this.state.loading ? (
							<ActivityIndicator color={APP_COLOURS.PRIMARY} />
						) : null}
						<View
							style={{
								// height: "auto",
								// width: "100%",
								flex: 1,
								minWidth: 200,
								// maxWidth: 400,
								backgroundColor: APP_COLOURS.BLACK_TRANS,
								borderRadius: 13,
							}}>
							<TextInput
								style={{
									fontWeight: "400",
									fontFamily: "Gotham",

									width: "100%",
									paddingLeft: 15,
									padding: 10,
									fontSize: 12,
									borderRadius: 13,
									marginRight: 15,
									color: APP_COLOURS.BG2,
									// backgroundColor:
									// 	APP_COLOURS.WHITE_TRANSPARENT4,
									backgroundColor: "#E8E8E8",
									fontWeight: "500",
								}}
								placeholderTextColor={"#797979"}
								autoCapitalize='sentences'
								clearButtonMode='while-editing'
								autoCompleteType='off'
								editable={true}
								autoCorrect={true}
								autoFocus
								keyboardType='default'
								textContentType='none'
								placeholder={` Search `}
								// defaultValue={this.state.user_data.email}
								defaultValue={this.state.filterTable}
								onChangeText={(text) => {
									console.log(text);
									this.setState({
										filterTable: text,
									});
								}}
							/>
						</View>

						<TouchableOpacity
							style={{
								// marginRight: 15,
								marginLeft: 15,
								backgroundColor: APP_COLOURS.BG2,
								borderRadius: 15,
								flexDirection: "row",
								paddingVertical: 10,
								alignItems: "center",
								paddingHorizontal: 15,
							}}
							onPress={async () => {
								this.setState({
									addNewService: !this.state.addNewService,
								});
							}}>
							<Text
								style={{
									color: "#FFF",
									fontSize: 14,
									marginRight: 10,
								}}>
								+
							</Text>
							<Text
								style={{
									color: "#FFF",
									fontSize: 12,
								}}>
								New
							</Text>
						</TouchableOpacity>
					</View>
				</View>
				<Paginater
					jsonData={data}
					cp={(setCurrentPage) => {
						console.log(setCurrentPage);
					}}
				/>

				<div style={{ overflowX: "auto" }}>
					<table
						style={{ width: "100%", borderCollapse: "collapse" }}>
						<thead>
							<tr>
								{columnNames.map((columnName, index) => (
									<th key={index} style={styles.headerCell}>
										{columnName}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{data
								.filter((d_) => {
									let filter = d_;

									if (
										typeof this.state.filterTable !==
											"undefined" &&
										this.state.filterTable !== ""
									) {
										let text_ = this.state.filterTable;
										var outString = text_.replace(
											/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
											"",
										);

										let join_names = `${d_["Variant Name"]} ${d_.Ingredients} ${d_["Sub-Brand"]} ${d_.Brand} ${d_["Product Name"]}`;

										join_names = join_names.replace(
											/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
											"",
										);

										return join_names
											.toLowerCase()
											.includes(outString.toLowerCase());
									}

									return filter;
								})
								.slice(0, 20)
								.map((item, rowIndex) => (
									<tr key={rowIndex}>
										{columnNames.map(
											(columnName, colIndex) => (
												<td
													key={colIndex}
													style={styles.cell}>
													<Text
														style={{}}
														numberOfLines={1}>
														{item[columnName]}
													</Text>
												</td>
											),
										)}
									</tr>
								))}
						</tbody>
					</table>
				</div>
			</View>
		);
	}
}
