import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import { Avatars, StatusColour, TaskStatus } from "../../APP_VARS";
import {
	crudCreate,
	crudDelete,
	crudRead,
	crudReadAll,
	crudUpdate,
} from "../../CRUDHELPER";
import Fade from "react-reveal/Fade";
import StatusItem from "../DailyPlanner/StatusItem";
import AddService from "./AddService";
import UsersSelectList from "../UsersSelectList";
import Masterservices from "./MasterServicesAgreement/Masterservices";

function uuid() {
	return "xxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

function firstAndLast(array) {
	var firstItem = array[0];
	var lastItem = array[array.length - 1];

	var objOutput = {
		start: firstItem,
		end: lastItem,
	};

	return objOutput;
}

function calculateTotal(data) {
	let total = 0;
	data.forEach((item) => {
		total += parseFloat(item.total);
	});
	return total;
}

class AutoHeightImage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<Image
				style={{
					width: this.props.width,
					height:
						typeof this.props.height !== "undefined"
							? this.props.height
							: this.props.width,
					resizeMode: "contain",
				}}
				source={this.props.source}
			/>
		);
	}
}

export default class OrganisationServices extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fontSize: 12,
			services: [],
			colours: ["#0468FC", "#E0A749", "#FF5F5F", "#45B88B", "#AF78FF"],
			filterTab: "",
			filterSearch: "",
			user_data: this.props.user_data,

			crudDocumentName: "services_organisation",

			uniqueServiceTitles: [],

			slas: [
				{
					title: "Community Management - REVLON & Personal Care",
					id: "SLA88421",
					total: "46305",
					brand: "REVLON",
					status: "In Progress",
					code: "CM",
				},
				{
					title: "Community Management - Elizabeth Arden",
					id: "SLA88321",
					total: "19845",
					brand: "Elizabeth Arden",
					status: "In Progress",
					code: "CM",
				},
				{
					title: "Content Creation - REVLON & Personal Care",
					id: "SLA88422",
					total: "153300",
					brand: "REVLON",
					status: "In Progress",
					code: "CC",
				},
				{
					title: "Content Creation - Elizabeth Arden",
					id: "SLA88322",
					total: "67400",
					brand: "Elizabeth Arden",
					status: "In Progress",
					code: "CC",
				},

				{
					title: "Digital Merchandising - REVLON & Personal Care",
					id: "SLA88423",
					total: "12950",
					brand: "REVLON",
					status: "In Progress",
					code: "DM",
				},
				{
					title: "Digital Merchandising - Elizabeth Arden",
					id: "SLA88323",
					total: "5550",
					brand: "Elizabeth Arden",
					status: "In Progress",
					code: "DM",
				},
				{
					title: "Secondment - REVLON & Personal Care",
					id: "SLA88424",
					total: "20125",
					brand: "REVLON",
					status: "In Progress",
					code: "SC",
				},
				{
					title: "Secondment - Elizabeth Arden",
					id: "SLA88324",
					total: "8625",
					brand: "Elizabeth Arden",
					status: "In Progress",
					code: "SC",
				},
				{
					title: "Tech support - REVLON & Personal Care",
					id: "SLA88425",
					total: "0",
					brand: "REVLON",
					status: "In Progress",
					code: "TS",
				},
				{
					title: "Tech support - Elizabeth Arden",
					id: "SLA88325",
					total: "0",
					brand: "Elizabeth Arden",
					status: "In Progress",
					code: "TS",
				},
				{
					title: "Skin Tools web portal - Elizabeth Arden",
					id: "SLA88302",
					total: "7500",
					brand: "Elizabeth Arden",
					status: "In Progress",
					code: "PWA",
				},
				{
					title: "Skin Transformation App - Elizabeth Arden",
					id: "SLA88312",
					total: "8270",
					brand: "Elizabeth Arden",
					status: "In Progress",
					code: "SaaS",
				},

				{
					title: "TRAQ -  Elizabeth Arden",
					id: "SLATRAQ",
					total: "6700",
					brand: "REVLON",
					status: "Pending",
					code: "SaaS",
				},
				{
					title: "Creme of nature web portal",
					id: "SLA88300",
					total: "7500",
					brand: "REVLON",
					status: "Paused",
					code: "PWA",
				},
			],
		};
	}

	render() {
		return (
			<View
				style={{
					flex: 1,
					padding: 10,
				}}>
				{/* <View
					style={{
						width: "100%",
						// height: 'auto',
						flexDirection: "row",
						alignItems: "center",
						padding: 5,
						// justifyContent: 'space-between',
						flexWrap: "wrap",
					}}>
					

					<View
						style={{
							flex: 1,
						}}
					/>
				</View> */}

				{this.state.addNewService ? (
					<AddService
						{...this.state}
						{...this.props}
						addEntry={(newService) => {
							crudCreate(
								this.state.crudDocumentName,
								newService,
								() => {
									this.setState((prevState) => ({
										services: [
											...prevState.services,
											newService,
										],
										addNewService: false,
									}));
								},
							);
						}}
						close={() =>
							this.setState({
								addNewService: false,
							})
						}
					/>
				) : null}
				{/* <View
					style={{
						// flex: 1,

						flexDirection: "row",
						width: "100%",
						justifyContent: "space-between",
					}}>
					<View
						style={{
							// flexDirection: "row",
							marginBottom: 5,
							maxWidth: 500,
						}}>
						<Text
							style={{
								fontSize: 14,
								color: APP_COLOURS.BG2,
								fontWeight: "800",
								padding: 5,
							}}>
							SLA (Service Level Agreements)
						</Text>
					</View>
					<View
						style={{
							flex: 1,
						}}>
						<Image
							style={{
								width: 250,
								height: 200,
								resizeMode: "contain",
							}}
							source={require("../../../assets/images/contentCalHead.png")}
						/>
					</View>
				</View> */}
				<View
					style={{
						// width: "100%",
						flex: 1,
						flexDirection: "row",
					}}>
					<View style={{ flex: 1, marginRight: 1 }}>
						<View style={{}}>
							<View
								style={{
									flex: 1,
									// flexDirection: "row",
									marginBottom: 5,
									maxWidth: 600,
									paddingBottom: 60,
									backgroundColor: "#FFF",
									padding: 5,
									borderRadius: 10,
								}}>
								<View
									style={{
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
									}}>
									<View
										style={{
											padding: 10,
										}}>
										<TouchableOpacity
											style={{
												flexDirection: "row",
												alignItems: "center",
												// position: "absolute",
											}}
											onPress={() => {
												// this.props.close();
											}}>
											<AutoHeightImage
												width={20}
												source={require("../../../assets/images/menu.png")}
											/>
											<Text
												adjustsFontSizeToFit
												style={{
													fontWeight: "800",
													fontFamily: "Gotham",
													color: APP_COLOURS.BG2,
													fontSize: 13,
													marginLeft: 4,
												}}>
												tetrice
											</Text>
										</TouchableOpacity>
										<Text
											style={{
												color: APP_COLOURS.BG2,
												fontSize: 28,
												fontWeight: "800",
												fontFamily: "Gotham",
												// marginTop: 20,
												// marginTop: 6,
											}}>
											Services
										</Text>

										<Text
											style={{
												fontSize: 16,
												color: APP_COLOURS.BG2,
												fontWeight: "800",
												padding: 2,
												marginBottom: 20,
											}}>
											SLA (Service Level Agreements)
										</Text>
									</View>
									<View
										style={
											{
												// position: "absolute",
												// right: 10,
												// top: 10,
											}
										}>
										{/* <Image
											style={{
												width: 200,
												height: 150,
												resizeMode: "contain",
											}}
											source={require("../../../assets/images/workfromhome.png")}
										/> */}
										<AutoHeightImage
											width={150}
											source={require("../../../assets/images/workfromhome.png")}
										/>
									</View>
								</View>

								{this.state.slas.map((d, i) => (
									<TouchableOpacity
										key={i}
										style={{}}
										onPress={() =>
											this.setState({
												filterTab: d.id,
											})
										}>
										<View
											style={{
												width: "auto",
												// justifyContent: "space-between",
												backgroundColor:
													this.state.filterTab ===
													d.id
														? APP_COLOURS.BLUE
														: APP_COLOURS.TEXTCOLOR,
												padding: 8,
												borderRadius: 5,
												marginRight: 5,
												flexDirection: "row",
												marginBottom: 1,
												alignItems: "center",
											}}>
											<View style={{ flex: 1 }}>
												<Text
													style={{
														fontSize: 12,
														color:
															this.state
																.filterTab ===
															d.id
																? "#FFF"
																: APP_COLOURS.BG2,
														fontWeight: "500",
														opacity: 0.7,
													}}>
													{d.id} - {d.code} -{" "}
												</Text>
												<Text
													style={{
														fontSize: 12,
														color:
															this.state
																.filterTab ===
															d.id
																? "#FFF"
																: APP_COLOURS.BG2,
														fontWeight: "500",
													}}>
													{d.title}
												</Text>
											</View>
											<View
												style={{
													padding: 2,
													alignItems: "center",
													backgroundColor:
														APP_COLOURS.RED,
													marginRight: 10,
												}}>
												<Text
													style={{
														fontSize: 9,
														color: "#FFF",
														fontWeight: "500",
													}}>
													UNSIGNED
												</Text>
											</View>
											<StatusItem
												{...this.props}
												{...d}
												status={d.status}
												fs={14}
												handleStatusChange={(
													_id,
													_status,
												) => {}}
											/>
										</View>
									</TouchableOpacity>
								))}
							</View>
						</View>

						<View
							style={{
								flex: 1,
								display: "none",
								// this.state.user_data.username ===
								// "wesley@tetrice.co.za"
								// 	? "flex"
								// 	: "none",
							}}>
							<View
								style={{
									// flexDirection: "row",
									marginBottom: 5,
									maxWidth: 500,
								}}>
								<Text
									style={{
										fontSize: 16,
										color: APP_COLOURS.BG2,
										fontWeight: "800",
										padding: 2,
										marginBottom: 20,
									}}>
									SLA (Service Level Agreements)
								</Text>
								<View
									style={{
										width: "100%",
										flexDirection: "row",
										justifyContent: "space-between",
									}}>
									<Text
										style={{
											fontSize: 12,
											color: APP_COLOURS.BG2,
											fontWeight: "800",
											padding: 2,
											marginBottom: 10,
										}}>
										REVLON
									</Text>
									<Text
										style={{
											fontSize: 12,
											color: APP_COLOURS.BG2,
											fontWeight: "800",
											padding: 2,
											marginBottom: 10,
										}}>
										R{" "}
										{calculateTotal(
											this.state.slas.filter(
												(d) => d.brand === "REVLON",
											),
										).toFixed(2)}
									</Text>
								</View>
								<View
									style={{
										width: "100%",
										flexDirection: "row",
										justifyContent: "space-between",
									}}>
									<Text
										style={{
											fontSize: 12,
											color: APP_COLOURS.BG2,
											fontWeight: "800",
											padding: 2,
											marginBottom: 10,
										}}>
										EA
									</Text>
									<Text
										style={{
											fontSize: 12,
											color: APP_COLOURS.BG2,
											fontWeight: "800",
											padding: 2,
											marginBottom: 10,
										}}>
										R{" "}
										{calculateTotal(
											this.state.slas.filter(
												(d) =>
													d.brand ===
													"Elizabeth Arden",
											),
										).toFixed(2)}
									</Text>
								</View>
								<View
									style={{
										width: "100%",
										flexDirection: "row",
										justifyContent: "space-between",
										borderTopWidth: 1,
									}}>
									<Text
										style={{
											fontSize: 12,
											color: APP_COLOURS.BG2,
											fontWeight: "800",
											padding: 2,
											marginBottom: 10,
										}}>
										Total excl
									</Text>
									<Text
										style={{
											fontSize: 12,
											color: APP_COLOURS.BG2,
											fontWeight: "800",
											padding: 2,
											marginBottom: 10,
										}}>
										R{" "}
										{calculateTotal(
											this.state.slas,
										).toFixed(2)}
									</Text>
								</View>

								{this.state.slas.map((d, i) => (
									<TouchableOpacity
										key={i}
										style={{}}
										onPress={() =>
											this.setState({
												filterTab: d.id,
											})
										}>
										<View
											style={{
												width: "auto",
												justifyContent: "space-between",
												backgroundColor:
													this.state.filterTab ===
													d.id
														? APP_COLOURS.BLUE
														: APP_COLOURS.TEXTCOLOR,
												padding: 8,
												borderRadius: 5,
												marginRight: 5,
												flexDirection: "row",
												marginBottom: 1,
											}}>
											<View>
												<Text
													style={{
														fontSize: 12,
														color:
															this.state
																.filterTab ===
															d.id
																? "#FFF"
																: APP_COLOURS.BG2,
														fontWeight: "500",
													}}>
													{d.title}
												</Text>
												<Text
													style={{
														fontSize: 12,
														color:
															this.state
																.filterTab ===
															d.id
																? "#FFF"
																: APP_COLOURS.BG2,
														fontWeight: "500",
														opacity: 0.7,
													}}>
													{d.id}
												</Text>
											</View>
											<Text
												style={{
													fontSize: 12,
													color:
														this.state.filterTab ===
														d.id
															? "#FFF"
															: APP_COLOURS.BG2,
													fontWeight: "500",
													opacity: 0.7,
												}}>
												R{" "}
												{parseFloat(d.total).toFixed(2)}
											</Text>
										</View>
									</TouchableOpacity>
								))}
							</View>
						</View>
					</View>

					<View style={{ flex: 2 }}>
						<View
							style={{
								flex: 1,
								paddingBottom: 60,
								backgroundColor: "#FFF",
								padding: 5,
								borderRadius: 10,
							}}>
							<View
								style={{
									width: "100%",

									paddingBottom: 2,
								}}>
								<View
									style={{
										flexDirection: "row",
										alignItems: "center",
									}}>
									<View>
										<Text
											style={{
												fontSize: 16,
												color: APP_COLOURS.BG2,
												fontWeight: "800",
												padding: 5,
												marginRight: 15,
											}}>
											SLA Service Codes & Documentation
										</Text>
										<Text
											style={{
												fontSize: 14,
												color: APP_COLOURS.BG2,
												fontWeight: "300",
												padding: 5,
												marginRight: 15,
											}}>
											Break down of all service offerings
											under each SLA.
										</Text>
									</View>
									{/* <View
										style={{
											flex: 1,
											justifyContent: "flex-end",
											alignItems: "flex-end",
										}}>
										<Image
											style={{
												width: 250,
												height: 150,
												resizeMode: "contain",
											}}
											source={require("../../../assets/images/contentCalHead.png")}
										/>
									</View> */}
								</View>
								<View
									style={{
										// flex: 1,
										width: "100%",
										flexDirection: "row",
										alignItems: "center",
										height: 45,
									}}>
									<TouchableOpacity
										style={{
											marginRight: 10,
											marginLeft: 10,
										}}
										onPress={() =>
											this.setState({
												showInput:
													!this.state.showInput,
											})
										}>
										<AutoHeightImage
											width={19}
											source={require("../../../assets/images/searchIcon.png")}
										/>
									</TouchableOpacity>
									{this.state.loading ? (
										<ActivityIndicator
											color={APP_COLOURS.PRIMARY}
										/>
									) : null}
									<View
										style={{
											// height: "auto",
											// width: "100%",
											flex: 1,
											minWidth: 200,
											// maxWidth: 400,
											backgroundColor:
												APP_COLOURS.BLACK_TRANS,
											borderRadius: 13,
										}}>
										{this.state.showInput ? (
											<TextInput
												style={{
													fontWeight: "400",
													fontFamily: "Gotham",

													width: "100%",
													paddingLeft: 15,
													padding: 10,
													fontSize: 12,
													borderRadius: 13,
													marginRight: 15,
													color: APP_COLOURS.BG2,
													// backgroundColor:
													// 	APP_COLOURS.WHITE_TRANSPARENT4,
													backgroundColor: "#E8E8E8",
													fontWeight: "500",
												}}
												placeholderTextColor={"#797979"}
												autoCapitalize='sentences'
												clearButtonMode='while-editing'
												autoCompleteType='off'
												editable={true}
												autoCorrect={true}
												autoFocus
												keyboardType='default'
												textContentType='none'
												placeholder={` Search `}
												// defaultValue={this.state.user_data.email}
												defaultValue={
													this.state.filterSearch
												}
												onChangeText={(text) => {
													console.log(text);
													this.setState({
														filterSearch: text,
													});
												}}
											/>
										) : null}
									</View>
									(
									<TouchableOpacity
										style={{
											// marginRight: 15,
											marginLeft: 15,
											backgroundColor: APP_COLOURS.BG2,
											borderRadius: 15,
											flexDirection: "row",
											paddingVertical: 10,
											alignItems: "center",
											paddingHorizontal: 15,
										}}
										onPress={async () => {
											this.setState({
												addNewService:
													!this.state.addNewService,
											});
										}}>
										<Text
											style={{
												color: "#FFF",
												fontSize: 14,
												marginRight: 10,
											}}>
											+
										</Text>
										<Text
											style={{
												color: "#FFF",
												fontSize: 12,
											}}>
											New
										</Text>
									</TouchableOpacity>
									)
								</View>
								<View style={{ flex: 1 }}>
									<ScrollView
										showsHorizontalScrollIndicator={false}
										horizontal>
										<View
											style={{
												flexDirection: "row",
											}}>
											<TouchableOpacity
												style={{}}
												onPress={() =>
													this.setState({
														filterTab: "",
													})
												}>
												<View
													style={{
														width: "auto",
														//  height: 50,
														justifyContent:
															"center",
														alignItems: "center",

														padding: 10,
														borderRadius: 12,
														marginRight: 5,
														backgroundColor:
															this.state
																.filterTab ===
															""
																? APP_COLOURS.BLUE
																: APP_COLOURS.WHITE_TRANSPARENT9,
													}}>
													<Text
														style={{
															fontSize: 12,
															color:
																this.state
																	.filterTab ===
																""
																	? "#FFF"
																	: APP_COLOURS.BLUE,
															fontWeight: "400",
															fontFamily:
																"Avenir Next",
														}}>
														All
													</Text>
												</View>
											</TouchableOpacity>
											{this.state.uniqueServiceTitles
												.filter((d_) => {
													let filter = d_;

													if (
														typeof this.state
															.filterSearch !==
															"undefined" &&
														this.state
															.filterSearch !== ""
													) {
														let text_ =
															this.state
																.filterSearch;
														var outString =
															text_.replace(
																/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
																"",
															);

														let join_names = `${d_}`;

														join_names =
															join_names.replace(
																/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
																"",
															);

														return join_names
															.toLowerCase()
															.includes(
																outString.toLowerCase(),
															);
													}

													return filter;
												})
												.map((d, i) => (
													<TouchableOpacity
														key={i}
														style={{}}
														onPress={() =>
															this.setState({
																filterTab: d,
															})
														}>
														<View
															style={{
																width: "auto",
																//   height: 50,
																justifyContent:
																	"center",
																alignItems:
																	"center",
																backgroundColor:
																	this.state
																		.filterTab ===
																	d
																		? APP_COLOURS.BLUE
																		: APP_COLOURS.WHITE_TRANSPARENT9,
																padding: 10,
																borderRadius: 10,
																marginRight: 5,
															}}>
															<Text
																style={{
																	fontSize: 12,
																	color:
																		this
																			.state
																			.filterTab ===
																		d
																			? "#FFF"
																			: APP_COLOURS.BG2,
																	fontWeight:
																		"500",
																}}>
																{d}
															</Text>
														</View>
													</TouchableOpacity>
												))}
										</View>
									</ScrollView>
								</View>
							</View>

							<ScrollView>
								{this.state.services
									.sort((a, b) => a.title > b.title)

									.filter((d_) => {
										let filter = d_;

										if (
											typeof this.state.filterTab !==
												"undefined" &&
											this.state.filterTab !== ""
										) {
											let text_ = this.state.filterTab;
											var outString = text_.replace(
												/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
												"",
											);

											let join_names = `${d_.brand?.title} ${d_.sla_id}`;

											join_names = join_names.replace(
												/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
												"",
											);

											return join_names
												.toLowerCase()
												.includes(
													outString.toLowerCase(),
												);
										}

										return filter;
									})

									.map((d, i) => this.serviceItem(d))}
							</ScrollView>
						</View>
					</View>
				</View>
				{this.state.showModalArea ? (
					<Modal
						animationType='fade'
						transparent={true}
						visible={true}>
						<View
							style={{
								width: this.props.screenWidth,
								height: this.props.screenHeight,
								backgroundColor: "rgba(52, 52, 52, 0.6)",
							}}>
							<TouchableOpacity
								activeOpacity={1}
								style={{
									width: this.props.screenWidth,
									height: this.props.screenHeight,
									backgroundColor: "rgba(52, 52, 52, 0.6)",
									zIndex: 10,
									position: "absolute",
									top: 0,
								}}
								onPress={() => {
									this.setState({
										showModalArea: false,
									});
								}}
							/>
							<View
								style={{
									top: this.state.yPos + 10,
									left: this.state.xPos - 270,
									width: 350,
									height: 300,
									// backgroundColor: APP_COLOURS.BG,
									backgroundColor: "#FFF",
									borderRadius: 15,
									padding: 10,
									zIndex: 999,
									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 4,
									},
									shadowOpacity: 0.23,
									shadowRadius: 4.65,
									elevation: 8,
								}}>
								<UsersSelectList
									assignment={
										this.state.selectedService.assignment
									}
									assignment_ids={
										this.state.selectedService
											.assignment_ids
									}
									isStandalone={true}
									addAssignment={(ass, ids) => {
										this.setState({
											showModalArea: "",
										});
										// this.addAssignment(ass, ids);
										console.log(
											this.state.selectedService.id,
											ass,
											ids,
										);

										let obj = {
											id: this.state.selectedService.id,
											assignment: ass,
											assignment_ids: ids,
										};
										crudUpdate(
											this.state.crudDocumentName,
											obj,
											(status, d) => {
												// console.log(status, d);
												console.log("updateItem");
												if (status === 200) {
													this.getAllServices();
												}
											},
										);
									}}
								/>
							</View>
						</View>
					</Modal>
				) : null}

				{this.state.showSla ? (
					<Modal
						animationType='fade'
						transparent={true}
						visible={true}>
						<View
							style={{
								width: this.props.screenWidth,
								height: this.props.screenHeight,
								backgroundColor: "rgba(52, 52, 52, 0.6)",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<TouchableOpacity
								activeOpacity={1}
								style={{
									width: this.props.screenWidth,
									height: this.props.screenHeight,
									backgroundColor: "rgba(52, 52, 52, 0.6)",
									zIndex: 10,
									position: "absolute",
									top: 0,
								}}
								onPress={() => {
									this.setState({
										showSla: false,
									});
								}}
							/>
							<View
								style={{
									top: this.state.yPos + 10,
									left: this.state.xPos - 270,
									width: "90%",
									maxWidth: 1200,
									height: window.innerHeight * 0.95,
									// backgroundColor: APP_COLOURS.BG,
									backgroundColor: "#FFF",
									borderRadius: 5,
									padding: 10,
									zIndex: 999,
									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 4,
									},
									shadowOpacity: 0.23,
									shadowRadius: 4.65,
									elevation: 8,
								}}>
								<Masterservices
									{...this.state}
									user_data={this.props.user_data}
									clientName={"REVLON SA (pty) ltd"}
									projectID={`REVLOn ELIZABETH ARDEN/v1.1`}
								/>
							</View>
						</View>
					</Modal>
				) : null}
			</View>
		);
	}

	async componentDidMount() {
		// this.setState({
		// 	...this.props,
		// });
		// console.log(this.props.user_data.id);
		this.getAllServices();
	}

	getAllServices = () => {
		if (this.props.user_data.id === "") {
			return;
		}

		// {
		// 	field: "assignment_ids",
		// 	action: "array-contains",
		// 	value: this.props.user_data.id,
		// }
		console.log(this.props.user_data.id);
		crudRead(
			this.state.crudDocumentName,
			{
				field: "assignment_ids",
				action: "array-contains",
				value: this.props.user_data.id,
			},
			async (status, d) => {
				console.log(status, d);
				if (status === 200) {
					this.setState({
						services: d,
						loading: false,
					});
					this.setUniqueServiceTitles(d);
				}
			},
		);
	};

	setUniqueServiceTitles = (arr) => {
		let uniqueServiceTitles = [];
		arr.forEach((service) => {
			if (!uniqueServiceTitles.includes(service.sla_id)) {
				uniqueServiceTitles.push(service.sla_id);
			}
		});
		console.log(uniqueServiceTitles);
		this.setState({ uniqueServiceTitles });
	};

	serviceItem = (service) => {
		let size = 25;
		let fs = 14;
		return (
			<View
				onMouseEnter={() => this.setState({ mainHover: service.id })}
				onMouseLeave={() => this.setState({ mainHover: "" })}
				key={`services-${service.referenceId}`}
				style={{
					width: "100%",
					marginBottom: 0.5,
					opacity: service.status === "Completed" ? 0.2 : 1,
				}}>
				<View
					style={{
						width: this.state.leftPanelMainWidth,
					}}
				/>
				<View>
					<View
						onMouseEnter={() =>
							this.setState({ hover: service.id })
						}
						onMouseLeave={() => this.setState({ hover: "" })}
						style={{
							width: "100%",
							flexDirection: "row",
							alignItems: "center",
							// backgroundColor: "#FFF",
							borderTopColor: "#797979",
							borderTopWidth: 0.5,
						}}>
						<View style={{ flex: 1 }}>
							{/* <TouchableOpacity
								onPress={() => {
									console.log(service);
								}}> */}
							<View
								style={{
									width: this.state.leftPanelWidth,
									flexDirection: "row",
									alignItems: "center",
									padding: 5,
									// justifyContent: "space-between",
									backgroundColor: "#FFF",
									marginRight: 2,
									// borderTopLeftRadius: 5,
									borderTopRightRadius: 5,
									paddingLeft: 6,
								}}>
								<View
									style={
										{
											// width: 85,
										}
									}>
									<Text
										ellipsizeMode='tail'
										style={{
											color: APP_COLOURS.BG2, // '#5A7BBA'
											fontSize: 12,
											fontWeight: "300",
										}}>
										{service.sla_id} -{" "}
										{service.service.code}-{" "}
									</Text>
								</View>

								<TouchableOpacity
									style={{}}
									onPress={() => {
										// alert(service.service.title);
										this.setState({
											showSla: true,
										});
									}}>
									<View
										style={{
											width: 170,
										}}>
										<Text
											style={{
												color: APP_COLOURS.BG2, // '#5A7BBA'
												fontSize: 12,
												fontWeight: "500",
											}}>
											{service.service.title}
										</Text>
									</View>
								</TouchableOpacity>

								<View
									style={{
										width: 220,
									}}>
									<Text
										style={{
											color: APP_COLOURS.BG2, // '#5A7BBA'
											fontSize: 12,
											fontWeight: "500",
										}}>
										{service.brand.title}:{" "}
										<Text
											style={{
												fontWeight: "300",
											}}>
											{service.platform.title}
										</Text>
									</Text>
								</View>

								{/* <TouchableOpacity
									style={{}}
									onPress={() => {
										// alert(service.service.title);
										this.setState({
											showSla: true,
											slaData: service,
										});
									}}> */}
								<View
									style={{
										width: 250,
									}}>
									<Text
										ellipsizeMode='tail'
										style={{
											color: APP_COLOURS.BG2, // '#5A7BBA'
											fontSize: 10,
											fontWeight: "300",
										}}>
										{service.sla_id}
									</Text>
									<Text
										ellipsizeMode='tail'
										style={{
											color: APP_COLOURS.BG2, // '#5A7BBA'
											fontSize: 10,
											fontWeight: "300",
										}}>
										{service.referenceId}
									</Text>
								</View>
								{/* </TouchableOpacity> */}
								<View
									style={{
										width: 85,
									}}>
									<Text
										ellipsizeMode='tail'
										style={{
											color: APP_COLOURS.BG2, // '#5A7BBA'
											fontSize: 12,
											fontWeight: "300",
										}}>
										{moment(service.start_date).format(
											"DD/MM/YYYY",
										)}
									</Text>
								</View>
								<View
									style={{
										width: 150,
									}}>
									<StatusItem
										{...this.props}
										{...service}
										fs={14}
										handleStatusChange={(_id, _status) => {
											let obj = {
												id: _id,
												status: _status,
											};
											crudUpdate(
												this.state.crudDocumentName,
												obj,
												(status, d) => {
													// console.log(status, d);
													console.log("updateItem");
													if (status === 200) {
														this.getAllServices();
													}
												},
											);
										}}
									/>
								</View>
								{/* <View
									style={{
										flex: 1,
									}}
								/> */}
								<View
									style={{
										width: 100,
										flexDirection: "row",
										alignItems: "center",
									}}>
									{this.assignment(service, 20)}
								</View>
							</View>
							{/* </TouchableOpacity> */}
						</View>
					</View>
				</View>
			</View>
		);
	};

	assignment = (d, size) => {
		let amountAvatars = 5;
		let fs = size;
		return (
			<TouchableOpacity
				onPress={(event) => {
					const layout = event.nativeEvent;
					let x = layout.pageX;
					let y = layout.pageY;
					console.log("assignment");
					this.setState({
						// showSubTasks: false,
						// showChat: false,
						// showAssignment: !this.state.showAssignment,
						showModalArea: true,

						xPos: x,
						yPos: y - 30,
						selectedService: d,
						selectedID: d.id,
					});
				}}>
				<View
					style={
						{
							// width: 100,
							// backgroundColor: '#252525',
							// padding: fs / 2,
						}
					}>
					{d.assignment.length < 1 ? (
						<View
							style={{
								//  flex: 1,
								//  backgroundColor: '#252525',
								justifyContent: "center",
								alignItems: "center",
								opacity: 0.4,
							}}>
							<Text
								style={{
									color: APP_COLOURS.BG2,
									fontSize: 13,
								}}>
								+ Assignment
							</Text>
						</View>
					) : (
						<View
							style={{
								// width: 200,
								//  flexDirection: 'row-reverse',
								//  alignItems: 'flex-end',
								flexDirection: "row",
								// marginRight: 20,
								alignItems: "center",
								// display: 'none',
								//  maxWidth: 120,
								width: 100,
								// d.assignment.length > 5
								// 	? 4 * (size / 2)
								// 	: d.assignment.length * (size / 2),
								// backgroundColor: APP_COLOURS.RED,
							}}>
							{d.assignment
								.filter((d) => d.id !== this.state.user_data.id)
								.slice(0, 5)
								.map((ass, i) => {
									return (
										<View
											key={i}
											style={{
												flexDirection: "row",
												justifyContent: "center",
												alignItems: "center",
												backgroundColor: "#FFF",
												borderWidth: 2,
												borderColor: "#E8E8E8",
												width: size * 1.1,
												height: size * 1.1,
												borderRadius: (size * 1.1) / 2,
												shadowColor: "#6494B7",
												shadowOffset: {
													width: 0,
													height: 4,
												},
												shadowOpacity: 0.23,
												shadowRadius: 4.65,
												elevation: 8,
												left: (size / 2.2) * -i,
												// position: "absolute",
												// top: 0,
												zIndex: i + 1,
											}}>
											<Image
												// source={avatars[parseInt(ass.avatar)]}
												source={
													Avatars[
														parseInt(ass.avatar)
													].pp
												}
												style={{
													width: size,
													height: size,
													borderRadius: size / 2,
													resizeMode: "contain",
													backgroundcolor:
														APP_COLOURS.BG2,
												}}
											/>
										</View>
									);
								})}
							{/* {d.assignment.length > 5 ? (
								<View
									style={{
										backgroundColor: "#252525",
										flexDirection: "row",
										justifyContent: "center",
										alignItems: "center",
										//   position: 'absolute',
										right: size / -2,
										width: size,
										height: size,

										borderRadius: 15,
									}}>
									<Text
										style={{
											color: APP_COLOURS.BG2,
											fontSize: fs * 0.7,
										}}>
										+{d.assignment.length - 5}
									</Text>
								</View>
							) : null} */}
						</View>
					)}
				</View>
			</TouchableOpacity>
		);
	};
}
