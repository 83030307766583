import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TextInput,
	ScrollView,
	Modal,
	Alert,
	Keyboard,
	Animated,
	LayoutAnimation,
	ActivityIndicator,
} from "react-native";
import {
	APP_COLOURS,
	TaskStatus,
	CustomLayoutSpring,
	Avatars,
	PROJECTSTATUS,
} from "../../APP_VARS";
import moment from "moment";
import {
	crudCreate,
	crudSendEmail,
	sendDiscordMessage,
	crudDeleteWhere,
	crudCreateWithHtml,
} from "../../CRUDHELPER";
import GetUser from "../GetUser";
import StatusItem from "./StatusItem";
import SmallerTabs from "../TopTabs/SmallerTabs";
import DescriptionQuillEditor from "./Editor/DescriptionQuillEditor";
import ProjectDocuments from "./ProjectDocuments";
import ProgressBar from "./ProgressBar";
import bin from "../../../assets/images/bin.png";
import EditTask from "../Gantt/EditTask";
import ChatBox from "./ChatBox";
import AddTask from "./AddTask";
// import ActivityLog from "../ActivityLog";

export default class ProjectComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			users: this.props.users,
			selectedProject: this.props.selectedProject,
			user_data: this.props.user_data,
			tasks: this.props.tasks,
		};
	}

	render() {
		let { d, fs, size } = this.props;
		// return;
		return (
			<View style={{ flex: 1 }}>
				<View
					style={{
						width: "100%",
						// flexDirection: "row",
						backgroundColor: "#FFF",
						padding: 5,
						borderRadius: 13,
						// marginLeft: 5,
						marginBottom: 10,
						paddingTop: 10,
					}}>
					<View
						style={{
							opacity: 0.8,
							marginRight: 20,
							alignItems: "center",

							paddingLeft: 10,
							flexDirection: "row",
						}}>
						{/* <AutoHeightImage
							width={120}
							source={{
								uri: d.brand?.imageData?.url,
							}}
						/> */}
						<Text
							style={{
								// color: APP_COLOURS.BLUE,
								color: APP_COLOURS.BG2,
								fontSize: fs + 5,
								fontWeight: "700",
							}}>
							{d.brand?.title}
						</Text>

						<View
							style={{
								flex: 1,
							}}
						/>

						<View
							style={{
								borderRightWidth: 0.5,
								height: "auto",
								alignItems: "center",
								justifyContent: "center",
								width: 90,
								marginRight: 10,
								paddingRight: 10,
							}}>
							<GetUser
								users={this.props.users}
								user_id={d.createdBy}
								size={size}
							/>
							<Text
								style={{
									color: APP_COLOURS.BG2,
									fontSize: 9,
									fontWeight: "600",
									// marginTop: 10,
								}}>
								SKrum Master
							</Text>
						</View>

						<TouchableOpacity
							onPress={(event) => {
								const layout = event.nativeEvent;
								let x = layout.pageX;
								let y = layout.pageY;
								console.log("assignment");
								this.setState({
									// showSubTasks: false,
									// showChat: false,
									// showAssignment: !this.state.showAssignment,
									showModalArea: "projectassignment",

									xPos: x,
									yPos: y - 30,
									// selectedTask: d,
									selectedID: d.id,
								});
							}}
							style={{
								justifyContent: "center",
								// backgroundColor: "#E8E8E8",
							}}>
							<View
								style={{
									// width: 150,
									alignItems: "flex-end",
									paddingRight: 3,
									// alignItems: "center",
									// backgroundColor: "#252525",
									// padding: fs / 2,
								}}>
								{d.assignment.length < 1 ? (
									<View
										style={{
											//  flex: 1,
											//  backgroundColor: '#252525',
											justifyContent: "center",
											alignItems: "center",
											opacity: 0.4,
										}}>
										<Text
											style={{
												color: APP_COLOURS.BG2,
												fontSize: fs,
											}}>
											+ Assign to project
										</Text>
									</View>
								) : (
									<View
										style={{
											// width: 200,
											//  flexDirection: 'row-reverse',
											//  alignItems: 'flex-end',
											flexDirection: "row",
											alignItems: "center",
											// marginRight: 20,
											//  alignItems: 'center',
											// display: 'none',
											//  maxWidth: 120,
											// width:
											// 	d.assignment.length > 5
											// 		? 4 * (size / 2)
											// 		: d.assignment
											// 				.length *
											// 		  (size / 2),
											minWidth: 100,
										}}>
										{/* .filter(
												(d) =>
													d.id !==
													this.props.user_data.id,
											) */}

										{d.assignment.map((ass, i) => {
											return (
												<View
													style={{
														marginHorizontal: 2,
														alignItems: "center",
													}}>
													<View
														key={i}
														style={{
															flexDirection:
																"row",
															justifyContent:
																"center",
															alignItems:
																"center",
															backgroundColor:
																"#FFF",
															borderWidth: 2,
															borderColor: "#FFF",
															width: size * 1.3,
															height: size * 1.3,
															borderRadius:
																size / 2,
															shadowColor:
																"#6494B7",
															shadowOffset: {
																width: 0,
																height: 4,
															},
															shadowOpacity: 0.23,
															shadowRadius: 4.65,
															elevation: 8,
															// left:
															// 	(size /
															// 		2.2) *
															// 	-i,
															// position: "absolute",
															// top: 0,
															zIndex: i + 1,
														}}>
														<Image
															// source={avatars[parseInt(ass.avatar)]}
															source={
																Avatars[
																	parseInt(
																		ass.avatar,
																	)
																].pp
															}
															style={{
																width:
																	size * 1.3,
																height:
																	size * 1.3,
																borderRadius:
																	(size *
																		1.3) /
																	2,
																resizeMode:
																	"contain",
																backgroundcolor:
																	APP_COLOURS.BG2,
															}}
														/>
													</View>
													<Text
														style={{
															color: APP_COLOURS.BG2,
															fontSize: 9,
														}}>
														{ass.first_name}
													</Text>
												</View>
											);
										})}
										{/* {d.assignment.length > 5 ? (
													<View
														style={{
															backgroundColor:
																"#252525",
															flexDirection:
																"row",
															justifyContent:
																"center",
															alignItems:
																"center",
															//   position: 'absolute',
															right: size / -2,
															width: size,
															height: size,

															borderRadius: 15,
														}}>
														<Text
															style={{
																color: APP_COLOURS.BG2,
																fontSize: fs,
															}}>
															+
															{d.assignment
																.length - 5}
														</Text>
													</View>
												) : null} */}
									</View>
								)}
							</View>
						</TouchableOpacity>

						<StatusItem
							{...this.props}
							{...d}
							fs={fs}
							handleStatusChange={async (_id, _status) => {
								let user_data = JSON.parse(
									localStorage.getItem("user_data"),
								);

								const pmp = await d.assignment_ids.forEach(
									async (id) => {
										await sendDiscordMessage(
											`${user_data.first_name} ${user_data.last_name}`,
											`[PROJECT Status Update]
### *${d.brand.title} - ${d.title}*
> *${_status}*
- url: ${window.location.origin}/home/projects?pid=${this.state.selectedProject.id}
`,
											`${window.location.origin}${
												Avatars[
													parseInt(user_data.avatar)
												].pp
											}`,
											id,
										);
									},
								);

								const mapThrough = await d.assignment.forEach(
									async (e) => {
										await crudSendEmail(
											"SKrum  <skrum@tetrice.co.za>",
											e.username,
											`[PROJECT UPDATE]: ${d.title}`,
											`<p>${d.brand.title}</p><p>${d.title}</p><p>From ${d.status} to ${_status}</p><br>
								<br>Be sure to check for tasks that are allocated to you.
								<br>https://skrum.tetrice.co.za/
								`,
										);
									},
								);

								crudCreate(
									"projects_timeline",
									{
										project_id: d.id,
										text: _status,
										type: "Status",
										sendBy: {
											id: user_data.id,
											first_name: user_data.first_name,
											last_name: user_data.last_name,
										},
										dateCreated: moment().toISOString(),
										dateUnix: moment().unix(),
									},
									() => {},
								);

								this.handleStatusChange(_id, _status);
							}}
						/>
					</View>

					<View style={{ width: "100%", flexDirection: "row" }}>
						<View
							style={{
								flex: 1,
								padding: 10,
								flexDirection: "row",
								borderBottomColor: "#E8E8E8",
								borderBottomWidth: 0.5,
								marginBottom: 4,
							}}>
							<TextInput
								returnKeyType='done'
								autoCorrect={false}
								style={{
									color: APP_COLOURS.BG2, // '#5A7BBA'
									fontSize: fs + 5,
									fontWeight: "700",
									marginBottom: 6,
									width: "100%",
								}}
								defaultValue={d.title}
								editable={true}
								onChangeText={(text) =>
									this.setState({
										changedTitle: text,
										needToUpdateProject: true,
									})
								}
								autoCapitalize='sentences'
								placeholder='TItle'
								clearButtonMode='while-editing'
							/>

							{this.state.needToUpdateProject ? (
								<TouchableOpacity
									style={{
										// marginRight: 15,
										marginLeft: 10,
										// backgroundColor: "#FFF",
										backgroundColor: APP_COLOURS.GREEN,
										borderRadius: 15,
										flexDirection: "row",
										paddingVertical: 12,
										alignItems: "center",
										paddingHorizontal: 15,
										// borderWidth: 0.5,
										// borderColor: APP_COLOURS.BG2,
									}}
									onPress={async () => {
										let obj = {
											...this.state.selectedProject,
											title: this.state.changedTitle,
											description:
												this.state.changedDescription,
										};

										if (
											this.state.changedDescription === ""
										) {
											delete obj.description;
										}

										let user_data = JSON.parse(
											localStorage.getItem("user_data"),
										);
										// 										sendDiscordMessage(
										// 											`${user_data.first_name} ${user_data.last_name}`,
										// 											`[Project Update]
										// New title: ${obj.title}`,
										// 											`${window.location.origin}${
										// 												Avatars[parseInt(user_data.avatar)].pp
										// 											}`,
										// 											"0QPZYrY6zpAnJXqX9gQl",
										// 										);

										await this.updateProjectItem(obj);
										await this.createLog(
											"update",
											obj.title,
										);
										await this.setState({
											needToUpdateProject: false,
											changedTitle: "",
											changedDescription: "",
										});
									}}>
									<Text
										style={{
											color: "#FFF",
											fontSize: 13,
										}}>
										Save changes
									</Text>
								</TouchableOpacity>
							) : null}
						</View>
					</View>

					{/* <View
						style={{
							padding: 3,
							alignItems: "flex-end",
						}}>
						<Text
							style={{
								color: APP_COLOURS.BG2,
								fontSize: fs + 3,
								fontWeight: "700",
							}}>
							Due {moment(d.due_date).format("DD/MM/YYYY")}{" "}
							<Text
								style={{
									fontStyle: "italic",
								}}>
								({moment(d.due_date).fromNow()})
							</Text>
						</Text>
					</View> */}
				</View>

				<View
					style={{
						flex: 1,
						flexDirection: "row",
					}}>
					<View
						style={{
							flex: 1.5,
						}}>
						<View
							style={{
								flex: 1,
								flexDirection: "row",
								backgroundColor: "#FFF",
								padding: 5,
								borderRadius: 13,
								// marginLeft: 5,
								marginBottom: 10,
							}}>
							<View
								style={{
									flex: 1,
									// height: this.props.screenHeight,
									// justifyContent: "flex-start",
									backgroundColor: "#FFF",
									padding: 5,
									borderRadius: 13,
									marginLeft: 5,
									marginBottom: 10,
								}}>
								{/* <View
								style={{
									width: "100%",
									marginVertical: 15,
									backgroundColor: "#FFF",
								}}>
								<ProgressBar
									progress={`${
										(this.state.tasks.filter(
											(t) =>
												t.project_id === d.id &&
												t.status === "Completed",
										).length /
											this.state.tasks.filter(
												(t) => t.project_id === d.id,
											).length) *
										100
									}%`}
									inProgress={true}
									color={APP_COLOURS.GREEN}
								/>
							</View> */}

								<View
									style={{
										padding: 3,
										// alignItems: "flex-end",
									}}>
									<Text
										style={{
											color: APP_COLOURS.BG2,
											fontSize: fs + 3,
											fontWeight: "700",
										}}>
										Due{" "}
										{moment(d.due_date).format(
											"DD/MM/YYYY",
										)}{" "}
										<Text
											style={{
												fontStyle: "italic",
											}}>
											({moment(d.due_date).fromNow()})
										</Text>
									</Text>
								</View>

								<SmallerTabs
									selectedSubPage={(d) => {
										this.setState({
											subPage: d,
										});
										console.log(d);
									}}
									tabs={[
										"Overview",
										"Documents",
										"Tasks",

										"Assignment",
										"Edit",
									]}
								/>

								{this.state.subPage === "Overview" ? (
									<View
										style={{
											flex: 1,
										}}>
										<View
											style={{
												flex: 1,
												padding: 5,
											}}>
											<ScrollView>
												<View
													style={{
														// padding: 15,
														// borderWidth: 0.5,
														borderRadius: 5,
														maxWidth: 800,
													}}>
													{/* <Text
												style={{
													color: APP_COLOURS.BG2, // '#5A7BBA'
													fontSize: 12,
													fontWeight: "300",
													marginBottom: 6,
													opacity: 0.5,
												}}>
												Overview:
											</Text> */}

													<TouchableOpacity
														onPress={() => {
															this.setState({
																lockContent:
																	this.state
																		.lockContent ===
																	d.id
																		? ""
																		: d.id,
															});
														}}>
														<View
															style={{
																width: 80,
																alignItems:
																	this.state
																		.lockContent ===
																	d.id
																		? "flex-start"
																		: "flex-end",
																borderRadius: 15,
																height: 40,
															}}>
															<View
																style={{
																	width: 80,
																	height: 40,
																	alignItems:
																		"center",
																	justifyContent:
																		"center",
																	borderRadius: 13,
																	backgroundColor:
																		this
																			.state
																			.lockContent ===
																		d.id
																			? "white"
																			: APP_COLOURS.BLUE,
																	paddingHorizontal: 10,
																}}>
																<Text
																	style={{
																		fontSize: 13,
																		color:
																			this
																				.state
																				.lockContent ===
																			d.id
																				? "blue"
																				: APP_COLOURS.DESELECTEDCOLOR,
																	}}>
																	{this.state
																		.lockContent ===
																	d.id
																		? "Unlocked"
																		: "Locked"}
																</Text>
															</View>
														</View>
													</TouchableOpacity>

													<DescriptionQuillEditor
														presentation_id={d.id}
														pageData={d.description}
														reloadData={(html) => {
															crudCreateWithHtml(
																"project_descriptions",
																{
																	presentation_id:
																		d.id,
																	html: html,
																	_id:
																		typeof d
																			.description
																			?.htmlData
																			?._id !==
																		"undefined"
																			? d
																					.description
																					.htmlData
																					._id
																			: "",
																},
																async (
																	status,
																	data,
																) => {
																	console.log(
																		data,
																		status,
																	);

																	let obj = {
																		...this
																			.state
																			.selectedProject,
																		description:
																			data,
																	};
																	await this.updateProjectItem(
																		obj,
																	);
																	await this.createLog(
																		"update",
																		obj.description,
																	);
																	await this.setState(
																		{
																			needToUpdateProject: false,
																			changedTitle:
																				"",
																			changedDescription:
																				"",
																		},
																	);
																},
															);
														}}
														lockContent={
															this.state
																.lockContent !==
															d.id
														}
													/>

													{/* <TextEditorComp
														defaultvalue={
															d.description
														}
														exportHtml={(html) => {
															console.log(html);

															this.setState({
																changedDescription:
																	html,
																needToUpdateDescription: true,
															});
														}}
													/> */}

													{/* <TextInput
												returnKeyType='done'
												autoCorrect={false}
												style={{
													color: APP_COLOURS.BG2, // '#5A7BBA'
													fontSize: 16,
													fontWeight: "300",
													marginBottom: 6,
												}}
												defaultValue={d.description}
												editable={true}
												multiline
												numberOfLines={10}
												onChangeText={(text) =>
													this.setState({
														changedDescription:
															text,
														needToUpdateProject: true,
													})
												}
												autoCapitalize='sentences'
												placeholder='Description'
												clearButtonMode='while-editing'
											/> */}

													{/* <AutoHeightImage
								width={100}
								source={{
									uri: d.brand?.imageData?.url,
								}}
								// src={`${d.brand?.imageData?.url}`}
								// style={{
								// 	marginBottom: 10,
								// }}
							/> */}
													{/* <Text
						style={{
							color: APP_COLOURS.BG2, // '#5A7BBA'
							fontSize: 17,
							fontWeight: "700",
							marginBottom: 6,
						}}>
						{d.brand.title}
					</Text> */}
													{/* <Text
									style={{
										color: APP_COLOURS.BG2, // '#5A7BBA'
										fontSize: 17,
										fontWeight: "700",
										marginBottom: 6,
									}}>
									{d.title}
								</Text>
								<View style={{}}>
									<Text
										style={{
											color: APP_COLOURS.BG2, // '#5A7BBA'
											fontSize: 13,
											fontWeight: "300",
											marginBottom: 6,
										}}>
										{d.description}
									</Text>
								</View> */}
												</View>
											</ScrollView>
											{/* {this.state
												.needToUpdateDescription ? (
												<TouchableOpacity
													style={{
														// marginRight: 15,
														marginLeft: 10,
														// backgroundColor: "#FFF",
														backgroundColor:
															APP_COLOURS.GREEN,
														borderRadius: 15,
														flexDirection: "row",
														paddingVertical: 12,
														alignItems: "center",
														paddingHorizontal: 15,
														// borderWidth: 0.5,
														// borderColor: APP_COLOURS.BG2,
														justifyContent:
															"center",
													}}
													onPress={async () => {
														let obj = {
															...this.state
																.selectedProject,

															description:
																this.state
																	.changedDescription,
														};
														await this.updateProjectItem(
															obj,
														);
														await this.createLog(
															"update",
															obj.description,
														);
														await this.setState({
															needToUpdateProject: false,
															changedTitle: "",
															changedDescription:
																"",
														});
													}}>
													<Text
														style={{
															color: "#FFF",
															fontSize: 13,
														}}>
														Save description
													</Text>
												</TouchableOpacity>
											) : null} */}
										</View>
									</View>
								) : null}

								{this.state.subPage === "Documents" ? (
									<View
										style={{
											flex: 1,
											padding: 5,
										}}>
										<ProjectDocuments
											{...this.state}
											project_id={
												this.state.selectedProject.id
											}
										/>
									</View>
								) : null}

								{this.state.subPage === "Assignment" ? (
									<View
										style={{
											flex: 1,
										}}>
										<View
											style={{
												padding: 15,
											}}>
											<Text
												style={{
													color: APP_COLOURS.BG2,
													fontSize: 9,
													fontWeight: "600",
													marginTop: 10,
												}}>
												Assignment:
											</Text>

											<View
												style={{
													paddingRight: 3,
													// alignItems: "center",
													// backgroundColor: "#252525",
													// padding: fs / 2,
												}}>
												{d.assignment.length < 1 ? (
													<View
														style={{
															//  flex: 1,
															//  backgroundColor: '#252525',
															justifyContent:
																"center",

															opacity: 0.4,
														}}>
														<Text
															style={{
																color: APP_COLOURS.BG2,
																fontSize: fs,
															}}>
															+ Assign
														</Text>
													</View>
												) : (
													<View style={{}}>
														{d.assignment.map(
															(ass, i) => {
																return (
																	<View
																		key={i}
																		style={{
																			flexDirection:
																				"row",

																			alignItems:
																				"center",
																		}}>
																		<Image
																			// source={avatars[parseInt(ass.avatar)]}
																			source={
																				Avatars[
																					parseInt(
																						ass.avatar,
																					)
																				]
																					.pp
																			}
																			style={{
																				width: size,
																				height: size,
																				borderRadius:
																					size /
																					2,
																				resizeMode:
																					"contain",
																				backgroundcolor:
																					APP_COLOURS.BG2,
																			}}
																		/>
																		<Text
																			style={{
																				color: APP_COLOURS.BG2,
																				fontSize:
																					fs -
																					2,
																			}}>
																			{
																				ass.first_name
																			}{" "}
																			{
																				ass.last_name
																			}
																		</Text>
																	</View>
																);
															},
														)}
													</View>
												)}
												<Text
													style={{
														color: APP_COLOURS.BG2,
														fontSize: 9,
														fontWeight: "600",
														marginTop: 10,
													}}>
													SKrum Master
												</Text>
												<GetUser
													users={this.props.users}
													user_id={d.createdBy}
													size={size}
												/>
											</View>
										</View>
									</View>
								) : null}

								{this.state.subPage === "Log" &&
								this.props.user_data.username ===
									"wesley@tetrice.co.za" ? (
									<View
										style={{
											flex: 1,
											padding: 5,
										}}>
										{/* <ActivityLog projectID={d.id} /> */}
									</View>
								) : null}

								{this.state.subPage === "Edit" &&
								this.props.user_data.username ===
									"wesley@tetrice.co.za" ? (
									<View
										style={{
											flex: 1,
										}}>
										<View
											style={{
												padding: 15,
											}}>
											<Text
												style={{
													color: APP_COLOURS.BG2, // '#5A7BBA'
													fontSize: 12,
													fontWeight: "300",
													marginBottom: 6,
													opacity: 0.5,
												}}>
												Edit project: {d.id}
											</Text>
											<View
												style={{
													flex: 1,
												}}>
												<TouchableOpacity
													onPress={async () => {
														await crudDeleteWhere(
															"projects_timeline",
															{
																field: "project_id",
																action: "==",
																value: d.id,
															},
															(res) => {
																if (
																	res === 200
																) {
																	// alert(
																	// 	"Deleted chats",
																	// );
																}
																return;
															},
														);

														await crudDeleteWhere(
															"projects_tasks",
															{
																field: "project_id",
																action: "==",
																value: d.id,
															},
															(res) => {
																if (
																	res === 200
																) {
																	alert(
																		"Deleted tasks",
																	);
																}
																this.props.getAllProjectTasks();
															},
														);

														await crudDeleteWhere(
															"projects",
															{
																field: "id",
																action: "==",
																value: d.id,
															},
															(res) => {
																if (
																	res === 200
																) {
																	// alert(
																	// 	"Deleted project",
																	// );
																}
																{
																	this.props.getAllProjectsUser();
																	this.setState(
																		{
																			selectedProject:
																				"",
																			loading: false,
																			hover: true,
																			filterSearch:
																				"",
																		},
																	);
																}
															},
														);
													}}
													style={{
														flexDirection: "row",
														justifyContent:
															"center",
														alignItems: "center",
														padding: 15,

														marginLeft: 5,
														marginRight: 10,
														backgroundColor:
															APP_COLOURS.RED2,
														borderRadius: 10,
													}}>
													<Text
														style={{
															color: "#FFF",
															marginRight: 10,
															fontWeight: "500",
														}}>
														Delete project and
														associated data
													</Text>
													<Image
														source={bin}
														style={{
															width: fs * 1.5,
															height: fs * 1.5,
															resizeMode:
																"contain",
															marginRight: 5,
														}}
													/>
												</TouchableOpacity>
											</View>
										</View>
									</View>
								) : null}

								{this.state.subPage === "Tasks" ? (
									<View
										style={{
											flex: 1,
											// marginLeft: 5,
											backgroundColor: "#FFF",
											padding: 10,
											borderRadius: 13,
										}}>
										<Text
											style={{
												color: APP_COLOURS.BG2,
												fontSize: 16,
												fontWeight: "900",
												marginBottom: 6,
											}}>
											Tasks
										</Text>
										<View
											style={{
												width: "100%",
												marginVertical: 15,
												backgroundColor: "#FFF",
											}}>
											<ProgressBar
												progress={`${
													(this.state.tasks.filter(
														(t) =>
															t.project_id ===
																d.id &&
															t.status ===
																"Completed",
													).length /
														this.state.tasks.filter(
															(t) =>
																t.project_id ===
																d.id,
														).length) *
													100
												}%`}
												inProgress={true}
												color={APP_COLOURS.GREEN}
											/>
										</View>
										{this.state.selectedTask !== "" ? (
											<View
												style={{
													flex: 1,
													// backgroundColor: APP_COLOURS.BG2,
												}}>
												<EditTask
													d={this.state.selectedTask}
													milestoneData={d}
													selectedProject={d}
													reloadData={() =>
														this.getAllProjectTasks()
													}
													close={() => {
														this.setState({
															selectedTask: "",
														});
													}}
												/>
												{/* <DailyPlanner
									user_data={this.props.user_data}
									title={d.title}
									category={"wes"}
								/> */}
											</View>
										) : (
											<View
												style={{
													flex: 1,
												}}>
												<ScrollView>
													{this.state.tasks
														.filter(
															(t) =>
																t.project_id ===
																d.id,
														)
														.filter(
															(t) =>
																t.status !==
																"Completed",
														)
														.sort((a, b) => {
															return (
																moment(
																	a.end_date,
																).unix() -
																moment(
																	b.end_date,
																).unix()
															);
														})
														.map((task, ii) =>
															this.taskItem(
																task,
																ii,
																fs,
																size,
															),
														)}

													<View
														style={{
															// flex: 1,
															padding: 3,
															// maxWidth: 600,
															width: "100%",
															maxWidth: 600,
															borderRadius: 20,
														}}>
														<View
															style={{
																width: "100%",
																padding: 10,
																borderRadius: 20,
															}}>
															<Text
																style={{
																	fontSize:
																		fs,
																	color: APP_COLOURS.BG2,
																	fontWeight:
																		"600",
																	fontFamily:
																		"Gotham",
																}}>
																All Completed
															</Text>
														</View>
														<ScrollView>
															{this.state.tasks
																.filter(
																	(t) =>
																		t.project_id ===
																		d.id,
																)
																.sort(
																	(a, b) => {
																		return (
																			moment(
																				a.end_date,
																			).unix() -
																			moment(
																				b.end_date,
																			).unix()
																		);
																	},
																)
																.filter(
																	(t) =>
																		t.status ===
																		"Completed",
																)

																.map(
																	(
																		task,
																		ii,
																	) =>
																		this.taskItem(
																			task,
																			ii,
																			fs,
																			size,
																		),
																)}
														</ScrollView>
													</View>
												</ScrollView>

												<TouchableOpacity
													style={{
														width: "100%",

														// backgroundColor: "#FFF",
														backgroundColor:
															APP_COLOURS.BLUE2,
														borderRadius: 15,
														flexDirection: "row",
														paddingVertical: 12,
														alignItems: "center",
														paddingHorizontal: 15,
														borderWidth: 0.5,
														borderColor:
															APP_COLOURS.BG2,
														justifyContent:
															"center",
														alignContent: "center",
													}}
													onPress={async () => {
														this.setState({
															addProjectTask: true,
														});
													}}>
													<Text
														style={{
															color: "#FFF",
															fontSize: 14,
														}}>
														+ New task
													</Text>
												</TouchableOpacity>
											</View>
										)}
									</View>
								) : null}

								{/* {this.state.subPage === "Documents" ? (
									<View
										style={{
											flex: 1,
											padding: 5,
										}}></View>
								) : null} */}
							</View>
						</View>
					</View>

					<View
						style={{
							flex: 1,
							// marginLeft: 5,
							backgroundColor: "#FFF",
							padding: 5,
							borderRadius: 13,
							marginLeft: 10,
						}}>
						<ChatBox
							chatTitle={d.title}
							project_id={d.id}
							selectedProject={this.state.selectedProject}
							assignment_ids={
								this.state.selectedProject?.assignment_ids
							}
							user_data={this.props.user_data}
						/>
					</View>

					{this.state.addProjectTask ? (
						<AddTask
							{...this.state}
							project_id={d.id}
							selectedProject={d}
							close={() =>
								this.setState({
									addProjectTask: false,
								})
							}
							reloadData={() => this.getAllProjectTasks()}
						/>
					) : null}
				</View>
			</View>
		);
	}
}
