import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import { crudRead, crudReadAll, crudReadUnass } from "../CRUDHELPER";
import { APP_COLOURS } from "../APP_VARS";

export default class AttendanceRecord extends Component {
	constructor(props) {
		super(props);
		this.state = {
			logs: [],
		};
	}

	componentDidMount() {
		this.getActivityLog();
	}

	getActivityLog = async () => {
		await this.setState({
			loading: true,
		});

		crudReadUnass(`attendance_record`, "", (status, response) => {
			console.log(status, response);
			if (status === 200) {
				this.setState({
					logs: response,
					loading: false,
				});
			} else {
				this.setState({ loading: false });
			}
		});
	};

	render() {
		return (
			<View
				style={{
					flex: 1,
					padding: 10,
				}}>
				<View
					style={{
						flex: 1,
						paddingBottom: 60,
						backgroundColor: "#FFF",
						padding: 15,
						borderRadius: 10,
					}}>
					<Text
						style={{
							color: APP_COLOURS.BG2,
							fontSize: 12,
							fontWeight: "800",
							fontFamily: "Gotham",
						}}>
						Attendance
					</Text>
					<ScrollView>
						{this.state.logs
							.sort(
								(a, b) =>
									moment(b.createdAt).unix() >
									moment(a.createdAt).unix(),
							)
							.map((d) => (
								<View
									key={`act-${d.id}`}
									style={{
										width: "100%",
										flexDirection: "row",
										justifyContent: "space-between",
										padding: 3,
										marginBottom: 3,
										borderBottomWidth: 0.3,
										borderBottomColor: "#E8E8E8",
									}}>
									<Text
										style={{
											color: APP_COLOURS.BG2,
											width: 120,
										}}>
										{d.user.first_name} {d.user.last_name}
									</Text>
									<Text
										style={{
											color: APP_COLOURS.BG2,
											width: 200,
										}}>
										{moment(d.createdAt).fromNow()} -{" "}
										{moment(d.createdAt).format("HH:mm")}
									</Text>

									<Text
										style={{
											color: APP_COLOURS.BG2,
											width: 200,
										}}>
										{d.action}
									</Text>
									<Text
										style={{
											color: APP_COLOURS.BG2,
											width: 200,
										}}>
										{d.project_id}
									</Text>
								</View>
							))}
					</ScrollView>
				</View>
			</View>
		);
	}
}
