import moment from "moment";
import React, { Component } from "react";
import {
	View,
	Text,
	StyleSheet,
	TouchableOpacity,
	Image,
	ScrollView,
} from "react-native";
import { APP_COLOURS, screenHeight } from "../../APP_VARS";

export default class TodayTimeline extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tasks: this.props.tasks,
		};
	}

	render() {
		return (
			<View
				style={{
					width: "100%",
					//  height: 500,
					//  backgroundColor: '#F2F2F2',
					// paddingTop: 50,
					padding: 10,
					flex: 1,
				}}>
				<View
					style={{
						flexDirection: "row",
						alignItems: "center",
						// borderBottomWidth: 0.5,
						paddingBottom: 10,
						// borderBottomColor: FONT,
						marginBottom: 20,
					}}>
					<TouchableOpacity
						onPress={async () => {
							this.props.close();
						}}>
						<Image
							style={{
								width: 24,
								height: 24,
								resizeMode: "contain",
								marginRight: 10,
							}}
							source={require("../../../assets/images/close.png")}
						/>
					</TouchableOpacity>

					<Text
						adjustsFontSizeToFit
						style={{
							fontWeight: "800",
							fontFamily: "Gotham",
							color: "#FFF",
							fontSize: 19,
							marginLeft: 10,
							// display: this.state.expand ? 'flex' : 'none',
						}}>
						Today
					</Text>
				</View>
				<View
					style={{
						flexDirection: "row",
						alignItems: "center",
						// borderBottomWidth: 0.5,
						paddingBottom: 10,
						// borderBottomColor: FONT,
						marginBottom: 20,
					}}>
					<Text
						adjustsFontSizeToFit
						style={{
							fontWeight: "700",
							fontFamily: "Gotham",
							color: "#FFF",
							fontSize: 25,
							marginLeft: 10,
							// display: this.state.expand ? 'flex' : 'none',
						}}>
						{moment().format("DD MMMM")}
					</Text>
					<Text
						adjustsFontSizeToFit
						style={{
							fontWeight: "300",
							//   fontFamily: 'Poppins',
							color: "#FFF",
							fontSize: 25,
							marginLeft: 10,
							// display: this.state.expand ? 'flex' : 'none',
						}}>
						{moment().format("HH:mm a")}
					</Text>
				</View>
				<View
					style={{
						flex: 1,
					}}>
					<ScrollView>
						<View
							style={{
								padding: 15,
								// height: 500,
								flex: 1,
							}}>
							{Array.from({ length: 24 }, (_, i) => i).map(
								(hour) => {
									let tasksAtHour = this.state.tasks.filter(
										(task) =>
											new Date(
												task.dueDate,
											).getHours() === hour,
									);
									let isPast = moment().isAfter(
										moment(hour, "HH"),
									);
									return (
										<View
											style={{
												minHeight: 500 / 24,
												padding: 5,
												opacity: isPast ? 0.6 : 1,
											}}
											key={hour}>
											<View
												style={{
													flexDirection: "row",
													position: "absolute",
													top: 0,
													alignItems: "center",
												}}>
												<Text
													style={{
														color: "#FFF",
														marginRight: 15,
														opacity:
															tasksAtHour.length <
															1
																? 0.4
																: 1,
													}}>
													{moment(hour, "HH").format(
														"h a",
													)}
												</Text>
												<View
													style={{
														flex: 1,
														borderTopColor:
															APP_COLOURS.WHITE_TRANSPARENT5,
														borderTopWidth: 1,
													}}
												/>
											</View>
											{tasksAtHour.length < 1 ? (
												<View
													style={{
														height: 500 / 24,
													}}
												/>
											) : null}
											<View
												style={{
													//   flex: 1,
													width: "100%",
													flexDirection: "row",
													paddingLeft: 25,
												}}>
												{tasksAtHour.map(
													(task, index) => {
														{
															/* let taskHour = new Date(task.dueDate).getHours();
                  let taskMinute = new Date(task.dueDate).getMinutes();
                  let taskTop = ((taskHour * 60 + taskMinute) / 1440) * 500; */
														}
														return (
															<View
																key={index}
																style={{
																	flex: 1,
																	backgroundColor:
																		isPast
																			? "grey"
																			: task.status ===
																			  "Complete"
																			? "transparent"
																			: task.status !==
																			  "Pending"
																			? APP_COLOURS.ORANGE
																			: APP_COLOURS.BLUE2,
																	padding: 10,
																	marginLeft: 15,
																	borderRadius: 8,
																}}>
																<Text
																	style={{
																		fontSize: 12,
																		color: "#fff",
																		opacity: 0.5,
																	}}>
																	{
																		task.project
																	}
																</Text>
																<Text
																	style={
																		styles.taskTitle
																	}>
																	{task.title}
																</Text>
															</View>
														);
													},
												)}
											</View>
										</View>
									);
								},
							)}
						</View>
					</ScrollView>
				</View>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	container: {},
	task: {},
	taskTitle: {
		fontSize: 16,
		color: "#fff",
		marginTop: 10,
	},
});
