import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import { Avatars, StatusColour, TaskStatus } from "../../APP_VARS";
import StatusItem from "../DailyPlanner/StatusItem";
import Lottie from "react-lottie";
import {
	crudCreate,
	crudDelete,
	crudGetcalendars,
	crudRead,
	crudReadAll,
	crudUpdate,
} from "../../CRUDHELPER";
import Fade from "react-reveal/Fade";
import debbie from "../../../assets/images/cloud.png";
import AutoHeightImage from "../AutoHeightImageComp";
import ContentCalendarItem from "./ContentCalendarItem";
import * as animationData from "../../../assets/lottie/team.json";
import PostCalendar from "./PostCalendar";
import AddContent from "./AddContent";
import AddContentCalendar from "./AddContentCalendar";

function uuid() {
	return "xxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

export default class ContentCalendar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user_data: this.props.user_data,
			loading: false,
			addNewContent: false,
			addContentCalendar: false,
			selectedContentCalendar: "",
			calendars: [
				{
					id: "hZgzLwGl6oUx5YkOj4vi",

					title: "November 2023",
					calendarDate: "01/11/2023",

					client_id: "df63292e4",
					client_name: "Elizabeth Arden",

					createdAt: "2023-10-10T00:48:18.264Z",
					createdBy: "4rAs2Xj9T4xoqBpsxLo0",
					description: "",

					link: "",
					publishDate: "2023-10-10T00:48:13.430Z",
					stage: 1,
					status: "Copy",

					objectives: [
						`Build excitement towards Black Friday and the upcoming holiday season. Feature products and specials that may be offered (no mention to specific retailers).`,
						"Guide community on gifting and tips to fill their shopping bags. ",
					],
					focusThisMonth: [
						"RETINOL + HPR WATER CREAM",
						"Eight Hour Skin protectant cream",
						"Prevage",
						"Arden Classics - Fragrances",
					],
					importantDates: [
						"12th - Festival Of Lights",
						"19th - International Men’s Day",
						"25th - Black Friday",
						"27th – Cyber Monday",
					],
					contentList: [
						{
							title: "Retinol",
							calendar_id: "hZgzLwGl6oUx5YkOj4vi",
							date: "13/11/2023",
							time: "13:00",
							postType: "Reel",
							approval: false,
							assets: [
								{
									id: "1",
									link: "https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/contentCreationPosts%2FInner-01.png?alt=media&token=4339c4a7-9037-49f6-9978-37fb7853d11e&_gl=1*1u7ml0h*_ga*MjI0OTc5NDIyLjE2NjczODk0NzU.*_ga_CW55HF8NVT*MTY5Njg5Mjk4MS4xOTkuMS4xNjk2ODk3NDUxLjYwLjAuMA..",
									type: "img",
								},
								{
									id: "1",
									link: "https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/contentCreationPosts%2FInner-01.png?alt=media&token=4339c4a7-9037-49f6-9978-37fb7853d11e&_gl=1*1u7ml0h*_ga*MjI0OTc5NDIyLjE2NjczODk0NzU.*_ga_CW55HF8NVT*MTY5Njg5Mjk4MS4xOTkuMS4xNjk2ODk3NDUxLjYwLjAuMA..",
									type: "img",
								},
							],
							assetsLink: "",
							assignment: [],
							assignment_ids: [],
							changeHistory: [],
							channel: "Instagram",
							client_id: "df63292e4",
							client_name: "Elizabeth Arden",
							copy: "Get ready to kick off Comic-Con Africa with a BANG! 💥  A co-ordinated flow for the background of each image, with the aim of a unified look and feel on the Instagram feedA co-ordinated flow for the background of each image, with the aim of a unified look and feel on the Instagram feedA co-ordinated flow for the background of each image, with the aim of a unified look and feel on the Instagram feed",
							createdAt: "2023-10-10T00:48:18.264Z",
							createdBy: "4rAs2Xj9T4xoqBpsxLo0",
							description: "",
							handle: "@elizabetharden_sa",
							id: "hZgzLwGl6oUx5YkOj4vi",
							link: "",
							publishDate: "2023-10-10T00:48:13.430Z",
							stage: 1,
							status: "Planning",
							title: "",
							visualDriveLink: "",
							visualType: "",
							hashtags: [`#ElizabethArden`],
							tags: [
								`@edgarsbeauty`,
								`@woolworths_sa`,
								`@superbalist`,
								`@truworthsfashion`,
								`@clicks_sa`,
								`@dischem.beauty`,
								`@dischem_pharmacies`,
								`@friendsofbash`,
								`@wooliesbeauty`,
							],
						},
						{
							title: "Retinol",
							calendar_id: "hZgzLwGl6oUx5YkOj4vi",
							date: "13/11/2023",
							time: "13:00",
							postType: "Reel",
							approval: false,
							assets: [
								{
									id: "1",
									link: "https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/contentCreationPosts%2FInner-01.png?alt=media&token=4339c4a7-9037-49f6-9978-37fb7853d11e&_gl=1*1u7ml0h*_ga*MjI0OTc5NDIyLjE2NjczODk0NzU.*_ga_CW55HF8NVT*MTY5Njg5Mjk4MS4xOTkuMS4xNjk2ODk3NDUxLjYwLjAuMA..",
									type: "img",
								},
								{
									id: "1",
									link: "https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/contentCreationPosts%2FInner-01.png?alt=media&token=4339c4a7-9037-49f6-9978-37fb7853d11e&_gl=1*1u7ml0h*_ga*MjI0OTc5NDIyLjE2NjczODk0NzU.*_ga_CW55HF8NVT*MTY5Njg5Mjk4MS4xOTkuMS4xNjk2ODk3NDUxLjYwLjAuMA..",
									type: "img",
								},
							],
							assetsLink: "",
							assignment: [],
							assignment_ids: [],
							changeHistory: [],
							channel: "Instagram",
							client_id: "df63292e4",
							client_name: "Elizabeth Arden",
							copy: "Get ready to kick off Comic-Con Africa with a BANG! 💥  A co-ordinated flow for the background of each image, with the aim of a unified look and feel on the Instagram feedA co-ordinated flow for the background of each image, with the aim of a unified look and feel on the Instagram feedA co-ordinated flow for the background of each image, with the aim of a unified look and feel on the Instagram feed",
							createdAt: "2023-10-10T00:48:18.264Z",
							createdBy: "4rAs2Xj9T4xoqBpsxLo0",
							description: "",
							handle: "@elizabetharden_sa",
							id: "hZgzLwGl6oUx5YkOj4vi",
							link: "",
							publishDate: "2023-10-10T00:48:13.430Z",
							stage: 1,
							status: "Planning",
							title: "",
							visualDriveLink: "",
							visualType: "",
							hashtags: [`#ElizabethArden`],
							tags: [
								`@edgarsbeauty`,
								`@woolworths_sa`,
								`@superbalist`,
								`@truworthsfashion`,
								`@clicks_sa`,
								`@dischem.beauty`,
								`@dischem_pharmacies`,
								`@friendsofbash`,
								`@wooliesbeauty`,
							],
						},
						{
							title: "Retinol",
							calendar_id: "hZgzLwGl6oUx5YkOj4vi",
							date: "13/11/2023",
							time: "13:00",
							postType: "Reel",
							approval: false,
							assets: [
								{
									id: "1",
									link: "https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/contentCreationPosts%2FInner-01.png?alt=media&token=4339c4a7-9037-49f6-9978-37fb7853d11e&_gl=1*1u7ml0h*_ga*MjI0OTc5NDIyLjE2NjczODk0NzU.*_ga_CW55HF8NVT*MTY5Njg5Mjk4MS4xOTkuMS4xNjk2ODk3NDUxLjYwLjAuMA..",
									type: "img",
								},
								{
									id: "1",
									link: "https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/contentCreationPosts%2FInner-01.png?alt=media&token=4339c4a7-9037-49f6-9978-37fb7853d11e&_gl=1*1u7ml0h*_ga*MjI0OTc5NDIyLjE2NjczODk0NzU.*_ga_CW55HF8NVT*MTY5Njg5Mjk4MS4xOTkuMS4xNjk2ODk3NDUxLjYwLjAuMA..",
									type: "img",
								},
							],
							assetsLink: "",
							assignment: [],
							assignment_ids: [],
							changeHistory: [],
							channel: "Instagram",
							client_id: "df63292e4",
							client_name: "Elizabeth Arden",
							copy: "Get ready to kick off Comic-Con Africa with a BANG! 💥  A co-ordinated flow for the background of each image, with the aim of a unified look and feel on the Instagram feedA co-ordinated flow for the background of each image, with the aim of a unified look and feel on the Instagram feedA co-ordinated flow for the background of each image, with the aim of a unified look and feel on the Instagram feed",
							createdAt: "2023-10-10T00:48:18.264Z",
							createdBy: "4rAs2Xj9T4xoqBpsxLo0",
							description: "",
							handle: "@elizabetharden_sa",
							id: "hZgzLwGl6oUx5YkOj4vi",
							link: "",
							publishDate: "2023-10-10T00:48:13.430Z",
							stage: 1,
							status: "Planning",
							title: "",
							visualDriveLink: "",
							visualType: "",
							hashtags: [`#ElizabethArden`],
							tags: [
								`@edgarsbeauty`,
								`@woolworths_sa`,
								`@superbalist`,
								`@truworthsfashion`,
								`@clicks_sa`,
								`@dischem.beauty`,
								`@dischem_pharmacies`,
								`@friendsofbash`,
								`@wooliesbeauty`,
							],
						},
						{
							title: "Retinol",
							calendar_id: "hZgzLwGl6oUx5YkOj4vi",
							date: "14/11/2023",
							time: "13:00",
							postType: "Banner",
							approval: false,
							assets: [
								{
									id: "1",
									link: "https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/contentCreationPosts%2FScreenshot%202023-11-10%20at%2016.12.43.png?alt=media&token=a2ad2046-4dcc-422a-bb78-0939820d51e6",
									type: "img",
								},
							],
							assetsLink: "",
							assignment: [],
							assignment_ids: [],
							changeHistory: [],
							channel: "Facebook",
							client_id: "df63292e4",
							client_name: "Elizabeth Arden",
							copy: "Get ready to kick off Comic-Con Africa with a BANG! 💥  ",
							createdAt: "2023-10-10T00:48:18.264Z",
							createdBy: "4rAs2Xj9T4xoqBpsxLo0",
							description: "",
							handle: "Elizabeth Arden South Africa",
							id: "hZgzLwGl6oUx5YkOj4vi",
							link: "",
							publishDate: "2023-10-10T00:48:13.430Z",
							stage: 3,
							status: "Planning",
							title: "",
							visualDriveLink: "",
							visualType: "",
							hashtags: [`#ElizabethArden`],
							tags: [
								`@edgarsbeauty`,
								`@woolworths_sa`,
								`@superbalist`,
								`@truworthsfashion`,
								`@clicks_sa`,
								`@dischem.beauty`,
								`@dischem_pharmacies`,
								`@friendsofbash`,
								`@wooliesbeauty`,
							],
						},
						{
							title: "Retinol",
							calendar_id: "hZgzLwGl6oUx5YkOj4vi",
							date: "14/11/2023",
							time: "13:00",
							postType: "Banner",
							approval: false,
							assets: [
								{
									id: "1",
									link: "https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/contentCreationPosts%2FScreenshot%202023-11-10%20at%2016.12.43.png?alt=media&token=a2ad2046-4dcc-422a-bb78-0939820d51e6",
									type: "img",
								},
							],
							assetsLink: "",
							assignment: [],
							assignment_ids: [],
							changeHistory: [],
							channel: "Facebook",
							client_id: "df63292e4",
							client_name: "Elizabeth Arden",
							copy: "Get ready to kick off Comic-Con Africa with a BANG! 💥  ",
							createdAt: "2023-10-10T00:48:18.264Z",
							createdBy: "4rAs2Xj9T4xoqBpsxLo0",
							description: "",
							handle: "Elizabeth Arden South Africa",
							id: "hZgzLwGl6oUx5YkOj4vi",
							link: "",
							publishDate: "2023-10-10T00:48:13.430Z",
							stage: 3,
							status: "Planning",
							title: "",
							visualDriveLink: "",
							visualType: "",
							hashtags: [`#ElizabethArden`],
							tags: [
								`@edgarsbeauty`,
								`@woolworths_sa`,
								`@superbalist`,
								`@truworthsfashion`,
								`@clicks_sa`,
								`@dischem.beauty`,
								`@dischem_pharmacies`,
								`@friendsofbash`,
								`@wooliesbeauty`,
							],
						},
						{
							title: "Retinol",
							calendar_id: "hZgzLwGl6oUx5YkOj4vi",
							date: "14/11/2023",
							time: "13:00",
							postType: "Banner",
							approval: false,
							assets: [
								{
									id: "1",
									link: "https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/contentCreationPosts%2FScreenshot%202023-11-10%20at%2016.12.43.png?alt=media&token=a2ad2046-4dcc-422a-bb78-0939820d51e6",
									type: "img",
								},
							],
							assetsLink: "",
							assignment: [],
							assignment_ids: [],
							changeHistory: [],
							channel: "Facebook",
							client_id: "df63292e4",
							client_name: "Elizabeth Arden",
							copy: "Get ready to kick off Comic-Con Africa with a BANG! 💥  ",
							createdAt: "2023-10-10T00:48:18.264Z",
							createdBy: "4rAs2Xj9T4xoqBpsxLo0",
							description: "",
							handle: "Elizabeth Arden South Africa",
							id: "hZgzLwGl6oUx5YkOj4vi",
							link: "",
							publishDate: "2023-10-10T00:48:13.430Z",
							stage: 3,
							status: "Planning",
							title: "",
							visualDriveLink: "",
							visualType: "",
							hashtags: [`#ElizabethArden`],
							tags: [
								`@edgarsbeauty`,
								`@woolworths_sa`,
								`@superbalist`,
								`@truworthsfashion`,
								`@clicks_sa`,
								`@dischem.beauty`,
								`@dischem_pharmacies`,
								`@friendsofbash`,
								`@wooliesbeauty`,
							],
						},
						{
							title: "Retinol",
							calendar_id: "hZgzLwGl6oUx5YkOj4vi",
							date: "13/11/2023",
							time: "13:00",
							postType: "Reel",
							approval: false,
							assets: [
								{
									id: "1",
									link: "https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/contentCreationPosts%2FInner-01.png?alt=media&token=4339c4a7-9037-49f6-9978-37fb7853d11e&_gl=1*1u7ml0h*_ga*MjI0OTc5NDIyLjE2NjczODk0NzU.*_ga_CW55HF8NVT*MTY5Njg5Mjk4MS4xOTkuMS4xNjk2ODk3NDUxLjYwLjAuMA..",
									type: "img",
								},
								{
									id: "1",
									link: "https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/contentCreationPosts%2FInner-01.png?alt=media&token=4339c4a7-9037-49f6-9978-37fb7853d11e&_gl=1*1u7ml0h*_ga*MjI0OTc5NDIyLjE2NjczODk0NzU.*_ga_CW55HF8NVT*MTY5Njg5Mjk4MS4xOTkuMS4xNjk2ODk3NDUxLjYwLjAuMA..",
									type: "img",
								},
							],
							assetsLink: "",
							assignment: [],
							assignment_ids: [],
							changeHistory: [],
							channel: "Instagram",
							client_id: "df63292e4",
							client_name: "Elizabeth Arden",
							copy: "Get ready to kick off Comic-Con Africa with a BANG! 💥  A co-ordinated flow for the background of each image, with the aim of a unified look and feel on the Instagram feedA co-ordinated flow for the background of each image, with the aim of a unified look and feel on the Instagram feedA co-ordinated flow for the background of each image, with the aim of a unified look and feel on the Instagram feed",
							createdAt: "2023-10-10T00:48:18.264Z",
							createdBy: "4rAs2Xj9T4xoqBpsxLo0",
							description: "",
							handle: "@elizabetharden_sa",
							id: "hZgzLwGl6oUx5YkOj4vi",
							link: "",
							publishDate: "2023-10-10T00:48:13.430Z",
							stage: 1,
							status: "Planning",
							title: "",
							visualDriveLink: "",
							visualType: "",
							hashtags: [`#ElizabethArden`],
							tags: [
								`@edgarsbeauty`,
								`@woolworths_sa`,
								`@superbalist`,
								`@truworthsfashion`,
								`@clicks_sa`,
								`@dischem.beauty`,
								`@dischem_pharmacies`,
								`@friendsofbash`,
								`@wooliesbeauty`,
							],
						},
					],
				},
			],
			selectedUser: "",

			// posts: [
			// 	{
			// 		approval: false,
			// 		assets: [
			// 			{
			// 				id: "1",
			// 				link: "https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/contentCreationPosts%2FComicon_Grid%20Post-01.png?alt=media&token=03c132c3-a203-43cf-8f4e-398a9cc537eb&_gl=1*10rwoo3*_ga*MjI0OTc5NDIyLjE2NjczODk0NzU.*_ga_CW55HF8NVT*MTY5Njg5Mjk4MS4xOTkuMS4xNjk2ODk3NDAzLjM2LjAuMA..",
			// 				type: "img",
			// 			},
			// 			{
			// 				id: "1",
			// 				link: "https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/contentCreationPosts%2FInner-01.png?alt=media&token=4339c4a7-9037-49f6-9978-37fb7853d11e&_gl=1*1u7ml0h*_ga*MjI0OTc5NDIyLjE2NjczODk0NzU.*_ga_CW55HF8NVT*MTY5Njg5Mjk4MS4xOTkuMS4xNjk2ODk3NDUxLjYwLjAuMA..",
			// 				type: "img",
			// 			},
			// 		],
			// 		assetsLink: "",
			// 		assignment: [],
			// 		assignment_ids: [],
			// 		changeHistory: [],
			// 		channel: "Facebook",
			// 		client_id: "df63292e4",
			// 		client_name: "Elizabeth Arden",
			// 		copy: "Get ready to kick off Comic-Con Africa with a BANG! 💥  ",
			// 		createdAt: "2023-10-10T00:48:18.264Z",
			// 		createdBy: "4rAs2Xj9T4xoqBpsxLo0",
			// 		description: "",
			// 		handle: "Elizabeth Arden South Africa",
			// 		id: "hZgzLwGl6oUx5YkOj4vi",
			// 		link: "",
			// 		publishDate: "2023-10-10T00:48:13.430Z",
			// 		stage: 1,
			// 		status: "Planning",
			// 		title: "",
			// 		visualDriveLink: "",
			// 		visualType: "",
			// 	},
			// ],

			crudDocumentName: "contentcalendar_content",
		};
	}

	componentDidMount() {
		// this.validateSession();
		// this.getcalendars();
		console.log("this.user_data.user_data", this.props.user_data);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.user_data !== this.props.user_data) {
			this.setState({
				user_data: this.props.user_data,
			});
		}
	}

	render() {
		let calendars = this.state.calendars.filter((d_) => {
			let filter = d_;

			if (
				typeof this.state.filterSearch !== "undefined" &&
				this.state.filterSearch !== ""
			) {
				let text_ = this.state.filterSearch;
				var outString = text_.replace(
					/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
					"",
				);

				let join_names = `${d_.title} ${d_.client_name}`;

				join_names = join_names.replace(
					/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
					"",
				);

				return join_names
					.toLowerCase()
					.includes(outString.toLowerCase());
			}

			return filter;
		});
		return (
			<View
				style={{
					flex: 1,
					padding: 10,
				}}>
				<View
					style={{
						flex: 1,
						width: "100%",
						// maxWidth: 1000,
						flexDirection: "row",
						marginBottom: 5,
						// width: "60%",
					}}>
					<View
						style={{
							width: 500,
							paddingBottom: 60,
							backgroundColor: "#FFF",
							padding: 5,
							borderRadius: 10,
							// alignItems: "center",
							display:
								this.state.selectedContentCalendar !== ""
									? "none"
									: "flex",
						}}>
						<View
							style={{
								flexDirection: "row",
								justifyContent: "space-between",
								alignItems: "center",
							}}>
							<View
								style={{
									padding: 10,
								}}>
								<TouchableOpacity
									style={{
										flexDirection: "row",
										alignItems: "center",
										// position: "absolute",
									}}
									onPress={() => {
										// this.props.close();
									}}>
									<AutoHeightImage
										width={20}
										source={require("../../../assets/images/menu.png")}
									/>
									<Text
										adjustsFontSizeToFit
										style={{
											fontWeight: "800",
											fontFamily: "Gotham",
											color: APP_COLOURS.BG2,
											fontSize: 13,
											marginLeft: 4,
										}}>
										tetrice
									</Text>
								</TouchableOpacity>
								<Text
									style={{
										color: APP_COLOURS.BG2,
										fontSize: 28,
										fontWeight: "800",
										fontFamily: "Gotham",
										// marginTop: 20,
										// marginTop: 6,
									}}>
									Content Calendar
								</Text>

								{/* <Text
									style={{
										fontSize: 16,
										color: APP_COLOURS.BG2,
										fontWeight: "300",
										padding: 2,
										marginBottom: 20,
									}}>
									Calendar per month
								</Text> */}
							</View>
							<View
								style={
									{
										// position: "absolute",
										// right: 10,
										// top: 10,
									}
								}>
								<Lottie
									options={{
										loop: true,
										autoplay: true,
										animationData: animationData,
										rendererSettings: {
											preserveAspectRatio:
												"xMidYMid slice",
										},
									}}
									height={150}
									width={200}
								/>
							</View>
						</View>

						<View
							style={{
								// flex: 1,
								width: "100%",
								flexDirection: "row",
								alignItems: "center",
								height: 45,
								paddingLeft: 5,
							}}>
							<View
								style={{
									// height: "auto",
									// width: "100%",
									flex: 1,
									minWidth: 200,
									// maxWidth: 400,
									backgroundColor: APP_COLOURS.BLACK_TRANS,
									borderRadius: 13,
								}}>
								{this.state.showInput ? (
									<TextInput
										style={{
											fontWeight: "400",
											fontFamily: "Gotham",

											width: "100%",
											paddingLeft: 15,
											padding: 10,
											fontSize: 15,
											borderRadius: 13,
											marginRight: 15,
											color: APP_COLOURS.BG2,
											// backgroundColor:
											// 	APP_COLOURS.WHITE_TRANSPARENT4,
											backgroundColor: "#E8E8E8",
											fontWeight: "500",
										}}
										placeholderTextColor={"#797979"}
										autoCapitalize='sentences'
										clearButtonMode='while-editing'
										autoCompleteType='off'
										editable={true}
										autoCorrect={true}
										autoFocus
										keyboardType='default'
										textContentType='none'
										placeholder={` Search `}
										// defaultValue={this.state.user_data.email}
										defaultValue={this.state.filterSearch}
										onChangeText={(text) => {
											console.log(text);
											this.setState({
												filterSearch: text,
											});
										}}
									/>
								) : null}
							</View>
							<TouchableOpacity
								style={{
									marginRight: 10,
									marginLeft: 10,
								}}
								onPress={() =>
									this.setState({
										showInput: !this.state.showInput,
									})
								}>
								<AutoHeightImage
									width={19}
									source={require("../../../assets/images/searchIcon.png")}
								/>
							</TouchableOpacity>
							{this.state.loading ? (
								<ActivityIndicator
									color={APP_COLOURS.PRIMARY}
								/>
							) : null}
							{this.state.user_data.username ===
							"wesley@tetrice.co.za" ? (
								<TouchableOpacity
									style={{
										// marginRight: 15,
										marginLeft: 15,
										// backgroundColor: APP_COLOURS.BG2,
										borderRadius: 15,
										flexDirection: "row",
										paddingVertical: 10,
										alignItems: "center",
										paddingHorizontal: 15,
										borderWidth: 0.5,
										borderColor: APP_COLOURS.BG2,
									}}
									onPress={async () => {
										this.setState({
											addContentCalendar:
												!this.state.addContentCalendar,
										});
									}}>
									<Text
										style={{
											color: APP_COLOURS.BG2, // "#FFF",
											fontSize: 14,
											marginRight: 10,
										}}>
										+
									</Text>
									<Text
										style={{
											color: APP_COLOURS.BG2, // "#FFF",
											fontSize: 12,
										}}>
										New
									</Text>
								</TouchableOpacity>
							) : null}
						</View>

						{calendars
							.sort((a, b) => a.dateStart > b.dateStart)
							.map((d) => this.ccCard(d))}
					</View>

					{/* {calendars
							.sort((a, b) => a.dateStart > b.dateStart)
							.map((d) => this.contentCalendarItem(d))} */}

					{this.state.selectedContentCalendar !== ""
						? this.calendarPage(this.state.selectedContentCalendar)
						: null}

					{/* <View
						style={{
							flex: 2,
						}}>
						{this.state.selectedUser !== ""
							? this.profileItem(this.state.selectedUser)
							: null}
					</View> */}
				</View>
				{this.addNewContent()}
				{this.addContentCalendar()}
			</View>
		);
	}

	overviewSection = (d) => {
		return (
			<View
				style={{
					width: "100%",
					flexDirection: "row",
					flexWrap: "wrap",
					padding: 5,
				}}>
				<View
					style={{
						flex: 1,
						padding: 10,
					}}>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							padding: 10,
						}}>
						<View style={{}}>
							<Text
								style={{
									color: APP_COLOURS.BG2,
									fontSize: 35,
									fontWeight: "800",
									fontFamily: "Gotham",
									// marginTop: 20,
									// marginTop: 6,
								}}>
								{d.client_name}
							</Text>

							<Text
								style={{
									color: APP_COLOURS.BG2,
									fontSize: 23,
									fontWeight: "500",
									fontFamily: "Gotham",
									top: -10,
								}}>
								<Text
									style={{
										color: APP_COLOURS.BG2,
										fontSize: 28,
										fontFamily: "Gotham",
										fontWeight: "800",
									}}>
									{d.title}
								</Text>{" "}
								- Content Calendar
							</Text>
						</View>
					</View>
					<View style={{ padding: 10 }}>
						<Text
							style={{
								fontSize: 16,
								color: APP_COLOURS.BG2,
								fontWeight: "500",
								marginBottom: 5,
								marginTop: 20,
							}}>
							Objectives:
						</Text>
						{d.objectives.map((dd) => (
							<Text
								style={{
									fontSize: 16,
									color: APP_COLOURS.BG2,
									fontWeight: "300",
									marginBottom: 2,
								}}>
								{dd}
							</Text>
						))}

						<Text
							style={{
								fontSize: 16,
								color: APP_COLOURS.BG2,
								fontWeight: "500",
								marginBottom: 5,
								marginTop: 20,
							}}>
							This months focus:
						</Text>
						{d.focusThisMonth.map((dd) => (
							<Text
								style={{
									fontSize: 16,
									color: APP_COLOURS.BG2,
									fontWeight: "300",
									marginBottom: 2,
								}}>
								- {dd}
							</Text>
						))}
						<Text
							style={{
								fontSize: 16,
								color: APP_COLOURS.BG2,
								fontWeight: "500",
								marginBottom: 5,
								marginTop: 20,
							}}>
							Important dates:
						</Text>
						{d.importantDates.map((dd) => (
							<Text
								style={{
									fontSize: 16,
									color: APP_COLOURS.BG2,
									fontWeight: "300",
									marginBottom: 2,
								}}>
								{dd}
							</Text>
						))}
					</View>
				</View>

				<View style={{ flex: 1, padding: 10 }}>
					<View
						style={{
							padding: 10,
							borderRadius: 60,
							borderWidth: 25,
							borderColor: "#000",
							flexDirection: "row",
							justifyContent: "center",
							alignContent: "center",
							paddingVertical: 40,
							backgroundColor: APP_COLOURS.DESELECTEDCOLOR,
						}}>
						<View
							style={{
								width: 600,
							}}>
							<PostCalendar
								compWidth={600}
								startDate={(dd) => {
									console.log("startDate", dd);
									this.setState({
										selectedDate: dd,
									});
								}}
								endDate={(dd) => {
									console.log(dd);
								}}
								selectedDate={moment(
									d.calendarDate,
									"DD/MM/YYYY",
								)}
								calendarDate={d.calendarDate}
								contentList={d.contentList}
							/>
						</View>
					</View>
				</View>
			</View>
		);
	};

	contentSection = (d) => {
		return (
			<View style={{ width: "100%" }}>
				<View
					style={{
						// flex: 1,
						width: "100%",
						flexDirection: "row",
						alignItems: "center",
						height: 45,
						paddingLeft: 5,
					}}>
					<Text
						style={{
							color: APP_COLOURS.BG2,
							fontSize: 28,
							fontWeight: "800",
							fontFamily: "Gotham",
							// marginTop: 20,
							// marginTop: 6,
						}}>
						Content
					</Text>
					<View
						style={{
							// height: "auto",
							// width: "100%",
							flex: 1,
							minWidth: 200,
							// maxWidth: 400,
							backgroundColor: APP_COLOURS.BLACK_TRANS,
							borderRadius: 13,
						}}>
						{this.state.showInput ? (
							<TextInput
								style={{
									fontWeight: "400",
									fontFamily: "Gotham",

									width: "100%",
									paddingLeft: 15,
									padding: 10,
									fontSize: 15,
									borderRadius: 13,
									marginRight: 15,
									color: APP_COLOURS.BG2,
									// backgroundColor:
									// 	APP_COLOURS.WHITE_TRANSPARENT4,
									backgroundColor: "#E8E8E8",
									fontWeight: "500",
								}}
								placeholderTextColor={"#797979"}
								autoCapitalize='sentences'
								clearButtonMode='while-editing'
								autoCompleteType='off'
								editable={true}
								autoCorrect={true}
								autoFocus
								keyboardType='default'
								textContentType='none'
								placeholder={` Search `}
								// defaultValue={this.state.user_data.email}
								defaultValue={this.state.filterSearch}
								onChangeText={(text) => {
									console.log(text);
									this.setState({
										filterSearch: text,
									});
								}}
							/>
						) : null}
					</View>
					<TouchableOpacity
						style={{
							marginRight: 10,
							marginLeft: 10,
						}}
						onPress={() =>
							this.setState({
								showInput: !this.state.showInput,
							})
						}>
						<AutoHeightImage
							width={19}
							source={require("../../../assets/images/searchIcon.png")}
						/>
					</TouchableOpacity>
					{this.state.loading ? (
						<ActivityIndicator color={APP_COLOURS.PRIMARY} />
					) : null}
					{this.state.user_data.username ===
					"wesley@tetrice.co.za" ? (
						<TouchableOpacity
							style={{
								// marginRight: 15,
								marginLeft: 15,
								// backgroundColor: APP_COLOURS.BG2,
								borderRadius: 15,
								flexDirection: "row",
								paddingVertical: 10,
								alignItems: "center",
								paddingHorizontal: 15,
								borderWidth: 0.5,
								borderColor: APP_COLOURS.BG2,
							}}
							onPress={async () => {
								this.setState({
									addNewContent: !this.state.addNewContent,
								});
							}}>
							<Text
								style={{
									color: APP_COLOURS.BG2, // "#FFF",
									fontSize: 14,
									fontWeight: "400",
									marginRight: 10,
								}}>
								+
							</Text>
							<Text
								style={{
									color: APP_COLOURS.BG2, // "#FFF",
									fontSize: 14,
									fontWeight: "400",
								}}>
								New
							</Text>
						</TouchableOpacity>
					) : null}
				</View>
				<View
					style={{
						flex: 1,
						padding: 10,
						paddingVertical: 40,
						paddingBottom: 60,
					}}>
					{d.contentList.length > 0
						? d.contentList
								.sort((a, b) => a.date > b.date)
								.filter(
									(dd) =>
										dd.date ===
										moment(this.state.selectedDate).format(
											"DD/MM/YYYY",
										),
								)
								.map((d) => (
									<ContentCalendarItem
										data={d}
										selectedPost={(sp) => console.log(sp)}
									/>
								))
						: null}
				</View>
			</View>
		);
	};

	ccCard = (d) => {
		let size = 16;
		return (
			<TouchableOpacity
				style={{}}
				onPress={async () => {
					await this.setState({
						selectedContentCalendar: d,
					});

					// setTimeout(() => {
					// 	// this.setState({
					// 	//   page: this.state.tabs[0],
					// 	// });
					// }, 200);

					console.log("SLECTED=======", d);
				}}>
				<View
					style={{
						marginBottom: 5,
						// padding: 10,
						backgroundColor:
							this.state.selectedContentCalendar.id === d.id
								? APP_COLOURS.DESELECTEDCOLOR
								: APP_COLOURS.WHITE_TRANSPARENT,
						flexDirection: "row",
						alignItems: "center",
						borderRadius: 8,
						overflow: "hidden",
					}}>
					<View
						style={{
							flex: 1,
							flexDirection: "row",
							// alignItems: 'center',
						}}>
						<View
							style={{
								flex: 1,
								height: "auto",
								maxWidth: 10,
								borderRadius: 2,
								// backgroundColor: TaskStatus(d.status),
								backgroundColor:
									this.state.selectedContentCalendar.id ===
									d.id
										? APP_COLOURS.BLUE
										: APP_COLOURS.WHITE_TRANSPARENT,
								marginRight: 15,
								justifyContent: "center",
								alignItems: "center",
							}}></View>
						{/* <View
				style={{
					justifyContent: "center",
					alignItems: "center",
					width: 110,
				}}>
				<View
					style={{
						// width: 150,
						justifyContent: "center",
						alignItems: "center",
						paddingRight: 2,
					}}>
					<Text
						allowFontScaling={false}
						style={{
							fontWeight: "700",
							fontSize: 14,
							// color: TaskStatus(d.status),
							// opacity: 0.8,
						}}>
						{moment(d.publishDate).fromNow()}
					</Text>
					<Text
						allowFontScaling={false}
						style={{
							fontWeight:
								this.state.selectedContentCalendar === d.id
									? "700"
									: "300",
							fontSize: 10,
							color: APP_COLOURS.BG2,
							// opacity: 0.8,
						}}>
						{typeof d.publishDate !== "undefined" &&
						d.publishDate !== ""
							? `${moment(d.publishDate).format(
									"DD/MM/YY HH:mm:ss",
							  )}`
							: ""}
					</Text>
				</View>
			</View> */}

						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "row",
								padding: 10,
							}}>
							<View
								style={{
									width: 200,
								}}>
								<Text
									allowFontScaling={false}
									style={{
										fontWeight: "500",
										fontSize: 16,
										color: APP_COLOURS.BG2,
									}}>
									{d.client_name}
								</Text>
								{/* <Text
									allowFontScaling={false}
									style={{
										fontWeight: "300",
										fontSize: 14,
										color: APP_COLOURS.BG2,
									}}>
									{d.handle}
								</Text> */}
							</View>
						</View>

						<View
							style={{
								flex: 1,
								// justifyContent: "center",
								alignItems: "center",
								flexDirection: "row",
							}}>
							<Text
								allowFontScaling={false}
								style={{
									fontWeight:
										this.state.selectedContentCalendar ===
										d.id
											? "700"
											: "500",
									fontSize: 16,
									color: APP_COLOURS.BG2,
								}}>
								{d.title}
							</Text>
						</View>

						<View
							style={{
								width: 80,
								justifyContent: "center",
								alignItems: "center",
								borderLeftWidth: 1,
								paddingLeft: 5,
								borderLeftColor: "#E8E8E8",
							}}>
							<View
								style={{
									flexDirection: "row",
									width: "100%",
								}}>
								{[
									"Planning",
									"Design",
									"Copy",
									"Awaiting Approval",
									"Approved",
									"Scheduled",
									"Published",
								].map((p, i) => {
									let current = d.stage === i + 1;
									let pending = d.stage < i + 1;
									let complete = d.stage > i + 1;
									return (
										<View
											style={{
												width: 80,
												display:
													complete || pending
														? "none"
														: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}>
											<Text
												style={{
													fontSize: 10,
													fontWeight: current
														? "700"
														: "300",
												}}>
												{p}
											</Text>
										</View>
									);
								})}
							</View>
							{/* <View
								style={{
									width: 80,
									marginVertical: 15,
									backgroundColor: "#FFF",
								}}>
								<ProgressBarStages
									progress={`${(d.stage / 7) * 100}%`}
									inProgress={true}
									color={TaskStatus(d.status)}
								/>
							</View> */}
						</View>
					</View>
				</View>
			</TouchableOpacity>
		);
	};

	calendarPage = (d) => {
		return (
			<View
				style={{
					flex: 1,
					flexDirection: "row",
				}}>
				<View
					style={{
						flex: 1,
						backgroundColor: "#FFF",
						padding: 5,
						borderRadius: 10,
						// alignItems: "center",
						marginLeft: 5,
					}}>
					<View
						style={{
							padding: 10,
						}}>
						<TouchableOpacity
							style={{
								borderRadius: 15,
								flexDirection: "row",
								paddingVertical: 10,
								alignItems: "center",
								paddingHorizontal: 15,
								borderWidth: 0.5,
								borderColor: APP_COLOURS.BG2,
								maxWidth: 150,
							}}
							onPress={async () => {
								this.setState({
									selectedContentCalendar: "",
								});
							}}>
							<Text
								style={{
									color: APP_COLOURS.BG2, // "#FFF",
									fontSize: 12,
								}}>
								Back
							</Text>
						</TouchableOpacity>
					</View>
					<View
						style={{
							flex: 1,
							padding: 10,
						}}>
						<ScrollView showsVerticalScrollIndicator={false}>
							{this.overviewSection(d)}
							{this.contentSection(d)}
						</ScrollView>
					</View>
				</View>

				{this.loadingComp()}
			</View>
		);
	};

	loadingComp = () => {
		if (this.state.loading) {
			return (
				<Modal animationType='fade' transparent={true} visible={true}>
					<View
						style={{
							flex: 1,
							justifyContent: "flex-start",
							alignItems: "center",
							backgroundColor: "rgba(52, 52, 52, 0.6)",
						}}>
						<View
							style={{
								flex: 1,
								justifyContent: "center",
								alignItems: "center",
							}}>
							<View
								style={{
									// flex: 1,
									width: 300,
									height: 250,
									backgroundColor: "#FFF",
									justifyContent: "center",
									alignItems: "center",
									borderRadius: 15,
								}}>
								<Image
									source={debbie}
									style={{
										width: 70,
										height: 60,
										resizeMode: "contain",
									}}
								/>
								<Text
									style={{
										color: APP_COLOURS.BG2,
										fontSize: 15,
										marginLeft: 10,
										fontWeight: "600",
										fontFamily: "Gotham",
									}}>
									Loading...
								</Text>

								<ActivityIndicator color={APP_COLOURS.BG2} />
							</View>
						</View>
					</View>
				</Modal>
			);
		}
	};

	addNewContent = () => {
		if (this.state.addNewContent) {
			return (
				<AddContent
					{...this.state}
					selectedContentCalendarId={
						this.state.selectedContentCalendar.id
					}
					addEntry={(obj) => {
						this.setState({
							loading: true,
						});
						crudCreate(this.state.crudDocumentName, obj, () => {
							this.setState((prevState) => ({
								// projects: [...prevState.projects, newProject],
								addNewContent: false,
								loading: false,
							}));
						});
					}}
					close={() =>
						this.setState({
							addNewContent: false,
							loading: false,
						})
					}
				/>
			);
		}
	};

	addContentCalendar = () => {
		if (this.state.addContentCalendar) {
			return (
				<AddContentCalendar
					{...this.state}
					selectedProject={this.state.filterTab}
					addEntry={(obj) => {
						crudCreate("contentcalendars", obj, () => {
							// this.setState((prevState) => ({
							// 	projects: [...prevState.projects, newProject],
							// 	addNewContent: false,
							// }));
						});
					}}
					close={() =>
						this.setState({
							addContentCalendar: false,
						})
					}
				/>
			);
		}
	};
}
