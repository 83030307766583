import React, { useState, useEffect } from "react";
import { View, Text } from "react-native";

export const Timer = ({ onTimeout }) => {
	let time = 10;
	// let time = 10;
	const [seconds, setSeconds] = useState(time);
	const [iterations, setIterations] = useState(0);

	useEffect(() => {
		if (seconds === 0) {
			onTimeout();
			setSeconds(time);
			setIterations(iterations + 1);
			return;
		}
		const interval = setInterval(() => {
			setSeconds(seconds - 1);
		}, 1000);
		return () => clearInterval(interval);
	}, [seconds, iterations, onTimeout]);

	return (
		<View>
			{/* <Text
				style={{
					color: "#FFF",
					fontSize: 9,
				}}>{`${seconds}`}</Text> */}
		</View>
	);
};

export const Timer60 = ({ onTimeout }) => {
	let time = 60;
	const [seconds, setSeconds] = useState(time);
	const [iterations, setIterations] = useState(0);

	useEffect(() => {
		if (seconds === 0) {
			onTimeout();
			setSeconds(time);
			setIterations(iterations + 1);
			return;
		}
		const interval = setInterval(() => {
			setSeconds(seconds - 1);
		}, 1000);
		return () => clearInterval(interval);
	}, [seconds, iterations, onTimeout]);

	return (
		<View>
			{/* <Text
				style={{
					color: "#FFF",
					fontSize: 9,
				}}>{`Time left: ${seconds} seconds`}</Text> */}
		</View>
	);
};
