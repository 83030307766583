import React, { useState, useEffect, Component } from "react";
import { useParams, useLocation } from "react-router-dom";
import ProjectDocuments from "./ProjectDocuments";
import ProjectManager from "./ProjectManager";

function useQuery() {
	// WORKS FOR QURY URL
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ProjectRouter = ({ parentData }) => {
	let query = useQuery();
	let project_id = query.get("pid");

	return <ProjectManager {...parentData} project_id={project_id} />;
};

export default ProjectRouter;
