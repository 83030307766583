import n1 from "./ding-126626.mp3";
import n2 from "./dogru-128492.mp3";
import n3 from "./greanpatch-166007.mp3";
import n4 from "./marimba-for-smartphone-151931.mp3";
import n5 from "./notification-6175.mp3";
import n6 from "./simple-notification-152054.mp3";
import n7 from "./tap-notification-180637.mp3";

import n8 from "./mixkit-correct-answer-tone-2870.wav";
import n9 from "./mixkit-magic-marimba-2820.wav";

const audioFiles = [n1, n2, n3, n4, n5, n6, n7, n8, n9];

export function playAudio(audioIndex = 0) {
	const audioFile = audioFiles[audioIndex];
	if (audioFile) {
		const audio = new Audio(audioFile);
		audio
			.play()
			.catch((error) => console.error("Error playing audio:", error));
	}
}
