import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	Alert,
	LayoutAnimation,
	Animated,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import debbie from "../../../assets/images/cloud.png";
import bin from "../../../assets/images/bin.png";
import { APP_COLOURS } from "../../APP_VARS";
import { Avatars, TaskStatus } from "../../APP_VARS";
import Fade from "react-reveal/Fade";
import {
	crudCreate,
	crudDelete,
	crudSendEmail,
	crudUpdate,
	sendDiscordMessage,
} from "../../CRUDHELPER";
// import FullCalendar from "../DailyPlanner/FullCalendar";
import CoverageCalendar from "../DailyPlanner/CoveragCalendar";
import UsersSelectList from "../UsersSelectList";
import SmallerTabs from "../TopTabs/SmallerTabs";
import ChatBox from "../DailyPlanner/ChatBox";
import FullCalendar from "../DailyPlanner/FullCalendar";
import StatusItem from "../DailyPlanner/StatusItem";

const collectionName = "projects_tasks";

export default class EditTask extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props.d,

			dateAllocation: "start_date",
			loading: false,

			subPage: "Task",
		};
	}

	saveEdit = async () => {
		let obj = {
			...this.state,
		};
		this.setState({
			loading: true,
		});
		// console.log(this.state);
		// return;

		let user_data = JSON.parse(localStorage.getItem("user_data"));
		sendDiscordMessage(
			`${user_data.first_name} ${user_data.last_name}`,
			`[Task Updated] 
- ${obj.status}
- ${obj.title}`,
			`${window.location.origin}${
				Avatars[parseInt(user_data.avatar)].pp
			}`,
			"0QPZYrY6zpAnJXqX9gQl",
		);

		const mapThrough = await this.state.assignment_ids.forEach(
			async (id) => {
				await sendDiscordMessage(
					`${user_data.first_name} ${user_data.last_name}`,
					`### *${this.props.selectedProject.brand.title} - ${
						this.props.selectedProject.title
					}*
[Edited Task]
- *${obj.status}*
> ${obj.title}
> ${moment(obj.start_date).format("DD/MM")} to ${moment(obj.end_date).format(
						"DD/MM",
					)}
`,
					`${window.location.origin}${
						Avatars[parseInt(user_data.avatar)].pp
					}`,
					id,
				);
			},
		);

		crudUpdate(collectionName, obj, (status, d) => {
			// console.log(status, d);
			console.log("updateItem");
			if (status === 200) {
				this.props.close();
				this.props.reloadData();
			}
		});
	};

	deleteItem = (d) => {
		let user_data = JSON.parse(localStorage.getItem("user_data"));
		sendDiscordMessage(
			`${user_data.first_name} ${user_data.last_name}`,
			`[Task Deleted]
- ${d.title}`,
			`${window.location.origin}${
				Avatars[parseInt(user_data.avatar)].pp
			}`,
			"0QPZYrY6zpAnJXqX9gQl",
		);
		crudDelete(collectionName, d, () => {
			this.props.close();
			this.props.reloadData();
		});
	};

	componentDidUpdate(prevProps) {
		// console.log("change", this.props.d);
		if (prevProps.d !== this.props.d) {
			this.setState({
				...this.props.d,
			});
		}
	}

	componentDidMount() {
		console.log("selectedProject", this.props.selectedProject);
	}

	render() {
		let color = APP_COLOURS.BG2;
		let d = this.props.d;

		let size = 35;
		let fs = 13;

		let textBox = {
			fontSize: 16,
			fontWeight: "600",
			color: color,
			width: "100%",
			height: "auto",
			borderBottomWidth: 0.5,
			padding: 10,
			borderRadius: 10,
			backgroundColor: "#FFF",
		};

		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						// backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<TouchableOpacity
						style={{
							width: window.innerWidth,
							height: window.innerHeight,
							justifyContent: "center",
							alignItems: "center",
							// backgroundColor: "rgba(52, 52, 52, 0.6)",
							backgroundColor: APP_COLOURS.BG2,
						}}
						onPress={() => this.props.close()}></TouchableOpacity>

					<View
						style={{
							// width: "100%",
							alignItems: "center",
							position: "absolute",
							zIndex: 10,
							// top: 0,
							maxWidth: 1100,
							width: "90%",
							height: "90%",
						}}>
						<View
							style={{
								flex: 1,
								// backgroundColor: APP_COLOURS.TEXTWHITE,
								backgroundColor: "#F1F5FA",
								padding: 5,
								// maxWidth: 800,
								width: "100%",
								borderRadius: 15,
								// maxHeight: window.innerHeight - 2,
							}}>
							<View
								style={{
									flex: 1,
								}}>
								<View
									style={{
										flexDirection: "row",
										width: "100%",
										alignItems: "center",
										// marginTop: 30,
										marginBottom: 10,
										height: "auto",
										// borderBottomWidth: 0.5,
										paddingBottom: 10,
										backgroundColor: TaskStatus(
											this.state.status,
										),
										borderRadius: 10,
										padding: 7,
									}}>
									<View
										style={{
											flex: 1,
											flexDirection: "row",
											alignItems: "center",
										}}>
										<TouchableOpacity
											onPress={() => this.props.close()}>
											<Image
												source={require("../../../assets/images/close.png")}
												style={{
													width: 25,
													height: 25,
													resizeMode: "contain",
												}}
											/>
										</TouchableOpacity>
										<Text
											numberOfLines={1}
											ellipsizeMode='tail'
											style={{
												color: APP_COLOURS.BG2,
												fontSize: 19,
												marginLeft: 10,
												width: "100%",
												textAlign: "center",
											}}>
											{this.state.title}
										</Text>
									</View>
									<TouchableOpacity
										onPress={(d) => {
											this.setState({
												subPage: "Assignment",
											});
										}}>
										<View
											style={{
												width: 90,
												// backgroundColor: '#252525',
												// padding: fs / 2,
												justifyContent: "center",
												// position: "absolute",
												// right: 10,
											}}>
											{d.assignment.length < 1 ? (
												<View
													style={{
														//  flex: 1,
														//  backgroundColor: '#252525',
														justifyContent:
															"center",
														alignItems: "center",
														opacity: 0.4,
													}}>
													<Text
														style={{
															color: APP_COLOURS.BG2,
															fontSize: fs * 0.7,
														}}>
														+ Assignment
													</Text>
												</View>
											) : (
												<View
													style={{
														// width: 200,
														//  flexDirection: 'row-reverse',
														//  alignItems: 'flex-end',
														flexDirection: "row",
														// marginRight: 20,
														//  alignItems: 'center',
														// display: 'none',
														//  maxWidth: 120,
														width:
															d.assignment
																.length > 5
																? 4 * (size / 2)
																: d.assignment
																		.length *
																  (size / 2),
													}}>
													{d.assignment

														.slice(0, 5)
														.map((ass, i) => {
															return (
																<View
																	key={i}
																	style={{
																		flexDirection:
																			"row",
																		justifyContent:
																			"center",
																		alignItems:
																			"center",
																		backgroundColor:
																			"#FFF",
																		borderWidth: 2,
																		borderColor:
																			"#FFF",
																		width: size,
																		height: size,
																		borderRadius:
																			size /
																			2,
																		shadowColor:
																			"#6494B7",
																		shadowOffset:
																			{
																				width: 0,
																				height: 4,
																			},
																		shadowOpacity: 0.23,
																		shadowRadius: 4.65,
																		elevation: 8,
																		left:
																			(size /
																				2.2) *
																			-i,
																		// position: "absolute",
																		// top: 0,
																		zIndex:
																			i +
																			1,
																	}}>
																	<Image
																		// source={avatars[parseInt(ass.avatar)]}
																		source={
																			Avatars[
																				parseInt(
																					ass.avatar,
																				)
																			].pp
																		}
																		style={{
																			width: size,
																			height: size,
																			borderRadius:
																				size /
																				2,
																			resizeMode:
																				"contain",
																			backgroundcolor:
																				APP_COLOURS.BG2,
																		}}
																	/>
																</View>
															);
														})}
													{d.assignment.length > 5 ? (
														<View
															style={{
																backgroundColor:
																	"#252525",
																flexDirection:
																	"row",
																justifyContent:
																	"center",
																alignItems:
																	"center",
																//   position: 'absolute',
																right:
																	size / -2,
																width: size,
																height: size,

																borderRadius: 15,
															}}>
															<Text
																style={{
																	color: APP_COLOURS.BG2,
																	fontSize:
																		fs *
																		0.7,
																}}>
																+
																{d.assignment
																	.length - 5}
															</Text>
														</View>
													) : null}
												</View>
											)}
										</View>
									</TouchableOpacity>

									<StatusItem
										{...this.props}
										{...d}
										fs={fs}
										handleStatusChange={async (
											_id,
											_status,
										) => {
											let user_data = JSON.parse(
												localStorage.getItem(
													"user_data",
												),
											);

											let selectedProject =
												this.props.selectedProject;

											const mapThrough =
												await d.assignment_ids.forEach(
													async (id) => {
														await sendDiscordMessage(
															`${user_data.first_name} ${user_data.last_name}`,
															`### *${selectedProject.brand.title} - ${selectedProject.title}*
[Task Status] - *${_status}*
> ${d.title}
`,
															`${
																window.location
																	.origin
															}${
																Avatars[
																	user_data
																		.avatar
																].pp
															}`,
															id,
														);
													},
												);

											crudUpdate(
												"projects_tasks",
												{
													id: _id,
													status: _status,
												},
												() => {
													this.setState({
														status: _status,
													});

													this.props.reloadData();
												},
											);
										}}
									/>

									{/* <TouchableOpacity
										onPress={() => {
											this.deleteItem(this.state);
										}}
										style={
											{
												// position: "absolute",
												// right: 10,
												// top: 0,
											}
										}>
										<Image
											source={bin}
											style={{
												width: 30,
												height: 30,
												marginRight: 5,
												resizeMode: "contain",
											}}
										/>
									</TouchableOpacity> */}
								</View>

								<View
									style={{
										flex: 1,
										flexDirection: "row",
									}}>
									<View
										style={{
											flex: 1,
											padding: 5,
											maxWidth: 400,
											alignItems: "center",
											width: "100%",
											// paddingTop: 30,
										}}>
										<View
											style={{
												flexDirection: "row",
												alignItems: "center",
												justifyContent: "space-between",
											}}>
											<SmallerTabs
												selectedSubPage={(d) => {
													this.setState({
														subPage: d,
													});
													console.log(d);
												}}
												tabs={[
													"Task",
													"Assignment",
													"Edit",
												]}
											/>
										</View>

										{this.state.subPage === "Task" ? (
											<View
												style={{
													flex: 1,
													width: 350,

													// marginBottom: 10,
													padding: 5,
												}}>
												<View
													style={{
														width: "100%",
													}}>
													<Text
														style={{
															color: color,
															fontSize: 11,
														}}>
														Task title
													</Text>
													<TextInput
														returnKeyType='done'
														autoCorrect={false}
														style={textBox}
														value={this.state.title}
														editable={true}
														onChangeText={(text) =>
															this.setState({
																title: text,
															})
														}
														multiline
														numberOfLines={5}
														autoCapitalize='sentences'
														placeholder='Task Name'
														ref='taskName'
														clearButtonMode='while-editing'
													/>
												</View>
												<View
													style={{
														marginBottom: 10,
														// width: 350,
														width: "100%",
														marginVertical: 20,
													}}>
													<Text
														style={{
															color: color,
															fontSize: 11,
														}}>
														Description
													</Text>
													<TextInput
														returnKeyType='done'
														autoCorrect={false}
														style={textBox}
														value={
															this.state
																.description
														}
														editable={true}
														onChangeText={(text) =>
															this.setState({
																description:
																	text,
															})
														}
														multiline
														numberOfLines={10}
														autoCapitalize='sentences'
														placeholder='Description'
														ref='taskName'
														clearButtonMode='while-editing'
														autoFocus
													/>
												</View>
												<View
													style={{
														width: 350,
														// display: this.state.showCalendar
														// 	? "flex"
														// 	: "none",
														// justifyContent: "center",
														// alignItems: "center",
													}}>
													<CoverageCalendar
														compWidth={350}
														calendarList={[]}
														selectedDate={
															this.state[
																this.state
																	.start_date
															]
														}
														setStartDate={moment(
															this.state
																.start_date,
														).format("DD/MM/YYYY")}
														setEndtDate={moment(
															this.state.end_date,
														).format("DD/MM/YYYY")}
														startDate={(date) => {
															this.setState({
																start_date:
																	date,
															});
														}}
														endDate={(date) => {
															this.setState({
																end_date: date,
															});
														}}
													/>
													<View
														style={{
															width: 330,
															flexDirection:
																"row",
														}}>
														<View
															style={{
																marginTop: 20,
																flex: 1,
																marginRight: 10,
																opacity: 0.6,
															}}>
															<Text
																style={{
																	fontWeight:
																		"400",
																	fontFamily:
																		"Gotham",

																	fontSize: 10,
																	color: APP_COLOURS.BG2,
																	marginBottom: 4,
																	position:
																		"absolute",
																	top: -12,
																	left: 9,
																	backgroundColor:
																		"#FFF",
																	padding: 4,
																	borderRadius: 8,
																	zIndex: 99,
																}}>
																Start date
															</Text>
															<View
																style={{
																	borderRadius: 7,
																	borderColor:
																		APP_COLOURS.BG2,
																	borderWidth: 1,
																}}>
																<TouchableOpacity
																	onPress={() => {
																		this.setState(
																			{
																				dateAllocation:
																					"start_date",
																				showCalendar:
																					!this
																						.state
																						.showCalendar,
																			},
																		);
																	}}>
																	<View
																		style={{
																			width: "100%",
																			padding: 13,
																			// justifyContent: "center",
																			// alignItems: "center",
																			paddingLeft: 10,
																		}}>
																		<Text
																			style={{
																				color: APP_COLOURS.BG2,
																				fontWeight:
																					"600",
																				fontSize: 16,
																			}}>
																			{moment(
																				this
																					.state
																					.start_date,
																			)
																				.endOf(
																					"day",
																				)
																				.format(
																					"DD/MM/YYYY",
																				)}
																		</Text>
																	</View>
																</TouchableOpacity>
															</View>
														</View>
														<View
															style={{
																opacity: 0.6,
																marginTop: 20,
																flex: 1,
															}}>
															<Text
																style={{
																	fontWeight:
																		"400",
																	fontFamily:
																		"Gotham",

																	fontSize: 10,
																	color: APP_COLOURS.BG2,
																	marginBottom: 4,
																	position:
																		"absolute",
																	top: -12,
																	left: 9,
																	backgroundColor:
																		"#FFF",
																	padding: 4,
																	borderRadius: 8,
																	zIndex: 99,
																}}>
																Due date
															</Text>
															<View
																style={{
																	borderRadius: 7,
																	borderColor:
																		APP_COLOURS.BG2,
																	borderWidth: 1,
																}}>
																<TouchableOpacity
																	onPress={() => {
																		this.setState(
																			{
																				dateAllocation:
																					"end_date",
																			},
																		);
																	}}>
																	<View
																		style={{
																			width: "100%",
																			padding: 13,
																			// justifyContent: "center",
																			// alignItems: "center",
																			paddingLeft: 10,
																		}}>
																		<Text
																			style={{
																				color: APP_COLOURS.BG2,
																				fontWeight:
																					"600",
																				fontSize: 16,
																			}}>
																			{moment(
																				this
																					.state
																					.end_date,
																			).format(
																				"DD/MM/YYYY",
																			)}
																		</Text>
																	</View>
																</TouchableOpacity>
															</View>
														</View>
													</View>
												</View>
												<View
													style={{
														// backgroundColor: 'red',
														width: "100%",
														height: 40,
														flexDirection: "row",
														justifyContent:
															"space-between",
														marginTop: 15,
													}}>
													<TouchableOpacity
														disabled={
															this.state.loading
														}
														onPress={() =>
															this.saveEdit()
														}
														style={{
															flex: 1,
															backgroundColor:
																APP_COLOURS.RED,
															justifyContent:
																"center",
															borderRadius: 20,
														}}>
														{this.state.loading ? (
															<ActivityIndicator
																color={"#FFF"}
															/>
														) : (
															<Text
																style={{
																	width: "100%",
																	textAlign:
																		"center",
																	color: "white",
																	fontSize: 10,
																	fontWeight:
																		"600",
																	padding: 5,
																}}>
																SAVE
															</Text>
														)}
													</TouchableOpacity>
												</View>
											</View>
										) : null}

										{this.state.subPage === "Assignment" ? (
											<View
												style={{
													flex: 1,
													padding: 5,
													width: "100%",
												}}>
												<UsersSelectList
													assignment={
														this.state.assignment
													}
													assignment_ids={
														this.state
															.assignment_ids
													}
													isStandalone={false}
													addAssignment={(
														ass,
														ids,
													) => {
														this.setState({
															assignment: ass,
															assignment_ids: ids,
														});
													}}
												/>
												<View
													style={{
														// backgroundColor: 'red',
														width: "100%",
														height: 40,
														flexDirection: "row",
														justifyContent:
															"space-between",
														marginTop: 15,
													}}>
													<TouchableOpacity
														disabled={
															this.state.loading
														}
														onPress={() =>
															this.saveEdit()
														}
														style={{
															flex: 1,
															backgroundColor:
																APP_COLOURS.RED,
															justifyContent:
																"center",
															borderRadius: 20,
														}}>
														{this.state.loading ? (
															<ActivityIndicator
																color={"#FFF"}
															/>
														) : (
															<Text
																style={{
																	width: "100%",
																	textAlign:
																		"center",
																	color: "white",
																	fontSize: 10,
																	fontWeight:
																		"600",
																	padding: 5,
																}}>
																SAVE
															</Text>
														)}
													</TouchableOpacity>
												</View>
											</View>
										) : null}

										{this.state.subPage === "Edit" ? (
											<View
												style={{
													padding: 10,
													width: "100%",
													flexDirection: "row",
												}}>
												<TouchableOpacity
													onPress={() => {
														this.deleteItem(
															this.state,
														);
													}}
													style={
														{
															// position: "absolute",
															// right: 10,
															// top: 0,
														}
													}>
													<Image
														source={bin}
														style={{
															width: 30,
															height: 30,
															marginRight: 5,
															resizeMode:
																"contain",
														}}
													/>
												</TouchableOpacity>
												<Text
													style={{
														margin: 5,
														fontSize: 18,
													}}>
													Delete Task
												</Text>
											</View>
										) : null}
									</View>

									{/* {this.state.subPage === "Date" ? (
										
									) : null} */}

									<View
										style={{
											flex: 1,
											// marginLeft: 5,
											backgroundColor: "#FFF",
											padding: 5,
											borderRadius: 13,
											marginLeft: 10,
										}}>
										<ChatBox
											chatTitle={this.state.title}
											project_id={this.props.d.id}
											selectedProject={
												this.props.selectedProject
											}
											assignment_ids={
												this.state.assignment_ids
											}
											user_data={JSON.parse(
												localStorage.getItem(
													"user_data",
												),
											)}
										/>
									</View>
								</View>

								<View
									style={
										{
											// flex: 1,
										}
									}>
									{/* <View
						style={{
							flex: 1,
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
						}}>
						<TouchableOpacity
							onPress={() =>
								this.props.updateMoveTask(
									d.id,
									"right",
									"minus",
								)
							}>
							<View
								style={{
									width: 60,
									justifyContent: "center",
									alignItems: "center",
								}}>
								<Image
									style={{
										width: 40,
										height: 40,
										resizeMode: "contain",
									}}
									source={require("../../../assets/images/min_btn2.png")}
								/>
							</View>
						</TouchableOpacity>
						<TouchableOpacity
							onPress={() =>
								this.props.updateMoveTask(d.id, "right", "plus")
							}>
							<View
								style={{
									width: 60,
									justifyContent: "center",
									alignItems: "center",
								}}>
								<Image
									style={{
										width: 40,
										height: 40,
										resizeMode: "contain",
									}}
									source={require("../../../assets/images/add_btn2.png")}
								/>
							</View>
						</TouchableOpacity>
					</View>
					<View
						style={{
							flex: 1,
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
						}}>
						<TouchableOpacity
							onPress={() =>
								this.props.updateMoveTask(d.id, "left", "move")
							}>
							<View
								style={{
									marginRight: 4,
								}}>
								<Image
									style={{
										width: 60,
										height: 60,
										resizeMode: "contain",
									}}
									source={require("../../../assets/images/left_btn.png")}
								/>
							</View>
						</TouchableOpacity>

						<TouchableOpacity
							onPress={() =>
								this.props.updateMoveTask(d.id, "right", "move")
							}>
							<View
								style={{
									marginLeft: 2,
								}}>
								<Image
									style={{
										width: 60,
										height: 60,
										resizeMode: "contain",
									}}
									source={require("../../../assets/images/right_btn.png")}
								/>
							</View>
						</TouchableOpacity>
					</View> */}
								</View>
							</View>
						</View>
					</View>
				</View>
			</Modal>
		);
	}
}
