import React, { Component } from "react";
import {
	View,
	ScrollView,
	TouchableOpacity,
	TextInput,
	ActivityIndicator,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import DynamicSpreadsheetTable from "./DynamicSpreadsheetTable";
import AutoHeightImage from "../AutoHeightImageComp";

async function csvToJson(csvText) {
	try {
		// Split the text into lines
		const lines = csvText.split("\n");

		// Extract the header line
		const headers = lines[0].split(",");

		// Map the remaining lines into objects
		const result = lines.slice(1).map((line) => {
			const values = line.split(",");
			return headers.reduce((object, header, index) => {
				object[header] = values[index];
				return object;
			}, {});
		});

		return result;
	} catch (error) {
		console.error("Error fetching or parsing CSV:", error);
		return [];
	}
}

export default class DynamicSpreadSheet extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
		};
	}

	componentDidMount() {
		// "https://docs.google.com/spreadsheets/d/e/2PACX-1vTPddFYItyXSHu7Ygt1dyIkaAQ8W3GfpKhdwhJIMEEfHvzJ0Y7TVjqGoFh-PUsMMz8146cg1Qf4usJD/pub?gid=2120384877&single=true&output=csv"

		const fetch_ = fetch(
			"https://docs.google.com/spreadsheets/d/e/2PACX-1vQsPjxTTSHUkH7TRF8o1TPD0MxCaBCN5-9rKEIU758lMy84M4xXT3RYGtlsxzHPhLrjVvljOlxtNSQp/pub?output=csv",
		)
			.then((response) => response.text())
			.then((csv) => {
				csvToJson(csv).then((jsonArray) => {
					console.log(jsonArray);
					this.setState({
						data: jsonArray,
					});
				});
			});
	}

	render() {
		return (
			<View style={{ flex: 1 }}>
				<View
					style={{
						// flex: 1,
						width: "100%",
						flexDirection: "row",
						alignItems: "center",
						height: 45,
						paddingLeft: 5,
					}}>
					<View
						style={{
							// height: "auto",
							// width: "100%",
							flex: 1,
							minWidth: 200,
							// maxWidth: 400,
							backgroundColor: APP_COLOURS.BLACK_TRANS,
							borderRadius: 13,
						}}>
						{/* {this.state.showInput ? ( */}
						<TextInput
							style={{
								fontWeight: "400",
								fontFamily: "Gotham",

								width: "100%",
								paddingLeft: 15,
								padding: 10,
								fontSize: 15,
								borderRadius: 13,
								marginRight: 15,
								color: APP_COLOURS.BG2,
								// backgroundColor:
								// 	APP_COLOURS.WHITE_TRANSPARENT4,
								backgroundColor: "#E8E8E8",
								fontWeight: "500",
							}}
							placeholderTextColor={"#797979"}
							autoCapitalize='sentences'
							clearButtonMode='while-editing'
							autoCompleteType='off'
							editable={true}
							autoCorrect={true}
							autoFocus
							keyboardType='default'
							textContentType='none'
							placeholder={` Search `}
							// defaultValue={this.state.user_data.email}
							defaultValue={this.state.filterSearch}
							onChangeText={(text) => {
								console.log(text);
								this.setState({
									filterSearch: text,
								});
							}}
						/>
						{/* ) : null} */}
					</View>
					<TouchableOpacity
						style={{
							marginRight: 10,
							marginLeft: 10,
						}}
						onPress={() =>
							this.setState({
								showInput: !this.state.showInput,
							})
						}>
						<AutoHeightImage
							width={19}
							source={require("../../../assets/images/searchIcon.png")}
						/>
					</TouchableOpacity>
					{this.state.loading ? (
						<ActivityIndicator color={APP_COLOURS.PRIMARY} />
					) : null}
				</View>
				<View style={{ flex: 1 }}>
					<ScrollView>
						<DynamicSpreadsheetTable
							data={this.state.data.filter((d_) => {
								let filter = d_;

								if (
									typeof this.state.filterSearch !==
										"undefined" &&
									this.state.filterSearch !== ""
								) {
									let text_ = this.state.filterSearch;
									var outString = text_.replace(
										/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
										"",
									);

									let join_names = `${JSON.stringify(d_)}`;

									join_names = join_names.replace(
										/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
										"",
									);

									return join_names
										.toLowerCase()
										.includes(outString.toLowerCase());
								}

								return filter;
							})}
						/>
					</ScrollView>
				</View>
			</View>
		);
	}
}
