import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import { Avatars, StatusColour, TaskStatus } from "../../APP_VARS";
import StatusItem from "../DailyPlanner/StatusItem";
import Lottie from "react-lottie";
import {
	crudCreate,
	crudDelete,
	crudGetcalendars,
	crudRead,
	crudReadAll,
	crudUpdate,
} from "../../CRUDHELPER";
import Fade from "react-reveal/Fade";
import AutoHeightImage from "../AutoHeightImageComp";
import * as animationData from "../../../assets/lottie/team.json";
import AddGrowthForm from "./AddGrowthData";

export default class GrowthTracker extends Component {
	constructor(props) {
		super(props);
		this.state = {
			addgrowthData: true,
		};
	}

	render() {
		return (
			<View
				style={{
					flex: 1,
					padding: 10,
				}}>
				<View
					style={{
						flex: 1,
						width: "100%",
						// maxWidth: 1000,
						flexDirection: "row",
						marginBottom: 5,
						// width: "60%",
					}}>
					<View
						style={{
							width: 500,
							paddingBottom: 60,
							backgroundColor: "#FFF",
							padding: 5,
							borderRadius: 10,
							// alignItems: "center",
							display:
								this.state.selectedContentCalendar !== ""
									? "none"
									: "flex",
						}}>
						<View
							style={{
								flexDirection: "row",
								justifyContent: "space-between",
								alignItems: "center",
							}}>
							<View
								style={{
									padding: 10,
								}}>
								<TouchableOpacity
									style={{
										flexDirection: "row",
										alignItems: "center",
										// position: "absolute",
									}}
									onPress={() => {
										// this.props.close();
									}}>
									<AutoHeightImage
										width={20}
										source={require("../../../assets/images/menu.png")}
									/>
									<Text
										adjustsFontSizeToFit
										style={{
											fontWeight: "800",
											fontFamily: "Gotham",
											color: APP_COLOURS.BG2,
											fontSize: 13,
											marginLeft: 4,
										}}>
										tetrice
									</Text>
								</TouchableOpacity>
								<Text
									style={{
										color: APP_COLOURS.BG2,
										fontSize: 28,
										fontWeight: "800",
										fontFamily: "Gotham",
										// marginTop: 20,
										// marginTop: 6,
									}}>
									Growth tracker
								</Text>
							</View>
							<View
								style={
									{
										// position: "absolute",
										// right: 10,
										// top: 10,
									}
								}>
								{/* <Lottie
									options={{
										loop: true,
										autoplay: true,
										animationData: animationData,
										rendererSettings: {
											preserveAspectRatio:
												"xMidYMid slice",
										},
									}}
									height={150}
									width={200}
								/> */}
							</View>
						</View>
					</View>
				</View>
				{this.state.addgrowthData ? this.addgrowthData() : null}
			</View>
		);
	}

	addgrowthData = () => {
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<ScrollView showsVerticalScrollIndicator={false}>
						<View
							style={{
								width: "100%",
								maxWidth: 600,
								// height: this.props.screenHeight / 2,
								height: "auto",
								// maxHeight: screenHeight - this.state.keyboardOffset,
								// flex: 1,
								// backgroundColor: BG,
								backgroundColor: "#171A20",
								borderRadius: 10,
								padding: 10,
								// paddingTop: 50,
								marginTop: 50,
							}}>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
									// borderBottomWidth: 0.5,
									paddingBottom: 10,
									// borderBottomcolor: APP_COLOURS.BG2,
									marginBottom: 20,
								}}>
								<TouchableOpacity
									onPress={async () => {
										this.setState({
											addgrowthData: false,
										});
									}}>
									<Image
										style={{
											width: 24,
											height: 24,
											resizeMode: "contain",
											marginRight: 10,
										}}
										source={require("../../../assets/images/close.png")}
									/>
								</TouchableOpacity>
								<View style={{}}>
									<Text
										style={{
											color: APP_COLOURS.BG2,
											fontSize: 20,
											marginLeft: 10,
											fontWeight: "600",
											fontFamily: "Gotham",
										}}>
										Add Data
									</Text>
								</View>
							</View>
							<AddGrowthForm />
						</View>
					</ScrollView>
				</View>
			</Modal>
		);
	};
}
