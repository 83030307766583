import React, { Component } from "react";
import { View, Text, ScrollView, TouchableOpacity } from "react-native";
import { APP_COLOURS } from "../../APP_VARS";

export default class MediumTabs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: this.props.tabs[0],
		};
	}

	render() {
		return (
			<View style={{ marginBottom: 5 }}>
				<ScrollView showsHorizontalScrollIndicator={false} horizontal>
					<View
						style={{
							flexDirection: "row",
						}}>
						{this.props.tabs.map((d, i) => (
							<TouchableOpacity
								key={i}
								style={{}}
								onPress={() => {
									this.setState({
										selected: d,
									});
									this.props.selectedSubPage(d);
								}}>
								<View
									style={{
										width: "auto",
										//   height: 50,
										justifyContent: "center",
										alignItems: "center",
										// borderColor:
										// 	this.state.selected === d
										// 		? APP_COLOURS.BLUE
										// 		: APP_COLOURS.BG2,
										// borderWidth: 0.5,
										backgroundColor:
											this.state.selected === d
												? APP_COLOURS.BLUE
												: APP_COLOURS.WHITE_TRANSPARENT,
										padding: 20,
										borderRadius: 10,
										marginRight: 5,
										// paddingVertical: 25,
									}}>
									<Text
										style={{
											fontSize: 15,
											color:
												this.state.selected === d
													? "#FFF"
													: APP_COLOURS.BG2,
											fontWeight: "500",
										}}>
										{d}
									</Text>
								</View>
							</TouchableOpacity>
						))}
					</View>
				</ScrollView>
			</View>
		);
	}
}
