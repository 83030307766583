import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
	View,
	Text,
	Image,
	TouchableOpacity,
	TextInput,
	ScrollView,
	ActivityIndicator,
	Modal,
} from "react-native";
import Lottie from "react-lottie";
import moment from "moment";
import Fade from "react-reveal/Fade";

import Font from "react-font";

import { APP_COLOURS, StatusColour } from "../APP_VARS";
import { crudRead, crudUpdate } from "../CRUDHELPER";
import { Avatars } from "../APP_VARS";
import StatusModal from "./StatusModal";
import Schedule from "./Profile/Schedule";

function capitalizeName(name) {
	return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
}

class AutoHeightImage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<Image
				style={{
					width: this.props.width,
					height:
						typeof this.props.height !== "undefined"
							? this.props.height
							: this.props.width,
					resizeMode: "contain",
				}}
				source={this.props.source}
			/>
		);
	}
}

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			formFields: [
				{
					id: 1,
					editable: false,
					state: "username",
					title: "Email",
					autoCapitalize: false,
				},
				{
					id: 2,
					editable: true,
					state: "first_name",
					title: "First name",
					autoCapitalize: true,
				},
				{
					id: 3,
					editable: true,
					state: "last_name",
					title: "Last name",
					autoCapitalize: true,
				},
				{
					id: 4,
					editable: true,
					state: "discordChannel",
					title: "Discord Channel",
					autoCapitalize: true,
				},
				{
					id: 5,
					editable: true,
					state: "webhookUrl",
					title: "Discord Webhook Url",
					autoCapitalize: true,
				},
			],
			user_data: {
				id: "....",
				username: "...",
				first_name: "... ",
				last_name: "...",
				avatar: 0,
				discordChannel: "",
			},
			email: "",
			accessToken: "",
			refresh_token: "",
			screenWidth: 0,
			screenHeight: 0,
			authenticating: true,
			validated: true,

			showStatus: false,
		};
	}

	async componentDidMount() {
		await this.validateSession();
	}

	validateSession = async () => {
		let user_data = await localStorage.getItem("user_data");
		let email = await localStorage.getItem("email");
		let accessToken = await localStorage.getItem("accessToken");
		let refresh_token = await localStorage.getItem("refresh_token");

		await this.setState({
			user_data: JSON.parse(user_data),
			email,
			accessToken,
			refresh_token,
		});

		if (accessToken !== null && accessToken !== "") {
			setTimeout(() => {
				this.setState({
					loading: false,
					authenticating: false,
				});
			}, 1000);
		} else {
			window.location.href = "/";
			localStorage.clear();
		}
	};

	validateEmail = (text) => {
		// console.log(text);
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (reg.test(text) === false) {
			// console.log("Email is Not Correct");
			this.setState({ emailValid: false });
			return false;
		} else {
			this.setState({ emailValid: true });
			// console.log("Email is Correct");
			return true;
		}
	};

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	logOut = async () => {
		await this.props.logOut();
	};

	render() {
		return (
			<View
				style={{
					flex: 1,
					backgroundColor: APP_COLOURS.BG2,
					height: "100%",
					overflow: "hidden",
					alignItems: "center",
				}}>
				<View
					style={{
						flex: 1,
						width: "100%",
						maxWidth: 800,
					}}>
					<ScrollView showsVerticalScrollIndicator={false}>
						<View
							style={{
								width: "100%",
								// flexDirection: "row",
							}}>
							<View style={{ flex: 1 }}>
								<TouchableOpacity
									style={{
										width: "100%",
										height: "auto",
										padding: 7,
										alignItems: "center",
										borderRadius: 10,
										// minHeight: 70,
										// marginBottom: 10,
										flexDirection: "row",
									}}
									onPress={() => {}}>
									<Image
										source={
											Avatars[
												parseInt(
													this.state.user_data.avatar,
												)
											].pp
										}
										style={{
											width: 55,
											height: 55,
											borderRadius: 35,
											resizeMode: "contain",
											backgroundColor: "#FFF",
											borderColor: StatusColour(
												this.state.user_data.status,
											),
											// borderColor: "#C5C5C5",
											borderWidth: 2,
											// shadowColor: StatusColour(
											// 	this.state.user_data.status,
											// ),
											// shadowOffset: {
											// 	width: 0,
											// 	height: 8,
											// },
											// shadowOpacity: 0.44,
											// shadowRadius: 10.32,
											// elevation: 16,
										}}
									/>
									<View
										style={{
											// padding: 5,
											marginLeft: 5,
											borderRadius: 10,
											alignItems: "center",
											flexDirection: "column",
											flex: 1,
											// alignItems: 'flex-end',
											alignItems: "flex-start",
										}}>
										<Text
											style={{
												fontFamily: "Gotham",
												fontSize: 17,
												fontWeight: "600",
												color: APP_COLOURS.TEXTCOLOR,
											}}>
											{this.state.user_data.first_name}{" "}
											{this.state.user_data.last_name}
										</Text>
										<Text
											style={{
												fontFamily: "Gotham",
												fontSize: 13,
												opacity: 0.8,
												color: APP_COLOURS.TEXTCOLOR,
											}}>
											{this.state.user_data.username}
										</Text>
									</View>
								</TouchableOpacity>
							</View>

							<View
								style={{
									// position: "absolute",
									// bottom: 0,
									flex: 1,
									// maxWidth: 300,
									// justifyContent: "center",
									// alignItems: "center",
									// left: 0,
									padding: 20,
								}}>
								<Fade>
									<div
										style={{
											height: "100%",
											width: "100%",
											// maxWidth: 300,
											alignItems: "center",
											justifyContent: "center",
										}}>
										<TouchableOpacity
											onPress={() => this.logOut()}>
											<View
												style={{
													backgroundColor:
														APP_COLOURS.BLUE,
													flex: 1,
													padding: 9,
													justifyContent: "center",
													alignItems: "center",
													borderRadius: 10,
												}}>
												<Text
													style={{
														color: APP_COLOURS.WHITE,
														fontSize: 16,
													}}>
													Log out
												</Text>
											</View>
										</TouchableOpacity>
									</div>
								</Fade>
							</View>
						</View>

						<View
							style={{
								padding: 10,
								// maxWidth: 550,
							}}>
							<View
								style={{
									width: "100%",
									justifyContent: "center",
									alignItems: "center",
									left: 0,
									// padding: 20,
								}}>
								<Fade>
									<div
										style={{
											height: "100%",
											width: "100%",
											// maxWidth: 300,
											alignItems: "center",
											justifyContent: "center",
										}}>
										<TouchableOpacity
											onPress={() =>
												this.setState({
													showStatus: true,
												})
											}>
											<View
												style={{
													backgroundColor:
														APP_COLOURS.BLUE,
													flex: 1,
													padding: 9,
													justifyContent: "center",
													alignItems: "center",
													borderRadius: 10,
												}}>
												<Text
													style={{
														color: APP_COLOURS.WHITE,
														fontSize: 16,
													}}>
													Update status
												</Text>
											</View>
										</TouchableOpacity>
									</div>
								</Fade>
							</View>

							<View
								style={{
									width: "100%",
									height: "auto",
									backgroundColor: "#FFF",
									marginBottom: 5,
									borderRadius: 20,
									justifyContent: "center",
									alignItems: "center",
									shadowColor: "#999797",
									shadowOffset: {
										width: 0,
										height: 4,
									},
									shadowOpacity: 0.3,
									shadowRadius: 4.65,
									elevation: 8,
									paddingBottom: 20,
									marginTop: 30,
								}}>
								<View
									style={{
										width: "100%",
										padding: 20,
										height: "auto",
									}}>
									<Text
										allowFontScaling={false}
										style={{
											fontSize: 19,
											fontFamily: "Gotham",
											textAlign: "center",
											fontWeight: "700",
										}}>
										Profile picture
									</Text>
								</View>
								<View
									style={{
										width: "100%",
									}}>
									<ScrollView horizontal>
										<View
											style={{
												width: 20,
											}}
										/>
										{/* .sort((a, b) => a.avatar - b.avatar) */}
										{Avatars.map((d, i) => (
											<View key={i}>
												<TouchableOpacity
													style={{
														paddingBottom: 10,
													}}
													onPress={async () => {
														await this.setState({
															chooseProfilePic: false,
															avatar: i,
															changesMade: true,
															updates: {
																...this.state
																	.updates,
																avatar: i,
															},
														});
														let obj = {
															id: this.state
																.user_data.id,
															avatar: i,
														};
														console.log(obj);
														// return;
														await crudUpdate(
															"users",
															obj,
															(status, d) => {
																let user_data =
																	JSON.parse(
																		localStorage.getItem(
																			"user_data",
																		),
																	);

																user_data.avatar =
																	obj.avatar;

																localStorage.setItem(
																	"user_data",
																	JSON.stringify(
																		user_data,
																	),
																);

																window.location.href =
																	"/home/projects";

																// crudRead(
																// 	"users",
																// 	{
																// 		field: "autoID",
																// 		action: "==",
																// 		value: this.state
																// 			.user_data.id,
																// 	},
																// 	(s, d) => {
																// 		console.log(s, d);
																// 		let user_data =
																// 			localStorage.setItem(
																// 				"user_data",
																// 				JSON.stringify(
																// 					responseData,
																// 				),
																// 			);
																// 	},
																// );
															},
														);
													}}>
													<View
														style={{
															width: 55,
															height: 55,
															borderRadius: 27,
															borderWidth: 5,
															justifyContent:
																"center",
															alignItems:
																"center",
															borderColor:
																APP_COLOURS.WHITE_TRANSPARENT6,
															overflow: "hidden",
														}}>
														<AutoHeightImage
															width={45}
															source={d.pp}
															style={{
																resizeMode:
																	"cover",
															}}
															loadingIndicatorSource={
																<ActivityIndicator
																	color={
																		APP_COLOURS.PRIMARY
																	}
																/>
															}
														/>
													</View>
													<View
														style={{
															width: 55,
															height: 55,
															borderRadius: 27,
															borderWidth: 5,
															borderColor:
																APP_COLOURS.WHITE_TRANSPARENT6,
															backgroundColor:
																"transparent",
															position:
																"absolute",
															top: 0,
															left: 0,
															shadowColor:
																"#999797",
															shadowOffset: {
																width: 0,
																height: 4,
															},
															shadowOpacity: 0.3,
															shadowRadius: 4.65,
															elevation: 8,
														}}
													/>
												</TouchableOpacity>
											</View>
										))}
									</ScrollView>
								</View>
							</View>

							<Fade>
								<View
									style={{
										flex: 1,
										marginLeft: "auto",
										marginRight: "auto",
										flexDirection: "row",
										flexWrap: "wrap",
										alignItems: "center",
										justifyContent: "center",
									}}>
									<View
										style={{
											flex: 1,
											// maxWidth: 400,
											padding: 20,
											backgroundColor: "#FFF",
											borderRadius: 20,
										}}>
										<Text
											style={{
												fontFamily: "Gotham",
												fontSize: 16,
												color: APP_COLOURS.BG4,
												fontWeight: "500",
												marginBottom: 15,
											}}>
											User details
										</Text>

										{this.state.formFields.map((d, i) => (
											<View
												key={i}
												style={{
													width: "auto",
													marginBottom: 16,
													flexDirection: "row",
													alignItems: "center",
												}}>
												<Text
													style={{
														opacity: this.state
															.unEditable
															? 0.4
															: 1,
														fontFamily: "Gotham",
														fontSize: 12,
														color: APP_COLOURS.PLACEHOLDER,
														fontWeight: "500",
														marginBottom: 4,
														position: "absolute",
														top: -12,
														left: 9,
														backgroundColor: "#FFF",
														padding: 4,
														borderRadius: 8,
													}}>
													{d.title}
												</Text>
												<TextInput
													style={{
														width: "100%",
														opacity: this.state
															.unEditable
															? 0.4
															: 1,
														borderWidth: 0.5,
														borderColor: "#000",
														padding: 12,
														paddingLeft: 10,
														fontSize: 16,
														borderRadius: 8,
														backgroundColor:
															APP_COLOURS.WHITE,
														color: "#000",
														fontWeight: "200",
														fontFamily: "Gotham",
													}}
													placeholderTextColor={
														"#000"
													}
													autoCapitalize='words'
													clearButtonMode='while-editing'
													autoCompleteType='email'
													editable={d.editable}
													onFocus={() => {
														this.setState({
															unEditable:
																!d.editable,
														});
													}}
													onBlur={() => {
														this.setState({
															unEditable: false,
														});
													}}
													autoCorrect={false}
													keyboardType='email-address'
													textContentType='emailAddress'
													placeholder={d.title}
													// defaultValue={this.state.user_data.email}
													value={
														this.state.user_data[
															d.state
														]
													}
													onChangeText={(text) => {
														// let validated = this.validateEmail(text);

														if (d.autoCapitalize) {
															text =
																capitalizeName(
																	text,
																);
														}

														this.setState(
															(prevState) => ({
																user_data: {
																	...prevState.user_data,
																	[d.state]:
																		text,
																},
															}),
														);
													}}
												/>

												{this.state.unEditable &&
												!d.editable ? (
													<View
														style={{
															position:
																"absolute",
															width: "100%",
															height: "100%",
															backgroundColor: `rgba(52, 52, 52, 0.3)`,
															flex: 1,
															justifyContent:
																"center",
															alignItems:
																"center",
															borderRadius: 8,
														}}>
														<Text
															style={{
																fontFamily:
																	"Gotham",
																fontSize: 12,
																color: APP_COLOURS.WHITE,
																fontWeight:
																	"700",
															}}>
															Uneditable field
														</Text>
													</View>
												) : null}
											</View>
										))}
									</View>
								</View>
							</Fade>

							<Fade>
								<View
									style={{
										flex: 1,
										marginLeft: "auto",
										marginRight: "auto",
										flexDirection: "row",
										flexWrap: "wrap",
										alignItems: "center",
										justifyContent: "center",
										marginBottom: 5,
										borderRadius: 20,
										justifyContent: "center",
										alignItems: "center",
										marginTop: 5,
									}}>
									<View
										style={{
											flex: 1,
											// maxWidth: 400,
											padding: 20,
											backgroundColor: "#FFF",
											borderRadius: 20,
										}}>
										<Text
											style={{
												fontFamily: "Gotham",
												fontSize: 16,
												color: APP_COLOURS.BG4,
												fontWeight: "500",
												marginBottom: 15,
											}}>
											Work Schedule
										</Text>

										<View
											style={{
												zIndex: 10,
												marginTop: 10,
												marginBottom: 10,
												shadowColor: APP_COLOURS.BLUE2,
												backgroundColor: "#312F38",
												shadowOffset: {
													width: 0,
													height: 4,
												},
												shadowOpacity: 0.13,
												shadowRadius: 4.65,
												elevation: 4,
												borderRadius: 10,
												overflow: "hidden",
											}}>
											{this.state.showSchedule ? (
												<Modal
													animationType='fade'
													transparent={true}
													visible={
														this.state.showSchedule
													}>
													<View
														style={{
															flex: 1,
															justifyContent:
																"flex-start",
															alignItems:
																"center",
															backgroundColor:
																APP_COLOURS.BG,
														}}>
														<View
															style={{
																flexDirection:
																	"row",
																alignItems:
																	"center",
																width: "100%",
																maxWidth: 600,
															}}>
															<TouchableOpacity
																onPress={() =>
																	this.setState(
																		{
																			showSchedule:
																				!this
																					.state
																					.showSchedule,
																		},
																	)
																}
																style={{
																	// flex: 1,
																	flexDirection:
																		"row",
																	alignItems:
																		"center",
																	marginBottom: 10,
																	padding: 15,
																	width: "100%",
																	maxWidth: 150,
																}}>
																<View
																	style={{
																		width: "100%",
																		borderRadius: 10,
																		backgroundColor:
																			APP_COLOURS.BLUE,
																		padding: 8,
																		flexDirection:
																			"row",
																		justifyContent:
																			"center",
																		alignItems:
																			"center",
																	}}>
																	<Text
																		allowFontScaling={
																			false
																		}
																		style={{
																			fontWeight:
																				"400",
																			fontSize: 15,
																			color: "#FFF",
																		}}>
																		Done
																	</Text>
																</View>
															</TouchableOpacity>
															<Text
																allowFontScaling={
																	false
																}
																style={{
																	fontSize: 27,
																	fontFamily:
																		"Gotham",
																	textAlign:
																		"center",
																	fontWeight:
																		"700",
																	color: "#FFF",
																}}>
																Work Schedule
															</Text>
														</View>

														<View
															style={{
																flex: 1,
															}}>
															<Schedule
																{...this.state}
															/>
														</View>
													</View>
												</Modal>
											) : (
												<TouchableOpacity
													onPress={() =>
														this.setState({
															showSchedule:
																!this.state
																	.showSchedule,
														})
													}
													style={{
														width: "100%",
														flexDirection: "row",
														alignItems: "center",
													}}>
													<View
														style={{
															flex: 1,
															borderTopWidth: 0.5,
															borderTopColor:
																"#3A3744",
															// backgroundColor: "#312F38",
															backgroundColor:
																APP_COLOURS.BG3,
															padding: 8,
															flexDirection:
																"row",
															// justifyContent: 'space-between',
															alignItems:
																"center",
														}}>
														<View
															style={{
																marginRight: 10,
															}}>
															<AutoHeightImage
																width={25}
																source={require("../../assets/images/calendar.png")}
															/>
														</View>
														<Text
															allowFontScaling={
																false
															}
															style={{
																fontWeight:
																	"400",
																fontSize: 15,
																color: "#FFF",
															}}>
															Schedule
														</Text>
													</View>
												</TouchableOpacity>
											)}
										</View>
									</View>
								</View>
							</Fade>
						</View>

						{this.state.showStatus ? (
							<StatusModal
								screenWidth={window.innerWidth}
								screenHeight={window.innerHeight}
								user_data={this.state.user_data}
								accepted={(b, s) => {
									this.setState({
										statusUpdated: b,
										status: s,
										currentModal: "",
										showStatus: false,
									});
									// this.onLoadProcess();
								}}
								avatarComp={null}
							/>
						) : null}
					</ScrollView>
				</View>
			</View>
		);
	}
}

export default index;
