import React, { Component } from "react";
import { View, Text, Image } from "react-native";
import { APP_COLOURS, Avatars } from "../APP_VARS";
import { crudGetUsers } from "../CRUDHELPER";

export default class GetUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			users: [],
		};
	}

	componentDidMount() {
		this.getUsers();
	}

	getUsers = () => {
		crudGetUsers((d) => {
			if (d.length > 0) {
				this.setState({
					users: d,
				});
			} else {
				alert("Fetching users error");
			}
		});
	};

	render() {
		let size = this.props.size;

		let user = this.state.users.filter((d) => d.id === this.props.user_id);
		return (
			<View style={{}}>
				{user.map((user, i) => (
					<View
						key={i}
						style={{
							alignItems: "center",
							justifyContent: "center",
							// flexDirection: "row",
							// marginTop: 7,
							// marginLeft: 4,
							// opacity: 0.7,
							// width: size * 1.1,
							overflow: "hidden",
							marginRight: 2,
						}}>
						<View
							style={{
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: "#FFF",
								borderWidth: 1,
								borderColor: "#FFF",
								width: size * 1.1,
								height: size * 1.1,
								borderRadius: size / 2,
								overflow: "hidden",
								// shadowColor: "#6494B7",
								// shadowOffset: {
								// 	width: 0,
								// 	height: 4,
								// },
								// shadowOpacity: 0.23,
								// shadowRadius: 4.65,
								// elevation: 8,
								// marginRight: 4,
							}}>
							{typeof user.avatar !== "undefined" ? (
								<Image
									// source={avatars[parseInt(ass.avatar)]}
									source={Avatars[parseInt(user.avatar)].pp}
									style={[
										{
											width: size * 1.3,
											height: size * 1.3,
											borderRadius: (size * 1.3) / 2,
											resizeMode: "contain",
											backgroundcolor: APP_COLOURS.BG2,
										},
									]}
								/>
							) : (
								<Image
									// source={avatars[parseInt(ass.avatar)]}
									source={Avatars[1].pp}
									style={{
										width: size * 1.3,
										height: size * 1.3,
										borderRadius: (size * 1.3) / 2,
										resizeMode: "contain",
										backgroundcolor: APP_COLOURS.BG2,
									}}
								/>
							)}
						</View>
						<View>
							{/* <Text
								style={{
									color: APP_COLOURS.BG2,
									fontSize: 9,
									fontWeight: "600",
								}}>
								SKrum Master
							</Text> */}
							<Text
								ellipsizeMode='tail'
								numberOfLines={1}
								style={{
									color: APP_COLOURS.BG2,
									fontSize: size * 0.35,
								}}>
								{user.first_name}
							</Text>
						</View>
					</View>
				))}
			</View>
		);
	}
}
