import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
} from "react-native";
import { APP_COLOURS, Avatars } from "../../APP_VARS";
import PresentationApp from "./index";
import { getPresentations } from "../../CRUDHELPER";
import AutoHeightImage from "../AutoHeightImageComp";
import * as animationData from "../../../assets/lottie/team.json";
import AddPresentation from "./AddPresentations";
import StatusItem from "../DailyPlanner/StatusItem";

export default class AllPresentations extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedPresentation: "",
			addPresentation: false,

			presentation: [],
			hover: true,
			loading: true,
		};
	}

	componentDidMount() {
		this.getAllPresentations();
	}

	getAllPresentations = async () => {
		this.setState({
			loading: true,
			presentation: [],
		});
		if (this.props.user_data.id !== "") {
			await getPresentations(
				{
					field: "assignment_ids",
					action: "array-contains",
					value: this.props.user_data.id,
				},
				async (status, d) => {
					console.log("presentation", status, d);
					if (status === 200) {
						if (typeof d.data !== "undefined") {
							await this.setState({
								presentation: d.data,
								loading: false,
							});

							console.log(d.data);
						} else {
							await this.setState({
								presentation: [],
								loading: false,
							});
						}

						// localStorage.setItem("presentation", JSON.stringify(d));
					} else {
						await this.setState({
							presentation: [],
							loading: false,
						});
					}
				},
			);
		} else {
			alert("User error");
		}
	};

	componentDidUpdate(prevProps) {
		if (prevProps.user_data !== this.props.user_data) {
			this.getAllPresentations();
		}
	}

	render() {
		return (
			<View
				style={{
					flex: 1,
					flexDirection: "row",
				}}>
				<View
					// onMouseEnter={() => this.setState({ hover: true })}
					// onMouseLeave={() => this.setState({ hover: false })}
					style={{
						// flex: 1,
						width: this.state.hover ? 350 : 50,
						backgroundColor: APP_COLOURS.BG2,
						padding: 5,
						// position: "absolute",
						// left: 0,
						zIndex: 99,
						height: "100%",
					}}>
					<View
						style={{
							flex: 1,
							paddingBottom: 60,
							// backgroundColor: "#FFF",
							padding: 5,
							borderRadius: 10,
							// alignItems: "center",
						}}>
						<View
							style={{
								flexDirection: "row",
								justifyContent: "space-between",
								alignItems: "center",
							}}>
							<View
								style={{
									padding: 10,
								}}>
								<TouchableOpacity
									style={{
										flexDirection: "row",
										alignItems: "center",
										// position: "absolute",
									}}
									onPress={() => {
										// this.props.close();
									}}>
									{/* <AutoHeightImage
										width={90}
										source={require("../../../assets/images/logoTetrice.png")}
									/> */}
									<Text
										adjustsFontSizeToFit
										style={{
											fontWeight: "800",
											fontFamily: "Gotham",
											color: APP_COLOURS.DESELECTEDCOLOR,
											fontSize: 13,
											marginLeft: 4,
										}}>
										tetrice
									</Text>
								</TouchableOpacity>
								<Text
									style={{
										color: APP_COLOURS.DESELECTEDCOLOR,
										fontSize: 28,
										fontWeight: "800",
										fontFamily: "Gotham",
										// marginTop: 20,
										// marginTop: 6,
									}}>
									Presentations
								</Text>

								{/* <Text
									style={{
										fontSize: 16,
										color: APP_COLOURS.DESELECTEDCOLOR,
										fontWeight: "300",
										padding: 2,
										marginBottom: 20,
									}}>
									Presentations
								</Text> */}
							</View>
							<View
								style={
									{
										// position: "absolute",
										// right: 10,
										// top: 10,
									}
								}>
								{/* <Lottie
									options={{
										loop: true,
										autoplay: true,
										animationData: animationData,
										rendererSettings: {
											preserveAspectRatio:
												"xMidYMid slice",
										},
									}}
									height={150}
									width={200}
								/> */}
							</View>
						</View>

						<View
							style={{
								// flex: 1,
								width: "100%",
								flexDirection: "row",
								alignItems: "center",
								height: 45,
								paddingLeft: 5,
								// display: this.state.hover ? "flex" : "none",
							}}>
							<View
								style={{
									// height: "auto",
									// width: "100%",
									flex: 1,
									minWidth: 100,
									// maxWidth: 400,
									backgroundColor: APP_COLOURS.BLACK_TRANS,
									borderRadius: 13,
								}}>
								{this.state.showInput ? (
									<TextInput
										style={{
											fontWeight: "400",
											fontFamily: "Gotham",

											width: "100%",
											paddingLeft: 40,
											padding: 10,
											fontSize: 15,
											borderRadius: 13,
											marginRight: 15,
											color: APP_COLOURS.BG2,
											// backgroundColor:
											// 	APP_COLOURS.WHITE_TRANSPARENT4,
											backgroundColor: "#E8E8E8",
											fontWeight: "500",
										}}
										placeholderTextColor={"#797979"}
										autoCapitalize='sentences'
										clearButtonMode='while-editing'
										autoCompleteType='off'
										editable={true}
										autoCorrect={true}
										autoFocus
										keyboardType='default'
										textContentType='none'
										placeholder={` Search `}
										// defaultValue={this.state.user_data.email}
										defaultValue={this.state.filterSearch}
										onChangeText={(text) => {
											console.log(text);
											this.setState({
												filterSearch: text,
											});
										}}
									/>
								) : null}
							</View>
							<TouchableOpacity
								style={{
									marginRight: 10,
									marginLeft: 10,
									position: "absolute",
									left: 10,
								}}
								onPress={() =>
									this.setState({
										showInput: !this.state.showInput,
									})
								}>
								<AutoHeightImage
									width={19}
									source={require("../../../assets/images/searchIcon.png")}
								/>
							</TouchableOpacity>
							{this.state.loading ? (
								<ActivityIndicator
									color={APP_COLOURS.PRIMARY}
								/>
							) : null}

							<TouchableOpacity
								style={{
									// marginRight: 15,
									marginLeft: 15,
									// backgroundColor: APP_COLOURS.BG2,
									borderRadius: 15,
									flexDirection: "row",
									paddingVertical: 10,
									alignItems: "center",
									paddingHorizontal: 15,
									borderWidth: 0.5,
									borderColor: APP_COLOURS.DESELECTEDCOLOR,
								}}
								onPress={async () => {
									this.setState({
										addPresentation:
											!this.state.addPresentation,
									});
								}}>
								<Text
									style={{
										color: APP_COLOURS.DESELECTEDCOLOR, // "#FFF",
										fontSize: 14,
										marginRight: 10,
										fontWeight: "600",
									}}>
									+
								</Text>
								<Text
									style={{
										color: APP_COLOURS.DESELECTEDCOLOR, // "#FFF",
										fontSize: 12,
										fontWeight: "600",
									}}>
									New
								</Text>
							</TouchableOpacity>
							<TouchableOpacity
								style={{
									// marginRight: 15,
									marginLeft: 15,
									// backgroundColor: APP_COLOURS.BG2,
									borderRadius: 15,
									flexDirection: "row",
									paddingVertical: 10,
									alignItems: "center",
									paddingHorizontal: 15,
									borderWidth: 0.5,
									borderColor: APP_COLOURS.DESELECTEDCOLOR,
								}}
								onPress={async () => {
									this.getAllPresentations();
								}}>
								<Text
									style={{
										color: APP_COLOURS.DESELECTEDCOLOR, // "#FFF",
										fontSize: 12,
										fontWeight: "600",
									}}>
									Reload
								</Text>
							</TouchableOpacity>
						</View>

						<View
							style={{
								flex: 1,
								// display: this.state.hover ? "flex" : "none",
							}}>
							<ScrollView showsVerticalScrollIndicator={false}>
								<Text
									style={{
										fontSize: 16,
										color: APP_COLOURS.DESELECTEDCOLOR,
										fontWeight: "900",
										padding: 2,
										marginTop: 20,
									}}>
									Presentations
								</Text>
								{this.state.loading ? (
									<ActivityIndicator color={"#FFF"} />
								) : null}
								{this.state.presentation
									.filter((d_) => {
										let filter = d_;

										if (
											typeof this.state.filterSearch !==
												"undefined" &&
											this.state.filterSearch !== ""
										) {
											let text_ = this.state.filterSearch;
											var outString = text_.replace(
												/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
												"",
											);

											let join_names = `${d_.title} ${d_.title2} ${d_.description}`;

											join_names = join_names.replace(
												/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
												"",
											);

											return join_names
												.toLowerCase()
												.includes(
													outString.toLowerCase(),
												);
										}

										return filter;
									})
									.map((d, i) => (
										<View
											key={i}
											style={{
												alignItems: "center",
												justifyContent: "center",
												width: "100%",
											}}>
											<TouchableOpacity
												style={{
													width: "100%",
													height: "auto",
													padding: 7,
													alignItems: "center",
													justifyContent: "center",
													borderRadius: 10,
													minHeight: 45,
													marginBottom: 10,
													flexDirection: "row",
													backgroundColor:
														this.state
															.selectedPresentation
															?._id === d._id
															? "rgba(13, 109, 251, 0.7)"
															: APP_COLOURS.WHITE_TRANSPARENT4,
												}}
												onPress={async () => {
													await this.setState({
														selectedPresentation:
															"",
													});
													await this.setState({
														selectedPresentation: d,
													});
													// window.location.href = `/home/${d.page}`;
												}}>
												<View
													style={{
														flex: 1,
														padding: 5,
														// display: this.state.hover
														// 	? "flex"
														// 	: "none",
														marginLeft: 9,
													}}>
													<View
														style={{
															flexDirection:
																"row",
															justifyContent:
																"space-between",
														}}>
														<Text
															style={{
																fontFamily:
																	"Avenir",
																fontSize: 16,
																color: APP_COLOURS.DESELECTEDCOLOR,
																fontWeight:
																	"800",
															}}>
															{d.title}
														</Text>
														<StatusItem
															{...d}
															fs={14}
															handleStatusChange={async (
																_id,
																_status,
															) => {}}
														/>
													</View>

													<View
														style={{
															flexDirection:
																"row",
															justifyContent:
																"space-between",
														}}>
														<View>
															<Text
																style={{
																	fontFamily:
																		"Avenir",
																	fontSize: 11,
																	color: APP_COLOURS.DESELECTEDCOLOR,
																	fontWeight:
																		"300",
																}}>
																{d.title2}
															</Text>
															{/* <Text
																style={{
																	fontFamily:
																		"Avenir",
																	fontSize: 11,
																	color: APP_COLOURS.DESELECTEDCOLOR,
																	fontWeight:
																		"300",
																}}>
																{d.description}
															</Text> */}
														</View>

														<View
															style={{
																// width: 30,
																// height: 30,
																alignItems:
																	"center",
																justifyContent:
																	"center",
																flexDirection:
																	"row",
															}}>
															{typeof d.createdBy
																.avatar !==
															"undefined" ? (
																<Image
																	source={
																		Avatars[
																			parseInt(
																				d
																					.createdBy
																					.avatar,
																			)
																		].pp
																	}
																	style={{
																		width: 25,
																		height: 25,
																		borderRadius: 25,
																		resizeMode:
																			"contain",
																		backgroundColor:
																			"#FFF",
																		marginRight: 5,
																	}}
																/>
															) : (
																<Image
																	source={
																		Avatars[1]
																			.pp
																	}
																	style={{
																		width: 25,
																		height: 25,
																		borderRadius: 25,
																		resizeMode:
																			"contain",
																		backgroundColor:
																			"#FFF",
																		marginRight: 5,
																	}}
																/>
															)}
															<View style={{}}>
																<Text
																	style={{
																		color: "#FFF",
																		fontWeight:
																			"300",
																		fontSize: 7,
																	}}>
																	created by
																</Text>
																<Text
																	style={{
																		color: "#FFF",
																		fontWeight:
																			"800",
																		fontSize: 10,
																	}}>
																	{
																		d
																			.createdBy
																			.fullName
																	}
																</Text>
															</View>
														</View>
													</View>
													<Text
														style={{
															fontFamily:
																"Avenir",
															fontSize: 9,
															color: APP_COLOURS.DESELECTEDCOLOR,
															fontWeight: "300",
															display:
																this.state
																	.selectedPresentation
																	?._id ===
																d._id
																	? "flex"
																	: "none",
														}}>
														{d._id}
													</Text>
												</View>
											</TouchableOpacity>
										</View>
									))}
							</ScrollView>
						</View>
					</View>
				</View>

				<View
					style={{
						flex: 1,
					}}>
					{this.state.selectedPresentation !== "" ? (
						<PresentationApp
							{...this.state}
							{...this.props}
							presentationData={this.state.selectedPresentation}
							reloadData={() => {
								this.getAllPresentations();
							}}
						/>
					) : (
						<View />
					)}

					{this.state.addPresentation ? (
						<AddPresentation
							{...this.state}
							{...this.props}
							reloadData={() => {
								this.getAllPresentations();
							}}
							close={() => {
								this.setState({
									addPresentation: false,
								});
							}}
						/>
					) : null}
				</View>
			</View>
		);
	}
}
