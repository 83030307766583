import React, { Component } from "react";
import { View, Image, Text, StyleSheet } from "react-native";
import { APP_COLOURS } from "../APP_VARS";
import { Fade } from "react-reveal";

let fontS = {
	fontSize: 15,
	color: APP_COLOURS.BG2,
	fontFamily: "Gotham",
	textAlign: "justify",
	fontWeight: "300",
};

const members = [
	{
		name: "Selina de Freitas",
		image: require("../../assets/images/pp1.png"),
		position: "Insights & Analytics Director",
		experience: `Insights & Analytics Director (Experience Management). 
IQBusiness Insights,
Masters in Community Counselling Psychology
BA Honours (Psychology)
Public Relations 
		`,
	},
	{
		name: "Leight-Ann Mol",
		image: require("../../assets/images/pp3.png"),
		position: "Senior Digital Marketing & Communications Manager",
		experience: `Digital Marketing & Social Media Manager, Content Specialist
5FM, MetroFM, Good Hope FM and SAfm
CliffCentral.com
Nala Media		
SABC (South African Broadcasting Corporation)
		
		`,
	},

	{
		name: "Wouter Nigrini",
		image: require("../../assets/images/pp3.png"),
		position: "CTO & Program manager",
		experience: `Blue Bean Software
Signatory Labs (pty) ltd
		
		
		`,
	},
	{
		name: "Wesley Cheia",
		image: require("../../assets/images/pp3.png"),
		position: "Account Director & Product Owner",
		experience: `Director of tetrice (pty) ltd 
Director of Westonry (pty) ltd
Director of Parkaid SA (pty) ltd
Director of ettso (pty) ltd
Partner at Grandett Investments (pty) ltd
Partner at MEDii cc
Partner at Surety App (pty) ltd,
Partner at Signatory Labs (pty) ltd,
Director of Westonry LLC/Inc`,
	},
	{
		name: "Kirana Singh",
		image: require("../../assets/images/pp2.png"),
		position: "Senior account director & Head of Business Development",
		experience: `Genex Insights / IQ Business, ZA
Nielsen, ZA
Millward Brown, ZA
Oceana Group, ZA	
BCom Law, UNISA`,
	},
	{
		name: "Aleshen Maistry",
		image: require("../../assets/images/pp3.png"),
		position: "Solutions Architect & Tech lead",
		experience: `FNB IT
Britehouse
2Cana Solution
BSc Computer Science & Information Technology
Bachelor of Science Honours in Computer Science
MSc Computer Science (Machine Learning)
PHd Computer Science Candidate`,
	},

	{
		name: "Natasha Lalika",
		image: require("../../assets/images/pp3.png"),
		position: "Creative Lead",
		experience: `Open window insitute
		
		
		`,
	},
	{
		name: "Llewellyn Absalom",
		image: require("../../assets/images/pp3.png"),
		position: "Business Development",
		experience: `Marketing Consultant
Provantage Media Group (PMG)
Thomson Reuters
Bachelor of Commerce (Marketing Mngmt)

		`,
	},
	{
		name: "Luvyuo Pangwa",
		image: require("../../assets/images/pp3.png"),
		position: "Business Development",
		experience: `BBA honours Degree in Marketing Management

		`,
	},

	{
		name: "Vincent Maponge",
		image: require("../../assets/images/pp3.png"),
		position: "Senior Developer (Full stack)",
		experience: `Nectarsoft
Microsoft Azure fundamentals
Diploma in System Development Richfield Graduate Institute
		
		
		
		`,
	},
	{
		name: "Johann Geldenhys",
		image: require("../../assets/images/pp3.png"),
		position: "Designer & Animantion",
		experience: `Open window institue
		
		
		`,
	},
	{
		name: "Jayden Purkiss",
		image: require("../../assets/images/pp3.png"),
		position: "Executive PA",
		experience: `
		
		
		`,
	},

	{
		name: "Tyron Brett",
		image: require("../../assets/images/pp3.png"),
		position: "Tech support",
		experience: `Hyundai (National After sales manager) South Africa
		
		
		`,
	},
	{
		name: "Sean Lees",
		image: require("../../assets/images/pp3.png"),
		position: "Digital assets manager",
		experience: `Hyundai Online Sales
		
		
		`,
	},
	{
		name: "Deidre Engelbrecht",
		image: require("../../assets/images/pp3.png"),
		position: "Group administration",
		experience: `Ice models
Westonry SA (pty) ltd
		
		
		`,
	},
	// {
	// 	name: "Denzyl Pratt Lees",
	// 	image: require("../../assets/images/pp3.png"),
	// 	position: "Workshop manager",
	// 	experience: `

	// 	`,
	// },
];

class TeamTetrice extends Component {
	renderRole(role) {
		return (
			<Fade>
				<View
					style={{
						// width: "100%",
						// flexDirection: "row",
						// justifyContent: "space-between",
						width: 300,
					}}>
					<View
						style={{
							flexDirection: "row",
						}}>
						<Image
							source={
								require("../../assets/images/proudly.svg")
									.default
							}
							style={{
								width: 25,
								height: 25,
								resizeMode: "contain",
								marginRight: 10,
							}}
						/>
						<View
							style={{
								width: 250,
								marginBottom: 10,
								marginHorizontal: 10,
								...fontS,
							}}>
							<Text
								style={{
									...fontS,
									fontWeight: "800",
								}}>
								{role.name}
							</Text>
							<Text
								style={{
									...fontS,
									fontWeight: "500",
									opacity: 0.8,
								}}>
								{role.position}
							</Text>

							<View
								style={{
									marginTop: 10,
								}}>
								<Text
									style={{
										...fontS,
										fontWeight: "200",
										opacity: 0.7,
										fontStyle: "italic",
									}}>
									{role.experience}
								</Text>
							</View>
						</View>
					</View>
				</View>
			</Fade>
		);
	}

	render() {
		return (
			<View style={styles.container}>
				{/* <View
					style={{
						width: "100%",
						borderBottomWidth: 0.5,
						borderColor: "#141D31",
						marginBottom: 25,
					}}
				/> */}
				<View
					style={{
						maxWidth: 1200,
						width: "90%",
					}}>
					<Image
						source={
							require("../../assets/images/tetrice_proudly.svg")
								.default
						}
						style={{
							width: 300,
							height: 200,
							resizeMode: "contain",
							alignSelf: "center",
						}}
					/>

					<Text
						style={{
							fontSize: 17,
							color: APP_COLOURS.TEXTCOLOR,
							marginBottom: 25,
							flexDirection: "row",
							...fontS,
						}}>
						At tetrice, we boast an incredibly talented team of
						South African Designers, Marketers, Researchers,
						engineers, software developers, and data analysts, who
						bring a youthful energy and passion to the world of
						Digital Communications. With the majority of our team
						members under 35 years old, our agility and adaptability
						allow us to quickly embrace and implement emerging
						technologies, setting us apart from larger, slower
						organizations.
					</Text>
					<Text
						style={{
							fontSize: 17,
							color: APP_COLOURS.TEXTCOLOR,
							marginBottom: 25,
							flexDirection: "row",
							...fontS,
						}}>
						Our diverse team of professionals are responsible for
						various aspects of each project, ensuring that every
						deliverable is meticulously crafted and expertly
						managed. In the descriptions that follow, we provide a
						snapshot of the roles, responsibilities, and unique
						skills of our esteemed team members.
					</Text>
					<Text
						style={{
							fontSize: 17,
							color: APP_COLOURS.TEXTCOLOR,
							marginBottom: 25,
							flexDirection: "row",
							...fontS,
						}}>
						At tetrice, we don't chase perfect résumés; instead, we
						hunt for undeniable talent and exceptional capabilities.
						For us, it's less about the specifics of your expertise,
						and more about the depth and breadth of your knowledge.
					</Text>

					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
						}}>
						<Image
							source={
								require("../../assets/images/team_tetrice.svg")
									.default
							}
							style={{
								width: 190,
								height: 50,
								resizeMode: "contain",
								alignSelf: "flex-start",
							}}
						/>
						<View
							style={{
								flexDirection: "row",
								alignItems: "center",
							}}>
							<Image
								source={require("../../assets/images/pp3.png")}
								style={{
									width: 35,
									height: 35,
									resizeMode: "contain",
								}}
							/>
							<Image
								source={require("../../assets/images/pp2.png")}
								style={{
									width: 35,
									height: 35,
									resizeMode: "contain",
									left: -15,
								}}
							/>
							<Image
								source={require("../../assets/images/pp1.png")}
								style={{
									width: 35,
									height: 35,
									resizeMode: "contain",
									left: -30,
								}}
							/>
						</View>
					</View>
					<View
						style={{
							width: "100%",
							borderBottomWidth: 0.5,
							borderColor: "#141D31",
							marginBottom: 25,
						}}
					/>
					<View
						style={{
							flexDirection: "row",
							flexWrap: "wrap",
							width: "100%",

							paddingTop: 0,
						}}>
						{members.map((role, i) => (
							<View
								key={i}
								style={{
									marginBottom: 15,
								}}>
								{this.renderRole(role)}
							</View>
						))}
					</View>
				</View>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		// padding: 20,
		// maxWidth: 1200,
		width: "100%",
		backgroundColor: "#FFF",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 5,
	},
	roleContainer: {},
	roleName: {},
	membersContainer: {
		marginLeft: 20,
	},
	memberContainer: {
		flexDirection: "row",
		alignItems: "center",
		marginBottom: 10,
	},
	memberImage: {
		width: 50,
		height: 50,
		borderRadius: 25,
		marginRight: 10,
	},
});

export default TeamTetrice;
