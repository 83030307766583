import React, { Component } from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import CountDownTimer from "./CountdownTimer";
import { APP_COLOURS, Avatars } from "../../APP_VARS";
import { crudReadUnass } from "../../CRUDHELPER";
import moment from "moment";

export default class NoticeBoardStream extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,

			crudDocument: "noticeBoard",
		};
	}

	render() {
		return (
			<View>
				<View style={{ width: "100%", padding: 5 }}>
					<View
						style={{
							marginVertical: 20,
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "flex-end",
						}}>
						<Text
							style={{
								fontFamily: "Gotham",
								fontSize: 15,
								color: APP_COLOURS.TEXTCOLOR,
								fontWeight: "300",
								opacity: 0.5,
								paddingLeft: 20,
							}}>
							Notice board
						</Text>

						{this.state.loading ? null : (
							<CountDownTimer
								duration={20}
								color={APP_COLOURS.BLUE2}
								onCountdownFinish={() => {
									this.fetchData();
									console.log("Time trigger");
								}}
							/>
						)}
					</View>

					{[
						{
							id: "1234",
							title: "Mandatory Policy Change",
							dateCreated: "04/01/2024",
							text: `
										
`,
							image: "https://cdn.discordapp.com/attachments/1107650389060374551/1192524811168137296/kalel0568_social_media_and_content_creation_team_illustration_p_cd6d0998-5462-4722-aea7-80e8e135afd2.png?ex=65a96447&is=6596ef47&hm=d027ca70d49ec5b3ecf005626261af3cdbf9d372496ac7bbbc1a3b79384d4a03&",
							writtenBy: {
								first_name: "Wesley",
								last_name: "Cheia",
								avatar: 4,
							},
							bgColor: "#090D13",
							mode: "Dark",
						},
					].map((d) => (
						<TouchableOpacity
							key={d.id}
							style={{
								width: "100%",
								borderRadius: 20,
								marginBottom: 10,
								// flexDirection: "row",
								backgroundColor: d.bgColor,
								overflow: "hidden",
								// minHeight: 170,
								height: 160,
							}}
							onPress={() => {
								this.setState({
									showWhitePaper: d,
								});
							}}>
							<View
								style={{
									flex: 1,
									// padding: 7,
									alignItems: "center",
									justifyContent: "center",
								}}>
								<View
									style={{
										position: "absolute",
										right: 20,
										opacity: 0.3,
									}}>
									<Image
										source={require("./WhitePapers/skrum_menu.png")}
										style={{
											width: 80,
											height: 80,
											resizeMode: "cover",
										}}
									/>
								</View>
								<View
									style={{
										flex: 1,
										width: "100%",
										padding: 7,
									}}>
									<View
										style={{
											width: "100%",
											height: "auto",
											padding: 7,
											alignItems: "center",
											borderRadius: 10,
											// minHeight: 70,
											// marginBottom: 10,
											flexDirection: "row",
											borderColor: "#C5C5C5",
											opacity: 0.6,
											marginBottom: 10,
										}}>
										<Image
											source={
												Avatars[
													parseInt(d.writtenBy.avatar)
												].pp
											}
											style={{
												width: 30,
												height: 30,
												borderRadius: 25,
												resizeMode: "contain",
												backgroundColor: "#FFF",
												borderWidth: 0.3,
												borderColor: "#C5C5C5",
												shadowColor: "#ECF4FF",
												shadowOffset: {
													width: 0,
													height: 8,
												},
												shadowOpacity: 0.44,
												shadowRadius: 10.32,

												elevation: 16,
											}}
										/>
										<View
											style={{
												padding: 5,
												borderRadius: 10,
												alignItems: "center",
												flexDirection: "column",
												flex: 1,
												alignItems: "flex-start",
											}}>
											<Text
												style={{
													fontFamily: "Gotham",
													fontSize: 12,
													fontWeight: "600",
													color: APP_COLOURS.WHITE,
												}}>
												{d.writtenBy.first_name}{" "}
												{d.writtenBy.last_name}
											</Text>
										</View>

										<Text
											style={{
												fontFamily: "Gotham",
												fontSize: 9,
												color:
													d.mode === "Dark"
														? APP_COLOURS.TEXTCOLOR
														: APP_COLOURS.BG3,
												fontWeight: "300",
											}}>
											{moment(
												d.dateCreated,
												"DD/MM/YYYY",
											).fromNow()}
										</Text>
									</View>
									<View
										style={{
											flex: 1,
											padding: 5,
											marginLeft: 9,
										}}>
										<Text
											style={{
												fontFamily: "Gotham",
												fontSize: 16,
												color:
													d.mode === "Dark"
														? APP_COLOURS.TEXTCOLOR
														: APP_COLOURS.BG3,
												fontWeight: "300",
												width: "80%",
												flexWrap: "wrap",
											}}>
											{d.title}
										</Text>

										<Text
											style={{
												fontFamily: "Gotham",
												fontSize: 11,
												color:
													d.mode === "Dark"
														? APP_COLOURS.TEXTCOLOR
														: APP_COLOURS.BG3,
												fontWeight: "300",
												maxHeight: 60,
												width: "70%",
												flexWrap: "wrap",
											}}>
											{/* {d.text.slice(0, 300)} */}
											{d.text}
										</Text>
									</View>
								</View>
							</View>
						</TouchableOpacity>
					))}
				</View>
			</View>
		);
	}

	fetchData = () => {
		console.log("fetching ===");
		this.setState({
			loading: true,
		});

		this.setState({
			loading: false,
		});
		return;

		crudReadUnass(
			this.state.crudDocument,
			{
				field: "project_id",
				action: "==",
				value: this.props.project_id,
			},
			async (status, d) => {
				console.log("messages", status, d);
				if (status === 200) {
					// this.setState({
					// 	sentMessageData: "",
					// 	sendingMessage: false,
					// });
					// if (
					// 	this.state.messages.length > 0 &&
					// 	d.length !== this.state.messages.length
					// ) {
					// 	playAudio(6);
					// }
					// await this.setState({
					// 	messages: d,
					// 	loading: false,
					// });
				}
			},
		);
	};
}
