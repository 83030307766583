import React, { Component, createRef } from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";

const images = [
	{
		imgName: "box 1",
		uri: require("../../../assets/images/checkBox1.png"),
	},
	{
		imgName: "box 2",
		uri: require("../../../assets/images/checkBox2.png"),
	},
	{
		imgName: "box 3",
		uri: require("../../../assets/images/checkBox3.png"),
	},
	{
		imgName: "box 4",
		uri: require("../../../assets/images/checkBox4.png"),
	},
];

class CheckBox extends Component {
	constructor(props) {
		super(props);
		this.state = {};

		this.subText = null;
	}

	checked = () => {
		// this.setState({
		//   checked: !this.props.checked,
		// });
		this.props.checkedItem();
	};

	longPress = () => {
		this.props.delete();
	};

	render() {
		return (
			<View
				style={{
					padding: 2,
					paddingLeft: 0,
					width: "100%",
					marginBottom: 3,
					paddingTop: 3,
					// height: 30,
				}}>
				<TouchableOpacity
					onLongPress={() => this.longPress()}
					onPress={() => this.checked()}>
					<View
						style={{
							// flex: 1,
							flexDirection: "row",
							// alignItems: 'center',
						}}>
						{this.props.checked ? (
							<Image
								style={{
									width: this.props.fontSize + 3,
									height: this.props.fontSize + 3,
									resizeMode: "contain",
								}}
								source={require("../../../assets/images/checkBox5.png")}
							/>
						) : (
							<Image
								style={{
									width: this.props.fontSize + 3,
									height: this.props.fontSize + 3,
									resizeMode: "contain",
								}}
								source={images[this.props.checkBoxStyle].uri}
							/>
						)}

						<Text
							style={{
								flex: 1,
								paddingLeft: 10,
								// top: -1,
								fontSize: this.props.fontSize,
								color: "#000",
								textDecorationLine: this.props.checked
									? "line-through"
									: "none",
								flexWrap: "wrap",
								opacity: this.props.checked ? 0.2 : 1,
							}}>
							{this.props.title}
						</Text>
					</View>
				</TouchableOpacity>
			</View>
		);
	}
}

export default CheckBox;
