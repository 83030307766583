import React from "react";
import { TextInput, View } from "react-native";
import { useRef } from "react";

export function TaskerInput({ onTextChange }) {
	const textInputRef = useRef(null);

	return (
		<View>
			<TextInput
				style={{
					marginTop: 10,
					height: "auto",
					// paddingLeft: 20,
					padding: 10,

					fontSize: 16,
					borderRadius: 8,
					// backgroundColor: 'rgba(52, 52, 52, 0.12)',
					color: "#FFF",
					fontWeight: "300",
					borderColor: "rgba(255, 255, 255, 0.15)",
					borderWidth: 0.5,
				}}
				ref={textInputRef}
				placeholderTextColor={"#787878"}
				autoCapitalize='sentences'
				clearButtonMode='while-editing'
				autoCompleteType='off'
				autoCorrect={false}
				defaultValue={this.textInput}
				// multiline
				// numberOfLines={4}
				placeholder={``}
				onChangeText={async (text) => {
					this.textInput = text;
				}}
				onSubmitEditing={async () => {
					onTextChange(this.textInput);
					this.textInput = "";
					textInputRef.current.clear();
				}}
			/>
		</View>
	);
}
