import React, { Component } from "react";
import { View, Image, ScrollView, Linking, Text } from "react-native";
import LandingPage from "./LandingPage";
import IntroPage from "./IntroPage";

import logo_tetrice from "../../../assets/images/tetrice-revlon-main.png";
// import Page2 from "./Page2.js";
// import Font from "react-font";
// import { APP_COLOURS } from "./APP_VARS";
// import Page1 from "./Page1";
// import Page3 from "./Page3";
import ProposalPage from "./PresentationPage";
// import Page4 from "./Page4";
// import Page5 from "./Page5";
// import Page6 from "./Page6";
// import Page7 from "./Page7";
// import Page8 from "./Page8";
// import Page9 from "./Page9";
// import Page10 from "./Page10";
// import Page11 from "./Page11";
// import Page12 from "./Page12";
// import Page13 from "./Page13";
// import Page14 from "./Page14";
// import Page15 from "./Page15";
// import PageFooter from "./PageFooter";

import * as findTarget from "../../../assets/lottie/64198-find-target.json";

if (typeof window !== "undefined") {
	require("smoothscroll-polyfill").polyfill();
}

export default class ProposalReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
			showSidebar: false,
			loading: true,
		};
	}

	componentDidMount() {
		console.log("componentDidMount");
		let accessToken = localStorage.getItem("accessToken");

		console.log("Approved User", accessToken);
		if (accessToken !== null) {
			// this.props.history.push("/dashboard/Home");
		} else {
			localStorage.clear();
			this.setState({
				loading: false,
			});
		}

		window.addEventListener("resize", this.resize.bind(this));
		this.resize();

		setTimeout(() => {
			this.setState({
				loading: false,
			});
		}, 2000);
	}

	componentDidUpdate() {}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	render() {
		// const { screenWidth } = this.state;
		// const isSmallScreen = screenWidth < 1000;
		// const scaleFactor = isSmallScreen ? 0.8 : 1.0;
		// const pageStyle = {
		// 	transform: `scale(${scaleFactor})`,
		// };

		let fs = window.innerWidth * 0.07;
		let maxFont = 40;
		let h1 = fs < maxFont ? fs : maxFont;
		let h2 = h1 / 1.618;
		let pp = h2 / 1.618;

		let mw = 1900;

		if (this.state.loading) {
			return (
				<View
					style={{
						// width: window.innerWidth,
						height: window.innerHeight,
						backgroundColor: "#171A20",
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						position: "absolute",
						top: 0,
						zIndex: 999,
						width: "100%",
					}}>
					<Image
						source={logo_tetrice}
						style={{
							width: 350,
							height: 100,
							resizeMode: "contain",
						}}
					/>
				</View>
			);
		} else
			return (
				<View
					style={{
						width: this.state.screenWidth,
						height: this.state.screenHeight,
						overflow: "hidden",
						backgroundColor: "#171A20",
					}}>
					<ScrollView
						ref={(ref) => {
							this.scrollRef = ref;
						}}
						overScrollMode='never'
						// showsVerticalScrollIndicator={false}
						// pagingEnabled={true}
						// decelerationRate='fast'
						// scrollEventThrottle={160}
						// snapToInterval={this.state.screenHeight}
						contentContainerStyle={{
							alignItems: "center",
						}}>
						<View
							style={{
								flex: 1,
								width: "100%",
								maxWidth: mw,
								padding: "8%",
								// paddingRight: "15%",
							}}>
							<LandingPage
								{...this.state}
								h1={h1}
								h2={h2}
								pp={pp}
							/>

							<IntroPage
								{...this.state}
								h1={h1}
								h2={h2}
								pp={pp}
							/>

							{[
								{
									title: "Overview",
									title2: "",
									animationData: findTarget,
								},
							].map((d) => (
								<ProposalPage
									{...this.state}
									h1={h1}
									h2={h2}
									pp={pp}
									{...d}
								/>
							))}
						</View>

						{/* <Scope {...this.state} h1={h1} h2={h2} pp={pp} /> */}

						{/* <Page1 {...this.state} /> */}

						{/* <Page2 {...this.state} /> */}

						{/* <Page3 {...this.state} /> */}

						{/* <Page5 {...this.state} />

					<Page10 {...this.state} />

					<Page6 {...this.state} />

					<Page4 {...this.state} />

					<Page7 {...this.state} />

					<Page9 {...this.state} />

					<Page8 {...this.state} />

					<Page17 {...this.state} />

					<Page11 {...this.state} />

					<Page12 {...this.state} />

					<Page13 {...this.state} /> */}

						{/* <TeamTetrice /> */}

						{/* <PageFooter /> */}
					</ScrollView>

					{/* {this.state.enquireNowForm ? (
         <EnquireNow
            {...this.props}
            {...this.state}
            nextPage={() =>
               this.setState({
                  resultsPage: true,
               })
            }
            close={() =>
               this.setState({
                  enquireNowForm: false,
               })
            }
         />
      ) : null} */}
				</View>
			);
	}
}
