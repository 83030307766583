import React, { useState } from "react";

import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import JsonTable from "./JSONTable";
import AutoHeightImage from "../AutoHeightImageComp";

const TSVToJsonConverter = ({ jsonReturn }) => {
	const [jsonResult, setJsonResult] = useState(null);

	const handleFileChange = (event) => {
		const file = event.target.files[0];

		if (file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				const text = e.target.result;
				const json = tsvToJson(text);
				setJsonResult(json);
			};
			reader.readAsText(file);
		}
	};

	const tsvToJson = (tsv) => {
		const lines = tsv.split("\n");
		const result = [];
		const headers = lines[0].split("\t"); // Splitting by tab

		for (let i = 1; i < lines.length; i++) {
			let obj = {};
			const currentline = lines[i].split("\t"); // Splitting by tab

			for (let j = 0; j < headers.length; j++) {
				obj[headers[j]] = currentline[j];
			}

			result.push(obj);
		}
		console.log(result);
		jsonReturn(result);
		return result;
		return JSON.stringify(result, null, 2); // Convert to JSON
	};

	return (
		<View style={{}}>
			<input type='file' accept='.tsv' onChange={handleFileChange} />
		</View>
	);
};

export default TSVToJsonConverter;
