import React, { useState } from "react";

const Paginater = ({ jsonData, cp }) => {
	const [currentPage, setCurrentPage] = useState(0);
	const recordsPerPage = 20;
	const columnNames = jsonData.length > 0 ? Object.keys(jsonData[0]) : [];

	const paginate = (data, pageNumber, recordsPerPage) => {
		const startIndex = pageNumber * recordsPerPage;
		return data.slice(startIndex, startIndex + recordsPerPage);
	};

	const totalPages = Math.ceil(jsonData.length / recordsPerPage);

	return (
		<div>
			<div style={styles.paginationContainer}>
				{Array.from({ length: totalPages }, (_, i) => (
					<button
						key={i}
						style={styles.pageButton}
						onClick={() => {
							setCurrentPage(i);
							cp(i);
						}}
						disabled={i === currentPage}>
						{i + 1}
					</button>
				))}
			</div>
		</div>
	);
};

const styles = {
	// ... existing styles ...
	paginationContainer: {
		display: "flex",
		justifyContent: "center",
		marginTop: "20px",
	},
	pageButton: {
		margin: "0 5px",
		padding: "5px 10px",
	},
};

export default Paginater;
