import React, { Component } from "react";
import { View, Text, ScrollView, TouchableOpacity, Image } from "react-native";
import Font from "react-font";
import { APP_COLOURS } from "../../../APP_VARS";

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pageMargin: 32,
		};
	}

	render() {
		let margin = this.props.margin;
		return (
			<View>
				<View
					style={{
						width: "100%",
						paddingRight: margin,
						alignItems: "flex-end",
					}}>
					<View
						style={{
							borderTopColor: "#141D31",
							borderTopWidth: 1,
							borderStyle: "dotted",
							opacity: 0.8,
							paddingTop: 10,
						}}>
						<Text
							style={{
								fontSize: 9,
								marginBottom: 5,
							}}>
							<Font family='Poppins' weight={300}>
								MSA v1.0 MARCH 2022, page{" "}
								{this.props.pageNumber} of{" "}
								{this.props.totalPages}{" "}
							</Font>
						</Text>
					</View>
				</View>

				<View
					style={{
						width: "100%",
						height: 75,
						flexDirection: "row",
						// backgroundColor: "#1C2B46",
						backgroundColor: APP_COLOURS.BG2,
						padding: this.state.pageMargin - 10,
					}}>
					<View
						style={{
							flex: 1,
							justifyContent: "center",
							paddingTop: 7,
							paddingBottom: 7,
							paddingLeft: 6,
						}}>
						<Text
							style={{
								fontWeight: "300",
								color: "#FFF",
								fontSize: 20,
							}}>
							<Font
								style={{
									color: "#FFF",
								}}
								family='Poppins'
								weight={300}>
								{this.props.title}
							</Font>
						</Text>
					</View>
					<View style={{ flex: 1, alignItems: "flex-end" }}>
						<Image
							style={{
								width: 220,
								height: 38,
								resizeMode: "contain",
							}}
							source={require("../../../../assets/images/infod.png")}
						/>
					</View>
				</View>
			</View>
		);
	}
}

export default Footer;
