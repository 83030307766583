import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import { Avatars, StatusColour, TaskStatus } from "../../APP_VARS";
import StatusItem from "../DailyPlanner/StatusItem";
import Lottie from "react-lottie";
import {
	crudCreate,
	crudDelete,
	crudGetUsers,
	crudRead,
	crudReadAll,
	crudUpdate,
} from "../../CRUDHELPER";
import Fade from "react-reveal/Fade";
import AutoHeightImage from "../AutoHeightImageComp";

import * as animationData from "../../../assets/lottie/team.json";

function uuid() {
	return "xxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

export default class PreviewUsers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			users: [],
			selectedUser: "",
			user_data: {
				id: "",
				username: "",
			},
		};
	}

	componentDidMount() {
		this.validateSession();
		this.getusers();
	}

	getusers = async () => {
		crudGetUsers((users) => {
			this.setState({
				users: users,
				loading: false,
			});
			console.log(users);
		});
	};

	validateSession = async () => {
		let user_data = await localStorage.getItem("user_data");
		let email = await localStorage.getItem("email");
		let accessToken = await localStorage.getItem("accessToken");
		let refresh_token = await localStorage.getItem("refresh_token");

		if (accessToken !== null && accessToken !== "") {
			await this.setState({
				user_data: JSON.parse(user_data),
				email,
				accessToken,
				refresh_token,
			});
			setTimeout(() => {
				this.setState({
					loading: false,
					authenticating: false,
				});
			}, 1000);
		} else {
			localStorage.clear();
			window.location.href = "/";
		}
	};

	render() {
		// .filter((d) => d.organisation === "tetrice")
		let users = this.state.users

			.filter((d) => {
				if (
					typeof d.lastSeen !== "undefined" &&
					moment(d.lastSeen)
						.subtract(3, "days")
						.isSameOrBefore(moment())
				) {
					return d;
				}
			})
			.filter((d_) => {
				let filter = d_;

				if (
					typeof this.state.filterSearch !== "undefined" &&
					this.state.filterSearch !== ""
				) {
					let text_ = this.state.filterSearch;
					var outString = text_.replace(
						/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
						"",
					);

					let join_names = `${JSON.stringify(d_)}`;

					join_names = join_names.replace(
						/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
						"",
					);

					return join_names
						.toLowerCase()
						.includes(outString.toLowerCase());
				}

				return filter;
			})
			.sort((a, b) => {
				return moment(b.lastSeen).unix() - moment(a.lastSeen).unix();
			});

		let offline = users.filter(
			(item) => moment().diff(moment(item.lastSeen), "hours") > 24,
		);
		let online = users.filter(
			(item) => moment().diff(moment(item.lastSeen), "hours") <= 24,
		);

		// .sort((a, b) => a.order > b.order)
		// 			.filter((d) => d.status !== "offline")

		return (
			<View
				style={{
					flex: 1,
					padding: 10,
					width: "100%",
					backgroundColor: APP_COLOURS.BG3,
					borderRadius: 20,
					// display: offline ? "none" : "flex",
				}}>
				{online.map((d) => this.userListItem(d, d.status))}
				{/* <Text
					style={{
						fontSize: 12,
						color: APP_COLOURS.TEXTCOLOR,
						fontWeight: "300",
						marginTop: 10,
					}}>
					Offline
				</Text> */}
				{/* {offline.map((d) => this.userListItem(d, "offline"))} */}

				{/* <Text
					style={{
						fontSize: 16,
						color: APP_COLOURS.BG2,
						fontWeight: "900",
						padding: 2,
						marginTop: 20,
					}}>
					REVLON
				</Text>
				{users
					.filter((d) => d.organisation === "REVLON")
					.filter((d) => {
						if (
							typeof d.lastSeen !== "undefined" &&
							moment(d.lastSeen)
								.subtract(24, "hours")
								.isSameOrBefore(moment())
						) {
							return d;
						}
					})
					.sort((a, b) => a.order > b.order)
					.map((d) => this.userListItem(d))} */}
			</View>
		);
	}

	userListItem = (d, status) => {
		return (
			<TouchableOpacity
				key={d.id}
				style={{}}
				onPress={() =>
					this.setState({
						selectedUser: d,
					})
				}>
				<View
					style={{
						width: "100%",
						padding: 2,
						borderRadius: 5,
						marginRight: 5,
						flexDirection: "row",
						alignItems: "center",
						backgroundColor: APP_COLOURS.BG4,
						marginBottom: 3,
						borderRadius: 12,
					}}>
					<Image
						source={Avatars[parseInt(d.avatar)].pp}
						style={[
							{
								width: 34,
								height: 34,
								borderRadius: 25,
								resizeMode: "contain",
								backgroundColor: "#000",
								borderColor: StatusColour(status),
								// borderColor: "#C5C5C5",
								borderWidth: 2,
								// shadowColor: StatusColour(status),
								// shadowOffset: {
								// 	width: 0,
								// 	height: 8,
								// },
								// shadowOpacity: 0.44,
								// shadowRadius: 10.32,
								// elevation: 16,
								// opacity: 0.9,
								overflow: "hidden",
							},
						]}
					/>
					<View
						style={{
							flex: 1,
							paddingLeft: 5,
							width: "100%",
						}}>
						<View
							style={{
								flex: 1,
								flexDirection: "row",
								width: "100%",
							}}>
							<Text
								style={{
									fontSize: 13,
									color:
										this.state.selectedUser.id === d.id
											? "#FFF"
											: APP_COLOURS.TEXTCOLOR,
									fontWeight: "500",
								}}>
								{d.first_name}{" "}
							</Text>
							<Text
								style={{
									fontSize: 13,
									opacity: 0.7,
									color:
										this.state.selectedUser.id === d.id
											? "#FFF"
											: APP_COLOURS.TEXTCOLOR,
									fontWeight: "500",
								}}>
								{d.last_name}
							</Text>
						</View>
						<Text
							style={{
								fontSize: 9,
								color:
									this.state.selectedUser.id === d.id
										? "#FFF"
										: APP_COLOURS.TEXTCOLOR,
								fontWeight: "400",
							}}>
							{/* {d.username} */}
							{moment(d.lastSeen).fromNow()}
							{" | "}
							<Text
								numberOfLines={1}
								ellipsizeMode='tail'
								style={{
									opacity: 0.8,
									fontWeight: "300",
									opacity: status !== "offline" ? 1 : 0,
								}}>
								{typeof d.statusDescription !== "undefined"
									? d.statusDescription
									: ""}
							</Text>
						</Text>

						{/* <Text
							style={{
								// maxWidth: 160,
								fontSize: 9,
								color:
									this.state.selectedUser.id === d.id
										? "#FFF"
										: APP_COLOURS.TEXTCOLOR,
								fontWeight: "500",
								// textAlign: "right",
							}}>
							{typeof d.position !== "undefined"
								? d.position
								: d.organisation}
						</Text> */}
					</View>
					{/* <View
						style={{
							justifyContent: "flex-end",
							alignItems: "flex-end",
						}}>
						
					</View> */}
				</View>
			</TouchableOpacity>
		);
	};

	profileItem = (d) => {
		return (
			<View style={{ flex: 1, alignItems: "center" }}>
				<AutoHeightImage
					source={Avatars[parseInt(d.avatar)].pp}
					width={55}
				/>
				<View style={{ flex: 1, flexDirection: "row" }}>
					<Text
						style={{
							fontSize: 16,
							color: APP_COLOURS.BG2,
							fontWeight: "500",
							opacity: 0.7,
						}}>
						{d.first_name}{" "}
					</Text>
					<Text
						style={{
							fontSize: 16,
							color: APP_COLOURS.BG2,
							fontWeight: "500",
						}}>
						{d.last_name}
					</Text>
				</View>
			</View>
		);
	};
}
