import React, { Component } from "react";

import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import Fade from "react-reveal/Fade";
import AutoHeightImage from "../AutoHeightImageComp";
import Lottie from "react-lottie";
import * as animationData from "./sending.json";

export default class SendingFeedback extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<View
				style={{
					backgroundColor: "#000",
					width: window.innerWidth,
					overflow: "hidden",
					height: window.innerHeight,
					alignItems: "center",
				}}>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						padding: 5,
						opacity: 0.3,
					}}>
					<View
						style={{
							padding: 5,
							flexDirection: "row",
						}}>
						<TouchableOpacity
							style={{
								flexDirection: "row",
								alignItems: "center",
								// position: "absolute",
							}}
							onPress={() => {
								// this.props.close();
							}}>
							<AutoHeightImage
								width={45}
								source={require("../../../assets/images/menu.png")}
							/>
							<Text
								adjustsFontSizeToFit
								style={{
									fontWeight: "800",
									fontFamily: "Gotham",
									color: "#FFF",
									fontSize: 22,
									marginLeft: 9,
								}}>
								tetrice
							</Text>
							{/* <Text
								adjustsFontSizeToFit
								style={{
									fontWeight: "300",
									fontFamily: "Gotham",
									color: "#FFF",
									fontSize: 15,
									marginLeft: 4,
									opacity: 0.7,
								}}>
								verification
							</Text> */}
						</TouchableOpacity>
						{/* <AutoHeightImage
							width={12}
							source={require("./verified.png")}
						/> */}
					</View>
				</View>

				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						padding: 5,
					}}>
					<Text
						style={{
							color: "#FFF",
							fontSize: 19,
							fontWeight: "800",
							fontFamily: "Gotham",
							opacity: 0.8,
						}}>
						Sending...
					</Text>
					<Fade left>
						<View style={{}}>
							<Lottie
								options={{
									loop: true,
									autoplay: true,
									animationData: animationData,
									rendererSettings: {
										preserveAspectRatio: "xMidYMid slice",
									},
								}}
								height={90}
								width={90}
							/>
						</View>
					</Fade>
				</View>
			</View>
		);
	}
}
