import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TextInput,
	ScrollView,
	Modal,
	Alert,
	Keyboard,
	Animated,
	LayoutAnimation,
	ActivityIndicator,
} from "react-native";
import {
	APP_COLOURS,
	TaskStatus,
	CustomLayoutSpring,
	Avatars,
	PROJECTSTATUS,
} from "../../APP_VARS";
import moment from "moment";
import Lottie from "react-lottie";
import {
	crudRead,
	crudReadAll,
	crudCreate,
	crudDelete,
	crudUpdate,
	crudGetUsers,
	crudSendEmail,
	sendDiscordMessage,
	crudReadUnass,
	crudSendUserIdEmail,
	crudDeleteArray,
	crudDeleteDocument,
	crudDeleteWhere,
	crudGetTasks,
	crudGetProjects,
	crudCreateWithHtml,
	fetchProjectsLastUpdated,
} from "../../CRUDHELPER";
import ProgressBar from "./ProgressBar";
import CheckBox from "./checkBox";
import AddTask from "./AddTask";
import { TaskerInput } from "./TaskerInput";
import UsersSelectList from "../UsersSelectList";
import TodayTimeline from "./TodayTimeline";
import ChangeDate from "./ChangeDate";
import StatusItem from "./StatusItem";
import * as animationData from "../../../assets/lottie/animation_lnu500l9.json";

// import debbie from "../../../assets/images/cloud.png";
// import starFill from "../../../assets/images/Table/starFill.png";
// import star from "../../../assets/images/Table/star.png";
import bin from "../../../assets/images/bin.png";
import AddProject from "./AddProject";
import EventCalendar from "../EventCalendar";
import GanttComponent from "../Gantt";
// import DailyPlanner from "./DailyPlanner";
// import TopTabs from "../TopTabs";
// import TopTabsObj from "../TopTabs/TabPassObj";
import SmallerTabs from "../TopTabs/SmallerTabs";
import AutoHeightImage from "../AutoHeightImageComp";
import EditTask from "../Gantt/EditTask";
import ChatBox from "./ChatBox";
import TextEditorComp from "./TextEditorCom";
import ActivityLog from "../ActivityLog";

import Pie from "./PieChart";
import ProjectDocuments from "./ProjectDocuments";
import GetUser from "../GetUser";
import UsersSelectCard from "../UsersSelectCards";
import NotificationList from "../Notifications/NotificationList";

import * as animationData2 from "../../../assets/lottie/Animation-Ai.json";
import DescriptionQuillEditor from "./Editor/DescriptionQuillEditor";
import PrivateChats from "./PrivateChats";
import ProjectComponent from "./ProjectComponent";

function uuid() {
	return "xxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

function isOdd(num) {
	return num % 2;
}

const BG = "#171B22";
const BG2 = "#20242B";
const FONT = "#F3F3F5";
const BORDER = "#383D43";

const BLUE = "#3F6EFF";

// class AutoHeightImage extends Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {};
// 	}
// 	// <Image
// 	// 			style={{
// 	// 				width: this.props.width,
// 	// 				height: "auto",
// 	// 				resizeMode: "contain",
// 	// 			}}
// 	// 			source={this.props.source}
// 	// 		/>
// 	render() {
// 		return (
// 			<img
// 				style={{
// 					width: this.props.width,
// 					resizeMode: "contain",
// 				}}
// 				src={this.props.source}
// 				alt='Image'
// 				className='responsive'
// 			/>
// 		);
// 	}
// }

export default class ProjectManager extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			showModal: false,
			addProjectTask: false,
			addNewProject: false,
			hover: true,
			showChat: false,
			showSubTasks: false,
			showAssignment: false,
			showDateChange: false,
			needToUpdateProject: false,
			selectedChat: "",
			filterTab: "",
			xPos: 0,
			yPos: 0,
			user_data: {
				id: "",
			},
			selectedProject: "",
			crudDocumentName: "projects",
			uniqueProjectTitles: [],
			showProjects: true,

			showModalArea: "",

			currentPage: "",

			projects: [],
			milestones: [],
			tasks: [],

			subPage: "",
			selectedTask: "",

			users: [],

			selecteduserFilter: "",

			allowDeeplink: true,
			loadingDeeplink: false,

			lockContent: "",

			filterSearch: "",

			archiveView: false,
		};

		this.keyboardOffset = new Animated.Value(0);
		this.projects = [];
		this._timeout = 1000;
		this.statusTypes = [
			{ title: "Pending", color: "transparent" },
			{ title: "Inprogress", color: "#E8E8E8" },
			{ title: "Delayed", color: APP_COLOURS.ORANGE },
			{ title: "Completed", color: APP_COLOURS.GREEN },
		];

		// this.keyboardDidShowListener = null;
	}

	handleStatusChange = async (id, status) => {
		let projects = [...this.state.projects];
		let taskIndex = projects.findIndex((task) => task.id === id);
		let task = projects[taskIndex];

		task.status = status;
		task.archive = status === "Completed";
		projects[taskIndex] = task;

		await this.setState({ projects });
		await this.updateProjectItem(projects[taskIndex]);
	};

	handleDateChange = async (date, id) => {
		let projects = [...this.state.projects];
		let projectIndex = projects.findIndex((task) => task.id === id);
		let project = projects[projectIndex];
		project.due_date = date;
		projects[projectIndex] = project;
		await this.updateProjectItem(projects[projectIndex]);
		await this.setState({ projects });
	};

	handleProjectChange = async (text, id) => {
		let projects = [...this.state.projects];
		let projectIndex = projects.findIndex((task) => task.id === id);
		let project = projects[projectIndex];
		project.project = text;
		projects[projectIndex] = project;
		await this.updateProjectItem(projects[projectIndex]);
		await this.setState({ projects });
	};

	handleTitleChange = async (text, id) => {
		let projects = [...this.state.projects];
		let projectIndex = projects.findIndex((task) => task.id === id);
		let project = projects[projectIndex];
		project.title = text;
		projects[projectIndex] = project;
		await this.updateProjectItem(projects[projectIndex]);
		await this.setState({ projects });
	};

	handlePriorityChange = async (priority, id) => {
		let projects = [...this.state.projects];
		let projectIndex = projects.findIndex((task) => task.id === id);
		let project = projects[projectIndex];
		project.priority = priority;
		projects[projectIndex] = project;
		await this.updateProjectItem(projects[projectIndex]);
		await this.setState({ projects });
	};

	handleNewMessageChange = async (text, id) => {
		let projects = [...this.state.projects];
		let projectIndex = projects.findIndex((task) => task.id === id);
		let project = projects[projectIndex];
		project.newMessage = text;
		projects[projectIndex] = project;
		await this.updateProjectItem(projects[projectIndex]);
		await this.setState({ projects });
	};

	handleMessageSubmit = async (id) => {
		let projects = [...this.state.projects];
		let projectIndex = projects.findIndex((task) => task.id === id);
		let project = projects[projectIndex];
		project.messages = [
			...project.messages,
			{
				text: project.newMessage,
				createdBy: this.state.user_data,
				id: uuid(),
			},
		];
		project.newMessage = "";
		projects[projectIndex] = project;
		await this.updateProjectItem(projects[projectIndex]);
		await this.setState({
			selectedSubtasks: projects[projectIndex],
		});
		await this.setState({ projects });
	};

	//
	//

	addAssignment = async (assignment, assignment_ids) => {
		let originalAssignments = this.state.selectedAssignment;
		// console.log(
		// 	"assignment",
		// 	assignment.map((d) => d.username),
		// 	"originalAssignments",
		// 	originalAssignments.map((d) => d.username),
		// );
		// return;
		//
		//
		let projects = [...this.state.projects];
		let projectIndex = projects.findIndex(
			(task) => task.id === this.state.selectedID,
		);
		projects[projectIndex].assignment = assignment;
		projects[projectIndex].assignment_ids = assignment_ids;

		const updatedAssignments = assignment; // Updated array with 5 items

		// Assuming each assignment has a unique 'id' or 'username'
		const newlyAddedAssignments = updatedAssignments.filter(
			(updatedAssignment) =>
				!originalAssignments.some(
					(originalAssignment) =>
						originalAssignment.id === updatedAssignment.id, // Replace 'id' with the property you use to uniquely identify assignments
				),
		);

		// console.log("newlyAddedAssignments", newlyAddedAssignments);
		// return;
		const mapThrough = await newlyAddedAssignments.forEach(async (e) => {
			await crudSendEmail(
				"SKrum  <skrum@tetrice.co.za>",
				e.username,
				"Project assignment",
				`You've been added to a project: <br> <h3> ${projects[projectIndex].brand?.title}</h3> <br> <h3> ${projects[projectIndex].title}</h3><br>
				
			<br>
			<br>Be sure to check for tasks that are allocated to you.
			<br>https://skrum.tetrice.co.za/
			`,
			);
		});

		const mapThroughDiscord = await newlyAddedAssignments
			.filter((a) => a !== this.state.user_data.id)
			.forEach(async (e) => {
				await sendDiscordMessage(
					`${this.state.user_data.first_name} ${this.state.user_data.last_name}`,
					`### *You've been added to a project: 
> ${projects[projectIndex].brand?.title} 
> ${projects[projectIndex].title}
- url: ${window.location.origin}/home/projects?pid=${projects[projectIndex].id}
`,
					`${window.location.origin}${
						Avatars[this.state.user_data.avatar].pp
					}`,
					e.id,
				);
			});

		console.log(JSON.stringify(projects[projectIndex].assignment_ids));
		await this.updateProjectItem(projects[projectIndex]);
		await this.setState({ projects });
	};

	//
	//
	addSubtask = async (subtask) => {
		let projects = [...this.state.projects];
		let projectIndex = projects.findIndex(
			(task) => task.id === this.state.selectedID,
		);
		projects[projectIndex].subtasks.push(subtask);
		await this.updateProjectItem(projects[projectIndex]);
		await this.setState({
			selectedSubtasks: projects[projectIndex],
		});
		await this.setState({ projects });
	};
	toggleSubtaskStatus = async (id, subtaskIndex, status) => {
		let projects = [...this.state.projects];
		let projectIndex = projects.findIndex(
			(task) => task.id === this.state.selectedID,
		);
		projects[projectIndex].subtasks[subtaskIndex].status =
			!projects[projectIndex].subtasks[subtaskIndex].status;
		let completedSubtasks = projects[projectIndex].subtasks.filter(
			(s) => s.status === true,
		);
		projects[projectIndex].progress =
			(completedSubtasks.length /
				projects[projectIndex].subtasks.length) *
			100;
		await this.updateProjectItem(projects[projectIndex]);
		await this.setState({
			selectedSubtasks: projects[projectIndex],
		});
		await this.setState({ projects });
	};

	removeSubtask = async (id, subtaskIndex) => {
		let projects = [...this.state.projects];
		let projectIndex = projects.findIndex((task) => task.id === id);
		projects[projectIndex].subtasks.splice(subtaskIndex, 1);
		let completedSubtasks = projects[projectIndex].subtasks.filter(
			(s) => s.status === true,
		);
		projects[projectIndex].progress =
			(completedSubtasks.length /
				projects[projectIndex].subtasks.length) *
			100;
		await this.updateProjectItem(projects[projectIndex]);
		await this.setState({
			selectedSubtasks: projects[projectIndex],
		});
		await this.setState({ projects });
	};

	deleteProject(id) {
		this.setState({
			projects: this.state.projects.filter(
				(project) => project.id !== id,
			),
			filterSearch: "",
		});
	}

	deepLinkToProject = () => {
		if (
			typeof this.props.project_id !== "undefined" &&
			this.props.project_id !== null &&
			this.state.allowDeeplink
		) {
			console.log(this.state.projects.length);
			let project = this.state.projects.find(
				(p) => p.id === this.props.project_id,
			);
			console.log(project, this.props.project_id);
			if (typeof project !== "undefined") {
				this.setState({
					selectedProject: "",
					selectedAssignment: "",
					subPage: "",
					hover: false,
				});
				setTimeout(() => {
					this.setState({
						selectedProject: project,
						selectedAssignment: [...project.assignment],
						subPage: "Overview",
					});
				}, 100);
				setTimeout(() => {
					this.setState({
						allowDeeplink: false,
					});
				}, 100);
			}
			// else {
			// 	alert(`Cant find project ${this.props.project_id}`);
			// }
		}
	};

	render() {
		LayoutAnimation.configureNext(CustomLayoutSpring);

		return (
			<View
				style={{
					flex: 1,
					padding: 10,
				}}>
				<View
					style={{
						width: "100%",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						minHeight: 80,
					}}>
					<View
						style={{
							flexDirection: "row",
							alignItems: "center",
							paddingLeft: 10,
						}}>
						<Image
							source={require("../../../assets/images/logo_ai.png")}
							style={{
								width: 35,
								height: 35,
								resizeMode: "contain",
								marginRight: 10,
							}}
						/>

						<View>
							<Text
								style={{
									// color: APP_COLOURS.TEXTCOLOR,
									color: "#FFF",
									fontSize: 26,
									fontWeight: "200",
									fontFamily: "Gotham",
								}}>
								Master
							</Text>
							<Text
								style={{
									color: APP_COLOURS.TEXTCOLOR,
									fontSize: 9,
									fontWeight: "300",
									fontFamily: "Gotham",
								}}>
								now powered by Ai
							</Text>
						</View>
					</View>
					<View
						style={{
							flexDirection: "row",
						}}>
						{/* <View
							style={{
								flexDirection: "row",
								alignItems: "center",
								borderRadius: 10,
								// padding: 4,
								// backgroundColor: APP_COLOURS.BG,
								marginRight: 20,
							}}>
							<Lottie
								options={{
									loop: true,
									autoplay: true,
									animationData: animationData2,
									rendererSettings: {
										preserveAspectRatio: "xMidYMid slice",
									},
								}}
								height={50}
								width={50}
							/>
							<View style={{}}>
								<AutoHeightImage
									width={90}
									source={require("../../../assets/images/skrum_ai.png")}
								/>
								<Text
									style={{
										color: APP_COLOURS.TEXTCOLOR,
										fontSize: 9,
										fontWeight: "300",
										fontFamily: "Gotham",
									}}>
									now powered by Ai
								</Text>
							</View>
						</View> */}
						<TouchableOpacity
							style={{
								marginHorizontal: 10,
								opacity:
									this.state.currentPage === "projects"
										? 0.2
										: 1,
							}}
							onPress={() => {
								let page = "projects";

								this.setState({
									currentPage: page,
									hover: true,
								});
							}}>
							<View
								style={{
									padding: 10,
									backgroundColor: APP_COLOURS.BG4,
									borderRadius: 15,
									minWidth: 150,
									alignItems: "center",
								}}>
								<View
									style={{
										flexDirection: "row",
										alignItems: "center",
									}}>
									<AutoHeightImage
										width={25}
										source={require("../../../assets/images/icons/DevTracker.png")}
									/>
									<Text
										style={{
											color: APP_COLOURS.TEXTCOLOR,
											fontSize: 16,
											fontWeight: "200",
											fontFamily: "Gotham",
											marginLeft: 10,
										}}>
										Projects
									</Text>
								</View>
							</View>
						</TouchableOpacity>
						<TouchableOpacity
							style={{
								marginHorizontal: 10,
								opacity:
									this.state.currentPage === "gantt"
										? 0.2
										: 1,
							}}
							onPress={() => {
								let page = "gantt";
								this.setState({
									currentPage: page,
									hover: true,
								});
							}}>
							<View
								style={{
									padding: 10,
									backgroundColor: APP_COLOURS.BG4,
									borderRadius: 15,
									minWidth: 150,
									alignItems: "center",
								}}>
								<View
									style={{
										flexDirection: "row",
										alignItems: "center",
									}}>
									<AutoHeightImage
										width={25}
										source={require("../../../assets/images/icons/gantt_icon.png")}
									/>
									<Text
										style={{
											color: APP_COLOURS.TEXTCOLOR,
											fontSize: 16,
											fontWeight: "200",
											fontFamily: "Gotham",
											marginLeft: 10,
										}}>
										Gantt
									</Text>
								</View>
							</View>
						</TouchableOpacity>
						<TouchableOpacity
							style={{
								marginHorizontal: 10,
								opacity:
									this.state.currentPage === "Tasks"
										? 0.2
										: 1,
							}}
							onPress={() => {
								this.setState({
									currentPage: "Tasks",
									hover: true,
								});
							}}>
							<View
								style={{
									padding: 10,
									backgroundColor: APP_COLOURS.BG4,
									borderRadius: 15,
									// minWidth: 150,
								}}>
								<View
									style={{
										marginRight: 15,
										marginLeft: 15,
										flexDirection: "row",
										alignItems: "center",
									}}>
									<AutoHeightImage
										width={25}
										source={require("../../../assets/images/icons/tasks.png")}
									/>
									<Text
										style={{
											color: APP_COLOURS.TEXTCOLOR,
											fontSize: 16,
											fontWeight: "200",
											fontFamily: "Gotham",
											marginLeft: 10,
										}}>
										Tasks
									</Text>
								</View>
							</View>
						</TouchableOpacity>
						{/* <TouchableOpacity
							style={{
								marginHorizontal: 10,
							}}
							onPress={() => {
								this.setState({
									showTodayView: !this.state.showTodayView,
								});
							}}>
							<View
								style={{
									padding: 10,
									backgroundColor: APP_COLOURS.BG4,
									borderRadius: 15,
								}}>
								{this.state.currentPage === "gantt" ? (
									<View
										style={{
											marginRight: 15,
											marginLeft: 15,
										}}>
										<AutoHeightImage
											width={25}
											source={require("../../../assets/images/icons/settings.png")}
										/>
									</View>
								) : (
									<View
										style={{
											marginRight: 15,
											marginLeft: 15,
										}}>
										<AutoHeightImage
											width={25}
											source={require("../../../assets/images/icons/settings.png")}
										/>
									</View>
								)}
							</View>
						</TouchableOpacity> */}
						{/* {this.state.loading ? null : (
							<TouchableOpacity
								style={{
									// marginRight: 15,

									backgroundColor: APP_COLOURS.BG4,
									borderRadius: 15,
									flexDirection: "row",
									paddingVertical: 12,
									alignItems: "center",
									paddingRight: 17,
									// borderWidth: 0.5,
									// borderColor: APP_COLOURS.BG2,
								}}
								onPress={async () => {
									this.setState({
										addNewProject:
											!this.state.addNewProject,
									});
								}}>
								<Text
									style={{
										color: APP_COLOURS.TEXTCOLOR,
										fontSize: 16,
										fontWeight: "200",
										fontFamily: "Gotham",
										marginLeft: 10,
									}}>
									+
								</Text>
								<Text
									style={{
										color: APP_COLOURS.TEXTCOLOR,
										fontSize: 16,
										fontWeight: "400",
										fontFamily: "Gotham",
										marginLeft: 10,
									}}>
									New
								</Text>
							</TouchableOpacity>
						)} */}
						{/* <View
								style={{
									height: this.state.showInput ? "auto" : 0.5,
									// display: this.state.showInput ? 'flex' : 'none',
									// padding: this.state.showInput ? 0.5 : 0,
									width: "100%",
									backgroundColor: APP_COLOURS.BLACK_TRANS,
									borderRadius: 13,
								}}>
								{this.state.showInput ? (
									<TextInput
										style={{
											fontWeight: "400",
											fontFamily: "Gotham",

											width: "100%",
											paddingLeft: 15,
											padding: 10,
											fontSize: 15,
											borderRadius: 13,
											marginRight: 15,
											color: APP_COLOURS.BG2,
											// backgroundColor:
											// 	APP_COLOURS.WHITE_TRANSPARENT4,
											backgroundColor: "#FFF",
											fontWeight: "500",
										}}
										placeholderTextColor={"#797979"}
										autoCapitalize='sentences'
										clearButtonMode='while-editing'
										autoCompleteType='off'
										editable={true}
										autoCorrect={true}
										autoFocus
										keyboardType='default'
										textContentType='none'
										placeholder={` Search `}
										// defaultValue={this.state.user_data.email}
										defaultValue={this.state.filterSearch}
										onChangeText={(text) => {
											console.log(text);
											this.setState({
												filterSearch: text,
											});
										}}
									/>
								) : null}
							</View> */}
					</View>
					<View style={{}}>
						{/* <Lottie
							options={{
								loop: true,
								autoplay: true,
								animationData: animationData,
								rendererSettings: {
									preserveAspectRatio: "xMidYMid slice",
								},
							}}
							height={150}
							width={200}
						/> */}
					</View>
				</View>

				<View
					style={{
						flex: 1,
						flexDirection: "row",
					}}>
					<View
						style={{
							flex: 1,
							// marginTop: 10,
							// marginRight: 10,
						}}>
						{this.state.currentPage === "projects" ? (
							<View
								style={{
									flex: 1,
									borderRadius: 14,
									flexDirection: "row",
								}}>
								{this.projectsListComponent()}
								<View
									style={{
										flex: 1,
										display: this.state.hover
											? "none"
											: "flex",
									}}>
									{this.state.selectedProject
										? this.projectComponent(
												this.state.selectedProject,
										  )
										: null}
								</View>
								{/* <PrivateChats
									user_data={this.state.user_data}
								/> */}
							</View>
						) : null}
						{this.state.currentPage === "Tasks" ? (
							<View
								style={{
									flex: 1,
									borderRadius: 14,
									flexDirection: "row",
								}}>
								{this.allTasksComp(12, 20)}
							</View>
						) : null}
						{this.state.currentPage === "gantt" ? (
							<View
								style={{
									flex: 1,
									justifyContent: "flex-start",
									// backgroundColor: "#FFF",
									borderRadius: 13,
								}}>
								<GanttComponent
									user_data={this.state.user_data}
									projects={this.state.projects}
									milestones={this.state.milestones}
									tasks={this.state.tasks}
									logOut={() => {
										alert("logout");
									}}
									reloadData={async () => {
										// this.getDeviceData();

										await this.getAllProjectTasks();
									}}
								/>
							</View>
						) : null}
					</View>

					<View
						style={{
							maxWidth: 300,
							width: this.state.showTodayView ? "100%" : 20,
							height: "100%",
							// position: "absolute",
							right: this.state.showTodayView ? 0 : -300,

							// backgroundColor: APP_COLOURS.BG,
							zIndex: 999,
							// display: this.state.addProjectTask ? 'flex' : 'none',
							borderTopLeftRadius: 15,
							borderTopRightRadius: 15,
							flexDirection: "row",
							overflow: "hidden",
							// shadowColor: "#000",
							// shadowOffset: {
							// 	width: 0,
							// 	height: 4,
							// },
							// shadowOpacity: 0.23,
							// shadowRadius: 4.65,
							// elevation: 8,
						}}>
						<TouchableOpacity
							onPress={() =>
								this.setState({
									showTodayView: !this.state.showTodayView,
								})
							}>
							<View
								style={{
									width: 10,
									// backgroundColor:APP_COLOURS,
									justifyContent: "center",
									alignItems: "center",
									height: "100%",
								}}>
								<View
									style={{
										height: 60,
										width: 5,
										backgroundColor: APP_COLOURS.BG2,
										borderRadius: 4,
									}}
								/>
							</View>
						</TouchableOpacity>
						{/* {this.state.showTodayView ? (
								<TodayTimeline
									//   {...this.state}
									tasks={this.state.projects.filter(
										(project) => {
											return moment(
												project.due_date,
											).isSame(moment(), "day");
										},
									)}
									close={() =>
										this.setState({
											showTodayView: false,
										})
									}
								/>
							) : ( */}
						<View
							style={{
								flex: 1,
								padding: 10,
								backgroundColor: "#FFF",
								borderRadius: 14,
								// justifyContent: "space-between",
							}}>
							<Text
								style={{
									color: APP_COLOURS.BG2,
									fontSize: 19,
									fontWeight: "600",
									fontFamily: "Gotham",
									width: "100%",
									// textAlign: "center",e
								}}>
								Event Calendar
							</Text>
							<View
								style={{
									backgroundColor: "#FFF",
									padding: 10,
									borderRadius: 14,
								}}>
								<Image
									style={{
										width: 230,
										height: 230,
										resizeMode: "contain",
									}}
									source={require("../../../assets/images/market-analysis.png")}
								/>
							</View>

							{/* <View
								style={{
									width: 280,
								}}>
								<EventCalendar
									compWidth={280}
									dateSelected={async (d) => {
										//   await this.setState({
										//     dateSelected: d,
										//     showFullCalendar: false,
										//   });
										//   await this.generateCalendar();
									}}
									calendarList={this.state.projects}
									selectedDate={moment()}
								/>
							</View> */}
						</View>

						{/* )} */}
					</View>
				</View>

				{this.state.addNewProject ? (
					<AddProject
						{...this.state}
						uniqueProjectTitles={this.state.uniqueProjectTitles.filter(
							(d) =>
								this.state.filterTab !== ""
									? d === this.state.filterTab
									: d,
						)}
						selectedProject={this.state.filterTab}
						addEntry={(newProject) => {
							let user_data = JSON.parse(
								localStorage.getItem("user_data"),
							);
							// 							sendDiscordMessage(
							// 								`${user_data.first_name} ${user_data.last_name}`,
							// 								`[New Project]
							// - Brand: ${newProject.brand.title}
							// - Title: ${newProject.title}
							// - Start: ${moment(newProject.start_date).format("DD/MM/YY")}
							// 							`,
							// 								`${window.location.origin}${
							// 									Avatars[parseInt(user_data.avatar)].pp
							// 								}`,
							// 								"0QPZYrY6zpAnJXqX9gQl",
							// 							);

							// 							if (user_data.id !== "0QPZYrY6zpAnJXqX9gQl") {
							// 								sendDiscordMessage(
							// 									`${user_data.first_name} ${user_data.last_name}`,
							// 									`[New Project]
							// - Brand: ${newProject.brand.title}
							// - Title: ${newProject.title}
							// - Start: ${moment(newProject.start_date).format("DD MMM YYYY")}

							// 							`,
							// 									`${window.location.origin}${
							// 										Avatars[parseInt(user_data.avatar)].pp
							// 									}`,
							// 									user_data.id,
							// 								);

							// 								crudSendUserIdEmail(
							// 									"SKrum  <skrum@tetrice.co.za>",
							// 									user_data.id,
							// 									`[New Project]: ${newProject.title}`,
							// 									`You've created a project <br> <h3> <br> [New Project] <br>
							// - Brand: ${newProject.brand.title} <br>
							// - Title: ${newProject.title} <br>
							// - Start: ${moment(newProject.start_date).format("DD MMM YYYY")} <br>

							// 			`,
							// 								);
							// 							}

							crudSendUserIdEmail(
								"SKrum  <skrum@tetrice.co.za>",
								"0QPZYrY6zpAnJXqX9gQl",
								`[New Project]: ${newProject.title}`,
								`${newProject.title}`,
								`${user_data.first_name} ${
									user_data.last_name
								} created a new project <br>
- Brand: ${newProject.brand.title} <br>
- Title: ${newProject.title} <br>
- Date: ${moment(newProject.start_date).format("DD MMM YYYY")}

			`,
								`${user_data.first_name} ${
									user_data.last_name
								} created a new project <br>
- Brand: ${newProject.brand.title} <br>
- Title: ${newProject.title} <br>
- Date: ${moment(newProject.start_date).format("DD MMM YYYY")}

			`,
								`${window.location.origin}${
									Avatars[parseInt(user_data.avatar)].pp
								}`,
								`${window.location.origin}/home/projects?pid=${newProject.id}`,
							);

							// return;
							crudCreate(
								this.state.crudDocumentName,
								newProject,
								async () => {
									await this.getAllProjectsUser(false);
									await this.setState((prevState) => ({
										// projects: [
										// 	...prevState.projects,
										// 	newProject,
										// ],
										addNewProject: false,
										filterSearch: newProject.title,
										loading: false,
										hover: true,
									}));
								},
							);
						}}
						close={() =>
							this.setState({
								addNewProject: false,
							})
						}
					/>
				) : null}

				{/* {this.state.loadingDeeplink ? (
					<View
						style={{
							flex: 1,
							backgroundColor: APP_COLOURS.BG2,
							// width: window.innerWidth,
							// height: window.innerHeight,
							justifyContent: "center",
							alignItems: "center",
							position: "absolute",
							zIndex: 999,
						}}>
						<View
							style={{
								// width: window.innerWidth,
								// height: window.innerHeight,
								justifyContent: "center",
								alignItems: "center",
								padding: 10,
							}}>
							<View
								style={{
									justifyContent: "center",
									alignItems: "center",
								}}>
								<View
									style={{
										flexDirection: "row",
										alignItems: "center",
									}}>
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
										}}>
										<AutoHeightImage
											width={200}
											source={require("../../../assets/images/skrum_logo.png")}
										/>
									</View>
								</View>
								<ActivityIndicator color='#FFF' />
							</View>
						</View>
					</View>
				) : null} */}

				{this.state.loading ? (
					<Modal
						animationType='none'
						transparent={true}
						visible={true}>
						<View
							style={{
								flex: 1,
								justifyContent: "flex-start",
								alignItems: "center",
								// backgroundColor:
								// 	"rgba(52, 52, 52, 0.6)",
								backgroundColor: "#171A20",
								opacity: 0.8,
							}}>
							<View
								style={{
									flex: 1,
									justifyContent: "center",
									alignItems: "center",
								}}>
								<View
									style={{
										// flex: 1,
										width: 300,
										height: 250,
										// backgroundColor:
										// 	APP_COLOURS.BG2,
										justifyContent: "center",
										alignItems: "center",
										borderRadius: 15,
									}}>
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
											marginBottom: 40,
										}}>
										<Image
											source={require("../../../assets/images/icons/skrum_menu.png")}
											style={{
												width: 350,
												height: 100,
												resizeMode: "contain",
												// marginRight: 3,
											}}
										/>
									</View>
								</View>
							</View>
						</View>
					</Modal>
				) : null}
			</View>
		);
	}

	projectsListComponent = () => {
		let fs = 10;
		let size = fs * 1.8;

		// ||
		// 			p.created_by === this.state.selecteduserFilter

		let projectsFiltered = this.state.projects.filter((p) => {
			if (this.state.selecteduserFilter !== "") {
				if (
					p.assignment_ids.includes(this.state.selecteduserFilter.id)
				) {
					return p;
				} else {
					return;
				}
			} else {
				return p;
			}
		});
		return (
			<View
				onLayout={() => {
					this.deepLinkToProject();
				}}
				style={{
					// width: '100%',
					flex: this.state.hover ? 1 : "",
					// height: this.props.screenHeight,
					justifyContent: "flex-start",
					// alignItems: "center",
					// backgroundColor: "rgba(0, 0, 0, 0.9)",
					backgroundColor: "#FFF",

					borderRadius: 15,
					// width: 400,
					marginRight: 10,
					// maxWidth: window.innerWidth * 0.8,
					flexDirection: "row",

					// maxWidth: 1100,
					// marginHorizontal: "auto",
				}}>
				<TouchableOpacity
					style={{
						// width: "100%",
						// height: "auto",
						// paddingHorizontal: 5,
						alignItems: "center",
						borderRadius: 15,
						// minHeight: 70,
						// marginBottom: 10,
						backgroundColor: APP_COLOURS.BG4,
						flexDirection: "row",
						borderColor: "#C5C5C5",
						display: this.state.hover ? "none" : "flex",
					}}
					onPress={() => {
						this.setState({
							hover: true,
						});

						// this.setState({
						// 	selectedProject: "",
						// 	selectedAssignment: "",
						// 	subPage: "",
						// 	hover: false,
						// });
					}}>
					<View
						// onMouseEnter={() => this.setState({ hover: true })}
						// onMouseLeave={() => this.setState({ hover: false })}
						style={{
							width: "auto",
							height: "100%",
							backgroundColor: APP_COLOURS.BG4,
							padding: 5,
							zIndex: 99,
							borderRadius: 20,
							// marginRight: 5,
						}}>
						{typeof this.props.user_data?.avatar !== "undefined" ? (
							<Image
								source={
									Avatars[
										parseInt(this.props.user_data?.avatar)
									].pp
								}
								style={{
									width: 36,
									height: 36,
									borderRadius: 18,
									resizeMode: "contain",
									backgroundColor: "#FFF",
									borderWidth: 2,
									borderColor: "#FFF",
									shadowColor: "#ECF4FF",
									shadowOffset: {
										width: 0,
										height: 8,
									},
									shadowOpacity: 0.44,
									shadowRadius: 10.32,
									elevation: 16,
								}}
							/>
						) : null}

						<View
							style={{
								justifyContent: "center",
								alignItems: "center",

								flex: 1,
							}}>
							<Image
								style={{
									width: 30,
									height: 30,
									resizeMode: "contain",
								}}
								source={
									require("../../../assets/images/rightArrow.svg")
										.default
								}
							/>
						</View>
					</View>
				</TouchableOpacity>
				<View
					style={{
						flex: 1,
						display: this.state.hover ? "flex" : "none",
						padding: 5,
					}}>
					<View
						style={{
							// flex: 1,
							width: "100%",
							flexDirection: "row",
							alignItems: "center",
							// height: 45,

							paddingLeft: 5,
							// marginBottom: 6,
						}}>
						{/* {this.state.showInput ? ( */}
						<View
							style={{
								// flex: 1,
								marginRight: 10,
								// marginLeft: 10,
								alignItems: "center",
								justifyContent: "center",
								// padding: 5,
								// backgroundColor: "#FFF",
								borderRadius: 15,
								// minWidth: 150,
							}}>
							<TouchableOpacity
								style={{
									width: "100%",
									// height: "auto",
									paddingHorizontal: 5,
									alignItems: "center",
									borderRadius: 10,
									// minHeight: 70,
									// marginBottom: 10,
									flexDirection: "row",
									borderColor: "#C5C5C5",
								}}
								onPress={() => {
									this.setState({
										// showInput: true,
										// filterSearch: `${this.props.user_data.first_name} ${this.props.user_data.last_name}`,

										selecteduserFilter:
											this.props.user_data,
									});
								}}>
								{typeof this.props.user_data.avatar !==
								"undefined" ? (
									<Image
										source={
											Avatars[
												parseInt(
													this.props.user_data.avatar,
												)
											].pp
										}
										style={{
											width: 36,
											height: 36,
											borderRadius: 18,
											resizeMode: "contain",
											backgroundColor: "#FFF",
											borderWidth: 2,
											borderColor: "#FFF",
											shadowColor: "#ECF4FF",
											shadowOffset: {
												width: 0,
												height: 8,
											},
											shadowOpacity: 0.44,
											shadowRadius: 10.32,
											elevation: 16,
										}}
									/>
								) : null}
								<View
									style={{
										padding: 5,
										borderRadius: 10,
										alignItems: "center",
										flexDirection: "column",
										flex: 1,
										// alignItems: "center",
										alignItems: "flex-start",
									}}>
									<Text
										style={{
											fontFamily: "Gotham",
											fontSize: 14,
											fontWeight: "600",
											color: APP_COLOURS.BG2,
										}}>
										{this.props.user_data.first_name}{" "}
										{this.props.user_data.last_name}
									</Text>
									<Text
										style={{
											fontFamily: "Gotham",
											fontSize: 10,
											opacity: 0.8,
											color: APP_COLOURS.BG2,
										}}>
										{this.state.user_data.username}
									</Text>
								</View>
							</TouchableOpacity>
						</View>
						<View
							style={{
								flex: 1,
							}}>
							<UsersSelectCard
								user_data={this.state.user_data}
								selectedUser={(selectedUser) => {
									console.log(selectedUser);
									this.setState({
										selecteduserFilter: selectedUser,
									});
								}}
							/>
						</View>
						<View
							style={{
								// height: "auto",
								// width: "100%",
								// flex: 1,
								// minWidth: 200,
								// maxWidth: 400,
								backgroundColor: APP_COLOURS.BLACK_TRANS,
								borderRadius: 13,
							}}>
							<TextInput
								style={{
									fontWeight: "400",
									fontFamily: "Gotham",

									// width: "100%",
									paddingLeft: 15,
									padding: 10,
									fontSize: 15,
									borderRadius: 13,
									marginRight: 15,
									color: APP_COLOURS.BG2,
									// backgroundColor:
									// 	APP_COLOURS.WHITE_TRANSPARENT4,
									backgroundColor: "#E8E8E8",
									fontWeight: "500",
								}}
								placeholderTextColor={"#797979"}
								autoCapitalize='sentences'
								clearButtonMode='while-editing'
								autoCompleteType='off'
								editable={true}
								autoCorrect={true}
								autoFocus
								keyboardType='default'
								textContentType='none'
								placeholder={` Search `}
								// defaultValue={this.state.user_data.email}
								defaultValue={this.state.filterSearch}
								onChangeText={(text) => {
									console.log(text);
									this.setState({
										filterSearch: text,
									});
								}}
							/>
						</View>
						{/* ) : ( */}

						{/* )} */}

						<TouchableOpacity
							style={{
								marginRight: 10,
								marginLeft: 10,
							}}
							onPress={() =>
								this.setState({
									showInput: !this.state.showInput,
									filterSearch: "",
								})
							}>
							<AutoHeightImage
								width={19}
								source={require("../../../assets/images/searchIcon.png")}
							/>
						</TouchableOpacity>

						{this.state.loading ? (
							<ActivityIndicator color={APP_COLOURS.PRIMARY} />
						) : null}
						{/* {this.state.user_data.username ===
					"wesley@tetrice.co.za" ? ( */}
						<TouchableOpacity
							style={{
								// marginRight: 15,
								marginLeft: 15,
								// backgroundColor: APP_COLOURS.BG2,
								borderRadius: 15,
								flexDirection: "row",
								paddingVertical: 10,
								alignItems: "center",
								paddingHorizontal: 15,
								borderWidth: 0.5,
								borderColor: APP_COLOURS.BG2,
							}}
							onPress={async () => {
								// this.setState({
								// 	addNewService: !this.state.addNewService,
								// });
								this.setState({
									addNewProject: !this.state.addNewProject,
								});

								// 					crudSendEmail(
								// 						"SKrum  <skrum@tetrice.co.za>",
								// 						"wesley@tetrice.co.za",
								// 						"Project assignment",
								// 						`You've been added to a project: sdvsd
								// <br>
								// <br>Be sure to check for tasks that are allocated to you.
								// `,
								// 					);
							}}>
							<Text
								style={{
									color: APP_COLOURS.BG2, // "#FFF",
									fontSize: 16,
									marginRight: 10,
								}}>
								+
							</Text>
							<Text
								style={{
									color: APP_COLOURS.BG2,
									fontSize: 16,
									fontWeight: "800",
									fontFamily: "Gotham",
								}}>
								New Project
							</Text>
						</TouchableOpacity>
						{/* ) : null} */}

						<TouchableOpacity
							onPress={() => {
								this.getAllProjectsUser(
									!this.state.archiveView,
								);
							}}>
							<View
								style={{
									padding: 10,
									borderRadius: 15,
									borderWidth: 0.5,
									borderColor: APP_COLOURS.BG2,
								}}>
								<Text
									style={{
										color: APP_COLOURS.BG2,
										fontSize: 16,
										fontWeight: "800",
										fontFamily: "Gotham",
									}}>
									{this.state.archiveView
										? "All projects"
										: "View Archive"}
								</Text>
							</View>
						</TouchableOpacity>
					</View>

					<View
						style={{
							flex: 1,
							// width: "100%",
							borderRadius: 5,
						}}>
						<View
							style={{
								marginBottom: 10,
							}}>
							<View
								style={{
									// display: this.state.showProjects
									// 	? "flex"
									// 	: "none",
									display: "none",
								}}>
								<ScrollView
									horizontal
									showsHorizontalScrollIndicator={false}>
									<View
										style={{
											flexDirection: "row",
											marginBottom: 5,
										}}>
										<TouchableOpacity
											style={{
												display:
													this.state.filterTab !== ""
														? "flex"
														: "none",
											}}
											onPress={() =>
												this.setState({
													filterTab: "",
													projects:
														this.state.projects,
												})
											}>
											<View
												style={{
													width: "auto",
													//  height: 50,
													justifyContent: "center",
													alignItems: "center",

													padding: 10,
													borderRadius: 12,
													marginRight: 5,
												}}>
												<Text
													style={{
														fontSize: fs,
														color: APP_COLOURS.BG2,
														fontWeight: "400",
														fontFamily: "Gotham",
													}}>
													All
												</Text>
											</View>
										</TouchableOpacity>
										{this.state.uniqueProjectTitles
											.filter((d_) => {
												let filter = d_;

												if (
													typeof this.state
														.filterSearch !==
														"undefined" &&
													this.state.filterSearch !==
														""
												) {
													let text_ =
														this.state.filterSearch;
													var outString =
														text_.replace(
															/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
															"",
														);

													let join_names = `${d_}`;

													join_names =
														join_names.replace(
															/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
															"",
														);

													return join_names
														.toLowerCase()
														.includes(
															outString.toLowerCase(),
														);
												}

												return filter;
											})
											.map((d, i) => (
												<TouchableOpacity
													key={i}
													style={{}}
													onPress={() =>
														this.setState({
															filterTab: d,
															projects:
																this.state
																	.projects,
														})
													}>
													<View
														style={{
															width: "auto",
															//   height: 50,
															justifyContent:
																"center",
															alignItems:
																"center",
															backgroundColor:
																this.state
																	.filterTab ===
																d
																	? BLUE
																	: APP_COLOURS.WHITE_TRANSPARENT9,
															padding: 10,
															borderRadius: 10,
															marginRight: 5,
														}}>
														<Text
															style={{
																fontSize: fs,
																color:
																	this.state
																		.filterTab ===
																	d
																		? "#FFF"
																		: APP_COLOURS.BG2,
																fontWeight:
																	"500",
															}}>
															{d}
														</Text>
													</View>
												</TouchableOpacity>
											))}
									</View>
								</ScrollView>
							</View>
						</View>

						<View
							style={{
								flex: 1,
							}}>
							<ScrollView
								ref={(ref) => (this.scrollView2 = ref)}
								scrollEventThrottle={16}
								showsVerticalScrollIndicator={false}
								contentContainerStyle={{}}>
								<View
									style={{
										flex: 1,

										paddingBottom: this.state.keyboardActive
											? 300
											: 300,
									}}>
									{this.state.projects.length > 0 ? null : (
										<View
											style={{
												flex: 1,
												justifyContent: "center",
												alignItems: "center",
												backgroundColor:
													APP_COLOURS.DESELECTEDCOLOR,
												borderRadius: 10,
												padding: "5%",
											}}>
											{this.state.loading ? (
												<ActivityIndicator />
											) : (
												<Text
													style={{
														fontWeight: "600",
													}}>
													No projects to display
												</Text>
											)}
										</View>
									)}
									{/* .sort((a, b) => {
											if (a.createdAt === "") return 1;
											if (b.createdAt === "") return -1;
											return (
												new Date(a.createdAt) -
												new Date(b.createdAt)
											);
										})
										.sort((a, b) => {
											if (a.due_date === "") return 1;
											if (b.due_date === "") return -1;
											return (
												new Date(a.due_date) -
												new Date(b.due_date)
											);
										}) */}
									<View
										style={{
											width: "100%",
											flexDirection: "row",
										}}>
										{/* {this.projectsTabs(
											projectsFiltered.filter(
												(f) => f.status === "Urgent",
											),
											"Urgent",
										)}
										{this.projectsTabs(
											projectsFiltered.filter(
												(f) => f.status === "Completed",
											),
											"Completed",
										)} */}
									</View>

									{this.projectSearchandReturn(
										projectsFiltered.filter(
											(f) => f.status === "Urgent",
										),
										"Urgent",
									)}

									{this.projectSearchandReturn(
										projectsFiltered.filter(
											(f) =>
												f.status ===
												"Attention Required",
										),
										"Attention Required",
									)}

									{this.projectSearchandReturn(
										projectsFiltered.filter(
											(f) => f.status === "In Progress",
										),
										"In Progress",
									)}

									{this.projectSearchandReturn(
										projectsFiltered.filter(
											(f) => f.status === "Design",
										),
										"Design",
									)}

									{this.projectSearchandReturn(
										projectsFiltered.filter(
											(f) => f.status === "Ongoing",
										),
										"Ongoing",
									)}

									{this.projectSearchandReturn(
										projectsFiltered.filter(
											(f) =>
												f.status ===
												"Under Review by client",
										),
										"Under Review by client",
									)}

									{this.projectSearchandReturn(
										projectsFiltered.filter(
											(f) =>
												f.status !== "Completed" &&
												f.status !== "Urgent" &&
												f.status !== "Cancelled" &&
												f.status !== "Paused" &&
												f.status !== "Design" &&
												f.status !== "In Progress" &&
												f.status !== "Ongoing" &&
												f.status !==
													"Attention Required" &&
												f.status !==
													"Under Review by client",
										),
										"All",
									)}
									{this.projectSearchandReturn(
										projectsFiltered.filter(
											(f) => f.status === "Paused",
										),
										"Paused",
									)}
									{this.projectSearchandReturn(
										projectsFiltered.filter(
											(f) => f.status === "Completed",
										),
										"Completed",
									)}
									{this.projectSearchandReturn(
										projectsFiltered.filter(
											(f) => f.status === "Cancelled",
										),
										"Cancelled",
									)}
								</View>
							</ScrollView>
						</View>

						{this.state.showChat ? (
							<View
								style={{
									position: "absolute",
									top: this.state.yPos + 10,
									left: this.state.xPos - 270,
									width: 250,
									height: 290,
									backgroundColor: APP_COLOURS.BG,
									borderRadius: 15,
									padding: 10,
									zIndex: 999,
									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 4,
									},
									shadowOpacity: 0.23,
									shadowRadius: 4.65,
									elevation: 8,
								}}>
								<View
									style={{
										flex: 1,
									}}>
									<ScrollView>
										<View
											style={{
												flex: 1,
											}}>
											{this.state.selectedChat.messages.map(
												(message, messageIndex) => (
													<View
														key={messageIndex}
														style={{
															padding: 2,
															marginBottom: 1,
														}}>
														<Text
															style={{
																color: APP_COLOURS.BG2,
																fontSize: fs,
															}}>
															<Text
																style={{
																	color: APP_COLOURS.BLUE,
																}}>
																{
																	message
																		.createdBy
																		.first_name
																}
																:
															</Text>{" "}
															{message.text}
														</Text>
													</View>
												),
											)}
										</View>
									</ScrollView>
									<View
										style={{
											// height: 50,
											flexDirection: "row",
											alignItems: "center",
											// padding: 10,
										}}>
										<TextInput
											style={{
												flex: 1,
												fontSize: 16,
												color: "#F3F3F5",
												fontWeight: "400",
												fontFamily: "Gotham",
												width: "100%",
												paddingLeft: 10,
												padding: 10,
												borderRadius: 7,
												borderColor: "#383D43",
												borderWidth: 1,
											}}
											onChangeText={(text) =>
												this.handleNewMessageChange(
													text,
													this.state.selectedID,
												)
											}
											value={
												this.state.selectedChat
													.newMessage
											}
											onSubmitEditing={() =>
												this.handleMessageSubmit(
													this.state.selectedID,
												)
											}
										/>
									</View>
								</View>
							</View>
						) : null}

						{this.state.showSubTasks ? (
							<View
								style={{
									position: "absolute",
									top: this.state.yPos + 10,
									left: this.state.xPos - 270,

									zIndex: 999,
								}}>
								<View
									style={{
										width: 250,
										height: 220,
										marginBottom: 5,
										borderRadius: 15,
										padding: 10,
										marginTop: 5,
										backgroundColor: APP_COLOURS.BG,
										shadowColor: "#000",
										shadowOffset: {
											width: 0,
											height: 4,
										},
										shadowOpacity: 0.23,
										shadowRadius: 4.65,
										elevation: 8,
									}}>
									<View
										style={{
											width: "100%",
											flexDirection: "row",
										}}>
										<View
											style={{
												width: "100%",
												flexDirection: "row",
												alignItems: "center",
												padding: 0,
											}}>
											<ProgressBar
												progress={`${this.state.selectedSubtasks.progress}%`}
												color={APP_COLOURS.GREEN}
												inProgress={
													parseFloat(
														this.state
															.selectedSubtasks
															.progress,
													) > 0
												}
											/>
										</View>
									</View>
									<View
										style={{
											flex: 1,
										}}>
										<ScrollView>
											<View
												style={{
													width: "100%",
													borderRadius: 13,
													backgroundColor:
														"rgba(52, 52, 52, 0.12)",
													// backgroundColor: APP_COLOURS.BG2,
													padding: 10,
													marginBottom: 25,
													opacity: 0.9,
												}}>
												{this.state.selectedSubtasks.subtasks.map(
													(s, ii) => {
														return (
															<CheckBox
																key={ii}
																title={s.title}
																fontSize={16}
																checked={
																	s.status
																}
																checkBoxStyle={
																	3
																}
																checkedItem={async () => {
																	this.toggleSubtaskStatus(
																		s.id,
																		ii,
																		!s.status,
																	);
																}}
																delete={() => {
																	this.removeSubtask(
																		this
																			.state
																			.selectedID,
																		s.id,
																	);
																}}
															/>
														);
													},
												)}
											</View>
										</ScrollView>
									</View>
									<TaskerInput
										onTextChange={(txt) => {
											let obj = {
												title: txt,
												status: false,
												id: uuid(),
											};
											this.addSubtask(obj);
										}}
									/>
								</View>
							</View>
						) : null}

						{this.state.showModalArea !== "" ? (
							<Modal
								animationType='fade'
								transparent={true}
								visible={true}>
								<View
									style={{
										width: this.props.screenWidth,
										height: this.props.screenHeight,
										backgroundColor:
											"rgba(52, 52, 52, 0.6)",
									}}>
									<TouchableOpacity
										activeOpacity={1}
										style={{
											width: this.props.screenWidth,
											height: this.props.screenHeight,
											backgroundColor:
												"rgba(52, 52, 52, 0.6)",
											zIndex: 10,
											position: "absolute",
											top: 0,
										}}
										onPress={() => {
											this.setState({
												showModalArea: "",
											});
										}}
									/>
									{this.state.showModalArea ===
									"projectassignment" ? (
										<View
											style={{
												top: this.state.yPos + 10,
												left: this.state.xPos - 270,
												width: 350,
												height: 300,
												// backgroundColor: APP_COLOURS.BG,
												backgroundColor: "#FFF",
												borderRadius: 15,
												padding: 10,
												zIndex: 999,
												shadowColor: "#000",
												shadowOffset: {
													width: 0,
													height: 4,
												},
												shadowOpacity: 0.23,
												shadowRadius: 4.65,
												elevation: 8,
											}}>
											<UsersSelectList
												assignment={
													this.state.selectedProject
														.assignment
												}
												assignment_ids={
													this.state.selectedProject
														.assignment_ids
												}
												isStandalone={true}
												addAssignment={(ass, ids) => {
													this.setState({
														showModalArea: "",
													});
													this.addAssignment(
														ass,
														ids,
													);
													console.log(ass, ids);
												}}
											/>
										</View>
									) : null}
									{/* {this.state.showModalArea ===
								"taskassignment" ? (
									<View
										style={{
											top: this.state.yPos + 10,
											left: this.state.xPos - 270,
											width: 350,
											height: 300,
											// backgroundColor: APP_COLOURS.BG,
											backgroundColor: "#FFF",
											borderRadius: 15,
											padding: 10,
											zIndex: 999,
											shadowColor: "#000",
											shadowOffset: {
												width: 0,
												height: 4,
											},
											shadowOpacity: 0.23,
											shadowRadius: 4.65,
											elevation: 8,
										}}>
										<UsersSelectList
											assignment={
												this.state.selectedTask
													.assignment
											}
											assignment_ids={
												this.state.selectedTask
													.assignment_ids
											}
											isStandalone={true}
											addAssignment={(ass, ids) => {
												this.setState({
													showModalArea: "",
												});
												this.addAssignment(ass, ids);
												//  console.log(ass, ids);
											}}
										/>
									</View>
								) : null} */}
									{this.state.showModalArea ===
									"dateChange" ? (
										<View
											style={{
												position: "absolute",
												top: this.state.yPos + 10,
												left: this.state.xPos - 270,
												width: 330,
												height: "auto",
												backgroundColor: "#20242B",
												borderRadius: 15,
												padding: 10,
												zIndex: 999,
												shadowColor: "#000",
												shadowOffset: {
													width: 0,
													height: 4,
												},
												shadowOpacity: 0.23,
												shadowRadius: 4.65,
												elevation: 8,
											}}>
											<ChangeDate
												date={
													this.state.selectedTask
														.due_date
												}
												changeDate={(date) => {
													this.setState({
														showModalArea: "",
													});
													this.handleDateChange(
														date,
														this.state.selectedID,
													);
												}}
												close={() =>
													this.setState({
														showModalArea: "",
													})
												}
											/>
										</View>
									) : null}
								</View>
							</Modal>
						) : null}

						{/* {this.state.showDateChange ? ( */}
						{/* <View
						style={{
							position: "absolute",
							top: this.state.yPos + 10,
							left: this.state.xPos - 350,
							width: 330,
							height: "auto",
							backgroundColor: "#20242B",
							borderRadius: 15,
							padding: 10,
							zIndex: 999,
							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 4,
							},
							shadowOpacity: 0.23,
							shadowRadius: 4.65,
							elevation: 8,
						}}>
						<ChangeDate
							date={this.state.selectedTask.due_date}
							changeDate={(date) => {
								this.setState({
									showDateChange: false,
								});
								this.handleDateChange(
									date,
									this.state.selectedID,
								);
							}}
							close={() =>
								this.setState({
									showDateChange: false,
								})
							}
						/>
					</View> */}
						{/* ) : null} */}

						{/* <View
						style={{
							// flex: 1,
							height: "100%",
							position: "absolute",
							right: this.state.showTodayView ? 0 : -450,
							top: 0,
							width: "100%",
							maxWidth: 400,
							backgroundColor: APP_COLOURS.BG,
							zIndex: 999,
							// display: this.state.addProjectTask ? 'flex' : 'none',
							borderTopLeftRadius: 15,
							borderTopRightRadius: 15,
						}}>
						{this.state.showTodayView ? (
							<TodayTimeline
								//   {...this.state}
								tasks={this.state.projects.filter((project) => {
									return moment(project.due_date).isSame(
										moment(),
										"day",
									);
								})}
								close={() =>
									this.setState({
										showTodayView: false,
									})
								}
							/>
						) : (
							<View
								style={{
									width: 300,
								}}>
								<View
									style={{
										flex: 1,
										padding: 10,
										backgroundColor: "#FFF",
										borderRadius: 14,
										// justifyContent: "space-between",
									}}>
									<Text
										style={{
											color: APP_COLOURS.BG2,
											fontSize: 19,
											fontWeight: "600",
											fontFamily: "Gotham",
											width: "100%",
											// textAlign: "center",e
										}}>
										Event Calendar
									</Text>
									<View
										style={{
											backgroundColor: "#FFF",
											padding: 10,
											borderRadius: 14,
										}}>
										<Image
											style={{
												width: 230,
												height: 230,
												resizeMode: "contain",
											}}
											source={require("../../../assets/images/market-analysis.png")}
										/>
									</View>

									<View
										style={{
											width: 280,
										}}>
										<EventCalendar
											compWidth={280}
											dateSelected={async (d) => {
												//   await this.setState({
												//     dateSelected: d,
												//     showFullCalendar: false,
												//   });
												//   await this.generateCalendar();
											}}
											calendarList={this.state.projects}
											selectedDate={moment()}
										/>
									</View>
								</View>
							</View>
						)}
					</View> */}
					</View>
				</View>
			</View>
		);
	};

	projectSearchandReturn = (projects, title) => {
		let fs = 12;
		let size = fs * 1.8;
		let filteredprojects = projects
			.sort((a, b) => `${a.title} ${a.id}` > `${b.title} ${b.id}`)
			.sort((a, b) => b.priority - a.priority)

			.filter((d_) => {
				if (
					typeof this.state.filterTab !== "undefined" &&
					this.state.filterTab !== ""
				) {
					if (d_.brand?.title === this.state.filterTab) return d_;
				} else {
					return d_;
				}
			})
			.filter((d_) => {
				let filter = d_;

				if (
					typeof this.state.filterSearch !== "undefined" &&
					this.state.filterSearch !== ""
				) {
					let text_ = this.state.filterSearch;
					var outString = text_.replace(
						/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
						"",
					);

					let join_names = `${d_.title} ${d_.brand?.title}`;

					join_names = join_names.replace(
						/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
						"",
					);

					return join_names
						.toLowerCase()
						.includes(outString.toLowerCase());
				}

				return filter;
			});
		return (
			<View
				onLayout={() => {
					// if (filteredprojects.length >= 0) {
					// 	this.deepLinkToProject();
					// }
				}}
				style={{
					marginTop: 15,
					display: filteredprojects.length === 0 ? "none" : "flex",
				}}>
				<TouchableOpacity
					onPress={() => {
						this.setState({
							viewProjectsItems: title,
						});
					}}>
					<View
						style={{
							width: "100%",
							padding: 10,
							borderRadius: 8,
							backgroundColor: TaskStatus(title),
							flexDirection: "row",
						}}>
						<Text
							style={{
								fontSize: 30,
								color:
									title === "Design"
										? "#FFF"
										: APP_COLOURS.BG2,
								fontWeight: "600",
								fontFamily: "Gotham",
							}}>
							{filteredprojects.length}
						</Text>
						<Text
							style={{
								fontSize: fs,
								color:
									title === "Design"
										? "#FFF"
										: APP_COLOURS.BG2,
								fontWeight: "600",
								fontFamily: "Gotham",
							}}>
							{title}
						</Text>
					</View>
				</TouchableOpacity>
				<View
					style={{
						flex: 1,
						display: this.state.archiveView
							? "flex"
							: this.state.filterSearch !== ""
							? "flex"
							: this.state.viewProjectsItems === title
							? "flex"
							: "none",
					}}>
					{filteredprojects.map((d, i) =>
						this.projectLeftCardItem(d, i, fs, size),
					)}
				</View>
			</View>
		);
	};

	projectLeftCardItem = (d, i, fs, size) => {
		let tasksForProject = this.state.tasks.filter(
			(t) => t.project_id === d.id,
		);

		return (
			<View
				key={`item_${d.id}`}
				// onMouseEnter={() => this.setState({ hover: d.id })}
				// onMouseLeave={() => this.setState({ hover: "" })}
				style={{
					width: "100%",
					backgroundColor:
						this.state.hover === d.id
							? APP_COLOURS.WHITE_TRANSPARENT5
							: isOdd(i)
							? "transparent"
							: APP_COLOURS.WHITE_TRANSPARENT6,

					// backgroundColor: "#FFF",

					// opacity: d.status === "Completed" ? 0.3 : 1,
					// 	isOdd(i)
					// ? 0.9
					// : 1,
					zIndex: 10,
					// borderRadius: 10,
					// minHeight: 40,
					borderTopColor: "#E8E8E8",
					borderTopWidth: 0.5,

					borderLeftWidth:
						this.state.selectedProject?.id === d.id ? 5 : 0,
				}}>
				<View
					style={{
						width: "100%",
						flexDirection: "row",
						paddingLeft: 3,
						// maxWidth: 550,
					}}>
					<StatusItem
						{...this.props}
						{...d}
						fs={fs}
						handleStatusChange={async (_id, _status) => {
							let user_data = JSON.parse(
								localStorage.getItem("user_data"),
							);

							const pmp = await d.assignment_ids.forEach(
								async (id) => {
									await sendDiscordMessage(
										`${user_data.first_name} ${user_data.last_name}`,
										`[PROJECT Status Update]
### *${d.brand.title} - ${d.title}*
> *${_status}*
- url: ${window.location.origin}/home/projects?pid=${this.state.selectedProject.id}
`,
										`${window.location.origin}${
											Avatars[parseInt(user_data.avatar)]
												.pp
										}`,
										id,
									);
								},
							);

							const mapThrough = await d.assignment.forEach(
								async (e) => {
									await crudSendEmail(
										"SKrum  <skrum@tetrice.co.za>",
										e.username,
										`[${_status}]: ${d.title}`,
										`<p>${d.brand.title}</p><p>${d.title}</p><p>From ${d.status} to ${_status}</p><br>
								<br>Be sure to check for tasks that are allocated to you.
								<br>https://skrum.tetrice.co.za/
								`,
									);
								},
							);

							crudCreate(
								"projects_timeline",
								{
									project_id: d.id,
									text: _status,
									type: "Status",
									sendBy: {
										id: user_data.id,
										first_name: user_data.first_name,
										last_name: user_data.last_name,
									},
									dateCreated: moment().toISOString(),
									dateUnix: moment().unix(),
								},
								() => {},
							);

							this.handleStatusChange(_id, _status);
						}}
					/>

					<View
						style={{
							borderLeftWidth: 1,
							width: 0.5,
							opacity: 0.1,
						}}
					/>

					<View
						style={{
							borderLeftWidth: 1,
							width: 0.5,
							opacity: 0.1,
						}}
					/>

					<View
						style={{
							flex: 1,
						}}>
						<TouchableOpacity
							style={{
								flex: 1,
								// width: "100%",
								justifyContent: "center",

								// maxWidth: 1200,
								// minWidth: 250,
								width: this.state.hover ? "auto" : 50,
							}}
							onPress={async () => {
								await this.setState({
									selectedProject: "",
									selectedAssignment: "",
									subPage: "",
									hover: false,
								});

								await this.setState({
									selectedProject: d,
									selectedAssignment: [...d.assignment],
									subPage: "Overview",
									loading: true,
								});

								console.log(d);

								// if (
								// 	this.state.user_data.username !==
								// 	"wesley@tetrice.co.za"
								// ) {
								// 	let obj = {
								// 		project_id: d.id,
								// 		action: "Selected project",
								// 		data: d.title,
								// 		date_created: moment().toISOString(),
								// 		created_by: `${this.state.user_data.first_name} ${this.state.user_data.last_name}`,
								// 	};
								// 	crudCreate("projects_log", obj, () => {});
								// }
							}}>
							<View
								style={{
									flex: 1,
									padding: fs / 2,
									justifyContent: "center",
								}}>
								<Text
									ellipsizeMode='tail'
									numberOfLines={1}
									style={{
										color: APP_COLOURS.BG2,
										fontSize: 9,
										fontWeight: "500",
										opacity: 0.5,
									}}>
									{d.brand?.title}
								</Text>
								<Text
									ellipsizeMode='tail'
									numberOfLines={1}
									style={{
										width: "100%",
										color:
											this.state.hover === d.id
												? APP_COLOURS.BLUE2
												: APP_COLOURS.BG2,
										fontSize: 13,
										flexWrap: "wrap",
										fontWeight: "700",
									}}>
									{d.title}
								</Text>
							</View>
						</TouchableOpacity>
					</View>
					<View
						style={{
							borderLeftWidth: 1,
							width: 0.5,
							opacity: 0.1,
						}}
					/>

					<TouchableOpacity
						onPress={(event) => {
							const layout = event.nativeEvent;
							let x = layout.pageX;
							let y = layout.pageY;
							// console.log("assignment");
							// return;
							this.setState({
								// showSubTasks: false,
								// showChat: false,
								// showAssignment: !this.state.showAssignment,
								showModalArea: "projectassignment",

								xPos: x,
								yPos: y - 30,
								selectedTask: d,
								selectedID: d.id,
							});
							// this.setState({ hover: false });
						}}
						style={{
							justifyContent: "center",
							display: this.state.hover ? "flex" : "none",
							right: 10,
						}}>
						<View
							style={{
								width: 90,
								alignItems: "flex-end",
								paddingRight: 3,
								// alignItems: "center",
								// backgroundColor: "#252525",
								// padding: fs / 2,
							}}>
							{d.assignment.length < 1 ? (
								<View
									style={{
										//  flex: 1,
										//  backgroundColor: '#252525',
										justifyContent: "center",
										alignItems: "center",
										opacity: 0.4,
									}}>
									<Text
										style={{
											color: APP_COLOURS.BG2,
											fontSize: fs * 0.7,
										}}>
										+ Assignment
									</Text>
								</View>
							) : (
								<View
									style={{
										// width: 200,
										//  flexDirection: 'row-reverse',
										//  alignItems: 'flex-end',
										flexDirection: "row",
										alignItems: "center",
										// marginRight: 20,
										//  alignItems: 'center',
										// display: 'none',
										//  maxWidth: 120,
										width:
											d.assignment.length > 5
												? 4 * (size / 2)
												: d.assignment.length *
												  (size / 2),
									}}>
									{/* .filter(
											(d) =>
												d.id !==
												this.state.user_data.id,
										) */}

									{d.assignment.slice(0, 5).map((ass, i) => {
										return (
											<View
												key={i}
												style={{
													flexDirection: "row",
													justifyContent: "center",
													alignItems: "center",
													backgroundColor: "#FFF",
													borderWidth: 2,
													borderColor: "#FFF",
													width: size,
													height: size,
													borderRadius: size / 2,
													shadowColor: "#6494B7",
													shadowOffset: {
														width: 0,
														height: 4,
													},
													shadowOpacity: 0.23,
													shadowRadius: 4.65,
													elevation: 8,
													left: (size / 2.2) * -i,
													// position: "absolute",
													// top: 0,
													zIndex: i + 1,
												}}>
												<Image
													// source={avatars[parseInt(ass.avatar)]}
													source={
														Avatars[
															parseInt(ass.avatar)
														].pp
													}
													style={{
														width: size,
														height: size,
														borderRadius: size / 2,
														resizeMode: "contain",
														backgroundcolor:
															APP_COLOURS.BG2,
													}}
												/>
											</View>
										);
									})}
									{d.assignment.length > 5 ? (
										<View
											style={{
												backgroundColor: "#252525",
												flexDirection: "row",
												justifyContent: "center",
												alignItems: "center",
												//   position: 'absolute',
												right: size / -2,
												width: size,
												height: size,

												borderRadius: 15,
											}}>
											<Text
												style={{
													color: APP_COLOURS.BG2,
													fontSize: fs * 0.7,
												}}>
												+{d.assignment.length - 5}
											</Text>
										</View>
									) : null}
								</View>
							)}
						</View>
					</TouchableOpacity>

					<View
						style={{
							borderLeftWidth: 1,
							width: 0.5,
							opacity: 0.1,
						}}
					/>

					<View
						style={{
							// flex: 1,
							flexDirection: "row",
							height: 20,
							borderRadius: 5,
							overflow: "hidden",

							width: 60,
							alignSelf: "center",
							marginHorizontal: 7,
							display: this.state.hover ? "flex" : "none",
						}}>
						{PROJECTSTATUS.map((p) => (
							<View
								key={`ss-${p.status}`}
								style={{
									// minWidth: 10,
									flex: tasksForProject.filter(
										(t) => t.status === p.status,
									).length,
									backgroundColor: TaskStatus(p.status),
								}}
							/>
						))}
					</View>
					<View
						style={{
							borderLeftWidth: 1,
							width: 0.5,
							opacity: 0.1,
						}}
					/>
					<View
						style={{
							width: 65,
							marginLeft: 3,
							flexDirection: "row",
							justifyContent: "center",
							// height: 40,
							alignItems: "center",
							display: this.state.hover ? "flex" : "none",
						}}>
						{/* {[0, 1, 2, 3, 4].map((priority) => (
							<TouchableOpacity
								key={priority}
								onPress={() =>
									this.handlePriorityChange(priority, d.id)
								}>
								{d.priority >= priority ? (
									<AutoHeightImage
										width={11}
										style={{
											marginRight: 1,
										}}
										source={starFill}
									/>
								) : (
									<AutoHeightImage
										width={11}
										style={{
											marginRight: 2,
											opacity: 0.2,
										}}
										source={star}
									/>
								)}
							</TouchableOpacity>
						))} */}
						<View>
							{/* <Text
								style={{
									fontSize: 9,
									opacity: 0.6,
								}}>
								Due
							</Text> */}
							<Text
								style={{
									fontSize: 11,
								}}>
								{moment(d.due_date).format("DD MMM")}
							</Text>
						</View>
					</View>
					<View
						style={{
							borderLeftWidth: 1,
							width: 0.5,
							opacity: 0.1,
						}}
					/>
					{/* <GetUser
						users={this.state.users}
						user_id={d.createdBy}
						size={18}
						showName={false}
					/> */}

					<View
						style={{
							width: 120,
							marginLeft: 3,
							flexDirection: "row",
							justifyContent: "center",
							// height: 40,
							alignItems: "center",
							display: this.state.hover ? "flex" : "none",
						}}>
						{[0, 1, 2, 3, 4].map((priority) => (
							<TouchableOpacity
								key={priority}
								onPress={() =>
									this.handlePriorityChange(priority, d.id)
								}>
								{d.priority >= priority ? (
									<AutoHeightImage
										width={11}
										style={{
											marginRight: 1,
										}}
										source={require("../../../assets/images/Table/starFill.png")}
									/>
								) : (
									<AutoHeightImage
										width={11}
										style={{
											marginRight: 2,
											opacity: 0.2,
										}}
										source={require("../../../assets/images/Table/star.png")}
									/>
								)}
							</TouchableOpacity>
						))}
						<View
							style={{
								borderLeftWidth: 1,
								width: 0.5,
								opacity: 0.1,
							}}
						/>
						<Text
							style={{
								fontSize: 11,
								width: 60,
								justifyContent: "center",
							}}>
							due {moment(d.due_date).fromNow()}
						</Text>
					</View>

					{/* <TouchableOpacity
					onPress={(event) => {
						const layout = event.nativeEvent;
						let x = layout.pageX;
						let y = layout.pageY;
						this.setState({
							showSubTasks: false,
							showChat: false,
							showAssignment: false,
							showDateChange: true,
							showModalArea: "dateChange",
							xPos: x,
							yPos: y - 30,
							selectedTask: d,
							selectedID: d.id,
						});
					}}>
					<View
						style={{
							width: 120,
							padding: fs / 2,
						}}>
						<Text
							style={{
								color: APP_COLOURS.BG2,
								fontSize: fs,
							}}>
							{d.start_date !== ""
								? moment(d.start_date).format("DD MMM YYYY")
								: ""}
						</Text>
						<Text
							style={{
								color: APP_COLOURS.BG2,
								fontSize: fs * 0.6,
								opacity: 0.7,
							}}>
							{d.start_date !== ""
								? moment(d.start_date).fromNow()
								: ""}
						</Text>
					</View>
				</TouchableOpacity> */}

					{/* <TouchableOpacity
					onPress={(event) => {
						const layout = event.nativeEvent;
						let x = layout.pageX;
						let y = layout.pageY;
						this.setState({
							showSubTasks: false,
							showChat: false,
							showAssignment: false,
							showDateChange: true,
							showModalArea: "dateChange",
							xPos: x,
							yPos: y - 30,
							selectedTask: d,
							selectedID: d.id,
						});
					}}>
					<View
						style={{
							width: 120,
							padding: fs / 2,
						}}>
						<Text
							style={{
								color: APP_COLOURS.BG2,
								fontSize: fs,
							}}>
							{d.due_date !== ""
								? moment(d.due_date).format("DD MMM YYYY")
								: ""}
						</Text>
						<Text
							style={{
								color: APP_COLOURS.BG2,
								fontSize: fs * 0.6,
								opacity: 0.7,
							}}>
							{d.due_date !== ""
								? moment(d.due_date).fromNow()
								: ""}
						</Text>
					</View>
				</TouchableOpacity> */}

					{/* {this.state.showEdit === d.id ? (
						<TouchableOpacity
							onPress={() => {
								// this.deleteProject(d.id);
								crudDelete(
									this.state.crudDocumentName,
									d,
									() => {
										this.getAllProjectsUser(false);
										this.setState({
											showEdit: "",
										});
									},
								);
							}}
							style={{
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
								width: fs * 1.5,
								height: fs * 1.5,

								marginLeft: 5,
								marginRight: 10,
							}}>
							<Image
								source={bin}
								style={{
									width: fs * 1.5,
									height: fs * 1.5,
									resizeMode: "contain",
									marginRight: 5,
								}}
							/>
						</TouchableOpacity>
					) : (
						<TouchableOpacity
							onPress={() => {
								this.setState({
									showEdit: d.id,
								});
							}}>
							<View
								style={{
									justifyContent: "center",
									alignItems: "center",
									marginLeft: 5,
									width: fs * 1.5,
									height: fs * 1.5,
									marginRight: 10,
									padding: fs / 2,
									opacity: 0.3,
								}}>
								<View
									style={{
										width: fs * 1.5,
										height: fs * 1.5,
										borderRadius: 4,
										borderWidth: 1,
										bordercolor: APP_COLOURS.BG2,
									}}
								/>
							</View>
						</TouchableOpacity>
					)} */}
				</View>
			</View>
		);
	};

	projectComponent = (d) => {
		let fs = 14;
		let size = fs * 1.8;
		return (
			<View
				onLayout={() =>
					this.setState({
						loading: false,
					})
				}
				style={{ flex: 1 }}>
				<View
					style={{
						width: "100%",
						// flexDirection: "row",
						backgroundColor: APP_COLOURS.WHITE_TRANSPARENT99,
						padding: 5,
						borderRadius: 13,
						// marginLeft: 5,
						marginBottom: 10,
						paddingTop: 10,
						flexDirection: "row",
					}}>
					<View
						style={{
							// padding: 3,
							// alignItems: "flex-end",
							borderRadius: 15,
							backgroundColor: APP_COLOURS.RED,
							justifyContent: "center",
							alignItems: "center",
							width: 75,
							height: 60,
							borderWidth: 0.5,
							borderColor: APP_COLOURS.DESELECTEDCOLOR,
							overflow: "hidden",
						}}>
						<View style={{ padding: 4 }}>
							<Text
								style={{
									color: "#FFF",
									fontSize: fs * 0.8,
									fontWeight: "700",
								}}>
								{moment(d.due_date).format("MMM")}{" "}
							</Text>
						</View>
						<View
							style={{
								flex: 1,
								backgroundColor: "#FFF",
								padding: 5,
								width: "100%",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<Text
								style={{
									color: APP_COLOURS.BG2,
									fontSize: fs * 1.5,
									fontWeight: "800",
								}}>
								{moment(d.due_date).format("DD")}
							</Text>
						</View>
					</View>
					<View
						style={{
							opacity: 0.8,
							marginRight: 20,
							alignItems: "center",
							flex: 1,
							paddingLeft: 10,
							flexDirection: "row",
						}}>
						{/* <AutoHeightImage
							width={120}
							source={{
								uri: d.brand?.imageData?.url,
							}}
						/> */}
						<Text
							style={{
								color: APP_COLOURS.SECONDARY,
								fontSize: fs + 5,
								fontWeight: "700",
							}}>
							{d.brand?.title} {">"}
						</Text>
						<View
							style={{
								flex: 1,
								padding: 10,
								flexDirection: "row",
								borderBottomColor: "#E8E8E8",
								borderBottomWidth: 0.5,
								// marginBottom: 4,
								// backgroundColor: "red",
							}}>
							<TextInput
								returnKeyType='done'
								autoCorrect={false}
								// multiline
								// numberOfLines={3}
								style={{
									color: APP_COLOURS.BACKGROUND, // '#5A7BBA'
									fontSize: fs + 5,
									fontWeight: "700",
									// marginBottom: 6,
									width: "100%",
								}}
								defaultValue={d.title}
								editable={true}
								onChangeText={(text) =>
									this.setState({
										changedTitle: text,
										needToUpdateProject: true,
									})
								}
								autoCapitalize='sentences'
								placeholder='TItle'
								clearButtonMode='while-editing'
							/>

							{this.state.needToUpdateProject ? (
								<TouchableOpacity
									style={{
										// marginRight: 15,
										marginLeft: 10,
										// backgroundColor: "#FFF",
										backgroundColor: APP_COLOURS.GREEN,
										borderRadius: 15,
										flexDirection: "row",
										paddingVertical: 12,
										alignItems: "center",
										paddingHorizontal: 15,
										// borderWidth: 0.5,
										// borderColor: APP_COLOURS.BG2,
									}}
									onPress={async () => {
										let obj = {
											...this.state.selectedProject,
											title: this.state.changedTitle,
											description:
												this.state.changedDescription,
										};

										if (
											this.state.changedDescription === ""
										) {
											delete obj.description;
										}

										let user_data = JSON.parse(
											localStorage.getItem("user_data"),
										);
										// 										sendDiscordMessage(
										// 											`${user_data.first_name} ${user_data.last_name}`,
										// 											`[Project Update]
										// New title: ${obj.title}`,
										// 											`${window.location.origin}${
										// 												Avatars[parseInt(user_data.avatar)].pp
										// 											}`,
										// 											"0QPZYrY6zpAnJXqX9gQl",
										// 										);

										await this.updateProjectItem(obj);
										await this.createLog(
											"update",
											obj.title,
										);
										await this.setState({
											needToUpdateProject: false,
											changedTitle: "",
											changedDescription: "",
										});
									}}>
									<Text
										style={{
											color: "#FFF",
											fontSize: 13,
										}}>
										Save changes
									</Text>
								</TouchableOpacity>
							) : null}
						</View>
					</View>

					{/* <View
						style={{
							flex: 1,
						}}
					/> */}

					<View
						style={{
							marginHorizontal: 10,
							justifyContent: "center",
							alignItems: "center",
							padding: 3,
							// backgroundColor: "#FFF",
						}}>
						<Text
							style={{
								fontStyle: "italic",
								fontSize: fs * 1.1,
							}}>
							Due ({moment(d.due_date).fromNow()})
						</Text>
					</View>

					<View
						style={{
							// flex: 1,
							flexDirection: "row",
							backgroundColor: APP_COLOURS.WHITE_TRANSPARENT9,
							borderRadius: 25,
							alignItems: "center",
							justifyContent: "flex-end",
							padding: 10,
							marginRight: 5,
						}}>
						<View
							style={{
								borderRightWidth: 0.5,
								borderRightColor: APP_COLOURS.BG2,
								// height: 45,
								alignItems: "center",
								marginRight: 10,

								// flexDirection: "row",
							}}>
							{/* <Text
									style={{
										color: APP_COLOURS.BG2,
										fontSize: 9,
										fontWeight: "600",
									}}>
									SKrum Master
								</Text> */}
							<GetUser
								users={this.state.users}
								user_id={d.createdBy}
								size={size * 0.95}
							/>
						</View>

						<TouchableOpacity
							onPress={(event) => {
								const layout = event.nativeEvent;
								let x = layout.pageX;
								let y = layout.pageY;
								console.log("assignment");
								this.setState({
									// showSubTasks: false,
									// showChat: false,
									// showAssignment: !this.state.showAssignment,
									showModalArea: "projectassignment",

									xPos: x,
									yPos: y - 30,
									// selectedTask: d,
									selectedID: d.id,
								});
							}}
							style={{
								justifyContent: "center",
								// backgroundColor: "#E8E8E8",
							}}>
							<View
								style={{
									// width: 150,
									alignItems: "flex-end",
									paddingRight: 3,
									// alignItems: "center",
									// backgroundColor: "#252525",
									// padding: fs / 2,
								}}>
								{d.assignment.length < 1 ? (
									<View
										style={{
											//  flex: 1,
											//  backgroundColor: '#252525',
											justifyContent: "center",
											alignItems: "center",
											opacity: 0.4,
										}}>
										<Text
											style={{
												color: APP_COLOURS.BG2,
												fontSize: fs,
											}}>
											+ Assign to project
										</Text>
									</View>
								) : (
									<View
										style={{
											// width: 200,
											//  flexDirection: 'row-reverse',
											//  alignItems: 'flex-end',
											flexDirection: "row",
											alignItems: "center",
											// marginRight: 20,
											//  alignItems: 'center',
											// display: 'none',
											//  maxWidth: 120,
											// width:
											// 	d.assignment.length > 5
											// 		? 4 * (size / 2)
											// 		: d.assignment
											// 				.length *
											// 		  (size / 2),
											minWidth: 100,
										}}>
										{/* .filter(
												(d) =>
													d.id !==
													this.state.user_data.id,
											) */}

										{d.assignment.map((ass, i) => {
											return (
												<View
													style={{
														marginHorizontal: 2,
														alignItems: "center",
													}}>
													<View
														key={i}
														style={{
															flexDirection:
																"row",
															justifyContent:
																"center",
															alignItems:
																"center",
															backgroundColor:
																"#FFF",
															borderWidth: 2,
															borderColor: "#FFF",
															width: size * 1.3,
															height: size * 1.3,
															borderRadius:
																size / 2,
															shadowColor:
																"#6494B7",
															shadowOffset: {
																width: 0,
																height: 4,
															},
															shadowOpacity: 0.23,
															shadowRadius: 4.65,
															elevation: 8,
															// left:
															// 	(size /
															// 		2.2) *
															// 	-i,
															// position: "absolute",
															// top: 0,
															zIndex: i + 1,
														}}>
														<Image
															// source={avatars[parseInt(ass.avatar)]}
															source={
																Avatars[
																	parseInt(
																		ass.avatar,
																	)
																].pp
															}
															style={{
																width:
																	size * 1.3,
																height:
																	size * 1.3,
																borderRadius:
																	(size *
																		1.3) /
																	2,
																resizeMode:
																	"contain",
																backgroundcolor:
																	APP_COLOURS.BG2,
															}}
														/>
													</View>
													<Text
														style={{
															color: APP_COLOURS.BG2,
															fontSize: 9,
														}}>
														{ass.first_name}
													</Text>
												</View>
											);
										})}
										{/* {d.assignment.length > 5 ? (
													<View
														style={{
															backgroundColor:
																"#252525",
															flexDirection:
																"row",
															justifyContent:
																"center",
															alignItems:
																"center",
															//   position: 'absolute',
															right: size / -2,
															width: size,
															height: size,

															borderRadius: 15,
														}}>
														<Text
															style={{
																color: APP_COLOURS.BG2,
																fontSize: fs,
															}}>
															+
															{d.assignment
																.length - 5}
														</Text>
													</View>
												) : null} */}
									</View>
								)}
							</View>
						</TouchableOpacity>
					</View>

					<StatusItem
						{...this.props}
						{...d}
						fs={fs}
						handleStatusChange={async (_id, _status) => {
							let user_data = JSON.parse(
								localStorage.getItem("user_data"),
							);

							const pmp = await d.assignment_ids.forEach(
								async (id) => {
									await sendDiscordMessage(
										`${user_data.first_name} ${user_data.last_name}`,
										`[PROJECT Status Update]
### *${d.brand.title} - ${d.title}*
> *${_status}*
- url: ${window.location.origin}/home/projects?pid=${this.state.selectedProject.id}
`,
										`${window.location.origin}${
											Avatars[parseInt(user_data.avatar)]
												.pp
										}`,
										id,
									);
								},
							);

							const mapThrough = await d.assignment.forEach(
								async (e) => {
									await crudSendEmail(
										"SKrum  <skrum@tetrice.co.za>",
										e.username,
										`[PROJECT UPDATE]: ${d.title}`,
										`<p>${d.brand.title}</p><p>${d.title}</p><p>From ${d.status} to ${_status}</p><br>
								<br>Be sure to check for tasks that are allocated to you.
								<br>https://skrum.tetrice.co.za/
								`,
									);
								},
							);

							crudCreate(
								"projects_timeline",
								{
									project_id: d.id,
									text: _status,
									type: "Status",
									sendBy: {
										id: user_data.id,
										first_name: user_data.first_name,
										last_name: user_data.last_name,
									},
									dateCreated: moment().toISOString(),
									dateUnix: moment().unix(),
								},
								() => {},
							);

							this.handleStatusChange(_id, _status);
						}}
					/>
				</View>

				<View style={{ width: "100%", flexDirection: "row" }}>
					{/* <View
						style={{
							padding: 3,
							alignItems: "flex-end",
						}}>
						<Text
							style={{
								color: APP_COLOURS.BG2,
								fontSize: fs + 3,
								fontWeight: "700",
							}}>
							Due {moment(d.due_date).format("DD/MM/YYYY")}{" "}
							<Text
								style={{
									fontStyle: "italic",
								}}>
								({moment(d.due_date).fromNow()})
							</Text>
						</Text>
					</View> */}
				</View>

				<View
					style={{
						flex: 1,
						flexDirection: "row",
					}}>
					<View
						style={{
							flex: 1,
						}}>
						<View
							style={{
								flex: 1,
								flexDirection: "row",
								// backgroundColor: "#FFF",
								backgroundColor: APP_COLOURS.BG4,
								// padding: 5,
								borderRadius: 13,
								// marginLeft: 5,
								// marginBottom: 10,
							}}>
							<View
								style={{
									flex: 1,
									// height: this.props.screenHeight,
									// justifyContent: "flex-start",
									// backgroundColor: "#FFF",
									// padding: 5,
									borderRadius: 13,
									// marginLeft: 5,
									// marginBottom: 10,
								}}>
								{/* <View
								style={{
									width: "100%",
									marginVertical: 15,
									backgroundColor: "#FFF",
								}}>
								<ProgressBar
									progress={`${
										(this.state.tasks.filter(
											(t) =>
												t.project_id === d.id &&
												t.status === "Completed",
										).length /
											this.state.tasks.filter(
												(t) => t.project_id === d.id,
											).length) *
										100
									}%`}
									inProgress={true}
									color={APP_COLOURS.GREEN}
								/>
							</View> */}

								<View
									style={{
										// flexDirection: "row",
										// alignItems: "center",
										// borderBottomWidth: 0.5,
										// borderBottomColor:
										// 	APP_COLOURS.DESELECTEDCOLOR,
										// paddingBottom: 10,
										width: "100%",
										justifyContent: "flex-end",
									}}>
									<ScrollView
										horizontal
										showsHorizontalScrollIndicator={false}>
										{/* <View
											style={{
												// padding: 3,
												// alignItems: "flex-end",
												borderRadius: 15,
												backgroundColor:
													APP_COLOURS.RED,
												justifyContent: "center",
												alignItems: "center",
												width: 75,
												height: 60,
												borderWidth: 0.5,
												borderColor:
													APP_COLOURS.DESELECTEDCOLOR,
											}}>
											<View style={{ padding: 4 }}>
												<Text
													style={{
														color: "#FFF",
														fontSize: fs * 0.8,
														fontWeight: "700",
													}}>
													{moment(d.due_date).format(
														"MMM",
													)}{" "}
												</Text>
											</View>
											<View
												style={{
													flex: 1,
													backgroundColor: "#FFF",
													padding: 5,
													width: "100%",
													justifyContent: "center",
													alignItems: "center",
												}}>
												<Text
													style={{
														color: APP_COLOURS.BG2,
														fontSize: fs * 1.5,
														fontWeight: "800",
													}}>
													{moment(d.due_date).format(
														"DD",
													)}
												</Text>
											</View>
										</View> */}

										<TouchableOpacity
											onPress={(event) => {
												const layout =
													event.nativeEvent;
												let x = layout.pageX;
												let y = layout.pageY;
												this.setState({
													showSubTasks: false,
													showChat: false,
													showAssignment: false,
													showDateChange: true,
													showModalArea: "dateChange",
													xPos: x,
													yPos: y - 30,
													selectedTask: d,
													selectedID: d.id,
												});
											}}>
											<View
												style={{
													width: 120,
													padding: 5,
													alignItems: "center",
													justifyContent: "center",
												}}>
												<Text
													style={{
														color: "#FFF",
														fontSize: fs,
													}}>
													{d.start_date !== ""
														? moment(
																d.start_date,
														  ).format(
																"DD MMM YYYY",
														  )
														: ""}
												</Text>
												<Text
													style={{
														color: "#FFF",
														fontSize: fs * 0.6,
														opacity: 0.7,
													}}>
													{d.start_date !== ""
														? moment(
																d.start_date,
														  ).fromNow()
														: ""}
												</Text>
											</View>
										</TouchableOpacity>

										<TouchableOpacity
											onPress={(event) => {
												const layout =
													event.nativeEvent;
												let x = layout.pageX;
												let y = layout.pageY;
												this.setState({
													showSubTasks: false,
													showChat: false,
													showAssignment: false,
													showDateChange: true,
													showModalArea: "dateChange",
													xPos: x,
													yPos: y - 30,
													selectedTask: d,
													selectedID: d.id,
												});
											}}>
											<View
												style={{
													width: 120,
													padding: 5,
													alignItems: "center",
													justifyContent: "center",
												}}>
												<Text
													style={{
														color: "#FFF",
														fontSize: fs,
													}}>
													{d.due_date !== ""
														? moment(
																d.due_date,
														  ).format(
																"DD MMM YYYY",
														  )
														: ""}
												</Text>
												<Text
													style={{
														color: "#FFF",
														fontSize: fs * 0.6,
														opacity: 0.7,
													}}>
													{d.due_date !== ""
														? moment(
																d.due_date,
														  ).fromNow()
														: ""}
												</Text>
											</View>
										</TouchableOpacity>

										<View
											style={{
												borderLeftWidth: 1,
												width: 0.5,
												opacity: 0.1,
												flex: 1,
											}}
										/>

										<SmallerTabs
											selectedSubPage={(d) => {
												this.setState({
													subPage: d,
													lockContent: "",
												});
												console.log(d);
											}}
											tabs={[
												"Overview",

												"Documents",
												"Tasks",
												"Dates",
												"Assignment",
												"Edit",
											]}
										/>
									</ScrollView>
								</View>

								{this.state.subPage === "Overview" ? (
									<View
										style={{
											flex: 1,
											backgroundColor: "#FFF",
											borderRadius: 13,
										}}>
										<View
											style={{
												flex: 1,
												padding: 5,
											}}>
											<ScrollView>
												<View
													style={{
														// padding: 15,
														// borderWidth: 0.5,
														borderRadius: 5,
														maxWidth: 800,
													}}>
													{/* <Text
												style={{
													color: APP_COLOURS.BG2, // '#5A7BBA'
													fontSize: 12,
													fontWeight: "300",
													marginBottom: 6,
													opacity: 0.5,
												}}>
												Overview:
											</Text> */}

													<View
														style={{
															flexDirection:
																"row",
															justifyContent:
																"space-between",
															alignItems:
																"center",
															borderBottomWidth: 0.5,
															borderBottomColor:
																APP_COLOURS.DESELECTEDCOLOR,
														}}>
														{/* {this.state.user_data
															.username ===
														"wesley@tetrice.co.za" ? (
															<Text>{d.id}</Text>
														) : null}
														<Text
															style={{
																fontStyle:
																	"italic",
															}}>
															last updated{" "}
															{moment(
																d.last_updated,
															).format(
																"DD/MM/YYYY HH:mm",
															)}
														</Text> */}
														<TouchableOpacity
															onPress={() => {
																this.setState({
																	lockContent:
																		this
																			.state
																			.lockContent ===
																		d.id
																			? ""
																			: d.id,
																});
															}}>
															<View
																style={{
																	width: 150,
																	alignItems:
																		this
																			.state
																			.lockContent ===
																		d.id
																			? "flex-start"
																			: "flex-end",
																	borderRadius: 15,
																	height: 40,
																	marginBottom: 5,
																}}>
																<View
																	style={{
																		width: 150,
																		height: 40,
																		alignItems:
																			"center",
																		justifyContent:
																			"center",
																		borderRadius: 13,
																		backgroundColor:
																			this
																				.state
																				.lockContent ===
																			d.id
																				? APP_COLOURS.GREENCOMPLETE
																				: APP_COLOURS.BLUE,
																		paddingHorizontal: 10,
																	}}>
																	<Text
																		style={{
																			fontSize: 13,
																			color:
																				this
																					.state
																					.lockContent ===
																				d.id
																					? "blue"
																					: APP_COLOURS.DESELECTEDCOLOR,
																		}}>
																		{this
																			.state
																			.lockContent ===
																		d.id
																			? "Unlocked"
																			: "Locked"}
																	</Text>
																</View>
															</View>
														</TouchableOpacity>
														{/* <TouchableOpacity
															onPress={() => {}}>
															<View
																style={{
																	width: 80,

																	borderRadius: 15,
																	height: 40,
																}}>
																<View
																	style={{
																		width: 80,
																		height: 40,
																		alignItems:
																			"center",
																		justifyContent:
																			"center",
																		borderRadius: 13,
																		backgroundColor:
																			APP_COLOURS.BLUE,
																		paddingHorizontal: 10,
																	}}>
																	<Text
																		style={{
																			fontSize: 13,
																			color: APP_COLOURS.DESELECTEDCOLOR,
																		}}>
																		Share
																	</Text>
																</View>
															</View>
														</TouchableOpacity> */}
													</View>

													<View
														style={{
															padding: 10,
														}}>
														<DescriptionQuillEditor
															presentation_id={
																d.id
															}
															pageData={
																d.description
															}
															reloadData={(
																html,
															) => {
																crudCreateWithHtml(
																	"project_descriptions",
																	{
																		presentation_id:
																			d.id,
																		html: html,
																		_id:
																			typeof d
																				.description
																				?.htmlData
																				?._id !==
																			"undefined"
																				? d
																						.description
																						.htmlData
																						._id
																				: "",
																	},
																	async (
																		status,
																		data,
																	) => {
																		console.log(
																			data,
																			status,
																		);

																		let last_updated =
																			moment().toISOString();

																		let obj =
																			{
																				...this
																					.state
																					.selectedProject,
																				description:
																					data,
																				last_updated:
																					last_updated,
																			};
																		await this.updateProjectItem(
																			obj,
																		);
																		await this.createLog(
																			"update",
																			obj.description,
																		);
																		await this.setState(
																			{
																				needToUpdateProject: false,
																				changedTitle:
																					"",
																				changedDescription:
																					"",
																			},
																		);
																	},
																);
															}}
															lockContent={
																this.state
																	.lockContent !==
																d.id
															}
														/>
													</View>

													{/* <TextEditorComp
														defaultvalue={
															d.description
														}
														exportHtml={(html) => {
															console.log(html);

															this.setState({
																changedDescription:
																	html,
																needToUpdateDescription: true,
															});
														}}
													/> */}

													{/* <TextInput
												returnKeyType='done'
												autoCorrect={false}
												style={{
													color: APP_COLOURS.BG2, // '#5A7BBA'
													fontSize: 16,
													fontWeight: "300",
													marginBottom: 6,
												}}
												defaultValue={d.description}
												editable={true}
												multiline
												numberOfLines={10}
												onChangeText={(text) =>
													this.setState({
														changedDescription:
															text,
														needToUpdateProject: true,
													})
												}
												autoCapitalize='sentences'
												placeholder='Description'
												clearButtonMode='while-editing'
											/> */}

													{/* <AutoHeightImage
								width={100}
								source={{
									uri: d.brand?.imageData?.url,
								}}
								// src={`${d.brand?.imageData?.url}`}
								// style={{
								// 	marginBottom: 10,
								// }}
							/> */}
													{/* <Text
						style={{
							color: APP_COLOURS.BG2, // '#5A7BBA'
							fontSize: 17,
							fontWeight: "700",
							marginBottom: 6,
						}}>
						{d.brand.title}
					</Text> */}
													{/* <Text
									style={{
										color: APP_COLOURS.BG2, // '#5A7BBA'
										fontSize: 17,
										fontWeight: "700",
										marginBottom: 6,
									}}>
									{d.title}
								</Text>
								<View style={{}}>
									<Text
										style={{
											color: APP_COLOURS.BG2, // '#5A7BBA'
											fontSize: 13,
											fontWeight: "300",
											marginBottom: 6,
										}}>
										{d.description}
									</Text>
								</View> */}
												</View>
											</ScrollView>
											{/* {this.state
												.needToUpdateDescription ? (
												<TouchableOpacity
													style={{
														// marginRight: 15,
														marginLeft: 10,
														// backgroundColor: "#FFF",
														backgroundColor:
															APP_COLOURS.GREEN,
														borderRadius: 15,
														flexDirection: "row",
														paddingVertical: 12,
														alignItems: "center",
														paddingHorizontal: 15,
														// borderWidth: 0.5,
														// borderColor: APP_COLOURS.BG2,
														justifyContent:
															"center",
													}}
													onPress={async () => {
														let obj = {
															...this.state
																.selectedProject,

															description:
																this.state
																	.changedDescription,
														};
														await this.updateProjectItem(
															obj,
														);
														await this.createLog(
															"update",
															obj.description,
														);
														await this.setState({
															needToUpdateProject: false,
															changedTitle: "",
															changedDescription:
																"",
														});
													}}>
													<Text
														style={{
															color: "#FFF",
															fontSize: 13,
														}}>
														Save description
													</Text>
												</TouchableOpacity>
											) : null} */}
										</View>
									</View>
								) : null}

								{this.state.subPage === "Documents" ? (
									<View
										style={{
											flex: 1,
											padding: 5,
											backgroundColor: "#FFF",
											borderRadius: 13,
										}}>
										<ProjectDocuments
											{...this.state}
											project_id={
												this.state.selectedProject.id
											}
										/>
									</View>
								) : null}

								{this.state.subPage === "Assignment" ? (
									<View
										style={{
											flex: 1,
											backgroundColor: "#FFF",
											borderRadius: 13,
										}}>
										<View
											style={{
												padding: 15,
											}}>
											<Text
												style={{
													color: APP_COLOURS.BG2,
													fontSize: 9,
													fontWeight: "600",
													marginTop: 10,
												}}>
												Assignment:
											</Text>

											<View
												style={{
													paddingRight: 3,
													// alignItems: "center",
													// backgroundColor: "#252525",
													// padding: fs / 2,
												}}>
												{d.assignment.length < 1 ? (
													<View
														style={{
															//  flex: 1,
															//  backgroundColor: '#252525',
															justifyContent:
																"center",

															opacity: 0.4,
														}}>
														<Text
															style={{
																color: APP_COLOURS.BG2,
																fontSize: fs,
															}}>
															+ Assign
														</Text>
													</View>
												) : (
													<View style={{}}>
														{d.assignment.map(
															(ass, i) => {
																return (
																	<View
																		key={i}
																		style={{
																			flexDirection:
																				"row",

																			alignItems:
																				"center",
																		}}>
																		<Image
																			// source={avatars[parseInt(ass.avatar)]}
																			source={
																				Avatars[
																					parseInt(
																						ass.avatar,
																					)
																				]
																					.pp
																			}
																			style={{
																				width: size,
																				height: size,
																				borderRadius:
																					size /
																					2,
																				resizeMode:
																					"contain",
																				backgroundcolor:
																					APP_COLOURS.BG2,
																			}}
																		/>
																		<Text
																			style={{
																				color: APP_COLOURS.BG2,
																				fontSize:
																					fs -
																					2,
																			}}>
																			{
																				ass.first_name
																			}{" "}
																			{
																				ass.last_name
																			}
																		</Text>
																	</View>
																);
															},
														)}
													</View>
												)}
												<Text
													style={{
														color: APP_COLOURS.BG2,
														fontSize: 9,
														fontWeight: "600",
														marginTop: 10,
													}}>
													SKrum Master
												</Text>
												<GetUser
													users={this.state.users}
													user_id={d.createdBy}
													size={size}
												/>
											</View>
										</View>
									</View>
								) : null}

								{/* {this.state.subPage === "Log" &&
								this.state.user_data.username ===
									"wesley@tetrice.co.za" ? (
									<View
										style={{
											flex: 1,
											padding: 5,
										}}>
										<ActivityLog projectID={d.id} />
									</View>
								) : null} */}

								{this.state.subPage === "Edit" &&
								this.state.user_data.username ===
									"wesley@tetrice.co.za" ? (
									<View
										style={{
											flex: 1,
											backgroundColor: "#FFF",
											borderRadius: 13,
										}}>
										<View
											style={{
												padding: 15,
											}}>
											<Text
												style={{
													color: APP_COLOURS.BG2, // '#5A7BBA'
													fontSize: 12,
													fontWeight: "300",
													marginBottom: 6,
													opacity: 0.5,
												}}>
												Edit project: {d.id}
											</Text>
											<View
												style={{
													flex: 1,
												}}>
												<TouchableOpacity
													onPress={async () => {
														await crudDeleteWhere(
															"projects_timeline",
															{
																field: "project_id",
																action: "==",
																value: d.id,
															},
															(res) => {
																if (
																	res === 200
																) {
																	// alert(
																	// 	"Deleted chats",
																	// );
																}
																return;
															},
														);

														await crudDeleteWhere(
															"projects_tasks",
															{
																field: "project_id",
																action: "==",
																value: d.id,
															},
															(res) => {
																if (
																	res === 200
																) {
																	alert(
																		"Deleted tasks",
																	);
																}
																this.getAllProjectTasks();
															},
														);

														await crudDeleteWhere(
															"projects",
															{
																field: "id",
																action: "==",
																value: d.id,
															},
															(res) => {
																if (
																	res === 200
																) {
																	alert(
																		"Deleted project",
																	);
																}
																{
																	this.getAllProjectsUser(
																		false,
																	);
																	this.setState(
																		{
																			selectedProject:
																				"",
																			loading: false,
																			hover: true,
																			filterSearch:
																				"",
																		},
																	);
																}
															},
														);
													}}
													style={{
														flexDirection: "row",
														justifyContent:
															"center",
														alignItems: "center",
														padding: 15,

														marginLeft: 5,
														marginRight: 10,
														backgroundColor:
															APP_COLOURS.RED2,
														borderRadius: 10,
													}}>
													<Text
														style={{
															color: "#FFF",
															marginRight: 10,
															fontWeight: "500",
														}}>
														Delete project and
														associated data
													</Text>
													<Image
														source={bin}
														style={{
															width: fs * 1.5,
															height: fs * 1.5,
															resizeMode:
																"contain",
															marginRight: 5,
														}}
													/>
												</TouchableOpacity>
											</View>
										</View>
									</View>
								) : null}

								{this.state.subPage === "Tasks" ? (
									<View
										style={{
											flex: 1,
											// marginLeft: 5,
											backgroundColor: "#FFF",
											padding: 10,
											borderRadius: 13,
										}}>
										<Text
											style={{
												color: APP_COLOURS.BG2,
												fontSize: 16,
												fontWeight: "900",
												marginBottom: 6,
											}}>
											Tasks
										</Text>
										<View
											style={{
												width: "100%",
												marginVertical: 15,
												backgroundColor: "#FFF",
											}}>
											<ProgressBar
												progress={`${
													(this.state.tasks.filter(
														(t) =>
															t.project_id ===
																d.id &&
															t.status ===
																"Completed",
													).length /
														this.state.tasks.filter(
															(t) =>
																t.project_id ===
																d.id,
														).length) *
													100
												}%`}
												inProgress={true}
												color={APP_COLOURS.GREEN}
											/>
										</View>
										{this.state.selectedTask !== "" ? (
											<View
												style={{
													flex: 1,
													// backgroundColor: APP_COLOURS.BG2,
												}}>
												<EditTask
													d={this.state.selectedTask}
													milestoneData={d}
													selectedProject={d}
													reloadData={() =>
														this.getAllProjectTasks()
													}
													close={() => {
														this.setState({
															selectedTask: "",
														});
													}}
												/>
												{/* <DailyPlanner
													user_data={
														this.state.user_data
													}
													title={d.title}
													category={"wes"}
												/> */}
											</View>
										) : (
											<View
												style={{
													flex: 1,
												}}>
												<ScrollView>
													{this.state.tasks
														.filter(
															(t) =>
																t.project_id ===
																d.id,
														)
														.filter(
															(t) =>
																t.status !==
																"Completed",
														)
														.sort((a, b) => {
															return (
																moment(
																	a.end_date,
																).unix() -
																moment(
																	b.end_date,
																).unix()
															);
														})
														.map((task, ii) =>
															this.taskItem(
																task,
																ii,
																fs,
																size,
															),
														)}

													<View
														style={{
															// flex: 1,
															padding: 3,
															// maxWidth: 600,
															width: "100%",
															maxWidth: 600,
															borderRadius: 20,
														}}>
														<View
															style={{
																width: "100%",
																padding: 10,
																borderRadius: 20,
															}}>
															<Text
																style={{
																	fontSize:
																		fs,
																	color: APP_COLOURS.BG2,
																	fontWeight:
																		"600",
																	fontFamily:
																		"Gotham",
																}}>
																All Completed
															</Text>
														</View>
														<ScrollView>
															{this.state.tasks
																.filter(
																	(t) =>
																		t.project_id ===
																		d.id,
																)
																.sort(
																	(a, b) => {
																		return (
																			moment(
																				a.end_date,
																			).unix() -
																			moment(
																				b.end_date,
																			).unix()
																		);
																	},
																)
																.filter(
																	(t) =>
																		t.status ===
																		"Completed",
																)

																.map(
																	(
																		task,
																		ii,
																	) =>
																		this.taskItem(
																			task,
																			ii,
																			fs,
																			size,
																		),
																)}
														</ScrollView>
													</View>
												</ScrollView>

												<TouchableOpacity
													style={{
														width: "100%",

														// backgroundColor: "#FFF",
														backgroundColor:
															APP_COLOURS.BLUE2,
														borderRadius: 15,
														flexDirection: "row",
														paddingVertical: 12,
														alignItems: "center",
														paddingHorizontal: 15,
														borderWidth: 0.5,
														borderColor:
															APP_COLOURS.BG2,
														justifyContent:
															"center",
														alignContent: "center",
													}}
													onPress={async () => {
														this.setState({
															addProjectTask: true,
														});
													}}>
													<Text
														style={{
															color: "#FFF",
															fontSize: 14,
														}}>
														+ New task
													</Text>
												</TouchableOpacity>
											</View>
										)}
									</View>
								) : null}

								{/* {this.state.subPage === "Documents" ? (
									<View
										style={{
											flex: 1,
											padding: 5,
										}}></View>
								) : null} */}
							</View>
						</View>
					</View>

					<View
						style={{
							flex: 1,
							// marginLeft: 5,
							backgroundColor: "#FFF",
							padding: 5,
							borderRadius: 13,
							marginLeft: 10,
						}}>
						<ChatBox
							chatTitle={d.title}
							project_id={d.id}
							selectedProject={this.state.selectedProject}
							assignment_ids={
								this.state.selectedProject?.assignment_ids
							}
							user_data={this.state.user_data}
						/>
					</View>

					{this.state.addProjectTask ? (
						<AddTask
							{...this.state}
							project_id={d.id}
							selectedProject={d}
							close={() =>
								this.setState({
									addProjectTask: false,
								})
							}
							reloadData={() => this.getAllProjectTasks()}
						/>
					) : null}
				</View>
			</View>
		);
	};

	projectComponent_ = (d) => {
		let fs = 14;
		let size = fs * 1.8;
		return (
			<ProjectComponent
				fs={fs}
				size={size}
				{...this.props}
				{...this.state}
				selectedProject={d}
				d={d}
				users={this.state.users}
				user_data={this.state.user_data}
				getAllProjectTasks={() => this.getAllProjectTasks()}
				getAllProjectsUser={() => this.getAllProjectsUser(false)}
			/>
		);
	};

	// saveupdateItem = (d) => {
	// 	// console.log(d);
	// 	// return;
	// 	clearTimeout(this._timeout);
	// 	this._timeout = setTimeout(async () => {
	// 		this.setState({
	// 			loading: true,
	// 		});

	// 		crudUpdate(`projects_tasks`, d, (status, d) => {
	// 			// console.log(status, d);
	// 			console.log("saveupdateItem");
	// 			if (status === 200) {
	// 				this.props.reloadData();
	// 			}
	// 		});
	// 	}, 1500);
	// 	// return;
	// };

	allTasksComp = (fs, size) => {
		let overdueTasks = this.state.tasks;
		let currentTasks;

		let allTasks = this.state.tasks.sort((a, b) => {
			return moment(a.start_date).unix() - moment(b.start_date).unix();
		});

		let mainTask = allTasks[0];
		return (
			<View
				style={{
					flex: 1,
					justifyContent: "flex-start",
					// backgroundColor: "#FFF",
					backgroundColor: APP_COLOURS.BG4,
					padding: 5,
					borderRadius: 15,
					marginRight: 10,
				}}>
				{/* <Pie percentage={60} color={"green"} /> */}

				{/* <View
					style={{
						width: "100%",
						flexDirection: "row",
						height: 50,
						borderRadius: 12,
						overflow: "hidden",
						marginBottom: 15,
					}}>
					{PROJECTSTATUS.map((p) => {
						let total =
							(allTasks.filter((t) => t.status === p.status)
								.length /
								allTasks.length) *
							100;

						if (total > 0)
							return (
								<View
									style={{
										flex: allTasks.filter(
											(t) => t.status === p.status,
										).length,
										backgroundColor: TaskStatus(p.status),
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Text
										style={{
											fontSize: 16,
										}}>
										{total.toFixed(0)}%
									</Text>
								</View>
							);
					})}
				</View> */}
				{/* <Text
					style={{
						color: APP_COLOURS.TEXTCOLOR,
						fontSize: 23,
						fontWeight: "700",
						marginVertical: 10,
					}}>
					{moment().format("ddd DD MMM YYYY")}
				</Text> */}
				<View
					style={{
						flex: 1,
						// flexDirection: "row",
					}}>
					<ScrollView horizontal>
						{this.taskCategories(
							allTasks
								.filter((t) => t.status !== "Completed")
								.filter((t) => t.assignment.length === 0)
								.filter((t) =>
									moment().isSameOrAfter(moment(t.end_date)),
								),
							"Urgent",
							"Overdue",
							fs,
							size,
						)}
						{this.taskCategories(
							allTasks
								.filter((t) => t.status !== "Completed")
								.filter((t) => t.assignment.length > 0)
								.filter((t) =>
									moment().isSameOrAfter(moment(t.end_date)),
								),
							"Urgent",
							"Assigned Overdue",
							fs,
							size,
						)}
						{/* {this.taskCategories(
							allTasks
								.filter((t) => t.status !== "Completed")
								.filter((t) => t.assignment.length > 0),
							"Urgent",
							"Assigned overdue",
							fs,
							size,
						)} */}
						{this.taskCategories(
							allTasks
								.filter((t) => t.status !== "Completed")
								.filter((t) => t.assignment.length === 0)
								.filter((t) =>
									moment().isSameOrAfter(
										moment(t.start_date),
									),
								)
								.filter((t) => {
									if (
										typeof t.end_date !== "undefined" &&
										t.end_date &&
										moment().isSameOrBefore(
											moment(t.end_date),
										)
									) {
										return t;
									} else {
										if (
											moment().isSameOrBefore(
												moment(t.start_date).add(
													1,
													"day",
												),
											)
										) {
											return t;
										}
									}
								}),
							"Awaiting Approval",
							"Current",
							fs,
							size,
						)}
						{this.taskCategories(
							allTasks
								.filter((t) => t.status !== "Completed")
								.filter((t) => t.assignment.length > 0)
								.filter((t) =>
									moment().isSameOrAfter(
										moment(t.start_date),
									),
								)
								.filter((t) => {
									if (
										typeof t.end_date !== "undefined" &&
										t.end_date &&
										moment().isSameOrBefore(
											moment(t.end_date),
										)
									) {
										return t;
									} else {
										if (
											moment().isSameOrBefore(
												moment(t.start_date).add(
													1,
													"day",
												),
											)
										) {
											return t;
										}
									}
								}),
							"Awaiting Approval",
							"Assigned Current",
							fs,
							size,
						)}

						{this.taskCategories(
							allTasks
								.filter((t) => t.status !== "Completed")
								.filter((t) => t.assignment.length === 0)
								.filter(
									(t) =>
										moment(t.start_date).unix() >=
										moment().unix(),
								),
							"Pending",
							"Upcoming",
							fs,
							size,
						)}

						{this.taskCategories(
							allTasks
								.filter((t) => t.status !== "Completed")
								.filter((t) => t.assignment.length > 0)
								.filter(
									(t) =>
										moment(t.start_date).unix() >=
										moment().unix(),
								),
							"Pending",
							"Pending Allocated",
							fs,
							size,
						)}

						<View
							style={{
								// flex: 1,
								padding: 3,
								// maxWidth: 600,
								width: "100%",
								maxWidth: 600,
								borderRadius: 20,
								backgroundColor: "#FFF",
							}}>
							<View
								style={{
									width: "100%",
									padding: 10,
									borderRadius: 20,
									backgroundColor: TaskStatus("Completed"),
									flexDirection: "row",
								}}>
								<Text
									style={{
										fontSize: fs,
										color: APP_COLOURS.BG2,
										fontWeight: "600",
										fontFamily: "Gotham",
									}}>
									All Completed
								</Text>
							</View>
							<ScrollView>
								{allTasks
									.filter((t) => t.status === "Completed")

									.map((task, ii) =>
										this.taskItem(task, ii, fs, size),
									)}
							</ScrollView>
						</View>

						{/* <View
						style={{
							flex: 1,
							padding: 5,
						}}>
						<View
							style={{
								width: "100%",
								padding: 10,
								borderRadius: 8,
								backgroundColor: TaskStatus("In Progress"),
								flexDirection: "row",
							}}>
							<Text
								style={{
									fontSize: 30,
									color: APP_COLOURS.BG2,
									fontWeight: "600",
									fontFamily: "Gotham",
								}}>
								{
									allTasks

										.filter((t) => t.status !== "Completed")

										.filter(
											(t) =>
												moment(t.start_date).unix() <=
												moment().unix(),
										).length
								}
							</Text>
							<Text
								style={{
									fontSize: fs,
									color: APP_COLOURS.BG2,
									fontWeight: "600",
									fontFamily: "Gotham",
								}}>
								Assigned Due
							</Text>
						</View>
						<ScrollView>
							{allTasks
								.filter((t) => t.status !== "Completed")
								.filter((t) => t.assignment.length > 0)
								.filter(
									(t) =>
										moment(t.start_date).unix() >=
										moment().unix(),
								)
								.map((task, ii) =>
									this.taskItem(task, ii, fs, size),
								)}
						</ScrollView>
					</View> */}

						{/* <View
						style={{
							flex: 1,
							padding: 5,
						}}>
						<View
							style={{
								width: "100%",
								padding: 10,
								borderRadius: 8,
								backgroundColor: TaskStatus("Completed"),
								flexDirection: "row",
							}}>
							<Text
								style={{
									fontSize: 30,
									color: APP_COLOURS.BG2,
									fontWeight: "600",
									fontFamily: "Gotham",
								}}>
								{
									allTasks
										.filter((t) => t.status === "Completed")
										.filter(
											(t) =>
												moment(t.start_date).unix() <=
												moment().unix(),
										).length
								}
							</Text>
							<Text
								style={{
									fontSize: fs,
									color: APP_COLOURS.BG2,
									fontWeight: "600",
									fontFamily: "Gotham",
								}}>
								Completed
							</Text>
						</View>
						<ScrollView>
							{allTasks
								.filter((t) => t.status === "Completed")
								.map((task, ii) =>
									this.taskItem(task, ii, fs, size),
								)}
						</ScrollView>
					</View> */}

						{/* <View
							style={{
								flex: 1,

								padding: 1,
								minWidth: 400,
								maxWidth: 600,
								backgroundColor: APP_COLOURS.BG3,
							}}></View> */}
					</ScrollView>
				</View>
			</View>
		);
	};

	taskCategories = (allTasks, status, title, fs, size) => {
		let t = allTasks;

		let firstStart = "";
		if (t.length > 0) {
			const firstItem = t[0];
			// const lastItem = t[t.length - 1];

			firstStart = moment(firstItem.start_date).fromNow();
		}

		return (
			<View
				style={{
					// flex: 1,
					// padding: 1,
					width: "100%",
					maxWidth: 600,
					// maxHeight: 500,
					borderRadius: 20,
					overflow: "hidden",
					backgroundColor: TaskStatus(status),
					marginRight: 10,
					marginBottom: 10,

					display: t.length > 0 ? "flex" : "none",
				}}>
				<View
					style={{
						width: "100%",
						padding: 10,
						borderRadius: 8,
						backgroundColor: TaskStatus(status),
						flexDirection: "row",
						// alignItems: "flex-end",
						alignItems: "center",
					}}>
					<Text
						style={{
							fontSize: 30,
							color: APP_COLOURS.BG2,
							fontWeight: "600",
							fontFamily: "Gotham",
							marginRight: 5,
						}}>
						{t.length}
					</Text>
					<Text
						style={{
							fontSize: fs * 1.2,
							color: APP_COLOURS.BG2,
							fontWeight: "600",
							fontFamily: "Gotham",
						}}>
						{title}
					</Text>
					<View
						style={{
							flex: 1,
						}}
					/>
					<Text
						style={{
							fontSize: fs * 1.2,
							color: APP_COLOURS.TE,
							fontWeight: "600",
							fontFamily: "Gotham",
						}}>
						{firstStart}
					</Text>
				</View>
				<View
					style={{
						width: "100%",
						borderBottomLeftRadius: 12,
						borderBottomRightRadius: 12,
					}}>
					{t.length > 0 ? this.featuredTask(t[0], 0, fs, size) : null}
				</View>
				{/* {this.state.expandTasks ? ( */}
				<View
					style={{
						flex: 1,
						// width: "100%",
						// backgroundColor: "#FFF",
						backgroundColor: APP_COLOURS.OFFWHITE,
						padding: 5,
					}}>
					<ScrollView showsVerticalScrollIndicator={false}>
						<View
							style={{
								height: 10,
							}}
						/>
						<View
							style={{
								flex: 1,
								padding: 5,
							}}>
							{t.map((task, ii) =>
								this.taskItem(task, ii, fs, size),
							)}
						</View>
						<View
							style={{
								height: 50,
							}}
						/>
					</ScrollView>
				</View>
				{/* ) : null} */}
			</View>
		);
	};

	taskItem = (d, i, fs, size) => {
		return (
			<View
				key={`task_${d.id}`}
				style={{
					width: "100%",
					// backgroundColor: isOdd(i)
					// 	? "white"
					// 	: APP_COLOURS.WHITE_TRANSPARENT6,
					backgroundColor: "#FFF",

					// alignItems: "center",
					zIndex: 10,
					borderRadius: 8,
					opacity: d.status === "Completed" ? 0.3 : 1,
					paddingLeft: 3,
					marginBottom: 15,
					overflow: "hidden",
					borderTopColor: "#E8E8E8",
					borderTopWidth: 1,
					marginRight: 5,
					padding: 10,
					// justifyContent: "center",
					alignItems: "center",

					// shadowColor: "#000",
					// shadowOffset: { width: 0, height: 8 },
					// shadowOpacity: d.status === "Completed" ? 0 : 0.14,
					// shadowRadius: 9.32,
					// elevation: 7,
				}}>
				{/* <View
					style={{
						width: "100%",
						flexDirection: "row",
						justifyContent: "space-between",
					}}>
					<View style={{ flex: 1 }}>
						<Text
							style={{
								fontFamily: "Gotham",
								fontSize: 15,
								color: APP_COLOURS.BG2,
								fontWeight: "600",
								flexWrap: "wrap",
							}}>
							{this.state.selectedProject?.title}
						</Text>
					</View>
					<TouchableOpacity
						onPress={(event) => {
							const layout = event.nativeEvent;
							let x = layout.pageX;
							let y = layout.pageY;
							console.log("taskassignment");
							this.setState({
								// showSubTasks: false,
								// showChat: false,
								// showAssignment: !this.state.showAssignment,
								showModalArea: "taskassignment",

								xPos: x,
								yPos: y - 30,
								selectedTask: d,
								selectedID: d.id,
							});
						}}>
						<View
							style={{
								width: 60,
								// backgroundColor: '#252525',
								// padding: fs / 2,

								justifyContent: "center",
							}}>
							{d.assignment.length < 1 ? (
								<View
									style={{
										//  flex: 1,
										//  backgroundColor: '#252525',
										justifyContent: "center",
										alignItems: "center",
										opacity: 0.4,
									}}>
									<Text
										style={{
											color: APP_COLOURS.BG2,
											fontSize: fs * 0.7,
										}}>
										+ Assign
									</Text>
								</View>
							) : (
								<View
									style={{
										// width: 200,
										//  flexDirection: 'row-reverse',
										//  alignItems: 'flex-end',
										flexDirection: "row",
										// marginRight: 20,
										//  alignItems: 'center',
										// display: 'none',
										//  maxWidth: 120,
										width:
											d.assignment.length > 4
												? 4 * (size / 2)
												: d.assignment.length *
												  (size / 2),

										// backgroundColor: "red",
									}}>
									{d.assignment
										.filter(
											(d) =>
												d.id !==
												this.state.user_data.id,
										)
										.slice(0, 4)
										.map((ass, i) => {
											return (
												<View
													key={i}
													style={{
														flexDirection: "row",
														justifyContent:
															"center",
														alignItems: "center",
														backgroundColor: "#FFF",
														borderWidth: 2,
														borderColor: "#FFF",
														width: size,
														height: size,
														borderRadius: size / 2,
														shadowColor: "#6494B7",
														shadowOffset: {
															width: 0,
															height: 4,
														},
														shadowOpacity: 0.23,
														shadowRadius: 4.65,
														elevation: 8,
														left: (size / 2.2) * -i,
														// position: "absolute",
														// top: 0,
														zIndex: i + 1,
													}}>
													<Image
														// source={avatars[parseInt(ass.avatar)]}
														source={
															Avatars[
																parseInt(
																	ass.avatar,
																)
															].pp
														}
														style={{
															width: size,
															height: size,
															borderRadius:
																size / 2,
															resizeMode: "cover",
															backgroundcolor:
																APP_COLOURS.BG2,
														}}
													/>
												</View>
											);
										})}
									{d.assignment.length >= 4 ? (
										<View
											style={{
												backgroundColor: "#252525",
												flexDirection: "row",
												justifyContent: "center",
												alignItems: "center",
												//   position: 'absolute',
												right: size / -2,
												width: size,
												height: size,

												borderRadius: 15,
											}}>
											<Text
												style={{
													color: APP_COLOURS.BG2,
													fontSize: fs * 0.7,
												}}>
												+{d.assignment.length - 4}
											</Text>
										</View>
									) : null}
								</View>
							)}
						</View>
					</TouchableOpacity>
				</View> */}

				<View
					style={{
						width: "100%",
						flexDirection: "row",
						justifyContent: "space-between",
					}}>
					<TouchableOpacity
						style={{
							flex: 1,
							// width: "100%",
							justifyContent: "center",
							maxWidth: 1200,
							minWidth: 250,
						}}
						onPress={() => {
							let selectedProject = this.state.projects.filter(
								(f) => f.id === d.project_id,
							);
							this.setState({
								selectedTask: d,
								selectedProject: selectedProject[0],
								hover: true,
								currentPage: "projects",
								subPage: "Tasks",
							});
							console.log(d);
						}}>
						<View
							style={{
								width: "100%",
								padding: fs / 2,
								// justifyContent: "center",
							}}>
							<Text
								ellipsizeMode='tail'
								style={{
									width: "100%",
									color: "#000",
									fontSize: 18,
									flexWrap: "wrap",
								}}>
								{d.title}
							</Text>

							<Text
								ellipsizeMode='tail'
								style={{
									width: "100%",
									color:
										this.state.hover === d.id
											? APP_COLOURS.BLUE2
											: APP_COLOURS.BG2,
									fontSize: 13,
									flexWrap: "wrap",
									// opacity: 0.7,
									fontWeight: "400",
								}}>
								{moment(d.start_date)
									.endOf("day")
									.format("DD MMM")}{" "}
								- {moment(d.end_date).format("DD MMM")}
							</Text>

							<Text
								ellipsizeMode='tail'
								style={{
									width: "100%",
									color: APP_COLOURS.BG2,
									fontSize: 10,
									opacity: 0.7,
									display:
										this.state.user_data.id === d.createdBy
											? "flex"
											: "none",
								}}>
								assigned by you
							</Text>
						</View>
					</TouchableOpacity>

					<StatusItem
						{...this.props}
						{...d}
						fs={fs}
						handleStatusChange={async (_id, _status) => {
							let user_data = JSON.parse(
								localStorage.getItem("user_data"),
							);

							let selectedProject = this.state.projects.filter(
								(f) => f.id === d.project_id,
							);

							const mapThrough = await d.assignment_ids.forEach(
								async (id) => {
									await sendDiscordMessage(
										`${user_data.first_name} ${user_data.last_name}`,
										`### *${selectedProject[0].brand.title} - ${selectedProject[0].title}*
[Task Status] - *${_status}*
> ${d.title}
- url: ${window.location.origin}/home/projects?pid=${selectedProject[0].id}
`,
										`${window.location.origin}${
											Avatars[parseInt(user_data.avatar)]
												.pp
										}`,
										id,
									);
								},
							);

							crudUpdate(
								"projects_tasks",
								{
									id: _id,
									status: _status,
								},
								() => {
									this.getAllProjectTasks();
								},
							);
						}}
					/>
				</View>

				<View>
					{/* <Text
						ellipsizeMode='tail'
						style={{
							// width: "100%",
							margin: 3,
							color:
								this.state.hover === d.id
									? APP_COLOURS.BLUE2
									: APP_COLOURS.BG2,
							fontSize: fs - 3,
							// flexWrap: "wrap",
							width: 55,
						}}>
						{moment().isSameOrBefore(moment(d.start_date))
							? moment(d.start_date).endOf("day").fromNow()
							: moment(d.end_date).endOf("day").fromNow()}
					</Text> */}
					{/* <Text
						ellipsizeMode='tail'
						style={{
							// width: "100%",
							marginRight: 15,
							color:
								this.state.hover === d.id
									? APP_COLOURS.BLUE2
									: APP_COLOURS.BG2,
							fontSize: 10,
							// flexWrap: "wrap",
						}}>
						{moment(d.end_date).format("DD MMM")}
					</Text> */}
				</View>

				{/* <View
					style={{
						width: 90,
						marginLeft: 5,
						flexDirection: "row",
						justifyContent: "flex-start",
					}}>
					{[0, 1, 2, 3, 4, 5].map((priority) => (
						<TouchableOpacity
							key={priority}
							onPress={() =>
								this.handlePriorityChange(priority, d.id)
							}>
							{d.priority >= priority ? (
								<AutoHeightImage
									width={fs}
									style={{
										marginRight: 3,
									}}
									source={starFill}
								/>
							) : (
								<AutoHeightImage
									width={fs}
									style={{
										marginRight: 3,
										opacity: 0.5,
									}}
									source={star}
								/>
							)}
						</TouchableOpacity>
					))}
				</View> */}

				{/* {this.state.showEdit === d.id ? (
					<TouchableOpacity
						onPress={() => {
							// this.deleteProject(d.id);
							crudDelete(this.state.crudDocumentName, d, () => {
								this.getAllProjectsUser(false);
								this.setState({
									showEdit: "",
								});
							});
						}}
						style={{
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							width: fs * 1.5,
							height: fs * 1.5,

							marginLeft: 5,
							marginRight: 10,
						}}>
						<Image
							source={bin}
							style={{
								width: fs * 1.5,
								height: fs * 1.5,
								resizeMode: "contain",
								marginRight: 5,
							}}
						/>
					</TouchableOpacity>
				) : (
					<TouchableOpacity
						onPress={() => {
							this.setState({
								showEdit: d.id,
							});
						}}>
						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
								marginLeft: 5,
								width: fs * 1.5,
								height: fs * 1.5,
								marginRight: 10,
								padding: fs / 2,
								opacity: 0.3,
							}}>
							<View
								style={{
									width: fs * 1.5,
									height: fs * 1.5,
									borderRadius: 4,
									borderWidth: 1,
									bordercolor: APP_COLOURS.BG2,
								}}
							/>
						</View>
					</TouchableOpacity>
				)} */}
			</View>
		);
	};

	featuredTask = (d, i, fs, size) => {
		let featuredProject = this.state.projects.find(
			(f) => f.id === d.project_id,
		);

		return (
			<TouchableOpacity
				style={{
					width: "100%",
					borderRadius: 20,

					backgroundColor: "#090D13",
					overflow: "hidden",
					// minHeight: 170,
					// height: 160,

					minHeight: 200,
					opacity: 0.95,
				}}
				onPress={() => {
					this.setState({
						expandTasks: !this.state.expandTasks,
					});
				}}>
				<View
					style={{
						flex: 1,
						// padding: 7,
						// alignItems: "center",
						justifyContent: "center",
						padding: 20,
					}}>
					<View
						style={{
							position: "absolute",
							right: 20,
						}}>
						<Image
							source={require("../../../assets/images/skrum_menu.png")}
							style={{
								width: 100,
								height: 100,
								resizeMode: "cover",
							}}
						/>
					</View>
					<View
						style={{
							flex: 1,
							width: "100%",
						}}>
						<View
							style={{
								width: "100%",
								height: "auto",
								padding: 7,
								alignItems: "center",
								borderRadius: 10,
								// minHeight: 70,
								// marginBottom: 10,
								flexDirection: "row",
								borderColor: "#C5C5C5",
								opacity: 0.9,
							}}>
							<View
								style={{
									width: 90,
									// backgroundColor: '#252525',
									// padding: fs / 2,
									justifyContent: "center",
								}}>
								{d.assignment.length < 1 ? (
									<View
										style={{
											//  flex: 1,
											//  backgroundColor: '#252525',
											justifyContent: "center",
											alignItems: "center",
											opacity: 0.4,
										}}>
										<Text
											style={{
												color: APP_COLOURS.TEXTCOLOR,
												fontSize: fs * 0.7,
											}}>
											+ Assignment
										</Text>
									</View>
								) : (
									<View
										style={{
											// width: 200,
											//  flexDirection: 'row-reverse',
											//  alignItems: 'flex-end',
											flexDirection: "row",
											// marginRight: 20,
											//  alignItems: 'center',
											// display: 'none',
											//  maxWidth: 120,
											width:
												d.assignment.length > 5
													? 4 * (size / 2)
													: d.assignment.length *
													  (size / 2),
										}}>
										{/* .filter(
												(d) =>
													d.id !==
													this.state.user_data.id,
											) */}
										{d.assignment

											.slice(0, 5)
											.map((ass, i) => {
												return (
													<View
														key={i}
														style={{
															flexDirection:
																"row",
															justifyContent:
																"center",
															alignItems:
																"center",
															backgroundColor:
																"#FFF",
															borderWidth: 2,
															borderColor: "#FFF",
															width: size,
															height: size,
															borderRadius:
																size / 2,
															shadowColor:
																"#6494B7",
															shadowOffset: {
																width: 0,
																height: 4,
															},
															shadowOpacity: 0.23,
															shadowRadius: 4.65,
															elevation: 8,
															left:
																(size / 2.2) *
																-i,
															// position: "absolute",
															// top: 0,
															zIndex: i + 1,
														}}>
														<Image
															// source={avatars[parseInt(ass.avatar)]}
															source={
																Avatars[
																	parseInt(
																		ass.avatar,
																	)
																].pp
															}
															style={{
																width: size,
																height: size,
																borderRadius:
																	size / 2,
																resizeMode:
																	"cover",
																backgroundcolor:
																	APP_COLOURS.BG2,
															}}
														/>
													</View>
												);
											})}
										{d.assignment.length > 5 ? (
											<View
												style={{
													backgroundColor: "#252525",
													flexDirection: "row",
													justifyContent: "center",
													alignItems: "center",
													//   position: 'absolute',
													right: size / -2,
													width: size,
													height: size,

													borderRadius: 15,
												}}>
												<Text
													style={{
														color: APP_COLOURS.TEXTCOLOR,
														fontSize: fs * 0.7,
													}}>
													+{d.assignment.length - 5}
												</Text>
											</View>
										) : null}
									</View>
								)}
							</View>
						</View>
						<View
							style={{
								flex: 1,
							}}>
							<Text
								style={{
									fontFamily: "Gotham",
									fontSize: 12,
									color: APP_COLOURS.TEXTCOLOR,
									fontWeight: "600",
									width: "80%",
									flexWrap: "wrap",
								}}>
								{featuredProject?.title}
							</Text>
							<Text
								style={{
									fontFamily: "Gotham",
									fontSize: 18,
									color: "#FFF",
									fontWeight: "300",
									width: "80%",
									flexWrap: "wrap",
								}}>
								{d.title}
							</Text>
						</View>
					</View>
					<Text
						style={{
							fontFamily: "Gotham",
							fontSize: 15,
							color: APP_COLOURS.TEXTCOLOR,
							fontWeight: "300",
							marginBottom: 5,
						}}>
						due {moment(d.end_date).fromNow()}
					</Text>

					<StatusItem
						{...this.props}
						{...d}
						fs={fs}
						handleStatusChange={async (_id, _status) => {
							let user_data = JSON.parse(
								localStorage.getItem("user_data"),
							);
							let selectedProject = this.state.projects.filter(
								(f) => f.id === d.project_id,
							);

							const mapThrough = await d.assignment_ids.forEach(
								async (id) => {
									await sendDiscordMessage(
										`${user_data.first_name} ${user_data.last_name}`,
										`### *${selectedProject[0].brand.title} - ${selectedProject[0].title}*
[Task Status] - *${_status}*
> ${d.title}
- url: ${window.location.origin}/home/projects?pid=${selectedProject[0].id}
`,
										`${window.location.origin}${
											Avatars[parseInt(user_data.avatar)]
												.pp
										}`,
										id,
									);
								},
							);

							crudUpdate(
								"projects_tasks",
								{
									id: _id,
									status: _status,
								},
								() => {
									this.getAllProjectTasks();
								},
							);
						}}
					/>
				</View>
			</TouchableOpacity>
		);
	};

	async componentDidMount() {
		console.log();
		this.setState({
			currentPage: "projects",
			...this.props,
		});

		// let last_updated = moment().subtract(1, "day").unix();
		// let obj = {
		// 	last_updated: last_updated,
		// };
		// fetchProjectsLastUpdated(obj, (d) => {
		// 	this.setState({
		// 		projects: d,
		// 	});
		// });
		// return;

		try {
			this.getDeviceData(async () => {
				console.log("checkToSeeIfDataIsLocal");
				await this.checkToSeeIfDataIsLocal();
				await this.setKeyboardListener();
				await this.getUsers();

				if (typeof this.props.filteredProject !== "undefined") {
					this.setState({
						filterTab: this.props.filteredProject,
						showProjects: false,
					});
				}
				setTimeout(() => {
					this.setState({
						showModal: true,
					});
				}, 100);

				setTimeout(() => {
					this.setState({
						currentPage: "projects",
					});
				}, 100);
			});
		} catch (error) {
			// Error retrieving data
			alert(error);
		}
	}

	getDeviceData = async (next) => {
		try {
			const value = await localStorage.getItem("user_data");
			if (value !== null) {
				const d = JSON.parse(value);
				await this.setState({
					user_data: d,
					accessToken: d.accessToken,
				});
				await console.log("user_data", d);
				return next();
			}
		} catch (error) {
			// Error retrieving data
			console.log(error);
		}
	};

	checkToSeeIfDataIsLocal = async () => {
		await this.setState({
			loading: true,
		});

		console.log("checkToSeeIfDataIsLocal");
		const map_thru = await ["projects", "milestones", "tasks"].map(
			async (p) => {
				try {
					const value = await localStorage.getItem(p);
					if (value !== null) {
						const d = JSON.parse(value);
						await this.setState({
							[p]: d,
						});
						console.log("local", p);
					} else {
					}
				} catch (error) {
					// Error retrieving data
					console.log(error);
					alert(error);
				}
			},
		);

		const aa = await this.getAllProjectsUser(false);
		const bb = await this.getAllProjectMilestones();
		const cc = await this.getAllProjectTasks();

		await this.setState({
			loading: false,
		});
	};

	getLocalStorage = async (file, next, e) => {
		try {
			let json_ = await localStorage.getItem(file);
			let array = JSON.stringify(json_);
			if (array.length > 0) {
				return next(array);
			}
			return array;
		} catch (error) {
			return e();
		}
	};

	getAllProjectsUser = async (archive) => {
		console.log("getAllProjectsUser", this.state.user_data);
		// return;
		if (this.state.user_data.id === "") {
			return;
		}
		this.setState({
			loading: true,
		});

		// this.getLocalStorage(
		// 	"projects",
		// 	(d) => {
		// 		console.log(d);
		// 		// this.setState({ projects: d });
		// 	},
		// 	() => {

		// 	},
		// );

		crudGetProjects({ archive: archive }, async (status, d) => {
			console.log(status, d);
			if (status === 200) {
				await this.setState({
					projects: d,
					archiveView: archive,
					loading: false,
					// selectedProject:
					// 	this.state.selectedProject?.id !== ""
					// 		? d.find(
					// 				(obj) =>
					// 					obj.id ===
					// 					this.state.selectedProject.id,
					// 		  )
					// 		: this.state.selectedProject,
				});
				localStorage.setItem("projects", JSON.stringify(d));
				// await this.setUniqueProjectTitles(d);
			}
		});
	};
	getAllProjectMilestones = async () => {
		if (this.state.user_data.id !== "") {
			// this.getLocalStorage(
			// 	"milestones",
			// 	(d) => {
			// 		this.setState({ milestones: d });
			// 	},
			// 	() => {
			await crudRead(
				"projects_milestones",
				{
					field: "assignment_ids",
					action: "array-contains",
					value: this.state.user_data.id,
				},
				async (status, d) => {
					console.log("milestones", status, d);
					if (status === 200) {
						await this.setState({
							milestones: d,
						});

						localStorage.setItem("milestones", JSON.stringify(d));
					}
				},
			);
			// 	},
			// );
		}
	};
	getAllProjectTasks = async () => {
		if (this.state.user_data.id === "") {
			return;
		}

		// this.getLocalStorage(
		// 	"tasks",
		// 	(d) => {
		// 		this.setState({ tasks: d });
		// 	},
		// 	() => {

		// 	},
		// );

		await crudRead(
			`${this.state.crudDocumentName}_tasks`,
			{
				field: "assignment_ids",
				action: "array-contains",
				value: this.state.user_data.id,
			},
			async (status, d) => {
				console.log(status, d);
				if (status === 200) {
					await this.setState({
						tasks: d,
					});

					localStorage.setItem("tasks", JSON.stringify(d));
				}
			},
		);

		crudGetTasks(async (status, d) => {
			console.log(status, d);
			if (status === 200) {
				await this.setState({
					tasks: d,

					loading: false,
				});

				localStorage.setItem("tasks", JSON.stringify(d));
			}
		});
	};

	setUniqueProjectTitles = async (d) => {
		let uniqueProjectTitles = [];
		d.forEach((project) => {
			if (!uniqueProjectTitles.includes(project.brand?.title)) {
				uniqueProjectTitles.push(project.brand?.title);
			}
		});
		await console.log(uniqueProjectTitles);
		await this.setState({ uniqueProjectTitles });
	};

	getUsers = async () => {
		await crudGetUsers((d) => {
			console.log("get users");
			if (d.length > 0) {
				this.setState({
					users: d,
				});
				return;
			} else {
				alert("Fetching users error");
				return;
			}
		});
	};

	handleSubTaskPress = (taskIndex, subtaskIndex) => {
		// this.setState(prevState => {
		//   const tasks = prevState.tasks;
		//   tasks[taskIndex].subtasks.splice(subtaskIndex, 1);
		//   return {tasks};
		// });
	};

	async onValueChange(item, selectedValue) {
		try {
			// await AsyncStorage.setItem(item, selectedValue).then((x) => {
			// 	// console.log('onValueChange', item, selectedValue);
			// });
		} catch (error) {
			console.error(error);
		}
	}

	setKeyboardListener = async () => {
		// this.keyboardDidShowListener = Keyboard.addListener(
		// 	"keyboardDidShow",
		// 	this._keyboardDidShow,
		// );
		// this.keyboardDidHideListener = Keyboard.addListener(
		// 	"keyboardDidHide",
		// 	this._keyboardDidHide,
		// );
	};

	_keyboardDidShow = (event) => {
		console.log(event.endCoordinates.height);

		let off = event.endCoordinates.height;
		this.setState({
			keyboardOffset: off,
			keyboardActive: true,
		});
		console.log("_keyboardDidShow");
		// setTimeout(() => {
		//   this.refs.scrollView2.scrollTo();
		//   // this.scrollView2.scrollToEnd({animated: true});
		// }, 600);
	};

	_keyboardDidHide = async () => {
		if (this.state.keyboardActive) {
			await console.log("_keyboardDidHide taskitem.js");
			await this.setState({
				keyboardActive: false,
			});
		}
	};

	updateProjectItem = (d) => {
		clearTimeout(this._timeout);
		this._timeout = setTimeout(async () => {
			const mpmgh = await d.assignment_ids.forEach(async (id) => {
				await sendDiscordMessage(
					`${this.state.user_data.first_name} ${this.state.user_data.last_name}`,
					`### *${d.brand.title} - ${d.title}*
[PROJECT Overview Update]
- url: ${window.location.origin}/home/projects?pid=${d.id}
`,
					`${window.location.origin}${
						Avatars[this.state.user_data.avatar].pp
					}`,
					id,
				);
			});

			await crudUpdate(this.state.crudDocumentName, d, (status, d) => {
				// console.log(status, d);
				console.log("updateProjectItem");
				if (status === 200) {
					this.getAllProjectsUser(false);
					// return;
				}
			});
		}, 1000);
		// return;
	};

	createLog = (action, data) => {
		let obj = {
			project_id: this.state.selectedProject.id,
			action: action,
			data: data,
			date_created: moment().toISOString(),
			created_by: `${this.state.user_data.first_name} ${this.state.user_data.last_name}`,
		};
		crudCreate("projects_log", obj, () => {});
	};

	// componentDidUpdate(prevProps, preState) {
	// 	if (prevProps.projects !== this.props.projects) {
	// 		this.getDeviceData();
	// 		this.setUniqueProjectTitles();
	// 	}
	// }

	async componentWillUnmount() {
		this.setState = (state, callback) => {
			return;
		};
		// this.keyboardDidShowListener.remove();
		// this.keyboardDidHideListener.remove();
	}
}
