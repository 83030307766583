import React, { Component } from "react";
import { View, Text } from "react-native";
import ProjectDocuments from "./ProjectDocuments";
import TopTabs from "../TopTabs";

export default class AssetsLibrary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user_data: {
				id: "",
				username: "",
			},
		};
	}

	componentDidMount() {
		this.validateSession();
	}

	validateSession = async () => {
		let user_data = await localStorage.getItem("user_data");
		let email = await localStorage.getItem("email");
		let accessToken = await localStorage.getItem("accessToken");
		let refresh_token = await localStorage.getItem("refresh_token");

		if (accessToken !== null && accessToken !== "") {
			await this.setState({
				user_data: JSON.parse(user_data),
				email,
				accessToken,
				refresh_token,
			});
			setTimeout(() => {
				this.setState({
					loading: false,
					authenticating: false,
				});
			}, 1000);
		} else {
			localStorage.clear();
			window.location.href = "/";
		}
	};

	render() {
		return (
			<View>
				<TopTabs
					{...this.state}
					selectedSubPage={(d) =>
						this.setState({
							subPage: d,
						})
					}
					tabs={["ToolKits"]}
				/>
				<View
					style={{
						flex: 1,
						padding: 10,
						backgroundColor: "#FFF",
						borderRadius: 10,
						maxWidth: 500,
					}}>
					{this.state.subPage === "ToolKits" ? (
						<ProjectDocuments
							{...this.state}
							project_id={"ToolKits"}
						/>
					) : null}
				</View>
			</View>
		);
	}
}
