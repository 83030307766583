import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TextInput,
	ScrollView,
	Modal,
	Alert,
	Keyboard,
	Animated,
	LayoutAnimation,
	ActivityIndicator,
} from "react-native";
import {
	APP_COLOURS,
	TaskStatus,
	CustomLayoutSpring,
	Avatars,
} from "../../APP_VARS";
import moment from "moment";
import Lottie from "react-lottie";
import {
	crudRead,
	crudReadAll,
	crudCreate,
	crudDelete,
	crudUpdate,
	crudGetUsers,
} from "../../CRUDHELPER";
import TodayTimeline from "./TodayTimeline";
import EventCalendar from "../EventCalendar";
import AutoHeightImage from "../AutoHeightImageComp";

export default class TetriceCalendar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			projects: [],
			tasks: [],
			crudDocumentName: "projects",
			companyEvents: [],

			recurringEvents: [
				{
					title: "CM & CC end of month report",
					date: "20/12/2023",
				},
			],
		};
	}
	componentDidMount() {
		this.getDeviceData();
	}

	getDeviceData = async () => {
		try {
			const value = await localStorage.getItem("user_data");
			if (value !== null) {
				const d = JSON.parse(value);
				await this.setState({
					user_data: d,
					accessToken: d.accessToken,
				});
				this.getAllProjectTasks();
			}
		} catch (error) {
			// Error retrieving data
		}
	};

	getAllProjectTasks = () => {
		if (this.state.user_data.id === "") {
			return;
		}
		this.setState({
			loading: true,
		});
		crudRead(
			`${this.state.crudDocumentName}_tasks`,
			{
				field: "assignment_ids",
				action: "array-contains",
				value: this.state.user_data.id,
			},
			async (status, d) => {
				console.log(status, d);
				if (status === 200) {
					await this.setState({
						tasks: d,

						loading: false,
					});

					localStorage.setItem("tasks", JSON.stringify(d));
				}
			},
		);
	};

	render() {
		return (
			<View
				style={{
					flex: 1,
					padding: 10,
				}}>
				<View
					style={{
						marginBottom: 10,
					}}>
					<TouchableOpacity
						style={{
							flexDirection: "row",
							alignItems: "center",
							// position: "absolute",
						}}
						onPress={() => {
							// this.props.close();
						}}>
						<AutoHeightImage
							width={20}
							source={require("../../../assets/images/menu.png")}
						/>
						<Text
							adjustsFontSizeToFit
							style={{
								fontWeight: "800",
								fontFamily: "Gotham",
								color: APP_COLOURS.BG2,
								fontSize: 13,
								marginLeft: 4,
							}}>
							tetrice
						</Text>
					</TouchableOpacity>
					<Text
						style={{
							color: APP_COLOURS.BG2,
							fontSize: 28,
							fontWeight: "800",
							fontFamily: "Gotham",
							// marginTop: 20,
							// marginTop: 6,
						}}>
						Calendar
					</Text>
				</View>
				<View
					style={{
						flex: 1,
						width: "100%",
						// maxWidth: 400,
						backgroundColor: "#E0E3EB",
						zIndex: 999,
						// display: this.state.addProjectTask ? 'flex' : 'none',
						borderTopLeftRadius: 15,
						borderTopRightRadius: 15,
						flexDirection: "row",
					}}>
					<View
						style={{
							width: 550,
						}}>
						<View
							style={{
								flex: 1,
								padding: 10,
								// backgroundColor: "#FFF",
								// backgroundColor: "#E8E8E8",
								borderRadius: 14,
								// justifyContent: "space-between",
								alignItems: "center",
								// justifyContent: "center",
							}}>
							<View
								style={{
									// backgroundColor: "#FFF",
									padding: 10,
									borderRadius: 14,
									// marginVertical: 40,
								}}>
								<Image
									style={{
										width: 340,
										height: 340,
										resizeMode: "contain",
									}}
									source={require("../../../assets/images/market-analysis.png")}
								/>
							</View>
							<View
								style={{
									width: 500,
								}}>
								<EventCalendar
									compWidth={500}
									dateSelected={async (d) => {
										await this.setState({
											dateSelected: d,
											showFullCalendar: false,
										});
										//   await this.generateCalendar();
									}}
									calendarList={this.state.tasks}
									companyEvents={this.state.companyEvents}
									selectedDate={moment()}
								/>

								<Text
									style={{
										color: APP_COLOURS.BG2,
										// color: "#FFF",
										fontWeight: "400",
									}}>
									Recurring
								</Text>

								{this.state.recurringEvents.map((d, i) => (
									<View
										key={i}
										style={{
											padding: 4,
											flexDirection: "row",
											backgroundColor: "#FFF",
											borderRadius: 5,
											// opacity: 0.6,
											marginBottom: 3,
										}}>
										<View
											style={{
												height: 20,
												width: 3,
												backgroundColor:
													APP_COLOURS.RED2,
												borderRadius: 3,
												marginRight: 10,
											}}
										/>
										<Text
											style={{
												color: APP_COLOURS.BG2,
												// color: "#FFF",
												fontWeight: "400",
											}}>
											{/* {moment().isSame(
												moment(d.date_monthly),
											)
												? "Due: "
												: "Start: "}{" "} */}
											{d.title}
										</Text>
									</View>
								))}
							</View>
						</View>
					</View>
					<TodayTimeline
						//   {...this.state}
						tasks={this.state.tasks.filter((project) => {
							return moment(project.start_date).isSame(
								moment(),
								"day",
							);
						})}
						close={() =>
							this.setState({
								showTodayView: false,
							})
						}
					/>
				</View>
			</View>
		);
	}
}
