import React, { Component } from "react";
import { View, LayoutAnimation, Text } from "react-native";
import { APP_COLOURS, CustomLayoutSpring } from "../../APP_VARS";

export default class ProgressBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			progressPerc: "0%",
		};
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({
				progressPerc: this.props.progress,
			});
			LayoutAnimation.configureNext(CustomLayoutSpring);
		}, 300);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.progress !== this.props.progress) {
			LayoutAnimation.configureNext(CustomLayoutSpring);
			this.setState({
				progressPerc: this.props.progress,
			});
		}
	}

	render() {
		let height = 10;
		return (
			<View
				style={{
					// marginTop: 5,
					// marginBottom: 10,
					borderRadius: 3,
					flexDirection: "row",
					alignItems: "center",
					width: "100%",
					display: this.props.inProgress ? "flex" : "none",
				}}>
				<View
					style={{
						flex: 1,
						paddingRight: 10,
					}}>
					<View
						style={{
							width: "100%",
							backgroundColor: this.props.color,
							height: height,
							borderRadius: height / 2,
							opacity: 0.05,
						}}
					/>
					<View
						style={{
							width: this.state.progressPerc,
							backgroundColor: this.props.color,
							height: height,
							borderRadius: height / 2,
							position: "absolute",
							left: 0,
						}}
					/>
				</View>

				<View
					style={{
						// position: 'absolute',
						// right: 0,
						zIndex: 99,
					}}>
					<Text
						style={{
							//   color: this.props.color,
							color: "#000",
							fontSize: height + 2,
							fontWeight: "400",
							width: 50,
							textAlign: "center",
						}}>
						{parseFloat(this.state.progressPerc).toFixed(0)}%
					</Text>
				</View>
			</View>
		);
	}
}
