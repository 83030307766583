import React, { Component } from "react";
import { Modal, Image } from "react-native";

class FlashImage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			imageLoaded: false,
			showImage: false,
		};
		this.imageRef = React.createRef();
	}

	componentDidMount() {
		this.flashImage();
	}

	// preloadImage = () => {
	// 	const img = new Image();
	// 	img.src = this.props.imageUrl;
	// 	img.onload = () => {
	// 		this.setState({ imageLoaded: true }, this.flashImage);
	// 	};
	// };

	flashImage = () => {
		this.setState({ showImage: true });

		// Hide the image after 1 second
		setTimeout(() => {
			this.setState({ showImage: false });
		}, 30);
	};

	render() {
		if (this.state.showImage) {
			return (
				<Modal visible={true}>
					<Image
						source={require("./Aron and Bae.jpg")}
						style={{
							display: this.state.showImage ? "flex" : "none",
							resizeMode: "cover",
							width: window.innerWidth,
							height: window.innerHeight,
						}}
					/>
				</Modal>
			);
		} else {
			return;
		}
	}
}

export default FlashImage;
