import React, { Component } from "react";
import { View, Text, ScrollView, TouchableOpacity, Image } from "react-native";
import Font from "react-font";
import Footer from "./Footer";
import "./page.css";
class CoverPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pageMargin: 29,
		};
	}

	render() {
		// let width = 595;
		// let height = 842;

		let width = 795;
		let height = 1119;

		// let width = 2480;
		// let height = 3508;

		let margin = this.state.pageMargin;

		return (
			<View
				style={{
					height: height,
					width: width,
					backgroundColor: "#FFF",
					marginRight: 20,
				}}>
				{/* Top Start  */}
				<div className='page-break' />
				<View
					style={{
						width: "100%",
						height: "auto",
						padding: this.state.pageMargin,
						paddingTop: 100,
					}}>
					{/* Left  */}
					<View
						style={{
							justifyContent: "center",
							alignItems: "center",
						}}>
						<Text
							style={{
								fontWeight: "400",
								fontSize: 28,
							}}>
							<Font
								style={{
									color: "#FFF",
								}}
								family='Poppins'
								weight={300}>
								(MSA)
							</Font>
						</Text>
					</View>
					<View
						style={{
							justifyContent: "center",
							alignItems: "center",
							marginBottom: 15,
						}}>
						<Text
							style={{
								fontWeight: "400",
								fontSize: 26,
							}}>
							<Font
								style={{
									color: "#FFF",
								}}
								family='Poppins'
								weight={300}>
								Master Services Agreement
							</Font>
						</Text>
					</View>
				</View>
				{/* Top END */}

				<View
					style={{
						flex: 1,
						paddingLeft: this.state.pageMargin,
						paddingRight: this.state.pageMargin,
						marginBottom: 5,
					}}>
					<View
						style={{
							width: "100%",
							height: "auto",
							padding: this.state.pageMargin,
							paddingTop: 20,
						}}>
						{/* Left  */}
						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
							}}>
							<Text
								style={{
									fontWeight: "400",
									fontSize: 11,
									color: "#141D31",
								}}>
								<Font family='Poppins' weight={300}>
									between
								</Font>
							</Text>
						</View>
						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
								marginBottom: 15,
							}}>
							<Text
								style={{
									fontSize: 30,
									color: "#141D31",
								}}>
								<Font family='Poppins' weight={900}>
									tetrice
								</Font>
							</Text>
						</View>

						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
							}}>
							{[
								"Reg. 2018/492074/07",
								"VAT: 4910296310",
								"159 Rivonia Road, Morningside, Sandton, South Africa, 2146",
								"https://tetrice.co.za",
								"info@tetrice.co.za",
								"+27 81 250 2054",
								"hereon referred to as “tetrice”",
							].map((d) => (
								<Text
									style={{
										fontWeight: "400",
										fontSize: 11,
										color: "#141D31",
									}}>
									<Font family='Poppins' weight={300}>
										{d}
									</Font>
								</Text>
							))}
						</View>
					</View>

					<View
						style={{
							width: "100%",
							height: "auto",
							padding: this.state.pageMargin,
							paddingTop: 20,
						}}>
						{/* Left  */}
						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
								marginBottom: 50,
							}}>
							<Text
								style={{
									fontWeight: "400",
									fontSize: 11,
									color: "#141D31",
								}}>
								<Font family='Poppins' weight={300}>
									and
								</Font>
							</Text>
						</View>
						{/* <View
							style={{
								justifyContent: "center",
								alignItems: "center",
								marginBottom: 15,
							}}>
							<Image
								style={{
									width: 113,
									height: 50,
									resizeMode: "contain",
								}}
								source={require("../../../../assets/images/tetrice-revlon-main.png")}
							/>
						</View> */}

						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
							}}>
							<Text
								style={{
									fontSize: 16,
									color: "#141D31",
								}}>
								<Font family='Poppins' weight={600}>
									REVLON SA (Pty) ltd
								</Font>
							</Text>
							{[
								"Reg. xxxx/xxxx/xx",
								"VAT. xxx",
								"Adresss",
								"Address line 2",
								"Address line 3",
								"website",
								"hereon referred to as Client",
							].map((d) => (
								<Text
									style={{
										fontWeight: "400",
										fontSize: 11,
										color: "#141D31",
									}}>
									<Font family='Poppins' weight={300}>
										{d}
									</Font>
								</Text>
							))}
						</View>
					</View>
				</View>

				<Footer
					title={"master services agreement"}
					margin={margin}
					pageNumber={2}
					totalPages={10}
				/>

				<View
					style={{
						width: 1,
						backgroundColor: "#1C2B46",
						height: height - 140,
						position: "absolute",
						left: margin,
						top: margin,
					}}
				/>
				<View
					style={{
						width: 1,
						backgroundColor: "#1C2B46",
						height: height - 140,
						position: "absolute",
						right: margin,
						top: margin,
					}}
				/>
			</View>
		);
	}
}

export default CoverPage;
