// import React, { Component } from "react";
import React, { useState, useEffect, Component } from "react";

import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
	useParams,
	useLocation,
} from "react-router-dom";
import { Dimensions } from "react-native";

import DevicePolicy from "./OpenUrls/DevicePolicy";
import PostApproval from "./Authed/ContentCalendar/PostApproval";
import PerformanceReview from "./Authed/PerformanceReview/PerformanceReview";

import Home from "./Authed/Home";
import Login from "./Unauthed/Login";
import HomeMobile from "./Authed/HomeMobile";
import CompanyDocuments from "./OpenUrls/CompanyDocuments";
import LiveStream from "./OpenUrls/STREAM";
import ContentApproval from "./Authed/ContentCalendar/ContentApproval";
import { ProposalUrl } from "./OpenUrls/Proposals/ProposalUrl";
import { PresentationUrl } from "./OpenUrls/Presentations";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

if (typeof window !== "undefined") {
	require("smoothscroll-polyfill").polyfill();
}

function useQuery() {
	// WORKS FOR QURY URL
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ResponsiveHome = () => {
	// State to hold screen width
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	let query = useQuery();
	const { page } = useParams(); // This hooks allows you to access the
	let project_id = query.get("pid");
	// Effect to listen for screen resize
	useEffect(() => {
		const handleResize = () => setScreenWidth(window.innerWidth);
		window.addEventListener("resize", handleResize);

		// Cleanup
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	// Conditionally rendering based on screen width
	const renderComponent =
		screenWidth < 700 ? (
			<HomeMobile page={page} project_id={project_id} />
		) : (
			<Home page={page} project_id={project_id} />
		);

	return <div>{renderComponent}</div>;
};

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
			console.log("Polly fill for smooth scroll in safari");
		} else {
			// production code
		}

		this.resize();
	}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	render() {
		return (
			<Router>
				<Routes>
					<Route path='/login' element={<Login />} />
					{/* <Route
						path='/Home/:page/:project'
						element={
							this.state.screenWidth < 700 ? (
								<HomeMobile />
							) : (
								<Home />
							)
						}
					/> */}

					<Route
						path='/home/:page'
						element={
							this.state.screenWidth < 700 ? (
								<HomeMobile />
							) : (
								<ResponsiveHome />
							)
						}
					/>

					<Route
						path='/postapproval/:postid/:token'
						element={<PostApproval />}
					/>

					<Route
						path='/caa/:contentid/:token'
						element={<ContentApproval />}
					/>

					<Route
						path='/performancereview/:surveyId/:token'
						element={<PerformanceReview />}
					/>

					<Route path='/livestream/:token' element={<LiveStream />} />

					{/* 192.168.20.11:3000/postapproval/retinolhpr1/xV3-0Ea5T2eLbRJYo-eGoT0Dq_Lq9o1-kyGK5gg */}

					<Route
						path='/smreport/:brand/:token'
						element={<PerformanceReview />}
					/>

					<Route
						path='/doc/:documentId/:token'
						element={<CompanyDocuments />}
					/>

					{/* <Route
						path='/proposal/:proposal_id/:token'
						element={<ProposalUrl />}
					/>

					<Route
						path='/presentation/:presentation_id/:token'
						element={<PresentationUrl />}
					/> */}

					<Route path='*' element={<Navigate to='/login' />} />
				</Routes>
			</Router>
		);
	}
}

export default index;
