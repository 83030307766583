import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import { Avatars, StatusColour, TaskStatus } from "../../APP_VARS";
import StatusItem from "../DailyPlanner/StatusItem";
import Lottie from "react-lottie";
import {
	createSurveyApproval,
	crudCreate,
	crudDelete,
	crudGetUsers,
	crudRead,
	crudReadAll,
	crudUpdate,
} from "../../CRUDHELPER";
import Fade from "react-reveal/Fade";
import * as animationData from "./RatingJson.json";

import AutoHeightImage from "../AutoHeightImageComp";
import FeedBackSent from "./FeedBackSent";
import SendingFeedback from "./SendingFeedback";
import SurveyItem from "./Survey";

let reviews = [
	{
		id: "revlonNov2023",
		title: "REVLON",
		// questions: [
		// 	{
		// 		q_number: "q1",
		// 		question:
		// 			"How satisfied are you with our community engagement levels?",
		// 		type: "scale",
		// 		scale: 5,
		// 		description:
		// 			"1 being very dissatisfied, 5 being very satisfied",
		// 	},
		// 	{
		// 		q_number: "q2",
		// 		question:
		// 			"How satisfied are you with the quality of the design work submitted ?",
		// 		type: "scale",
		// 		scale: 5,
		// 		description:
		// 			"1 being very dissatisfied, 5 being very satisfied",
		// 	},
		// 	{
		// 		q_number: "q3",
		// 		question: "Howsdfvsdfvsdfv ?",
		// 		type: "scale",
		// 		scale: 5,
		// 		description:
		// 			"1 being very dissatisfied, 5 being very satisfied",
		// 	},
		// ],
		questions: [
			{
				q_number: "q1",
				question:
					"How would you rate tetrice's community engagement and community management?",

				scale: 5,

				type: "multiple_choice",
				options: [
					"Excellent",
					"Good",
					"Average",
					"Below Average",
					"Poor",
				],
			},
			{
				q_number: "q2",
				question:
					"How would you rate tetrice's response time to community inquiries?",
				// type: "multiple_choice",
				// options: ["Excellent", "Good", "Average", "Poor", "Very Poor"],
				description:
					"1 being very dissatisfied, 5 being very satisfied",
				type: "scale",
			},
			{
				q_number: "q3",
				question:
					"How do you rate the quality of the content from tetrice's design team?",
				// type: "multiple_choice",
				// options: [
				// 	"Excellent",
				// 	"Good",
				// 	"Average",
				// 	"Below Average",
				// 	"Poor",
				// ],
				description:
					"1 being very dissatisfied, 5 being very satisfied",
				type: "scale",
			},
			{
				q_number: "q4",
				question:
					"How relevant do you find the content to your company's overall social media strategy?",
				type: "multiple_choice",
				options: [
					"Extremely Relevant",
					"Somewhat Relevant",
					"Neutral",
					"Somewhat Irrelevant",
					"Not Relevant At All",
				],
			},
			{
				q_number: "q5",
				question:
					"How do you perceive your company's image in the media?",
				type: "multiple_choice",
				options: [
					"Outstanding",
					"Impactful",
					"Average",
					"poor",
					"irrelevant",
				],
				scale: 5,
				description: "",
			},
			{
				q_number: "q6",
				question:
					"In your opinion, have you noticed any improvement in your social page content?",
				type: "multiple_choice",
				options: ["Absolutely", "Somewhat", "moderate", "Not at all"],
			},
			{
				q_number: "q7",
				question:
					"What improvements would you suggest for community management, content creation, and PR activities?",
				type: "open_ended",
			},

			{
				q_number: "q8",
				question:
					"How would you rate the turnaround time of tetrice's services?",
				type: "scale",
				scale: 5,
				description: "1 being very slow, 5 being very fast",
			},
			{
				q_number: "q9",
				question:
					"How do you perceive the friendliness of tetrice's staff ?",
				type: "scale",
				scale: 5,
				description: "1 being very unfriendly, 5 being very friendly",
			},
			{
				q_number: "q10",
				question:
					"How do you rate the proficiency of tetrice's staff to perform?",
				type: "scale",
				scale: 5,
				description: "1 being very unfriendly, 5 being very friendly",
			},
			{
				q_number: "q11",
				question: "How would you rate tetrice's communication skills?",
				type: "scale",
				scale: 5,
				description: "1 being very poor, 5 being excellent",
			},
			{
				q_number: "q12",
				question:
					"What areas do you think we need to improve in our business operations or services?",
				type: "open_ended",
			},
			{
				q_number: "q13",
				question:
					"How satisfied are you with the tetrice's overall performance?",
				type: "scale",
				scale: 5,
				description:
					"1 being very dissatisfied, 5 being very satisfied",
			},
			{
				q_number: "q14",
				question: `Leave us with a final comment. 
Don't hold back!
`,
				type: "open_ended",
			},
		],
	},
	{
		id: "testsurvey",
		title: "REVLON",

		questions: [
			{
				q_number: "q1",
				question:
					"How would you rate our community engagement and community management?",

				scale: 5,

				type: "multiple_choice",
				options: [
					"Excellent",
					"Good",
					"Average",
					"Below Average",
					"Poor",
				],
			},
			{
				q_number: "q2",
				question:
					"How would you rate our response time to community inquiries?",
				// type: "multiple_choice",
				// options: ["Excellent", "Good", "Average", "Poor", "Very Poor"],
				description:
					"1 being very dissatisfied, 5 being very satisfied",
				type: "scale",
			},
			{
				q_number: "q3",
				question: "How do you rate the quality of our content?",
				// type: "multiple_choice",
				// options: [
				// 	"Excellent",
				// 	"Good",
				// 	"Average",
				// 	"Below Average",
				// 	"Poor",
				// ],
				description:
					"1 being very dissatisfied, 5 being very satisfied",
				type: "scale",
			},
			{
				q_number: "q4",
				question:
					"How relevant do you find our content to your company's overall social media strategy?",
				type: "multiple_choice",
				options: [
					"Extremely Relevant",
					"Somewhat Relevant",
					"Neutral",
					"Somewhat Irrelevant",
					"Not Relevant At All",
				],
			},
			{
				q_number: "q5",
				question:
					"How do you perceive your company's image in the media?",
				type: "scale",
				scale: 5,
				description: "",
			},
			{
				q_number: "q6",
				question:
					"In your opinion, have you noticed any improvement in your social page content?",
				type: "multiple_choice",
				options: ["Absolutely", "Somewhat", "Not at all", "irrelevant"],
			},
			{
				q_number: "q7",
				question:
					"What improvements would you suggest for our community management, content creation, and PR activities?",
				type: "open_ended",
			},

			{
				q_number: "q8",
				question:
					"How would you rate the turnaround time of our services or products?",
				type: "scale",
				scale: 5,
				description: "1 being very slow, 5 being very fast",
			},
			{
				q_number: "q9",
				question:
					"How do you perceive the friendliness of our staff or customer service?",
				type: "scale",
				scale: 5,
				description: "1 being very unfriendly, 5 being very friendly",
			},
			{
				q_number: "q10",
				question: "How would you rate our team's communication skills?",
				type: "scale",
				scale: 5,
				description: "1 being very poor, 5 being excellent",
			},
			{
				q_number: "q11",
				question:
					"What areas do you think we need to improve in our business operations or services?",
				type: "open_ended",
			},
			{
				q_number: "q12",
				question:
					"How satisfied are you with the overall performance of our company?",
				type: "scale",
				scale: 5,
				description:
					"1 being very dissatisfied, 5 being very satisfied",
			},
			{
				q_number: "q13",
				question: `Leave us with a final comment. 
Don't hold back!.  
`,
				type: "open_ended",
			},
		],
	},

	{
		id: "samafitness2023",
		title: "SAMA Fitness",
		// questions: [
		// 	{
		// 		q_number: "q1",
		// 		question:
		// 			"How satisfied are you with our community engagement levels?",
		// 		type: "scale",
		// 		scale: 5,
		// 		description:
		// 			"1 being very dissatisfied, 5 being very satisfied",
		// 	},
		// 	{
		// 		q_number: "q2",
		// 		question:
		// 			"How satisfied are you with the quality of the design work submitted ?",
		// 		type: "scale",
		// 		scale: 5,
		// 		description:
		// 			"1 being very dissatisfied, 5 being very satisfied",
		// 	},
		// 	{
		// 		q_number: "q3",
		// 		question: "Howsdfvsdfvsdfv ?",
		// 		type: "scale",
		// 		scale: 5,
		// 		description:
		// 			"1 being very dissatisfied, 5 being very satisfied",
		// 	},
		// ],
		questions: [
			{
				q_number: "q1",
				question: "How would you rate tetrice's performance this year?",

				scale: 5,

				type: "multiple_choice",
				options: [
					"Excellent",
					"Good",
					"Average",
					"Below Average",
					"Poor",
				],
			},
			{
				q_number: "q2",
				question:
					"How would you rate tetrice's response time to tech support queries?",
				// type: "multiple_choice",
				// options: ["Excellent", "Good", "Average", "Poor", "Very Poor"],
				description:
					"1 being very dissatisfied, 5 being very satisfied",
				type: "scale",
			},
			{
				q_number: "q3",
				question:
					"How do you rate the usability of the Gym management SaaS software?",
				// type: "multiple_choice",
				// options: [
				// 	"Excellent",
				// 	"Good",
				// 	"Average",
				// 	"Below Average",
				// 	"Poor",
				// ],
				description:
					"1 being very dissatisfied, 5 being very satisfied",
				type: "scale",
			},
			{
				q_number: "q4",
				question: "How likely are you to continue ?",
				type: "multiple_choice",
				options: [
					"Extremely Relevant",
					"Somewhat Relevant",
					"Neutral",
					"Somewhat Irrelevant",
					"Not Relevant At All",
				],
			},
			{
				q_number: "q5",
				question:
					"How do you perceive your company's image in the media?",
				type: "multiple_choice",
				options: [
					"Outstanding",
					"Impactful",
					"Average",
					"poor",
					"irrelevant",
				],
				scale: 5,
				description: "",
			},
			{
				q_number: "q6",
				question:
					"In your opinion, have you noticed any improvement in your social page content?",
				type: "multiple_choice",
				options: ["Absolutely", "Somewhat", "moderate", "Not at all"],
			},
			{
				q_number: "q7",
				question:
					"What improvements would you suggest for community management, content creation, and PR activities?",
				type: "open_ended",
			},

			{
				q_number: "q8",
				question:
					"How would you rate the turnaround time of tetrice's services?",
				type: "scale",
				scale: 5,
				description: "1 being very slow, 5 being very fast",
			},
			{
				q_number: "q9",
				question:
					"How do you perceive the friendliness of tetrice's staff ?",
				type: "scale",
				scale: 5,
				description: "1 being very unfriendly, 5 being very friendly",
			},
			{
				q_number: "q10",
				question:
					"How do you rate the proficiency of tetrice's staff to perform?",
				type: "scale",
				scale: 5,
				description: "1 being very unfriendly, 5 being very friendly",
			},
			{
				q_number: "q11",
				question: "How would you rate tetrice's communication skills?",
				type: "scale",
				scale: 5,
				description: "1 being very poor, 5 being excellent",
			},
			{
				q_number: "q12",
				question:
					"What areas do you think we need to improve in our business operations or services?",
				type: "open_ended",
			},
			{
				q_number: "q13",
				question:
					"How satisfied are you with the tetrice's overall performance?",
				type: "scale",
				scale: 5,
				description:
					"1 being very dissatisfied, 5 being very satisfied",
			},
			{
				q_number: "q14",
				question: `Leave us with a final comment. 
Don't hold back!
`,
				type: "open_ended",
			},
		],
	},
];

const PerformanceReview = () => {
	const [survey, setSurvey] = useState(reviews[0]);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const [beginSurvey, setbeginSurvey] = useState(false);

	const [sent, setSent] = useState(false);
	const [sending, setSending] = useState(false);

	const { surveyId, token } = useParams();

	useEffect(() => {
		const fetchSurvey = async () => {
			let surveyData = reviews.filter((d) => d.id === surveyId);

			setSurvey(surveyData[0]);
			console.log(surveyData);
			document.title = `Customer satisfaction - ${survey.title}`;
		};

		fetchSurvey();
	}, [surveyId, token]);

	// Approval handler
	const handleApproval = async (data) => {
		console.log("handleApproval", data);
		// return;
		setSending(true);

		data.token = token;

		createSurveyApproval({ ...data }, (status_) => {
			if (status_ === 200) {
				setSending(false);
				setSent(true);
			} else {
				setSending(false);
				setSent(false);
				setError(true);
			}
		});
	};

	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error}</div>;

	if (sending) return <SendingFeedback />;
	if (sent) return <FeedBackSent />;

	let screenWidth = window.innerWidth;

	screenWidth = screenWidth > 450 ? 450 : screenWidth;

	return (
		<View
			style={{
				backgroundColor: "#000",
				width: "100%",
				overflow: "hidden",
				height: window.innerHeight,
				alignItems: "center",
			}}>
			<View
				style={{
					flex: 1,
					width: screenWidth,
					// marginVertical: 50,
				}}>
				{beginSurvey ? (
					<View
						style={{
							width: "100%",
							flexDirection: "row",
							justifyContent: "space-between",
							// justifyContent: "center",
							alignItems: "center",
							padding: 5,
							// marginTop: 50,
						}}>
						<View
							style={{
								padding: 5,
								// flexDirection: "row",
							}}>
							<TouchableOpacity
								style={{
									flexDirection: "row",
									alignItems: "center",
									// position: "absolute",
								}}
								onPress={() => {
									// this.props.close();
								}}>
								<AutoHeightImage
									width={35}
									source={require("../../../assets/images/menu.png")}
								/>
								<Text
									adjustsFontSizeToFit
									style={{
										fontWeight: "800",
										fontFamily: "Gotham",
										color: "#FFF",
										fontSize: 19,
										marginLeft: 4,
									}}>
									tetrice
								</Text>
							</TouchableOpacity>
						</View>
						<View style={{}}>
							<Lottie
								options={{
									loop: true,
									autoplay: true,
									animationData: animationData,
									rendererSettings: {
										preserveAspectRatio: "xMidYMid slice",
									},
								}}
								height={95}
								width={140}
							/>
						</View>
					</View>
				) : (
					<View
						style={{
							flexDirection: "row",
							// justifyContent: "space-between",
							justifyContent: "center",
							alignItems: "center",
							padding: 5,
							// marginTop: 50,
						}}>
						<View
							style={{
								padding: 5,
								// flexDirection: "row",
							}}>
							<TouchableOpacity
								style={{
									flexDirection: "row",
									alignItems: "center",
									// position: "absolute",
								}}
								onPress={() => {
									// this.props.close();
								}}>
								<AutoHeightImage
									width={45}
									source={require("../../../assets/images/menu.png")}
								/>
								<Text
									adjustsFontSizeToFit
									style={{
										fontWeight: "800",
										fontFamily: "Gotham",
										color: "#FFF",
										fontSize: 23,
										marginLeft: 4,
									}}>
									tetrice
								</Text>
							</TouchableOpacity>
						</View>
					</View>
				)}
				<View style={{ flex: 1 }}>
					{beginSurvey ? (
						<SurveyItem
							screenWidth={screenWidth}
							id={surveyId}
							{...survey}
							handleApproval={(data) => handleApproval(data)}
						/>
					) : (
						<ScrollView showsVerticalScrollIndicator={false}>
							<View style={{ flex: 1 }}>
								<View
									style={{
										flex: 1,
										justifyContent: "space-between",
										alignItems: "center",
										padding: 5,
										marginTop: 30,
									}}>
									<View style={{}}>
										<Text
											style={{
												color: "#FFF",
												fontSize: 19,
												fontWeight: "800",
												fontFamily: "Gotham",
												opacity: 0.8,
											}}>
											Customer Satisfaction Survey
										</Text>

										{/* <Text
								style={{
									fontSize: 14,
									color: "#FFF",
									fontWeight: "300",
									padding: 2,
									opacity: 0.7,
									marginBottom: 20,
								}}>
								Instagram survey approval
							</Text> */}
									</View>
									<View
										style={{
											// position: "absolute",
											// right: 10,
											// top: 10,
											maxWidth: 500,
											marginTop: 20,
										}}>
										<Lottie
											options={{
												loop: true,
												autoplay: true,
												animationData: animationData,
												rendererSettings: {
													preserveAspectRatio:
														"xMidYMid slice",
												},
											}}
											height={250}
											width={350}
										/>
									</View>
								</View>

								<View
									style={{
										padding: 20,
										// paddingLeft: 20,
									}}>
									<Text
										style={{
											fontSize: 16,
											color: "#FFF",
											fontFamily: "Gotham",
											fontWeight: "500",
											marginVertical: 12,
										}}>
										Dear valued customer,
									</Text>
									<Text
										style={{
											fontSize: 16,
											color: "#FFF",
											fontFamily: "Gotham",
											fontWeight: "300",
											marginTop: 10,
											// textAlign: "justify",
										}}>
										At{" "}
										<Text
											style={{
												fontSize: 15,
												color: "#FFF",
												fontFamily: "Gotham",
												fontWeight: "800",
											}}>
											tetrice
										</Text>
										, we continuously strive to deliver
										exceptional service and improve our
										offerings. As we approach the end of the
										year, we would like to take this
										opportunity to get your feedback on the
										services we have provided you in the
										year that has past. Your feedback is
										crucial in guiding our efforts. We
										kindly invite you to participate in our
										Customer Satisfaction Survey. This
										survey aims to evaluate our performance
										in community management, content
										creation, and public relations
										activities.
									</Text>
									<Text
										style={{
											fontSize: 16,
											color: "#FFF",
											fontFamily: "Gotham",
											fontWeight: "300",
											marginTop: 10,
										}}>
										Along with the many other services we
										provide, these services form part of the
										broader expansion of tetrice consulting
										and your honest feedback is critical to
										our success and positive ongoing
										relationship with you.
									</Text>
									{/* <Text
								style={{
									fontSize: 16,
									color: "#FFF",
									fontFamily: "Gotham",
									fontWeight: "300",
									marginTop: 10,
								}}>
								The survey will take approximately 2 minutes to
								complete, and your responses will be kept
								confidential. As a token of our appreciation,
								kindly attach your address for us to survey you
								a thank you gift from us to you.
							</Text> */}
									<Text
										style={{
											fontSize: 16,
											color: "#FFF",
											fontFamily: "Gotham",
											fontWeight: "300",
											marginTop: 10,
										}}>
										The survey will take approximately 2
										minutes to complete, and your responses
										will be kept confidential.
									</Text>
									<Text
										style={{
											fontSize: 16,
											color: "#FFF",
											fontFamily: "Gotham",
											fontWeight: "300",
											marginTop: 10,
										}}>
										We greatly appreciate your ongoing
										business and support. Wishing you a safe
										and prosperous holiday season.
									</Text>
									<Text
										style={{
											fontSize: 16,
											color: "#FFF",
											fontFamily: "Gotham",
											fontWeight: "300",
											marginTop: 10,
										}}>
										Sincerely,
									</Text>
									<Text
										style={{
											fontSize: 16,
											color: "#FFF",
											fontFamily: "Gotham",
											fontWeight: "500",
											marginTop: 10,
										}}>
										team{" "}
										<Text
											style={{
												fontSize: 15,
												color: "#FFF",
												fontFamily: "Gotham",
												fontWeight: "800",
											}}>
											tetrice
										</Text>
									</Text>
									{/* <Text
										style={{
											fontSize: 11,
											color: "#FFF",
											fontFamily: "Gotham",
											fontWeight: "300",
										}}>
										Director
									</Text> */}
									<TouchableOpacity
										style={{
											borderRadius: 10,
											justifyContent: "center",
											alignItems: "center",
											marginVertical: 25,
											flexDirection: "row",
											overflow: "hidden",
											marginTop: 60,
										}}
										onPress={() => {
											setbeginSurvey(true);
										}}>
										<Image
											source={
												require("./button.svg").default
											}
											style={{
												width: "101%",
												height: 45,
												resizeMode: "cover",
											}}
										/>

										<Text
											style={{
												fontSize: 16,
												// marginTop: 12,
												fontWeight: "600",
												color: "#FFF",
												position: "absolute",
											}}>
											Let's begin
										</Text>
									</TouchableOpacity>
								</View>
							</View>
						</ScrollView>
					)}
				</View>
			</View>
		</View>
	);
};

export default PerformanceReview;
