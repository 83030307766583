import React, { useState } from "react";
import {
	View,
	Image,
	Text,
	StyleSheet,
	ScrollView,
	TouchableOpacity,
} from "react-native";
import ImagePickerComponent from "./ImgUploader";
import { APP_COLOURS } from "../../APP_VARS";
import Font from "react-font";
import { crudCreateWithBase64 } from "../../CRUDHELPER";
import ImageUploaderComp from "./ImageUploaderComp";

const ListingImageBox = ({ uploadImage }) => {
	const [images, setImages] = useState([]);

	const handleImagePicked = (imageData) => {
		console.log();
		setImages([...images, imageData[0]]);
		// uploadImage(imageData);
	};

	const handleDeleteImage = (index) => {
		setImages((prevImages) => prevImages.filter((_, idx) => idx !== index));
	};

	return (
		<ScrollView contentContainerStyle={styles.container}>
			{images.map((img, index) => (
				<View key={index} style={styles.imageContainer}>
					<Image source={{ uri: img.uri }} style={styles.image} />
					<Text>
						Original: {img.width}x{img.height}
					</Text>

					<button
						onClick={() => handleDeleteImage(index)}
						style={{ position: "absolute", top: 0, right: 0 }}>
						Delete
					</button>

					<TouchableOpacity
						style={{
							width: "100%",
							padding: 10,
						}}
						onPress={async () => {
							let obj = {
								document: img,
							};
							// console.log(obj);
							// return;
							crudCreateWithBase64(
								"dm_listingImages",
								obj,
								(s, data) => {
									uploadImage(data);
								},
							);
						}}>
						<View
							style={{
								backgroundColor: APP_COLOURS.BG2,
								width: "100%",
								padding: 13,
								height: 45,
								borderRadius: 25,
								justifyContent: "center",
								alignItems: "center",
							}}>
							<Text
								style={{
									alignItems: "center",
									justifyContent: "center",
									color: "#FFF",
									fontSize: 15,
								}}>
								<Font weight={700} family='Montserrat'>
									Upload
								</Font>
							</Text>
						</View>
					</TouchableOpacity>
				</View>
			))}
			{/* {images.length < 10 && (
				<ImagePickerComponent onImagePicked={handleImagePicked} />
			)} */}
			{images.length < 1 && (
				<ImageUploaderComp onImagesUploaded={handleImagePicked} />
			)}
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	container: {
		alignItems: "center",
		justifyContent: "center",
	},
	imageContainer: {
		margin: 10,
		alignItems: "center",
		justifyContent: "center",
	},
	image: {
		width: 200,
		height: 200,
		resizeMode: "cover",
	},
});

export default ListingImageBox;
