import React, { useState } from "react";
import {
	Modal,
	View,
	Text,
	TextInput,
	TouchableOpacity,
	StyleSheet,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import Font from "react-font";

const AppColours = {
	PLACEHOLDER: "#888", // Replace with your actual color code
	GREEN: "#0f0", // Replace with your actual color code
	RED: "#f00", // Replace with your actual color code
	WHITE: "#fff", // Replace with your actual color code
};

const AddGrowthForm = ({ isVisible, onClose }) => {
	const [data, setData] = useState({
		followers: "",
		campaignFocus: "",
		numberOfFeedPosts: "",
		ugcStories: "",
		ugcPosts: "",
		// Add more fields as required
	});

	const handleInputChange = (field, value) => {
		setData({ ...data, [field]: value });
	};

	const handleSubmit = async () => {
		// Here, you would send the data to your server endpoint
		console.log("Data to submit", data);
		// Close the modal
		onClose();
	};

	return (
		<View style={styles.modalView}>
			<Text
				style={{
					fontSize: 16 * 1.618,
					color: "#FFF",
					paddingBottom: 5,
				}}>
				<Font family='Gotham' weight={400}>
					End of month report data
				</Font>
			</Text>
			<Text
				style={{
					fontSize: 16,
					color: "#FFF",
					paddingBottom: 5,
				}}>
				<Font family='Gotham' weight={400}>
					Select social Handle
				</Font>
			</Text>

			<View style={styles.inputContainer}>
				<Text style={styles.label}>Campaigns / Focus </Text>
				<TextInput
					style={[styles.input, {}]}
					multiline
					numberOfLines={5}
					placeholder='describe the campaigns and focus for the motnh that has passed'
					value={data.campaigns}
					onChangeText={(text) =>
						handleInputChange("campaigns", text)
					}
				/>
			</View>

			<Text
				style={{
					fontSize: 16 * 1.618,
					color: "#FFF",
					paddingBottom: 5,
					marginTop: 20,
				}}>
				<Font family='Gotham' weight={400}>
					Overview
				</Font>
			</Text>
			<Text
				style={{
					fontSize: 16 * 1.618,
					color: "#FFF",
					paddingBottom: 5,
					marginTop: 20,
				}}>
				<Font family='Gotham' weight={400}>
					Accounts reached
				</Font>
			</Text>
			<Text
				style={{
					fontSize: 16,
					color: "#FFF",
					paddingBottom: 5,
				}}>
				<Font family='Gotham' weight={300}>
					Go into insights tab on instagram and select accounts
					reached
				</Font>
			</Text>

			{[
				{
					title: "Accounts Reached",
					state: "a_accounts_reached",
				},
				{
					title: "Accounts Reached: Followers",
					state: "a_accounts_reached_followers",
				},
				{
					title: "Accounts Reached: Non-Followers",
					state: "a_accounts_reached_nonfollowers",
				},

				{
					title: "Accounts Reached: Impressions",
					state: "a_impressions",
				},
				{
					title: "Accounts Reached: Profile visits",
					state: "a_profile_visits",
				},
				{
					title: "Accounts Reached: External links taps",
					state: "a_external_link_taps",
				},
			].map((d) => (
				<View key={`key${d.state}`} style={styles.inputContainer}>
					<Text
						style={[
							styles.label,
							{
								opacity:
									typeof data[d.state] !== "undefined"
										? 0.3
										: 1,
							},
						]}>
						{d.title}
					</Text>
					<TextInput
						style={styles.input}
						placeholder='total'
						value={data[d.state]}
						onChangeText={(text) =>
							handleInputChange(d.state, text)
						}
					/>
				</View>
			))}

			<Text
				style={{
					fontSize: 16 * 1.618,
					color: "#FFF",
					paddingBottom: 5,
					marginTop: 20,
				}}>
				<Font family='Gotham' weight={400}>
					Content you shared
				</Font>
			</Text>
			<Text
				style={{
					fontSize: 16,
					color: "#FFF",
					paddingBottom: 5,
				}}>
				<Font family='Gotham' weight={300}>
					Go into insights tab on instagram and scroll down to content
					you shared
				</Font>
			</Text>

			{[
				{
					title: "# of Feed posts",
					state: "engagement_posts",
				},
				{
					title: "# of Stories",
					state: "engagement_stories",
				},
				{
					title: "# of Reels",
					state: "engagement_reels",
				},
				{
					title: "UGC (STORIES)",
					state: "engagement_ugc_stories",
				},
				{
					title: "UGC (POSTS)",
					state: "engagement_ugc_posts",
				},
			].map((d) => (
				<View key={`key${d.state}`} style={styles.inputContainer}>
					<Text style={styles.label}>{d.title}</Text>
					<TextInput
						style={styles.input}
						placeholder={d.title}
						value={data[d.state]}
						onChangeText={(text) =>
							handleInputChange(d.state, text)
						}
					/>
				</View>
			))}

			<Text
				style={{
					fontSize: 16 * 1.618,
					color: "#FFF",
					paddingBottom: 5,
					marginTop: 20,
				}}>
				<Font family='Gotham' weight={400}>
					Accounts engaged
				</Font>
			</Text>
			<Text
				style={{
					fontSize: 16,
					color: "#FFF",
					paddingBottom: 5,
				}}>
				<Font family='Gotham' weight={300}>
					Go into insights tab on instagram and select accounts
					engaged
				</Font>
			</Text>

			<Text
				style={{
					fontSize: 16,
					color: "#FFF",
					paddingBottom: 5,
				}}>
				<Font family='Gotham' weight={400}>
					Engagement: Posts
				</Font>
			</Text>
			{[
				{
					title: "Posts: ",
					state: "engagement_posts",
					placeholder: "Enter posts",
				},
				{
					title: "Posts: Sgares",
					state: "engagement_posts_shares",
					placeholder: "Enter post shares",
				},
				{
					title: "Posts:Comments ",
					state: "engagement_posts_comments",
					placeholder: "Enter post comments",
				},
				{
					title: "Posts: Saves",
					state: "engagement_posts_saves",
					placeholder: "Enter post saves",
				},
				{
					title: "Posts: likes",
					state: "engagement_posts_likes",
					placeholder: "Enter post likes",
				},
			].map((d) => (
				<View key={`key${d.state}`} style={styles.inputContainer}>
					<Text style={styles.label}>{d.title}</Text>
					<TextInput
						style={styles.input}
						placeholder='total'
						value={data[d.state]}
						onChangeText={(text) =>
							handleInputChange(d.state, text)
						}
					/>
				</View>
			))}

			<Text
				style={{
					fontSize: 16,
					color: "#FFF",
					paddingBottom: 5,
				}}>
				<Font family='Gotham' weight={400}>
					Engagement: Stories
				</Font>
			</Text>

			{[
				{
					title: "Stories",
					state: "engagement_stories",
					placeholder: "Enter stories",
				},
				{
					title: "Stories: Replies",
					state: "engagement_stories_replies",
					placeholder: "Enter replies",
				},
				{
					title: "Stories: Shares",
					state: "engagement_stories_shares",
					placeholder: "Enter shares",
				},
			].map((d) => (
				<View key={`key${d.state}`} style={styles.inputContainer}>
					<Text style={styles.label}>{d.title}</Text>
					<TextInput
						style={styles.input}
						placeholder='total'
						value={data[d.state]}
						onChangeText={(text) =>
							handleInputChange(d.state, text)
						}
					/>
				</View>
			))}

			<Text
				style={{
					fontSize: 16,
					color: "#FFF",
					paddingBottom: 5,
				}}>
				<Font family='Gotham' weight={400}>
					Engagement: Reels
				</Font>
			</Text>
			{[
				{
					title: "Reels: ",
					state: "engagement_reels",
					placeholder: "Enter Reels",
				},
				{
					title: "Posts: Sgares",
					state: "engagement_reels_shares",
					placeholder: "Enter Reels shares",
				},
				{
					title: "Posts:Comments ",
					state: "engagement_reels_comments",
					placeholder: "Enter Reels comments",
				},
				{
					title: "Posts: Saves",
					state: "engagement_reels_saves",
					placeholder: "Enter Reels saves",
				},
				{
					title: "Posts: likes",
					state: "engagement_reels_likes",
					placeholder: "Enter Reels likes",
				},
			].map((d) => (
				<View key={`key${d.state}`} style={styles.inputContainer}>
					<Text style={styles.label}>{d.title}</Text>
					<TextInput
						style={styles.input}
						placeholder='total'
						value={data[d.state]}
						onChangeText={(text) =>
							handleInputChange(d.state, text)
						}
					/>
				</View>
			))}

			<Text
				style={{
					fontSize: 16,
					color: "#FFF",
					paddingBottom: 5,
				}}>
				<Font family='Gotham' weight={400}>
					Engagement: Videos
				</Font>
			</Text>
			{[
				{
					title: "Videos: ",
					state: "engagement_videos",
					placeholder: "Enter videos",
				},

				{
					title: "Posts: Saves",
					state: "engagement_videos_saves",
					placeholder: "Enter video saves",
				},
				{
					title: "Posts: likes",
					state: "engagement_videos_likes",
					placeholder: "Enter video likes",
				},
			].map((d) => (
				<View key={`key${d.state}`} style={styles.inputContainer}>
					<Text style={styles.label}>{d.title}</Text>
					<TextInput
						style={styles.input}
						placeholder='total'
						value={data[d.state]}
						onChangeText={(text) =>
							handleInputChange(d.state, text)
						}
					/>
				</View>
			))}

			<Text
				style={{
					fontSize: 16 * 1.618,
					color: "#FFF",
					paddingBottom: 5,
					marginTop: 20,
				}}>
				<Font family='Gotham' weight={400}>
					Followers:
				</Font>
			</Text>

			{[
				{
					title: "Total Page followers",
					state: "followers_total_page_followers",
				},
				{
					title: "Growth overall",
					state: "followers_overall_growth",
				},
				{
					title: "Growth follows",
					state: "followers_follows",
				},
				{
					title: "Top city & percentage",
					state: "followers_top_city",
				},
				{
					title: "Top Age range & percentage",
					state: "followers_top_age_range",
				},
				{
					title: "Top Gender",
					state: "followers_top_gender",
				},
				{
					title: "Most active time",
					state: "followers_most_active_time",
				},
			].map((d) => (
				<View key={`key${d.state}`} style={styles.inputContainer}>
					<Text style={styles.label}>{d.title}</Text>
					<TextInput
						style={styles.input}
						placeholder={d.title}
						value={data[d.state]}
						onChangeText={(text) =>
							handleInputChange(d.state, text)
						}
					/>
				</View>
			))}

			<Text
				style={{
					fontSize: 16 * 1.618,
					color: "#FFF",
					paddingBottom: 5,
				}}>
				<Font family='Gotham' weight={400}>
					Paid Ads
				</Font>
			</Text>

			{[
				{
					title: "AD Reach",
					state: "b_ad_reach",
				},
				{
					title: "Ad Taps",
					state: "b_ad_taps",
				},
				{
					title: "Website Taps",
					state: "b_website_taps",
				},
				{
					title: "Content Interactions ",
					state: "b_content_interactions",
				},
			].map((d) => (
				<View key={`key${d.state}`} style={styles.inputContainer}>
					<Text
						style={[
							styles.label,
							{
								opacity: data[d.state] !== "" ? 0.8 : 1,
							},
						]}>
						{d.title}
					</Text>
					<TextInput
						style={styles.input}
						placeholder='total'
						value={data[d.state]}
						onChangeText={(text) =>
							handleInputChange(d.state, text)
						}
					/>
				</View>
			))}

			<TouchableOpacity
				style={styles.submitButton}
				onPress={handleSubmit}>
				<Text style={styles.submitButtonText}>Submit</Text>
			</TouchableOpacity>
		</View>
	);
};

const styles = StyleSheet.create({
	modalView: {
		width: "100%",
	},
	inputContainer: {
		flexDirection: "row",
		alignItems: "center",
		margin: 10,
		marginBottom: 16,
	},
	label: {
		color: "#25F4EE",
		fontFamily: "Gotham",
		fontSize: 16,
		// color: AppColours.PLACEHOLDER,
		fontWeight: "500",
		// position: "absolute",
		// top: -12,
		// left: 9,
		// backgroundColor: AppColours.WHITE,
		// padding: 4,
		// borderRadius: 8,
	},
	input: {
		// width: "100%",
		flex: 1,
		opacity: 1,
		// borderWidth: 1,
		// borderColor: "#000", // Replace with your condition for validation colors
		padding: 12,
		// paddingLeft: 30,
		fontSize: 25,
		borderRadius: 8,
		// backgroundColor: AppColours.WHITE,
		color: "#FFF",
		fontWeight: "900",

		// alignItems: "center",
		// justifyContent: "center",
		textAlign: "right",
	},
	submitButton: {
		backgroundColor: "#000", // Replace with your actual color code
		padding: 10,
		borderRadius: 5,
	},
	submitButtonText: {
		color: AppColours.WHITE,
		fontSize: 16,
		fontWeight: "600",
		textAlign: "center",
	},
});

export default AddGrowthForm;
