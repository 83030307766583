import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TextInput,
	ScrollView,
	Keyboard,
	Modal,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import Font from "react-font";
// import DatePicker from 'react-native-date-picker';
import { APP_COLOURS, RETAILERS, screenHeight } from "../../APP_VARS";

import tick from "../../../assets/images/tick.png";
import starFill from "../../../assets/images/Table/starFill.png";
import star from "../../../assets/images/Table/star.png";
import FullCalendar from "./FullCalendar";
import BrandTabs from "../BrandTabs";
import SmallerTabs from "../TopTabs/SmallerTabs";

function currencyFormat(num) {
	return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function uuid() {
	return "xxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

function capitalizeName(name) {
	return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
}

const BG = "#171B22";
const BG2 = "#20242B";
const FONT = "#F3F3F5";
const BORDER = "#383D43";

const BLUE = "#3F6EFF";
const WHITE = FONT;
const FONT_ALMOSTWHITE = "#D4D4D4";

const RED = "#FF5F5F";
const GREEN = "#3F6EFF";
const OPACITYGREY = "#2F2F39";

const FONT_DARK = "#141D31";
const BORDERBOTTOM = "#28282F";

class AutoHeightImage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<Image
				style={{
					width: this.props.width,
					height:
						typeof this.props.height !== "undefined"
							? this.props.height
							: this.props.width,
					resizeMode: "contain",
				}}
				source={this.props.source}
			/>
		);
	}
}

export default class AddListing extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: uuid(),
			title: "",

			due_date: moment(),
			start_date: moment(),

			status: "Pending",
			description: "",

			assignment_ids: [this.props.user_data.id],
			assignment: [],
			googleDriveDocumentUrl: "",
			...this.props.selectedProduct,

			retailerList: RETAILERS,
		};
		this._timeout = 1000;
	}

	componentDidMount() {
		this.setKeyboardListener();
	}

	generateCalendar = () => {
		let startOfWeek = moment(this.state.due_date).startOf("week");

		console.log("startOfWeek", startOfWeek);

		let datesArray = [];

		let dLoop = Array.from(Array(7).keys());
		let doff = 0;
		for (const d of dLoop) {
			let date = moment(startOfWeek).add(doff, "days");
			doff = d + 1;
			datesArray.push({ date: date, event: true, meeting: true });
		}

		console.log(datesArray);
		// this.setState({
		//   eventCalendar: datesArray,
		// });
	};

	render() {
		let form_fields = [
			{
				id: 1,
				editable: true,
				state: "barcode",
				title: "Barcode / A Code",
				autoCapitalize: true,
				validation: "name",
			},
			{
				id: 2,
				editable: true,
				state: "title",
				title: "Product title",
				autoCapitalize: true,
				validation: "name",
			},

			{
				id: 4,
				editable: true,
				state: "googleDriveDocumentUrl",
				title: "Google drive file URL",
				autoCapitalize: true,
				validation: "name",
			},
		];
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<ScrollView
						showsVerticalScrollIndicator={false}
						ref={(ref) => (this.scrollViewRef = ref)}>
						<View
							style={{
								width: "100%",
								// maxWidth: 600,
								// height: this.props.screenHeight / 2,
								height: "auto",
								// maxHeight: screenHeight - this.state.keyboardOffset,
								// flex: 1,
								// backgroundColor: BG,
								backgroundColor: "#FFF",
								borderRadius: 10,
								padding: 10,
								// paddingTop: 50,
								marginTop: 50,
							}}>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
									// borderBottomWidth: 0.5,
									paddingBottom: 10,
									// borderBottomcolor: APP_COLOURS.BG2,
									marginBottom: 20,
								}}>
								<TouchableOpacity
									onPress={async () => {
										this.props.close();
									}}>
									<Image
										style={{
											width: 24,
											height: 24,
											resizeMode: "contain",
											marginRight: 10,
										}}
										source={require("../../../assets/images/close.png")}
									/>
								</TouchableOpacity>
								<View style={{ flex: 1 }}>
									<Text
										style={{
											color: APP_COLOURS.BG2,
											fontSize: 20,
											marginLeft: 10,
											fontWeight: "600",
											fontFamily: "Gotham",
										}}>
										{this.props.selectedProduct !== ""
											? "Update"
											: "Add"}{" "}
										Listing
									</Text>
								</View>

								{this.props.selectedProduct !== "" ? (
									<TouchableOpacity
										onPress={async () => {
											this.props.deleteListing();
										}}>
										<Image
											style={{
												width: 24,
												height: 24,
												resizeMode: "contain",
											}}
											source={require("../../../assets/images/bin.png")}
										/>
									</TouchableOpacity>
								) : null}
							</View>

							<SmallerTabs
								selectedSubPage={(d) => {
									this.setState({
										selectedTab: d,
									});
									console.log(d);
								}}
								tabs={["Product", "Retailer", "Dates"]}
							/>

							<View
								style={
									{
										// display:
										// this.state.selectedTab
									}
								}>
								<View
									style={{
										marginBottom: 10,
										padding: 10,
										borderRadius: 10,
										backgroundColor: BG2,
										// backgroundColor: "#FFF",
										maxWidth: 350,
									}}>
									<Text
										style={{
											fontSize: 10,
											color: "#FFF",
											fontWeight: "400",
											fontFamily: "Gotham",
											// opacity: 0.5,
											padding: 0,
											marginBottom: 3,
										}}>
										Select brand
									</Text>
									<ScrollView
										horizontal
										showsHorizontalScrollIndicator={false}>
										<BrandTabs
											{...this.state}
											selectedSubPage={(d) => {
												this.setState({
													brand: {
														id: d.id,
														title: d.title,
													},
												});
											}}
										/>
									</ScrollView>
								</View>
							</View>

							{/* 
							<View
								style={{
									marginBottom: 10,
									padding: 10,
									borderRadius: 10,
									// backgroundColor: BG2,
									backgroundColor: "#FFF",
								}}>
								<Text
									style={{
										fontSize: 10,
										color: APP_COLOURS.BG2,
										fontWeight: "400",
										fontFamily: "Gotham",
										// opacity: 0.5,
										padding: 0,
										marginBottom: 3,
									}}>
									Priority
								</Text>

								<View
									style={{
										width: 120,
										flexDirection: "row",
										justifyContent: "flex-start",
									}}>
									{[0, 1, 2, 3, 4, 5].map((priority, ii) => (
										<TouchableOpacity
											key={ii}
											key={priority}
											onPress={() =>
												this.setState({
													priority: priority,
												})
											}>
											{this.state.priority >= priority ? (
												<AutoHeightImage
													width={19}
													style={{
														marginRight: 3,
													}}
													source={starFill}
												/>
											) : (
												<AutoHeightImage
													width={19}
													style={{
														marginRight: 3,
														opacity: 0.5,
													}}
													source={star}
												/>
											)}
										</TouchableOpacity>
									))}
								</View>
							</View> */}

							{/* <View
								style={{
									marginTop: 20,
								}}>
								<Text
									style={{
										fontWeight: "400",
										fontFamily: "Gotham",
										fontSize: 12,
										color: BORDER,
										marginBottom: 4,
										position: "absolute",
										top: -12,
										left: 9,
										zIndex: 99,
										backgroundColor: BG,
										padding: 4,
										borderRadius: 8,
									}}>
									Task description
								</Text>

								<TextInput
									style={{
										fontSize: 16,
										color: APP_COLOURS.BG2,
										fontWeight: "400",
										fontFamily: "Gotham",
										width: "100%",
										paddingLeft: 10,
										padding: 13,
										// backgroundColor:
										// 	this.state.docID !== "" ? BLUE : BG2,
										color: APP_COLOURS.BG2,
										borderRadius: 7,
										borderColor: BORDER,
										borderWidth: 1,
									}}
									placeholderTextColor={FONT}
									autoCapitalize='sentences'
									// autoCorrect={false}
									clearButtonMode='while-editing'
									// autoCompleteType="off"
									placeholder={`Task title`}
									onChangeText={(text) =>
										this.setState({
											title: text,
										})
									}
								/>
							</View> */}

							{form_fields.map((d, i) => {
								let validated = `${d.state}_valid`;
								return (
									<View
										key={i}
										style={{
											width: "auto",

											flexDirection: "row",
											alignItems: "center",
											margin: 10,
											marginBottom: 16,
										}}>
										<Text
											style={{
												opacity: this.state.unEditable
													? 0.4
													: 1,
												fontFamily: "Gotham",
												fontSize: 12,
												color: APP_COLOURS.PLACEHOLDER,
												fontWeight: "500",
												marginBottom: 4,
												position: "absolute",
												top: -12,
												left: 9,
												backgroundColor: "#FFF",
												padding: 4,
												borderRadius: 8,
											}}>
											{d.title}
										</Text>
										<TextInput
											style={{
												width: "100%",
												opacity: this.state.unEditable
													? 0.4
													: 1,
												borderWidth: 1,
												borderColor: this.state[
													validated
												]
													? APP_COLOURS.GREEN
													: typeof this.state[
															d.state
													  ] !== "undefined"
													? "#000"
													: APP_COLOURS.RED,
												padding: 12,
												paddingLeft: 30,
												fontSize: 16,
												borderRadius: 8,
												backgroundColor:
													APP_COLOURS.WHITE,
												color: "#000",
												fontWeight: "500",
												fontFamily: "Gotham",
											}}
											placeholderTextColor={"#000"}
											// autoCapitalize="sentences"
											clearButtonMode='while-editing'
											autoCorrect={false}
											// placeholder={d.title}
											defaultValue={this.state[d.state]}
											onChangeText={async (text) => {
												// if (d.autoCapitalize) {
												// 	text = await capitalizeName(
												// 		text,
												// 	);
												// }

												await this.setState({
													[d.state]: text,
												});
											}}
										/>

										{this.state.unEditable &&
										!d.editable ? (
											<View
												style={{
													position: "absolute",
													width: "100%",
													height: "100%",
													backgroundColor: `rgba(52, 52, 52, 0.3)`,
													flex: 1,
													justifyContent: "center",
													alignItems: "center",
													borderRadius: 8,
												}}>
												<Text
													style={{
														fontFamily: "Gotham",
														fontSize: 12,
														color: APP_COLOURS.WHITE,
														fontWeight: "700",
													}}>
													Uneditable field
												</Text>
											</View>
										) : null}

										{this.state[validated] ? (
											<Image
												source={tick}
												style={{
													width: 16,
													height: 16,
													resizeMode: "contain",
													position: "absolute",
													left: 9,
													top: 16,
												}}
											/>
										) : null}
									</View>
								);
							})}

							<Text
								style={{
									fontFamily: "Gotham",
									fontSize: 12,
									color: APP_COLOURS.BG3,
									fontWeight: "700",
								}}>
								Select retailers
							</Text>

							{RETAILERS.map((d, i) => {
								let exists = this.state.retailerList.includes(
									d.title,
								);

								return (
									<TouchableOpacity
										onPress={() => {
											if (exists) {
												this.setState({
													retailerList:
														this.state.retailerList.filter(
															(r) =>
																r !== d.title,
														),
												});
											} else {
												this.setState({
													retailerList: [
														...this.state
															.retailerList,
														d.title,
													],
												});
											}
										}}>
										<View
											key={i}
											style={{
												width: "auto",

												flexDirection: "row",
												alignItems: "center",
												margin: 10,
												marginBottom: 16,
											}}>
											<View
												style={{
													opacity: exists ? 1 : 0.2,
													padding: 5,
													borderRadius: 4,
													// borderWidth: 0.5,
													marginRight: 5,
												}}>
												<Image
													source={tick}
													style={{
														width: 16,
														height: 16,
														resizeMode: "contain",
														// position: "absolute",
														// left: 9,
														// top: 16,
														opacity: exists
															? 1
															: 0.2,
													}}
												/>
											</View>

											<Text
												style={{
													opacity: exists ? 1 : 0.4,
													fontFamily: "Gotham",
													fontSize: 15,
													color: APP_COLOURS.BG3,
													fontWeight: "500",

													backgroundColor: "#FFF",
													padding: 4,
													borderRadius: 8,
												}}>
												{d.title}
											</Text>
										</View>
									</TouchableOpacity>
								);
							})}

							<View
								style={{
									width: 350,
									// display: this.state.showCalendar
									// 	? "flex"
									// 	: "none",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<View
									style={{
										width: 330,
										flexDirection: "row",
									}}>
									<View
										style={{
											marginTop: 20,
											flex: 1,
										}}>
										<Text
											style={{
												fontWeight: "400",
												fontFamily: "Gotham",

												fontSize: 12,
												color: APP_COLOURS.BG2,
												marginBottom: 4,
												position: "absolute",
												top: -12,
												left: 9,
												backgroundColor: "#FFF",
												padding: 4,
												borderRadius: 8,
												zIndex: 99,
											}}>
											Due date
										</Text>
										<View
											style={{
												borderRadius: 7,
												borderColor: BORDER,
												borderWidth: 1,
											}}>
											<TouchableOpacity
												onPress={() => {
													this.setState({
														dateAllocation:
															"due_date",
													});
												}}>
												<View
													style={{
														width: "100%",
														padding: 13,
														// justifyContent: "center",
														// alignItems: "center",
														paddingLeft: 10,
													}}>
													<Text
														style={{
															color: APP_COLOURS.BG2,
															fontWeight: "600",
															fontSize: 16,
														}}>
														{moment(
															this.state.due_date,
														).format("DD/MM/YYYY")}
													</Text>
												</View>
											</TouchableOpacity>
										</View>
									</View>
								</View>
								<FullCalendar
									compWidth={350}
									calendarList={[]}
									selectedDate={
										this.state[this.state.dateAllocation]
									}
									startDate={(date) => {
										this.setState({
											start_date: date,
										});
									}}
									endDate={(date) => {
										this.setState({
											due_date: date,
										});
									}}
								/>
							</View>

							<TouchableOpacity
								style={{
									width: this.state.addingBrand
										? "auto"
										: "100%",
									padding: 10,
									display:
										this.state.passcode !== ""
											? "flex"
											: "none",
								}}
								onPress={async () => {
									let obj = { ...this.state };
									delete obj.keyboardOffset;

									this.props.addEntry(obj);
									console.log(obj);
								}}>
								<View
									style={{
										backgroundColor: APP_COLOURS.BG2,
										width: this.state.addingBrand
											? "auto"
											: "100%",
										padding: 13,
										height: 45,
										borderRadius: 25,
										justifyContent: "center",
										alignItems: "center",
									}}>
									{!this.state.addingBrand ? (
										<Text
											style={{
												alignItems: "center",
												justifyContent: "center",
												color: "#FFF",
												fontSize: 15,
											}}>
											<Font
												weight={700}
												family='Montserrat'>
												{this.props.selectedProduct !==
												""
													? "Update"
													: "Add"}{" "}
												listing
											</Font>
										</Text>
									) : (
										<ActivityIndicator color={"#FFF"} />
									)}
								</View>
							</TouchableOpacity>
						</View>
					</ScrollView>
				</View>
			</Modal>
		);
	}

	setKeyboardListener = async () => {
		this.keyboardDidShowListener = Keyboard.addListener(
			"keyboardDidShow",
			this._keyboardDidShow,
		);
		this.keyboardDidHideListener = Keyboard.addListener(
			"keyboardDidHide",
			this._keyboardDidHide,
		);
	};

	_keyboardDidShow = (event) => {
		console.log(event.endCoordinates.height);
		if (this.state.keyboardOffset === 0) {
			this.setState({
				keyboardOffset: event.endCoordinates.height,
				// keyboardActive: true,
			});
		}
	};

	_keyboardDidHide = () => {
		// this.setState({
		//   keyboardActive: false,
		// });
		// LayoutAnimation.spring();
	};

	componentWillUnmount() {
		this.setState = (state, callback) => {
			return;
		};
		this.keyboardDidShowListener.remove();
		this.keyboardDidShowListener.remove();
	}
}
