import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	Alert,
	LayoutAnimation,
	Animated,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import debbie from "../../../assets/images/cloud.png";
import bin from "../../../assets/images/bin.png";
import { APP_COLOURS } from "../../APP_VARS";
import { Avatars, TaskStatus } from "../../APP_VARS";
import Fade from "react-reveal/Fade";
import { crudCreate, crudDelete, crudUpdate } from "../../CRUDHELPER";
import UsersSelectList from "../UsersSelectList";

const collectionName = "projects_milestones";

export default class EditMilestone extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props.d,
		};
	}

	saveEdit = () => {
		let obj = {
			...this.state,
		};

		crudUpdate(collectionName, obj, (status, d) => {
			// console.log(status, d);
			console.log("updateItem");
			if (status === 200) {
				this.props.close();
				this.props.reloadData();
			}
		});
	};

	deleteItem = (d) => {
		// console.log(this.state.selectedSubTask);
		// return;
		crudDelete(collectionName, d, () => {
			this.props.close();
			this.props.reloadData();
		});
	};

	componentDidUpdate(prevProps) {
		console.log("change");
		if (prevProps.d !== this.props.d) {
			this.setState({
				...this.props.d,
			});
		}
	}

	render() {
		let color = "#434586";
		let d = this.props.d;

		let textBox = {
			fontSize: 16,
			fontWeight: "600",
			color: color,
			width: "100%",
			height: "auto",
			borderBottomWidth: 0.5,
			padding: 10,
		};

		return (
			<View
				style={{
					flex: 1,
					flexDirection: "row",
				}}>
				<View
					style={{
						flex: 1.5,
						// flexDirection: "row",
					}}>
					<View
						style={{
							flex: 1,
						}}>
						<View
							style={{
								flexDirection: "row",
								width: "100%",
								alignItems: "center",
								// marginTop: 30,
								marginBottom: 10,
								height: "auto",
								borderBottomWidth: 0.5,
								paddingBottom: 10,
							}}>
							<View
								style={{
									flex: 1,
									flexDirection: "row",
									alignItems: "center",
								}}>
								<TouchableOpacity
									onPress={() => this.props.close()}>
									<Image
										source={require("../../../assets/images/close.png")}
										style={{
											width: 20,
											height: 20,
											resizeMode: "contain",
										}}
									/>
								</TouchableOpacity>
								<Text
									style={{
										color: APP_COLOURS.BG2,
										fontSize: 20,
										marginLeft: 10,
										fontWeight: "600",
										fontFamily: "Gotham",
									}}>
									Edit milestone
								</Text>
							</View>

							<TouchableOpacity
								onPress={() => {
									this.deleteItem(this.state);
								}}
								style={
									{
										// position: "absolute",
										// right: 10,
										// top: 0,
									}
								}>
								<Image
									source={bin}
									style={{
										width: 30,
										height: 30,
										marginRight: 5,
										resizeMode: "contain",
									}}
								/>
							</TouchableOpacity>
						</View>
						<Text
							style={{
								color: color,
								fontSize: 16,
								marginBottom: 10,
								fontWeight: "600",
								marginTop: 30,
							}}>
							{this.props.projectData.title}
						</Text>

						{/* <Text
						style={{
							color: color,
							fontSize: 11,
							marginBottom: 10,
						}}>
						{this.state.id}
					</Text> */}
						<View
							style={{
								marginBottom: 10,
							}}>
							<Text
								style={{
									color: color,
									fontSize: 11,
								}}>
								Milestone title
							</Text>
							<TextInput
								returnKeyType='done'
								autoCorrect={false}
								style={textBox}
								value={this.state.title}
								editable={true}
								onChangeText={(text) =>
									this.setState({ title: text })
								}
								autoCapitalize='sentences'
								placeholder='Milestone title'
								ref='taskName'
								clearButtonMode='while-editing'
								autoFocus
							/>
						</View>
						<View
							style={{
								marginBottom: 10,
							}}>
							<Text
								style={{
									color: color,
									fontSize: 11,
								}}>
								Description
							</Text>
							<TextInput
								returnKeyType='done'
								autoCorrect={false}
								style={textBox}
								value={this.state.description}
								editable={true}
								onChangeText={(text) =>
									this.setState({ description: text })
								}
								autoCapitalize='sentences'
								placeholder='Description'
								ref='taskName'
								clearButtonMode='while-editing'
								autoFocus
							/>
						</View>
					</View>
					<View
						style={
							{
								// flex: 1,
							}
						}>
						<View
							style={{
								// backgroundColor: 'red',
								width: "100%",
								height: 40,
								flexDirection: "row",
								justifyContent: "space-between",
								marginTop: 15,
							}}>
							<TouchableOpacity
								onPress={() => this.saveEdit()}
								style={{
									flex: 1,
									backgroundColor: APP_COLOURS.RED,
									justifyContent: "center",
									borderRadius: 10,
								}}>
								<Text
									style={{
										width: "100%",
										textAlign: "center",
										color: "white",
										fontSize: 12,
										fontWeight: "600",
										padding: 5,
									}}>
									SAVE
								</Text>
							</TouchableOpacity>
						</View>
					</View>
				</View>
				<View
					style={{
						flex: 1,
						padding: 5,
					}}>
					<UsersSelectList
						assignment={this.state.assignment}
						assignment_ids={this.state.assignment_ids}
						isStandalone={false}
						addAssignment={(ass, ids) => {
							this.setState({
								assignment: ass,
								assignment_ids: ids,
							});
						}}
					/>
				</View>
			</View>
		);
	}
}
