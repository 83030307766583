import React, { Component } from "react";
import { View, Text, ScrollView, TouchableOpacity, Image } from "react-native";
import Font from "react-font";
import Footer from "./Footer";
import { APP_COLOURS } from "../../../APP_VARS";
class Page2 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pageMargin: 32,
			documentText: this.props.data,
		};
	}

	render() {
		let width = 795;
		let height = 1119;
		let margin = this.state.pageMargin;
		return (
			<View
				style={{
					height: height,
					width: width,
					backgroundColor: "#FFF",
					marginRight: 20,
				}}>
				<div className='page-break' />
				<View
					style={{
						width: "100%",
						height: "auto",
						flexDirection: "row",
						padding: this.state.pageMargin,
					}}>
					{/* Left  */}
					<View
						style={{
							flex: 1,
						}}>
						<Image
							style={{
								width: 173,
								height: 50,
								resizeMode: "contain",
							}}
							source={require("../../../../assets/images/tetrice_logo.png")}
						/>
					</View>

					{/* Right  */}
					<View
						style={{
							flex: 1,
						}}>
						<View
							style={{
								// flex: 1,
								// alignItems: 'flex-end',
								flexDirection: "row",
								paddingBottom: 16,
								paddingTop: 5,
								justifyContent: "flex-end",
							}}>
							<View
								style={{
									alignItems: "flex-end",
									// backgroundColor: APP_COLOURS.BG2,
								}}>
								{/* <Image
									style={{
										width: 113,
										height: 50,
										resizeMode: "contain",
									}}
									source={require("../../../../assets/images/tetrice-revlon-main.png")}
								/> */}
								<Text
									style={{
										fontSize: 16,
										color: APP_COLOURS.BG2,
									}}>
									<Font family='Poppins' weight={600}>
										REVLON SA (Pty) ltd
									</Font>
								</Text>
							</View>
						</View>
					</View>
				</View>
				{/* Top END */}

				<View
					style={{
						flex: 1,
						paddingLeft: this.state.pageMargin,
						paddingRight: this.state.pageMargin,
						marginBottom: 5,
					}}>
					<View
						style={{
							flex: 1,
						}}>
						{this.state.documentText.map((d, i) => {
							if (d.type === "h1") {
								return (
									<Text
										style={{
											fontSize: 19,
											marginBottom: 10,
										}}>
										<Font family='Poppins' weight={600}>
											{d.text}
										</Font>
									</Text>
								);
							} else if (d.type === "h2") {
								return (
									<Text
										style={{
											fontSize: 15,
											marginBottom: 10,
										}}>
										<Font family='Poppins' weight={500}>
											{d.text}
										</Font>
									</Text>
								);
							} else if (d.type === "h3") {
								return (
									<Text
										style={{
											fontSize: 13,
											marginBottom: 10,
										}}>
										<Font family='Poppins' weight={500}>
											{d.text}
										</Font>
									</Text>
								);
							} else if (d.type === "p") {
								return (
									<Text
										style={{
											width: "100%",
											fontSize: 11,
											marginBottom: 10,
											textAlign: "justify",
										}}>
										<Font
											style={{
												textAlign: "justify",
											}}
											family='Poppins'
											weight={300}>
											{d.text}
										</Font>
									</Text>
								);
							} else if (d.type === "li") {
								return d.data.map((dd, ii) => (
									<Text
										style={{
											width: "100%",
											fontSize: 11,
											marginBottom: 5,
											textAlign: "justify",
										}}>
										<Font
											style={
												{
													// textAlign: "justify",
												}
											}
											family='Poppins'
											weight={300}>
											{dd}
										</Font>
									</Text>
								));
							}
						})}
					</View>
				</View>

				<Footer
					title={"master services agreement"}
					margin={margin}
					pageNumber={2}
					totalPages={10}
				/>
			</View>
		);
	}
}

export default Page2;
